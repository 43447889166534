import * as SearchService from "../../../services/search";
import * as FindSimilarConversaionsService from "../../../services/findSimilarConversations";

export const getAllStories: (obj: {
	startDate?: string | undefined;
	endDate?: string | undefined;
	speaker?: string | undefined;
	showResults?: boolean | undefined;
	storyId?: string | undefined;
}) => Promise<unknown> = async (obj: {
	startDate?: string | undefined;
	endDate?: string | undefined;
	speaker?: string | undefined;
	showResults?: boolean | undefined;
	storyId?: string | undefined;
}) => {
	const data = await FindSimilarConversaionsService.getAllStories(obj);
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};

export const addToStory: (transcriptData: any, storyId: string) => Promise<unknown> = async (transcriptData, storyId) => {
	const data = await SearchService.addToStory(transcriptData, storyId);
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};

export const getStoryStatus: (storyId: string) => Promise<unknown> = async (storyId) => {
	const data = await FindSimilarConversaionsService.getStoryStatus(storyId);
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};
