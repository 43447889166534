import { Checkbox, Empty, Input, List, Modal, Skeleton, Spin, Switch } from "antd";
import React from "react";
import FilterResultCard from "../../../../Components/FilterResultCard";
import { Transcripts } from "../../../../Components/SearchEngineCards/Transcripts";
import TranscriptsWrapper from "../../../../Components/TransciptsWrapper";
import { icons } from "../../../../GlobalThemes";
import * as StoriesSearchHelper from "../../ServiceHelpers/stories_search";
import * as StoriesServiceHelper from "../../../../Pages/SearchEngine/ServiceHelpers/stories";
import * as SearchServiceHelper from "../../../../Pages/SearchEngine/ServiceHelpers/search";

import * as Messages from "../../../../Components/Messages";
import { filterOptions } from "../../data";
import { searchQuery } from "../../Search";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import moment from "moment";
import AppContext from "../../../../store";

interface dataResultInterface {
	filterOptions: {
		filterName: string;
		type: string;
		options: string[];
	}[];
	numberOfPatients: number;
	numberOfSearchResults: number;
	searchResults: {
		id: number;
		s3path: string;
		callDate: string;
		callId: string;
		callTime: string;
		dialogueNo: number;
		name: string;
		tags: string[];
		dialogueAppearedAt: number;
		dialogueLength: number;
		transcriptLength: number;
		addedToStory: boolean;
		patientId: number;
		speaker: string;
		elasticSearchId: string;
		transcripts: {
			dialogueNo: number;
			speaker: string;
			text: string;
		}[];
	}[];
}

export interface transcriptDataInter {
	numberOfPatients: number;
	numberOfSearchResults: number;
	emotionsCounts: any;
	statesResults: any;
	searchResults: {
		id: number;
		s3path: string;
		callDate: string;
		callId: string;
		callTime: string;
		callTypeIds: string;
		therapyStatusIds: string;
		dialogueNo: number;
		name: string;
		tags: string[];
		dialogueAppearedAt: number;
		dialogueLength: number;
		transcriptLength: number;
		addedToStory: boolean;
		storiesAdded: {
			storyId: any;
			storyName: string;
		}[];
		patientId: number;
		speaker: string;
		elasticSearchId: string;
		transcripts: {
			dialogueNo: number;
			speaker: string;
			text: string;
		}[];
	}[];
}

/**
 * Renders the Search View inside Story, showing the Filters and the Searched Transcripts.
 *
 * @param storyId - Id of the Story that the component is getting rendered in
 * @param addTranscript - Callback function for handling Addition of Transcript to a Story
 *
 * @returns Search View
 */

export default function StoriesSearch(props: { storyId: string; addTranscript: (transcriptData: any) => void }) {
	const [dataResult1, setDataResult1] = React.useState<dataResultInterface[]>([]);
	const [expanded, setExpanded] = React.useState(false);
	const [filters, setFilters] = React.useState<
		{
			filter: string;
			value: string;
		}[]
	>([]);
	const [searchString, setSearchString] = React.useState("");
	const [deepSearch, setDeepSearch] = React.useState(false);
	const [testButton1, setTestButton1] = React.useState(false);
	const [testButton2, setTestButton2] = React.useState(false);
	const [testButton3, setTestButton3] = React.useState(false);
	const [testButton4, setTestButton4] = React.useState(false);
	const [testButton5, setTestButton5] = React.useState(false);
	const [modalVisible, setModalVisible] = React.useState(false);
	const [fetching, setFetching] = React.useState(false);
	const [emptyText, setEmptyText] = React.useState("Enter a search word to see results.");
	const [errorText, setErrorText] = React.useState("");

	function handleAddToStory(transcriptData: any, storyId: number[]) {
		const temp: dataResultInterface[] = [...dataResult1];

		const idx = temp[0].searchResults.findIndex((res) => res.callId === transcriptData.callId && res.dialogueNo === transcriptData.dialogueNo);

		if (idx !== -1) {
			// temp[0].searchResults[idx].addedToStory = true;
			// setDataResult1(temp);
			const res = addToStory({
				...temp[0].searchResults[idx],
				storiesAdded: [{ storyId: props.storyId, storyName: "" }],
				source: 1,
			});
			return res;
		}
		return Promise.reject();
	}

	async function addToStory(transcriptData: any) {
		const key = "updatable";
		Messages.loading("Adding to Story", key);
		const data: any = await StoriesSearchHelper.addToStory(transcriptData, props.storyId);
		if (data) {
			const temp = [...dataResult1];
			const idx = temp[0].searchResults.findIndex((res) => res.callId === transcriptData.callId && res.dialogueNo === transcriptData.dialogueNo);

			temp[0].searchResults[idx].addedToStory = true;
			props.addTranscript(temp[0].searchResults[idx]);
			setDataResult1(temp);
			// setFetchedData(temp);
			Messages.success("Added Successfully", key);
			return true;
		} else {
			Messages.error("Failed!", key);
			return false;
		}
	}
	async function fetchData(
		searchword?: string,
		deepSearch?: boolean,
		testButton1?: boolean,
		testButton2?: boolean,
		testButton3?: boolean,
		testButton4?: boolean,
		testButton5?: boolean
	) {
		setFetching(true);
		let showResults = filters.find((r) => r.filter === "Show Results")?.value;

		const obj: searchQuery = {};
		obj.deepSearch = deepSearch;
		obj.testButton1 = testButton1;
		obj.testButton2 = testButton2;
		obj.testButton3 = testButton3;
		obj.testButton4 = testButton4;
		obj.testButton5 = testButton5;
		obj.startDate = filters.find((r) => r.filter === "Start Date")?.value;
		obj.endDate = filters.find((r) => r.filter === "End Date")?.value;
		obj.speaker = filters.find((r) => r.filter === "Speaker Type")?.value;
		obj.totStart = filters.find((r) => r.filter === "totStart")?.value;
		obj.totEnd = filters.find((r) => r.filter === "totEnd")?.value;
		obj.showResults = showResults ? (showResults === "All" ? undefined : showResults === "Already added to a story" ? true : false) : undefined;
		obj.searchWord = searchword;
		obj.storyId = props.storyId;

		const data: any = await StoriesSearchHelper.getAllTranscript(obj);
		console.log(data);

		if (data) {
			// data.filterOptions = filterOptions;
			// dataCopy.current = [{ ...data }];
			// setTranscriptData([data]);
			setDataResult1([data]);
			setFetching(false);
		}
	}
	React.useEffect(() => {
		if (searchString) fetchData(searchString, deepSearch, testButton1, testButton2, testButton3, testButton4, testButton5);
	}, [filters]);

	function ModalContent() {
		return (
			<>
				{/* <Checkbox
					onChange={(e) => {
						handleFilters("deepSearch", e.target.checked);
					}}
				>
					Deep Search
				</Checkbox> */}
				<div className="text-2xl text-text-gray-100 font-normal my-4">Search Settings</div>
				<div className="text-base text-text-gray-50 my-6">Please select the search settings.</div>
				<div className="w-full ">
					<div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">Enable Deep Search</div>

						<Switch
							onChange={(checked) => {
								setDeepSearch(checked);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}
								>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}
								>
									<CloseOutlined />
								</div>
							}
							defaultChecked={deepSearch}
						/>
					</div>

					<div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">Test Button 1</div>

						<Switch
							onChange={(checked) => {
								setTestButton1(checked);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}
								>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}
								>
									<CloseOutlined />
								</div>
							}
							defaultChecked={testButton1}
						/>
					</div>

					<div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">Test Button 2</div>

						<Switch
							onChange={(checked) => {
								setTestButton2(checked);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}
								>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}
								>
									<CloseOutlined />
								</div>
							}
							defaultChecked={testButton2}
						/>
					</div>

					<div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">Test Button 3</div>

						<Switch
							onChange={(checked) => {
								setTestButton3(checked);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}
								>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}
								>
									<CloseOutlined />
								</div>
							}
							defaultChecked={testButton3}
						/>
					</div>

					<div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">Test Button 4</div>

						<Switch
							onChange={(checked) => {
								setTestButton4(checked);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}
								>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}
								>
									<CloseOutlined />
								</div>
							}
							defaultChecked={testButton4}
						/>
					</div>
					<div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">Test Button 5</div>

						<Switch
							onChange={(checked) => {
								setTestButton5(checked);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}
								>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}
								>
									<CloseOutlined />
								</div>
							}
							defaultChecked={testButton5}
						/>
					</div>
					{/* <div className="flex justify-end pt-4">
						<button
							type="button"
							className="bg-text-teal text-white text-base border-text-teal border-2 px-4 py-2"
							onClick={() => {
								setModalVisible(false);
							}}
						>
							Save Settings
						</button>
					</div> */}
				</div>
			</>
		);
	}

	const [pinnedStories, setPinnedStories] = React.useState<Array<any>>([]);
	const [fetchedStoryData, setFetchedStoryData] = React.useState<any>([]);
	const [transcriptData, setTranscriptData] = React.useState<transcriptDataInter[]>([]);

	const changePinnedStories = (stories: any) => {
		setPinnedStories(stories);
	};

	async function fetchStoryData() {
		const data: any = await StoriesServiceHelper.getAllStories();

		if (data) {
			setFetchedStoryData(data);
		}
	}

	async function addNewStory(addData: { "Story Name": string; "Story Type": string }) {
		const key = "updatable";
		Messages.loading("Adding Transcript", key);

		addData["Story Type"] = addData["Story Type"].split(" ").join("");
		// console.log(addData);
		const data: any = await StoriesServiceHelper.addNewStory(addData);
		if (data) {
			var temp: any = [];
			var d = data["Date Created"];
			data["Date Created"] = moment(d).format("DD MMM YYYY");
			temp = [data, ...fetchedStoryData];
			setFetchedStoryData(temp);
			Messages.success("Successfully Created New Story", key);
		} else Messages.error("Failed", key);
	}
	const context = React.useContext(AppContext);
	async function addStoryToTranscriptData(
		transcript: {
			id: any;
			name: any;
			tags: any;
			storiesAdded: any;
			source: number;
			dialogueNo: any;
			callId: any;
			callDate: any;
			callTime: any;
			s3path: any;
			dialogueAppearedAt: any;
			dialogueLength: any;
			transcriptLength: any;
			transcripts: any;
			patientId: any;
			speaker: any;
			elasticSearchId: any;
			storyDialogues: any;
		},
		storyId?: any
	) {
		const key = "updatable";
		Messages.loading("Adding Transcript to Story", key);

		const data: any = await SearchServiceHelper.addToStory(transcript);

		if (data) {
			Messages.success("Successfully Added Transcript to Story", key);

			const temp: any = [...dataResult1];
			setTranscriptData(temp);

			console.log("DataResult2", data, dataResult1, transcriptData, transcript);

			const idx = temp[0].searchResults.findIndex((res: any) => res.callId === transcript.callId && res.dialogueNo === transcript.dialogueNo);

			console.log("Console", data, idx);

			//@ts-ignore
			temp[0].searchResults[idx].storiesAdded = data;
			if (data.length === 0) {
				temp[0].searchResults[idx].addedToStory = false;
			}
			setTranscriptData(temp);

			return true;
		} else {
			Messages.error("Failed", key);
			return false;
		}
	}

	const addToStorySearch = (transcriptData: any, storyId: any) => {
		var request = {
			transcriptData: transcriptData,
			storyId: storyId,
		};
		var add: any = [];
		storyId.forEach((story: any, index: number) => {
			add.push({
				storyId: story,
				storyName: fetchedStoryData[fetchedStoryData.findIndex((storyFetch: any) => storyFetch.id === story)].storyName,
			});
		});

		var trans = {
			id: transcriptData.id,
			name: transcriptData.name,
			tags: transcriptData.tags,
			storiesAdded: add,
			source: 1,
			dialogueNo: transcriptData.dialogueNo,
			callId: transcriptData.callId,
			callDate: transcriptData.callDate,
			callTime: transcriptData.callTime,
			s3path: transcriptData.s3path,
			dialogueAppearedAt: transcriptData.dialogueAppearedAt,
			dialogueLength: transcriptData.dialogueLength,
			transcriptLength: transcriptData.transcriptLength,
			transcripts: transcriptData.transcripts,
			patientId: transcriptData.patientId,
			speaker: transcriptData.speaker,
			elasticSearchId: transcriptData.elasticSearchId,
			storyDialogues: transcriptData.storyDialogues,
		};
		console.log("TRANS", trans, storyId);
		const d = addStoryToTranscriptData(trans, storyId);

		return d;
	};

	React.useEffect(() => {
		// fetchFilters();
		fetchStoryData();
	}, []);

	const onChangeOfSearch = (e: any) => {
		setSearchString(e);
		console.log(e);
	};

	return (
		<>
			<Modal
				// title="Search Settings"
				visible={modalVisible}
				onOk={() => setModalVisible(false)}
				onCancel={() => setModalVisible(false)}
				footer={null}
			>
				<ModalContent />
			</Modal>
			<>
				<div className="p-4 bg-white">
					<div className="pb-4 border-b-2 flex items-center gap-2 relative">
						<Input.Search
							defaultValue={searchString}
							className="flex w-1/3 input-search"
							placeholder="Search for transcript dialogues with keywords and tags"
							onChange={(e) => onChangeOfSearch(e.target.value)}
							onSearch={(value) => {
								if (value) {
									fetchData(value, deepSearch, testButton1, testButton2, testButton3, testButton4, testButton5);
									setEmptyText("Loading...");
									setErrorText("");
								} else {
									setErrorText("Enter a word to search.");
								}
							}}
						/>
						<div className="absolute -bottom-px">{errorText && <p className="text-text-red text-sm">{errorText}</p>}</div>

						<div title="Search Settings" className="text-text-gray-60 cursor-pointer" onClick={() => setModalVisible(true)}>
							{icons.find((l) => l.id === "Search Settings")?.icon}
						</div>
					</div>
					{dataResult1.length > 0 ? (
						<div className="grid grid-cols-10">
							<div
								className="col-span-2 border-r-2 p-2 overflow-y-auto"
								// style={{ maxHeight: "26.5em" }}
								style={{ maxHeight: "51vh" }}
							>
								<FilterResultCard filterOptions={filterOptions} filters={filters} setFilters={setFilters} />
							</div>
							{!fetching ? (
								<div
									className="col-span-8 px-2 overflow-y-auto"
									// style={{ maxHeight: "26.5em" }}
									style={{ maxHeight: "51vh" }}
								>
									<TranscriptsWrapper
										header={`${dataResult1[0].numberOfSearchResults} search results across ${dataResult1[0].numberOfPatients} members`}
										expanded={expanded}
										toggleExpand={setExpanded}
										goodSearch={dataResult1.length > 0 ? dataResult1[0].searchResults.filter((x: any) => x.tags[0] === "Good").length : null}
										badSearch={dataResult1.length > 0 ? dataResult1[0].searchResults.filter((x: any) => x.tags[0] === "Bad").length : null}
										averageSearch={dataResult1.length > 0 ? dataResult1[0].searchResults.filter((x: any) => x.tags[0] === "Average").length : null}
										noSummary
									>
										<List
											itemLayout="vertical"
											size="large"
											pagination={{
												hideOnSinglePage: true,
												style: {
													marginLeft: "2rem",
													marginBottom: "1.2rem",
													float: "right",
												},
												onChange: (page) => {
													console.log(page);
												},
												pageSize: 10,
											}}
											locale={{
												emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>{emptyText}</span>} />,
											}}
											dataSource={dataResult1[0].searchResults}
											renderItem={(result: any) => (
												<Transcripts
													storyId={props.storyId}
													data={result}
													expanded={expanded}
													addToStoryApi={addToStorySearch}
													// dataForNegativeSampling={dataResult1[0].searchResults}
													transcriptData={dataResult1.length > 0 ? dataResult1[0].searchResults : []}
													// adding extra props here to get the modal
													pinnedStoriesData={pinnedStories}
													fetchedStoryData={fetchedStoryData}
													fetchStoryData={fetchStoryData}
													addNewStory={addNewStory}
													changePinnedStories={changePinnedStories}
													searchString={searchString}
													setSearchString={setSearchString}
												/>
											)}
										/>
									</TranscriptsWrapper>
								</div>
							) : (
								<>
									<div
										className="col-span-8 px-2 overflow-y-hidden"
										// style={{ maxHeight: "26.5em" }}
										style={{ height: "51vh" }}
									>
										<TranscriptsWrapper header="" loading>
											<>
												{[1, 2].map((result) => (
													<div className="p-4 px-8">
														<Skeleton
															loading={true}
															active
															paragraph={{
																rows: 4,
															}}
														></Skeleton>
													</div>
												))}
											</>
										</TranscriptsWrapper>
									</div>
								</>
							)}
						</div>
					) : (
						<div style={{ height: "51vh" }}>
							{emptyText === "Loading..." ? (
								<div className="grid grid-cols-10">
									<div className="col-span-2 border-r-2 p-2 overflow-y-auto" style={{ maxHeight: "30.8em" }}>
										<FilterResultCard loading={true} filterOptions={[]} filters={[]} setFilters={setFilters} />
									</div>
									<div
										className="col-span-8 px-2 overflow-y-hidden"
										// style={{ maxHeight: "26.5em" }}
										style={{ maxHeight: "51vh" }}
									>
										<TranscriptsWrapper header="" loading>
											<>
												{[1, 2].map((result) => (
													<div className="p-4 px-8">
														<Skeleton
															loading={true}
															active
															paragraph={{
																rows: 4,
															}}
														></Skeleton>
													</div>
												))}
											</>
										</TranscriptsWrapper>
									</div>
								</div>
							) : (
								<div className="flex items-center justify-center h-full text-sm text-text-gray-50">
									<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>{emptyText}</span>} />
								</div>
							)}
						</div>
					)}
				</div>
			</>
		</>
	);
}
