import { Button, Checkbox, DatePicker, Input, Modal, Select, Switch } from "antd";
import { icons } from "../../GlobalThemes";
import React, { useState, useContext } from "react";
import * as Messages from "../../Components/Messages";
import moment from "moment";
import AppContext from "../../store";
import { searchQuery } from "./Search";
import { getAllTranscripts } from "./ServiceHelpers/search";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import * as NodeGraphServiceHelper from "./../SearchEngine/ServiceHelpers/nodeGraph";
import * as ExploreServiceHelper from "./../SearchEngine/ServiceHelpers/explore";

export interface filterValues {
	startDate: string;
	endData: string;
	speaker: string;
	searchWord: string;
}

/**
 * Renders the search filters at the top of search page, which includes speaker type, time period and search bar.
 *
 * @remarks - This function is the part of search tab and will be rendered at the top of the page
 *
 * @param showDrawer - The props used to show and hide the drawer for advanced filters
 * @param fetchTranscripts - This function is  used to fetch the transcripts based on the added inputs
 * @param searchWordRequired - This props check search word is present or not
 *
 * @returns the filters at search tab
 */

export function NodeGraphFilters(props: {
	showDrawer: () => void;
	fetchTranscripts: (queries: searchQuery) => void;
	searchWordRequired: boolean;
	searchString?: any;
	setSearchString?: any;
	path?: any;
	setPath?: any;
	searchFilterData: any;
	setSearchFilterData: any;
	onSave: any;
}) {
	// var filterData = {
	//   startDate: "",
	//   endDate: "",
	//   speaker: "",
	//   searchWord: "",
	// };
	var dateFormat = "MMM YYYY";
	// const [searchFilterData, setSearchFilterData] = useState<searchQuery>(filterData);
	const app = useContext(AppContext);
	const [dateRanges, setDateRanges] = React.useState<Array<any>>([]);
	const [loadingState, setloadingState] = React.useState(false);
	const [modalVisible, setModalVisible] = React.useState(false);
	const [fetchedData, setFetchedData] = React.useState<any>(null);

	async function fetchDataForPage() {
		// add s3 parameter value
		// const data: any = await NodeGraphServiceHelper.getNodeJsons(
		//   "kural/json_data/nodegraph_experiment_list.txt"
		// );
		const data: any = await NodeGraphServiceHelper.getExperiments();
		if (data) {
			setFetchedData(data);
			console.log("CHECK HERE", fetchedData);
			console.log(data[0]["experimentId"]);
			app.setExpIdForNodeGraphHere(data[0]["experimentId"]);
		}
	}

	React.useEffect(() => {
		fetchDataForPage();
	}, []);

	const handleFilters = async (filterType: string, filterValue: string | boolean) => {
		var filters: any = props.searchFilterData;

		if (filterType == "startDate" || filterType == "endDate") {
			filters[filterType] = filterValue;
			console.log(filters[filterType]);
		} else {
			filters[filterType] = filterValue;
		}

		props.setSearchFilterData(filters);

		// props.setPath(filterValue);
	};

	// FOR DEMO PURPOSES
	const [startDate, setStartDate] = useState(moment(app.startDate, "DD MMM YYYY"));
	const [endDate, setEndDate] = useState(moment(app.endDate, "DD MMM YYYY"));
	handleFilters("startDate", moment(startDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
	handleFilters("endDate", moment(endDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));

	// async function onSave()
	// {
	//   let data: any;
	//   var filters: any = searchFilterData;

	//   if (filters == undefined) {
	//     data = await ExploreServiceHelper.getExploreCardsData(
	//       1,
	//       null,
	//       null,
	//       null,
	//       null,
	//       null,
	//       null,
	//       null,
	//       null,
	//       null,
	//       null
	//     );
	//   } else {
	//     console.log(filters);
	//     data = await ExploreServiceHelper.getExploreCardsData(
	//       filters.experimentId == "" ? null : filters.experimentId,
	//       filters.startDate == "" ? null : filters.startDate,
	//       filters.endDate == "" ? null : filters.endDate,
	//       filters.speaker !== undefined ? filters.speaker : null,
	//       filters.topics&&filters.topics[0] !== undefined ? filters.topics[0] : null,
	//       filters.totStart !== undefined ? filters.totStart : null,
	//       filters.totEnd !== undefined ? filters.totEnd : null,
	//       filters.callTypeIds&&filters.callTypeIds[0] !== undefined ? filters.callTypeIds[0] : null,
	//       filters.therapyStatusIds&&filters.therapyStatusIds[0] !== undefined ? filters.therapyStatusIds[0] : null,
	//       filters.sentiment&&filters.sentiment[0] !== undefined ? filters.sentiment[0] : null,
	//       filters.emotions&&filters.emotions.length > 0 ? filters.emotions : null
	//     );
	//   }
	//   if (data) {
	//     console.log(data);
	//     // setResponse(data);
	//   }
	// }
	const disableSelectedDates = (current: any) => {
		var c = current;
		//console.log(current);
		return (
			current < moment(app.startDate, dateFormat) ||
			current > moment(app.endDate, dateFormat).endOf("month") ||
			(dateRanges && dateRanges[0] && current.diff(dateRanges[0], "months") > 10) ||
			(dateRanges && dateRanges[1] && dateRanges[1].diff(current, "months") > 10)
		);
	};

	function ModalContent() {
		const [deepSearch, setDeepSearch] = React.useState(props.searchFilterData["deepSearch"]);
		const [testButton1, setTestButton1] = React.useState(props.searchFilterData["testButton1"]);
		const [testButton2, setTestButton2] = React.useState(props.searchFilterData["testButton2"]);
		const [testButton3, setTestButton3] = React.useState(props.searchFilterData["testButton3"]);
		const [testButton4, setTestButton4] = React.useState(props.searchFilterData["testButton4"]);
		const [testButton5, setTestButton5] = React.useState(props.searchFilterData["testButton5"]);

		return (
			<>
				{/* <Checkbox
					onChange={(e) => {
						handleFilters("deepSearch", e.target.checked);
					}}
				>
					Deep Search
				</Checkbox> */}
				<div className="text-2xl text-text-gray-100 font-normal my-4">Search Settings</div>
				<div className="text-base text-text-gray-50 my-6">Please select the search settings.</div>
				<div className="w-full ">
					<div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">Enable Deep Search</div>

						<Switch
							onChange={(checked) => {
								setDeepSearch(checked);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CloseOutlined />
								</div>
							}
							defaultChecked={deepSearch}
						/>
					</div>

					<div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">Test Button 1</div>

						<Switch
							onChange={(checked) => {
								setTestButton1(checked);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CloseOutlined />
								</div>
							}
							defaultChecked={testButton1}
						/>
					</div>

					<div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">Test Button 2</div>

						<Switch
							onChange={(checked) => {
								setTestButton2(checked);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CloseOutlined />
								</div>
							}
							defaultChecked={testButton2}
						/>
					</div>

					<div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">Test Button 3</div>

						<Switch
							onChange={(checked) => {
								setTestButton3(checked);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CloseOutlined />
								</div>
							}
							defaultChecked={testButton3}
						/>
					</div>

					<div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">Test Button 4</div>

						<Switch
							onChange={(checked) => {
								setTestButton4(checked);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CloseOutlined />
								</div>
							}
							defaultChecked={testButton4}
						/>
					</div>
					<div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">Test Button 5</div>

						<Switch
							onChange={(checked) => {
								setTestButton5(checked);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CloseOutlined />
								</div>
							}
							defaultChecked={testButton5}
						/>
					</div>
					<div className="flex justify-end pt-4">
						<button
							type="button"
							className="bg-text-teal text-white text-base border-text-teal border-2 px-4 py-2"
							onClick={() => {
								if (deepSearch !== undefined) handleFilters("deepSearch", deepSearch);
								if (testButton1 !== undefined) handleFilters("testButton1", testButton1);
								if (testButton2 !== undefined) handleFilters("testButton2", testButton2);
								if (testButton3 !== undefined) handleFilters("testButton3", testButton3);
								if (testButton4 !== undefined) handleFilters("testButton4", testButton4);
								if (testButton5 !== undefined) handleFilters("testButton5", testButton5);
								setModalVisible(false);
							}}>
							Save Settings
						</button>
					</div>
				</div>
			</>
		);
	}

	const onClickSearch = () => {
		Messages.loading("Fetching Clusters", "updatable");
		props.fetchTranscripts(props.searchFilterData);
		console.log(props.searchFilterData);
		props.setSearchString(props.searchFilterData.searchWord);
		props.onSave(props.searchFilterData);
	};
	return (
		<>
			{fetchedData && (
				<div>
					<Modal
						// title="Search Settings"
						visible={modalVisible}
						onOk={() => setModalVisible(false)}
						onCancel={() => setModalVisible(false)}
						footer={null}>
						<ModalContent />
					</Modal>
					<div className="flex justify-between items-center pb-4">
						<div className="flex align-middle">
							{/* <div className="flex items-center mr-8">
                <span className="mr-2">Speaker Type:</span>
                <Select
                  clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
                  className="w-32"
                  placeholder="Speaker Type"
                  allowClear
                  onChange={(value: string) => {
                    handleFilters("speaker", value);
                  }}
                >
                  <Select.Option value="Agent">Agent</Select.Option>
                  <Select.Option value="Member">Member</Select.Option>
                </Select>
              </div> */}
							<div className="flex items-center mr-8">
								<span className="mr-2">Experiment</span>
								<Select
									clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
									className="w-32"
									placeholder="Experiment"
									allowClear
									defaultActiveFirstOption={true}
									// defaultValue={app.expIdForNodeGraph}
									defaultValue={fetchedData[0]["experimentName"]}
									onChange={(value: string) => {
										handleFilters("experimentId", value);
									}}>
									{fetchedData.map((d: any) => (
										<Select.Option value={d["experimentId"]}>{d["experimentName"]}</Select.Option>
									))}
									{/* <Select.Option value="York Good Food">York Good Food</Select.Option>
              <Select.Option value="Symptoms pml Weakness">Symptoms pml Weakness</Select.Option>
              <Select.Option value="Diarrhea Constantly">Diarrhea Constantly</Select.Option> */}
								</Select>
							</div>
							<div className="flex items-center mr-8">
								<span className="mr-2">Time Period:</span>
								<DatePicker.RangePicker
									value={[startDate, endDate]}
									clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
									disabledDate={disableSelectedDates}
									defaultPickerValue={[moment(app.startDate, "DD MMM YYYY"), moment(app.endDate, "DD MMM YYYY")]}
									format={dateFormat}
									separator={icons.find((l) => l.id === "Swap")?.icon}
									onChange={(e: any) => {
										handleFilters("startDate", moment(e[0]).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
										handleFilters("endDate", moment(e[1]).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
									}}
									onCalendarChange={(value: any) => {
										setDateRanges(value);
									}}
									className=""
									picker="month"
								/>
							</div>
							{/* <div className={props.searchWordRequired ? "" : "flex items-center"}>
                {props.searchWordRequired ? (
                  <div style={{ marginTop: "-20px" }}>
                    <p className="text-sm text-text-red">Keyword is Required</p>
                  </div>
                ) : null}
                <Input
                  className="flex input-search lg:w-96 md:w-48"
                  placeholder="Search for transcript dialogues with keywords and tags"
                  onChange={(e) => {
                    handleFilters("searchWord", e.target.value);
                  }}
                  onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                      props.fetchTranscripts(searchFilterData);
                      props.setSearchString(searchFilterData.searchWord);
                    }
                  }}
                />
              </div> */}
							<div className="flex items-center ml-5">
								<Button type="primary" loading={loadingState} className="green-button flex items-center" onClick={() => onClickSearch()}>
									Search
								</Button>
							</div>

							{/* <div>
								<div
									className="flex items-center cursor-pointer w-44 ml-6 mt-1"
									onClick={() => {
										props.showDrawer();
									}}>
									<div className="mr-2">{icons.find((l) => l.id === "FilterGreen")?.icon}</div>
									<div style={{ color: "#27A6A4" }} className="flex text-base">
										Advanced Filters
									</div>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			)}
		</>
	);
}
