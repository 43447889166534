import React from "react";
import LandingBanner from "./Components/LandingBanner";
import LandingBody from "./Components/LandingBody";
import AppContext from "../../store";
export default function LandingPage() {
	const context = React.useContext(AppContext);

	return (
		<>
			<LandingBanner />
			<LandingBody />
		</>
	);
}
