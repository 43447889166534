import { Route, Routes } from "react-router-dom";
import SubHeaderComponent from "../../Components/SubHeaderComponent";
import SubNavigation from "../../Components/SubNavigation";
import { Search } from "./Search";
import { StoriesView } from "./Stories";
import { SearchEngineTabs } from "./data";
import Explore from "./Explore";
import PatientJourney from "./PatientJourney";
import PatientDetails from "./PatientDetails";
import { NodeGraph } from "./NodeGraph";

/**
 * This page is the main search engine page, which also contains the routes for search and story tab
 *
 * @returns the navigation to open search and stories tab
 */

export default function SearchEngine() {
  const components = [
    <NodeGraph />,
    <Search />,
    <StoriesView />,
    <PatientJourney />,
    // <Explore />,
    <PatientDetails />,
  ];
  //  [ {
  //     name: "Search",
  //     component: <Search/>
  //   },
  //   {
  //     name: "NodeGraph",
  //     component: <NodeGraph/>
  //   },
  //   {
  //     name: "StoriesView",
  //     component: <StoriesView/>
  //   },
  //   {
  //     name: "PatientDetails",
  //     component: <PatientDetails/>
  //   },
  //   ]

  const tabs = SearchEngineTabs();
  return (
    <>
      <SubNavigation tabs={tabs} />
      <div className="">
        <Routes>
          {tabs.map((subTab, index) => (
            <Route
              key={index}
              path={index === 0 ? "/" : subTab.subTab.split(" ").join("_") + "/*"}
              element={components[index]}
            />
          ))}
        </Routes>
      </div>
    </>
  );
}
