import React from "react";
import { DatePicker, Radio, Select, Skeleton, Slider, Space } from "antd";
import moment from "moment";
import AppContext from "../../store";
import { icons } from "../../GlobalThemes";

/**
 * This function is used to render the filters in tag form
 *
 * @param filterOptions - This props represent the array of filters with all the options
 * @param filters - This props represents the array of filter with name and value
 * @param setFilters - Ths props is used to change the filters on the page
 *
 * @returns the filters in card form
 */

const marks: { [id: number]: string } = {
	0: "0",
	16.6: "30",
	33.3: "60",
	50: "90",
	66.6: "180",
	83.3: "360",
	100: "360+",
	// 100: {
	//   style: {
	// 	color: '#f50',
	//   },
	//   label: <strong>100°C</strong>,
	// },
};
export default function FilterResultCard(props: {
	filterOptions: {
		filterName: string;
		type: string;
		options: string[];
	}[];
	filters: {
		filter: string;
		value: string;
	}[];
	setFilters: React.Dispatch<
		React.SetStateAction<
			{
				filter: string;
				value: string;
			}[]
		>
	>;
	loading?: boolean;
}) {
	const context = React.useContext(AppContext);
	const [radioValue, setRadioValue] = React.useState("All");
	const [selectValue, setSelectValue] = React.useState("");
	// const [dateSelected, setDateSelected] = React.useState({
	// 	startDate: "",
	// 	endDate: "",
	// });
	const dateFormat = "MMM YYYY";
	const { RangePicker } = DatePicker;
	const [dateRanges, setDateRanges] = React.useState<Array<any>>([]);
	const [totRange, setTotRange] = React.useState<[number, number]>();
	const disableSelectedDates = (current: any) => {
		var c = current;
		//console.log(current);
		return (
			current < moment(context.startDate, dateFormat) ||
			current > moment(context.endDate, dateFormat).endOf("month") ||
			(dateRanges &&
				dateRanges[0] &&
				current.diff(dateRanges[0], "months") > 10) ||
			(dateRanges &&
				dateRanges[1] &&
				dateRanges[1].diff(current, "months") > 10)
		);
	};
	const { Option } = Select;
	function handleSubmit() {
		const obj: {
			filter: string;
			value: string | any;
		}[] = [];
		props.filterOptions.map((filter) => {
			if (filter.type === "radio")
				obj.push({
					filter: filter.filterName,
					value: radioValue,
				});
			else if (filter.type === "dropdown") {
				obj.push({
					filter: filter.filterName,
					value: selectValue,
				});
			} else if (filter.type === "slider") {
				obj.push({
					filter: "totStart",
					// value: moment(dateRanges[0]).format(),
					value: totRange ? marks[totRange[0]] : undefined,
				});
				obj.push({
					filter: "totEnd",
					// value: moment(dateRanges[1]).format(),
					value: totRange ? marks[totRange[1]] : undefined,
				});
			} else {
				obj.push({
					filter: "Start Date",
					// value: moment(dateRanges[0]).format(),
					value:
						dateRanges && dateRanges[0] !== undefined
							? moment(dateRanges[0])
									.startOf("month")
									.add(1, "days")
							: "",
				});
				obj.push({
					filter: "End Date",
					// value: moment(dateRanges[1]).format(),
					value:
						dateRanges && dateRanges[1] !== undefined
							? moment(dateRanges[1]).endOf("month")
							: "",
				});
			}
		});
		props.setFilters(obj);
	}
	return (
		<>
			{!props.loading ? (
				<div>
					<div className="flex items-center justify-between mr-2 mt-2 border-b">
						<div className="text-text-gray-100 font-semibold text-base py-2">
							Filter Results
						</div>
						<div
							className="text-sm text-blue-500 cursor-pointer"
							onClick={() => {
								handleSubmit();
							}}
						>
							Apply Filter
						</div>
					</div>
					{props.filterOptions.map((filter) => {
						if (filter.type === "radio") {
							return (
								<>
									<div className="py-2">
										<div className="text-text-gray-90 font-semibold text-sm pb-1">
											{filter.filterName}
										</div>
										<div>
											<Radio.Group
												onChange={(e) => {
													setRadioValue(
														e.target.value
													);
												}}
												value={radioValue}
											>
												<Space
													direction="vertical"
													size="small"
												>
													{filter.options.map(
														(option) => (
															<Radio
																className="radio-custom"
																value={option}
															>
																{option}
															</Radio>
														)
													)}
												</Space>
											</Radio.Group>
										</div>
									</div>
								</>
							);
						} else if (filter.type === "dropdown") {
							return (
								<>
									<div className="py-2">
										<div className="text-text-gray-90 font-semibold text-sm pb-2">
											{filter.filterName}
										</div>
										<div>
											<Select
												allowClear
												clearIcon={
													icons.find(
														(f) =>
															f.id === "FormClear"
													)?.icon
												}
												placeholder={`Select ${filter.filterName}`}
												style={{ width: "95%" }}
												onChange={(value: string) => {
													setSelectValue(value);
												}}
											>
												{filter.options.map(
													(option) => (
														<Option value={option}>
															{option}
														</Option>
													)
												)}
											</Select>
										</div>
									</div>
								</>
							);
						} else if (filter.type === "date-range") {
							return (
								<>
									<div className="py-2">
										<div className="text-text-gray-90 font-semibold text-sm pb-2">
											{filter.filterName}
										</div>
										<div>
											<RangePicker
												clearIcon={
													icons.find(
														(f) =>
															f.id === "FormClear"
													)?.icon
												}
												picker="month"
												defaultPickerValue={[
													moment(
														context.startDate,
														"DD MMM YYYY"
													),
													moment(
														context.endDate,
														"DD MMM YYYY"
													),
												]}
												separator={
													icons.find(
														(l) => l.id === "Swap"
													)?.icon
												}
												disabledDate={
													disableSelectedDates
												}
												format={dateFormat}
												onCalendarChange={(
													value: any
												) => {
													setDateRanges(value);
												}}
												style={{ width: "95%" }}
											/>
										</div>
									</div>
								</>
							);
						} else if (filter.type === "slider") {
							return (
								<>
									<div className="py-2">
										<div className="text-text-gray-90 font-semibold text-sm pb-2">
											{filter.filterName}
										</div>
										<div className="px-1">
											<Slider
												range
												marks={marks}
												step={null}
												// defaultValue={[0, 0]}
												min={0}
												max={100}
												tooltipVisible={false}
												value={totRange}
												onChange={(value) =>
													setTotRange(value)
												}
											/>
										</div>
									</div>
								</>
							);
						}
					})}
				</div>
			) : (
				<>
					<div>
						<div className="flex items-center justify-between mr-2 mt-2 border-b">
							<div className="text-text-gray-100 font-semibold text-base py-2">
								Filter Results
							</div>
							<div
								className="text-sm text-blue-500 cursor-pointer"
								onClick={() => {
									handleSubmit();
								}}
							>
								Apply Filter
							</div>
						</div>
						<div className="py-4 pr-2">
							<div>
								<Skeleton active paragraph={{ rows: 3 }} />
							</div>
							<div className="py-6">
								<Skeleton.Button
									active
									size="default"
									block={true}
								/>
							</div>
							<div className="py-3">
								<Skeleton.Button
									active
									size="default"
									block={true}
								/>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}
