import { request } from "./../App";

export const getData = async () => {
	const fetchUrl = "Home/GetDatesForHomePage";
	const data = await request
		.get(fetchUrl, { withCredentials: true })
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
	.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	return data;
};
