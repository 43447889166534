import { searchQuery } from "../Search";
import * as SearchService from "./../../../services/search";

export const getAllTranscripts: (
	searchQuery: searchQuery
) => Promise<unknown> = async (searchQuery: searchQuery) => {
	const data = await SearchService.getAllTranscripts2(searchQuery);
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};

export const getFullTranscript: (
	transcript: string
) => Promise<unknown> = async (s3path: string) => {
	const data = await SearchService.getFullTranscript(s3path);
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};

export const addToStory: (transcriptData: any) => Promise<unknown> = async (
	transcriptData
) => {
	const data = await SearchService.addToStory(transcriptData);
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};

export const getFilters: () => Promise<unknown> = async () => {
	const data = await SearchService.getFilters();
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};

export const getNodeGraphAllTranscript: (
	searchQuery: searchQuery
) => Promise<unknown> = async (searchQuery: searchQuery) => {
	const data = await SearchService.getNodeTranscripts("searchQuery");
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};
