import * as SearchService from "./../../../services/search";
import { searchQuery } from "../Search";
export const getAllTranscript: (
	searchQuery: searchQuery
) => Promise<unknown> = async (searchQuery: searchQuery) => {
	const data = await SearchService.getAllTranscripts2(searchQuery);
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};
export const addToStory: (
	transcriptData: any,
	storyId: string
) => Promise<unknown> = async (transcriptData, storyId) => {
	const data = await SearchService.addToStory(transcriptData, storyId);
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};
