import { Tooltip } from "antd";
import moment from "moment";
import { icons } from "../../GlobalThemes";
import { FilterActiveInterface, FilterInterface } from "../../Pages/Dashboard";

/**
 * Renders the filters to be applied as a drawer.
 *
 * @param filters - The props is an array of the filters
 * @param setFilters - The function is used to apply the filters
 *
 * @returns the advanced filters as a drawer
 */

export function FilterCard(props: {
  filters: FilterInterface;
  setFilters?: React.Dispatch<React.SetStateAction<FilterInterface>>;
  filterActiveStatus?: FilterActiveInterface;
}) {
  const filterCards: JSX.Element[] = [];
  function getQuarter(date: string | number | string[] | number[] | undefined) {
    const quarter = moment(date, "MMM YYYY").quarter();
    const year = moment(date, "MMM YYYY").year();
    console.log("Quarter : ", quarter);
    return year + "-Q" + quarter;
  }
  return (
    <>
      {Object.entries(props.filters).map(([key, value], index) => {
        if (!Array.isArray(value) && value) {
          if (
            key !== "Start_Date" &&
            key !== "End_Date" &&
            key !== "totStart" &&
            key !== "totEnd"
          ) {
            return (
              <div
                key={key + "" + index}
                className="text-text-gray-90 bg-filterCard pl-4 pr-2 py-1 mx-2 border border-filterCard-border"
              >
                <span
                  className={
                    props.filterActiveStatus === undefined
                      ? "text-sm text-text-gray-90 font-normal"
                      : props.filterActiveStatus && props.filterActiveStatus[key]
                      ? "text-sm text-text-gray-90 font-normal"
                      : "text-sm text-text-gray-30 font-normal line-through"
                  }
                >
                  {key.split("_").join(" ")}: {value}{" "}
                  {props.filterActiveStatus !== undefined &&
                  props.filterActiveStatus[key] === true ? (
                    <span
                      title="Remove Filter"
                      className="cursor-pointer"
                      onClick={() => {
                        if (props.setFilters)
                          props.setFilters({
                            ...props.filters,
                            [key]: undefined,
                          });
                      }}
                    >
                      {icons.find((l) => l.id === "Close")?.icon}
                    </span>
                  ) : null}
                </span>
              </div>
            );
          }
        } else if (Array.isArray(value) && value.length > 0) {
          return (
            <div
              key={key + "" + index}
              className="text-text-gray-90 bg-filterCard pl-4 pr-2 py-1 mx-2 border border-filterCard-border"
            >
              <span className="text-sm text-text-gray-90 font-normal">
                {key.split("_").join(" ")}:{" "}
                {value.map((v, index) => {
                  if (index === 0) return <>{`${v}`}</>;
                  else if (index < 2) return <>{`, ${v}`}</>;
                })}
                {value.length > 2 && (
                  <Tooltip
                    title={
                      <>
                        {value.map((v, index) => {
                          if (index >= 2) return <p>{v}</p>;
                        })}
                      </>
                    }
                  >
                    <span className="text-text-gray-50 text-sm mr-2">
                      <i>{` +${value.length - 2} more`}</i>
                    </span>
                  </Tooltip>
                )}
                {props.filterActiveStatus !== undefined &&
                props.filterActiveStatus[key] === true ? (
                  <span
                    title="Remove Filter"
                    className="cursor-pointer"
                    onClick={() => {
                      console.log("Keys", key);
                      if (props.setFilters) {
                        if (key === "Emotions") {
                          props.setFilters({
                            ...props.filters,
                            Emotions: new Array<string>(),
                          });
                        } else {
                          props.setFilters({
                            ...props.filters,
                            [key]: undefined,
                          });
                        }
                      }
                    }}
                  >
                    {icons.find((l) => l.id === "Close")?.icon}
                  </span>
                ) : null}
              </span>
            </div>
          );
        }
      })}
      {Object.entries(props.filters).find(
        ([key, value], index) => key === "Start_Date" && value
      ) && (
        <>
          <div
            key={"Selection Period"}
            className="text-text-gray-90 bg-filterCard pl-4 pr-2 py-1 mx-2 border border-filterCard-border"
          >
            <span className="text-sm text-text-gray-90 font-normal">
              Selection Period:{" "}
              {props.filters["Timeframe_Display"] === "Quarter" ? (
                <>
                  {getQuarter(props.filters["Start_Date"]) + " "}
                  {icons.find((l) => l.id === "Swap")?.icon}
                  {" " + getQuarter(props.filters["End_Date"])}
                  {/* {getQuarter(props.filters["End_Date"]) +
										" -> " +
										getQuarter(
											props.filters["End_Date"]
										)}{" "} */}
                </>
              ) : (
                <>
                  {props.filters["Start_Date"] + " "}
                  {icons.find((l) => l.id === "Swap")?.icon}
                  {" " + props.filters["End_Date"]}
                  {/* {props.filters["Start_Date"] +
										" -> " +
										props.filters["End_Date"]}{" "} */}
                </>
              )}
              <span
                title="Remove Filter"
                className="cursor-pointer"
                onClick={() => {
                  if (props.setFilters)
                    props.setFilters({
                      ...props.filters,
                      ["Start_Date"]: "",
                      ["End_Date"]: "",
                    });
                }}
              >
                {icons.find((l) => l.id === "Close")?.icon}
              </span>
            </span>
          </div>
        </>
      )}
      {Object.entries(props.filters).find(([key, value], index) => key === "totStart" && value) && (
        <>
          <div
            key={"Selection Period"}
            className="text-text-gray-90 bg-filterCard pl-4 pr-2 py-1 mx-2 border border-filterCard-border"
          >
            <span className="text-sm text-text-gray-90 font-normal">
              {`TOT Selected: ${props.filters.totStart} - ${props.filters.totEnd}`}

              <span
                title="Remove Filter"
                className="cursor-pointer"
                onClick={() => {
                  if (props.setFilters)
                    props.setFilters({
                      ...props.filters,
                      ["totStart"]: "",
                      ["totEnd"]: "",
                    });
                }}
              >
                {icons.find((l) => l.id === "Close")?.icon}
              </span>
            </span>
          </div>
        </>
      )}
    </>
  );
}
