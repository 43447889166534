import { Modal } from "antd";
import React from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import DeleteAlert from "../../../Components/DeleteAlert";
import SubHeaderComponent from "../../../Components/SubHeaderComponent";
import SubNavigation from "../../../Components/SubNavigation";
import { icons } from "../../../GlobalThemes";
import AnnotatedSelection from "./AnnotatedSelections";
import AnnotatedSelectionLoading from "./AnnotatedSelections/loading";
import FindSimilarConversations from "./FindSimilarConversations";
import Search from "./Search";
import StoriesSearch from "./Search/newIndex";
import * as AnnotatedSelectionsHelper from "../ServiceHelpers/annotatedSelections";
import * as StoriesServiceHelper from "../ServiceHelpers/stories";
import * as Messages from "../../../Components/Messages";
import { publishLimit } from "../data";
import { ExportToExcel, exportDataInterface } from "../ExportToExcel";
import moment from "moment";
// const DUMMY_DATA = [
// 	{
// 		id: 100,
// 		storyName: "Query about Alcohol Consumption",
// 		status: "In Draft",
// 		numberOfHighlightedTranscripts: 0,
// 		headerInfo: [
// 			"Annotated Selection",
// 			"Date Added on",
// 			"Source",
// 			"Tags",
// 			"Status",
// 		],
// 		filteredColumns: ["Source", "Tags", "Status"],
// 		annotatedSelections: [
// 			{
// 				id: 1,
// 				"Annotated Selection": "Transcript from 12 June 2020 [5:33:23]",
// 				"Date Added on": "12 Nov 2021",
// 				Source: "Search Based",
// 				Tags: [
// 					"Inquiry",
// 					"Concern",
// 					"Covid",
// 					"Question",
// 					"Issues",
// 					"Medication Issues",
// 				],
// 				Status: "Pending",
// 				Description: {
// 					tags: ["Inbound", "Follow Up Call", "Nervous"],
// 					dialogueNo: 33,
// 					dialogueAppearedAt: 0.2,
// 					dialogueLength: 1.2,
// 					transcriptLength: 5,
// 					transcripts: [
// 						{
// 							dialogueNo: 32,
// 							speaker: "Agent",
// 							text: "Are there any other questions I can answer for you?",
// 						},
// 						{
// 							dialogueNo: 33,
// 							speaker: "Patient",
// 							text: "So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
// 						},
// 						{
// 							dialogueNo: 34,
// 							speaker: "Agent",
// 							text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
// 						},
// 					],
// 				},
// 			},
// 			{
// 				id: 2,
// 				"Annotated Selection": "Transcript from 10 May 2020 [12:48:35]",
// 				"Date Added on": "03 Nov 2021",
// 				Source: "System Recommended",
// 				Tags: ["Concern", "Risk", "Covid", "Question", "Issues"],
// 				Status: "Pending",
// 				Description: {
// 					tags: ["Inbound", "Follow Up Call", "Nervous"],
// 					dialogueAppearedAt: 0.2,
// 					dialogueLength: 1.2,
// 					transcriptLength: 5,
// 					dialogueNo: 33,
// 					transcripts: [
// 						{
// 							dialogueNo: 32,
// 							speaker: "Agent",
// 							text: "Are there any other questions I can answer for you?",
// 						},
// 						{
// 							dialogueNo: 33,
// 							speaker: "Patient",
// 							text: "So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
// 						},
// 						{
// 							dialogueNo: 34,
// 							speaker: "Agent",
// 							text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
// 						},
// 					],
// 				},
// 			},
// 			{
// 				id: 3,
// 				"Annotated Selection": "Transcript from 04 Jan 2020 [01:03:12]",
// 				"Date Added on": "01 Nov 2021",
// 				Source: "Search Based",
// 				Tags: ["Concern", "Covid", "Question"],
// 				Status: "Complete",
// 				Description: {
// 					tags: ["Inbound", "Follow Up Call", "Nervous"],
// 					dialogueAppearedAt: 0.2,
// 					dialogueLength: 1.2,
// 					transcriptLength: 5,
// 					dialogueNo: 33,
// 					transcripts: [
// 						{
// 							dialogueNo: 32,
// 							speaker: "Agent",
// 							text: "Are there any other questions I can answer for you?",
// 						},
// 						{
// 							dialogueNo: 33,
// 							speaker: "Patient",
// 							text: "So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
// 						},
// 						{
// 							dialogueNo: 34,
// 							speaker: "Agent",
// 							text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
// 						},
// 					],
// 				},
// 			},
// 			{
// 				id: 4,
// 				"Annotated Selection": "Transcript from 01 Jan 2020 [01:00:45]",
// 				"Date Added on": "02 Nov 2021",
// 				Source: "Search Based",
// 				Tags: ["Medication Issues", "Concern", "Covid", "Question"],
// 				Status: "Complete",
// 				Description: {
// 					tags: ["Inbound", "Follow Up Call", "Nervous"],
// 					dialogueAppearedAt: 0.2,
// 					dialogueLength: 1.2,
// 					transcriptLength: 5,
// 					dialogueNo: 33,
// 					transcripts: [
// 						{
// 							dialogueNo: 32,
// 							speaker: "Agent",
// 							text: "Are there any other questions I can answer for you?",
// 						},
// 						{
// 							dialogueNo: 33,
// 							speaker: "Patient",
// 							text: "So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
// 						},
// 						{
// 							dialogueNo: 34,
// 							speaker: "Agent",
// 							text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
// 						},
// 					],
// 				},
// 			},
// 		],
// 	},
// ];

/**
 * Renders the extra SubHeader Components inside the SubHeader.
 *
 * @param id - Id of the Story inside which the Subheader is getting rendered.
 * @param handleDelete - Callback function for handling deletion of story.
 * @param handlePublish - Callback function for handling Publishing of a story.
 * @param numberOfHiglightedTranscripts - Number of Transcripts Highlighted.
 * @param status - Status of the Story - In Draft, Publishing or Published.
 *
 * @returns Stories View.
 */
function SubHeaderExtra(props: {
  id: string;
  handleDelete: () => void;
  handlePublish: () => void;
  numberOfHiglightedTranscripts: number;
  status: string;
  exportData: exportDataInterface;
}) {
  return (
    <div className="flex justify-start items-center gap-6">
      {/* <div className="text-text-gray-70 flex">
				<div className="font-semibold text-sm">Status :</div>
				<div className="flex gap-1 ml-1 text-sm">
					{icons.find((l) => l.id === props.status)?.icon}
					{props.status}
				</div>
			</div> */}
      <div
        className="flex items-center text-text-gray-70 text-sm cursor-pointer"
        onClick={() => {
          props.handleDelete();
        }}
      >
        {icons.find((l) => l.id === "Delete")?.icon} Delete Story
      </div>
      <ExportToExcel exportData={props.exportData} />

      <div>
        <button
          className={`${props.numberOfHiglightedTranscripts >= publishLimit
              ? "bg-text-teal text-white cursor-pointer border-text-teal border-2"
              : "bg-body text-text-gray-60 cursor-not-allowed border-2"
            } px-2 py-1 font-normal`}
          onClick={() => {
            if (props.numberOfHiglightedTranscripts >= publishLimit) props.handlePublish();
          }}
        >
          {icons.find((l) => l.id === "Publish")?.icon}
          {props.status === "In Draft" ? <>Publish Story</> : <>Republish Story</>}
        </button>
      </div>
      {/* <div className="flex items-center gap-4 border-2 px-2 py-1 bg-body-green">
				<div className="text-text-gray-70 flex">
					<div className="font-semibold text-sm">Status :</div>
					<div className="flex gap-1 ml-1 text-sm">
						{icons.find((l) => l.id === props.status)?.icon}
						{props.status}
					</div>
				</div>
				<div
					className={
						props.numberOfHiglightedTranscripts >= publishLimit
							? "text-text-teal text-base font-semibold cursor-pointer"
							: "text-text-gray-40 text-base font-semibold cursor-not-allowed"
					}
					onClick={() => {
						if (props.numberOfHiglightedTranscripts >= publishLimit)
							props.handlePublish();
					}}
				>
					{icons.find((l) => l.id === "Publish")?.icon}
					{props.status === "In Draft" ? (
						<>Publish Story</>
					) : (
						<>Republish Story</>
					)}
				</div>
			</div> */}

      {/* <button
				className={
					props.numberOfHiglightedTranscripts < 2
						? "px-4 py-1 bg-text-gray text-text-gray-30 border border-border"
						: "px-4 py-1 bg-text-teal text-text-white border border-text-teal"
				}
			>
				Publish Story
			</button> */}
    </div>
  );
}
/**
 * Renders the SubHeader Component inside the Stories Tab and specifies the routes inside the Stories Tab.
 *
 * @returns SubHeader Component and Routes inside Stories Tab.
 */
export default function StoryMode() {
  const { storyId } = useParams();
  const [fetchedData, setFetchedData] = React.useState<
    {
      id: string;
      storyName: string;
      status: string;
      numberOfHighlightedTranscripts: number;
      headerInfo: string[];
      filteredColumns: string[];
      annotatedSelections: {
        id: number;
        elasticSearchId: string;
        "Annotated Selection": string;
        "Date Added on": string;
        Source: string;
        Tags: string[];
        Status: string;
        therapyStatus: string;
        ToT: number;
        highlightedString: string;
        Description: {
          tags: string[];
          dialogueAppearedAt: number;
          dialogueLength: number;
          transcriptLength: number;
          dialogueNo: number;
          transcripts: {
            dialogueNo: number;
            speaker: string;
            text: string;
          }[];
        };
      }[];
    }[]
  >([]);
  const [visible, setVisible] = React.useState(false);
  const [numberOfHighlightedTranscripts, setNumberOfHighlightedTranscripts] = React.useState(0);
  const [exportData, setExportData] = React.useState<any>();
  // const [filters, setFilters] = React.useState<
  // 	{
  // 		columnName: string;
  // 		options: string[];
  // 	}[]
  // >([]);
  const [updated, setUpdated] = React.useState(moment().toString());
  const subTabs = [
    {
      subTab: "Annotated Selections",
      subSubTabs: [],
    },
    {
      subTab: "Search",
      subSubTabs: [],
    },
    {
      subTab: "Find Similar Conversations",
      subSubTabs: [],
    },
  ];

  function handleDelete() {
    setVisible(true);
    // navigate("/search/Stories");
  }
  function addHighlightedTranscript() {
    // setNumberOfHighlightedTranscripts(numberOfHighlightedTranscripts + 1);
    // fetchedData[0].annotatedSelection.find(sel=>sel.))
  }
  function removeHighlightedTranscript() {
    // setNumberOfHighlightedTranscripts(numberOfHighlightedTranscripts - 1);
  }
  function updateTranscript(
    id: number,
    editData: {
      id: number;
      highlightText: string;
      Tags: string[];
      highlightedString: string;
    }
  ) {
    const temp = [...fetchedData];
    const idx = temp[0].annotatedSelections.findIndex((l) => l.id === id);

    if (idx !== -1) {
      temp[0].annotatedSelections[idx].Status = "Updating";
      temp[0].annotatedSelections[idx].Tags = ["Updating"];
    }

    setFetchedData(temp);
    // createFilters();
    setUpdated(moment().toString());
    editHiglights(id, editData);
  }

  const navigate = useNavigate();
  function updateStatusCount(data: any) {
    let count = 0;
    console.log("Data", data);
    data.annotatedSelections.map((d: any) => {
      if (d.Status === "Complete") count++;
    });
    setNumberOfHighlightedTranscripts(count);
  }
  function handleDeleteStoryApi() {
    setVisible(false);
    if (storyId) deleteStory(storyId);
  }
  function handlePublishStoryApi() {
    setVisible(false);
    if (storyId) publishStory(storyId);
  }
  function addTranscript(transcriptData: any) {
    // const obj = {
    // 	id: transcriptData.id,
    // 	"Annotated Selection": transcriptData.name,
    // 	"Date Added on": transcript.call_date,
    // 	"Status":"Pending"
    // }
    // const temp = [...fetchedData];
    // temp[0].annotatedSelections.push(transcriptData);
    // setFetchedData(temp);
    fetchData();
  }
  // function createFilters() {
  // 	console.log("Crete filters");
  // 	const dataTemp = [...fetchedData[0].annotatedSelections];
  // 	const filtersTemp: {
  // 		columnName: string;
  // 		options: string[];
  // 	}[] = [
  // 		{ columnName: "Source", options: [] },
  // 		{ columnName: "Tags", options: [] },
  // 		{ columnName: "Status", options: [] },
  // 	];

  // 	fetchedData[0].filteredColumns.map((filteredCol) => {
  // 		dataTemp.map((d: any) => {
  // 			if (filteredCol === "Tags") {
  // 				const tagArray = d["Tags"];
  // 				tagArray.map((tag: string) => {
  // 					if (
  // 						filtersTemp
  // 							.find((f) => f.columnName === "Tags")
  // 							?.options.indexOf(tag) === -1
  // 					)
  // 						filtersTemp
  // 							.find((f) => f.columnName === "Tags")
  // 							?.options.push(tag);
  // 				});
  // 			} else {
  // 				if (
  // 					filtersTemp
  // 						.find((f) => f.columnName === filteredCol)
  // 						?.options.indexOf(d[filteredCol]) === -1
  // 				)
  // 					filtersTemp
  // 						.find((f) => f.columnName === filteredCol)
  // 						?.options.push(d[filteredCol]);
  // 			}
  // 		});
  // 	});
  // 	// console.log("FFF", filtersTemp);
  // 	setFilters(filtersTemp);
  // }
  // function setFilter(
  // 	filtersApplied: {
  // 		[id: string]: string[];
  // 	},
  // 	columnName: string,
  // 	filterValue: string[]
  // ) {
  // 	const newFilter = { ...filtersApplied, [columnName]: filterValue };
  // 	let tempData = [...props.data];
  // 	for (let key in newFilter) {
  // 		let temp: any[] = [];
  // 		if (newFilter[key].length > 0) {
  // 			newFilter[key].map((d) => {
  // 				temp = temp.concat(
  // 					temp
  // 						.concat(filterData(tempData, key, d))
  // 						.filter(
  // 							(item) =>
  // 								!JSON.stringify(temp).includes(
  // 									JSON.stringify(item)
  // 								)
  // 						)
  // 				);
  // 			});
  // 			tempData = [...temp];
  // 		}
  // 	}

  // 	filteredData.current = [...tempData];
  // 	setFiltersApplied(newFilter);
  // 	setData(tempData);
  // }
  async function editHiglights(
    id: number,
    editData: {
      id: number;
      highlightText: string;
      Tags: string[];
      highlightedString: string;
    }
  ) {
    const key = "updatable";
    Messages.loading("Updating Story", key);
    const data: any = await AnnotatedSelectionsHelper.editHighlights(editData);

    if (data) {
      const temp = [...fetchedData];
      const idx = temp[0].annotatedSelections.findIndex((l) => l.id === id);

      if (idx !== -1) {
        temp[0].annotatedSelections[idx].Tags = data.Tags;
        temp[0].annotatedSelections[idx].Status = data.Status;
        temp[0].annotatedSelections[idx].highlightedString = data.highlightedString;
      }
      setFetchedData(temp);
      // createFilters();
      setUpdated(moment().toString());
      updateStatusCount(temp[0]);
      Messages.success("Story Updated Sucessfully", key);
    } else {
      Messages.error("Failed!", key);
    }
  }
  async function deleteStory(storyId: string) {
    const key = "updatable";
    Messages.loading("Deleting Story", key);
    const data: any = await StoriesServiceHelper.deleteStory(storyId);
    if (data === 200) {
      Messages.success("Story Deleted Sucessfully", key);
      navigate("/search/Stories");
    } else {
      Messages.error("Failed!", key);
    }
  }
  async function deleteTranscript(id: number) {
    const key = "updatable";
    Messages.loading("Deleting Transcript", key);
    // console.log("Checkkk", fetchedData[0].);

    const elasticSearchId = fetchedData[0].annotatedSelections.find(
      (t) => t.id === id
    )?.elasticSearchId;
    const data: any = await AnnotatedSelectionsHelper.deleteAnnotation(
      id,
      storyId || "",
      elasticSearchId || "0"
    );
    if (data) {
      const temp = [...fetchedData];
      const idx = temp[0].annotatedSelections.findIndex((d) => d.id === id);
      temp[0].annotatedSelections.splice(idx, 1);
      setFetchedData(temp);
      // createFilters();
      setUpdated(moment().toString());
      updateStatusCount(temp[0]);
      Messages.success("Transcript Deleted Sucessfully", key);
    } else {
      Messages.error("Failed!", key);
    }
  }
  async function publishStory(storyId: string) {
    console.log("Publishing");
    const key = "updatable";
    Messages.loading("Publishing", key);
    const data: any = await StoriesServiceHelper.publishStory(storyId);
    if (data) {
      const temp = [...fetchedData];
      temp[0].status = data;
      setFetchedData(temp);
      Messages.success("Story is getting published.", key);
    } else {
      Messages.error("Failed!", key);
    }
  }
  async function fetchData() {
    const data: any = await AnnotatedSelectionsHelper.getAnnotations(String(storyId));

    if (data) {
      const headerInfo = [
        "Annotated Selection",
        "Date Added on",
        // "Source",
        // "Therapy Status",
        // "ToT",
        "Tags",
        "Status",
      ];
      const exportData: exportDataInterface = {
        storyId: data.id,
        storyName: data.storyName,
        storyStatus: data.status,
        transcripts: data.annotatedSelections,
      };
      const filteredColumns = ["Source", "Tags", "Status", "Therapy Status", "ToT"];
      data.headerInfo = headerInfo;
      data.filteredColumns = filteredColumns;
      // console.log("Dataaaa", data);
      updateStatusCount(data);
      setExportData(exportData);
      setFetchedData([data]);
      // createFilters();
      setUpdated(moment().toString());
      // setNumberOfHighlightedTranscripts(
      // 	data.numberOfHighlightedTranscripts
      // );
    }
  }
  React.useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      {console.log("Number of Highlighted Transcript ", numberOfHighlightedTranscripts)}
      {fetchedData.length > 0 ? (
        <SubHeaderComponent
          // title={fetchedData[0].storyName}
          breadcrumb={[
            { item: "Stories", link: "/search/stories" },
            { item: fetchedData[0].storyName, link: "" },
          ]}
          status={fetchedData[0].status}
          extra={
            <SubHeaderExtra
              id={fetchedData[0].id}
              handleDelete={handleDelete}
              numberOfHiglightedTranscripts={numberOfHighlightedTranscripts}
              handlePublish={handlePublishStoryApi}
              status={fetchedData[0].status}
              exportData={exportData}
            />
          }
        />
      ) : (
        <SubHeaderComponent loading={true} extra={<></>} />
      )}
      <SubNavigation tabs={subTabs} />
      <DeleteAlert visible={visible} setVisible={setVisible} onYes={handleDeleteStoryApi} />
      {fetchedData.length > 0 ? (
        <div
          className="bg-body p-4"
          // style={{ height: "38em" }}
          style={{ height: "69vh" }}
        >
          <Routes>
            <Route
              key={"Annotated Selections"}
              path="/"
              element={
                <AnnotatedSelection
                  headerInfo={fetchedData[0].headerInfo}
                  data={fetchedData[0].annotatedSelections}
                  filteredColumns={fetchedData[0].filteredColumns}
                  addHighlightedTranscript={addHighlightedTranscript}
                  removeHighlightedTranscript={removeHighlightedTranscript}
                  updateTranscript={updateTranscript}
                  deleteTranscript={deleteTranscript}
                  // filters={filters}
                  updated={updated}
                />
              }
            />
            <Route
              key={"Search"}
              path="/search"
              // element={<Search storyId={fetchedData[0].id} />}
              element={<StoriesSearch storyId={fetchedData[0].id} addTranscript={addTranscript} />}
            />
            <Route
              key={"Find Similar Conversations"}
              path="/find_similar_conversations"
              element={
                <FindSimilarConversations
                  storyId={fetchedData[0].id}
                  addTranscript={addTranscript}
                />
              }
            />
          </Routes>
        </div>
      ) : (
        <>
          <div className="bg-body p-4" style={{ height: "38em" }}>
            <Routes>
              <Route
                key={"Annotated Selections"}
                path="/"
                element={<AnnotatedSelectionLoading />}
              />
              <Route
                key={"Search"}
                path="/search"
                // element={<Search storyId={fetchedData[0].id} />}
                element={<StoriesSearch storyId={""} addTranscript={addTranscript} />}
              />
              <Route
                key={"Find Similar Conversations"}
                path="/find_similar_conversations"
                element={<FindSimilarConversations storyId={""} addTranscript={addTranscript} />}
              />
            </Routes>
          </div>
        </>
      )}
    </>
  );
}
