// import React from "react";
// import ReactExport from "react-export-excel";
// import { icons } from "../../../GlobalThemes";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

// const dataSet1 = [
// 	{
// 		"Story Id": "Story_1",
// 		"Story Name": "Flushing Related Issues",
// 		"Story Status": "In Draft",
// 		Transcript: "Transcript from 29 Jul 2021 [09:18:39]",
// 		"Transcript Added On": "15 Feb 2022",
// 		"Dialogue Appeared At": "10 minutes",
// 		"Dialogue Length": "3 minutes",
// 		"Transcript Length": "16 minutes",
// 		"Transcript Text":
// 			"Patient : id of for 10, so 2022 10 ? Agent : mm okay so a lot of numbers id expiration is nan christopher do you feel that the um flushing or the redness on your ears that you experience is related to the viewmeridy okay. Patient : uh i would i would assume so but i do not know that for sure.",
// 		Source: "Search Based",
// 		"Transript Tags": "Covid",
// 		"Transcript Status": "Pending",
// 		"Highlighted Text": "",
// 	},
// 	{
// 		"Story Id": "Story_2",
// 		"Story Name": "Flushing Related Issues",
// 		"Story Status": "In Draft",
// 		Transcript: "Transcript from 31 Jul 2021 [05:28:26]",
// 		"Transcript Added On": "16 Feb 2022",
// 		"Dialogue Appeared At": "13minutes",
// 		"Dialogue Length": "2 minutes",
// 		"Transcript Length": "18 minutes",
// 		"Transcript Text":
// 			"Patient : id of for 10, so 2022 10 ? Agent : mm okay so a lot of numbers id expiration is nan christopher do you feel that the um flushing or the redness on your ears that you experience is related to the viewmeridy okay. Patient : uh i would i would assume so but i do not know that for sure.",
// 		Source: "Search Based",
// 		"Transript Tags": "",
// 		"Transcript Status": "Pending",
// 		"Highlighted Text": "",
// 	},
// ];
// const dataSet2 = [
// 	{
// 		name: "Johson",
// 		amount: 30000,
// 		sex: "M",
// 		is_married: true,
// 	},
// 	{
// 		name: "Monika",
// 		amount: 355000,
// 		sex: "F",
// 		is_married: false,
// 	},
// 	{
// 		name: "John",
// 		amount: 250000,
// 		sex: "M",
// 		is_married: false,
// 	},
// 	{
// 		name: "Josef",
// 		amount: 450500,
// 		sex: "M",
// 		is_married: true,
// 	},
// ];
// const multiDataSet = [
// 	{
// 		columns: [
// 			{ title: "Headings", width: { wpx: 80 } }, //pixels width
// 			{ title: "Text Style", width: { wch: 40 } }, //char width
// 			{ title: "Colors", width: { wpx: 90 } },
// 		],
// 		data: [
// 			[
// 				{ value: "H1", style: { font: { sz: "24", bold: true } } },
// 				{ value: "Bold", style: { font: { bold: true } } },
// 				{
// 					value: "Red",
// 					style: {
// 						fill: {
// 							patternType: "solid",
// 							fgColor: { rgb: "FFFF0000" },
// 						},
// 					},
// 				},
// 			],
// 			[
// 				{ value: "H2", style: { font: { sz: "18", bold: true } } },
// 				{ value: "underline", style: { font: { underline: true } } },
// 				{
// 					value: "Blue",
// 					style: {
// 						fill: {
// 							patternType: "solid",
// 							fgColor: { rgb: "FF0000FF" },
// 						},
// 					},
// 				},
// 			],
// 			[
// 				{ value: "H3", style: { font: { sz: "14", bold: true } } },
// 				{ value: "italic", style: { font: { italic: true } } },
// 				{
// 					value: "Green",
// 					style: {
// 						fill: {
// 							patternType: "solid",
// 							fgColor: { rgb: "FF00FF00" },
// 						},
// 					},
// 				},
// 			],
// 			[
// 				{ value: "H4", style: { font: { sz: "12", bold: true } } },
// 				{ value: "strike", style: { font: { strike: true } } },
// 				{
// 					value: "Orange",
// 					style: {
// 						fill: {
// 							patternType: "solid",
// 							fgColor: { rgb: "FFF86B00" },
// 						},
// 					},
// 				},
// 			],
// 			[
// 				{ value: "H5", style: { font: { sz: "10.5", bold: true } } },
// 				{ value: "outline", style: { font: { outline: true } } },
// 				{
// 					value: "Yellow",
// 					style: {
// 						fill: {
// 							patternType: "solid",
// 							fgColor: { rgb: "FFFFFF00" },
// 						},
// 					},
// 				},
// 			],
// 			[
// 				{ value: "H6", style: { font: { sz: "7.5", bold: true } } },
// 				{ value: "shadow", style: { font: { shadow: true } } },
// 				{
// 					value: "Light Blue",
// 					style: {
// 						fill: {
// 							patternType: "solid",
// 							fgColor: { rgb: "FFCCEEFF" },
// 						},
// 					},
// 				},
// 			],
// 		],
// 	},
// ];
// export function ExportToExcel() {
// 	return (
// 		<ExcelFile
// 			filename="AI-VOC"
// 			element={
// 				<div className="flex items-center text-text-gray-70 text-sm cursor-pointer">
// 					{icons.find((l) => l.id === "Excel")?.icon} Export to Excel
// 				</div>
// 			}
// 		>
// 			{/* <ExcelSheet dataSet={multiDataSet} name="Organization" /> */}

// 			<ExcelSheet data={dataSet1} name="Sheet 1">
// 				<ExcelColumn label="Story Id" value="Story Id" />
// 				<ExcelColumn label="Story Name" value="Story Name" />
// 				<ExcelColumn label="Story Status" value="Story Status" />
// 				<ExcelColumn label="Transcript" value="Transcript" />
// 				<ExcelColumn
// 					label="Transcript Added On"
// 					value="Transcript Added On"
// 				/>
// 				<ExcelColumn
// 					label="Dialogue Appeared At"
// 					value="Dialogue Appeared At"
// 				/>
// 				<ExcelColumn label="Dialogue Length" value="Dialogue Length" />
// 				<ExcelColumn
// 					label="Transcript Length"
// 					value="Transcript Length"
// 				/>
// 				<ExcelColumn label="Transcript Text" value="Transcript Text" />
// 				<ExcelColumn label="Source" value="Source" />
// 				<ExcelColumn label="Transript Tags" value="Transript Tags" />
// 				<ExcelColumn
// 					label="Transcript Status"
// 					value="Transcript Status"
// 				/>
// 				<ExcelColumn
// 					label="Highlighted Text"
// 					value="Highlighted Text"
// 				/>
// 			</ExcelSheet>
// 		</ExcelFile>
// 	);
// }
import { CSVDownload, CSVLink } from "react-csv";
import { icons } from "../../../GlobalThemes";
import { transformExportData } from "./processing";

const csvData = [
	[
		"Story Id",
		"Story Name",
		"Story Status",
		"Transcript",
		"Transcript Added On",
		"Dialogue Appeared At",
		"Dialogue Length",
		"Transcript Length",
		"Transcript Text",
		"Source",
		"Transript Tags",
		"Transcript Status",
		"Highlighted Text",
	],
	[
		"Story_1",
		"Flushing Related Issues",
		"In Draft",
		"Transcript from 29 Jul 2021 [09:18:39]",
		"15 Feb 2022",
		"274.31 minutes",
		"13.42 minutes",
		"446.52 minutes",
		"Patient : id of for 10, so 2022 10 ? Agent : mm okay so a lot of numbers id expiration is nan christopher do you feel that the um flushing or the redness on your ears that you experience is related to the viewmeridy okay. Patient : uh i would i would assume so but i do not know that for sure.",
		"Search Based",
		"Covid, Question",
		"Pending",
		"",
	],
	[
		"Story_1",
		"Flushing Related Issues",
		"In Draft",
		"Transcript from 23 Jul 2021 [17:12:45]",
		"11 Feb 2022",
		"274.31 minutes",
		"13.42 minutes",
		"446.52 minutes",
		"Patient : id of for 10, so 2022 10 ? Agent : mm okay so a lot of numbers id expiration is nan christopher do you feel that the um flushing or the redness on your ears that you experience is related to the viewmeridy okay. Patient : uh i would i would assume so but i do not know that for sure.",
		"Search Based",
		"",
		"Complete",
		"so you did have some flushing on the community on the tecfidera thymethyl and that sounds like that flushing was related to your therapy.",
	],
	[
		"Story_1",
		"Flushing Related Issues",
		"In Draft",
		"Transcript from 16 Jul 2021 [16:39:58]",
		"15 Feb 2022",
		"274.31 minutes",
		"13.42 minutes",
		"446.52 minutes",
		"Patient : id of for 10, so 2022 10 ? Agent : mm okay so a lot of numbers id expiration is nan christopher do you feel that the um flushing or the redness on your ears that you experience is related to the viewmeridy okay. Patient : uh i would i would assume so but i do not know that for sure.",
		"System Recommended",
		"",
		"Complete",
		"",
		"",
	],
];

export interface exportDataInterface {
	storyId: string;
	storyName: string;
	storyStatus: string;
	transcripts: {
		"Annotated Selection": string;
		"Date Added on": string;
		Source: string;
		Status: string;
		Tags: string[];
		therapyStatus:string;
		ToT:number;
		Description: {
			dialogueAppearedAt: string;
			dialogueLength: string;
			transcriptLength: string;
			transcripts: {
				speaker: string;
				text: string;
			}[];
		};
		highlightedString: string;
	}[];
}

export function ExportToExcel(props: { exportData: exportDataInterface }) {
	return (
		<CSVLink
			data={transformExportData(props.exportData)}
			target="_blank"
			filename={"AI_VOC"}
		>
			<div className="flex items-center text-text-gray-70 text-sm cursor-pointer">
				{icons.find((l) => l.id === "ExportCsv")?.icon} Export Data
			</div>
		</CSVLink>
	);
}
