import { request } from "./../App";

export const getAllStories = async (obj: {
	startDate?: string | undefined;
	endDate?: string | undefined;
	speaker?: string | undefined;
	showResults?: boolean | undefined;
	storyId?: string | undefined;
}) => {
	let str = "";
	const filters: any = {};
	Object.entries(obj).map(([key, value]) => {
		console.log("Key, Val", key, value);
		if ((Array.isArray(value) && value.length > 0) || typeof value === "boolean") filters[key] = value;
		else if (!Array.isArray(value) && value) filters[key] = value;
	});
	const fetchUrl = `story/GetStoryRecommendations?${str}`;
	const data = await request
		.post(fetchUrl, filters, { withCredentials: true })
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
		.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	return data;
};

export const getStoryStatus = async (storyId: string) => {
	const fetchUrl = `originalStory/GetStoryStatus?storyId=${storyId}`;
	const data = await request
		.get(fetchUrl, { withCredentials: true })
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
		.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	return data;
};
