import React, { useEffect } from "react";
import { userTypeEnum } from "../GlobalThemes";
const AppContext = React.createContext({
  lastRefreshedOn: "",
  startDate: "",
  endDate: "",
  setDates: (lastRefreshedOn: string, startDate: string, endDate: string) => {},
  userType: userTypeEnum[0],
  userName: "",
  setUser: (userName: string, userType: string) => {},
  searchWord: "",
  setSearch: (searchWord: any) => {},
  temp: null,
  setTempHere: (temp: any) => {},
  expIdForNodeGraph: null,
  setExpIdForNodeGraphHere: (expIdForNodeGraph: any) => {},
  
});

export function ContextProvider(props: { children: React.ReactNode }) {
  const [lastRefreshedOn, setLastRefreshedOn] = React.useState(
    localStorage.getItem("lastRefreshedOn") || ""
  );
  const [startDate, setStartDate] = React.useState(localStorage.getItem("startDate") || "");
  const [endDate, setEndDate] = React.useState(localStorage.getItem("endDate") || "");
  const [searchWord, setSearchWord] = React.useState("");
  const [temp, setTemp] = React.useState(null);
  const [expIdForNodeGraph, setExpIdForNodeGraph] = React.useState(null);

  const [userType, setUserType] = React.useState(userTypeEnum[0]);
  const [userName, setUserName] = React.useState("");

  useEffect(() => {
    caches.open("userData").then((cache) => {
      console.log(cache);
      cache.match("/userData").then((res) => {
        console.log(res);
        res?.json().then((res1) => {
          setUserType(res1.userType);
          setUserName(res1.userName);
        });
      });
    });
  }, []);

  function setTempHere(temp: any) {
    setTemp(temp);
  }

  function setExpIdForNodeGraphHere(expId: any) {
    setExpIdForNodeGraph(expId);
  }

  function setDates(lastRefreshedOn: string, startDate: string, endDate: string) {
    setLastRefreshedOn(lastRefreshedOn);
    setStartDate(startDate);
    setEndDate(endDate);
  }

  function setSearch(searchWord: any) {
    setSearchWord(searchWord);
  }

  function setUser(userName: string, userType: string) {
    setUserName(userName);
    setUserType(userType);
  }

  const context = {
    lastRefreshedOn: lastRefreshedOn,
    startDate: startDate,
    endDate: endDate,
    setDates: setDates,
    userType: userType,
    userName: userName,
    setUser: setUser,
    searchWord: searchWord,
    setSearch: setSearch,
    temp: temp,
    setTempHere: setTempHere,
    expIdForNodeGraph:expIdForNodeGraph,
    setExpIdForNodeGraphHere:setExpIdForNodeGraphHere
  };
  return <AppContext.Provider value={context}>{props.children}</AppContext.Provider>;
}

export default AppContext;
