import { Button, DatePicker, Input, Pagination, Select, List, message, Skeleton, Tag, Empty, Slider, Switch } from "antd";
import { Transcripts } from "../../Components/SearchEngineCards/Transcripts";
import Plot from "react-plotly.js";
import * as ReportTypesComponentInterface from "../../libraries/report-types/charts/interfaces/main";
import { ResponsiveLine } from "@nivo/line";
import { icons } from "../../GlobalThemes";
import React, { useState, useEffect } from "react";
import { FilterInterface } from "../Dashboard";
import { Drawer as AntDrawer } from "antd";
import Drawer from "../Dashboard/Components/Drawer";
import { FilterCard } from "../../Components/FilterCard";
import { usData } from "../../libraries/report-types/charts/geoMap/dataUS";
import { Choropleth, ResponsiveChoropleth } from "@nivo/geo";
import { usFeatures } from "../../libraries/report-types/charts/geoMap/geoUSFeatures";
import TranscriptsWrapper from "../../Components/TransciptsWrapper";
import SummaryCard from "../../Components/SummaryCard";
import ReactWordcloud from "react-wordcloud";
import { DUMMY_DATA, fetchedFilters, searchFilterActiveStatus, words } from "./data";
import { SearchFilters } from "../../Components/SearchEngineCards/SearchFilters";
import { FileSearchOutlined } from "@ant-design/icons";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import * as StoriesServiceHelper from "./../SearchEngine/ServiceHelpers/stories";
import * as SearchServiceHelper from "./../SearchEngine/ServiceHelpers/search";
import moment from "moment";
import * as Messages from "../../Components/Messages";
import AppContext from "../../store";
import { ResponsiveBar } from "@nivo/bar";
import ReportTypes from "../../libraries/report-types";

export interface searchQuery {
	deepSearch?: boolean;
	testButton1?: boolean;
	testButton2?: boolean;
	testButton3?: boolean;
	testButton4?: boolean;
	testButton5?: boolean;
	searchWord?: string;
	startDate?: string | any;
	endDate?: string | any;
	speaker?: string;
	showResults?: boolean;
	storyId?: string;
	callTypeIds?: number[];
	therapyStatusIds?: number[];
	emotions?: number[];
	emotionsGroup?: number[];
	sentiment?: number[];
	topics?: number[];
	totStart?: string;
	totEnd?: string;
}

var trace1 = {
	x: [1, 2, 3, 4, 5],
	y: [1, 6, 3, 6, 1],
	mode: "markers",
	type: "scatter",
	name: "Team A",
	text: ["A-1", "A-2", "A-3", "A-4", "A-5"],
	marker: { size: 12 },
};

var trace2 = {
	x: [1.5, 2.5, 3.5, 4.5, 5.5],
	y: [4, 1, 7, 1, 4],
	mode: "markers",
	type: "scatter",
	name: "Team B",
	text: ["B-a", "B-b", "B-c", "B-d", "B-e"],
	marker: { size: 12 },
};

var data = [trace1, trace2];

var layout = {
	xaxis: {
		range: [0.75, 5.25],
	},
	yaxis: {
		range: [0, 8],
	},
	title: "Data Labels Hover",
};

export interface transcriptDataInter {
	// filterOptions: {
	// 	filterName: string;
	// 	type: string;
	// 	options: string[];
	// }[];
	numberOfPatients: number;
	numberOfSearchResults: number;
	emotionsCounts: any;
	statesResults: any;
	headers: any;
	searchResults: {
		id: number;
		s3path: string;
		callDate: string;
		callId: string;
		callTime: string;
		callTypeIds: string;
		therapyStatusIds: string;
		dialogueNo: number;
		name: string;
		tags: string[];
		dialogueAppearedAt: number;
		dialogueLength: number;
		transcriptLength: number;
		addedToStory: boolean;
		storiesAdded: {
			storyId: any;
			storyName: string;
		}[];
		patientId: number;
		speaker: string;
		elasticSearchId: string;
		transcripts: {
			dialogueNo: number;
			speaker: string;
			text: string;
		}[];
		storyDialogues: any;
	}[];
}

/**
 * This page is the main search tab in Search Engine Page. This page renders searched transcripts in a list.
 *
 * @returns List of Searched Transcripts.
 */

export function Search() {
	const context = React.useContext(AppContext);
	const [showResultSummary, setShowResultSummary] = useState<boolean>(false);
	const [expanded, setExpanded] = React.useState(false);
	const [visible, setVisible] = React.useState(false);
	const [transcriptData, setTranscriptData] = useState<transcriptDataInter[]>([]);
	const [fetchedFilters, setFetchedFilters] = React.useState<
		{
			filter: string;
			type: string;
			optionsObject: {
				id: number;
				name: string;
				subgroup: {
					id: number;
					name: string;
				}[];
			}[];
		}[]
	>([]);
	const dataCopy = React.useRef<transcriptDataInter[]>([]);
	const [pinnedStories, setPinnedStories] = useState<Array<any>>([]);
	const [fetchedStoryData, setFetchedStoryData] = useState<any>([]);
	const [transcriptLoading, setTranscriptLoading] = useState<boolean>(false);
	const [searchTranscriptQuery, setSearchTranscriptQuery] = useState<searchQuery>({});
	const [searchWordRequired, setsearchWordRequired] = useState<boolean>(false);
	const [searchFilterData, setSearchFilterData] = useState<any>();
	const [selectLevel, setSelectLevel] = useState<any>();
	const [therapyStatus, setTherapyStatus] = useState<any>();
	const handleFilters = (filterValue: any) => {
		console.log(filterValue);
		setSearchFilterData(filterValue);
	};
	const handleFilters2 = (filterValue: string | boolean) => {
		setSelectLevel(filterValue);
	};

	const handleFilters3 = (filterValue: string | boolean) => {
		console.log(filterValue);
		setTherapyStatus(filterValue);
	};

	const [emptyTextField, setEmptyTextField] = useState("View transcripts after searching with criteria");
	var loadingState = true;
	const showDrawer = () => {
		setVisible(true);
	};
	const onClose = () => {
		setVisible(false);
	};

	const [filters, setFilters] = React.useState<FilterInterface>({
		Timeframe_Display: undefined,
		Start_Date: "",
		End_Date: "",
		Call_Type: undefined,
		Therapy_Status: undefined,
		Call_Intent: undefined,
		Adherence: undefined,
		Sentiment: undefined,
		Topics: undefined,
		Emotions: new Array<string>(),
		totStart: undefined,
		totEnd: undefined,
	});

	const [searchString, setSearchString] = React.useState("");

	const clearFilters = () => {
		const obj = {
			Timeframe_Display: undefined,
			Start_Date: "",
			End_Date: "",
			Call_Type: undefined,
			Therapy_Status: undefined,
			Call_Intent: undefined,
			Adherence: undefined,
			Sentiment: undefined,
			Topics: undefined,
			Emotions: new Array<string>(),
			totStart: undefined,
			totEnd: undefined,
		};
		setFilters(obj);
		setVisible(false);
		setTranscriptFilters(obj);
	};

	async function fetchFilters() {
		const data: any = await SearchServiceHelper.getFilters();
		data.push({
			filter: "TOT",
			optionsObject: [],
			type: "slider",
		});
		if (data) {
			setFetchedFilters(data);
		}
	}

	async function fetchStoryData() {
		const data: any = await StoriesServiceHelper.getAllStories();

		if (data) {
			setFetchedStoryData(data);
		}
	}

	function updateStoriesData() {
		fetchStoryData();
	}

	const fetchTranscripts = async (query: searchQuery) => {
		setTranscriptLoading(true);
		Messages.loading("Fetching...", "key1");
		const data: any = await SearchServiceHelper.getAllTranscripts(query);
		setTranscriptLoading(false);
		const key = "Key 1";

		if (data) {
			setTranscriptData([data]);
			console.log(transcriptData);
			dataCopy.current = [data];
			Messages.success("Success", "key1");
		} else {
			Messages.error("Fetching failed", "key1");
			setTranscriptData([]);
		}
	};

	async function addNewStory(addData: { "Story Name": string; "Story Type": string }) {
		const key = "updatable";
		Messages.loading("Adding Transcript", key);

		addData["Story Type"] = addData["Story Type"].split(" ").join("");
		// console.log(addData);
		const data: any = await StoriesServiceHelper.addNewStory(addData);
		if (data) {
			var temp: any = [];
			var d = data["Date Created"];
			data["Date Created"] = moment(d).format("DD MMM YYYY");
			temp = [data, ...fetchedStoryData];
			setFetchedStoryData(temp);
			Messages.success("Successfully Created New Story", key);
		} else Messages.error("Failed", key);
	}

	async function addStoryToTranscriptData(transcript: {
		id: any;
		name: any;
		tags: any;
		storiesAdded: any;
		source: number;
		dialogueNo: any;
		callId: any;
		callDate: any;
		callTime: any;
		s3path: any;
		dialogueAppearedAt: any;
		dialogueLength: any;
		transcriptLength: any;
		transcripts: any;
		patientId: any;
		speaker: any;
		elasticSearchId: any;
		storyDialogues: any;
	}) {
		const key = "updatable";
		Messages.loading("Adding Transcript to Story", key);
		const data: any = await SearchServiceHelper.addToStory(transcript);
		// console.log("Fetched : ", data);
		if (data) {
			Messages.success("Successfully Added Transcript to Story", key);

			const temp = [...transcriptData];
			console.log("transc", transcriptData, temp, transcript);

			const idx = temp[0].searchResults.findIndex((t) => t.callId === transcript.callId && t.dialogueNo === transcript.dialogueNo);

			temp[0].searchResults[idx].storiesAdded = data;
			if (data.length === 0) {
				temp[0].searchResults[idx].addedToStory = false;
			}
			setTranscriptData(temp);

			return true;
		} else {
			Messages.error("Failed", key);
			return false;
		}
	}

	useEffect(() => {
		fetchFilters();
		fetchStoryData();
		setSearchFilterData(["Good"]);
	}, []);

	const addToStorySearch = (transcriptData: any, storyId: any) => {
		var request = {
			transcriptData: transcriptData,
			storyId: storyId,
		};
		var add: any = [];
		storyId.forEach((story: any, index: number) => {
			add.push({
				storyId: story,
				storyName: fetchedStoryData[fetchedStoryData.findIndex((storyFetch: any) => storyFetch.id === story)].storyName,
			});
		});

		var trans = {
			id: transcriptData.id,
			name: transcriptData.name,
			tags: transcriptData.tags,
			storiesAdded: add,
			source: 1,
			dialogueNo: transcriptData.dialogueNo,
			callId: transcriptData.callId,
			callDate: transcriptData.callDate,
			callTime: transcriptData.callTime,
			s3path: transcriptData.s3path,
			dialogueAppearedAt: transcriptData.dialogueAppearedAt,
			dialogueLength: transcriptData.dialogueLength,
			transcriptLength: transcriptData.transcriptLength,
			transcripts: transcriptData.transcripts,
			patientId: transcriptData.patientId,
			speaker: transcriptData.speaker,
			elasticSearchId: transcriptData.elasticSearchId,
			storyDialogues: transcriptData.storyDialogues,
		};
		const d = addStoryToTranscriptData(trans);
		return d;
	};

	const changePinnedStories = (stories: any) => {
		setPinnedStories(stories);
	};
	React.useEffect(() => {
		// applyFilters();
	}, [filters]);

	const addSearchFilters = (query: searchQuery) => {
		var searchFilter: searchQuery = searchTranscriptQuery;
		searchFilter.searchWord = query.searchWord;
		searchFilter.speaker = query.speaker;
		// console.log(
		// 	query.startDate,
		// 	moment(query.startDate, "MMM YYYY").format()
		// );
		if (query.startDate) {
			// searchFilter.startDate = moment(query.startDate).format();
			searchFilter.startDate = moment(query.startDate).startOf("month").add(1, "days");
			searchFilter.endDate = moment(query.endDate).add(1, "months");
		}
		searchFilter.deepSearch = query.deepSearch;
		searchFilter.testButton1 = query.testButton1;
		searchFilter.testButton2 = query.testButton2;
		searchFilter.testButton3 = query.testButton3;
		searchFilter.testButton4 = query.testButton4;
		searchFilter.testButton5 = query.testButton5;
		checksearchWord(searchFilter);
		setSearchTranscriptQuery(searchFilter);
	};

	function findId(filter: string, value: string) {
		const grpIdx: number[] = [];
		const idx: number[] = [];
		fetchedFilters.map((f) => {
			f.optionsObject.map((group) => {
				if (filter === f.filter) {
					if (group.subgroup.length === 0 && value === group.name) {
						idx.push(group.id);
						grpIdx.push(group.id);
						return group.id;
					} else if (group.subgroup.length > 0) {
						if (Array.isArray(value) && value.find((f) => f === group.name)) {
							grpIdx.push(group.id);
						} else if (Array.isArray(value)) {
							value.map((v) => {
								const ob = group.subgroup.find((sg) => sg.name === v);
								if (ob) {
									grpIdx.push(group.id);
									idx.push(ob.id);
								}

								return -1;
							});
						}
					}
				}
			});
		});
		return { idx, grpIdx };
	}

	const colors: Record<string, string> = {
		Inbound: "rgb(224,198,255)",
		Outbound: "rgb(114,50,207)",
		Agent: "rgb(169,218,253)",
		Member: "rgb(169,218,253)",
		Positive: "rgb(38,195,141)",
		Neutral: "rgb(222,220,222)",
		Negative: "rgb(230,84,40)",
		High: "rgb(238, 103, 103)",
		Medium: "rgb(255, 199, 54)",
		Low: "rgb(118, 188, 238)",
	};
	const getColor = (bar: any) => colors[bar.id];

	const setTranscriptFilters = (filter: any) => {
		console.log("Filter", filter);
		var transcriptFilters: searchQuery = searchTranscriptQuery;
		const callType = findId("Call_Type", filter.Call_Type);
		transcriptFilters.callTypeIds = callType.grpIdx;

		const therapyStatus = findId("Therapy_Status", filter.Therapy_Status);
		transcriptFilters.therapyStatusIds = therapyStatus.grpIdx;

		const sentiment = findId("Sentiment", filter.Sentiment);
		transcriptFilters.sentiment = sentiment.grpIdx;

		const topics = findId("Topics", filter.Topics);
		transcriptFilters.topics = topics.grpIdx;

		const emotions = findId("Emotions", filter.Emotions);
		transcriptFilters.emotionsGroup = emotions.grpIdx;
		transcriptFilters.emotions = emotions.idx;

		transcriptFilters.totStart = filter.totStart;
		transcriptFilters.totEnd = filter.totEnd;

		console.log("TranscriptFilter", transcriptFilters);
		setFilters(filter);
		checksearchWord(transcriptFilters);
		setSearchTranscriptQuery(transcriptFilters);
	};

	const checksearchWord = (query: searchQuery) => {
		if (query.searchWord === undefined || query.searchWord === "") {
			setsearchWordRequired(true);
		} else {
			setsearchWordRequired(false);
			setEmptyTextField("No Data");
			console.log(query);
			fetchTranscripts(query);
		}
	};

	console.log("transcriptData:", transcriptData);
	let findValueForTherapyStatusGraph = (therapyStatus: any, bound: any, filterVal: any) => {
		// fitlerVal is an array
		let count = 0;

		transcriptData[0].searchResults.map((i: any) => {
			if (
				i.tags[2] === therapyStatus &&
				i.tags[1] === bound &&
				(filterVal.length === 1
					? i.tags[0] === filterVal[0]
					: filterVal.length === 2
					? i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1]
					: i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1] || i.tags[0] === filterVal[2])
			)
				++count;
		});

		return count;
	};

	let findValueForSentimentGraph = (patient: any, sentiment: any, filterVal: any, useTopic: boolean) => {
		if (useTopic === true) {
			let count = 0;
			transcriptData[0].searchResults.map((i: any) => {
				if (
					i.tags[4] === sentiment &&
					i.tags[5] === patient &&
					(filterVal.length === 1
						? i.tags[0] === filterVal[0]
						: filterVal.length === 2
						? i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1]
						: i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1] || i.tags[0] === filterVal[2])
				)
					++count;
			});

			return count;
		} else {
			let count = 0;
			transcriptData[0].searchResults.map((i: any) => {
				if (
					i.tags[4] === sentiment &&
					i.speaker === patient &&
					(filterVal.length === 1
						? i.tags[0] === filterVal[0]
						: filterVal.length === 2
						? i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1]
						: i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1] || i.tags[0] === filterVal[2])
				)
					++count;
			});

			return count;
		}
	};

	let findValueForToT = (ll: any, ul: any, filterVal: any, therapyStatus?: any) => {
		if (therapyStatus) {
			let count = 0;

			transcriptData[0].searchResults.map((i: any) => {
				if (
					i.tags[3] > ll &&
					i.tags[3] < ul &&
					i.tags[2] === therapyStatus &&
					(filterVal.length === 1
						? i.tags[0] === filterVal[0]
						: filterVal.length === 2
						? i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1]
						: i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1] || i.tags[0] === filterVal[2])
				)
					++count;
			});
			return count;
		} else {
			let count = 0;

			transcriptData[0].searchResults.map((i: any) => {
				if (
					i.tags[3] > ll &&
					i.tags[3] < ul &&
					(filterVal.length === 1
						? i.tags[0] === filterVal[0]
						: filterVal.length === 2
						? i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1]
						: i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1] || i.tags[0] === filterVal[2])
				)
					++count;
			});
			return count;
		}
	};

	// let temp:any = [];

	// let findGreatestVal: any = transcriptData[0].emotionsCounts.map((i:any) => {
	//   let t: any
	//   t.name = i.text;
	//   t.value = i.value;

	//   });

	// let findValueForTherapyDistributionGraph = (time: any) => {
	//   let count = 0;
	//   transcriptData[0].searchResults.map((i: any) => {
	//     if (i.tags[4] === sentiment && i.speaker === patient) ++count;
	//   });

	//   return count;
	// };

	// let findValueForConceptGraph = (keyword: any) => {
	//   let count = 0;
	//   transcriptData[0].searchResults.map((i: any) => {
	//     if (i.tags[4] === sentiment && i.speaker === patient) ++count;
	//   });

	//   return count;
	// };

	let temp: any = [];

	if (transcriptData.length > 0) {
		let objs: any = transcriptData[0].emotionsCounts;

		objs.sort((a: any, b: any) => b.value - a.value);
		objs.map((i: any, index: any) => {
			if (index < 10) {
				temp.push(i);
			}
		});
	}

	let arrForFilteringChart: any = [];

	const summaryDummyData =
		transcriptData.length <= 0
			? null
			: "Calls can refer to different things, such as the Apple TV+ series, making and receiving phone calls from a PC, using Google Voice, or making free internet calls with PopTox.The Apple TV+ series Calls is a horror show directed by Fede �lvarez. The show features a series of seemingly unrelated phone calls, each featuring characters involved in stories that involve strange, supernatural phenomena[1]. The show is effective in setting the mood, with a separate Spanish-language version planned for Latin American markets[1].Making and receiving phone calls from a PC is possible with the Phone Link app from Microsoft. The app requires Bluetooth capability on both the PC and the Android device[2]. Troubleshooting tips are available if issues arise while using the calling feature[2]. Recent calls cannot be managed or deleted from the PC[2].Google Voice allows users to place and receive calls from anywhere using smartphones and the web. The service offers voicemail transcription and the ability to forward calls to any device while blocking spam calls[3]. The service is available on Apple devices and other smartphones[3].";

	if (transcriptData.length > 0) {
		let tempArr: any = [
			"Dosing Information",
			"Closing",
			"Speciality Pharmacy",
			"Common Side Effects",
			"Next Call Confirmation",
			"Introduction",
			"Lab Monitoring Guidance",
			"  Nurse Educator Role",
			"Others",
			"Member Questionnaire",
			"Safety Recording",
			"Shipment",
		];

		for (let i = 0; i <= tempArr.length; i++) {
			if (
				findValueForSentimentGraph(tempArr[i], "Negative", searchFilterData, true) +
					findValueForSentimentGraph(tempArr[i], "Positive", searchFilterData, true) +
					+findValueForSentimentGraph(tempArr[i], "Neutral", searchFilterData, true) >
				0
			) {
				let obj: any = {};
				obj.Topic = tempArr[i];
				obj.Positive = findValueForSentimentGraph(tempArr[i], "Positive", searchFilterData, true);
				obj["positive Color"] = "hsl(251 70%, 50%)";
				obj.Neutral = findValueForSentimentGraph(tempArr[i], "Neutral", searchFilterData, true);
				obj["neutral Color"] = "hsl(41, 70%, 50%)";
				obj.Negative = findValueForSentimentGraph(tempArr[i], "Negative", searchFilterData, true);
				obj["negative Color"] = "hsl(41, 70%, 50%)";
				arrForFilteringChart.push(obj);
			}
		}
	}

	return (
		<div>
			<AntDrawer title="Filters" placement="right" onClose={onClose} visible={visible} destroyOnClose={true}>
				<Drawer
					filters={filters}
					setFilters={setTranscriptFilters}
					filterOptions={fetchedFilters}
					clearFilters={clearFilters}
					filterActiveStatus={searchFilterActiveStatus}
				/>
			</AntDrawer>

			<div className="w-screen px-4 shadow-lg" style={{ zIndex: 99, position: "relative" }}>
				<div className="flex py-4 items-center">
					<div className="flex">
						<h2 className="text-xl font-semibold ">Search Transcripts</h2>
						<div className="flex flex-wrap ml-8">
							<FilterCard filters={filters} setFilters={setTranscriptFilters} filterActiveStatus={searchFilterActiveStatus} />
						</div>
					</div>
				</div>
				<SearchFilters
					showDrawer={showDrawer}
					fetchTranscripts={addSearchFilters}
					searchWordRequired={searchWordRequired}
					searchString={searchString}
					setSearchString={setSearchString}
				/>
			</div>
			<div className="bg-body  p-4  flex" style={{ height: "calc(100vh - 14.3rem)" }}>
				<div
					className="bg-white shadow-lg shadow-gray-900"
					style={{
						overflowY: "auto",
						// height: `${transcriptData.length === 0 ? "fill-available" : "max-content"}`,
						flexBasis: showResultSummary ? "65%" : "100%",
					}}
				>
					{transcriptLoading ? (
						<div>
							<Skeleton active className="py-4 px-8 border-b" />
						</div>
					) : (
						<div
							className="bg-white shadow-md shadow-gray-900 "
							style={{
								overflowY: "auto",
								zIndex: 99,
								position: "relative",
								// height: `${transcriptData.length === 0 ? "fill-available" : "max-content"}`,
								flexBasis: showResultSummary ? "65%" : "100%",
							}}
						>
							{/* {console.log(transcriptData[0]["headers"]["x-gpt-summary"])} */}
							<SummaryCard
								data={
									transcriptData.length > 0 && transcriptData[0]["headers"]["x-gpt-summary"]
										? decodeURIComponent(transcriptData[0]["headers"]["x-gpt-summary"])
										: ""
								}
								// data={summaryDummyData}
								searchString={searchString}
								isloaded={transcriptData.length > 0 ? true : false}
							></SummaryCard>
						</div>
					)}
					<TranscriptsWrapper
						// header={`${transcriptData.length > 0 ? transcriptData[0].numberOfSearchResults : 0} search results across ${
						// 	transcriptData.length > 0 ? transcriptData[0].numberOfPatients : 0
						// } patients`}
						header={`${transcriptData.length > 0 ? transcriptData[0].numberOfSearchResults : 0} search results across 100 members`}
						expanded={expanded}
						toggleExpand={setExpanded}
						showSummary={showResultSummary}
						toggleSummary={setShowResultSummary}
						loading={transcriptLoading}
						noSideSummary={transcriptData.length > 0 ? false : true}
						goodSearch={transcriptData.length > 0 ? transcriptData[0].searchResults.filter((x: any) => x.tags[0] === "Good").length : null}
						badSearch={transcriptData.length > 0 ? transcriptData[0].searchResults.filter((x: any) => x.tags[0] === "Bad").length : null}
						averageSearch={transcriptData.length > 0 ? transcriptData[0].searchResults.filter((x: any) => x.tags[0] === "Average").length : null}
					>
						{transcriptLoading ? (
							<div>
								<Skeleton active className="py-4 px-8 border-b" />
								<Skeleton active className="py-4 px-8 border-b" />
								<Skeleton active className="py-4 px-8 border-b" />
							</div>
						) : (
							<div className={transcriptData.length > 0 ? "" : "flex items-center justify-around h-96"}>
								<List
									itemLayout="vertical"
									size="large"
									pagination={{
										hideOnSinglePage: true,
										style: {
											marginLeft: "2rem",
											marginBottom: "1.2rem",
											float: "left",
											paddingBottom: "1rem",
										},
										onChange: (page) => {
											console.log(page);
										},
										pageSize: 20,
									}}
									dataSource={transcriptData.length > 0 ? transcriptData[0].searchResults : []}
									locale={{
										emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>{emptyTextField}</span>} />,
									}}
									renderItem={(result, index) => (
										<Transcripts
											data={result}
											expanded={expanded}
											addToStoryApi={addToStorySearch}
											pinnedStoriesData={pinnedStories}
											fetchedStoryData={fetchedStoryData}
											fetchStoryData={fetchStoryData}
											addNewStory={addNewStory}
											changePinnedStories={changePinnedStories}
											transcriptData={transcriptData.length > 0 ? transcriptData[0].searchResults : []}
											searchString={searchString}
											setSearchString={setSearchString}
										/>
									)}
								/>
							</div>
						)}
					</TranscriptsWrapper>
				</div>
				{showResultSummary ? (
					<div className="bg-white shadow-md shadow-gray-900 ml-4 p-4" style={{ flexBasis: "40%", overflowY: "auto" }}>
						<p className="font-semibold text-base">
							Search Result Summary
							{/* <p className="mt-7 font-semibold text-sm"> */}
							{/* <Select
								clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
								className="ml-4 float-right w-60"
								placeholder="Select Quality"
								allowClear
								mode="multiple"
								defaultValue={searchFilterData ? searchFilterData : ["High"]}
								onChange={(value: string) => {
									handleFilters(value);
								}}>
								<Select.Option value="Good">High</Select.Option>
								<Select.Option value="Average">Medium</Select.Option>
								<Select.Option value="Bad">Low</Select.Option>
							</Select> */}
							{/* </p> */}
						</p>
						<p className="font-semibold mt-5 text-sm mb-3">Age Distribution</p>
						<div className="flex h-60 border-2" style={{ height: 200 }}>
							<ResponsiveBar
								data={[
									{
										"Therapy Status": "18-45",
										Inbound: 23,
										"inbound Color": "rgb(224,198,255)",
										Outbound: 0,
										"outbound Color": "hsl(41, 70%, 50%)",
									},
									{
										"Therapy Status": "45-60",
										Inbound: 27,
										"inbound Color": "rgb(224,198,255)",
										Outbound: 0,
										"outbound Color": "hsl(41, 70%, 50%)",
									},
									{
										"Therapy Status": "60-80",
										Inbound: 21,
										"inbound Color": "rgb(224,198,255)",
										Outbound: 0,
										"outbound Color": "hsl(41, 70%, 50%)",
									},
									{
										"Therapy Status": "80+",
										Inbound: 13,
										"inbound Color": "rgb(224,198,255)",
										Outbound: 0,
										"outbound Color": "hsl(41, 70%, 50%)",
									},
								]}
								keys={["Inbound", "Outbound"]}
								indexBy="Therapy Status"
								margin={{ top: 20, right: 20, bottom: 90, left: 35 }}
								padding={0.3}
								valueScale={{ type: "linear" }}
								indexScale={{ type: "band", round: true }}
								// colors={{ scheme: "nivo" }}
								colors={getColor}
								defs={[
									{
										id: "dots",
										type: "patternDots",
										background: "inherit",
										color: "#38bcb2",
										size: 4,
										padding: 1,
										stagger: true,
									},
									{
										id: "lines",
										type: "patternLines",
										background: "inherit",
										color: "#eed312",
										rotation: -45,
										lineWidth: 6,
										spacing: 10,
									},
								]}
								borderColor={{
									from: "color",
									modifiers: [["darker", 1.6]],
								}}
								axisTop={null}
								axisRight={null}
								// gridYValues = {[10,20,30,40,50,60,70,80]}
								axisBottom={{
									format: (v) => {
										return v.length > 14 ? (
											<tspan>
												{v.substring(0, 12) + ".."}
												<p>{v}</p>
											</tspan>
										) : (
											v
										);
									},
									tickSize: 5,
									tickPadding: 5,
									tickRotation: 0,
									legend: <tspan>Age Distribution</tspan>,
									legendPosition: "middle",
									legendOffset: 40,
								}}
								axisLeft={{
									format: (e) => Math.floor(e) === e && e,
									tickSize: 5,
									tickPadding: 5,
									tickRotation: 0,
									legend: <tspan className="font-semibold">#Members</tspan>,
									legendPosition: "middle",
									legendOffset: -30,
								}}
								labelSkipWidth={12}
								labelSkipHeight={12}
								labelTextColor={{
									from: "color",
									modifiers: [["darker", 1.6]],
								}}
								// legends={[
								// 	{
								// 		dataFrom: "keys",
								// 		anchor: "bottom",
								// 		direction: "row",
								// 		justify: false,
								// 		translateX: 30,
								// 		translateY: 80,
								// 		itemsSpacing: 2,
								// 		itemWidth: 100,
								// 		itemHeight: 20,
								// 		itemDirection: "left-to-right",
								// 		itemOpacity: 0.85,
								// 		symbolSize: 20,
								// 		effects: [
								// 			{
								// 				on: "hover",
								// 				style: {
								// 					itemOpacity: 1,
								// 				},
								// 			},
								// 		],
								// 	},
								// ]}
								role="application"
								// ariaLabel="Current Plan Status Distribution"
							/>
						</div>
						<p className="font-semibold mt-5 text-sm mb-3">
							Discussion Sentiment
							{/* <span className="font-normal float-right">Select Level</span> */}
						</p>
						<div className="flex h-60 border-2" style={{ height: 200 }}>
							<ResponsiveBar
								layout="horizontal"
								colors={getColor}
								// data={arrForFilteringChart.map((i: any) => i)}
								data={[
									{
										Topic: "Agent",
										Positive: 30,
										"positive Color": "hsl(251, 70%, 50%)",
										Neutral: 60,
										"neutral Color": "hsl(41, 70%, 50%)",
										Negative: 10,
										"negative Color": "hsl(41, 70%, 50%)",
									},
									{
										Topic: "Member",
										Positive: 10,
										"positive Color": "hsl(251, 70%, 50%)",
										Neutral: 40,
										"neutral Color": "hsl(41, 70%, 50%)",
										Negative: 50,
										"negative Color": "hsl(41, 70%, 50%)",
									},
								]}
								keys={["Positive", "Neutral", "Negative"]}
								indexBy="Topic"
								margin={{ top: 10, right: 110, bottom: 30, left: 160 }}
								padding={0.3}
								valueScale={{ type: "linear" }}
								indexScale={{ type: "band", round: true }}
								defs={[
									{
										id: "dots",
										type: "patternDots",
										background: "inherit",
										color: "#38bcb2",
										size: 4,
										padding: 1,
										stagger: true,
									},
									{
										id: "lines",
										type: "patternLines",
										background: "inherit",
										color: "#eed312",
										rotation: -45,
										lineWidth: 6,
										spacing: 10,
									},
								]}
								borderColor={{
									from: "color",
									modifiers: [["darker", 1.6]],
								}}
								axisTop={null}
								axisRight={null}
								axisBottom={{
									tickSize: 0,
									tickPadding: 5,
									tickRotation: 0,
									legend: "",
									legendPosition: "middle",
									legendOffset: 38,
								}}
								axisLeft={{
									tickSize: 5,
									tickPadding: 5,
									tickRotation: 0,
									// legend: "food",
									legendPosition: "middle",
									legendOffset: -40,
								}}
								labelSkipWidth={12}
								labelSkipHeight={12}
								labelTextColor={{
									from: "color",
									modifiers: [["darker", 1.6]],
								}}
								legends={[
									{
										dataFrom: "keys",
										anchor: "bottom-right",
										direction: "column",
										justify: false,
										translateX: 120,
										translateY: 0,
										itemsSpacing: 2,
										itemWidth: 100,
										itemHeight: 20,
										itemDirection: "left-to-right",
										itemOpacity: 0.85,
										symbolSize: 20,
										effects: [
											{
												on: "hover",
												style: {
													itemOpacity: 1,
												},
											},
										],
									},
								]}
								role="application"
								ariaLabel="Current Plan Status Distribution"
							/>
						</div>

						<p className="font-semibold mt-5 text-sm mb-3">
							Time on Plan Distribution by Member Risk Category
							<span className="font-normal float-right">
								{/* Show Current Plan Status
									<Switch className="ml-2" /> */}
							</span>
						</p>
						<div className="flex h-60 border-2" style={{ height: 200 }}>
							<ResponsiveBar
								data={[
									{
										"Days on Plan": "30 days",
										High: 6,
										Medium: 4,
										Low: 4,
									},
									{
										"Days on Plan": "60 days",
										High: 8,
										Medium: 5,
										Low: 3,
									},
									{
										"Days on Plan": "90 days",
										High: 4,
										Medium: 6,
										Low: 5,
									},
									{
										"Days on Plan": "180 days",
										High: 3,
										Medium: 5,
										Low: 7,
									},
									{
										"Days on Plan": "360 days",
										High: 1,
										Medium: 2,
										Low: 9,
									},
									{
										"Days on Plan": "360+ days",
										High: 2,
										Medium: 4,
										Low: 6,
									},
								]}
								keys={["High", "Medium", "Low"]}
								indexBy="Days on Plan"
								margin={{ top: 20, right: 80, bottom: 90, left: 35 }}
								padding={0.3}
								valueScale={{ type: "linear" }}
								indexScale={{ type: "band", round: true }}
								// colors={{ scheme: "nivo" }}
								colors={getColor}
								defs={[
									{
										id: "dots",
										type: "patternDots",
										background: "inherit",
										color: "#38bcb2",
										size: 4,
										padding: 1,
										stagger: true,
									},
									{
										id: "lines",
										type: "patternLines",
										background: "inherit",
										color: "#eed312",
										rotation: -45,
										lineWidth: 6,
										spacing: 10,
									},
								]}
								borderColor={{
									from: "color",
									modifiers: [["darker", 1.6]],
								}}
								axisTop={null}
								axisRight={null}
								// gridYValues = {[10,20,30,40,50,60,70,80]}
								axisBottom={{
									format: (v) => {
										return v.length > 14 ? (
											<tspan>
												{v.substring(0, 12) + ".."}
												<p>{v}</p>
											</tspan>
										) : (
											v
										);
									},
									tickSize: 5,
									tickPadding: 5,
									tickRotation: 0,
									legend: <tspan>#Days on Plan</tspan>,
									legendPosition: "middle",
									legendOffset: 40,
								}}
								axisLeft={{
									format: (e) => Math.floor(e) === e && e,
									tickSize: 5,
									tickPadding: 5,
									tickRotation: 0,
									legend: <tspan className="font-normal">#Members</tspan>,
									legendPosition: "middle",
									legendOffset: -30,
								}}
								labelSkipWidth={24}
								labelSkipHeight={24}
								labelTextColor={{
									from: "color",
									modifiers: [["darker", 1.6]],
								}}
								legends={[
									{
										dataFrom: "keys",
										anchor: "right",
										direction: "column",
										justify: false,
										translateX: 110,
										translateY: 80,
										itemsSpacing: 10,
										itemWidth: 100,
										itemHeight: 20,
										itemDirection: "left-to-right",
										itemOpacity: 0.85,
										symbolSize: 20,
										effects: [
											{
												on: "hover",
												style: {
													itemOpacity: 1,
												},
											},
										],
									},
								]} //
								role="application"
								ariaLabel="Current Plan Status Distribution"
							/>
						</div>

						{/* <p className="font-semibold mt-5 text-sm mb-3">
							Discussion Sentiment
							<span className="font-normal float-right">
								Select Level
								<Select
									clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
									className="ml-4 -mt-2 float-right w-32"
									placeholder="Select Quality"
									allowClear
									defaultValue={selectLevel ? selectLevel : "Speaker"}
									onChange={(value: string) => {
										handleFilters2(value);
									}}>
									{/* <Select.Option value="Topic">Topic</Select.Option> */}
						{/* <Select.Option value="Speaker">Speaker</Select.Option>
								</Select>
							</span>
						</p>
						<div className="flex h-60 border-2">
							{selectLevel === "Topic" ? (
								<ResponsiveBar
									layout="horizontal"
									colors={getColor}
									data={arrForFilteringChart.map((i: any) => i)}
									// data={[
									//   {
									//     Topic: "Closing",
									//     Positive: findValueForSentimentGraph(
									//       "Closing",
									//       "Positive",
									//       searchFilterData,
									//       true
									//     ),
									//     "positive Color": "hsl(251, 70%, 50%)",
									//     Neutral: findValueForSentimentGraph(
									//       "Closing",
									//       "Neutral",
									//       searchFilterData,
									//       true
									//     ),
									//     "neutral Color": "hsl(41, 70%, 50%)",
									//     Negative: findValueForSentimentGraph(
									//       "Closing",
									//       "Negative",
									//       searchFilterData,
									//       true
									//     ),
									//     "negative Color": "hsl(41, 70%, 50%)",
									//   },
									//   {
									//     Topic: "Common Side Effects",
									//     Positive: findValueForSentimentGraph(
									//       "Common Side Effects",
									//       "Positive",
									//       searchFilterData,
									//       true
									//     ),
									//     "positive Color": "hsl(251, 70%, 50%)",
									//     Neutral: findValueForSentimentGraph(
									//       "Common Side Effects",
									//       "Neutral",
									//       searchFilterData,
									//       true
									//     ),
									//     "neutral Color": "hsl(41, 70%, 50%)",
									//     Negative: findValueForSentimentGraph(
									//       "Common Side Effects",
									//       "Negative",
									//       searchFilterData,
									//       true
									//     ),
									//     "negative Color": "hsl(41, 70%, 50%)",
									//   },
									//   {
									//     Topic: "Next Call Confirmation",
									//     Positive: findValueForSentimentGraph(
									//       "Next Call Confirmation",
									//       "Positive",
									//       searchFilterData,
									//       true
									//     ),
									//     "positive Color": "hsl(251, 70%, 50%)",
									//     Neutral: findValueForSentimentGraph(
									//       "Next Call Confirmation",
									//       "Neutral",
									//       searchFilterData,
									//       true
									//     ),
									//     "neutral Color": "hsl(41, 70%, 50%)",
									//     Negative: findValueForSentimentGraph(
									//       "Next Call Confirmation",
									//       "Negative",
									//       searchFilterData,
									//       true
									//     ),
									//     "negative Color": "hsl(41, 70%, 50%)",
									//   },
									//   {
									//     Topic: "Introduction",
									//     Positive: findValueForSentimentGraph(
									//       "Introduction",
									//       "Positive",
									//       searchFilterData,
									//       true
									//     ),
									//     "positive Color": "hsl(251, 70%, 50%)",
									//     Neutral: findValueForSentimentGraph(
									//       "Introduction",
									//       "Neutral",
									//       searchFilterData,
									//       true
									//     ),
									//     "neutral Color": "hsl(41, 70%, 50%)",
									//     Negative: findValueForSentimentGraph(
									//       "Introduction",
									//       "Negative",
									//       searchFilterData,
									//       true
									//     ),
									//     "negative Color": "hsl(41, 70%, 50%)",
									//   },
									//   {
									//     Topic: "Lab Monitoring Guidance",
									//     Positive: findValueForSentimentGraph(
									//       "Lab Monitoring Guidance",
									//       "Positive",
									//       searchFilterData,
									//       true
									//     ),
									//     "positive Color": "hsl(251, 70%, 50%)",
									//     Neutral: findValueForSentimentGraph(
									//       "Lab Monitoring Guidance",
									//       "Neutral",
									//       searchFilterData,
									//       true
									//     ),
									//     "neutral Color": "hsl(41, 70%, 50%)",
									//     Negative: findValueForSentimentGraph(
									//       "Lab Monitoring Guidance",
									//       "Negative",
									//       searchFilterData,
									//       true
									//     ),
									//     "negative Color": "hsl(41, 70%, 50%)",
									//   },
									//   {
									//     Topic: "Nurse Educator Role",
									//     Positive: findValueForSentimentGraph(
									//       "Nurse Educator Role",
									//       "Positive",
									//       searchFilterData,
									//       true
									//     ),
									//     "positive Color": "hsl(251, 70%, 50%)",
									//     Neutral: findValueForSentimentGraph(
									//       "Nurse Educator Role",
									//       "Neutral",
									//       searchFilterData,
									//       true
									//     ),
									//     "neutral Color": "hsl(41, 70%, 50%)",
									//     Negative: findValueForSentimentGraph(
									//       "Nurse Educator Role",
									//       "Negative",
									//       searchFilterData,
									//       true
									//     ),
									//     "negative Color": "hsl(41, 70%, 50%)",
									//   },
									//   {
									//     Topic: "Others",
									//     Positive: findValueForSentimentGraph(
									//       "Others",
									//       "Positive",
									//       searchFilterData,
									//       true
									//     ),
									//     "positive Color": "hsl(251, 70%, 50%)",
									//     Neutral: findValueForSentimentGraph(
									//       "Others",
									//       "Neutral",
									//       searchFilterData,
									//       true
									//     ),
									//     "neutral Color": "hsl(41, 70%, 50%)",
									//     Negative: findValueForSentimentGraph(
									//       "Others",
									//       "Negative",
									//       searchFilterData,
									//       true
									//     ),
									//     "negative Color": "hsl(41, 70%, 50%)",
									//   },
									//   {
									//     Topic: "Member Questionnaire",
									//     Positive: findValueForSentimentGraph(
									//       "Member Questionnaire",
									//       "Positive",
									//       searchFilterData,
									//       true
									//     ),
									//     "positive Color": "hsl(251, 70%, 50%)",
									//     Neutral: findValueForSentimentGraph(
									//       "Member Questionnaire",
									//       "Neutral",
									//       searchFilterData,
									//       true
									//     ),
									//     "neutral Color": "hsl(41, 70%, 50%)",
									//     Negative: findValueForSentimentGraph(
									//       "Member Questionnaire",
									//       "Negative",
									//       searchFilterData,
									//       true
									//     ),
									//     "negative Color": "hsl(41, 70%, 50%)",
									//   },
									//   {
									//     Topic: "Safety Recording",
									//     Positive: findValueForSentimentGraph(
									//       "Safety Recording",
									//       "Positive",
									//       searchFilterData,
									//       true
									//     ),
									//     "positive Color": "hsl(251, 70%, 50%)",
									//     Neutral: findValueForSentimentGraph(
									//       "Safety Recording",
									//       "Neutral",
									//       searchFilterData,
									//       true
									//     ),
									//     "neutral Color": "hsl(41, 70%, 50%)",
									//     Negative: findValueForSentimentGraph(
									//       "Safety Recording",
									//       "Negative",
									//       searchFilterData,
									//       true
									//     ),
									//     "negative Color": "hsl(41, 70%, 50%)",
									//   },
									//   {
									//     Topic: "Shipment",
									//     Positive: findValueForSentimentGraph(
									//       "Shipment",
									//       "Positive",
									//       searchFilterData,
									//       true
									//     ),
									//     "positive Color": "hsl(251, 70%, 50%)",
									//     Neutral: findValueForSentimentGraph(
									//       "Shipment",
									//       "Neutral",
									//       searchFilterData,
									//       true
									//     ),
									//     "neutral Color": "hsl(41, 70%, 50%)",
									//     Negative: findValueForSentimentGraph(
									//       "Shipment",
									//       "Negative",
									//       searchFilterData,
									//       true
									//     ),
									//     "negative Color": "hsl(41, 70%, 50%)",
									//   },
									//   {
									//     Topic: "Speciality Pharmacy",
									//     Positive: findValueForSentimentGraph(
									//       "Speciality Pharmacy",
									//       "Positive",
									//       searchFilterData,
									//       true
									//     ),
									//     "positive Color": "hsl(251, 70%, 50%)",
									//     Neutral: findValueForSentimentGraph(
									//       "Speciality Pharmacy",
									//       "Neutral",
									//       searchFilterData,
									//       true
									//     ),
									//     "neutral Color": "hsl(41, 70%, 50%)",
									//     Negative: findValueForSentimentGraph(
									//       "Speciality Pharmacy",
									//       "Negative",
									//       searchFilterData,
									//       true
									//     ),
									//     "negative Color": "hsl(41, 70%, 50%)",
									//   },
									//   {
									//     Topic: "Dosing Information",
									//     Positive: findValueForSentimentGraph(
									//       "Dosing Information",
									//       "Positive",
									//       searchFilterData,
									//       true
									//     ),
									//     "positive Color": "hsl(251, 70%, 50%)",
									//     Neutral: findValueForSentimentGraph(
									//       "Dosing Information",
									//       "Neutral",
									//       searchFilterData,
									//       true
									//     ),
									//     "neutral Color": "hsl(41, 70%, 50%)",
									//     Negative: findValueForSentimentGraph(
									//       "Dosing Information",
									//       "Negative",
									//       searchFilterData,
									//       true
									//     ),
									//     "negative Color": "hsl(41, 70%, 50%)",
									//   },
									// ]}
									keys={["Positive", "Neutral", "Negative"]}
									indexBy="Topic"
									margin={{ top: 10, right: 110, bottom: 30, left: 160 }}
									padding={0.3}
									valueScale={{ type: "linear" }}
									indexScale={{ type: "band", round: true }}
									defs={[
										{
											id: "dots",
											type: "patternDots",
											background: "inherit",
											color: "#38bcb2",
											size: 4,
											padding: 1,
											stagger: true,
										},
										{
											id: "lines",
											type: "patternLines",
											background: "inherit",
											color: "#eed312",
											rotation: -45,
											lineWidth: 6,
											spacing: 10,
										},
									]}
									borderColor={{
										from: "color",
										modifiers: [["darker", 1.6]],
									}}
									axisTop={null}
									axisRight={null}
									axisBottom={{
										tickSize: 0,
										tickPadding: 5,
										tickRotation: 0,
										legend: "",
										legendPosition: "middle",
										legendOffset: 38,
									}}
									axisLeft={{
										tickSize: 5,
										tickPadding: 5,
										tickRotation: 0,
										// legend: "food",
										legendPosition: "middle",
										legendOffset: -40,
									}}
									labelSkipWidth={12}
									labelSkipHeight={12}
									labelTextColor={{
										from: "color",
										modifiers: [["darker", 1.6]],
									}}
									legends={[
										{
											dataFrom: "keys",
											anchor: "bottom-right",
											direction: "column",
											justify: false,
											translateX: 120,
											translateY: 0,
											itemsSpacing: 2,
											itemWidth: 100,
											itemHeight: 20,
											itemDirection: "left-to-right",
											itemOpacity: 0.85,
											symbolSize: 20,
											effects: [
												{
													on: "hover",
													style: {
														itemOpacity: 1,
													},
												},
											],
										},
									]}
									role="application"
									ariaLabel="Current Plan Status Distribution"
								/>
							) : (
								<ResponsiveBar
									layout="horizontal"
									colors={getColor}
									data={[
										{
											Sentiment: "Member",
											Positive: findValueForSentimentGraph("Member", "Positive", searchFilterData, false),
											"positive Color": "hsl(251, 70%, 50%)",
											Neutral: findValueForSentimentGraph("Member", "Neutral", searchFilterData, false),
											"neutral Color": "hsl(41, 70%, 50%)",
											Negative: findValueForSentimentGraph("Member", "Negative", searchFilterData, false),
											"negative Color": "hsl(41, 70%, 50%)",
										},
										{
											Sentiment: "Agent",
											Positive: findValueForSentimentGraph("Agent", "Positive", searchFilterData, false),
											"positive Color": "hsl(251, 70%, 50%)",
											Neutral: findValueForSentimentGraph("Agent", "Neutral", searchFilterData, false),
											"neutral Color": "hsl(41, 70%, 50%)",
											Negative: findValueForSentimentGraph("Agent", "Negative", searchFilterData, false),
											"negative Color": "hsl(41, 70%, 50%)",
										},
									]}
									keys={["Positive", "Neutral", "Negative"]}
									indexBy="Sentiment"
									margin={{ top: 20, right: 130, bottom: 50, left: 60 }}
									padding={0.3}
									valueScale={{ type: "linear" }}
									indexScale={{ type: "band", round: true }}
									defs={[
										{
											id: "dots",
											type: "patternDots",
											background: "inherit",
											color: "#38bcb2",
											size: 4,
											padding: 1,
											stagger: true,
										},
										{
											id: "lines",
											type: "patternLines",
											background: "inherit",
											color: "#eed312",
											rotation: -45,
											lineWidth: 6,
											spacing: 10,
										},
									]}
									borderColor={{
										from: "color",
										modifiers: [["darker", 1.6]],
									}}
									axisTop={null}
									axisRight={null}
									axisBottom={{
										tickSize: 0,
										tickPadding: 5,
										tickRotation: 0,
										legend: "",
										legendPosition: "middle",
										legendOffset: 38,
									}}
									axisLeft={{
										tickSize: 5,
										tickPadding: 5,
										tickRotation: 0,
										// legend: "food",
										legendPosition: "middle",
										legendOffset: -40,
									}}
									labelSkipWidth={12}
									labelSkipHeight={12}
									labelTextColor={{
										from: "color",
										modifiers: [["darker", 1.6]],
									}}
									legends={[
										{
											dataFrom: "keys",
											anchor: "bottom-right",
											direction: "column",
											justify: false,
											translateX: 120,
											translateY: 0,
											itemsSpacing: 2,
											itemWidth: 100,
											itemHeight: 20,
											itemDirection: "left-to-right",
											itemOpacity: 0.85,
											symbolSize: 20,
											effects: [
												{
													on: "hover",
													style: {
														itemOpacity: 1,
													},
												},
											],
										},
									]}
									role="application"
									ariaLabel="Current Plan Status Distribution"
								/>
							)}
						</div>

						<p className="font-semibold mt-5 text-sm mb-3">
							Time on Plan Distribution
							<span className="font-normal float-right">
								Show Current Plan Status
								<Switch className="ml-2" onChange={handleFilters3} />
							</span>
						</p>
						<div className="flex h-60 border-2">
							{therapyStatus ? (
								<ResponsiveLine
									data={[
										{
											id: "On Therapy",
											color: "hsl(331, 70%, 50%)",
											data: [
												{
													x: "30 days",
													y: findValueForToT(-99999, 30, searchFilterData, "On Therapy"),
												},
												{
													x: "60 days",
													y: findValueForToT(30, 60, searchFilterData, "On Therapy"),
												},
												{
													x: "90 days",
													y: findValueForToT(60, 90, searchFilterData, "On Therapy"),
												},
												{
													x: "180 days",
													y: findValueForToT(90, 180, searchFilterData, "On Therapy"),
												},
												{
													x: "360 days",
													y: findValueForToT(180, 360, searchFilterData, "On Therapy"),
												},
												{
													x: "360+ days",
													y: findValueForToT(360, 99999999, searchFilterData, "On Therapy"),
												},
											],
										},
										{
											id: "Discontinued",
											color: "hsl(281, 70%, 50%)",
											data: [
												{
													x: "30 days",
													y: findValueForToT(-99999, 30, searchFilterData, "Discontinued"),
												},
												{
													x: "60 days",
													y: findValueForToT(30, 60, searchFilterData, "Discontinued"),
												},
												{
													x: "90 days",
													y: findValueForToT(60, 90, searchFilterData, "Discontinued"),
												},
												{
													x: "180 days",
													y: findValueForToT(90, 180, searchFilterData, "Discontinued"),
												},
												{
													x: "360 days",
													y: findValueForToT(180, 360, searchFilterData, "Discontinued"),
												},
												{
													x: "360+ days",
													y: findValueForToT(360, 99999999, searchFilterData, "Discontinued"),
												},
											],
										},
										{
											id: "Therapy Repeat",
											color: "hsl(281, 70%, 50%)",
											data: [
												{
													x: "30 days",
													y: findValueForToT(-99999, 30, searchFilterData, "Therapy Repeat"),
												},
												{
													x: "60 days",
													y: findValueForToT(30, 60, searchFilterData, "Therapy Repeat"),
												},
												{
													x: "90 days",
													y: findValueForToT(60, 90, searchFilterData, "Therapy Repeat"),
												},
												{
													x: "180 days",
													y: findValueForToT(90, 180, searchFilterData, "Therapy Repeat"),
												},
												{
													x: "360 days",
													y: findValueForToT(180, 360, searchFilterData, "Therapy Repeat"),
												},
												{
													x: "360+ days",
													y: findValueForToT(360, 99999999, searchFilterData, "Therapy Repeat"),
												},
											],
										},
										{
											id: "In Process",
											color: "hsl(281, 70%, 50%)",
											data: [
												{
													x: "30 days",
													y: findValueForToT(-99999, 30, searchFilterData, "In Process"),
												},
												{
													x: "60 days",
													y: findValueForToT(-99999, 30, searchFilterData, "In Process"),
												},
												{
													x: "90 days",
													y: findValueForToT(-99999, 30, searchFilterData, "In Process"),
												},
												{
													x: "180 days",
													y: findValueForToT(-99999, 30, searchFilterData, "In Process"),
												},
												{
													x: "360 days",
													y: findValueForToT(-99999, 30, searchFilterData, "In Process"),
												},
												{
													x: "360+ days",
													y: findValueForToT(-99999, 30, searchFilterData, "In Process"),
												},
											],
										},
										{
											id: "Unknown",
											color: "hsl(281, 70%, 50%)",
											data: [
												{
													x: "30 days",
													y: findValueForToT(-99999, 30, searchFilterData, "UNKNOWN"),
												},
												{
													x: "60 days",
													y: findValueForToT(30, 60, searchFilterData, "UNKNOWN"),
												},
												{
													x: "90 days",
													y: findValueForToT(60, 90, searchFilterData, "UNKNOWN"),
												},
												{
													x: "180 days",
													y: findValueForToT(90, 180, searchFilterData, "UNKNOWN"),
												},
												{
													x: "360 days",
													y: findValueForToT(180, 360, searchFilterData, "UNKNOWN"),
												},
												{
													x: "360+ days",
													y: findValueForToT(360, 99999, searchFilterData, "UNKNOWN"),
												},
											],
										},
										{
											id: "Never Start",
											color: "hsl(281, 70%, 50%)",
											data: [
												{
													x: "30 days",
													y: findValueForToT(-99999, 30, searchFilterData, "Never Start"),
												},
												{
													x: "60 days",
													y: findValueForToT(30, 60, searchFilterData, "Never Start"),
												},
												{
													x: "90 days",
													y: findValueForToT(60, 90, searchFilterData, "Never Start"),
												},
												{
													x: "180 days",
													y: findValueForToT(90, 180, searchFilterData, "Never Start"),
												},
												{
													x: "360 days",
													y: findValueForToT(180, 360, searchFilterData, "Never Start"),
												},
												{
													x: "360+ days",
													y: findValueForToT(360, 99999999, searchFilterData, "Never Start"),
												},
											],
										},
									]}
									margin={{ top: 20, right: 130, bottom: 60, left: 60 }}
									xScale={{ type: "point" }}
									yScale={{
										type: "linear",
										min: "auto",
										max: "auto",
										stacked: false,
										reverse: false,
									}}
									yFormat=" >-.2f"
									axisTop={null}
									axisRight={null}
									enableGridX={false}
									pointSize={10}
									pointColor={{ theme: "background" }}
									pointBorderWidth={2}
									pointBorderColor={{ from: "serieColor" }}
									pointLabelYOffset={-12}
									useMesh={true}
									axisLeft={{
										format: (e) => Math.floor(e) === e && e,
									}}
									axisBottom={{
										tickSize: 5,
										tickPadding: 5,
										tickRotation: 0,
										legend: <tspan className="font-semibold">Time on Plan</tspan>,
										legendPosition: "middle",
										legendOffset: 40,
									}}
									legends={[
										{
											anchor: "bottom-right",
											direction: "column",
											justify: false,
											translateX: 100,
											translateY: 0,
											itemsSpacing: 0,
											itemDirection: "left-to-right",
											itemWidth: 80,
											itemHeight: 20,
											itemOpacity: 0.75,
											symbolSize: 12,
											symbolShape: "circle",
											symbolBorderColor: "rgba(0, 0, 0, .5)",
											effects: [
												{
													on: "hover",
													style: {
														itemBackground: "rgba(0, 0, 0, .03)",
														itemOpacity: 1,
													},
												},
											],
										},
									]}
								/>
							) : (
								<ResponsiveLine
									data={[
										{
											id: "Total",
											color: "hsl(331, 70%, 50%)",
											data: [
												{
													x: "30 days",
													y: findValueForToT(-99999, 30, searchFilterData),
												},
												{
													x: "60 days",
													y: findValueForToT(30, 60, searchFilterData),
												},
												{
													x: "90 days",
													y: findValueForToT(60, 90, searchFilterData),
												},
												{
													x: "180 days",
													y: findValueForToT(90, 180, searchFilterData),
												},
												{
													x: "360 days",
													y: findValueForToT(180, 360, searchFilterData),
												},
												{
													x: "360+ days",
													y: findValueForToT(360, 99999999, searchFilterData),
												},
											],
										},
									]}
									margin={{ top: 20, right: 80, bottom: 60, left: 50 }}
									xScale={{ type: "point" }}
									yScale={{
										type: "linear",
										min: "auto",
										max: "auto",
										stacked: false,
										reverse: false,
									}}
									axisBottom={{
										tickSize: 5,
										tickPadding: 5,
										tickRotation: 0,
										legend: <tspan className="font-semibold">Time on Plan</tspan>,
										legendPosition: "middle",
										legendOffset: 40,
									}}
									colors="#3287C4"
									yFormat=" >-.2f"
									axisTop={null}
									// gridYValues = {[10,20,30,40,50,60,70,80]}
									axisLeft={{
										format: (e) => Math.floor(e) === e && e,
									}}
									axisRight={null}
									enableGridX={false}
									pointSize={10}
									pointColor={{ theme: "background" }}
									pointBorderWidth={2}
									pointBorderColor={{ from: "serieColor" }}
									pointLabelYOffset={-12}
									useMesh={true}
									legends={[
										{
											anchor: "bottom-right",
											direction: "column",
											justify: false,
											translateX: 100,
											translateY: 0,
											itemsSpacing: 0,
											itemDirection: "left-to-right",
											itemWidth: 80,
											itemHeight: 20,
											itemOpacity: 0.75,
											symbolSize: 12,
											symbolShape: "circle",
											symbolBorderColor: "rgba(0, 0, 0, .5)",
											effects: [
												{
													on: "hover",
													style: {
														itemBackground: "rgba(0, 0, 0, .03)",
														itemOpacity: 1,
													},
												},
											],
										},
									]}
								/>
							)}
						</div> */}

						<p className="font-semibold mt-5 text-sm mb-3">Location of Calls</p>
						<div className="border-2" style={{ height: "240px", overflow: "hidden" }}>
							<div style={{ height: "476px" }}>
								<>{console.log("ResponsiveChoropleth State Results", transcriptData[0].statesResults)}</>
								<ResponsiveChoropleth
									data={transcriptData.length > 0 ? transcriptData[0].statesResults : []}
									features={usFeatures.features}
									margin={{
										top: 0,
										right: 0,
										bottom: 0,
										left: 0,
									}}
									colors={["#D8ECFB", "#99D3FD", "#3B9FE7", "#2A71A4"]}
									domain={[
										0,
										Math.max.apply(
											Math,
											transcriptData[0].statesResults.map(function (o: any) {
												return o.value;
											})
										),
									]}
									unknownColor="#666666"
									label="properties.name"
									projectionScale={140}
									projectionTranslation={[0.52, 0.24]}
									projectionRotation={[0, 0, 0]}
									enableGraticule={false}
									graticuleLineColor="#dddddd"
									borderWidth={0.5}
									borderColor="#152538"
									legends={[
										{
											anchor: "top-right",
											direction: "column",
											justify: false,
											translateX: -60,
											translateY: 120,
											itemsSpacing: 2,
											itemWidth: 100,
											itemHeight: 20,
											itemDirection: "left-to-right",
											itemOpacity: 0.85,
											symbolSize: 20,
											effects: [
												{
													on: "hover",
													style: {
														itemOpacity: 1,
													},
												},
											],
										},
										{
											anchor: "top-right",
											direction: "column",
											justify: false,
											translateX: -60,
											translateY: 120,
											itemsSpacing: 2,
											itemWidth: 100,
											itemHeight: 20,
											itemDirection: "left-to-right",
											itemTextColor: "#666666", // Change text color if needed
											itemOpacity: 0.85,
											symbolSize: 20,
											// label: "0, // Label for unknown color
											effects: [
												{
													on: "hover",
													style: {
														itemOpacity: 1,
													},
												},
											],
										},
									]}
								/>
							</div>
						</div>

						{/* <p className="mt-7 font-semibold text-sm">Key Words and Concepts</p>
						<div className="flex h-60 mt-2 border-2">
							<ResponsiveBar
								layout="horizontal"
								colors={"#3B9FE7"}
								data={temp.reverse()}
								keys={["value"]}
								indexBy="text"
								margin={{ top: 15, right: 30, bottom: 15, left: 200 }}
								padding={0.3}
								valueScale={{ type: "linear" }}
								// indexScale={{ type: "band", round: true }}
								// defs={[
								//   {
								//     id: "dots",
								//     type: "patternDots",
								//     background: "inherit",
								//     color: "#38bcb2",
								//     size: 4,
								//     padding: 1,
								//     stagger: true,
								//   },
								//   {
								//     id: "lines",
								//     type: "patternLines",
								//     background: "inherit",
								//     color: "#eed312",
								//     rotation: -45,
								//     lineWidth: 6,
								//     spacing: 10,
								//   },
								// ]}
								borderColor={{
									from: "color",
									modifiers: [["darker", 1.6]],
								}}
								enableGridY={false}
								axisTop={null}
								axisRight={null}
								axisBottom={null}
								axisLeft={{
									// format: (v) => {
									//   return v.length > 16 ? (
									//     <tspan>
									//       {v.substring(0, 16) + "..."}
									//       <p>{v}</p>
									//     </tspan>
									//   ) : (
									//     v
									//   );
									// },
									tickSize: 0,
									tickPadding: 5,
									tickRotation: 0,
									// legend: "food",
									legendPosition: "middle",
									legendOffset: -40,
								}}
								labelSkipWidth={12}
								labelSkipHeight={20}
								labelTextColor={{
									from: "color",
									modifiers: [["darker", 1.6]],
								}}
								role="application"
								ariaLabel="Current Plan Status Distribution"
							/>
						</div> */}
					</div>
				) : null}
			</div>
		</div>
		// </div>
	);
}
