import { NavLink } from "react-router-dom";
export default function SubNavigation(props: {
	tabs?: {
		subTab: string;
		subSubTabs: string[];
	}[];
	subSubTabs?: string[];
}) {
	return (
		<div
			className="flex items-center w-full shadow-lg"
			style={{ zIndex: 99, position: "relative" }}
		>
			{props.tabs?.map((tab, index) => {
				return (
					<div
						key={tab.subTab}
						className="mx-4 py-3 text-text text-base font-medium "
					>
						<NavLink
							end={index === 0 ? true : false}
							to={
								index === 0
									? ""
									: tab.subTab.split(" ").join("_")
							}
							className={(navData) =>
								navData.isActive
									? "tab-active hover:text-text-teal"
									: "hover:text-text-teal"
							}
						>
							{tab.subTab}
						</NavLink>
					</div>
				);
			})}
			{props.subSubTabs?.map((subTab, index) => {
				return (
					<div
						key={subTab}
						className="mx-4 py-3 text-text text-base font-medium"
					>
						<NavLink
							to={subTab.split(" ").join("_")}
							className={(navData) =>
								navData.isActive
									? "tab-active hover:text-text-teal"
									: "hover:text-text-teal"
							}
						>
							{subTab}
						</NavLink>
					</div>
				);
			})}
		</div>
	);
}
