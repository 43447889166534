import { request } from "./../App";

export const getAnnotations = async (id: string) => {
	const fetchUrl = `story/GetAnnotations?id=${id}`;
	const data = await request
		.get(fetchUrl, { withCredentials: true })
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
		.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	return data;
};
export const deleteAnnotations = async (
	annotationId: number,
	storyId: string,
	elasticSearchId: string
) => {
	const fetchUrl = `story/DeleteAnnotatedSelection?id=${annotationId}&storyId=${storyId}&elasticSearchId=${elasticSearchId}`;
	const data = await request
		.post(fetchUrl, {}, { withCredentials: true })
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
		.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	return data;
};
export const getHighlights = async (id: number) => {
	const fetchUrl = `story/GetEditAnnotatedSelection?id=${id}`;
	const data = await request
		.get(fetchUrl, { withCredentials: true })
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
		.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	return data;
};
export const editHighlights = async (editData: {
	id: number;
	highlightText: string;
	Tags: string[];
	highlightedString: string;
}) => {
	const fetchUrl = "story/EditAnnotatedSelection";
	const data = await request
		.put(fetchUrl, editData, { withCredentials: true })
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
		.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	return data;
};
