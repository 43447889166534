import { request } from "./../App";

export const getPatientList = async () => {
  const fetchUrl = `PatientJourney/GetAllPatientsList`;
  const data = await request
    .post(fetchUrl, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const getPatientDetails = async (patientId: any) => {
  const fetchUrl = `PatientJourney/GetPatientDetails?patientId=` + patientId;
  const data = await request
    .post(fetchUrl, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const getCallTranscriptDetails = async (callId: any) => {
  const fetchUrl = `PatientJourney/GetCallTranscriptDetails?callId=` + callId;
  const data = await request
    .post(fetchUrl, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};
