// import { icons, userTypeEnum } from "../../GlobalThemes";
import React, { useState, useEffect } from "react";
// import AppContext from "../../store";
import { Card } from 'antd';




export default function SummaryCard
(props: {
  storyId?: any;
  dataForNegativeSampling?: any;
  data: any;
  isloaded:boolean;
  expanded?: boolean;
  showTags?: boolean;
  hideProgressBar?: boolean;
  fetchedStoryData?: any;
  fetchStoryData?: any;
  addToStoryApi?: (transcriptData: any, id: any[]) => Promise<boolean>;
  changePinnedStories?: (stories: any) => void;
  addNewStory?: (addData: any) => void;
  pinnedStoriesData?: any;
  transcriptData?: any;
  storyDialogues?: any;
  searchString?: any;
  setSearchString?: any;
  keywords?: string[];
}) 
{

 const [showMore, setShowMore] = useState<boolean>(false);

 return( 
    <>
        {
            props.isloaded && props.data!=""?
            (
                <Card bordered={false} className={" border-b"} style={{borderBottomWidth : "8px"}} >
                    <div className="pb-2 font-semibold text-base text-text-teal w-max">
                        {props.searchString} 
                    </div>
                    <div className="text-base pb-2">
                        {
                            !showMore && props.data.length>840 ?
                            (
                                <p className="text-text-gray-90 px-0 py-1">
                                {props.data.slice(0,840)}
                                </p>
                            )
                            :
                            (
                                <p className="text-text-gray-90 px-0 py-1">
                                {props.data}
                                </p>
                            )
                        }                        
                        <div
                            className="text-text-teal font-normal cursor-pointer w-max pt-2"
                            onClick={() => {
                            setShowMore(!showMore);
                            }}
                        >
                            {showMore ? <>Show less</> : <>Show more</>}
                        </div>
                    </div>         
                </Card>
            ):
            (
                <></>
            )
        }
     </>

   )}



