import React from "react";
import DOMPurify from "dompurify";
import { icons } from "../../GlobalThemes";
import "./style.css";
import Tags from "../Tags";
import { AutoComplete, Select, Skeleton } from "antd";
import * as AnnotatedSelectionsHelper from "../../Pages/SearchEngine/ServiceHelpers/annotatedSelections";

function AddTagsView(props: {
  tags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
  suggestedTags: string[];
  setSuggestedTags: React.Dispatch<React.SetStateAction<string[]>>;
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [isVisible, setIsVisible] = React.useState(false);
  // const [currentTags, setCurrentTags] = React.useState(props.tags);
  // const [suggestedTags, setSuggestedTags] = React.useState(
  // 	props.suggestedTags
  // );
  const removeTag = (tagName: string) => {
    let temp = [...props.tags];
    temp.splice(temp.indexOf(tagName), 1);
    props.setTags(temp);
    // setCurrentTags(temp);
    props.setUpdated(true);
  };
  const handleCancel = () => {
    setIsVisible(false);
  };
  const handleAdd = (tagName: string) => {
    console.log("ADdddd", tagName);
    setIsVisible(false);
    const temp1 = [...props.tags];
    temp1.push(tagName);
    props.setTags(temp1);
    // setCurrentTags([...currentTags, tagName]);
    const temp = [...props.suggestedTags];
    if (temp.indexOf(tagName) !== -1) temp.splice(temp.indexOf(tagName), 1);
    props.setSuggestedTags(temp);
    // setSuggestedTags(temp);
    props.setUpdated(true);
  };

  const addTag = () => {
    setIsVisible(true);
  };
  function PopupContent(props: {
    tags: string[];
    suggestionTags: string[];
    handleAdd: (tagName: string) => void;
  }) {
    const [items, setItems] = React.useState(props.suggestionTags);
    const [err, setErr] = React.useState("");
    const [addItemName, setAddItemName] = React.useState("");
    const [selected, setSelected] = React.useState("");
    const onNameChange = (event: any) => {
      setAddItemName(event.target.value);
    };

    const addItem = () => {
      setErr("");
      if (
        (!items ||
          (addItemName &&
            !items.find(
              (item) =>
                item.localeCompare(addItemName, undefined, {
                  sensitivity: "accent",
                }) === 0
            ))) &&
        !props.tags.find(
          (item) =>
            item.localeCompare(addItemName, undefined, {
              sensitivity: "accent",
            }) === 0
        )
      ) {
        if (items) setItems([...items, addItemName]);
        else setItems([addItemName]);
        setSelected(addItemName);
        props.handleAdd(addItemName);
      } else {
        setErr("Tag already available");
      }
    };

    return (
      <div className="custom-dropdown z-10 relative">
        <AutoComplete
          style={{ width: 200 }}
          options={
            props.suggestionTags
              ? props.suggestionTags.map((option) => {
                  return {
                    value: option,
                  };
                })
              : undefined
          }
          onChange={(value: string) => {
            setSelected(value);
            setAddItemName(value);
            // props.handleAdd(value);
          }}
          onSelect={(value) => {
            setSelected(value);
            props.handleAdd(value);
          }}
          onBlur={() => {
            addItem();
          }}
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              addItem();
            }
          }}
          placeholder="Enter Tag Name"
          filterOption={(inputValue, option) =>
            option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
        />
        {err && <div className="text-red-500">{err}</div>}
      </div>
    );
  }
  return (
    <>
      <div
        style={{ marginLeft: "-1rem", width: "105%" }}
        className="relative bg-white shadow-lg z-10 w-full left-0 -top-8 p-4"
      >
        <div className="text-sm text-black font-semibold">
          Add new tags or edit existing tags for the highlighted portion of the transcript
        </div>
        <div className="py-4">
          <Tags
            data={props.tags}
            type="Header"
            remove={removeTag}
            add={addTag}
            visible={isVisible}
            handleAdd={handleAdd}
            handleCancel={handleCancel}
            popupContent={
              <PopupContent
                tags={props.tags}
                suggestionTags={props.suggestedTags}
                handleAdd={handleAdd}
              />
            }
          />
          {/* <Tags data={["Add New Tag"]} type="Header" add={addTag} /> */}
        </div>
      </div>
    </>
  );
}
function clearHighlight(
  setInnerHTML: React.Dispatch<React.SetStateAction<string>>,
  undoArray: string[],
  setUndoArray: React.Dispatch<React.SetStateAction<string[]>>,
  setUndo: React.Dispatch<React.SetStateAction<number>>,
  undo: number,
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>
) {
  setUpdated(true);
  let reset = document.querySelector(".highlighted-text");
  reset?.classList.remove("highlighted-text");
  setInnerHTML(document.getElementById("highlight-text-container")?.innerHTML || "");
  const temp = [...undoArray];
  temp.push(document.getElementById("highlight-text-container")?.innerHTML || "");

  setUndoArray(temp);
  setUndo(undo + 1);
}
function highlightText(
  // selection: Selection | undefined,
  setInnerHTML: React.Dispatch<React.SetStateAction<string>>,
  undoArray: string[],
  setUndoArray: React.Dispatch<React.SetStateAction<string[]>>,
  setUndo: React.Dispatch<React.SetStateAction<number>>,
  undo: number,
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>
) {
  setUpdated(true);
  let reset = document.querySelector(".highlighted-text");
  reset?.classList.remove("highlighted-text");
  const sel = window.getSelection();
  const range = sel?.getRangeAt(0);
  const selectedText = range?.extractContents();
  //@ts-ignore

  let span = document.createElement("span");
  span.className = "highlighted-text";
  //@ts-ignore
  span.appendChild(selectedText);

  // console.log("Selected", span.innerText);
  //@ts-ignore
  range?.insertNode(span);
  // alert(document.getElementById("highlight-text-container")?.innerHTML);
  setInnerHTML(document.getElementById("highlight-text-container")?.innerHTML || "");
  const temp = [...undoArray];
  temp.splice(undo + 1);
  temp.push(document.getElementById("highlight-text-container")?.innerHTML || "");
  setUndoArray(temp);
  setUndo(undo + 1);
}
// const DUMMY_DATA = {
// 	highlightText:
// 		"<p><span class='font-semibold'>Patient:</span> Oh okay, sure.<br/><br/><span class='font-semibold'>Agent:</span> Are there any other questions I can answer for you?<br/><br/><span class='font-semibold'>Patient:</span> So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.<br/><br/><span class='font-semibold'>Agent:</span> Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.<br/><br/><span class='font-semibold'>Patient:</span> Alrigt. Thanks a lot.</p>",
// 	tags: ["Inquiry", "Concern", "COVID", "Issue with Meds"],
// 	suggestionTags: ["Risk", "Question", "Medication Issues", "Agitated"],
// 	fullTranscript: [
// 		{
// 			Dialogue_no: 1,
// 			speaker: "Patient",
// 			text: "Hello?",
// 		},
// 		{
// 			Dialogue_no: 2,
// 			speaker: "Agent",
// 			text: "Hello, may I speak to <NAME>?",
// 		},
// 		{
// 			Dialogue_no: 3,
// 			speaker: "Patient",
// 			text: "Who am I speaking to?",
// 		},
// 		{
// 			Dialogue_no: 4,
// 			speaker: "Agent",
// 			text: "My name is <NAME>, I'm a nurse educator and I work for Ashfield on behalf of Biogen.",
// 		},
// 		{
// 			Dialogue_no: 5,
// 			speaker: "Patient",
// 			text: "Oh okay, sure.",
// 		},
// 		{
// 			Dialogue_no: 6,
// 			speaker: "Agent",
// 			text: "Are there any other questions I can answer for you?",
// 		},
// 		{
// 			Dialogue_no: 7,
// 			speaker: "Patient",
// 			text: "So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
// 		},
// 		{
// 			Dialogue_no: 8,
// 			speaker: "Agent",
// 			text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
// 		},
// 		{
// 			Dialogue_no: 9,
// 			speaker: "Patient",
// 			text: "Alrigt. Thanks a lot.",
// 		},
// 	],
// };
export default function HighlightDrawer(props: {
  id: number;
  addHighlightedTranscript: () => void;
  removeHighlightedTranscript: () => void;
  setVisible: () => void;
  updateTranscript: (
    id: number,
    editData: {
      id: number;
      highlightText: string;
      Tags: string[];
      highlightedString: string;
    }
  ) => void;
}) {
  const [innerHTML, setInnerHTML] = React.useState("");

  const [data, setData] = React.useState<{
    highlightText: string;
    tags: string[];
    suggestionTags: string[];
    Description: {
      transcripts: { [id: string]: string }[];
    };
  }>();
  const [loading, setLoading] = React.useState(true);
  const [selectHighlight, setSelectHighlight] = React.useState(true);
  const [selectEdit, setSelectEdit] = React.useState(true);
  const [viewTags, setViewTags] = React.useState(false);
  const [undo, setUndo] = React.useState(0);
  const [undoArray, setUndoArray] = React.useState<string[]>([]);
  const [updated, setUpdated] = React.useState<boolean>(false);
  const [tags, setTags] = React.useState<string[]>([]);
  const [suggestedTags, setSuggestedTags] = React.useState<string[]>([]);
  // const [selectedText, setSelectedText] = React.useState<string>("");

  function handleEditHighlight(id: number) {
    const original = undoArray[0];
    const current = undoArray[undo];
    const checkString = '<span class="highlighted-text">';

    // console.log(
    // 	document
    // 		.getElementById("highlight-text-container")
    // 		?.getElementsByClassName("highlighted-text")[0].textContent
    // );
    const highlightedString =
      document
        .getElementById("highlight-text-container")
        ?.getElementsByClassName("highlighted-text")[0]?.textContent || "";

    if (original.indexOf(checkString) === -1 && current.indexOf(checkString) > -1) {
      props.addHighlightedTranscript();
      props.updateTranscript(id, {
        id: id,
        highlightText: undoArray[undo],
        Tags: tags,
        highlightedString: highlightedString,
      });
    } else if (original.indexOf(checkString) > -1 && current.indexOf(checkString) === -1) {
      props.removeHighlightedTranscript();
      props.updateTranscript(id, {
        id: id,
        highlightText: undoArray[undo],
        Tags: tags,
        highlightedString: highlightedString,
      });
    } else {
      props.updateTranscript(id, {
        id: id,
        highlightText: undoArray[undo],
        Tags: tags,
        highlightedString: highlightedString,
      });
    }
    setUpdated(false);
  }

  async function fetchData() {
    const data: any = await AnnotatedSelectionsHelper.getHighlights(props.id);

    if (data) {
      setData(data);
      setLoading(false);
      setUndoArray([data.highlightText]);
      setTags(data.Tags);
      setSuggestedTags(data.suggestionTags);
      setUndo(0);
    }
  }
  React.useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      {!loading && data ? (
        <div>
          <div className="p-4 text-text-gray-80 overflow-auto" style={{ height: "85vh" }}>
            {/* {console.log("Selection : ", selection.current?.toString())} */}
            {!selectEdit ? (
              <>
                <div className="">
                  <>
                    <div
                      style={{
                        position: "sticky",
                        top: -20,
                        padding: "5px",
                        backgroundColor: "white",
                        // border: "2px solid #4CAF50",
                      }}
                      className="text-base flex flex-wrap items-center justify-evenly border-b pb-2"
                    >
                      <div
                        className={"flex cursor-pointer text-text-gray-30"}
                        style={{ pointerEvents: "none" }}
                        onClick={() => {
                          setViewTags(false);
                          setSelectHighlight(!selectHighlight);
                        }}
                      >
                        <div
                          style={{
                            marginTop: "2px",
                            marginRight: "4px",
                          }}
                        >
                          {icons.find((l) => l.id === "HighlightDisabled")?.icon}
                        </div>
                        <span>Highlight Text</span>
                      </div>
                      <div className="border-r h-6"></div>
                      <div
                        title="Undo"
                        style={{ pointerEvents: "none" }}
                        className={
                          undo === 0
                            ? "text-text-gray-30 cursor-pointer"
                            : "text-text-gray-50 cursor-pointer"
                        }
                        onClick={() => {
                          setUndo(undo - 1 >= 0 ? undo - 1 : 0);
                        }}
                      >
                        {icons.find((l) => l.id === "ArrowBack")?.icon}
                      </div>
                      <div className="border-r h-6"></div>
                      <div
                        style={{ pointerEvents: "none" }}
                        title="Redo"
                        className={
                          undo === undoArray.length - 1
                            ? "text-text-gray-30 cursor-pointer"
                            : "text-text-gray-50 cursor-pointer"
                        }
                        onClick={() => {
                          setUndo(undo + 1 < undoArray.length ? undo + 1 : undoArray.length - 1);
                        }}
                      >
                        {icons.find((l) => l.id === "ArrowForward")?.icon}
                      </div>
                      <div className="border-r h-6"></div>
                      <div
                        className="flex cursor-pointer text-text-gray-30"
                        style={{ pointerEvents: "none" }}
                        onClick={() => {
                          clearHighlight(
                            setInnerHTML,
                            undoArray,
                            setUndoArray,
                            setUndo,
                            undo,
                            setUpdated
                          );
                          // setSelectHighlight(false);
                        }}
                      >
                        <div
                          className="text-text-gray-30"
                          style={{
                            marginTop: "2px",
                            marginRight: "4px",
                          }}
                        >
                          {icons.find((l) => l.id === "ClearHighlight")?.icon}
                        </div>
                        Clear All
                      </div>
                      <div className="border-r h-6"></div>
                      <div
                        className="cursor-pointer"
                        style={{
                          // position: "fixed",
                          position: "sticky",
                          top: selectHighlight ? 50 : 25,
                          padding: "5px",
                          marginLeft: "0rem",
                          backgroundColor: "white",
                        }}
                        onClick={() => {
                          setSelectEdit(true);
                        }}
                      >
                        <span className="mx-1 -mt-2">{icons.find((l) => l.id === "Edit")?.icon}</span>
                        Go to edit
                      </div>
                      <div className="border-r h-6"></div>
                      <div
                        className="flex cursor-pointer text-text-gray-30 "
                        onClick={() => {
                          setViewTags(!viewTags);
                          setSelectHighlight(false);
                        }}
                        style={{ pointerEvents: "none" }}
                      >
                        <div
                          className="text-text-teal"
                          style={{
                            marginTop: "2px",
                            marginRight: "4px",
                          }}
                        >
                          {icons.find((l) => l.id === "Tags")?.icon}
                        </div>
                        Tags
                        <div className="text-text-gray-30">
                          {viewTags
                            ? icons.find((l) => l.id === "ArrowDown")?.icon
                            : icons.find((l) => l.id === "ArrowRight")?.icon}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "sticky",
                        top: 15,
                        padding: "5px",
                        backgroundColor: "white",
                      }}
                    >
                      {selectHighlight && (
                        <div className="flex items-center justify-center py-2"></div>
                      )}
                    </div>

                    <div
                      className="highlight-text-container p-6 "
                      onClick={() => {
                        if (selectHighlight)
                          highlightText(
                            // selection.current,
                            setInnerHTML,
                            undoArray,
                            setUndoArray,
                            setUndo,
                            undo,
                            setUpdated
                          );
                      }}
                    >
                      {viewTags && (
                        <AddTagsView
                          tags={tags}
                          setTags={setTags}
                          suggestedTags={suggestedTags}
                          setSuggestedTags={setSuggestedTags}
                          setUpdated={setUpdated}
                        />
                      )}
                    </div>
                  </>
                  <div
                    className="text-base overflow-auto px-6 -my-8"
                    // style={{ height: !updated ? "30em" : "31em" }}
                  >
                    {data.Description.transcripts.map((detail) => {
                      return (
                        <p className="m-0 py-3 text-text-gray-90">
                          <strong>{detail.speaker}</strong>: {detail.text}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    position: "sticky",
                    top: -20,
                    padding: "5px",
                    backgroundColor: "white",
                    // border: "2px solid #4CAF50",
                  }}
                  className="text-base flex flex-wrap items-center justify-evenly border-b pb-2"
                >
                  <div
                    className={
                      selectHighlight
                        ? "flex cursor-pointer border-b-2 border-text-teal"
                        : "flex cursor-pointer "
                    }
                    onClick={() => {
                      setViewTags(false);
                      setSelectHighlight(!selectHighlight);
                    }}
                  >
                    <div
                      style={{
                        marginTop: "2px",
                        marginRight: "4px",
                      }}
                    >
                      {icons.find((l) => l.id === "Highlight")?.icon}
                    </div>
                    <span className={selectHighlight ? "text-text-teal" : ""}>Highlight Text</span>
                  </div>
                  <div className="border-r h-6"></div>
                  <div
                    title="Undo"
                    className={
                      undo === 0
                        ? "text-text-gray-30 cursor-pointer"
                        : "text-text-gray-50 cursor-pointer"
                    }
                    onClick={() => {
                      setUndo(undo - 1 >= 0 ? undo - 1 : 0);
                    }}
                  >
                    {icons.find((l) => l.id === "ArrowBack")?.icon}
                  </div>
                  <div className="border-r h-6"></div>
                  <div
                    title="Redo"
                    className={
                      undo === undoArray.length - 1
                        ? "text-text-gray-30 cursor-pointer"
                        : "text-text-gray-50 cursor-pointer"
                    }
                    onClick={() => {
                      setUndo(undo + 1 < undoArray.length ? undo + 1 : undoArray.length - 1);
                    }}
                  >
                    {icons.find((l) => l.id === "ArrowForward")?.icon}
                  </div>
                  <div className="border-r h-6"></div>
                  <div
                    className="flex cursor-pointer"
                    onClick={() => {
                      clearHighlight(
                        setInnerHTML,
                        undoArray,
                        setUndoArray,
                        setUndo,
                        undo,
                        setUpdated
                      );
                      // setSelectHighlight(false);
                    }}
                  >
                    <div
                      className="text-text-teal"
                      style={{
                        marginTop: "2px",
                        marginRight: "4px",
                      }}
                    >
                      {icons.find((l) => l.id === "ClearHighlight")?.icon}
                    </div>
                    Clear All
                  </div>
                  <div className="border-r h-6"></div>
                  <div
                    className="cursor-pointer"
                    style={{
                      // position: "fixed",
                      position: "sticky",
                      top: selectHighlight ? 50 : 25,
                      padding: "5px",
                      marginLeft: "0rem",
                      backgroundColor: "white",
                    }}
                    onClick={() => {
                      setSelectEdit(false);
                    }}
                  >
                    <span className="mx-1">
                      {icons.find((l) => l.id === "DocumentOnePage")?.icon}
                    </span>
                    Show Full Transcript
                  </div>
                  <div className="border-r h-6"></div>
                  <div
                    className="flex cursor-pointer"
                    onClick={() => {
                      setViewTags(!viewTags);
                      setSelectHighlight(false);
                    }}
                  >
                    <div
                      className="text-text-teal"
                      style={{
                        marginTop: "2px",
                        marginRight: "4px",
                      }}
                    >
                      {icons.find((l) => l.id === "Tags")?.icon}
                    </div>
                    Tags
                    <div className="text-text-gray-30">
                      {viewTags
                        ? icons.find((l) => l.id === "ArrowDown")?.icon
                        : icons.find((l) => l.id === "ArrowRight")?.icon}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    position: "sticky",
                    top: 15,
                    padding: "5px",
                    backgroundColor: "white",
                  }}
                >
                  {selectHighlight && (
                    <div className="flex items-center justify-center py-2">
                      <i>Select Text to highlight</i>
                    </div>
                  )}
                </div>

                <div
                  className="highlight-text-container p-6 "
                  onClick={() => {
                    if (selectHighlight)
                      highlightText(
                        // selection.current,
                        setInnerHTML,
                        undoArray,
                        setUndoArray,
                        setUndo,
                        undo,
                        setUpdated
                      );
                  }}
                >
                  {viewTags && (
                    <AddTagsView
                      tags={tags}
                      setTags={setTags}
                      suggestedTags={suggestedTags}
                      setSuggestedTags={setSuggestedTags}
                      setUpdated={setUpdated}
                    />
                  )}

                  <div
                    id="highlight-text-container"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(undoArray[undo]),
                    }}
                    className="cursor-text"
                  ></div>
                </div>
              </>
            )}
          </div>

          {updated && (
            <div className="px-10 py-2 absolute bottom-0 border-t w-full">
              <button
                className="bg-text-teal text-text-white text-base px-4 py-1"
                onClick={() => {
                  handleEditHighlight(props.id);
                  props.setVisible();
                }}
              >
                Mark Complete
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="py-4 px-10 w-full">
          <Skeleton.Input
            style={{ width: "32.8rem", marginBottom: "2rem" }}
            active
            size={"large"}
          />
          <Skeleton title={false} paragraph={{ rows: 10 }} active />
        </div>
      )}
    </>
  );
}

/*

<p><span className:"bold">Patient<span>: Hello? <span >Who am i taking to?</p>
<p><span className:"bold">Agent<span>: Bla bla</span> bla   </p> 
<p><span className:"bold">Patient<span>: Bla bla bla   </p>

"<p><span class='font-semibold'>Patient:</span> <span class="highlighted-text">Oh okay</span>, sure.<br/><br/><span class='font-semibold'>Agent:</span> Are there any other questions I can answer for you?<br/><br/><span class='font-semibold'>Patient:</span> So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.<br/><br/><span class='font-semibold'>Agent:</span> Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.<br/><br/><span class='font-semibold'>Patient:</span> Alrigt. Thanks a lot.</p>""

*/
