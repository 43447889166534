import { searchQuery } from "../Search";
import * as NodeGraph from "./../../../services/nodeGraph";

export const getNodeJsons: (s3path: string) => Promise<unknown> = async (s3path: string) => {
  const data = await NodeGraph.getNodeJsonsData(s3path);
  // console.log("api", data);
  if (data === null || data === undefined) return null;
  return data;
};

export const getExperiments: () => Promise<unknown> = async () => {
  const data = await NodeGraph.getExperimentData();
  // console.log("api", data);
  if (data === null || data === undefined) return null;
  return data;
};
