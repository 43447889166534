import { filterTabs } from "./ProgramOverview";

export const dashboardTabs = [
	{
		subTab: "KPIs",
		subSubTabs: [],
	},
	{
		subTab: "Program Overview",
		subSubTabs: ["Calls", "Reach", "Agent Activity"],
	},
	{
		subTab: "Patient Engagement",
		subSubTabs: ["Call Participation", "Engagement by Topics"],
	},
	{
		subTab: "Patient Needs",
		subSubTabs: ["Needs", "Sentiment", "Journey"],
	},
	{
		subTab: "Patient Experience",
		subSubTabs: ["Survey Metrics", "Call Experience"],
	},
];

export const highPatientNeedsTabs = [
	{
		subTab: "Churn Predictions",
		subSubTabs: [],
	},
	{
		subTab: "Drivers Summary",
		subSubTabs: [],
	},
	// TODO Commented the tab not in figma
	// {
	// 	subTab: "Overview",
	// 	subSubTabs: [],
	// },
	{
		subTab: "Churn Summary",
		subSubTabs: [],
	},
];

export const fetchedFilters: {
	filter: string;
	type: string;
	optionsObject: {
		id: number;
		name: string;
		subgroup: {
			id: number;
			name: string;
		}[];
	}[];
}[] = [
	{
		filter: "Timeframe_Display",
		type: "select",
		optionsObject: [
			{
				id: 0,
				name: "Quarter",
				subgroup: [],
			},
			{
				id: 1,
				name: "Month",
				subgroup: [],
			},
			// {
			// 	id: 2,
			// 	name: "Week",
			// 	subgroup: [],
			// },
		],
	},
	{
		filter: "Selection_Period",
		type: "date",
		optionsObject: [],
	},
	// {
	// 	filter: "Quarter_Selection_Period",
	// 	type: "date",
	// 	optionsObject: [],
	// },
	{
		filter: "Call_Type",
		type: "select",
		optionsObject: [
			{
				id: 1,
				name: "Inbound",
				subgroup: [],
			},
			{
				id: 2,
				name: "Outbound",
				subgroup: [],
			},
		],
	},
	{
		filter: "Therapy_Status",
		type: "select",
		optionsObject: [
			{
				id: 1,
				name: "On Therapy",
				subgroup: [],
			},
			{
				id: 2,
				name: "Discontinued",
				subgroup: [],
			},
			{
				id: 3,
				name: "Never Started",
				subgroup: [],
			},
		],
	},
	{
		filter: "Call_Intent",
		type: "select",
		optionsObject: [
			{
				id: 1,
				name: "Introduction Call",
				subgroup: [],
			},
			{
				id: 2,
				name: "Follow-Up Call 1",
				subgroup: [],
			},
			{
				id: 3,
				name: "Follow-Up Call 2",
				subgroup: [],
			},
			{
				id: 4,
				name: "Follow-Up Call 3",
				subgroup: [],
			},
			{
				id: 5,
				name: "Inquiry Call",
				subgroup: [],
			},
			{
				id: 6,
				name: "Return Call",
				subgroup: [],
			},
		],
	},
	{
		filter: "Adherence",
		type: "select",
		optionsObject: [
			{
				id: 1,
				name: "High",
				subgroup: [],
			},
			{
				id: 2,
				name: "Medium",
				subgroup: [],
			},
			{
				id: 3,
				name: "Low",
				subgroup: [],
			},
		],
	},
	{
		filter: "Patient_Persona",
		type: "select",
		optionsObject: [
			{
				id: 1,
				name: "High",
				subgroup: [],
			},
			{
				id: 2,
				name: "Medium",
				subgroup: [],
			},
			{
				id: 3,
				name: "Low",
				subgroup: [],
			},
		],
	},
];
export const pageWiseFilters: filterTabs[] = [
	{
		tab: "KPIs",
		filter: [
			"Timeframe_Display",
			"Selection_Period",
			"Start_Date",
			"End_Date",
			// "Call_Type",
			// "Adherence",
			// "Call_Intent",
			// "Therapy_Status",
			// "Patient_Persona",
		],
	},
	{
		tab: "Program Overview",
		filter: [],
		subtab: [
			{
				name: "Calls",
				filters: [
					"Timeframe_Display",
					"Selection_Period",
					"Start_Date",
					"End_Date",
					"Call_Type",
					"Therapy_Status",
					//"Adherence",
					// "Patient_Persona",
				],
			},
			{
				name: "Reach",
				filters: [
					"Timeframe_Display",
					"Selection_Period",
					"Start_Date",
					"End_Date",
					"Call_Type",
					//"Adherence",
					// "Patient_Persona",
				],
			},
			{
				name: "Agent_Activity",
				filters: ["Timeframe_Display", "Selection_Period", "Start_Date", "End_Date"],
			},
		],
	},
	{
		tab: "Patient Engagement",
		filter: [],
		subtab: [
			{
				name: "Call_Participation",
				filters: [
					"Timeframe_Display",
					"Selection_Period",
					"Start_Date",
					"End_Date",
					"Call_Type",
					// "Call_Intent",
					"Therapy_Status",
					//"Adherence",
					// "Patient_Persona",
				],
			},
			{
				name: "Engagement_by_Topics",
				filters: [
					"Timeframe_Display",
					"Selection_Period",
					"Start_Date",
					"End_Date",
					"Call_Type",
					// "Call_Intent",
					"Therapy_Status",
				],
			},
		],
	},
	{
		tab: "Patient Needs",
		filter: [],
		subtab: [
			{
				name: "Needs",
				filters: [
					"Timeframe_Display",
					"Selection_Period",
					"Start_Date",
					"End_Date",
					"Call_Type",
					// "Call_Intent",
					"Therapy_Status",
					//"Adherene",
					// "Patient_Persona",
				],
			},
			{
				name: "Journey",
				filters: [
					"Timeframe_Display",
					"Selection_Period",
					"Start_Date",
					"End_Date",
					"Call_Type",
					// "Call_Intent",
					"Therapy_Status",
				],
			},
			{
				name: "Sentiment",
				filters: [
					"Timeframe_Display",
					"Selection_Period",
					"Start_Date",
					"End_Date",
					"Call_Type",
					// "Call_Intent",
					"Therapy_Status",
					//"Adherene",
					// "Patient_Persona",
				],
			},
		],
	},
	{
		tab: "Patient Experience",
		filter: [],
		subtab: [
			{
				name: "Survey_Metrics",
				filters: [
					"Timeframe_Display",
					"Selection_Period",
					"Start_Date",
					"End_Date",
					"Call_Type",
					// "Call_Intent",
					//"Adherence",
					// "Patient_Persona",
				],
			},
			{
				name: "Call_Experience",
				filters: [
					"Timeframe_Display",
					"Selection_Period",
					"Start_Date",
					"End_Date",
					"Call_Type",
					// "Call_Intent",
					//"Adherence",
					// "Patient_Persona",
				],
			},
		],
	},
];

// const DUMMY_DATA = [
// 	{
// 		calls: [
// 			{
// 				cardName: "Average Patient Volume",
// 				month: ["November 2021"],
// 				value: ["173"],
// 				unit: [""],
// 				vsPrevMonth: "3.5",
// 				topChart: {},
// 				chartName: ["Total Patient Volume"],
// 				xAxisLabel: [],
// 				yAxisLabel: ["# of Patients"],
// 				footer: "",
// 				chartColor: ["#3B9FE7"],
// 				tableLinks: [],
// 				chartAccessor: [],
// 				chartData: [
// 					{
// 						"Feb 21": {
// 							"Series 1": 80,
// 						},
// 						"Mar 21": {
// 							"Series 1": 115,
// 						},
// 						"Apr 21": {
// 							"Series 1": 230,
// 						},
// 						"May 21": {
// 							"Series 1": 65,
// 						},
// 						"Jun 21": {
// 							"Series 1": 85,
// 						},
// 						"Jul 21": {
// 							"Series 1": 125,
// 						},
// 						"Aug 21": {
// 							"Series 1": 80,
// 						},
// 						"Sep 21": {
// 							"Series 1": 80,
// 						},
// 						"Oct 21": {
// 							"Series 1": 115,
// 						},
// 						"Nov 21": {
// 							"Series 1": 65,
// 						},
// 					},
// 				],
// 			},
// 			{
// 				cardName: "Average Duration of Calls",
// 				month: ["November 2021"],
// 				value: ["7"],
// 				unit: "mins",
// 				vsPrevMonth: "3.5",
// 				topChart: {},
// 				chartName: ["Average Duration of Calls"],
// 				xAxisLabel: [],
// 				yAxisLabel: ["Duration of Call (minutes)"],
// 				footer: "",
// 				chartColor: ["#3B9FE7"],
// 				tableLinks: [],
// 				chartAccessor: [],
// 				chartData: [
// 					{
// 						"Feb 21": {
// 							"Series 1": 7,
// 						},
// 						"Mar 21": {
// 							"Series 1": 11,
// 						},
// 						"Apr 21": {
// 							"Series 1": 23,
// 						},
// 						"May 21": {
// 							"Series 1": 6,
// 						},
// 						"Jun 21": {
// 							"Series 1": 1,
// 						},
// 						"Jul 21": {
// 							"Series 1": 14,
// 						},
// 						"Aug 21": {
// 							"Series 1": 8,
// 						},
// 						"Sep 21": {
// 							"# of Patients": 8,
// 						},
// 						"Oct 21": {
// 							"Series 1": 11,
// 						},
// 						"Nov 21": {
// 							"Series 1": 6,
// 						},
// 					},
// 				],
// 			},
// 			{
// 				cardName: ["Average Calls Attempted"],
// 				month: ["November 2021"],
// 				value: ["247"],
// 				unit: "",
// 				vsPrevMonth: "-4.7",
// 				topChart: {},
// 				chartName: [
// 					"Total Calls Attempted",
// 					"% Of Successful Calls",
// 				],
// 				xAxisLabel: [],
// 				yAxisLabel: ["# of Calls", "% of Successful Calls"],
// 				chartColor: ["#7232CF", "#E0C6FF"],
// 				tableLinks: [],
// 				chartAccessor: [],
// 				footer: "",
// 				chartData: [
// 					{
// 						"Feb 21": {
// 							"Successful Calls": 40,
// 							"Total Calls": 120,
// 						},
// 						"Mar 21": {
// 							"Successful Calls": 90,
// 							"Total Calls": 120,
// 						},
// 						"Apr 21": {
// 							"Successful Calls": 55,
// 							"Total Calls": 155,
// 						},
// 						"May 21": {
// 							"Successful Calls": 25,
// 							"Total Calls": 40,
// 						},
// 						"Jun 21": {
// 							"Successful Calls": 85,
// 							"Total Calls": 110,
// 						},
// 						"Jul 21": {
// 							"Successful Calls": 40,
// 							"Total Calls": 75,
// 						},
// 						"Aug 21": {
// 							"Successful Calls": 85,
// 							"Total Calls": 75,
// 						},
// 						"Sep 21": {
// 							"Successful Calls": 45,
// 							"Total Calls": 85,
// 						},
// 						"Oct 21": {
// 							"Successful Calls": 145,
// 							"Total Calls": 35,
// 						},
// 						"Nov 21": {
// 							"Successful Calls": 35,
// 							"Total Calls": 130,
// 						},
// 					},
// 					{
// 						"Feb 21": {
// 							"Successful Calls": 45,
// 							"Total Calls": 55,
// 						},
// 						"Mar 21": {
// 							"Successful Calls": 30,
// 							"Total Calls": 70,
// 						},
// 						"Apr 21": {
// 							"Successful Calls": 30,
// 							"Total Calls": 70,
// 						},
// 						"May 21": {
// 							"Successful Calls": 75,
// 							"Total Calls": 25,
// 						},
// 						"Jun 21": {
// 							"Successful Calls": 15,
// 							"Total Calls": 85,
// 						},
// 						"Jul 21": {
// 							"Successful Calls": 30,
// 							"Total Calls": 70,
// 						},
// 						"Aug 21": {
// 							"Successful Calls": 60,
// 							"Total Calls": 40,
// 						},
// 						"Sep 21": {
// 							"Successful Calls": 90,
// 							"Total Calls": 10,
// 						},
// 						"Oct 21": {
// 							"Successful Calls": 30,
// 							"Total Calls": 70,
// 						},
// 						"Nov 21": {
// 							"Successful Calls": 35,
// 							"Total Calls": 65,
// 						},
// 					},
// 				],
// 			},
// 		],
// 		reach: [
// 			{
// 				cardName: "New Patient Volume",
// 				month: ["November 2021"],
// 				value: ["173"],
// 				unit: [""],
// 				vsPrevMonth: "3.5",
// 				topChart: {},
// 				chartName: ["Total New Patient Volume"],
// 				footer: "",
// 				xAxisLabel: [],
// 				yAxisLabel: ["# of Patients"],
// 				chartColor: ["#3B9FE7"],
// 				tableLinks: [],
// 				chartAccessor: [],
// 				chartData: [
// 					{
// 						"Feb 21": {
// 							"Series 1": 80,
// 						},
// 						"Mar 21": {
// 							"Series 1": 115,
// 						},
// 						"Apr 21": {
// 							"Series 1": 230,
// 						},
// 						"May 21": {
// 							"Series 1": 65,
// 						},
// 						"Jun 21": {
// 							"Series 1": 85,
// 						},
// 						"Jul 21": {
// 							"Series 1": 125,
// 						},
// 						"Aug 21": {
// 							"Series 1": 80,
// 						},
// 						"Sep 21": {
// 							"Series 1": 80,
// 						},
// 						"Oct 21": {
// 							"Series 1": 115,
// 						},
// 						"Nov 21": {
// 							"Series 1": 65,
// 						},
// 					},
// 				],
// 			},
// 			{
// 				cardName: "Call Volume for ",
// 				month: ["November 2021"],
// 				value: ["247"],
// 				unit: [""],
// 				vsPrevMonth: "3.5",
// 				chartName: [
// 					"Patient Call Volume",
// 					"Call Reach",
// 					"Call Volume Comparison",
// 				],
// 				topChart: {},
// 				xAxisLabel: [],
// 				yAxisLabel: ["# of Calls", "% of Patients", "Call Volume"],
// 				chartColor: [
// 					["#3B9FE7"],
// 					["#300C64", "#7232CF", "#BB86FF", "#E8D9FF"],
// 					[
// 						"#3B9FE7",
// 						"#2c78d4",
// 						"#2557cc",
// 						"#1a32ba",
// 						"#1b11ab",
// 						"#140c8a",
// 					],
// 				],
// 				tableLinks: [],
// 				chartAccessor: [],
// 				chartData: [
// 					{
// 						"Feb 21": {
// 							"Series 1": 7,
// 						},
// 						"Mar 21": {
// 							"Series 1": 11,
// 						},
// 						"Apr 21": {
// 							"Series 1": 23,
// 						},
// 						"May 21": {
// 							"Series 1": 6,
// 						},
// 						"Jun 21": {
// 							"Series 1": 1,
// 						},
// 						"Jul 21": {
// 							"Series 1": 14,
// 						},
// 						"Aug 21": {
// 							"Series 1": 8,
// 						},
// 						"Sep 21": {
// 							"Series 1": 8,
// 						},
// 						"Oct 21": {
// 							"Series 1": 11,
// 						},
// 						"Nov 21": {
// 							"Series 1": 6,
// 						},
// 					},
// 					{
// 						"Feb 21": {
// 							"0 Calls": 38,
// 							"1 Call": 24,
// 							"2 Calls": 16,
// 							"3 Calls": 22,
// 						},
// 						"Mar 21": {
// 							"0 Calls": 56,
// 							"1 Call": 24,
// 							"2 Calls": 15,
// 							"3 Calls": 5,
// 						},
// 						"Apr 21": {
// 							"0 Calls": 25,
// 							"1 Call": 33,
// 							"2 Calls": 20,
// 							"3 Calls": 22,
// 						},
// 						"May 21": {
// 							"0 Calls": 30,
// 							"1 Call": 28,
// 							"2 Calls": 18,
// 							"3 Calls": 24,
// 						},
// 						"Jun 21": {
// 							"0 Calls": 56,
// 							"1 Call": 8,
// 							"2 Calls": 20,
// 							"3 Calls": 16,
// 						},
// 						"Jul 21": {
// 							"0 Calls": 42,
// 							"1 Call": 36,
// 							"2 Calls": 16,
// 							"3 Calls": 6,
// 						},
// 						"Aug 21": {
// 							"0 Calls": 50,
// 							"1 Call": 32,
// 							"2 Calls": 10,
// 							"3 Calls": 8,
// 						},
// 						"Sep 21": {
// 							"0 Calls": 22,
// 							"1 Call": 18,
// 							"2 Calls": 40,
// 							"3 Calls": 20,
// 						},
// 						"Oct 21": {
// 							"0 Calls": 30,
// 							"1 Call": 28,
// 							"2 Calls": 18,
// 							"3 Calls": 24,
// 						},
// 						"Nov 21": {
// 							"0 Calls": 18,
// 							"1 Call": 42,
// 							"2 Calls": 26,
// 							"3 Calls": 14,
// 						},
// 					},
// 					{
// 						"Feb 21": {
// 							"0 Days": 40,
// 							"30 Days": 90,
// 							"60 Days": 70,
// 							"90 Days": 62,
// 							"180 Days": 30,
// 							">180 Days": 50,
// 						},
// 						"Mar 21": {
// 							"0 Days": 30,
// 							"30 Days": 78,
// 							"60 Days": 88,
// 							"90 Days": 60,
// 							"180 Days": 60,
// 							">180 Days": 58,
// 						},
// 						"Apr 21": {
// 							"0 Days": 22,
// 							"30 Days": 72,
// 							"60 Days": 56,
// 							"90 Days": 68,
// 							"180 Days": 53,
// 							">180 Days": 62,
// 						},
// 						"May 21": {
// 							"0 Days": 33,
// 							"30 Days": 88,
// 							"60 Days": 60,
// 							"90 Days": 80,
// 							"180 Days": 42,
// 							">180 Days": 68,
// 						},
// 						"Jun 21": {
// 							"0 Days": 41,
// 							"30 Days": 87,
// 							"60 Days": 46,
// 							"90 Days": 76,
// 							"180 Days": 68,
// 							">180 Days": 70,
// 						},
// 						"Jul 21": {
// 							"0 Days": 43,
// 							"30 Days": 94,
// 							"60 Days": 83,
// 							"90 Days": 49,
// 							"180 Days": 62,
// 							">180 Days": 64,
// 						},
// 						"Aug 21": {
// 							"0 Days": 29,
// 							"30 Days": 78,
// 							"60 Days": 36,
// 							"90 Days": 80,
// 							"180 Days": 45,
// 							">180 Days": 72,
// 						},
// 						"Sep 21": {
// 							"0 Days": 32,
// 							"30 Days": 85,
// 							"60 Days": 49,
// 							"90 Days": 82,
// 							"180 Days": 32,
// 							">180 Days": 78,
// 						},
// 						"Oct 21": {
// 							"0 Days": 31,
// 							"30 Days": 82,
// 							"60 Days": 41,
// 							"90 Days": 84,
// 							"180 Days": 38,
// 							">180 Days": 80,
// 						},
// 						"Nov 21": {
// 							"0 Days": 38,
// 							"30 Days": 86,
// 							"60 Days": 58,
// 							"90 Days": 81,
// 							"180 Days": 44,
// 							">180 Days": 82,
// 						},
// 					},
// 				],
// 			},
// 		],
// 		agentActivity: [
// 			{
// 				cardName: "",
// 				month: [""],
// 				value: [""],
// 				unit: [""],
// 				vsPrevMonth: "",
// 				chartName: [
// 					"Agent Activity On Program",
// 					"Agent Activity on Program - Table view",
// 				],
// 				topChart: {},
// 				xAxisLabel: [],
// 				yAxisLabel: ["# of Calls / Day"],
// 				footer: "",
// 				chartColor: ["#3B9FE7"],
// 				tableLinks: [],
// 				chartAccessor: [],
// 				chartData: [
// 					[
// 						{ x: 1, y: 3 },
// 						{ x: -2, y: 1 },
// 						{ x: 3, y: 5 },
// 						{ x: -4, y: -4 },
// 						{ x: 5, y: 1 },
// 						{ x: 3, y: 6 },
// 						{ x: 7, y: -4 },
// 						{ x: 5, y: -4 },
// 						{ x: -2, y: 2 },
// 						{ x: 8, y: -1 },
// 						{ x: -6, y: -3 },
// 						{ x: 4, y: -3 },
// 						{ x: 2, y: 2 },
// 						{ x: -4, y: 1 },
// 						{ x: -3, y: -5 },
// 						{ x: 6, y: 4 },
// 						{ x: 5, y: 2 },
// 						{ x: -5, y: -4 },
// 						{ x: 5, y: 3 },
// 					],
// 					[
// 						[
// 							{
// 								"Agent ID": "00001",
// 								"Calls per Day": 5,
// 								"Inbound Calls": 8,
// 								"Outbound Calls": 3,
// 								"Avg Call Duration (min)": 8,
// 							},
// 							{
// 								"Agent ID": "00002",
// 								"Calls per Day": 5,
// 								"Inbound Calls": 8,
// 								"Outbound Calls": 3,
// 								"Avg Call Duration (min)": 8,
// 							},
// 							{
// 								"Agent ID": "00003",
// 								"Calls per Day": 5,
// 								"Inbound Calls": 8,
// 								"Outbound Calls": 3,
// 								"Avg Call Duration (min)": 8,
// 							},
// 							{
// 								"Agent ID": "00004",
// 								"Calls per Day": 5,
// 								"Inbound Calls": 8,
// 								"Outbound Calls": 3,
// 								"Avg Call Duration (min)": 8,
// 							},
// 							{
// 								"Agent ID": "00005",
// 								"Calls per Day": 5,
// 								"Inbound Calls": 8,
// 								"Outbound Calls": 3,
// 								"Avg Call Duration (min)": 8,
// 							},
// 						],
// 					],
// 				],
// 			},
// 			{
// 				cardName: "All Agents",
// 				kpi: ["Total Calls", "Average Duration of Calls"],
// 				month: ["November 2021", "November 2021"],
// 				value: ["2377", "16"],
// 				unit: ["", "mins"],
// 				vsPrevMonth: "",
// 				topChart: {
// 					"Outbound Calls": 58,
// 					"Inbound Calls": 42,
// 				},
// 				chartName: [
// 					"Trends Of Total Calls",
// 					"Avg. duration of Calls",
// 				],
// 				yAxisLabel: ["# of Calls", "Avg. Duration of Calls"],
// 				xAxisLabel: [],
// 				footer: "",
// 				chartColor: ["#7232CF", "#E0C6FF"],
// 				tableLinks: [],
// 				chartAccessor: [],
// 				chartData: [
// 					{
// 						"Feb 21": {
// 							"Outbound Calls": 30,
// 							"Inbound Calls": 90,
// 						},
// 						"Mar 21": {
// 							"Outbound Calls": 80,
// 							"Inbound Calls": 150,
// 						},
// 						"Apr 21": {
// 							"Outbound Calls": 50,
// 							"Inbound Calls": 170,
// 						},
// 						"May 21": {
// 							"Outbound Calls": 10,
// 							"Inbound Calls": 40,
// 						},
// 						"Jun 21": {
// 							"Outbound Calls": 65,
// 							"Inbound Calls": 145,
// 						},
// 						"Jul 21": {
// 							"Outbound Calls": 25,
// 							"Inbound Calls": 85,
// 						},
// 						"Aug 21": {
// 							"Outbound Calls": 140,
// 							"Inbound Calls": 40,
// 						},
// 						"Sep 21": {
// 							"Outbound Calls": 56,
// 							"Inbound Calls": 190,
// 						},
// 						"Oct 21": {
// 							"Outbound Calls": 68,
// 							"Inbound Calls": 200,
// 						},
// 						"Nov 21": {
// 							"Outbound Calls": 25,
// 							"Inbound Calls": 150,
// 						},
// 					},
// 					{
// 						"Feb 21": {
// 							"Outbound Calls": 60,
// 							"Inbound Calls": 90,
// 						},
// 						"Mar 21": {
// 							"Outbound Calls": 80,
// 							"Inbound Calls": 120,
// 						},
// 						"Apr 21": {
// 							"Outbound Calls": 50,
// 							"Inbound Calls": 170,
// 						},
// 						"May 21": {
// 							"Outbound Calls": 110,
// 							"Inbound Calls": 10,
// 						},
// 						"Jun 21": {
// 							"Outbound Calls": 75,
// 							"Inbound Calls": 125,
// 						},
// 						"Jul 21": {
// 							"Outbound Calls": 125,
// 							"Inbound Calls": 45,
// 						},
// 						"Aug 21": {
// 							"Outbound Calls": 10,
// 							"Inbound Calls": 140,
// 						},
// 						"Sep 21": {
// 							"Outbound Calls": 156,
// 							"Inbound Calls": 90,
// 						},
// 						"Oct 21": {
// 							"Outbound Calls": 168,
// 							"Inbound Calls": 20,
// 						},
// 						"Nov 21": {
// 							"Outbound Calls": 85,
// 							"Inbound Calls": 50,
// 						},
// 					},
// 				],
// 			},
// 		],
// 	},
// ];
