import React, { useEffect, useRef, useState } from "react";
import { select, selectAll, scaleLinear, axisBottom, axisLeft, drag, zoom, zoomIdentity, ZoomTransform, D3ZoomEvent, forceCollide, min, max } from "d3";
import * as lasso from "../../libraries/d3-lasso";
//import { scatterPlotData } from "../../Pages/HighPatientNeeds/Discon/data";

export default function ScatterPlotChart(props: { data: any; onScatterPlatLoad: any; onDrawLassoEnd: any; isLassoEnable: boolean; selectedDriver: string }) {
	console.log("Scattor plot load----->>>>>", props.data);
	// const [chartData, setChartData] = React.useState<any>([]);
	const graphRef = useRef(null);
	const colors = ["#1e88e5", "#1e88e5"];

	const [chartRef, setchartRef] = useState<any>(null);
	const renderChart = (chartData: any) => {
		// The attributes of the riders corresponding to the above colors
		const legendKeys = ["No Doping Allegations", "Doping Allegations"];

		// Create an invisible div for the tooltip
		const tooltip = select("body").append("div").attr("id", "tooltip").style("visibility", "hidden");

		// 1. Load the data from external source
		// d3.json(urlData).then(function (data: any) {

		// 2. Append svg-object for the bar chart to a div in your webpage
		// (here we use a div with id=container)
		const width = 700;
		const height = 390;
		const margin = { left: 90, top: 20, bottom: 60, right: 50 };
		const axisOffset = 1; // How for the axes are moved away from each other
		// var scatterPlotId = document.getElementById('scatterPlot');

		// function drawGraph() {
		const svg = select(graphRef.current)
			// .append("svg")
			// .attr("id", "svgScatterPlot")
			// .attr("width", width)
			//.attr("height", height)
			.attr("viewBox", `0 0 ${width}  ${height}`);

		const selectionArea = svg
			.append("rect")
			.attr("x", margin.left)
			.attr("y", margin.top)
			.attr("width", width - margin.left - margin.right)
			.attr("height", height - margin.top - margin.bottom)
			.style("fill-opacity", 0);
		// }

		// 3. Define scales to translate domains of the data to the range of the svg
		//const [xMin, setXmin] = useState('0');
		const xMin = min(chartData, (d: any) => d.featureValue);
		const xMax: any = max(chartData, (d: any) => d.featureValue);
		//console.log('======xMin', xMin);
		const yMin: any = min(chartData, (d: any) => d.disconImpact);
		const yMax: any = max(chartData, (d: any) => d.disconImpact);

		const xScale = scaleLinear()
			.domain([xMin, xMax])
			.range([margin.left + axisOffset, width - margin.right]);

		const yScale = scaleLinear()
			.domain([yMin, yMax])
			.range([height - margin.bottom - axisOffset, margin.top]);

		// console.log(yMax, yMin);
		// 4. Draw and transform/translate horizontal and vertical axes
		{
			/*
      // @ts-ignore */
		}
		// @ts-ignore */
		const xAxis = axisBottom().scale(xScale).tickSizeOuter(0);
		{
			/*
      // @ts-ignore */
		}
		// @ts-ignore
		const yAxis = axisLeft().scale(yScale).ticks(10).tickSizeOuter(0);
		svg.append("g")
			.attr("transform", "translate(0, " + (height - margin.bottom) + ")")
			.attr("class", "axis")
			.attr("id", "x-axis")
			.call(xAxis)
			.selectAll("text")
			.style("font-family", "Roboto")
			.style("font-size", 14)
			.style("font-style", "normal")
			.style("font-weight", 400)
			.style("fill", "#53565A");

		svg.append("g")
			.attr("transform", "translate(" + margin.left + ", 0)")
			.attr("class", "axis")
			.attr("id", "y-axis")
			.call(yAxis)
			.selectAll("text")
			.style("font-family", "Roboto")
			.style("font-size", 14)
			.style("font-style", "normal")
			.style("font-weight", 400)
			.style("fill", "#53565A");

		// d3.selectAll("#x-axis line")
		//     .style("stroke", "gray");
		// d3.selectAll("#y-axis line")
		//     .style("stroke", "gray");

		// 5. Draw individual scatter points and define mouse events for the tooltip
		const circles = svg
			.selectAll(".scatterPoints")
			.data(chartData)
			.enter()
			.append("circle")
			.attr("cx", (d: any) => xScale(d.featureValue))
			.attr("cy", (d: any) => yScale(d.disconImpact))
			.attr("r", 2.5)
			.attr("fill", (d: any) => (d["Doping"] == "" ? colors[0] : colors[1]))
			.attr("class", "scatterPoints")
			.attr("data-xvalue", (d: any) => d.featureValue)
			.attr("data-yvalue", (d: any) => d.disconImpact)
			.on("click", function (d: any) {
				/*Click on Dot or node event*/
				console.log("click dot===>", d.target.__data__);
			});

		// 6. Finalize chart by adding title, axes labels and legend
		svg.append("text")
			.attr("x", width / 2)
			.attr("y", height - margin.bottom / 5)
			.attr("class", "label")
			.text("Feature Value: " + props.selectedDriver)
			.style("font-family", "Roboto")
			.style("text-anchor", "middle")
			.style("font-size", 14)
			.style("font-style", "normal")
			// .style("opacity", 0.7)
			.style("font-weight", 500)
			.style("fill", "#454250");

		svg.append("text")
			.attr("y", margin.left - 55)
			.attr("x", (-height + margin.bottom) / 2)
			.attr("transform", "rotate(-90)")
			.attr("class", "label")
			.text("Impact on churn")
			.style("font-family", "Roboto")
			.style("font-size", 14)
			.style("text-anchor", "middle")
			.style("font-style", "normal")
			// .style("opacity", 0.7)
			.style("font-weight", 500)
			.style("fill", "#454250");

		// Lasso functions
		const lasso_start = function () {
			circles.classed("possible", false).classed("not_possible", true).classed("selected", false).attr("r", 3.0);
		};

		const lasso_draw = function () {
			circles
				.classed("possible", function () {
					//@ts-ignore
					return (this as any).__lasso.possible;
				})
				.classed("not_possible", function () {
					//@ts-ignore
					return !(this as any).__lasso.possible;
				});
		};

		const lasso_end = function () {
			// circles
			//     .classed("possible", false)
			//     .classed("not_possible", false)
			//     .classed("selected", false)

			const selectedCircles = circles.filter(function () {
				//@ts-ignore
				return (this as any).__lasso.selected;
			});

			const selection = selectedCircles.nodes().map((d: any) => d.__data__);

			console.log(selection);
			circles.classed("possible", false).classed("selected", false);

			if (selection.length) {
				circles.classed("not_possible", true);
			} else {
				circles.classed("not_possible", false);
			}
			selectedCircles.classed("selected", true);
			props.onDrawLassoEnd([{ key: "headache" }]);
		};

		const d3lasso = lasso.lasso();
		d3lasso.items(selectAll(".scatterPoints"));
		d3lasso.targetArea(selectionArea).on("start", lasso_start).on("draw", lasso_draw).on("end", lasso_end);

		svg.call(d3lasso);

		// d3lasso.enable();

		svg.append("clipPath")
			.attr("id", "lassoBound")
			.append("rect")
			.attr("x", margin.left)
			.attr("y", margin.top)
			.attr("width", width - margin.left - margin.right)
			.attr("height", height - margin.top - margin.bottom);

		svg.select("path.drawn").attr("clip-path", "url(#lassoBound)");
		svg.select("path.loop_close").attr("clip-path", "url(#lassoBound)");

		const resetSelection = () => {
			circles.classed("possible", false).classed("selected", false).classed("not_possible", false);
		};

		setchartRef({
			d3lasso,
			resetSelection,
		});
	};
	//}
	// const getData = () => {
	//     fetch('../../../public/Jsons/driversSummary/scatter-plot-curiosity_mean_diet.jsonc'
	//         , {
	//             headers: {
	//                 'Content-Type': 'application/jsonc',
	//                 'Accept': 'application/jsonc'
	//             }
	//         }
	//     )
	//         .then(function (response) {
	//             console.log("11111111----------", response)
	//             return response.json();
	//         })
	//         .then(function (myJson) {
	//             console.log("11111122211----------", myJson)
	//             console.log(myJson);
	//             //setData(myJson)
	//         });
	// }

	// useEffect(() => {
	//     let rest: any = scatterPlotData.find(d => {
	//         return d.title = props.onScatterPlatLoad.showSadnessSideEffect;
	//         //console.log(d.title)
	//     })

	//     if (rest.chartData.length > 0) {
	//         let dataForChart = rest.chartData;
	//         setChartData(dataForChart);
	//         //setXmin(d3.min(dataForChart, (d: any) => d.featureValue))
	//     }
	// }, [props.onScatterPlatLoad.showSadnessSideEffect])

	useEffect(() => {
		if (!chartRef) return;
		chartRef.resetSelection();
		props.isLassoEnable ? chartRef.d3lasso.enable() : chartRef.d3lasso.disable();
	}, [props.isLassoEnable]);

	useEffect(() => {
		if (props.data) {
			props.onDrawLassoEnd([]);
			select(graphRef.current).selectAll("*").remove();
			// setChartData(props.data);
			// setTimeout(() => {
			renderChart(props.data);
			// }, 200);
		}
	}, [props.data]);

	return (
		<>
			<div id="scatterPlot">
				<svg id="svgScatterPlot" ref={graphRef}></svg>
			</div>
		</>
	);
}
