import React from "react";
import { Header } from "../../Components/ReportCards/CommonCardComponents/Header";
import { overAllDisconRate, disconRateRishBucket, disconRateSplitByTactic } from "./data";
import { OverAllDisconRate } from "./OverallDisconRate";
import { DisconRateRishBucket } from "./DisconRateRishBucket";
import * as NodeGraphServiceHelper from "../SearchEngine/ServiceHelpers/nodeGraph";

export default function DisconSummary() {
	// const [fetchData, setFetchData] = React.useState<any>();
	const [overAllDisconRate, setOverAllDisconRate] = React.useState<any>(null);
	const [disconRateRishBucket, setDisconRateRishBucket] = React.useState<any>(null);
	const [disconRateSplitByTactic, setDisconRateSplitByTactic] = React.useState<any>(null);

	async function fetchDataForPage() {
		let data: any = await NodeGraphServiceHelper.getNodeJsons(
			// "kural/json_data/hnp_discon_summ.txt"
			// "kural_hpp_demo/json_data/hnp_discon_summ.txt"
			"kural_hpp_demo/predictive_dashboard_data/hnp_discon_summ.txt"
		);
		if (data) {
			// data = eval(data)
			data = eval("(" + data + ")");
			setOverAllDisconRate(data["overAllDisconRate"]);
			setDisconRateRishBucket(data["disconRateRishBucket"]);
			setDisconRateSplitByTactic(data["disconRateSplitByTactic"]);
			// console.log(data["overAllDisconRate"][0], data["overAllDisconRate"]);
		}
	}

	React.useEffect(() => {
		fetchDataForPage();
	}, []);

	return (
		<>
			{overAllDisconRate && disconRateRishBucket && disconRateSplitByTactic && (
				<div className="discon-summary">
					<div className="grid lg:grid-cols-12 md:grid-cols-1 gap-4">
						<div className="bg-white shadow-md col-span-8 shadow-gray-900 p-4">
							<div className="flex justify-between">
								<OverAllDisconRate data={overAllDisconRate[0]} />
							</div>
						</div>
						<div className="bg-white shadow-md col-span-4 shadow-gray-900 p-4">
							<div className="w-full">
								<DisconRateRishBucket data={disconRateRishBucket[0]} />
							</div>
						</div>
						{/* <div className="bg-white shadow-md col-span-6 shadow-gray-900 p-4">
							<div className="w-full">
								<DisconRateRishBucket data={disconRateSplitByTactic[0]} />
							</div>
						</div> */}
					</div>
				</div>
			)}
		</>
	);
}
