import { Loading3QuartersOutlined } from "@ant-design/icons";
export default function Welome() {
	return (
		<>
			<div className="bg-navbar h-screen">
				<div className="h-5/6 text-white flex items-center justify-center text-5xl font-semibold">
					<div className="flex flex-col items-center">
						<img
							src="logos/ZSLogoFull.png"
							style={{ height: "4em", marginBottom: "4px" }}
						/>
						Kural - Voice of Member
						<div className="p-4">
							<Loading3QuartersOutlined
								style={{ fontSize: 30 }}
								spin
							/>
						</div>
					</div>
				</div>
				<div className="h-1/6 bg-white flex items-end justify-end p-4">
					<div className="text-base text-text-gray-50">
						Built by{" "}
						<span className="text-text-teal">
							Analytic Tools Pod
						</span>
					</div>
				</div>
			</div>
		</>
	);
}
