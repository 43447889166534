import { request } from "./../App";

export const getNodeJsonsData = async (s3path: string) => {
  const fetchUrl = "Explore/GetNodeJsons?s3path=" + s3path;
  const data = await request
    .post(fetchUrl, s3path, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const getExperimentData = async () => {
  const fetchUrl = "Explore/GetExperiments" ;
  const data = await request
    .get(fetchUrl, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};
