import { searchQuery } from "../Search";
import * as ExploreService from "./../../../services/explore";

export const getExploreCardsData: (
  experimentId:any,
  clusterId: any,
  startDate?: any,
  endDate?: any,
  speaker?: any,
  topic?: any,
  totStart?: any,
  totEnd?: any,
  callTypeId?: any,
  therapyStatusId?: any,
  sentiment_id?: any,
  emotion_id?: any
) => Promise<unknown> = async (
  experimentId?:any,
  clusterId?: any,
  startDate?: any,
  endDate?: any,
  speaker?: any,
  topic?: any,
  totStart?: any,
  totEnd?: any,
  callTypeId?: any,
  therapyStatusId?: any,
  sentiment_id?: any,
  emotion_id?: any
) => {
  const data = await ExploreService.getExploreCards(
    experimentId,
    clusterId,
    startDate,
    endDate,
    speaker,
    topic,
    totStart,
    totEnd,
    callTypeId,
    therapyStatusId,
    sentiment_id,
    emotion_id
  );
  // console.log("api", data);
  if (data === null || data === undefined) return null;
  return data;
};

export const changeCardNameData: (clusterId: any, clusterName: any) => Promise<unknown> = async (
  clusterId: any,
  clusterName: any
) => {
  const data = await ExploreService.changeCardName(clusterId, clusterName);
  // console.log("api", data);
  if (data === null || data === undefined) return null;
  return data;
};

export const getClusterDetailsData: (
  clusterId: any,
  startDate?: any,
  endDate?: any,
  speaker?: any,
  topic?: any,
  totStart?: any,
  totEnd?: any,
  callTypeId?: any,
  therapyStatusId?: any,
  sentiment_id?: any,
  emotion_id?: any
) => Promise<unknown> = async (
  clusterId: any,
  startDate?: any,
  endDate?: any,
  speaker?: any,
  topic?: any,
  totStart?: any,
  totEnd?: any,
  callTypeId?: any,
  therapyStatusId?: any,
  sentiment_id?: any,
  emotion_id?: any
) => {
  const data = await ExploreService.getClusterDetails(
    clusterId,
    startDate,
    endDate,
    speaker,
    topic,
    totStart,
    totEnd,
    callTypeId,
    therapyStatusId,
    sentiment_id,
    emotion_id
  );
  // console.log("api", data);
  if (data === null || data === undefined) return null;
  return data;
};
