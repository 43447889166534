import { Divider, Drawer, Select, Skeleton, Space } from "antd";
import React from "react";
import Collapse from "../../../Components/Collapse";
import { SentimentPatientCard } from "../../../Components/ReportCards/CommonCardComponents/SentimentPatientCard";
import {
	SkeletonDetail,
	SkeletonSentiment,
} from "../../../Components/SkeletonCard";
import { icons } from "../../../GlobalThemes";
import ReportTypes from "../../../libraries/report-types";
import * as ReportTypesComponentInterface from "../../../libraries/report-types/charts/interfaces/main";
import { transformBarChartData, transformTopChartData } from "../Processing";
import {
	findMaxGroupValue,
	// findMaxValue,
} from "../ProgramOverview/ProgramOverviewCalls";
import { getPatientNeedsDetails } from "../ServiceHelpers/PatientExperience";

const COLLAPSE_DUMMY_DATA: {
	positive: {
		id: number;
		"Annotated Selection": string;
		"Date Added on": string;
		Source: string;
		Tags: string[];
		Status: string;
		Description: {
			tags: string[];
			dialogueNo: number;
			dialogueAppearedAt: number;
			dialogueLength: number;
			transcriptLength: number;
			transcripts: {
				dialogueNo: number;
				speaker: string;
				text: string;
			}[];
		};
	}[];
	neutral: {
		id: number;
		"Annotated Selection": string;
		"Date Added on": string;
		Source: string;
		Tags: string[];
		Status: string;
		Description: {
			tags: string[];
			dialogueNo: number;
			dialogueAppearedAt: number;
			dialogueLength: number;
			transcriptLength: number;
			transcripts: {
				dialogueNo: number;
				speaker: string;
				text: string;
			}[];
		};
	}[];
	negative: {
		id: number;
		"Annotated Selection": string;
		"Date Added on": string;
		Source: string;
		Tags: string[];
		Status: string;
		Description: {
			tags: string[];
			dialogueNo: number;
			dialogueAppearedAt: number;
			dialogueLength: number;
			transcriptLength: number;
			transcripts: {
				dialogueNo: number;
				speaker: string;
				text: string;
			}[];
		};
	}[];
} = {
	positive: [
		{
			id: 1,
			"Annotated Selection": "Transcript from 12 June 2020 [5:33:23]",
			"Date Added on": "",
			Source: "",
			Tags: [],
			Status: "",
			Description: {
				tags: [],
				dialogueNo: 33,
				dialogueAppearedAt: 0,
				dialogueLength: 0,
				transcriptLength: 0,
				transcripts: [
					{
						dialogueNo: 32,
						speaker: "Agent",
						text: "Are there any other questions I can answer for you?",
					},
					{
						dialogueNo: 33,
						speaker: "Patient",
						text: "So, how long um between vumerity and alcoholic beverage? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
					},
					{
						dialogueNo: 34,
						speaker: "Agent",
						text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
					},
				],
			},
		},
		{
			id: 2,
			"Annotated Selection": "Transcript from 12 June 2020 [5:33:23]",
			"Date Added on": "",
			Source: "",
			Tags: [],
			Status: "",
			Description: {
				tags: [],
				dialogueNo: 33,
				dialogueAppearedAt: 0,
				dialogueLength: 0,
				transcriptLength: 0,
				transcripts: [
					{
						dialogueNo: 32,
						speaker: "Agent",
						text: "Are there any other questions I can answer for you?",
					},
					{
						dialogueNo: 33,
						speaker: "Patient",
						text: "So, how long um between vumerity and alcoholic beverage? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
					},
					{
						dialogueNo: 34,
						speaker: "Agent",
						text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
					},
				],
			},
		},
		{
			id: 3,
			"Annotated Selection": "Transcript from 12 June 2020 [5:33:23]",
			"Date Added on": "",
			Source: "",
			Tags: [],
			Status: "",
			Description: {
				tags: [],
				dialogueNo: 33,
				dialogueAppearedAt: 0,
				dialogueLength: 0,
				transcriptLength: 0,
				transcripts: [
					{
						dialogueNo: 32,
						speaker: "Agent",
						text: "Are there any other questions I can answer for you?",
					},
					{
						dialogueNo: 33,
						speaker: "Patient",
						text: "So, how long um between vumerity and alcoholic beverage? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
					},
					{
						dialogueNo: 34,
						speaker: "Agent",
						text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
					},
				],
			},
		},
	],
	neutral: [
		{
			id: 1,
			"Annotated Selection": "Transcript from 12 June 2020 [5:33:23]",
			"Date Added on": "",
			Source: "",
			Tags: [],
			Status: "",
			Description: {
				tags: [],
				dialogueNo: 33,
				dialogueAppearedAt: 0,
				dialogueLength: 0,
				transcriptLength: 0,
				transcripts: [
					{
						dialogueNo: 32,
						speaker: "Agent",
						text: "Are there any other questions I can answer for you?",
					},
					{
						dialogueNo: 33,
						speaker: "Patient",
						text: "So, how long um between vumerity and alcoholic beverage? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
					},
					{
						dialogueNo: 34,
						speaker: "Agent",
						text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
					},
				],
			},
		},
		{
			id: 2,
			"Annotated Selection": "Transcript from 12 June 2020 [5:33:23]",
			"Date Added on": "",
			Source: "",
			Tags: [],
			Status: "",
			Description: {
				tags: [],
				dialogueNo: 33,
				dialogueAppearedAt: 0,
				dialogueLength: 0,
				transcriptLength: 0,
				transcripts: [
					{
						dialogueNo: 32,
						speaker: "Agent",
						text: "Are there any other questions I can answer for you?",
					},
					{
						dialogueNo: 33,
						speaker: "Patient",
						text: "So, how long um between vumerity and alcoholic beverage? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
					},
					{
						dialogueNo: 34,
						speaker: "Agent",
						text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
					},
				],
			},
		},
		{
			id: 3,
			"Annotated Selection": "Transcript from 12 June 2020 [5:33:23]",
			"Date Added on": "",
			Source: "",
			Tags: [],
			Status: "",
			Description: {
				tags: [],
				dialogueNo: 33,
				dialogueAppearedAt: 0,
				dialogueLength: 0,
				transcriptLength: 0,
				transcripts: [
					{
						dialogueNo: 32,
						speaker: "Agent",
						text: "Are there any other questions I can answer for you?",
					},
					{
						dialogueNo: 33,
						speaker: "Patient",
						text: "So, how long um between vumerity and alcoholic beverage? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
					},
					{
						dialogueNo: 34,
						speaker: "Agent",
						text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
					},
				],
			},
		},
	],
	negative: [
		{
			id: 1,
			"Annotated Selection": "Transcript from 12 June 2020 [5:33:23]",
			"Date Added on": "",
			Source: "",
			Tags: [],
			Status: "",
			Description: {
				tags: [],
				dialogueNo: 33,
				dialogueAppearedAt: 0,
				dialogueLength: 0,
				transcriptLength: 0,
				transcripts: [
					{
						dialogueNo: 32,
						speaker: "Agent",
						text: "Are there any other questions I can answer for you?",
					},
					{
						dialogueNo: 33,
						speaker: "Patient",
						text: "So, how long um between vumerity and alcoholic beverage? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
					},
					{
						dialogueNo: 34,
						speaker: "Agent",
						text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
					},
				],
			},
		},
		{
			id: 2,
			"Annotated Selection": "Transcript from 12 June 2020 [5:33:23]",
			"Date Added on": "",
			Source: "",
			Tags: [],
			Status: "",
			Description: {
				tags: [],
				dialogueNo: 33,
				dialogueAppearedAt: 0,
				dialogueLength: 0,
				transcriptLength: 0,
				transcripts: [
					{
						dialogueNo: 32,
						speaker: "Agent",
						text: "Are there any other questions I can answer for you?",
					},
					{
						dialogueNo: 33,
						speaker: "Patient",
						text: "So, how long um between vumerity and alcoholic beverage? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
					},
					{
						dialogueNo: 34,
						speaker: "Agent",
						text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
					},
				],
			},
		},
		{
			id: 3,
			"Annotated Selection": "Transcript from 12 June 2020 [5:33:23]",
			"Date Added on": "",
			Source: "",
			Tags: [],
			Status: "",
			Description: {
				tags: [],
				dialogueNo: 33,
				dialogueAppearedAt: 0,
				dialogueLength: 0,
				transcriptLength: 0,
				transcripts: [
					{
						dialogueNo: 32,
						speaker: "Agent",
						text: "Are there any other questions I can answer for you?",
					},
					{
						dialogueNo: 33,
						speaker: "Patient",
						text: "So, how long um between vumerity and alcoholic beverage? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
					},
					{
						dialogueNo: 34,
						speaker: "Agent",
						text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
					},
				],
			},
		},
	],
};

/**
 * This function is used to render the dropdown in Sentiment over Time card
 *
 * @param options - This props passes the options in select dropdown
 * @param view - This props is the value of the select dropdown in number
 * @param setView - This props is used to change the value of dropdown
 *
 * @returns the dropdown at right corner in sentiment over time card
 */

function RenderDropdown(props: {
	options: string[];
	view: number;
	setView: React.Dispatch<React.SetStateAction<number>>;
}) {
	var reversedArray = [...props.options];

	return (
		<div>
			View : &nbsp;
			<Select
				className="w-44 h-9"
				value={props.view}
				onChange={(value: number) => {
					props.setView(value);
				}}
			>
				<Select.Option value={0} key={0}>{reversedArray[0]}</Select.Option>
				{reversedArray.reverse().map((option, index) => (
					index < (reversedArray.length - 1) && <Select.Option value={index+1} key={index+1}>{option}</Select.Option>
				))}
			</Select>
		</div>
	);
}

/**
 * This page is used to render the drawer on clicking on sentiment by topic label
 *
 * @returns the drawer from right of the page
 */

export function RenderCollapse(props: { storyDetail: any }) {
	const [fetchedData, setFetchedData] = React.useState<{
		positive: {
			id: number;
			"Annotated Selection": string;
			"Date Added on": string;
			Source: string;
			Tags: string[];
			Status: string;
			therapyStatus:string;
			ToT:number;
			Description: {
				tags: string[];
				dialogueNo: number;
				dialogueAppearedAt: number;
				dialogueLength: number;
				transcriptLength: number;
				transcripts: {
					dialogueNo: number;
					speaker: string;
					text: string;
				}[];
			};
		}[];
		neutral: {
			id: number;
			"Annotated Selection": string;
			"Date Added on": string;
			Source: string;
			Tags: string[];
			Status: string;
			therapyStatus:string;
			ToT:number;
			Description: {
				tags: string[];
				dialogueNo: number;
				dialogueAppearedAt: number;
				dialogueLength: number;
				transcriptLength: number;
				transcripts: {
					dialogueNo: number;
					speaker: string;
					text: string;
				}[];
			};
		}[];
		negative: {
			id: number;
			"Annotated Selection": string;
			"Date Added on": string;
			Source: string;
			Tags: string[];
			Status: string;
			therapyStatus:string;
			ToT:number;
			Description: {
				tags: string[];
				dialogueNo: number;
				dialogueAppearedAt: number;
				dialogueLength: number;
				transcriptLength: number;
				transcripts: {
					dialogueNo: number;
					speaker: string;
					text: string;
				}[];
			};
		}[];
	}>({ positive: [], neutral: [], negative: [] });
	const [view, setView] = React.useState("Positive");
	const [loading, setLoading] = React.useState(true);
	const fetchStoryDetails = async (story: any) => {
		setLoading(true);
		const dataNew: any = await getPatientNeedsDetails(story.id);
		if (dataNew) {
			setFetchedData(dataNew);
			setLoading(false);
		}
	};

	React.useEffect(() => {
		fetchStoryDetails(props.storyDetail);
	}, []);

	return (
		<>
			<div className="px-8 py-2">
				<div className="flex items-center border-b pb-2">
					<div
						className={`${
							view === "Positive"
								? "text-text-teal"
								: "text-text-gray-90"
						} text-base font-medium border-r pr-8 cursor-pointer`}
						onClick={() => setView("Positive")}
					>
						Positive
					</div>
					<div
						className={`${
							view === "Neutral"
								? "text-text-teal"
								: "text-text-gray-90"
						} text-base font-medium border-r px-8 cursor-pointer`}
						onClick={() => setView("Neutral")}
					>
						Neutral
					</div>
					<div
						className={`${
							view === "Negative"
								? "text-text-teal"
								: "text-text-gray-90"
						} text-base font-medium pl-8 cursor-pointer`}
						onClick={() => setView("Negative")}
					>
						Negative
					</div>
				</div>
				<div className="py-4 h-128">
					{loading ? (
						<SkeletonDetail />
					) : (
						<Collapse
							data={
								view === "Positive"
									? fetchedData?.positive
									: view === "Neutral"
									? fetchedData?.neutral
									: fetchedData?.negative
							}
							height="auto"
						/>
					)}
				</div>
			</div>
		</>
	);
}

/**
 * This page is the main patient sentiment tab in patient experience page
 *
 * @param data - This props is the data props
 *
 * @returns the whole patient sentiment page with 3 cards
 */

export function PatientSentiment(props: { data: any }) {
	const [fetchedData, setFetchedData] = React.useState<
		{
			cardName: string;
			kpi: string[];
			month: string[];
			value: number[];
			unit: string[];
			vsPrevMonth: string[];
			vsPrevMonthArray: string[];
			topChart: {
				[id: string]: number;
			};
			chartName: string[];
			xAxisLabel: string[];
			yAxisLabel: string[];
			chartColor: string[];
			chartAccessor: string[];
			footer: string;
			storyData?: {
				id?: string;
				storyName?: string;
			}[];
			chartData: {
				[id: string]: {
					[id: string]: number;
				};
			}[];
		}[]
	>([]);
	const [loading, setLoading] = React.useState(true);
	const [view, setView] = React.useState(0);
	const [visible, setVisible] = React.useState(false);
	const [drawerHeader, setdrawerHeader] = React.useState("");
	const [drawerBody, setDrawerBody] = React.useState(<></>);
	const showDrawer = (topicId: number, header: string, story: any) => {
		setdrawerHeader(header);
		setDrawerBody(<RenderCollapse storyDetail={story} />);
		setVisible(true);
	};
	const onClose = () => {
		setVisible(false);
	};

	React.useEffect(() => {
		setFetchedData(props.data);
		setLoading(false);
	}, []);

	return (
		<>
			{!loading ? (
				<div>
					<Drawer
						destroyOnClose={true}
						title={drawerHeader}
						placement="right"
						onClose={onClose}
						visible={visible}
						width={600}
					>
						{drawerBody}
					</Drawer>
					<div className="bg-white w-full shadow-xl overflow-hidden flex items-start justify-between py-4">
						<div className="border-r w-1/4 px-4 text-text-gray-90 text-base font-semibold">
							<div className="flex gap-2">
								{icons.find((i) => i.id === "Report Pie")?.icon}
								{fetchedData[0].cardName}
							</div>
							<div>
								<ReportTypes
									hideShadow
									hideRibbon
									minHeight="30px"
									height="170px"
									bodyPadding="0px 20px"
									title={{ title: "xx" }}
									chartType={
										ReportTypesComponentInterface.chartType
											.pie
									}
									label={{
										format: (d: any) => {
											console.log(d);
											return `${d.value}%`;
										},
										skipSmallValue: true,
									}}
									// series={series4}
									// data={chartData}
									{...transformTopChartData(
										fetchedData[0].topChart,
										fetchedData[0].chartColor
									)}
									chartConfig={{}}
									style={{
										labelTextColor: "black",
										margin: {
											top: 10,
											right: 140,
											bottom: 10,
											left: 0,
										},
										// labelTextColor: "black",
									}}
									tooltip={{
										isInteractive: true,
										valueFormat: (value: any) => {
											return `${value}%`;
										},
									}}
									legend={{
										position:
											ReportTypesComponentInterface
												.legendPosition.right,
										symbolShape:
											ReportTypesComponentInterface
												.legendSymbolShape.circle,
									}}
								/>
								<div className="text-sm text-gray-500 font-normal text-center -mt-3">
									{fetchedData[0].month}
								</div>
							</div>
						</div>
						<div className="w-3/4 px-4 text-text-gray-90 text-base font-semibold">
							<div className="flex gap-2">
								{
									icons.find((i) => i.id === "Report Thumbs")
										?.icon
								}
								{fetchedData[1].cardName}
							</div>

							<div className="py-10 px-4 w-4/5">
								<div className="flex items-start justify-between">
									{fetchedData[1].kpi.map((k, index) => {
										return (
											<div>
												<div className="text-text-gray-90 text-base font-bold">
													{
														icons.find(
															(l) =>
																l.id ===
																(index === 0
																	? "EmotionHappy"
																	: index ===
																	  1
																	? "EmotionNormal"
																	: "EmotionUnhappy")
														)?.icon
													}{" "}
													{k}
												</div>
												<div className="text-4xl font-semibold text-text-gray-90 flex items-center">
													<div className="ml-5 mr-1">
														{Number(
															fetchedData[1]
																.vsPrevMonthArray[
																index
															]
														) >= 0
															? icons.find(
																	(l) =>
																		l.id ===
																		"PlusOutline"
															  )?.icon
															: icons.find(
																	(l) =>
																		l.id ===
																		"MinusOutline"
															  )?.icon}
													</div>
													<div>
														{` ${Math.abs(
															Number(
																fetchedData[1]
																	.vsPrevMonthArray[
																	index
																]
															)
														)}${
															fetchedData[1].unit[
																index
															]
														}`}
													</div>
												</div>
												<div className="text-sm font-normal text-text-gray-60 mx-4 ml-7">
													{props.data[0]
														.timeframeDisplay ===
													"Quarter"
														? "vs Prev. Quarter"
														: "vs Prev. Month"}
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
					<div className="w-full grid xl:grid-cols-2 lg:grid-cols-1 gap-4 mt-4">
						<div className="bg-white shadow-lg px-4 py-2 w-full">
							<div className=" text-text-gray-90 text-base font-semibold pt-3">
								<div className="flex gap-2">
									{
										icons.find(
											(i) => i.id === "Report Bar Chart"
										)?.icon
									}
									{fetchedData[2].cardName}
								</div>
							</div>

							<div className="w-full">
								<div className="flex px-4 w-full">
									<div
										className="font-semibold text-text-teal w-1/4"
										style={{ marginTop: "34px" }}
									>
										<div
											className="overflow-y-hidden flex flex-col justify-around"
											style={{
												fontSize: "16px",
												height: "292px",
											}}
										>
											{Object.entries(
												fetchedData[2].chartData
													.length > 0
													? fetchedData[2]
															.chartData[0]
													: []
											)
												.reverse()
												.map(([key], index) => {
													return (
														<div
															className="flex items-center overflow-hidden"
															style={
																{
																	// height: "3.95rem",
																	// height: "70px",
																}
															}
															// className={
															// 	index === 0
															// 		? "my-4"
															// 		: "my-8"
															// }
														>
															<div
																className="w-full text-right cursor-pointer text-sm overflow-ellipsis whitespace-nowrap overflow-hidden"
																onClick={() => {
																	console.log(
																		"Key if ",
																		key
																	);
																	var story =
																		fetchedData[2].storyData?.find(
																			(
																				s: any
																			) =>
																				s.storyName ===
																				key
																		);
																	console.log(
																		story
																	);
																	showDrawer(
																		index,
																		key,
																		story
																	);
																}}
															>
																{key}
															</div>
														</div>
													);
												})}
										</div>
									</div>
									<div className="w-3/4">
										<ReportTypes
											header={null}
											hideShadow
											hideRibbon
											// footer="Footer Text"
											minHeight="30px"
											// height="23rem"
											height="400px"
											bodyPadding="5px 24px"
											chartType={
												ReportTypesComponentInterface
													.chartType.bar
											}
											series={
												transformBarChartData(
													fetchedData[2].chartData,
													fetchedData[2].chartColor
												).series
											}
											data={
												transformBarChartData(
													fetchedData[2].chartData,
													fetchedData[2].chartColor
												).data
											}
											label={{
												format: (d: any) => {
													return `${d.value}%`;
												},
												skipSmallValue: true,
												skipWidth: 15,
											}}
											chartArea={{
												layout: ReportTypesComponentInterface
													.chartAreaLayout.horizontal,
											}}
											axis={{
												primaryX: {
													dim: 0,
													tickValues: 5,
													tickRotation: 0,
													legend: "% of Dialogues",
													legendPosition:
														ReportTypesComponentInterface
															.axisLegendPosition
															.middle,
													legendOffset: 37,
												},
												primaryY: { tickSize: 0 },
											}}
											chartConfig={{
												cartesian: {
													line: {
														lineWidth: 3,
														pointSize: 10,
													},
													grid: {
														enableGridX: true,
														enableGridY: false,
														// gridYValues: 3,
														gridXValues: 5,
													},
													gaps: {
														barGap: 0.4,
														groupGap: 0,
													},
												},
											}}
											style={{
												margin: {
													top: 10,
													right: 10,
													bottom: 50,
													left: 5,
												},
												labelTextColor: "black",
												axisWidth: 1,
											}}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="bg-white shadow-lg px-4 py-1">
							<ReportTypes
								// header={`Sentiment Over Time - ${
								// 	fetchedData[3].chartName.length > 0 &&
								// 	fetchedData[3] !== undefined
								// 		? fetchedData[3].chartName[view]
								// 		: ""
								// 	}`}
								header={
									<>
										<div className="flex gap-2">
											{
												icons.find(
													(i) =>
														i.id ===
														"Report Line Chart"
												)?.icon
											}
											{fetchedData[2].cardName}
										</div>
									</>
								}
								borderedHeading
								hideShadow
								hideRibbon={false}
								ribbonData={
									<RenderDropdown
										options={fetchedData[3].chartName}
										view={view}
										setView={setView}
									/>
								}
								minHeight="30px"
								// height="25rem"
								height="430px"
								bodyPadding="0px 0px"
								title={{ title: "xx" }}
								chartType={
									ReportTypesComponentInterface.chartType.line
								}
								series={
									transformBarChartData(
										fetchedData[3].chartData.length > 0 &&
											fetchedData[3] !== undefined
											? fetchedData[3].chartData[view]
											: [],
										fetchedData[3].chartData.length > 0 &&
											fetchedData[3] !== undefined
											? fetchedData[3].chartColor
											: []
									).series
								}
								data={
									transformBarChartData(
										fetchedData[3].chartData.length > 0 &&
											fetchedData[3] !== undefined
											? fetchedData[3].chartData[view]
											: [],
										fetchedData[3].chartData.length > 0 &&
											fetchedData[3] !== undefined
											? fetchedData[3].chartColor
											: []
									).data
								}
								tooltip={{
									isInteractive: true,
									format: "z",
								}}
								axis={{
									primaryX: {
										dim: 0,
										tickValues: 5,
										tickRotation: -45,
										legend: "",
										legendPosition:
											ReportTypesComponentInterface
												.axisLegendPosition.middle,
										legendOffset: 22,
									},
									primaryY: {
										tickSize: 5,
										tickValues: 5,
										tickRotation: 0,
										legend: fetchedData[3].yAxisLabel[0],
										legendPosition:
											ReportTypesComponentInterface
												.axisLegendPosition.middle,
										legendOffset: -45,
										minValue: 0,
										maxValue: findMaxGroupValue(
											transformBarChartData(
												fetchedData[3].chartData
													.length > 0 &&
													fetchedData[3] !== undefined
													? fetchedData[3].chartData[
															view
													  ]
													: [],
												fetchedData[3].chartData
													.length > 0 &&
													fetchedData[3] !== undefined
													? fetchedData[3].chartColor
													: []
											).data
										),
									},
								}}
								legend={{
									position:
										ReportTypesComponentInterface
											.legendPosition.top,
									direction:
										ReportTypesComponentInterface
											.legendDirection.row,
									symbolSize: 15,
									translateX: -2,
									translateY: -37,
									symbolShape:
										ReportTypesComponentInterface
											.legendSymbolShape.circle,
								}}
								chartConfig={{
									cartesian: {
										line: {
											lineWidth: 3,
											pointSize: 10,
										},
										// groupMode: groupMode.grouped,
										grid: {
											enableGridX: false,
											enableGridY: true,
											gridYValues: 5,
											gridXValues: 5,
										},
									},
								}}
								style={{
									margin: {
										top: 50,
										right: 50,
										bottom: 50,
										left: 80,
									},
									axisWidth: 1,
								}}
							/>
						</div>
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	);
}
