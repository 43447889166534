import TableWrapper from "../../../Components/TableWrapper";
import ReportTypes from "../../../libraries/report-types";
import * as ReportTypesComponentInterface from "../../../libraries/report-types/charts/interfaces/main";
import { transformTableData } from "../../Dashboard/Processing";
import { icons } from "../../../GlobalThemes";
import { Empty, Input, Modal, Select, Skeleton, Spin, Table } from "antd";
import React from "react";
import AddStoryModalContent from "../../../Components/AddStoryModal";
import { storyTypes, stories_empty_grouped_columns, storiesEmptyData } from "../data";

/**
 * Renders the Stories Table, along with the various Search and Sort options.
 *
 * @param data - Data to be shown in the Accordion.
 * @param editStory - Callback function for handling updation of story data.
 * @param deleteStory - Callback function for handling deletion a story.
 * @param addNewStory - Callback function for handling addition of a story.
 * @param loading - To specify whether data is getting fetched or not.
 *
 * @returns Stories View.
 */

export default function Stories(props: {
  data: any;
  editStory?: (storyId: number) => void;
  deleteStory?: (storyId: string) => void;
  addNewStory?: (storyName: string, storyType: string) => void;
  loading?: boolean;
}) {
  const [searchedData, setsearchedData] = React.useState(props.data);
  function handleDelete(storyId: string) {
    if (props.deleteStory) props.deleteStory(storyId);
  }
  function handleEdit(storyId: number) {
    if (props.editStory) props.editStory(storyId);
  }

  //   const grouped_columns = [
  //     {
  //       title: "Story Name",
  //       dataIndex: "Story Name",
  //       accessor: "Story_Name",
  //       width: 300,
  //       Cell: (props: any) => {
  //         console.log("props1", props);
  //         if (props.cell.value === "Updating" || props.cell.value.props.children === "Updating")
  //           return (
  //             <div className="text-text-gray-80 text-base">
  //               {icons.find((l) => l.id === "HourGlass")?.icon}
  //               Updating
  //             </div>
  //           );
  //         return <div>{props.cell.value}</div>;
  //       },
  //     },
  //     {
  //       title: "Story Type",
  //       dataIndex: "Story Type",
  //       accessor: "Story_Type",
  //       sort: true,
  //       Cell: (props: any) => {
  //         if (props.cell.value === "Updating")
  //           return (
  //             <div className="text-text-gray-80 text-base">
  //               {icons.find((l) => l.id === "HourGlass")?.icon}
  //               Updating
  //             </div>
  //           );
  //         return <div>{props.cell.value}</div>;
  //       },
  //     },
  //     {
  //       title: "Date Created",
  //       dataIndex: "Date Created",
  //       accessor: "Date_Created",
  //       sort: true,
  //     },
  //     {
  //       title: "# of Annotated Selections",
  //       dataIndex: "# of Annotated Selections",
  //       accessor: "#_of_Annotated_Selections",
  //       sort: true,
  //       Cell: (props: any) => {
  //         return <div style={{ textAlign: "center" }}>{props.cell.value}</div>;
  //       },
  //     },
  //     {
  //       title: "Status",
  //       dataIndex: "Status",
  //       accessor: "Status",
  //       sort: true,
  //       Cell: (props: any) => {
  //         return (
  //           <div
  //             style={{
  //               textAlign: "left",
  //             }}
  //           >
  //             <div className="flex">
  //               {icons.find((l) => l.id === props.cell.value)?.icon}
  //               &nbsp;
  //               {props.cell.value}
  //             </div>
  //           </div>
  //         );
  //       },
  //     },
  //     {
  //       //   title: "Actions",
  //       dataIndex: "Actions",
  //       accessor: "Actions",
  //       renderCell: (props: any) => {
  //         return (
  //           <div className="flex items-center gap-2">
  //             <div
  //               title="Delete Story"
  //               className="text-text-teal flex justify-center cursor-pointer"
  //               onClick={() => {
  //                 handleEdit(searchedData.stories[props.cell.row.index].id);
  //               }}
  //             >
  //               {icons.find((l) => l.id === "Edit")?.icon}
  //             </div>
  //             <div
  //               title="Delete Story"
  //               className="text-text-teal flex justify-center cursor-pointer"
  //               onClick={() => {
  //                 handleDelete(searchedData.stories[props.cell.row.index].id);
  //               }}
  //             >
  //               {icons.find((l) => l.id === "TableDelete")?.icon}
  //             </div>
  //           </div>
  //         );
  //       },
  //       width: 40,
  //     },
  //   ];

  const grouped_columns = [
    {
      title: "Story Name",
      accessor: "Story_Name",
      width: 300,
      Cell: (props: any) => {
        console.log("props1", props);
        if (props.cell.value === "Updating" || props.cell.value.props.children === "Updating")
          return (
            <div className="text-text-gray-80 text-base">
              {icons.find((l) => l.id === "HourGlass")?.icon}
              Updating
            </div>
          );
        return <div>{props.cell.value}</div>;
      },
    },
    {
      title: "Story Type",
      accessor: "Story_Type",
      sort: true,
      Cell: (props: any) => {
        if (props.cell.value === "Updating")
          return (
            <div className="text-text-gray-80 text-base">
              {icons.find((l) => l.id === "HourGlass")?.icon}
              Updating
            </div>
          );
        return <div>{props.cell.value}</div>;
      },
    },
    {
      title: "Date Created",
      accessor: "Date_Created",
      sort: true,
    },
    {
      title: "# of Annotated Selections",
      accessor: "#_of_Annotated_Selections",
      sort: true,
      Cell: (props: any) => {
        return <div style={{ textAlign: "center" }}>{props.cell.value}</div>;
      },
    },
    {
      title: "Status",
      accessor: "Status",
      sort: true,
      Cell: (props: any) => {
        return (
          <div
            style={{
              textAlign: "left",
            }}
          >
            <div className="flex">
              {icons.find((l) => l.id === props.cell.value)?.icon}
              &nbsp;
              {props.cell.value}
            </div>
          </div>
        );
      },
    },
    {
      title: "Actions",
      accessor: "Actions",
      Cell: (props: any) => {
        return (
          <div className="flex items-center gap-2">
            <div
              title="Delete Story"
              className="text-text-teal flex justify-center cursor-pointer icon-width"
              onClick={() => {
                handleEdit(searchedData.stories[props.cell.row.index].id);
              }}
            >
              {icons.find((l) => l.id === "Edit")?.icon}
            </div>
            <div
              title="Delete Story"
              className="text-text-teal flex justify-center cursor-pointer icon-width"
              onClick={() => {
                handleDelete(searchedData.stories[props.cell.row.index].id);
              }}
            >
              {icons.find((l) => l.id === "TableDelete")?.icon}
            </div>
          </div>
        );
      },
      width: 40,
    },
  ];
  const handleSearch = (e: any) => {
    // console.log("Man United Suck", searchedData);
    if (!e.target.value) {
      setsearchedData(props.data);
    } else {
      let temp = { ...props.data };
      temp.stories = temp.stories.filter((story: any) =>
        story["Story Name"].toLowerCase().includes(e.target.value.toLowerCase())
      );
      setsearchedData(temp);
    }
  };

  React.useEffect(() => {
    setsearchedData(props.data);
  }, [props.data]);

  return (
    <>
      {!props.loading ? (
        <>
          {/* <Modal
						destroyOnClose={true}
						title={null}
						visible={isModalVisible}
						footer={null}
						closable={false}
					>
						<AddStoryModalContent
							handleCancel={handleCancel}
							storyTypes={storyTypes}
						/>
					</Modal> */}
          <TableWrapper
            title="Total Story"
            value={searchedData.stories.length}
            createStory
            showModalButton={props.addNewStory}
            // showModal={showModal}
            searchBar={handleSearch}
          >
            <>
              {searchedData.stories.length > 0 ? (
                <>
                  {console.log(
                    "CHECK HERE",
                    transformTableData(
                      searchedData.stories,
                      [],
                      [
                        {
                          id: "Story Name",
                          link: "story-mode",
                        },
                      ],
                      undefined,
                      true
                    )
                  )}
                  <ReportTypes
                    hideShadow
                    hideRibbon
                    chartType={ReportTypesComponentInterface.chartType.simpleTable}
                    series={[]}
                    chartConfig={{
                      table: {
                        tableData: {
                          column: grouped_columns,
                          data: transformTableData(
                            searchedData.stories,
                            [],
                            [
                              {
                                id: "Story Name",
                                link: "story-mode",
                              },
                            ],
                            undefined,
                            true
                          ).data,
                        },
                        grid: {
                          enableGridX: false,
                          enableGridY: false,
                        },
                        noSearchField: true,
                        noPagination: true,
                        // maxHeight: 394,
                        maxHeight: "57vh",
                      },
                    }}
                  />

                  {/* <Table
                    style={{ height: "250" }}
                    columns={grouped_columns}
                    // expandable={{ expandedRowRender, defaultExpandedRowKeys: ["0"] }}
                    dataSource={searchedData.stories}
                    scroll={{ y: 360 }}
                    pagination={false}
                  /> */}
                  {console.log("searchedData", searchedData)}
                </>
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  className="flex h-64 items-center justify-center"
                />
              )}
            </>
          </TableWrapper>
        </>
      ) : (
        <>
          <TableWrapper loading={true} minHeight="37em">
            <ReportTypes
              hideShadow
              hideRibbon
              chartType={ReportTypesComponentInterface.chartType.simpleTable}
              series={[]}
              chartConfig={{
                table: {
                  tableData: {
                    column: stories_empty_grouped_columns,
                    data: storiesEmptyData,
                  },

                  noSearchField: true,
                  noPagination: true,
                  maxHeight: 394,
                },
              }}
            />
          </TableWrapper>
        </>
      )}
    </>
  );
}
