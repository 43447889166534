import { request } from "./../App";
import React, { useState, useEffect, useRef,useContext } from "react";
import AppContext from "../store";
import { searchQuery } from "./../Pages/SearchEngine/Search";



export const getExploreCards = async (
  experimentId:any,
 
  startDate?: any,
  endDate?: any,
  speaker?: any,
  topic?: any,
  totStart?: any,
  totEnd?: any,
  callTypeId?: any,
  therapyStatusId?: any,
  sentiment_id?: any,
  emotion_id?: any,
   clusterId?: any,
) => {
  const fetchUrl = "Explore/GetAllClusters";
 

  const filters: any = {};

  if(experimentId)
  {
    filters.experimentId=experimentId;
  }
  else
  {
    filters.experimentId=1;
  }
  
  
  if(clusterId){
    filters.clusterId = clusterId;
  }

 if (startDate) {
    filters.startDate = startDate;
  }
  if (endDate) {
    filters.endDate = endDate;
  }
  if (speaker) {
    filters.speaker = speaker;
  }
  if (topic) {
    filters.topic = topic;
  }
  if (totStart) {
    filters.totStart = totStart;
    filters.totEnd = totEnd;
  }
  if (callTypeId) {
    filters.callTypeId = callTypeId;
  }
  if (therapyStatusId) {
    filters.therapyStatusId = therapyStatusId;
  }
  if (sentiment_id) {
    filters.sentiment_id = sentiment_id;
  }
  if (emotion_id) {
    filters.emotion_id = emotion_id;
  }

 console.log(filters);
  const data = await request
    .post(fetchUrl, filters, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const changeCardName = async (clusterId: any, clusterName: any) => {
  const fetchUrl = "Explore/EditClusterName?clusterId=" + clusterId + "&clusterName=" + clusterName;
  const data = await request
    .post(fetchUrl, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const getClusterDetails = async (
  clusterId: any,
  startDate?: any,
  endDate?: any,
  speaker?: any,
  topic?: any,
  totStart?: any,
  totEnd?: any,
  callTypeId?: any,
  therapyStatusId?: any,
  sentiment_id?: any,
  emotion_id?: any
) => {
  const fetchUrl = "Explore/GetClusterDetails";
  const filters: any = {};

  filters.clusterId = clusterId;
  if (startDate) {
    filters.startDate = startDate;
  }
  if (endDate) {
    filters.endDate = endDate;
  }
  if (speaker) {
    filters.speaker = speaker;
  }
  if (topic) {
    filters.topic = topic;
  }
  if (totStart) {
    filters.totStart = totStart;
    filters.totEnd = totEnd;
  }
  if (callTypeId) {
    filters.callTypeId = callTypeId;
  }
  if (therapyStatusId) {
    filters.therapyStatusId = therapyStatusId;
  }
  if (sentiment_id) {
    filters.sentiment_id = sentiment_id;
  }
  if (emotion_id) {
    filters.emotion_id = emotion_id;
  }

  const data = await request
    .post(fetchUrl, filters, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};
