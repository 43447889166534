import BgImg from "./LandingBanner.jpg";

/**
 * This is the top banner which is rendered on the landing page
 *
 * @returns heading and description in a banner form on landing page
 */

export default function LandingBanner() {
	return (
		<div
			className="min-h-1/3 px-4 py-2 bg-banner text-text-gray bg-cover bg-right"
			style={{ backgroundImage: `url(${BgImg})` }}
		>
			<div className="flex flex-col justify-center h-full w-2/5 py-8">
				<h2 className="text-xl font-bold mb-4 text-text-gray">
					Voice of Member
				</h2>
				<p className="font-normal font-base tracking-wide">
					Kural uses NLP &amp; AI to understand, identify &amp;
					predict member needs and preferences using voice calls,
					allowing health plan companies to create, measure and proactively
					manage member experience on the product. It enables you to
					go beyond traditional voice analytics by providing
					actionable insights to proactively help improve member
					experience.
				</p>
			</div>
		</div>
	);
}
