import { Breadcrumb, Col, Divider, Row, List, Tag, Tooltip, Skeleton } from "antd";
import React, { useContext } from "react";
import { icons, userTypeEnum } from "../../GlobalThemes";
import { User } from "@styled-icons/boxicons-regular/User";
import { RightOutlined } from "@ant-design/icons";
import { Avatar, Card, Typography } from "antd";
import { Collapse, Space } from "antd";
import TableWrapper from "../../Components/TableWrapper";
import TranscriptsWrapper from "../../Components/TransciptsWrapper";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import timeline from "highcharts/modules/timeline";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { renderToStaticMarkup } from "react-dom/server";
import * as ReportTypesComponentInterface from "../../libraries/report-types/charts/interfaces/main";
import {
  getCallTranscriptDetailsData,
  getPatientDetailsData,
} from "./ServiceHelpers/patientJourney";
import { CallOutbound } from "@styled-icons/fluentui-system-filled/CallOutbound";
import { CallInbound } from "@styled-icons/fluentui-system-filled/CallInbound";
import { Circle } from "@styled-icons/boxicons-solid/Circle";
import { SquareFill } from "@styled-icons/bootstrap/SquareFill";
import moment from "moment";
import AppContext from "../../store";
import { active } from "d3";
import "./style.css";
// HighchartMore(Highcharts);
const { Panel } = Collapse;
HighchartsExporting(Highcharts);
timeline(Highcharts);

export default function PatientDetails(props: {
  patientId?: any;
  showPatientDetails?: any;
  setShowPatientDetails?: any;
}) {
  const state: any = useLocation();
  var idxOfColon:number=0;
  var tempCallDuration:any;
  var min:number=0;
  var sec:number=0;
  const [fetching, setFetching] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [fetchedData, setFetchedData] = React.useState<any>();
  const [patientId, setPatientId] = React.useState(props.patientId);
  const [tempMin, setTempMin] = React.useState(0);
  const [transcriptData, setTranscriptData] = React.useState<any>(null);
  const [selectedTranscript, setSelectedTranscript] = React.useState<any>(null);
  const [bgForList, setBgForList] = React.useState<any>(null);
  const [callId, setCallId] = React.useState<any>(null);
  const [topbarInfo, setTopbarInfo] = React.useState<any>(" ");
  const [startDay, setStartDay] = React.useState<Number>();

  const navigate = useNavigate();
  const context = React.useContext(AppContext);

  (function (H) {
    var each = H.each,
      extend = H.extend;

    /**
     * Hide overlapping labels. Labels are moved and faded in and out on zoom to provide a smooth
     * visual imression.
     */
    //@ts-ignore
    H.Series.prototype.hideOverlappingDataLabels = function () {
      var points = this.points,
        len = points.length,
        i,
        j,
        label1,
        label2,
        intersectRect = function (pos1: any, pos2: any, size1: any, size2: any) {
          return !(
            pos2.x > pos1.x + size1.width ||
            pos2.x + size2.width < pos1.x ||
            pos2.y > pos1.y + size1.height ||
            pos2.y + size2.height < pos1.y
          );
        };

      // Mark with initial opacity
      each(points, function (point: any, label: any) {
        label = point.dataLabel;
        if (label) {
          label.oldOpacity = label.opacity;
          label.newOpacity = 1;
        }
      });

      // Detect overlapping labels
      for (i = 0; i < len - 1; ++i) {
        //@ts-ignore
        label1 = points[i].dataLabel;

        for (j = i + 1; j < len; ++j) {
          //@ts-ignore
          label2 = points[j].dataLabel;
          if (
            label1 &&
            label2 &&
            label1.newOpacity !== 0 &&
            label2.newOpacity !== 0 &&
            intersectRect(label1.alignAttr, label2.alignAttr, label1, label2)
          ) {
            //@ts-ignore
            (points[i].labelrank < points[j].labelrank ? label1 : label2).newOpacity = 0;
          }
        }
      }

      // Hide or show
      each(points, function (point: any, label: any) {
        label = point.dataLabel;
        if (label) {
          if (label.oldOpacity !== label.newOpacity) {
            label[label.isOld ? "animate" : "attr"](
              extend({ opacity: label.newOpacity }, label.alignAttr)
            );
          }
          label.isOld = true;
        }
      });
    };

    H.wrap(H.Series.prototype, "drawDataLabels", function (proceed) {
      //@ts-ignore
      proceed.call(this);
      //@ts-ignore
      this.hideOverlappingDataLabels();
    });
  })(Highcharts);

  const handleClick = () => {
    navigate("/search/Patient_Journey");
    props.setShowPatientDetails(false);
  };
  let { patientID } = useParams();
  let linkUrl = window.location.href;
  let x = linkUrl.lastIndexOf("patientID=");
  let y: any = 1;
  if (x !== -1) {
    y = linkUrl.substring(x + 10);
  }

  let dummyData: any = [];

  async function fetchdata() {
    const data: any = await getPatientDetailsData(y);
    if (data) {
      // setFetching(false);
      data.callDetails.sort(function (a: any, b: any) {
        return a.callStartDate - b.callStartDate;
      });
      console.log(data);
      data.callDetails.length > 0 ? setCallId(data.callDetails[0].callId) : setCallId(null);
      fetchDataForTranscript(data.callDetails[0].callId);
      
      let info = {
        startDate: moment(data.callDetails[0].callStartDate).format("DD MMM YYYY"),
        callTime: data.callDetails[0].callStartTime,
        programName: data.callDetails[0].programName,
        callType: data.callDetails[0].callType,
      };
      setStartDay(Date.parse(data.callDetails[0].callStartDate));
      setTopbarInfo(info);
      setBgForList("0");

      setFetchedData(data);
      setLoading(true);
    }
  }

  fetchedData &&
    fetchedData.callDetails.map((call: any, index: any) => {
      // if (index < 6) {
      let tempObj: any = {};
      let flag: boolean = false;
      if (call.callId === callId) flag = true;
      if (call.callId === callId && flag) {
        tempObj.dataLabels = {
          enabled: true,
          zIndex: 10,
          // enabled: true,
          // distance:
          allowOverlap: false,
          connectorColor: "#27A6A4",
          connectorNeighbourDistance: 20,
          connectorWidth: 2,
          borderColor: call.callId === callId ? "#27A6A4" : "#AAA",
          borderWidth: 2,
          backgroundColor: call.callId === callId ? "#EAF7F6" : "white",
          // style: {
          //   textOverflow: "ellipsis",
          // },
          // attr: {
          //   fill: "#EAF7F6",
          //   stroke: "#27A6A4", // basic
          //   "stroke-width": 2, // hyphenated
          //   // rotation: 45, // custom
          // },
          shadow: {
            color: "#DEDCDE",
            offsetX: 2,
            opacity: 1,
          },
          style: {
            fill: "red",
            borderColor: "red",
            strokeWidth: "2px",
          },
          labelOptions: {
            borderRadius: 0,
            backgroundColor: "red",
            borderWidth: 12,
            borderColor: "blue",
          },
          formatter: function () {
            return renderToStaticMarkup(
              <div>
                <span
                  style={{
                    paddingTop: "20px",
                    color: "#27A6A4",
                    fontWeight: "800",
                  }}
                >
                  {moment(call.callStartDate).format("DD MMM YYYY")} [{call.callStartTime}]
                </span>
                <br></br>
                <br></br>
                <span style={{ paddingTop: "20px" }}>{call.programName}</span>
                <br></br>
                <span style={{ fontWeight: "600" }}>
                  Unmet Needs: <span style={{ fontWeight: "200" }}>{call.unmetNeeds}</span>
                </span>
                <br></br>
                {call.callId === callId && !call.topic && (
                  <span style={{ fontWeight: "600" }}>
                    <Typography>
                      Topics:
                      {call.topic}
                    </Typography>
                  </span>
                )}
              </div>
            );
          },
        };
        flag = false;
      } else {
        tempObj.dataLabels = {
          // enabled: call.callId === callId && true,
          // enabled: true,
          allowOverlap: false,
          connectorColor: "#27A6A4",
          connectorNeighbourDistance: 10,
          connectorWidth: 2,
          borderColor: call.callId === callId ? "#27A6A4" : "#AAA",
          borderWidth: 2,
          backgroundColor: call.callId === callId ? "#EAF7F6" : "white",
          // style: {
          //   textOverflow: "ellipsis",
          // },
          attr: {
            fill: "#EAF7F6",
            stroke: "#27A6A4", // basic
            "stroke-width": 2, // hyphenated
            // rotation: 45, // custom
          },
          zIndex: 2,
          shadow: {
            color: "#DEDCDE",
            offsetX: 2,
            opacity: 1,
          },
          style: {
            fill: "red",
            borderColor: "red",
            strokeWidth: "2px",
          },
          labelOptions: {
            borderRadius: 0,
            backgroundColor: "red",
            borderWidth: 12,
            borderColor: "blue",
          },
          formatter: function () {
            return renderToStaticMarkup(
              <div>
                <span
                  style={{
                    paddingTop: "20px",
                    color: "#27A6A4",
                    fontWeight: "800",
                  }}
                >
                  {moment(call.callStartDate).format("DD MMM YYYY")} [{call.callStartTime}]
                </span>
                <br></br>
                <br></br>
                <span style={{ paddingTop: "20px" }}>{call.programName}</span>
                <br></br>
                <span style={{ fontWeight: "600" }}>
                  Unmet Needs: <span style={{ fontWeight: "200" }}>{call.unmetNeeds}</span>
                </span>
                <br></br>
                {call.callId === callId && !call.topic && (
                  <span style={{ fontWeight: "600" }}>
                    <Typography>
                      Topics:
                      {call.topic}
                    </Typography>
                  </span>
                )}
              </div>
            );
          },
          // };
        };
      }

      let dt = call.callStartDate;
      // let dd = dt.substring(8, 10);
      // let mm = dt.substring(5, 7);
      // let yy = dt.substring(0, 4);
      tempObj.x = Date.parse(dt);
      // Date.UTC(yy, mm - 1, dd);
      tempObj.id = index;

      tempObj.name =
        '<span style="color: #27A6A4">' +
        moment(call.callStartDate).format("DD MMM YYYY") +
        " [" +
        call.callStartTime +
        "]</span><br><br/>";
      tempObj.tooltip = {
        borderColor: "#27A6A4",
        enabled: true,
        style: {
          fontFamily: "roboto",
        },
        formatter: function () {
          return renderToStaticMarkup(
            <div>
              The value for <b>{this.x.toLocaleDateString("DD-MMM-YYYY")} </b> is <b>{this.y} </b>
            </div>
          );
        },
      };
      tempObj.index = index;
      tempObj.icon = "https://i.ibb.co/7S1Npwr/Unsuccesful-Call-2.png";
      tempObj.uuid = call.callId;
      // tempObj.tooltip = {
      //   formatter: function () {
      //     return renderToStaticMarkup(
      //       <div>
      //         The value for <b>{this.x.toLocaleDateString("DD-MMM-YYYY")} </b> is <b>{this.y} </b>
      //       </div>
      //     );
      //   },
      // };

      tempObj.marker = {
        // radius: call.callId === callId ? 8 : 7,
        fill: "#EAF7F6",
        // stroke: "black", // basic
        "stroke-width": 2,
        symbol:
          call.callType === "Outbound"
            ? "circle"
            : call.callType === "Inbound"
            ? "circle"
            : "diamond",
        lineColor: call.callId === callId ? "black" : null,
        radius: 9,
        fillColor:
          call.callType === "Outbound"
            ? call.callId === callId
              ? "grey"
              : "#2F6F7B"
            : call.callType === "Inbound"
            ? call.callId === callId
              ? "grey"
              : "#27A6A4"
            : call.callId === callId
            ? "grey"
            : "#9C9AA1",
      };
      dummyData.push(tempObj);
      // }
    });

  React.useEffect(() => {
    fetchdata();
  }, []);

  async function fetchDataForTranscript(callId: any) {
    const data: any = await getCallTranscriptDetailsData(callId);
    if (data) {
      setTranscriptData(data);
    }

    console.log(data);

    // tempCallDuration=transcriptData.actualCallDuration;

    // var tempLength=tempCallDuration.concat(" sec");

    // var tempTime=tempLength.replace(':', " min ");

    // setTempMin(tempTime);
    // console.log(tempMin);

    

    // idxOfColon=tempCallDuration.indexOf(':',0);
    // console.log("idxOfColon:" + idxOfColon);
  }

  const showTranscriptForCall = (
    callId: any,
    startDate: any,
    callTime: any,
    programName: any,
    callType: any,
    index: any
  ) => {
    fetchDataForTranscript(callId);
    setSelectedTranscript(callId);
    setFetching(true);
    setCallId(callId);
    setFetching(false);
    setBgForList(index + "");
    let info = {
      startDate: startDate,
      callTime: callTime,
      programName: programName,
      callType: callType,
    };
    setTopbarInfo(info);
  };

  const options: Highcharts.Options = {
    chart: {
      type: "timeline",
      style: {
        fontFamily: "roboto",
      },
      events: {
        load: function () {
          var chart = this;

          // chart.series[0].points.forEach(function (point: any) {
          //   point.dataLabel.on("mouseover", function () {
          //     point.dataLabel.box.css({
          //       enabled: true,
          //       fill: "#EAF7F6",
          //     });
          //   });

          //   point.dataLabel.on("mouseout", function () {
          //     point.dataLabel.box.css({
          //       fill: "white",
          //     });
          //   });
          // });
        },
      },
    },

    annotations: [
      {
        labels: [
          {
            point: "0",
            shadow: true,
          },
          {
            point: "1",
            shadow: {
              color: "red",
              offsetX: -1,
              opacity: 0.3,
            },
          },
          {
            point: "2",
            padding: 10,
          },
          {
            point: "3",
            style: {
              fontSize: "8px",
            },
          },
          {
            point: "4",
            borderWidth: 3,
          },
          {
            point: "5",
          },
        ],
        labelOptions: {
          borderRadius: 0,
          backgroundColor: "rgba(252, 255, 197, 0.7)",
          borderWidth: 1,
          borderColor: "green",
        },
      },
    ],

    xAxis: {
      type: "datetime",
      visible: true,
      offset: -170,
      tickInterval: 24 * 3600 * 1000 * 30,
      // floor: Date.UTC(2021, 2, 10),
      showEmpty: true,
      // ceiling: Date.UTC(2022, 4, 1),
      labels: {
        padding: 0,
        formatter: function () {
          {
            console.log(this);
          }
          return renderToStaticMarkup(
            <div>
              Day{" "}
              {
                //@ts-ignore
                Math.floor((this.value - startDay) / 86400000)
              }
            </div>
          );
        },

        style: {
          color: "#7596b7",
        },
        useHTML: true,
        // formatter: function () {
        //   return Highcharts.dateFormat("%b %Y", this.value).toUpperCase();
        // },
      },
    },
    yAxis: [
      {
        title: {
          text: "Therapy Start",
          style: {
            color: "#000",
            fontWeight: "bold",
            font: "Roboto",
          },
        },
        labels: {
          enabled: false,
        },
      },
      {
        title: {
          text: "Present",
          style: {
            color: "#000",
            fontWeight: "bold",
            font: "Roboto",
          },
        },
        labels: {
          enabled: false,
        },
        opposite: true,
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    plotOptions: {
      series: {
        color: "#FF0000",
      },
    },

    series: [
      {
        point: {
          events: {
            click: function (e: any) {
              setSelectedTranscript(e.point.id + "");
              // getDetailsForTranscriptData(e.point.id);
              // showTranscriptForCall();
              setBgForList(e.point.id + "");
              setFetching(true);
              setCallId(e.point.uuid);
              // setCallId()

              let points = this.series.points;
              this.series.chart.redraw();

              //@ts-ignore
              // this.dataLabel.attr({
              //   fill: "#EAF7F6",
              //   stroke: "#27A6A4", // basic
              //   "stroke-width": 2, // hyphenated
              //   // rotation: 45, // custom
              // });
            },
          },
        },
        allowPointSelect: true,
        marker: {
          states: {
            // select: {
            //   radius: 8,
            //   fillColor: "#27A6A4",
            // },
            // hover: {
            //   fillColor: "red",
            // },
          },
        },
        showInLegend: true,
        type: "timeline",
        data: dummyData,
        lineWidth: 12,
      },
    ],
  };

  return (
    <div>
      {loading ? (
        <>
          {" "}
          {fetchedData && (
            <div>
              <div className="w-screen px-4 shadow-lg" style={{ zIndex: 99, position: "relative" }}>
                <div className="flex py-4 items-center">
                  <div className="">
                    {/* <h2 className="text-xl font-semibold ">Patient Journey</h2> */}
                    <Breadcrumb>
                      <Breadcrumb.Item>
                        <a onClick={handleClick}>Patient Journey</a>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>Member Details</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="mt-4">
                      <Avatar
                        style={{ backgroundColor: "#D6F7FF", color: "#27A6A4" }}
                        icon={<User size={23} />}
                      />
                      <span className="ml-4 text-xl pt-4 font-bold">{fetchedData.lh_id}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="bg-body p-4 w-screen"
                style={{
                  height: "calc(100vh - 14.3rem)",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <div className="mb-4">
                  <Space direction="vertical" className="mr-4">
                    <Collapse
                      bordered={false}
                      defaultActiveKey={["1"]}
                      expandIcon={({ isActive }) => (
                        <RightOutlined
                          style={{
                            strokeWidth: "70",
                            stroke: "black",
                          }}
                          rotate={isActive ? -90 : 0}
                        />
                      )}
                      collapsible="header"
                    >
                      <Panel
                        style={{ width: "93.5rem" }}
                        header={
                          <span>
                            <span className="text-lg font-bold">Patient History</span>{" "}
                            <Divider className="ml-4 mr-4" type="vertical" />
                            <span style={{ color: "#B21111" }}>
                              {icons.find((l: any) => l.id === "ExclamationTriangle")?.icon}{" "}
                              <span className="ml-2">{fetchedData.currentTherapyStatus} </span>
                            </span>
                          </span>
                        }
                        key="1"
                      >
                        <div>
                          <Row gutter={16}>
                            <Col span={6}>
                              {" "}
                              <Card>
                                <p className="font-bold mb-4">Patient Demographics</p>
                                <div className="ml-8">
                                  <p>
                                    <span className="font-bold text-right mb-4"> Location </span>:{" "}
                                    {fetchedData.location}
                                  </p>
                                  <p>
                                    <span className="font-bold text-right mb-4">
                                      {" "}
                                      Sex at Birth{" "}
                                    </span>
                                    :{fetchedData.gender}
                                  </p>
                                  <p>
                                    <span className="font-bold text-right mb-4"> Age Group </span>:
                                    {fetchedData.ageGroup}
                                  </p>
                                  <p>
                                    <span className="font-bold text-right mb-4">
                                      {" "}
                                      Participation{" "}
                                    </span>
                                    :{fetchedData.participation}
                                  </p>
                                  <p>
                                    <span className="font-bold text-right mb-4">
                                      {" "}
                                      Inquisitiveness{" "}
                                    </span>
                                    : {fetchedData.inquisitiveness}
                                  </p>
                                </div>
                              </Card>
                            </Col>
                            <Col span={6}>
                              <Card>
                                <p className="font-bold mb-4">Therapy Experience</p>
                                <div className="ml-8">
                                  <p>
                                    <span className="font-bold text-right mb-4">
                                      {" "}
                                      Current Therapy{" "}
                                    </span>
                                    : {""}
                                    {fetchedData.currentTherapy}
                                  </p>
                                  <p>
                                    <span className="font-bold text-right mb-4"> Status </span>:{" "}
                                    {fetchedData.currentTherapyStatus}
                                  </p>
                                  <Divider
                                    style={{
                                      width: "125%",
                                      marginTop: "8px",
                                      marginBottom: "8px",
                                      marginLeft: "-47px",
                                      background: "#DEDCDE",
                                    }}
                                  />
                                  <p>
                                    <span className="font-bold text-right mb-4">
                                      Last Known Therapy
                                    </span>
                                    : {fetchedData.lastKnownTherapy}
                                  </p>
                                  <p>
                                    <span className="font-bold text-right mb-4">
                                      {" "}
                                      Other Therapies{" "}
                                    </span>
                                    : {fetchedData.otherTherapies}
                                  </p>
                                </div>
                              </Card>
                            </Col>
                            <Col span={6}>
                              <Card>
                                <p className="font-bold mb-4">Current Therapy Shipments</p>
                                <div className="ml-8">
                                  <p>
                                    <span className="font-bold text-right mb-4"> Start Date </span>:{" "}
                                    {moment(fetchedData.therapyStartDate).format("DD MMM YYYY")}
                                  </p>
                                  <p>
                                    <span className="font-bold text-right mb-4"> End Date </span>:{" "}
                                    {moment(fetchedData.therapyEndDate).format("DD MMM YYYY")}
                                  </p>
                                  <p>
                                    <span className="font-bold text-right mb-4">
                                      Time on Therapy{" "}
                                    </span>
                                    : {fetchedData.timeOnTherapy} days
                                  </p>
                                  <p>
                                    <span className="font-bold text-right mb-4">
                                      {" "}
                                      Last Shipment Date{" "}
                                    </span>
                                    : {moment(fetchedData.lastShipmentDate).format("DD MMM YYYY")}
                                  </p>
                                  <p>
                                    <span className="font-bold text-right mb-4">
                                      {" "}
                                      Out of Stock Date{" "}
                                    </span>
                                    : {moment(fetchedData.outOfStockDate).format("DD MMM YYYY")}
                                  </p>
                                </div>
                              </Card>
                            </Col>
                            <Col span={6}>
                              <Card>
                                <p className="font-bold mb-4">HCP Profile</p>
                                <div className="ml-8">
                                  <p>
                                    <span className="font-bold text-right mb-4"> HCP ID </span>:
                                    {fetchedData.hcpid}
                                  </p>
                                  <p>
                                    <span className="font-bold text-right mb-4"> HCP Name</span>:{" "}
                                    {fetchedData.hcp_name}
                                  </p>
                                  <p>
                                    <span className="font-bold text-right mb-4">
                                      {" "}
                                      HCP Location{" "}
                                    </span>
                                    : {fetchedData.hcpLocation}
                                  </p>
                                  <p>
                                    <span className="font-bold text-right mb-4">Hospital</span>:{" "}
                                    {fetchedData.hospital}
                                  </p>
                                  <p>
                                    <span className="font-bold text-right mb-4">Segment</span>:{" "}
                                    {fetchedData.segment}
                                  </p>
                                </div>
                              </Card>
                            </Col>
                          </Row>
                        </div>
                      </Panel>
                    </Collapse>
                  </Space>
                </div>
                <TableWrapper title="Call History" value={1} loading={false} minHeight="73em">
                  <div>
                    {dummyData.length > 0 ? (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        // ref={chartComponentRef}
                      >
                        <hr style={{ border: "10px solid green", borderRadius: "0" }}></hr>
                      </HighchartsReact>
                    ) : (
                      <>no data</>
                    )}
                    <div className="ml-12 mb-8 font-bold" style={{ paddingTop: "1rem" }}>
                      <span>Legend :</span>
                      <span className="ml-4">
                        <Circle color="#2F6F7B" size={17} />
                        <span className="ml-4"> Outbound </span>
                      </span>
                      <span className="ml-4">
                        <Circle color="#27A6A4" size={17} />
                        <span className="ml-4"> Inbound </span>
                      </span>{" "}
                      <span className="ml-4">
                        <SquareFill color="#9C9AA1" size={15} />
                        <span className="ml-4"> Unsuccessful </span>
                      </span>
                    </div>
                    <div className="border-2 border-slate-200 ml-6 mr-4 pt-4 pb-4">
                      <span className="ml-4 font-bold text-base"> Call Transcript</span>
                    </div>
                    <div className="pl-4 pr-4 bg-white">
                      <div className="grid grid-cols-10">
                        <div
                          className="col-span-2 p-2 overflow-y-auto"
                          // style={{ maxHeight: "30.8em" }}
                          style={{ maxHeight: "59vh" }}
                        >
                          <List
                            style={{ marginTop: "-14px" }}
                            bordered
                            dataSource={fetchedData.callDetails}
                            // dataSource={fetchedData.callDetails}
                            renderItem={(item: any, index: any) => (
                              <List.Item
                                style={{
                                  backgroundColor: index + "" === bgForList ? "#27A6A41A" : "white",
                                }}
                                id={item.callId}
                              >
                                <>
                                {index + "" === bgForList &&
                                  fetching &&
                                  showTranscriptForCall(
                                    item.callId,
                                    moment(item.callStartDate).format("DD-MMM-YYYY"),
                                    item.callStartTime,
                                    item.programName,
                                    item.callType,
                                    index
                                  )}
                                
                                {item.callType === "Outbound" && item.isCallSuccessful===true &&(
                                  // <CallOutbound size={22} color={"#2D8B93"} />
                                  <Circle color="#2D8B93" size={17} />
                                )}
                                {item.callType === "Inbound"  && item.isCallSuccessful===true &&(
                                  // <CallInbound size={22} color={"#2D8B93"} />
                                  <Circle color="#43BEBE" size={17} />
                                  
                                )}
                                {item.callType === "Unsuccessful" && (
                                  // <PhoneMissed size={22} color={"#2D8B93"} />
                                  <SquareFill color="#716E79" size={13} />
                                )}
                                {item.callType === "Outbound" && item.isCallSuccessful===false &&(
                                  // <CallOutbound size={22} color={"#2D8B93"} />
                                  <>
                                   <Circle color="#43BEBE" size={17} />
                                  <SquareFill color="#716E79" size={13} /></>
                                )}
                                {item.callType === "Inbound" && item.isCallSuccessful===false &&(
                                  // <CallInbound size={22} color={"#2D8B93"} />
                                  <>
                                   <Circle color="#43BEBE" size={17} />
                                   <SquareFill color="#716E79" size={13} /></>
                                 
                                )}
                                <Typography.Text
                                  onClick={() =>
                                    showTranscriptForCall(
                                      item.callId,
                                      moment(item.callStartDate).format("DD-MMM-YYYY"),
                                      item.callStartTime,
                                      item.programName,
                                      item.callType,
                                      index
                                    )
                                  }
                                  className="ml-1 mr-4 mb-2 cursor-pointer"
                                  style={{ color: "#2D8B93" }}
                                >
                                  {" "}
                                  {moment(item.callStartDate).format("DD-MMM-YYYY") + " "}
                                  {"[" + item.callStartTime + "]"}
                                </Typography.Text>{" "}
                                <span className="text-sm" style={{ color: "#87848D" }}>
                                  {item.programName}
                                </span>
                                </>
                              </List.Item>
                            )}
                          />
                        </div>
                        {transcriptData ? (
                          <div
                            className="col-span-8 px-2 overflow-y-auto"
                            // style={{ maxHeight: "30.8em" }}
                            style={{ maxHeight: "59vh" }}
                          >
                            <TranscriptsWrapper
                              header={`Details about selected transcript here`}
                              noSummary
                              noSideSummary
                            >
                              <div>
                                <div
                                  className="mt-2 -mb-2"
                                  style={{
                                    position: "sticky",
                                    top: "0rem",
                                    background: "white",
                                    zIndex: 5,
                                    paddingTop: "1rem",
                                  }}
                                >
                                  <span className="font-bold ml-4">
                                    {" "}
                                    {topbarInfo.startDate + " "}
                                    {"[" + topbarInfo.callTime + "]"}
                                  </span>
                                  <span className="ml-4" style={{ color: "#87848D" }}>
                                    {topbarInfo.programName}
                                  </span>
                                  <Divider type="vertical" />
                                  <span style={{ color: "#87848D" }}> {topbarInfo.callType}</span>
                                  <Divider type="vertical" />
                                  <span style={{ color: "#87848D" }}>
                                    {" "}
                                    Call Duration:{" "} {transcriptData.actualCallDuration}
                                    {/* {transcriptData.actualCallDuration.charAt(3) === "0"
                                      ? transcriptData.actualCallDuration.substring(4, 5) + " min "
                                      : transcriptData.actualCallDuration.charAt(4) === "0"
                                      ? ""
                                      : transcriptData.actualCallDuration.substring(3, 5) + " min "}{" "}
                                    {transcriptData.actualCallDuration.substring(6, 8) + " sec"} */}
                                      
                                    
                                  </span>
                                  <span className="float-right mr-12">
                                    <SquareFill color={"#ffe0c2"} className="mr-1" size={12} />{" "}
                                    Unmet Need
                                    <SquareFill
                                      color={"#D1AAFF"}
                                      className="ml-8 mr-1"
                                      size={12}
                                    />{" "}
                                    Topic
                                  </span>
                                  <Divider />
                                </div>

                                <div className="transcriptSection" style={{ minHeight: "55em" }}>
                                  {transcriptData.transcript.map((trans: any) => (
                                    <Row>
                                      <Col span={18}>
                                        <div
                                          id={trans.dialogue_no}
                                          style={{
                                            marginTop: trans.dialogue_no === 1 ? "0" : "2rem",
                                          }}
                                          className="ml-24 w-5/6"
                                        >
                                          <span className="font-bold">{trans.speaker} : </span>{" "}
                                          <span style={{ color: "#87848D" }}>{trans.text}</span>
                                        </div>
                                      </Col>
                                      <Col span={2}></Col>
                                      <Col
                                        style={{
                                          marginTop: trans.dialogue_no === 1 ? "0" : "1.8rem",
                                        }}
                                        span={4}
                                      >
                                        <span>
                                          {trans.unmetNeeds.length > 0 &&
                                            trans.unmetNeeds.map((t: any) => (
                                              <Tag
                                                style={{ color: "#BA5422", borderColor: "#BA5422" }}
                                                color="#FFF9F3  "
                                              >
                                                {t}
                                              </Tag>
                                            ))}
                                        </span>
                                        <span>
                                          {trans.topic !== null && (
                                            <Tag
                                              style={{ color: "#7232CF", borderColor: "#7232CF" }}
                                              color="#F5F1FF"
                                            >
                                              {trans.topic}
                                            </Tag>
                                          )}
                                        </span>
                                      </Col>
                                    </Row>
                                  ))}
                                </div>
                              </div>
                            </TranscriptsWrapper>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </TableWrapper>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {/* <h1>Loading!</h1> */}
          <div className="w-screen px-4 shadow-lg" style={{ zIndex: 99, position: "relative" }}>
            <Skeleton className="pb-4" paragraph={{ rows: 1 }} />
          </div>

          <Collapse
            bordered={false}
            defaultActiveKey={["1"]}
            // expandIcon={({ isActive }) => (
            //   <RightOutlined
            //     style={{
            //       strokeWidth: "70",
            //       stroke: "black",
            //     }}
            //     rotate={isActive ? -90 : 0}
            //   />
            // )}
            collapsible="header"
          >
            <Panel style={{ width: "93.5rem" }} header={""} key="1">
              <div>
                <Row gutter={16}>
                  <Col span={6}>
                    {" "}
                    <Card>
                      <Skeleton />
                    </Card>
                  </Col>
                  <Col span={6}>
                    <Card>
                      <Skeleton />
                    </Card>
                  </Col>
                  <Col span={6}>
                    <Card>
                      <Skeleton />
                    </Card>
                  </Col>
                  <Col span={6}>
                    <Card>
                      <Skeleton />
                    </Card>
                  </Col>
                </Row>
              </div>
            </Panel>
          </Collapse>
          <div className="bg-body p-4 w-screen" style={{ height: "calc(100vh - 14.3rem)" }}>
            <TableWrapper title="Call History" value={1} loading={true} minHeight="37em">
              <Skeleton paragraph={{ rows: 1 }} />
            </TableWrapper>
          </div>
        </>
      )}
    </div>
  );
}
