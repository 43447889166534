import React from "react";
import LandingCards from "../../../../Components/LandingCards";
import AppContext from "../../../../store";
import { dashboardCards } from "../../../../GlobalThemes";
import "./style.css";

/**
 * This function is used to render the landing cards for specific user
 *
 * @returns the landing cards
 */

export default function LandingBody() {
	const context = React.useContext(AppContext);

	return (
		<div className="bg-body pb-8 landing-body">
			{dashboardCards[context.userType].map((view) => {
				return (
					<>
						<div className="w-full px-4 py-2">
							<div className="">
								<h3 className="text-text text-xl font-semibold py-4">
									{view.programName}
								</h3>
								<div className="flex flex-wrap">
									{view.cards.map((card) => {
										return (
											<LandingCards
												name={card.name}
												link={card.link}
												comingSoon={
													card.link === ""
														? true
														: false
												}
												type={card.type}
											/>
										);
									})}
								</div>
							</div>
						</div>
					</>
				);
			})}
		</div>
	);
}
