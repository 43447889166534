import React, { useState, useEffect } from "react";
import { Card } from "antd";
// import ReactMarkdown from "react-markdown";
// import remarkGfm from "remark-gfm";
import Markdown from "react-markdown";
import gfm from "remark-gfm";
import breaks from "remark-breaks";
import { Collapse } from "antd";
// import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import "./StoriesSummaryCard.css";

export default function StoriesSummaryCard(props: {
	storyId?: any;
	data: any;
	isloaded: boolean;
	fetchedStoryData?: any;
	fetchStoryData?: any;
	transcriptData?: any;
	storyDialogues?: any;
	searchString?: any;
	setSearchString?: any;
	keywords?: string[];
	maxHeight?: string;
}) {
	const [showMore, setShowMore] = useState<boolean>(false);

	return (
		<>
			{props.isloaded && props.data !== "" ? (
				<Collapse defaultActiveKey={["1"]} expandIconPosition="right" ghost>
					<Collapse.Panel className="px-2" header={<span className="w-12/12 font-bold text-base text-xl text-text-black">{props.searchString}</span>} key="1">
						<Card style={{ maxHeight: props.maxHeight, overflowY: "auto", marginBottom: "1rem" }} bordered={false}>
							<div className="text-base">
								{showMore && props.data.length > 1100 ? (
									<>
										<Markdown remarkPlugins={[breaks, gfm]}>{props.data}</Markdown>
									</>
								) : (
									<Markdown remarkPlugins={[breaks, gfm]}>{props.data.slice(0, 1100)}</Markdown>
								)}
								<div
									className="text-text-teal font-normal cursor-pointer w-max pt-2"
									onClick={() => {
										setShowMore(!showMore);
									}}>
									{!showMore ? <>Show more</> : <>Show less</>}
								</div>
							</div>
						</Card>
					</Collapse.Panel>
				</Collapse>
			) : (
				<></>
			)}
		</>
	);
}
