import { Select, List, Skeleton, Empty, Switch, Checkbox } from "antd";
import { Transcripts } from "./Transcripts";
import { ResponsiveLine } from "@nivo/line";
import { icons } from "../../GlobalThemes";
import React, { useState, useEffect, useRef,useContext } from "react";
import { FilterInterface } from "../Dashboard";
import { Drawer as AntDrawer } from "antd";
import Drawer from "../Dashboard/Components/Drawer";
import { FilterCard } from "../../Components/FilterCard";
import  SummaryCard  from "../../Components/SummaryCard";
import { ResponsiveChoropleth } from "@nivo/geo";
import { usFeatures } from "../../libraries/report-types/charts/geoMap/geoUSFeatures";
import TranscriptsWrapper from "./TransciptsWrapper";
import * as ExploreServiceHelper from "./../SearchEngine/ServiceHelpers/explore";

import {
  searchFilterActiveStatus,
  transcriptStoriesDummyData,
  transcrtiptFilters,
  nodeGraphTranscriptData,
} from "./data";
import { NodeGraphFilters } from "./NodeGraphFilters";
// import "tippy.js/dist/tippy.css";
// import "tippy.js/animations/scale.css";
import * as StoriesServiceHelper from "./../SearchEngine/ServiceHelpers/stories";
import * as NodeGraphServiceHelper from "./../SearchEngine/ServiceHelpers/nodeGraph";
import * as SearchServiceHelper from "./../SearchEngine/ServiceHelpers/search";
import moment from "moment";
import * as Messages from "../../Components/Messages";
import AppContext from "../../store";
import { ResponsiveBar } from "@nivo/bar";
import NodeGraphCanvas from "../../Components/NodeGraphCanvas";

export interface searchQuery {
  deepSearch?: boolean;
  testButton1?: boolean;
  testButton2?: boolean;
  testButton3?: boolean;
  testButton4?: boolean;
  testButton5?: boolean;
  searchWord?: string;
  startDate?: string | any;
  endDate?: string | any;
  speaker?: string;
  showResults?: boolean;
  storyId?: string;
  callTypeIds?: number[];
  therapyStatusIds?: number[];
  emotions?: number[];
  emotionsGroup?: number[];
  sentiment?: number[];
  topics?: number[];
  totStart?: string;
  totEnd?: string;
}

export interface transcriptDataInter {
  numberOfPatients: number;
  summary:string;
  numberOfSearchResults: number;
  emotionsCounts?: any;
  statesResults: any;
  searchResults: {
    id: number;
    s3path: string;
    callDate: string;
    callId: string;
    callTime: string;
    callTypeIds: string;
    therapyStatusIds: string;
    dialogueNo: number;
    name: string;
    tags: string[];
    dialogueAppearedAt: number;
    dialogueLength: number;
    transcriptLength: number;
    addedToStory: boolean;
    storiesAdded: {
      storyId: any;
      storyName: string;
    }[];
    patientId: number;
    speaker: string;
    elasticSearchId: string;
    transcripts: {
      dialogueNo: number;
      speaker: string;
      text: string;
    }[];
    storyDialogues: any;
  }[];
}

// export const DUMMYRESPONSE: any = {

  
//   data: {
//     nodes: [
//       {
//         topicId: 0,
//         topic: "denied claim lectrazine",
//         keywordCount: 1,
//         conversations: 3,
//         speakerTypes: ["patient"],
//         descriptionType: "All Documents",
//         date: "2022-12-23",
//         sentiments: {
//           positive: 0,
//           negative: 1,
//           neutral: 0,
//         },
//         keywords: [
//           {
//             name: "lectrazine",
//             occurences: 3,
//           },
//         ],
//       },
//       {
//         topicId: 1,
//         topic: "feeling really anxious",
//         keywordCount: 4,
//         conversations: 3,
//         speakerTypes: ["patient"],
//         descriptionType: "All Documents",
//         date: "2022-12-23",
//         sentiments: {
//           positive: 0,
//           negative: 1,
//           neutral: 0,
//         },
//         keywords: [
//           {
//             name: "get",
//             occurences: 4,
//           },
//           {
//             name: "call",
//             occurences: 4,
//           },
//           {
//             name: "feel",
//             occurences: 4,
//           },
//           {
//             name: "time", 
//             occurences: 3,
//           },
//         ],
//       },
//       {
//         topicId: 2,
//         topic: "worried effects lectrazine",
//         keywordCount: 5,
//         conversations: 7,
//         speakerTypes: ["patient"],
//         descriptionType: "All Documents",
//         date: "2022-12-23",
//         sentiments: {
//           positive: 0,
//           negative: 0.7142857142857143,
//           neutral: 0.2857142857142857,
//         },
//         keywords: [
//           {
//             name: "lectrazine",
//             occurences: 6,
//           },
//           {
//             name: "effect",
//             occurences: 5,
//           },
//           {
//             name: "side",
//             occurences: 4,
//           },
//           {
//             name: "worried",
//             occurences: 4,
//           },
//           {
//             name: "cause",
//             occurences: 3,
//           },
//         ],
//       },
//       {
//         topicId: 3,
//         topic: "covered medication",
//         keywordCount: 1,
//         conversations: 3,
//         speakerTypes: ["patient"],
//         descriptionType: "All Documents",
//         date: "2022-12-23",
//         sentiments: {
//           positive: 0.3333333333333333,
//           negative: 0.6666666666666666,
//           neutral: 0,
//         },
//         keywords: [
//           {
//             name: "medication",
//             occurences: 3,
//           },
//         ],
//       },
//       {
//         topicId: 4,
//         topic: "feeling okay little",
//         keywordCount: 4,
//         conversations: 3,
//         speakerTypes: ["patient"],
//         descriptionType: "All Documents",
//         date: "2022-12-23",
//         sentiments: {
//           positive: 0.6666666666666666,
//           negative: 0.3333333333333333,
//           neutral: 0,
//         },
//         keywords: [
//           {
//             name: "little",
//             occurences: 2,
//           },
//           {
//             name: "though",
//             occurences: 2,
//           },
//           {
//             name: "feel",
//             occurences: 2,
//           },
//           {
//             name: "lot",
//             occurences: 2,
//           },
//         ],
//       },
//       {
//         topicId: 5,
//         topic: "kind beverages lectrazine",
//         keywordCount: 5,
//         conversations: 3,
//         speakerTypes: ["patient"],
//         descriptionType: "All Documents",
//         date: "2022-12-23",
//         sentiments: {
//           positive: 0,
//           negative: 0,
//           neutral: 1,
//         },
//         keywords: [
//           {
//             name: "medication",
//             occurences: 3,
//           },
//           {
//             name: "kind",
//             occurences: 3,
//           },
//           {
//             name: "wonder",
//             occurences: 3,
//           },
//           {
//             name: "beverage",
//             occurences: 2,
//           },
//           {
//             name: "feel",
//             occurences: 2,
//           },
//         ],
//       },
//       {
//         topicId: 6,
//         topic: "swallow water just",
//         keywordCount: 1,
//         conversations: 1,
//         speakerTypes: ["patient"],
//         descriptionType: "All Documents",
//         date: "2022-12-23",
//         sentiments: {
//           positive: 0,
//           negative: 0,
//           neutral: 1,
//         },
//         keywords: [
//           {
//             name: "water",
//             occurences: 2,
//           },
//         ],
//       },
//       {
//         topicId: 7,
//         topic: "medication feeling okay",
//         keywordCount: 2,
//         conversations: 5,
//         speakerTypes: ["patient"],
//         descriptionType: "All Documents",
//         date: "2022-12-23",
//         sentiments: {
//           positive: 0,
//           negative: 0.8333333333333334,
//           neutral: 0.16666666666666666,
//         },
//         keywords: [
//           {
//             name: "medication",
//             occurences: 5,
//           },
//           {
//             name: "worried",
//             occurences: 2,
//           },
//         ],
//       },
//       {
//         topicId: 8,
//         topic: "good lectrazine pills",
//         keywordCount: 2,
//         conversations: 3,
//         speakerTypes: ["patient"],
//         descriptionType: "All Documents",
//         date: "2022-12-23",
//         sentiments: {
//           positive: 0,
//           negative: 1,
//           neutral: 0,
//         },
//         keywords: [
//           {
//             name: "lectrazine",
//             occurences: 3,
//           },
//           {
//             name: "pill",
//             occurences: 2,
//           },
//         ],
//       },
//       {
//         topicId: 9,
//         topic: "try thanks advice",
//         keywordCount: 2,
//         conversations: 3,
//         speakerTypes: ["patient"],
//         descriptionType: "All Documents",
//         date: "2022-12-23",
//         sentiments: {
//           positive: 1,
//           negative: 0,
//           neutral: 0,
//         },
//         keywords: [
//           {
//             name: "much",
//             occurences: 2,
//           },
//           {
//             name: "help",
//             occurences: 2,
//           },
//         ],
//       },
//       {
//         topicId: 10,
//         topic: "thank help okay",
//         keywordCount: 2,
//         conversations: 2,
//         speakerTypes: ["patient"],
//         descriptionType: "All Documents",
//         date: "2022-12-23",
//         sentiments: {
//           positive: 1,
//           negative: 0,
//           neutral: 0,
//         },
//         keywords: [
//           {
//             name: "alright",
//             occurences: 2,
//           },
//           {
//             name: "help",
//             occurences: 2,
//           },
//         ],
//       },
//       {
//         topicId: 11,
//         topic: "possible effects medication",
//         keywordCount: 2,
//         conversations: 3,
//         speakerTypes: ["patient"],
//         descriptionType: "All Documents",
//         date: "2022-12-23",
//         sentiments: {
//           positive: 0,
//           negative: 0.25,
//           neutral: 0.75,
//         },
//         keywords: [
//           {
//             name: "effect",
//             occurences: 3,
//           },
//           {
//             name: "side",
//             occurences: 3,
//           },
//         ],
//       },
//       {
//         topicId: 12,
//         topic: "pill day yes",
//         keywordCount: 5,
//         conversations: 2,
//         speakerTypes: ["patient"],
//         descriptionType: "All Documents",
//         date: "2022-12-23",
//         sentiments: {
//           positive: 0,
//           negative: 0,
//           neutral: 1,
//         },
//         keywords: [
//           {
//             name: "take",
//             occurences: 2,
//           },
//           {
//             name: "every",
//             occurences: 2,
//           },
//           {
//             name: "one",
//             occurences: 2,
//           },
//           {
//             name: "day",
//             occurences: 2,
//           },
//           {
//             name: "pill",
//             occurences: 2,
//           },
//         ],
//       },
//     ],
//     link: [
//       {
//         sourceTopicId: 0,
//         targetTopicId: 1,
//         weight: 2, // TBD
//       },
//       {
//         sourceTopicId: 2,
//         targetTopicId: 3,
//         weight: 2, // TBD
//       },
//       {
//         sourceTopicId: 3,
//         targetTopicId: 6,
//         weight: 2, // TBD
//       },
//       {
//         sourceTopicId: 4,
//         targetTopicId: 8,
//         weight: 2, // TBD
//       },
//     ],
//   },
// };

/**
 * This page is the main search tab in Search Engine Page. This page renders searched clusters and transcripts in a list.
 *
 * @returns Node Graph and List of Searched Transcripts.
 */

export function NodeGraph() {
  const app = useContext(AppContext);
  const [fetchedData, setFetchedData] = React.useState<any>(null);
  const [showResultSummary, setShowResultSummary] = useState<boolean>(false);
  const [expanded, setExpanded] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [transcriptData, setTranscriptData] = useState<any>([]);
  const dataCopy = React.useRef<any>([]);
  const [pinnedStories, setPinnedStories] = useState<Array<any>>([]);
  const [fetchedStoryData, setFetchedStoryData] = useState<any>([]);
  const [transcriptLoading, setTranscriptLoading] = useState<boolean>(false);
  const [searchTranscriptQuery, setSearchTranscriptQuery] = useState<searchQuery>({});
  const [searchWordRequired, setsearchWordRequired] = useState<boolean>(false);
  // const [searchFilterData, setSearchFilterData] = useState<any>();
  const [selectLevel, setSelectLevel] = useState<any>();
  const [therapyStatus, setTherapyStatus] = useState<any>();
  const [emptyTextField, setEmptyTextField] = useState("Enter a search word to see results");
  const [searchString, setSearchString] = React.useState("");
  const [nodeGraphData, setNodeGraphData] = React.useState<any>(null);
  const [pageData, setPageData] = React.useState<any>();
  const [selectedNode, setSelectedNode] = React.useState<any>(null);
  const [updatedNode, setUpdatedNode] = React.useState<any>(null);
  const [keywords, setKeywords] = React.useState<string[] | undefined>();
  const [path, setPath] = React.useState<any>("kural/json_data/srch_node_graph.txt");
  const [response, setResponse] = useState({
  data:{
    nodes:[],
    link:[],
  }
});
var filterData = {
  startDate: "",
  endDate: "",
  speaker: "",
  searchWord: "",
};
var dateFormat = "MMM YYYY";
const [searchFilterData, setSearchFilterData] = useState<searchQuery>(filterData);

  const [filters, setFilters] = React.useState<FilterInterface>({
    Timeframe_Display: undefined,
    Start_Date: "",
    End_Date: "",
    Call_Type: undefined,
    Therapy_Status: undefined,
    Call_Intent: undefined,
    Adherence: undefined,
    Sentiment: undefined,
    Topics: undefined,
    Emotions: new Array<string>(),
    totStart: undefined,
    totEnd: undefined,
  });
  const [fetchedFilters, setFetchedFilters] = React.useState<any>([]);
  const [headerTitle, setHeaderTitle] = React.useState<string | undefined>();

  const temp: any = [];
  const arrForFilteringChart: any = [];
  const colors: Record<string, string> = {
    Inbound: "rgb(224,198,255)",
    Outbound: "rgb(114,50,207)",
    Agent: "rgb(169,218,253)",
    Patient: "rgb(169,218,253)",
    Positive: "rgb(38,195,141)",
    Neutral: "rgb(222,220,222)",
    Negative: "rgb(230,84,40)",
  };
  // console.log('9999999999999999999999', RESPONSE)
  const handleFilters = (filterValue: any) => setSearchFilterData(filterValue);
  const handleFilters2 = (filterValue: string | boolean) => setSelectLevel(filterValue);
  const handleFilters3 = (filterValue: string | boolean) => setTherapyStatus(filterValue);
  const getColor = (bar: any) => colors[bar.id];
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => setVisible(false);
  const topRef = useRef<any>(null);

  // const summaryDummyData="Calls can refer to different things, such as the Apple TV+ series, making and receiving phone calls from a PC, using Google Voice, or making free internet calls with PopTox.The Apple TV+ series Calls is a horror show directed by Fede Álvarez. The show features a series of seemingly unrelated phone calls, each featuring characters involved in stories that involve strange, supernatural phenomena[1]. The show is effective in setting the mood, with a separate Spanish-language version planned for Latin American markets[1].Making and receiving phone calls from a PC is possible with the Phone Link app from Microsoft. The app requires Bluetooth capability on both the PC and the Android device[2]. Troubleshooting tips are available if issues arise while using the calling feature[2]. Recent calls cannot be managed or deleted from the PC[2].Google Voice allows users to place and receive calls from anywhere using smartphones and the web. The service offers voicemail transcription and the ability to forward calls to any device while blocking spam calls[3]. The service is available on Apple devices and other smartphones[3].";

  async function fetchDataForPage() {
    // add s3 parameter value
    // const data: any = await NodeGraphServiceHelper.getNodeJsons(path);
    // if (data) {
    //   setFetchedData(data);
    // }

    const data: any = await SearchServiceHelper.getFilters();
    data.push({
      filter: "TOT",
      optionsObject: [],
      type: "slider",
    });
    if (data) {
      setFetchedData(data);
    }

  }

  async function fetchCards(filters?: any) {
    setTranscriptData([]);                          //To Re-render page on applying filters
    setSelectedNode(null);                          //To Re-render page on applying filters
    Messages.loading("Fetching Clusters", "updatable");
    let data: any;
    filters = {...filters, ...searchFilterData, ...searchTranscriptQuery};
    
    if (Object.entries(filters).length === 0) {
      data = await ExploreServiceHelper.getExploreCardsData(
        app.expIdForNodeGraph,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
    } else {
      console.log(filters);
      data = await ExploreServiceHelper.getExploreCardsData(
        filters.experimentId !==null  ? filters.experimentId:null ,
         
        // searchFilter.startDate = moment(query.startDate).format();
            
        filters.startDate  ?filters.startDate: null ,
        filters.endDate  ?  filters.endDate : null ,
        filters.speaker !== null || filters.speaker!==""  ? (filters.speaker=='Member' ?'member':filters.speaker=='Agent'?'agent':null): null,
        filters.topics !== undefined ? filters.topics[0] : null,
        filters.totStart !== '' ? filters.totStart : null,
        filters.totEnd !== '' ? filters.totEnd : null,
        filters.callTypeIds && filters.callTypeIds[0] !== undefined ? filters.callTypeIds[0] : null,
        filters.therapyStatusIds && filters.therapyStatusIds[0] !== undefined ? filters.therapyStatusIds[0] : null,
        filters.sentiment && filters.sentiment[0] !== undefined ? filters.sentiment[0] : null,
        filters.emotions && filters.emotions.length > 0 ? filters.emotions : null,
        filters.clusterId!==undefined?filters.clusterId:null
      );
    }

    if (data) {
      Messages.success("Success", "updatable");
      console.log(data);
      setResponse(data);
      // setResponse(DUMMYRESPONSE);
      // setFetchedCards(data);
      // if (data.length === 0) {
      //   setResults(true);
      // } else {
      //   setResults(true);
      // }
    }else{
      Messages.error("Fetching failed", "updatable");
      console.log("ERROR");
    }
  }

  async function fetchFilters() {
    // const data: any = await SearchServiceHelper.getFilters();

    // transcrtiptFilters.push({
    //   filter: "TOT",
    //   optionsObject: [],
    //   type: "slider",
    // });

    // console.log('FILTERS DATA', data)
    // if (transcrtiptFilters) {
    //   setFetchedFilters(transcrtiptFilters);
    // }

    if (fetchedData) {
      // let transFilters: any = [...fetchedData[1]["transcriptFilters"]];
      // transFilters.push({
      //   filter: "TOT",
      //   optionsObject: [],
      //   type: "slider",
      // });
      // setFetchedFilters(transFilters);
      setFetchedFilters(fetchedData);
    }
  }
  async function fetchStoryData() {
    if (fetchedData) {
      const data: any = await StoriesServiceHelper.getAllStories();
      if (data) {
        setFetchedStoryData(data);
      }
    }
  }

  async function addNewStory(addData: { "Story Name": string; "Story Type": string }) {
    const key = "updatable";
    Messages.loading("Adding Transcript", key);

    addData["Story Type"] = addData["Story Type"].split(" ").join("");
    // console.log(addData);
    const data: any = await StoriesServiceHelper.addNewStory(addData);
    if (data) {
      var temp: any = [];
      var d = data["Date Created"];
      data["Date Created"] = moment(d).format("DD MMM YYYY");
      temp = [data, ...fetchedStoryData];
      setFetchedStoryData(temp);
      Messages.success("Successfully Created New Story", key);
    } else Messages.error("Failed", key);
  }

  async function addStoryToTranscriptData(transcript: {
    id: any;
    name: any;
    tags: any;
    storiesAdded: any;
    source: number;
    dialogueNo: any;
    callId: any;
    callDate: any;
    callTime: any;
    s3path: any;
    dialogueAppearedAt: any;
    dialogueLength: any;
    transcriptLength: any;
    transcripts: any;
    patientId: any;
    speaker: any;
    elasticSearchId: any;
    storyDialogues: any;
  }) {
    const key = "updatable";
    Messages.loading("Adding Transcript to Story", key);
    const data: any = await SearchServiceHelper.addToStory(transcript);
    // console.log("Fetched : ", data);
    if (data) {
      Messages.success("Successfully Added Transcript to Story", key);

      const temp = [...transcriptData];
      console.log("transc", transcriptData, temp, transcript);

      const idx = temp[0].searchResults.findIndex(
        (t: any) => t.callId === transcript.callId && t.dialogueNo === transcript.dialogueNo
      );

      temp[0].searchResults[idx].storiesAdded = data;
      if (data.length === 0) {
        temp[0].searchResults[idx].addedToStory = false;
      }
      setTranscriptData(temp);

      return true;
    } else {
      Messages.error("Failed", key);
      return false;
    }
  }

  const clearFilters = () => {
    const obj = {
      Timeframe_Display: undefined,
      Start_Date: "",
      End_Date: "",
      Call_Type: undefined,
      Therapy_Status: undefined,
      Call_Intent: undefined,
      Adherence: undefined,
      Sentiment: undefined,
      Topics: undefined,
      Emotions: new Array<string>(),
      totStart: undefined,
      totEnd: undefined,
    };
    setFilters(obj);
    setVisible(false);
    setTranscriptFilters(obj);
  };

  async function fetchTranscriptsForNode(clusterId: any) {
    const data: any = await ExploreServiceHelper.getClusterDetailsData(clusterId);

    if (data) {
      let tempArr: any = [];
      tempArr.push(data);
      const nodeTranscriptData = tempArr;
      if (nodeTranscriptData) {
        console.log(nodeTranscriptData);
        setTranscriptData(nodeTranscriptData);
        dataCopy.current = [nodeTranscriptData];
        Messages.success("Success", "key1");
      } else {
        Messages.error("Fetching failed", "key1");
        setTranscriptData([]);
      }
    }
  }

  const fetchTranscripts = async (query: searchQuery) => {
    setTranscriptLoading(true);
    Messages.loading("Fetching...", "key1");
    console.log("the search query coming in ", query);
    // const data: any = await SearchServiceHelper.getAllTranscripts(query);
    // TODO Make API Call here
    setTranscriptLoading(false);
    const key = "Key 1";

    if (fetchedData) {
      console.log(fetchedData[2]["nodeGraphTranscriptData"]["transcript_data"].topicId);
      const nodeTranscriptData = fetchedData[2]["nodeGraphTranscriptData"][
        "transcript_data"
      ].filter((trans: any) => trans.topicId === selectedNode.topicId);

      // const nodeTranscriptData = fetchTranscriptsForNode(4);

      // if (nodeTranscriptData) {
      //   console.log(nodeTranscriptData);
      //   setTranscriptData(nodeTranscriptData);
      //   dataCopy.current = [nodeTranscriptData];
      //   Messages.success("Success", "key1");
      // } else {
      //   Messages.error("Fetching failed", "key1");
      //   setTranscriptData([]);
      // }
    }
  };

  const addToStorySearch = (transcriptData: any, storyId: any) => {
    var add: any = [];
    storyId.forEach((story: any, index: number) => {
      add.push({
        storyId: story,
        storyName:
          fetchedStoryData[fetchedStoryData.findIndex((storyFetch: any) => storyFetch.id === story)]
            .storyName,
      });
    });

    var trans = {
      id: transcriptData.id,
      name: transcriptData.name,
      tags: transcriptData.tags,
      storiesAdded: add,
      source: 1,
      dialogueNo: transcriptData.dialogueNo,
      callId: transcriptData.callId,
      callDate: transcriptData.callDate,
      callTime: transcriptData.callTime,
      s3path: transcriptData.s3path,
      dialogueAppearedAt: transcriptData.dialogueAppearedAt,
      dialogueLength: transcriptData.dialogueLength,
      transcriptLength: transcriptData.transcriptLength,
      transcripts: transcriptData.transcripts,
      patientId: transcriptData.patientId,
      speaker: transcriptData.speaker,
      elasticSearchId: transcriptData.elasticSearchId,
      storyDialogues: transcriptData.storyDialogues,
    };
    const d = addStoryToTranscriptData(trans);
    return d;
  };

  const changePinnedStories = (stories: any) => {
    setPinnedStories(stories);
  };

  const addSearchFilters = (query: searchQuery) => {
    var searchFilter: searchQuery = searchTranscriptQuery;
    searchFilter.searchWord = query.searchWord;
    searchFilter.speaker = query.speaker;
    if (query.startDate) {
      searchFilter.startDate = moment(query.startDate).startOf("month").add(1, "days");
      searchFilter.endDate = moment(query.endDate).add(1, "months");
    }
    searchFilter.deepSearch = query.deepSearch;
    searchFilter.testButton1 = query.testButton1;
    searchFilter.testButton2 = query.testButton2;
    searchFilter.testButton3 = query.testButton3;
    searchFilter.testButton4 = query.testButton4;
    searchFilter.testButton5 = query.testButton5;
    checksearchWord(searchFilter);
    setSearchTranscriptQuery(searchFilter);
  };

  const findId = (filter: string, value: string) => {
    const grpIdx: number[] = [];
    const idx: number[] = [];
    fetchedFilters.map((f: any) => {
      f.optionsObject.map((group: any) => {
        if (filter === f.filter) {
          if (group.subgroup.length === 0 && value === group.name) {
            idx.push(group.id);
            grpIdx.push(group.id);
            return group.id;
          } else if (group.subgroup.length > 0) {
            if (Array.isArray(value) && value.find((f) => f === group.name)) {
              grpIdx.push(group.id);
            } else if (Array.isArray(value)) {
              value.map((v) => {
                const ob = group.subgroup.find((sg: any) => sg.name === v);
                if (ob) {
                  grpIdx.push(group.id);
                  idx.push(ob.id);
                }
                return -1;
              });
            }
          }
        }
      });
    });
    return { idx, grpIdx };
  };

  const setTranscriptFilters = (filter: any) => {
    console.log("Filter", filter);
    var transcriptFilters: searchQuery = searchTranscriptQuery;
    const callType = findId("Call_Type", filter.Call_Type);
    transcriptFilters.callTypeIds = callType.grpIdx;

    const therapyStatus = findId("Therapy_Status", filter.Therapy_Status);
    transcriptFilters.therapyStatusIds = therapyStatus.grpIdx;

    const sentiment = findId("Sentiment", filter.Sentiment);
    transcriptFilters.sentiment = sentiment.grpIdx;

    const topics = findId("Topics", filter.Topics);
    transcriptFilters.topics = topics.grpIdx;

    const emotions = findId("Emotions", filter.Emotions);
    transcriptFilters.emotionsGroup = emotions.grpIdx;
    transcriptFilters.emotions = emotions.idx;

    transcriptFilters.totStart = filter.totStart;
    transcriptFilters.totEnd = filter.totEnd;

    console.log("TranscriptFilter", transcriptFilters);
    setFilters(filter);
    checksearchWord(transcriptFilters);
    fetchCards(transcriptFilters);
    setSearchTranscriptQuery(transcriptFilters);
  };

  const checksearchWord = (query: searchQuery) => {
    if (query.searchWord === undefined || query.searchWord === "") {
      setsearchWordRequired(true);
    } else {
      setsearchWordRequired(false);
      setEmptyTextField("No Data");
      console.log(query);
      fetchTranscripts(query);
    }
  };

  const findValueForTherapyStatusGraph = (therapyStatus: any, bound: any, filterVal: any) => {
    let count = 0;
    transcriptData[0].searchResults.map((i: any) => {
      if (
        i.tags[2] === therapyStatus &&
        i.tags[1] === bound &&
        (filterVal.length === 1
          ? i.tags[0] === filterVal[0]
          : filterVal.length === 2
          ? i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1]
          : i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1] || i.tags[0] === filterVal[2])
      )
        ++count;
    });
    return count;
  };

  const findValueForSentimentGraph = (
    patient: any,
    sentiment: any,
    filterVal: any,
    useTopic: boolean
  ) => {
    if (useTopic === true) {
      let count = 0;
      // transcriptData[0].searchResults.map((i: any) => {
      //   if (
      //     i.tags[4] === sentiment &&
      //     i.tags[5] === patient &&
      //     (filterVal.length === 1
      //       ? i.tags[0] === filterVal[0]
      //       : filterVal.length === 2
      //       ? i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1]
      //       : i.tags[0] === filterVal[0] ||
      //         i.tags[0] === filterVal[1] ||
      //         i.tags[0] === filterVal[2])
      //   )
      //     ++count;
      // });

      return count;
    } else {
      let count = 0;
      transcriptData[0].searchResults.map((i: any) => {
        if (
          i.tags[4] === sentiment &&
          i.speaker === patient &&
          (filterVal.length === 1
            ? i.tags[0] === filterVal[0]
            : filterVal.length === 2
            ? i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1]
            : i.tags[0] === filterVal[0] ||
              i.tags[0] === filterVal[1] ||
              i.tags[0] === filterVal[2])
        )
          ++count;
      });

      return count;
    }
  };

  const findValueForToT = (ll: any, ul: any, filterVal: any, therapyStatus?: any) => {
    if (therapyStatus) {
      let count = 0;

      transcriptData[0].searchResults.map((i: any) => {
        if (
          i.tags[3] > ll &&
          i.tags[3] < ul &&
          i.tags[2] === therapyStatus &&
          (filterVal.length === 1
            ? i.tags[0] === filterVal[0]
            : filterVal.length === 2
            ? i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1]
            : i.tags[0] === filterVal[0] ||
              i.tags[0] === filterVal[1] ||
              i.tags[0] === filterVal[2])
        )
          ++count;
      });
      return count;
    } else {
      let count = 0;

      transcriptData[0].searchResults.map((i: any) => {
        if (
          i.tags[3] > ll &&
          i.tags[3] < ul &&
          (filterVal.length === 1
            ? i.tags[0] === filterVal[0]
            : filterVal.length === 2
            ? i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1]
            : i.tags[0] === filterVal[0] ||
              i.tags[0] === filterVal[1] ||
              i.tags[0] === filterVal[2])
        )
          ++count;
      });
      return count;
    }
  };

  if (transcriptData.length > 0) {
    let tempArr: any = [
      "Dosing Information",
      "Closing",
      "Speciality Pharmacy",
      "Common Side Effects",
      "Next Call Confirmation",
      "Introduction",
      "Lab Monitoring Guidance",
      "  Nurse Educator Role",
      "Others",
      "Patient Questionnaire",
      "Safety Recording",
      "Shipment",
    ];

    for (let i = 0; i <= tempArr.length; i++) {
      if (
        findValueForSentimentGraph(tempArr[i], "Negative", searchFilterData, true) +
          findValueForSentimentGraph(tempArr[i], "Positive", searchFilterData, true) +
          +findValueForSentimentGraph(tempArr[i], "Neutral", searchFilterData, true) >
        0
      ) {
        let obj: any = {};
        obj.Topic = tempArr[i];
        obj.Positive = findValueForSentimentGraph(tempArr[i], "Positive", searchFilterData, true);
        obj["positive Color"] = "hsl(251 70%, 50%)";
        obj.Neutral = findValueForSentimentGraph(tempArr[i], "Neutral", searchFilterData, true);
        obj["neutral Color"] = "hsl(41, 70%, 50%)";
        obj.Negative = findValueForSentimentGraph(tempArr[i], "Negative", searchFilterData, true);
        obj["negative Color"] = "hsl(41, 70%, 50%)";
        arrForFilteringChart.push(obj);
      }
    }
  }

  async function onSave(searchFilterData:any)
  {
    let data: any;
    var filters: any = {...searchFilterData, ...searchTranscriptQuery};
    
    if (filters == undefined) {
      data = await ExploreServiceHelper.getExploreCardsData(
        1,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
    } else {
      console.log(filters);
      data = await ExploreServiceHelper.getExploreCardsData(
        filters.experimentId == "" ? null : filters.experimentId,
        filters.startDate == "" ? null : filters.startDate,
        filters.endDate == "" ? null : filters.endDate,
        filters.speaker !== undefined ? filters.speaker : null,
        filters.topics&&filters.topics[0] !== undefined ? filters.topics : null,
        filters.totStart !== undefined ? filters.totStart : null,
        filters.totEnd !== undefined ? filters.totEnd : null,
        filters.callTypeIds&&filters.callTypeIds[0] !== undefined ? filters.callTypeIds : null,
        filters.therapyStatusIds&&filters.therapyStatusIds[0] !== undefined ? filters.therapyStatusIds : null,
        filters.sentiment&&filters.sentiment[0] !== undefined ? filters.sentiment : null,
        filters.emotions&&filters.emotions.length > 0 ? filters.emotions : null
      );
    }
    if (data) {
      console.log(data);
      // setResponse(data);
    }
  }
  useEffect(() => {
    fetchDataForPage();
    fetchCards();
  }, [path]);

  useEffect(() => {
    if (fetchedData) {
      fetchFilters();
      fetchStoryData();
      // setSearchFilterData(["Good"]);
    }
  }, [fetchedData]);

  const scrollToTop = () => {
    topRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    console.log("Selected Node - ", selectedNode);
    if (selectedNode) {
      setKeywords(selectedNode.keywords.map((keyword: { name: any }) => keyword.name));
      fetchTranscripts({ searchWord: "headache" });
      setHeaderTitle(selectedNode.topic);
    }
  }, [selectedNode]);

  const headerEdit = (headerLabel: any) => {
    setHeaderTitle(headerLabel);
  };
  useEffect(() => {
    console.log("Edited  - ", headerTitle);
    if (headerTitle) {
      selectedNode.topic = headerTitle;
      ExploreServiceHelper.changeCardNameData(selectedNode.topicId,headerTitle);
      setUpdatedNode(selectedNode);
      setHeaderTitle(headerTitle);     
    }
  }, [headerTitle]);

  // useEffect(() => {
  //   fetchTranscriptsForNode(22);
  // }, []);

  return (
    <div>
      <div className="w-screen px-4 shadow-lg" style={{ zIndex: 99, position: "relative" }}>
        <div className="flex py-4 items-center">
          {/* {console.log("TRANSCRIPT DATA", transcriptData)} */}
          <div className="flex">
            <h2 className="text-xl font-semibold ">Node Graph</h2>
            <div className="flex flex-wrap ml-8">
              <FilterCard
                filters={filters}
                setFilters={setTranscriptFilters}
                filterActiveStatus={searchFilterActiveStatus}
              />
              <AntDrawer
                title="Filters"
                placement="right"
                onClose={onClose}
                visible={visible}
                destroyOnClose={true}
              >
                <Drawer
                  filters={filters}
                  setFilters={setTranscriptFilters}
                  filterOptions={fetchedFilters}
                  clearFilters={clearFilters}
                  filterActiveStatus={searchFilterActiveStatus}
                />
              </AntDrawer>
            </div>
          </div>
        </div>
        <NodeGraphFilters
          path={path}
          setPath={setPath}
          showDrawer={showDrawer}
          fetchTranscripts={addSearchFilters}
          searchWordRequired={searchWordRequired}
          searchString={searchString}
          setSearchString={setSearchString}
          searchFilterData={searchFilterData}
          setSearchFilterData={setSearchFilterData}
          // onSave={onSave}
          onSave={fetchCards}
        />
      </div>
      <div
        className="nodegraph-page"
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          height: "calc(100vh - 200px)",
        }}
      >
        <div className="bg-body h-full relative" ref={topRef}>
          <NodeGraphCanvas
            data={nodeGraphData}
            onEdit={() => {
              console.log("ON EDIT CALLED");
              setNodeGraphData(Math.random());
            }}
            onSelect={(node) => {
              console.log("On Node Click", node, updatedNode);
              fetchTranscriptsForNode(node.id);
              setSelectedNode(node);
              setTimeout(() => {
                const ele: any = document.querySelector("#pk-transcript");
                ele.parentElement.scrollBy({
                  behavior: "smooth",
                  left: 0,
                  top: ele.offsetTop,
                });
              }, 200);
            }}
            updatedNode={updatedNode}
            nodeData={response}
          />
        </div>
        
              
              
             
          
        {selectedNode && (
          <div id={"pk-transcript"} className="bg-body flex h-full px-4">
            <div
              className="bg-white shadow-md shadow-gray-900 "
              style={{
                overflowY: "auto",
                flexBasis: showResultSummary ? "65%" : "100%",
              }}
            >
              {/* {console.log("selectedNode", selectedNode, headerTitle)} */}

              <TranscriptsWrapper
                header={selectedNode.topic}
                subHeader={`100 Conversations`}
                hideSummaryTags={true}
                noSummary={true}
                // expanded={expanded}
                toggleScrollToTop={scrollToTop}
                loading={transcriptLoading}
                noSideSummary={transcriptData.length > 0 ? false : true}
                headerEditCallback={headerEdit}
              >
                {/* <div
              className="bg-white shadow-md shadow-gray-900 "
              style={{
              overflowY: "auto",
              zIndex: 99, 
              position: "relative",
              // height: `${transcriptData.length === 0 ? "fill-available" : "max-content"}`,
              flexBasis: showResultSummary ? "65%" : "100%",
            }}> */}
             
             
              {/* </div> */}
                {transcriptLoading ? (
                  <div>
                    <Skeleton active className="py-4 px-8 border-b" />
                    <Skeleton active className="py-4 px-8 border-b" />
                    <Skeleton active className="py-4 px-8 border-b" />
                  </div>
                ) : (
                  <div
                    className={
                      transcriptData.length > 0 ? "" : "flex items-center justify-around h-96"
                    }
                  >
                     <SummaryCard
                        data={transcriptData.length>0 && transcriptData[0]["summary"]?transcriptData[0]["summary"]:""}
                        // data={summaryDummyData}
                        // searchString={"headache"}
                        // isloaded={summaryDummyData.length>0?true:false}
                        isloaded={transcriptData.length>0?true:false}
                        ></SummaryCard>
                    <List
                      itemLayout="vertical"
                      size="large"
                      pagination={{
                        hideOnSinglePage: true,
                        style: {
                          marginLeft: "2rem",
                          marginBottom: "1.2rem",
                          float: "left",
                          paddingBottom: "2rem",
                        },
                        onChange: (page) => {
                          console.log(page);
                        },
                        pageSize: 20,
                      }}
                      dataSource={transcriptData.length > 0 ? transcriptData[0].searchResults : []}
                      locale={{
                        emptyText: (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={<span>{emptyTextField}</span>}
                          />
                        ),
                      }}
                      renderItem={(result: any, index) => (
                        <Transcripts
                          showTags={true}
                          keywords={keywords}
                          hideProgressBar={true}
                          data={result}
                          expanded={expanded}
                          addToStoryApi={addToStorySearch}
                          pinnedStoriesData={pinnedStories}
                          fetchedStoryData={fetchedStoryData}
                          fetchStoryData={fetchStoryData}
                          addNewStory={addNewStory}
                          changePinnedStories={changePinnedStories}
                          transcriptData={
                            transcriptData.length > 0 ? transcriptData[0].searchResults : []
                          }
                          searchString={searchString}
                          setSearchString={setSearchString}
                          fetchedFilters={fetchedFilters}
                        />
                      )}
                    />
                  </div>
                )}
              </TranscriptsWrapper>
            </div>
            {showResultSummary ? (
              <div
                className="bg-white shadow-md shadow-gray-900 ml-4 p-4"
                style={{ flexBasis: "40%", overflowY: "auto" }}
              >
                <p className="font-semibold text-base">
                  Search Result Summary
                  <Select
                    clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
                    className="ml-4 float-right w-60"
                    placeholder="Select Quality"
                    allowClear
                    mode="multiple"
                    // defaultValue={searchFilterData ? searchFilterData : "High"}
                    onChange={(value: string) => {
                      handleFilters(value);
                    }}
                  >
                    <Select.Option value="Good">High</Select.Option>
                    <Select.Option value="Average">Medium</Select.Option>
                    <Select.Option value="Bad">Low</Select.Option>
                  </Select>
                </p>
                <p className="font-semibold mt-5 text-sm mb-3">Therapy Status Distribution</p>
                <div className="flex h-60 border-2">
                  <ResponsiveBar
                    data={[
                      {
                        "Therapy Status": "Discontinued",
                        Inbound: findValueForTherapyStatusGraph(
                          "Discontinued",
                          "Inbound",
                          searchFilterData
                        ),
                        "inbound Color": "rgb(224,198,255)",
                        Outbound: findValueForTherapyStatusGraph(
                          "Discontinued",
                          "Outbound",
                          searchFilterData
                        ),
                        "outbound Color": "hsl(267, 100%, 88.8%)",
                      },
                      {
                        "Therapy Status": "Therapy",
                        Inbound: findValueForTherapyStatusGraph(
                          "On Therapy",
                          "Inbound",
                          searchFilterData
                        ),
                        "inbound Color": "rgb(224,198,255)",
                        Outbound: findValueForTherapyStatusGraph(
                          "On Therapy",
                          "Outbound",
                          searchFilterData
                        ),
                        "outbound Color": "hsl(41, 70%, 50%)",
                      },
                      {
                        "Therapy Status": "Therapy Repeat ",
                        Inbound: findValueForTherapyStatusGraph(
                          "ON THERAPY REPEAT",
                          "Inbound",
                          searchFilterData
                        ),
                        "inbound Color": "rgb(224,198,255)",
                        Outbound: findValueForTherapyStatusGraph(
                          "ON THERAPY REPEAT",
                          "Outbound",
                          searchFilterData
                        ),
                        "outbound Color": "hsl(41, 70%, 50%)",
                      },
                      {
                        "Therapy Status": "In-Process",
                        Inbound: findValueForTherapyStatusGraph(
                          "IN PROCESS",
                          "Inbound",
                          searchFilterData
                        ),
                        "inbound Color": "rgb(224,198,255)",
                        Outbound: findValueForTherapyStatusGraph(
                          "IN PROCESS",
                          "Outbound",
                          searchFilterData
                        ),
                        "outbound Color": "hsl(41, 70%, 50%)",
                      },
                      {
                        "Therapy Status": "Unknown",
                        Inbound: findValueForTherapyStatusGraph(
                          "UNKNOWN",
                          "Inbound",
                          searchFilterData
                        ),
                        "inbound Color": "rgb(224,198,255)",
                        Outbound: findValueForTherapyStatusGraph(
                          "UNKNOWN",
                          "Outbound",
                          searchFilterData
                        ),
                        "outbound Color": "hsl(41, 70%, 50%)",
                      },
                      {
                        "Therapy Status": "Never Start",
                        Inbound: findValueForTherapyStatusGraph(
                          "NEVER START",
                          "Inbound",
                          searchFilterData
                        ),
                        "inbound Color": "rgb(224,198,255)",
                        Outbound: findValueForTherapyStatusGraph(
                          "NEVER START",
                          "Outbound",
                          searchFilterData
                        ),
                        "outbound Color": "hsl(41, 70%, 50%)",
                      },
                    ]}
                    keys={["Inbound", "Outbound"]}
                    indexBy="Therapy Status"
                    margin={{ top: 20, right: 20, bottom: 90, left: 35 }}
                    padding={0.3}
                    valueScale={{ type: "linear" }}
                    indexScale={{ type: "band", round: true }}
                    // colors={{ scheme: "nivo" }}
                    colors={getColor}
                    defs={[
                      {
                        id: "dots",
                        type: "patternDots",
                        background: "inherit",
                        color: "#38bcb2",
                        size: 4,
                        padding: 1,
                        stagger: true,
                      },
                      {
                        id: "lines",
                        type: "patternLines",
                        background: "inherit",
                        color: "#eed312",
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10,
                      },
                    ]}
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 1.6]],
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                      format: (v) => {
                        return v.length > 14 ? (
                          <tspan>
                            {v.substring(0, 12) + ".."}
                            <p>{v}</p>
                          </tspan>
                        ) : (
                          v
                        );
                      },
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: <tspan>Therapy Status</tspan>,
                      legendPosition: "middle",
                      legendOffset: 40,
                    }}
                    axisLeft={{
                      format: (e) => Math.floor(e) === e && e,
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: "food",
                      legendPosition: "middle",
                      legendOffset: -40,
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{
                      from: "color",
                      modifiers: [["darker", 1.6]],
                    }}
                    legends={[
                      {
                        dataFrom: "keys",
                        anchor: "bottom",
                        direction: "row",
                        justify: false,
                        translateX: 30,
                        translateY: 80,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: "left-to-right",
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                          {
                            on: "hover",
                            style: {
                              itemOpacity: 1,
                            },
                          },
                        ],
                      },
                    ]}
                    role="application"
                    ariaLabel="Therapy Status Distribution"
                  />
                </div>
                <p className="font-semibold mt-5 text-sm mb-3">
                  Discussion Sentiment
                  <span className="font-normal float-right">
                    Select Level
                    <Select
                      clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
                      className="ml-4 -mt-2 float-right w-32"
                      placeholder="Select Quality"
                      allowClear
                      defaultValue={selectLevel ? selectLevel : "Speaker"}
                      onChange={(value: string) => {
                        handleFilters2(value);
                      }}
                    >
                      <Select.Option value="Topic">Topic</Select.Option>
                      <Select.Option value="Speaker">Speaker</Select.Option>
                    </Select>
                  </span>
                </p>
                <div className="flex h-60 border-2">
                  {selectLevel === "Topic" ? (
                    <ResponsiveBar
                      layout="horizontal"
                      colors={getColor}
                      data={arrForFilteringChart.map((i: any) => i)}
                      keys={["Positive", "Neutral", "Negative"]}
                      indexBy="Topic"
                      margin={{ top: 10, right: 110, bottom: 30, left: 160 }}
                      padding={0.3}
                      valueScale={{ type: "linear" }}
                      indexScale={{ type: "band", round: true }}
                      defs={[
                        {
                          id: "dots",
                          type: "patternDots",
                          background: "inherit",
                          color: "#38bcb2",
                          size: 4,
                          padding: 1,
                          stagger: true,
                        },
                        {
                          id: "lines",
                          type: "patternLines",
                          background: "inherit",
                          color: "#eed312",
                          rotation: -45,
                          lineWidth: 6,
                          spacing: 10,
                        },
                      ]}
                      borderColor={{
                        from: "color",
                        modifiers: [["darker", 1.6]],
                      }}
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        tickSize: 0,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: "",
                        legendPosition: "middle",
                        legendOffset: 38,
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: "middle",
                        legendOffset: -40,
                      }}
                      labelSkipWidth={12}
                      labelSkipHeight={12}
                      labelTextColor={{
                        from: "color",
                        modifiers: [["darker", 1.6]],
                      }}
                      legends={[
                        {
                          dataFrom: "keys",
                          anchor: "bottom-right",
                          direction: "column",
                          justify: false,
                          translateX: 120,
                          translateY: 0,
                          itemsSpacing: 2,
                          itemWidth: 100,
                          itemHeight: 20,
                          itemDirection: "left-to-right",
                          itemOpacity: 0.85,
                          symbolSize: 20,
                          effects: [
                            {
                              on: "hover",
                              style: {
                                itemOpacity: 1,
                              },
                            },
                          ],
                        },
                      ]}
                      role="application"
                      ariaLabel="Therapy Status Distribution"
                    />
                  ) : (
                    <ResponsiveBar
                      layout="horizontal"
                      colors={getColor}
                      data={[
                        {
                          Sentiment: "Patient",
                          Positive: findValueForSentimentGraph(
                            "Patient",
                            "Positive",
                            searchFilterData,
                            false
                          ),
                          "positive Color": "hsl(251, 70%, 50%)",
                          Neutral: findValueForSentimentGraph(
                            "Patient",
                            "Neutral",
                            searchFilterData,
                            false
                          ),
                          "neutral Color": "hsl(41, 70%, 50%)",
                          Negative: findValueForSentimentGraph(
                            "Patient",
                            "Negative",
                            searchFilterData,
                            false
                          ),
                          "negative Color": "hsl(41, 70%, 50%)",
                        },
                        {
                          Sentiment: "Agent",
                          Positive: findValueForSentimentGraph(
                            "Agent",
                            "Positive",
                            searchFilterData,
                            false
                          ),
                          "positive Color": "hsl(251, 70%, 50%)",
                          Neutral: findValueForSentimentGraph(
                            "Agent",
                            "Neutral",
                            searchFilterData,
                            false
                          ),
                          "neutral Color": "hsl(41, 70%, 50%)",
                          Negative: findValueForSentimentGraph(
                            "Agent",
                            "Negative",
                            searchFilterData,
                            false
                          ),
                          "negative Color": "hsl(41, 70%, 50%)",
                        },
                      ]}
                      keys={["Positive", "Neutral", "Negative"]}
                      indexBy="Sentiment"
                      margin={{ top: 20, right: 130, bottom: 50, left: 60 }}
                      padding={0.3}
                      valueScale={{ type: "linear" }}
                      indexScale={{ type: "band", round: true }}
                      defs={[
                        {
                          id: "dots",
                          type: "patternDots",
                          background: "inherit",
                          color: "#38bcb2",
                          size: 4,
                          padding: 1,
                          stagger: true,
                        },
                        {
                          id: "lines",
                          type: "patternLines",
                          background: "inherit",
                          color: "#eed312",
                          rotation: -45,
                          lineWidth: 6,
                          spacing: 10,
                        },
                      ]}
                      borderColor={{
                        from: "color",
                        modifiers: [["darker", 1.6]],
                      }}
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        tickSize: 0,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: "",
                        legendPosition: "middle",
                        legendOffset: 38,
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: "middle",
                        legendOffset: -40,
                      }}
                      labelSkipWidth={12}
                      labelSkipHeight={12}
                      labelTextColor={{
                        from: "color",
                        modifiers: [["darker", 1.6]],
                      }}
                      legends={[
                        {
                          dataFrom: "keys",
                          anchor: "bottom-right",
                          direction: "column",
                          justify: false,
                          translateX: 120,
                          translateY: 0,
                          itemsSpacing: 2,
                          itemWidth: 100,
                          itemHeight: 20,
                          itemDirection: "left-to-right",
                          itemOpacity: 0.85,
                          symbolSize: 20,
                          effects: [
                            {
                              on: "hover",
                              style: {
                                itemOpacity: 1,
                              },
                            },
                          ],
                        },
                      ]}
                      role="application"
                      ariaLabel="Therapy Status Distribution"
                    />
                  )}
                </div>

                <p className="font-semibold mt-5 text-sm mb-3">
                  Time on Therapy Distribution
                  <span className="font-normal float-right">
                    Show Therapy Status
                    <Switch className="ml-2" onChange={handleFilters3} />
                  </span>
                </p>
                <div className="flex h-60 border-2">
                  {therapyStatus ? (
                    <ResponsiveLine
                      data={[
                        {
                          id: "On Therapy",
                          color: "hsl(331, 70%, 50%)",
                          data: [
                            {
                              x: "30 days",
                              y: findValueForToT(-99999, 30, searchFilterData, "On Therapy"),
                            },
                            {
                              x: "60 days",
                              y: findValueForToT(30, 60, searchFilterData, "On Therapy"),
                            },
                            {
                              x: "90 days",
                              y: findValueForToT(60, 90, searchFilterData, "On Therapy"),
                            },
                            {
                              x: "180 days",
                              y: findValueForToT(90, 180, searchFilterData, "On Therapy"),
                            },
                            {
                              x: "360 days",
                              y: findValueForToT(180, 360, searchFilterData, "On Therapy"),
                            },
                            {
                              x: "360+ days",
                              y: findValueForToT(360, 99999999, searchFilterData, "On Therapy"),
                            },
                          ],
                        },
                        {
                          id: "Discontinued",
                          color: "hsl(281, 70%, 50%)",
                          data: [
                            {
                              x: "30 days",
                              y: findValueForToT(-99999, 30, searchFilterData, "Discontinued"),
                            },
                            {
                              x: "60 days",
                              y: findValueForToT(30, 60, searchFilterData, "Discontinued"),
                            },
                            {
                              x: "90 days",
                              y: findValueForToT(60, 90, searchFilterData, "Discontinued"),
                            },
                            {
                              x: "180 days",
                              y: findValueForToT(90, 180, searchFilterData, "Discontinued"),
                            },
                            {
                              x: "360 days",
                              y: findValueForToT(180, 360, searchFilterData, "Discontinued"),
                            },
                            {
                              x: "360+ days",
                              y: findValueForToT(360, 99999999, searchFilterData, "Discontinued"),
                            },
                          ],
                        },
                        {
                          id: "Therapy Repeat",
                          color: "hsl(281, 70%, 50%)",
                          data: [
                            {
                              x: "30 days",
                              y: findValueForToT(-99999, 30, searchFilterData, "Therapy Repeat"),
                            },
                            {
                              x: "60 days",
                              y: findValueForToT(30, 60, searchFilterData, "Therapy Repeat"),
                            },
                            {
                              x: "90 days",
                              y: findValueForToT(60, 90, searchFilterData, "Therapy Repeat"),
                            },
                            {
                              x: "180 days",
                              y: findValueForToT(90, 180, searchFilterData, "Therapy Repeat"),
                            },
                            {
                              x: "360 days",
                              y: findValueForToT(180, 360, searchFilterData, "Therapy Repeat"),
                            },
                            {
                              x: "360+ days",
                              y: findValueForToT(360, 99999999, searchFilterData, "Therapy Repeat"),
                            },
                          ],
                        },
                        {
                          id: "In Process",
                          color: "hsl(281, 70%, 50%)",
                          data: [
                            {
                              x: "30 days",
                              y: findValueForToT(-99999, 30, searchFilterData, "In Process"),
                            },
                            {
                              x: "60 days",
                              y: findValueForToT(-99999, 30, searchFilterData, "In Process"),
                            },
                            {
                              x: "90 days",
                              y: findValueForToT(-99999, 30, searchFilterData, "In Process"),
                            },
                            {
                              x: "180 days",
                              y: findValueForToT(-99999, 30, searchFilterData, "In Process"),
                            },
                            {
                              x: "360 days",
                              y: findValueForToT(-99999, 30, searchFilterData, "In Process"),
                            },
                            {
                              x: "360+ days",
                              y: findValueForToT(-99999, 30, searchFilterData, "In Process"),
                            },
                          ],
                        },
                        {
                          id: "Unknown",
                          color: "hsl(281, 70%, 50%)",
                          data: [
                            {
                              x: "30 days",
                              y: findValueForToT(-99999, 30, searchFilterData, "UNKNOWN"),
                            },
                            {
                              x: "60 days",
                              y: findValueForToT(30, 60, searchFilterData, "UNKNOWN"),
                            },
                            {
                              x: "90 days",
                              y: findValueForToT(60, 90, searchFilterData, "UNKNOWN"),
                            },
                            {
                              x: "180 days",
                              y: findValueForToT(90, 180, searchFilterData, "UNKNOWN"),
                            },
                            {
                              x: "360 days",
                              y: findValueForToT(180, 360, searchFilterData, "UNKNOWN"),
                            },
                            {
                              x: "360+ days",
                              y: findValueForToT(360, 99999, searchFilterData, "UNKNOWN"),
                            },
                          ],
                        },
                        {
                          id: "Never Start",
                          color: "hsl(281, 70%, 50%)",
                          data: [
                            {
                              x: "30 days",
                              y: findValueForToT(-99999, 30, searchFilterData, "Never Start"),
                            },
                            {
                              x: "60 days",
                              y: findValueForToT(30, 60, searchFilterData, "Never Start"),
                            },
                            {
                              x: "90 days",
                              y: findValueForToT(60, 90, searchFilterData, "Never Start"),
                            },
                            {
                              x: "180 days",
                              y: findValueForToT(90, 180, searchFilterData, "Never Start"),
                            },
                            {
                              x: "360 days",
                              y: findValueForToT(180, 360, searchFilterData, "Never Start"),
                            },
                            {
                              x: "360+ days",
                              y: findValueForToT(360, 99999999, searchFilterData, "Never Start"),
                            },
                          ],
                        },
                      ]}
                      margin={{ top: 20, right: 130, bottom: 60, left: 60 }}
                      xScale={{ type: "point" }}
                      yScale={{
                        type: "linear",
                        min: "auto",
                        max: "auto",
                        stacked: false,
                        reverse: false,
                      }}
                      yFormat=" >-.2f"
                      axisTop={null}
                      axisRight={null}
                      enableGridX={false}
                      pointSize={10}
                      pointColor={{ theme: "background" }}
                      pointBorderWidth={2}
                      pointBorderColor={{ from: "serieColor" }}
                      pointLabelYOffset={-12}
                      useMesh={true}
                      axisLeft={{
                        format: (e) => Math.floor(e) === e && e,
                      }}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: <tspan className="font-semibold">Time on Therapy</tspan>,
                        legendPosition: "middle",
                        legendOffset: 40,
                      }}
                      legends={[
                        {
                          anchor: "bottom-right",
                          direction: "column",
                          justify: false,
                          translateX: 100,
                          translateY: 0,
                          itemsSpacing: 0,
                          itemDirection: "left-to-right",
                          itemWidth: 80,
                          itemHeight: 20,
                          itemOpacity: 0.75,
                          symbolSize: 12,
                          symbolShape: "circle",
                          symbolBorderColor: "rgba(0, 0, 0, .5)",
                          effects: [
                            {
                              on: "hover",
                              style: {
                                itemBackground: "rgba(0, 0, 0, .03)",
                                itemOpacity: 1,
                              },
                            },
                          ],
                        },
                      ]}
                    />
                  ) : (
                    <ResponsiveLine
                      data={[
                        {
                          id: "Total",
                          color: "hsl(331, 70%, 50%)",
                          data: [
                            {
                              x: "30 days",
                              y: findValueForToT(-99999, 30, searchFilterData),
                            },
                            {
                              x: "60 days",
                              y: findValueForToT(30, 60, searchFilterData),
                            },
                            {
                              x: "90 days",
                              y: findValueForToT(60, 90, searchFilterData),
                            },
                            {
                              x: "180 days",
                              y: findValueForToT(90, 180, searchFilterData),
                            },
                            {
                              x: "360 days",
                              y: findValueForToT(180, 360, searchFilterData),
                            },
                            {
                              x: "360+ days",
                              y: findValueForToT(360, 99999999, searchFilterData),
                            },
                          ],
                        },
                      ]}
                      margin={{ top: 20, right: 80, bottom: 60, left: 50 }}
                      xScale={{ type: "point" }}
                      yScale={{
                        type: "linear",
                        min: "auto",
                        max: "auto",
                        stacked: false,
                        reverse: false,
                      }}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: <tspan className="font-semibold">Time on Therapy</tspan>,
                        legendPosition: "middle",
                        legendOffset: 40,
                      }}
                      colors="#3287C4"
                      yFormat=" >-.2f"
                      axisTop={null}
                      axisLeft={{
                        format: (e) => Math.floor(e) === e && e,
                      }}
                      axisRight={null}
                      enableGridX={false}
                      pointSize={10}
                      pointColor={{ theme: "background" }}
                      pointBorderWidth={2}
                      pointBorderColor={{ from: "serieColor" }}
                      pointLabelYOffset={-12}
                      useMesh={true}
                      legends={[
                        {
                          anchor: "bottom-right",
                          direction: "column",
                          justify: false,
                          translateX: 100,
                          translateY: 0,
                          itemsSpacing: 0,
                          itemDirection: "left-to-right",
                          itemWidth: 80,
                          itemHeight: 20,
                          itemOpacity: 0.75,
                          symbolSize: 12,
                          symbolShape: "circle",
                          symbolBorderColor: "rgba(0, 0, 0, .5)",
                          effects: [
                            {
                              on: "hover",
                              style: {
                                itemBackground: "rgba(0, 0, 0, .03)",
                                itemOpacity: 1,
                              },
                            },
                          ],
                        },
                      ]}
                    />
                  )}
                </div>

                <p className="font-semibold mt-5 text-sm mb-3">Location of Calls</p>
                <div className="border-2" style={{ height: "240px", overflow: "hidden" }}>
                  <div style={{ height: "476px" }}>
                    <ResponsiveChoropleth
                      data={transcriptData.length > 0 ? transcriptData[0].statesResults : []}
                      features={usFeatures.features}
                      margin={{
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                      }}
                      colors={["#D8ECFB", "#99D3FD", "#3B9FE7", "#2A71A4"]}
                      domain={[
                        0,
                        Math.max.apply(
                          Math,
                          transcriptData[0].statesResults.map(function (o: any) {
                            return o.value;
                          })
                        ),
                      ]}
                      unknownColor="#666666"
                      label="properties.name"
                      projectionScale={140}
                      projectionTranslation={[0.52, 0.24]}
                      projectionRotation={[0, 0, 0]}
                      enableGraticule={false}
                      graticuleLineColor="#dddddd"
                      borderWidth={0.5}
                      borderColor="#152538"
                    />
                  </div>
                </div>
                <p className="mt-7 font-semibold text-sm">Key Words and Concepts</p>
                <div className="flex h-60 mt-2 border-2">
                  <ResponsiveBar
                    layout="horizontal"
                    colors={"#3B9FE7"}
                    data={temp.reverse()}
                    keys={["value"]}
                    indexBy="text"
                    margin={{ top: 15, right: 30, bottom: 15, left: 200 }}
                    padding={0.3}
                    valueScale={{ type: "linear" }}
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 1.6]],
                    }}
                    enableGridY={false}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={null}
                    axisLeft={{
                      tickSize: 0,
                      tickPadding: 5,
                      tickRotation: 0,
                      legendPosition: "middle",
                      legendOffset: -40,
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={20}
                    labelTextColor={{
                      from: "color",
                      modifiers: [["darker", 1.6]],
                    }}
                    role="application"
                    ariaLabel="Therapy Status Distribution"
                  />
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}
