import { Button, Form, Input, Select } from "antd";
import React from "react";

/**
 * This function is used to render the Modal to create a new story
 * 
 * @param handleCancel - This props is used to close the modal
 * @param storytypes - This props show the dropdown of story types
 * @param editData -  This props store the story name and  story type
 * @param onSave - This props is used to save the story data
 *
 * @returns the modal  to edit or create story
 */

export default function AddStoryModalContent(props: {
	handleCancel: () => void;
	storyTypes: string[];
	editData?: any;
	onSave?: (editData?: any) => void;
}) {
	const { Option } = Select;
	const [storyName, setSoryName] = React.useState(
		props.editData ? props.editData["Story Name"] : ""
	);
	const [storyTypes, setStoryTypes] = React.useState(
		props.editData ? props.editData["Story Type"] : ""
	);

	const onFinish = (values: any) => {
		console.log(values);
		if (props.onSave && props.editData) {
			props.onSave({
				id: props.editData.id,
				"Story Name": values["Story Name"],
				"Story Type": values["Story Type"],
			});
		} else if (props.onSave) {
			props.onSave({
				"Story Name": values["Story Name"],
				"Story Type": values["Story Type"],
			});
		}
	};
	return (
		<div>
			<div className="text-2xl text-text-gray-100 font-normal my-4">
				{props.editData ? <>Edit Story</> : <>Create New Story</>}
			</div>
			<div className="text-base text-text-gray-50 my-6">
				{props.editData ? (
					<>Please complete the form below to edit the story.</>
				) : (
					<>Please complete the form below to create a new story.</>
				)}
			</div>
			<div className="my-2">
				<Form
					name="newStoryStory"
					labelCol={{ span: 24 }}
					wrapperCol={{ span: 24 }}
					initialValues={props.editData}
					layout="vertical"
					onFinish={onFinish}
					autoComplete="off"
				>
					<Form.Item
						name="Story Name"
						label={
							<div className="text-sm text-text-gray-90 font-medium">
								Story Name
							</div>
						}
						className="mt-4 text-base "
						rules={[
							{
								required: true,
								message: "Story name is required!",
							},
						]}
					>
						<Input
							// defaultValue={storyName}
							onChange={(e) => {
								setSoryName(e.target.value);
							}}
							placeholder="Enter a story name"
						/>
					</Form.Item>
					<Form.Item
						name="Story Type"
						label={
							<div className="text-sm text-text-gray-90 font-medium">
								Story Type
							</div>
						}
						rules={[
							{
								required: true,
								message: "Story type is required!",
							},
						]}
					>
						<Select
							placeholder="Please select a story type"
							// defaultValue={storyTypes || undefined}
							onChange={(value: string) => {
								setStoryTypes(value);
							}}
						>
							{props.storyTypes.map((story) => (
								<Select.Option value={story} key={story}>
									{story}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<div className="flex pt-4">
						<Form.Item>
							<button
								type="submit"
								className="bg-text-teal text-white text-base border-text-teal border-2 px-2 py-1"
							>
								{props.editData ? (
									<>Edit Story</>
								) : (
									<>Create Story</>
								)}
							</button>
						</Form.Item>
						<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
							<button
								type="button"
								className="bg-white text-text-gray-80 text-base px-2 py-1 border-2 rounded-sm"
								onClick={() => {
									if (props.handleCancel)
										props.handleCancel();
								}}
							>
								Cancel
							</button>
						</Form.Item>
					</div>
				</Form>
			</div>
		</div>
	);
}
