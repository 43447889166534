import { Link } from "react-router-dom";
import * as ReportTypesComponentInterface from "../../../libraries/report-types/charts/interfaces/main";
import ReportTypes from "../../../libraries/report-types";
import { ColumnFilter } from "../../../libraries/report-types/charts/table/simpleTable/columnFilter";
import { chartAreaLayout } from "../../../libraries/report-types/charts/interfaces/main";
export function transformBarChartData(
  rawChartData: any,
  chartColor: string[],
  chartType = "line",
  index = 0
) {
  const transformedData: {
    series: {
      dim: number;
      color: string;
      name: string;
    }[];
    data: (string[] | number[])[];
  } = {
    series: Array(),
    data: Array(),
  };
  let index1 = 0;
  var rawData: any = [];
  if (typeof rawChartData === "object" && !Array.isArray(rawChartData) && rawChartData !== null) {
    rawData = rawChartData;
  } else {
    rawData = rawChartData[index];
  }
  for (let key in rawData) {
    if (transformedData.data.length === 0) transformedData.data.push([key]);
    else
      transformedData.data[0].push(
        //@ts-ignore
        key
      );

    if (index1 === 0) {
      let index2 = 0;
      for (let key2 in rawData[key]) {
        transformedData.series.push({
          dim: index2 + 1,
          color: chartType === "bar" ? chartColor[index2 + 1] : chartColor[index2],
          name: key2,
        });
        index2 += 1;
      }
    }
    let index2 = 0;
    for (let key2 in rawData[key]) {
      if (transformedData.data.length - 1 <= index2)
        transformedData.data.push([rawData[key][key2]]);
      else
        transformedData.data[index2 + 1].push(
          //@ts-ignore
          rawData[key][key2]
        );

      index2 += 1;
    }

    index1 += 1;
  }
  // console.log('tranformed dtaa',transformedData?[0]?[0])
  return transformedData;
}

export function transformTableData(
  rawChartData: any,
  chartAccessor?: {
    id: string;
    type: string;
  }[],
  tableLinks?: {
    id: string;
    link: string;
  }[],
  showModal?: (storyName: string) => void | ((story: any) => void),
  showLink?: boolean,
  completeData?: boolean
) {
  // console.log(tableLinks);
  const transformedData: {
    column: {
      title: string;
      accessor: string;
      width?: number;
      Filter?: ({ column }: { column: any }) => JSX.Element;
      sort?: boolean;
    }[];
    data: any;
  } = {
    column: Array(),
    data: Array(),
  };
  var rawData: any = [];
  if (typeof rawChartData === "object" && !Array.isArray(rawChartData) && rawChartData !== null) {
    rawData = Object.assign([], rawChartData);
  } else {
    rawData = rawChartData;
  }
  // console.log("Raw Chart Data ", rawData);
  rawData.map((row: any, index: number) => {
    if (index === 0) {
      for (let key in row) {
        if (chartAccessor && chartAccessor.find((name) => name.id.split(" ").join("_") === key)) {
          transformedData.column.push({
            title: key,
            accessor: key.split(" ").join("_"),
            // width: 200,
            Filter: ColumnFilter,
            sort: true,
          });
        } else {
          transformedData.column.push({
            title: key,
            accessor: key.split(" ").join("_"),
            width: 200,
            Filter: ColumnFilter,
            sort: true,
          });
        }
      }
    }
    const ob: any = {};
    for (let key in row) {
      if (tableLinks && tableLinks.find((l) => l.id === key)) {
        ob[key.split(" ").join("_")] = showLink ? (
          <Link
            to={
              tableLinks.find((l) => l.id === key)?.link !== ""
                ? tableLinks.find((l) => l.id === key)?.link + "/" + row.id
                : ""
            }
            className="text-text-teal cursor-pointer"
            onClick={() => {
              if (showModal) showModal(row[key]);
            }}
          >
            {row[key]}
          </Link>
        ) : (
          <div
            className="text-text-teal cursor-pointer"
            onClick={() => {
              console.log(row);
              if (showModal) {
                if (completeData) {
                  showModal(row);
                } else {
                  showModal(row[key]);
                }
              }
            }}
          >
            {row[key]}
          </div>
        );
      } else {
        ob[key.split(" ").join("_")] = row[key];
      }
    }
    transformedData.data.push(ob);
  });

  // console.log("Transformed Table Data", transformedData);
  return transformedData;
}

export function transformTableChartData(
  transformedTableData: any,
  chartAccessorName?: {
    id: string;
    type: string;
  }[],
  transformBarChartData?: (rawChartData: any, chartColor: string[], chartType?: string) => any,
  chartColor: string[] = [],
  margins?: any
) {
  const transformedData: any[] = [];

  transformedTableData.map((row: any) => {
    const ob: any = {};
    for (let key in row) {
      if (
        chartAccessorName &&
        transformBarChartData &&
        chartAccessorName.find((name) => name.id.split(" ").join("_") === key)
      ) {
        const chartType = chartAccessorName.find(
          (name) => name.id.split(" ").join("_") === key
        )?.type;
        const series = transformBarChartData(row[key], chartColor, chartType).series;
        const data = transformBarChartData(row[key], chartColor).data;

        ob[key] =
          chartType === "line" ? (
            <ReportTypes
              hideShadow
              hideRibbon
              // footer="Footer Text"
              minHeight="30px"
              height="60px"
              tooltip={{ isInteractive: true, format: "y" }}
              crosshair={false}
              bodyPadding="0px 0px"
              title={{ title: "Chart Title" }}
              chartType={
                chartType === "line"
                  ? ReportTypesComponentInterface.chartType.line
                  : ReportTypesComponentInterface.chartType.bar
              }
              series={series}
              data={data}
              axis={{
                primaryX: null,
                primaryY: null,
              }}
              chartArea={{ layout: chartAreaLayout.horizontal }}
              chartConfig={{
                cartesian: {
                  gaps: {
                    barGap: 0.6,
                  },
                  line: {
                    pointSize: 10,
                  },
                  grid: {
                    enableGridX: false,
                    enableGridY: false,
                    gridYValues: 3,
                    gridXValues: 2,
                  },
                },
              }}
              style={{
                margin: {
                  top: margins ? margins.top : 5,
                  right: margins ? margins.right : 50,
                  bottom: margins ? margins.bottom : 5,
                  left: margins ? margins.left : 10,
                },
                labelTextColor: "white",
                axisWidth: 0,
                borderRadius: 1,
              }}
            />
          ) : (
            <>
              <ReportTypes
                hideShadow
                hideRibbon
                // footer="Footer Text"
                minHeight="30px"
                height="42px"
                bodyPadding="6px 0px"
                title={{ title: "Chart Title" }}
                chartType={ReportTypesComponentInterface.chartType.bar}
                series={[
                  {
                    name: "Positive",
                    dim: 1,
                    color: "#26C38D",
                  },
                  {
                    name: "Neutral",
                    dim: 2,
                    color: "#B2B0B6",
                  },
                  {
                    name: "Negative",
                    dim: 3,
                    color: "#E65428",
                  },
                ]}
                data={data}
                axis={{
                  primaryX: {
                    dim: 0,
                    enable: false,
                    minValue: 0,
                    maxValue: "auto",
                  },
                  primaryY: {
                    enable: false,
                    reverse: true,
                  },
                }}
                chartArea={{
                  layout: ReportTypesComponentInterface.chartAreaLayout.horizontal,
                }}
                chartConfig={{
                  cartesian: {
                    gaps: {
                      barGap: 0.6,
                    },
                    line: {
                      pointSize: 10,
                    },
                    grid: {
                      enableGridX: false,
                      enableGridY: false,
                      gridYValues: 3,
                      gridXValues: 2,
                    },
                  },
                }}
                tooltip={{
                  isInteractive: true,
                  format: "stack",
                }}
                style={{
                  margin: {
                    top: 5,
                    right: 10,
                    bottom: 5,
                    left: 10,
                  },
                  labelTextColor: "white",
                  axisWidth: 0,
                  borderRadius: 2,
                  borderWidth: 10,
                }}
              />
            </>
          );
      } else {
        ob[key] = row[key];
      }
    }
    transformedData.push(ob);
  });
  // console.log("Transformed TableChart Data", transformedData);
  return transformedData;
}

export function transformTopChartData(
  topChartData: any,
  chartColor: string[],
  showNumber?: boolean
) {
  const transformedData: {
    series: {
      dim: number;
      color: string;
      name: string;
    }[];
    data: (string[] | number[])[];
  } = {
    series: Array(),
    data: Array(),
  };
  let index = 0;
  for (let key in topChartData) {
    transformedData.series.push({
      dim: index + 1,
      color: chartColor[index],
      name: showNumber ? topChartData[key] + "% " + key : key,
    });
    if (index === 0) transformedData.data.push([key]);
    else
      transformedData.data[0].push(
        //@ts-ignore
        key
      );
    transformedData.data.push([topChartData[key]]);
    index += 1;
  }

  return transformedData;
}
export function transformBubbleChartData(rawChartData: any, chartColor: string[]) {
  const transformedData: {
    series: {
      dim: number;
      color: string;
      name: string;
    }[];
    data: (string[] | number[])[];
  } = {
    series: Array(),
    data: Array(),
  };

  transformedData.series.push({
    dim: 0,
    color: chartColor[0],
    name: "Series 1",
  });
  transformedData.data.push([]);
  rawChartData.map((point: any) => {
    const obj: any = {};
    for (let key in point) {
      if (key === "x" || key === "y" || key === "z") obj[key] = point[key];
      else obj.w = key;
      if (key === "Agent ID") {
        obj.w = point["Agent ID"];
      }
    }
    transformedData.data[0].push(
      //@ts-ignore
      obj
    );
  });
  // console.log("CC", transformedData);
  return transformedData;
}
