import { Drawer } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import Collapse from "../../../../Components/Collapse";
import HighlightDrawer from "../../../../Components/HighlightDrawer";
import TableWrapper from "../../../../Components/TableWrapper";
import { icons } from "../../../../GlobalThemes";

/**
 * Renders the Accordion Component along with its Headers and Sorting.
 *
 * @param headerInfo - Array of String containing Header Names
 * @param data - Data to be shown in the Accordion
 * @param filteredColumns - Array of Strings containing names of the header fields that is to be filtered.
 * @param addHighlightedTranscript - Callback function for handling addition of highlighted transcript
 * @param removeHighlightedTranscript - Callback function for handling removal of highlighted transcript
 * @param updateTranscript - Callback function for handling updation of transcript data on the accordion
 * @param deleteTranscript - Callback function for deleting a Transcript
 *
 * @returns Accordion Component
 */
export interface AnnotatedSelectionInterface {
  id: number;
  "Annotated Selection": string;
  "Date Added on": string;
  Source: string;
  Tags: string[];
  Status: string;
  therapyStatus: string;
  ToT: number;
  Description: {
    tags: string[];
    dialogueAppearedAt: number;
    dialogueLength: number;
    transcriptLength: number;
    dialogueNo: number;
    transcripts: {
      dialogueNo: number;
      speaker: string;
      text: string;
    }[];
  };
}
export default function AnnotatedSelection(props: {
  headerInfo: string[];
  data: AnnotatedSelectionInterface[];
  filteredColumns: string[];
  addHighlightedTranscript: () => void;
  removeHighlightedTranscript: () => void;
  updateTranscript: (
    id: number,
    editData: {
      id: number;
      highlightText: string;
      Tags: string[];
      highlightedString: string;
    }
  ) => void;
  deleteTranscript: (id: number) => void;
  updated?: string;
}) {
  const dataCopy = [...props.data];
  const [data, setData] = React.useState<AnnotatedSelectionInterface[]>(props.data);
  const [order, setOrder] = React.useState(0);

  // console.log("Col", props.filteredColumns);
  const [filters, setFilters] = React.useState<
    {
      columnName: string;
      options: string[];
    }[]
  >([
    ...props.filteredColumns.map((col) => {
      return { columnName: col, options: [] };
    }),
  ]);
  const filterTemp: { [id: string]: string[] } = {};
  const filteredData = React.useRef([...props.data]);
  filters.map((filter) => {
    filterTemp[filter.columnName] = [];
  });
  // filterTemp["Source"] = ["Search Based"];
  const [filtersApplied, setFiltersApplied] = React.useState(filterTemp);
  function sortData(arr: any, columnName: string) {
    if (order === 2) setData(filteredData.current);
    else
      setData(
        [...filteredData.current].sort(function (a: any, b: any) {
          if (new Date(a[columnName]) < new Date(b[columnName])) {
            if (order === 0) return -1;
            else return 1;
          } else {
            if (order === 0) return 1;
            else return -1;
          }
        })
      );
    setOrder((order + 1) % 3);
  }
  const [sliderVal, setSliderVal] = React.useState<any>();

  function filterData(arr: any, columnName: string, columnValue: any) {
    console.log("ToT", arr, columnValue);

    if (columnName === "ToT") {
      let temp: any = [];
      arr.map((i: any) => {
        if (columnValue[1] !== 100) {
          if (i.ToT > marks[columnValue[0]] && i.ToT < marks[columnValue[1]]) {
            temp.push(i);
          }
        } else {
          if (i.ToT > marks[columnValue[0]]) {
            temp.push(i);
          }
        }
      });
      console.log(temp);
      return temp;
    } else {
      return arr.filter((a: any) => {
        if (Array.isArray(a[columnName])) {
          if (a[columnName].find((tag: string) => tag === columnValue)) return true;
          else return false;
        } else {
          return a[columnName] === columnValue;
        }
      });
    }
  }
  function createFilters() {
    const dataTemp = [...data];
    const filtersTemp: {
      columnName: string;
      options: string[];
    }[] = [
      { columnName: "Source", options: [] },
      { columnName: "ToT", options: [] },
      { columnName: "Therapy Status", options: [] },
      { columnName: "Tags", options: [] },
      { columnName: "Status", options: [] },
    ];

    props.filteredColumns.map((filteredCol) => {
      dataTemp.map((d: any) => {
        if (filteredCol === "Tags") {
          const tagArray = d["Tags"];
          tagArray.map((tag: string) => {
            if (filtersTemp.find((f) => f.columnName === "Tags")?.options.indexOf(tag) === -1)
              filtersTemp.find((f) => f.columnName === "Tags")?.options.push(tag);
          });
        } else {
          if (filteredCol === "Therapy Status") {
            if (
              filtersTemp
                .find((f) => f.columnName === filteredCol)
                ?.options.indexOf(d["therapyStatus"]) === -1
            )
              filtersTemp
                .find((f) => f.columnName === filteredCol)
                ?.options.push(d["therapyStatus"]);
          } else {
            if (
              filtersTemp
                .find((f) => f.columnName === filteredCol)
                ?.options.indexOf(d[filteredCol]) === -1
            )
              filtersTemp.find((f) => f.columnName === filteredCol)?.options.push(d[filteredCol]);
          }
        }
      });
    });

    console.log(filterTemp);
    setFilters(filtersTemp);
  }
  function setFilter(
    filtersApplied: {
      [id: string]: string[];
    },
    columnName: string,
    filterValue: any
  ) {
    if (columnName === "ToT") {
      setSliderVal(filterValue);
    }

    const newFilter = { ...filtersApplied, [columnName]: filterValue };
    let tempData = [...props.data];

    for (let key in newFilter) {
      let temp: any[] = [];

      if (newFilter[key].length > 0) {
        newFilter[key].map((d: any) => {
          key === "ToT" &&
            columnName === "ToT" &&
            (temp = temp.concat(
              temp
                .concat(filterData(tempData, "ToT", filterValue))
                .filter((item) => !JSON.stringify(temp).includes(JSON.stringify(item)))
            ));

          if (columnName !== "ToT" && key === "ToT") {
            temp = temp.concat(
              temp
                .concat(filterData(tempData, "ToT", sliderVal))
                .filter((item) => !JSON.stringify(temp).includes(JSON.stringify(item)))
            );
          }
          console.log("TEMP", temp, newFilter, key, d);
          temp =
            key === "Therapy Status"
              ? temp.concat(
                  temp
                    .concat(filterData(tempData, "therapyStatus", d))
                    .filter((item) => !JSON.stringify(temp).includes(JSON.stringify(item)))
                )
              : temp.concat(
                  temp
                    .concat(filterData(tempData, key, d))
                    .filter((item) => !JSON.stringify(temp).includes(JSON.stringify(item)))
                );
          console.log("TEMP 2", temp, tempData, key, d);
        });

        tempData = [...temp];
      }
    }

    filteredData.current = [...tempData];
    setFiltersApplied(newFilter);
    setData(tempData);
    console.log("AFTER", newFilter, tempData, dataCopy);
  }

  const marks: { [id: number]: string } = {
    0: "0",
    16.6: "30",
    33.3: "60",
    50: "90",
    66.6: "180",
    83.3: "360",
    100: "360+",
  };

  function sliderFilter(e: any) {
    setFilter(filtersApplied, "ToT", e);
    console.log(e, data, props.data);
  }

  const [visible, setVisible] = React.useState(false);
  const [drawerHeader, setdrawerHeader] = React.useState("");
  const [drawerBody, setDrawerBody] = React.useState(<></>);
  const showDrawer = (id: number, header: string, transcriptId: number) => {
    setdrawerHeader(header);
    setDrawerBody(
      <HighlightDrawer
        id={transcriptId}
        addHighlightedTranscript={props.addHighlightedTranscript}
        removeHighlightedTranscript={props.removeHighlightedTranscript}
        setVisible={onClose}
        updateTranscript={props.updateTranscript}
      />
    );
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const navigate = useNavigate();
  function handleRedirectOnEmpty() {
    navigate("Search");
  }
  React.useEffect(() => {
    createFilters();
    // console.log("Create Filters Called", props.filters);
  }, [data, props.data, props.updated]);

  React.useEffect(() => {}, [sliderVal]);

  return (
    <>
      <Drawer
        destroyOnClose={true}
        title={drawerHeader}
        placement="right"
        onClose={onClose}
        visible={visible}
        width={680}
      >
        {drawerBody}
      </Drawer>
      {console.log("data", sliderVal)}
      <TableWrapper title="Annotated Selection" value={data.length}>
        {props.data.length === 0 ? (
          <div className="bg-body flex flex-col items-center justify-center h-96 m-2">
            <div className="text-text-gray-80 font-semibold text-base p-2">
              No Annotations Added
            </div>
            <div
              className="flex items-center gap-2 text-text-teal text-base cursor-pointer"
              onClick={() => {
                handleRedirectOnEmpty();
              }}
            >
              {icons.find((l) => l.id === "Plus Filled Green")?.icon}
              Add Annotated Selection
            </div>
          </div>
        ) : (
          <div
            className="mt-4"
            // style={{ height: "28em" }}
            // style={{ height: "70vh" }}
          >
            <Collapse
              headerInfo={props.headerInfo}
              data={data}
              filters={filters}
              sorted={order}
              sortData={sortData}
              // filterData={filterData}
              filtersApplied={filtersApplied}
              sliderFilter={sliderFilter}
              setFilter={setFilter}
              showDrawer={showDrawer}
              deleteTranscript={props.deleteTranscript}
            />
          </div>
        )}
      </TableWrapper>
    </>
  );
}
