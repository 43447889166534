import { Skeleton } from "antd";
import React from "react";
import { icons, userTypeEnum } from "../../GlobalThemes";
import AppContext from "../../store";

export const patientRiskBucket = [
	{
		cardName: "Low",
		value: "245",
		vsPrevMonth: -10,
	},
	{
		cardName: "Medium",
		value: "230",
		vsPrevMonth: -7,
	},
	{
		cardName: "High",
		value: "270",
		vsPrevMonth: 10,
	},
];

export const words = [
	{
		text: "told",
		value: 64,
	},
	{
		text: "mistake",
		value: 11,
	},
	{
		text: "thought",
		value: 16,
	},
	{
		text: "bad",
		value: 17,
	},
	{
		text: "correct",
		value: 10,
	},
	{
		text: "day",
		value: 54,
	},
	{
		text: "prescription",
		value: 12,
	},
	{
		text: "time",
		value: 77,
	},
	{
		text: "thing",
		value: 45,
	},
	{
		text: "left",
		value: 19,
	},
	{
		text: "pay",
		value: 13,
	},
	{
		text: "people",
		value: 32,
	},
	{
		text: "month",
		value: 22,
	},
	{
		text: "again",
		value: 35,
	},
	{
		text: "review",
		value: 24,
	},
	{
		text: "call",
		value: 38,
	},
	{
		text: "doctor",
		value: 70,
	},
	{
		text: "asked",
		value: 26,
	},
	{
		text: "finally",
		value: 14,
	},
	{
		text: "insurance",
		value: 29,
	},
	{
		text: "week",
		value: 41,
	},
	{
		text: "called",
		value: 49,
	},
	{
		text: "problem",
		value: 20,
	},
	{
		text: "going",
		value: 59,
	},
	{
		text: "help",
		value: 49,
	},
	{
		text: "felt",
		value: 45,
	},
	{
		text: "discomfort",
		value: 11,
	},
	{
		text: "lower",
		value: 22,
	},
	{
		text: "severe",
		value: 12,
	},
	{
		text: "free",
		value: 38,
	},
	{
		text: "better",
		value: 54,
	},
	{
		text: "muscle",
		value: 14,
	},
	{
		text: "neck",
		value: 41,
	},
	{
		text: "root",
		value: 24,
	},
	{
		text: "adjustment",
		value: 16,
	},
	{
		text: "therapy",
		value: 29,
	},
	{
		text: "injury",
		value: 20,
	},
	{
		text: "excruciating",
		value: 10,
	},
	{
		text: "chronic",
		value: 13,
	},
	{
		text: "chiropractor",
		value: 35,
	},
	{
		text: "treatment",
		value: 59,
	},
	{
		text: "tooth",
		value: 32,
	},
	{
		text: "chiropractic",
		value: 17,
	},
	{
		text: "dr",
		value: 77,
	},
	{
		text: "relief",
		value: 19,
	},
	{
		text: "shoulder",
		value: 26,
	},
	{
		text: "nurse",
		value: 17,
	},
	{
		text: "room",
		value: 22,
	},
	{
		text: "hour",
		value: 35,
	},
	{
		text: "wait",
		value: 38,
	},
	{
		text: "hospital",
		value: 11,
	},
	{
		text: "eye",
		value: 13,
	},
	{
		text: "test",
		value: 10,
	},
	{
		text: "appointment",
		value: 49,
	},
	{
		text: "medical",
		value: 19,
	},
	{
		text: "question",
		value: 20,
	},
	{
		text: "office",
		value: 64,
	},
	{
		text: "care",
		value: 54,
	},
	{
		text: "minute",
		value: 29,
	},
	{
		text: "waiting",
		value: 16,
	},
	{
		text: "patient",
		value: 59,
	},
	{
		text: "health",
		value: 49,
	},
	{
		text: "alternative",
		value: 24,
	},
	{
		text: "holistic",
		value: 19,
	},
	{
		text: "traditional",
		value: 20,
	},
	{
		text: "symptom",
		value: 29,
	},
	{
		text: "internal",
		value: 17,
	},
	{
		text: "prescribed",
		value: 26,
	},
	{
		text: "acupuncturist",
		value: 16,
	},
	{
		text: "pain",
		value: 64,
	},
	{
		text: "integrative",
		value: 10,
	},
	{
		text: "herb",
		value: 13,
	},
	{
		text: "sport",
		value: 22,
	},
	{
		text: "physician",
		value: 41,
	},
	{
		text: "herbal",
		value: 11,
	},
	{
		text: "eastern",
		value: 12,
	},
	{
		text: "chinese",
		value: 32,
	},
	{
		text: "acupuncture",
		value: 45,
	},
	{
		text: "prescribe",
		value: 14,
	},
	{
		text: "medication",
		value: 38,
	},
	{
		text: "western",
		value: 35,
	},
	{
		text: "sure",
		value: 38,
	},
];

export const DUMMY_DATA = [
	{
		numberOfPatients: 79,
		numberOfSearchResults: 3,
		searchResults: [
			{
				id: 0,
				name: "Transcripts from 12 June 2020 [18:50:23]",
				tags: ["Inbound", "Follow Up Call", "Nervous"],
				storiesAdded: [
					{
						id: 1,
						storyName: "Query about Alcohol Consumption",
					},
				],
				dialogueNo: 34,
				callId: "393EBD7490B340B197BDD2552D4DF263",
				callDate: "2017-11-01T00:00:00",
				callTime: "0:12:20",
				callType: "Inbound",
				therapyStatus: "On Therapy",
				dialogueAppearedAt: 0.2,
				dialogueLength: 1.2,
				transcriptLength: 5,
				S3path: "ABC/transcript_sample_script.json",
				addedToStory: false,
				transcripts: [
					{
						dialogueNo: 1,
						speaker: "Patient",
						text: "Oh okay, sure.",
					},
					{
						dialogueNo: 2,
						speaker: "Agent",
						text: "Are there any other questions I can answer for you?",
					},
					{
						dialogueNo: 3,
						speaker: "Patient",
						text: "So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
					},
					{
						dialogueNo: 4,
						speaker: "Agent",
						text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
					},
					{
						dialogueNo: 5,
						speaker: "Patient",
						text: "Alrigt. Thanks a lot",
					},
				],
			},
			{
				id: 1,
				name: "Transcripts from 10 June 2020 [17:30:58]",
				tags: ["Inbound", "Follow Up Call", "Nervous"],
				dialogueNo: 34,
				callId: "393EBD7490B340B197BDD2552D4DF263",
				callDate: "2017-11-01T00:00:00",
				callTime: "0:12:20",
				callType: "Inbound",
				therapyStatus: "On Therapy",
				dialogueAppearedAt: 0.2,
				dialogueLength: 1.2,
				S3path: "ABC/transcript_sample_script.json",
				transcriptLength: 5,
				addedToStory: false,
				transcripts: [
					{
						dialogueNo: 1,
						speaker: "Patient",
						text: "Oh okay, sure.",
					},
					{
						dialogueNo: 2,
						speaker: "Agent",
						text: "Are there any other questions I can answer for you?",
					},
					{
						dialogueNo: 3,
						speaker: "Patient",
						text: "So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
					},
					{
						dialogueNo: 4,
						speaker: "Agent",
						text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
					},
					{
						dialogueNo: 5,
						speaker: "Patient",
						text: "Alrigt. Thanks a lot",
					},
				],
			},
			{
				id: 2,
				name: "Transcripts from 3 June 2020 [12:33:28]",
				tags: ["Inbound", "Follow Up Call", "Nervous"],
				dialogueAppearedAt: 0.2,
				dialogueNo: 34,
				callId: "393EBD7490B340B197BDD2552D4DF263",
				callDate: "2017-11-01T00:00:00",
				callTime: "0:12:20",
				callType: "Outbound",
				therapyStatus: "On Therapy",
				dialogueLength: 1.2,
				transcriptLength: 5,
				S3path: "ABC/transcript_sample_script.json",
				addedToStory: false,
				transcripts: [
					{
						dialogueNo: 1,
						speaker: "Patient",
						text: "Oh okay, sure.",
					},
					{
						dialogueNo: 2,
						speaker: "Agent",
						text: "Are there any other questions I can answer for you?",
					},
					{
						dialogueNo: 3,
						speaker: "Patient",
						text: "So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
					},
					{
						dialogueNo: 4,
						speaker: "Agent",
						text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
					},
					{
						dialogueNo: 5,
						speaker: "Patient",
						text: "Alrigt. Thanks a lot",
					},
				],
			},
		],
	},
];

export const fetchedFilters: {
	filter: string;
	options?: string[] | undefined;
	optionsObject?:
		| {
				id: number;
				name: string;
				subgroup: {
					id: number;
					name: string;
				}[];
		  }[]
		| undefined;
}[] = [
	{
		filter: "Call_Type",
		optionsObject: [
			{ id: 0, name: "All", subgroup: [] },
			{ id: 1, name: "Inbound", subgroup: [] },
			{ id: 2, name: "Outbound", subgroup: [] },
		],
	},
	{
		filter: "Therapy_Status",
		optionsObject: [
			{ id: 0, name: "All", subgroup: [] },
			{ id: 1, name: "On Therapy", subgroup: [] },
			{ id: 2, name: "Never Started", subgroup: [] },
		],
	},
	{
		filter: "Call_Intent",
		optionsObject: [
			{ id: 0, name: "All", subgroup: [] },
			{ id: 1, name: "Introduction Call", subgroup: [] },
			{ id: 2, name: "Follow-Up Call 1", subgroup: [] },
			{ id: 3, name: "Follow-Up Call 2", subgroup: [] },
			{ id: 4, name: "Follow-Up Call 3", subgroup: [] },
			{ id: 5, name: "Inquiry Call", subgroup: [] },
			{ id: 6, name: "Return Call", subgroup: [] },
		],
	},
	{
		filter: "Emotions",
		optionsObject: [
			{
				id: 0,
				name: "All",
				subgroup: [],
			},
			{
				id: 1,
				name: "Joy",
				subgroup: [
					{ id: 1, name: "Admiration" },
					{ id: 2, name: "Amusement" },
					{ id: 3, name: "Approval" },
					{ id: 4, name: "Caring" },
					{ id: 5, name: "Desire" },
					{ id: 6, name: "Excitement" },
					{ id: 7, name: "Gratitude" },
					{ id: 8, name: "Joy" },
					{ id: 9, name: "Love" },
					{ id: 10, name: "Optimism" },
					{ id: 11, name: "Pride" },
					{ id: 12, name: "Relief" },
				],
			},
			{
				id: 2,
				name: "Anger",
				subgroup: [
					{ id: 1, name: "Anger" },
					{ id: 2, name: "Annoyance" },
				],
			},
			{
				id: 3,
				name: "Sadness",
				subgroup: [
					{ id: 1, name: "Embarrassment" },
					{ id: 2, name: "Disappointment" },
					{ id: 3, name: "Remorse" },
					{ id: 4, name: "Sadness" },
				],
			},
			{ id: 4, name: "Disgust", subgroup: [{ id: 1, name: "Disgust" }] },
			{ id: 5, name: "Fear", subgroup: [{ id: 1, name: "Fear" }] },
			{
				id: 5,
				name: "Surprise",
				subgroup: [
					{ id: 1, name: "Confusion" },
					{ id: 2, name: "Curiosity" },
					{ id: 3, name: "Surprise" },
					{ id: 4, name: "Realization" },
				],
			},
			{ id: 6, name: "Neutral", subgroup: [{ id: 1, name: "Neutral" }] },
		],
	},
	{
		filter: "Adherence",
		optionsObject: [
			{ id: 0, name: "All", subgroup: [] },
			{ id: 1, name: "High", subgroup: [] },
			{ id: 2, name: "Medium", subgroup: [] },
			{ id: 3, name: "Low", subgroup: [] },
		],
	},
];

export const storyTypes = ["General Tracker", "Issues"];
export const storyStatus = ["In Draft", "Publishing", "Published"];

export const fullTranscriptData = [
	{
		speaker: "Patient",
		text: "Hello?",
	},
	{
		speaker: "Agent",
		text: "Hello, may I speak to <NAME>?",
	},
	{
		speaker: "Patient",
		text: "Who am I speaking to?",
	},
	{
		speaker: "Agent",
		text: "My name is <NAME>, I'm a nurse educator and I work for Ashfield on behalf of Biogen.",
	},
	{
		speaker: "Patient",
		text: "Oh okay, sure.",
	},
	{
		speaker: "Agent",
		text: "Are there any other questions I can answer for you?",
	},
	{
		speaker: "Patient",
		text: "So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
	},
	{
		speaker: "Agent",
		text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
	},
	{
		speaker: "Patient",
		text: "Alrigt. Thanks a lot.",
	},
];

export const storyList = [
	{
		chartData: [
			[
				{
					id: 1,
					selected: false,
					"Story Name": "Query about Alcohol Consumption",
					"Story Type": "Type",
					"Date Created": "12th Nov 2021",
					"# of Annotated Selections": 12,
					Status: "Published",
				},
				{
					id: 2,
					selected: false,
					"Story Name": "Worried about Side Effects",
					"Story Type": "Type",
					"Date Created": "12th Nov 2021",
					"# of Annotated Selections": 12,
					Status: "Published",
				},
				{
					id: 3,
					selected: false,
					"Story Name": "Unhappy with meal restrictions",
					"Story Type": "Type",
					"Date Created": "12th Nov 2021",
					"# of Annotated Selections": 12,
					Status: "Published",
				},
				{
					id: 4,
					selected: false,
					"Story Name": "Unhappy with meal restrictions",
					"Story Type": "Type",
					"Date Created": "12th Nov 2021",
					"# of Annotated Selections": 12,
					Status: "Published",
				},
				{
					id: 5,
					selected: false,
					"Story Name": "Unhappy with meal restrictions",
					"Story Type": "Type",
					"Date Created": "12th Nov 2021",
					"# of Annotated Selections": 12,
					Status: "Published",
				},
				{
					id: 6,
					selected: false,
					"Story Name": "Unhappy with meal restrictions",
					"Story Type": "Type",
					"Date Created": "12th Nov 2021",
					"# of Annotated Selections": 12,
					Status: "Published",
				},
				{
					id: 7,
					selected: false,
					"Story Name": "Unhappy with meal restrictions",
					"Story Type": "Type",
					"Date Created": "12th Nov 2021",
					"# of Annotated Selections": 12,
					Status: "Published",
				},
			],
		],
	},
];
export const stories_empty_grouped_columns = [
	{
		title: "Story Name",
		accessor: "Story_Name",
		width: 300,
		Cell: (props: any) => {
			if (props.cell.value === "Updating" || props.cell.value.props.children === "Updating")
				return (
					<div className="text-text-gray-80 text-base">
						{icons.find((l) => l.id === "HourGlass")?.icon}
						Updating
					</div>
				);
			return <div>{props.cell.value}</div>;
		},
	},
	{
		title: "Story Type",
		accessor: "Story_Type",
		sort: true,
		Cell: (props: any) => {
			if (props.cell.value === "Updating")
				return (
					<div className="text-text-gray-80 text-base">
						{icons.find((l) => l.id === "HourGlass")?.icon}
						Updating
					</div>
				);
			return <div>{props.cell.value}</div>;
		},
	},
	{
		title: "Date Created",
		accessor: "Date_Created",
		sort: true,
	},
	{
		title: "# of Annotated Selections",
		accessor: "#_of_Annotated_Selections",
		sort: true,
		Cell: (props: any) => {
			return <div style={{ textAlign: "center" }}>{props.cell.value}</div>;
		},
	},
	{
		title: "Status",
		accessor: "Status",
		sort: true,
		Cell: (props: any) => {
			return (
				<div
					style={{
						textAlign: "left",
					}}
				>
					<div className="flex">
						{icons.find((l) => l.id === props.cell.value)?.icon}
						&nbsp;
						{props.cell.value}
					</div>
				</div>
			);
		},
	},
	{
		title: "Actions",
		accessor: "Actions",
		Cell: (props: any) => {
			return <div style={{ textAlign: "center" }}>{props.cell.value}</div>;
		},
		width: 40,
	},
];
export const storiesEmptyData = [
	{
		Story_Name: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Story_Type: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		"#_of_Annotated_Selections": <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Date_Created: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Status: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
	},
	{
		Story_Name: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Story_Type: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		"#_of_Annotated_Selections": <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Date_Created: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Status: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
	},
	{
		Story_Name: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Story_Type: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		"#_of_Annotated_Selections": <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Date_Created: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Status: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
	},
];

export const annotated_selection_empty_grouped_columns = [
	{
		title: "Annotated Selection",
		accessor: "Annotated_Selection",
		width: 300,
	},
	{
		title: "Date Added on",
		accessor: "Date_Added_on",
	},
	{
		title: "Source",
		accessor: "Source",
	},
	{
		title: "Therapy Status",
		accessor: "therapyStatus",
	},
	{
		title: "ToT",
		accessor: "ToT",
	},
	{
		title: "Tags",
		accessor: "Tags",
	},

	{
		title: "Status",
		accessor: "Status",
	},
	{
		title: "Actions",
		accessor: "Actions",

		width: 40,
	},
];

export const annotatedSelectionEmptyData = [
	{
		Annotated_Selection: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Date_Added_on: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		"#_of_Annotated_Selections": <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Source: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		therapyStatus: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		ToT: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Tags: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Status: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
	},
	{
		Annotated_Selection: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Date_Added_on: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		"#_of_Annotated_Selections": <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Source: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		therapyStatus: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		ToT: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Tags: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Status: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
	},
	{
		Annotated_Selection: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Date_Added_on: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		"#_of_Annotated_Selections": <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Source: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		therapyStatus: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		ToT: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Tags: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Status: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
	},
];
export const publishLimit = 2;

export const filterOptions = [
	{
		filterName: "Show Results",
		type: "radio",
		options: ["All", "Already added to a story", "Not added to a story"],
	},
	{
		filterName: "Speaker Type",
		type: "dropdown",
		options: ["Member", "Agent"],
	},
	{
		filterName: "Time Range",
		type: "date-range",
		options: [],
	},
	{
		filterName: "Time on Therapy",
		type: "slider",
		options: [],
	},
];
export function SearchEngineTabs() {
	const context = React.useContext(AppContext);
	if (context.userType === userTypeEnum[1])
		return [
			{
				subTab: "Search",
				subSubTabs: [],
			},
		];
	else
		return [
			{
				subTab: "Search",
				subSubTabs: [],
			},
			{
				subTab: "Stories",
				subSubTabs: [],
			},
		];
}
export const searchFilterActiveStatus = {
	Sentiment: true,
	Start_Date: true,
	End_Date: true,
	Call_Type: true,
	Therapy_Status: true,
	Topics: true,
	Emotions: true,
	totStart: true,
	totEnd: true,
};

export const patientScoreChartData = [
	{
		cardName: "#Members scorecard over time",
		yAxisLabel: [],
		chartData: [
			{
				"Jul 21": {
					Negative: 1136,
					Neutral: 353,
					Positive: 548,
				},
				"Aug 21": {
					Negative: 714,
					Neutral: 202,
					Positive: 305,
				},
				"Sep 21": {
					Negative: 1220,
					Neutral: 382,
					Positive: 579,
				},
				"Oct 21": {
					Negative: 1142,
					Neutral: 379,
					Positive: 543,
				},
				"Nov 21": {
					Negative: 1150,
					Neutral: 329,
					Positive: 529,
				},
				"Dec 21": {
					Negative: 1044,
					Neutral: 313,
					Positive: 530,
				},
				"Jan 22": {
					Negative: 1162,
					Neutral: 361,
					Positive: 574,
				},
				"Feb 22": {
					Negative: 1130,
					Neutral: 353,
					Positive: 550,
				},
				"Mar 22": {
					Negative: 1179,
					Neutral: 341,
					Positive: 528,
				},
				"Apr 22": {
					Negative: 937,
					Neutral: 297,
					Positive: 441,
				},
				"May 22": {
					Negative: 968,
					Neutral: 276,
					Positive: 401,
				},
			},
			{
				"Jul 21": {
					Negative: 1,
					Neutral: 0,
					Positive: 1,
				},
				"Aug 21": {
					Negative: 2,
					Neutral: 0,
					Positive: 0,
				},
				"Sep 21": {
					Negative: 1,
					Neutral: 1,
					Positive: 0,
				},
				"Nov 21": {
					Negative: 1,
					Neutral: 0,
					Positive: 0,
				},
				"Dec 21": {
					Negative: 3,
					Neutral: 0,
					Positive: 0,
				},
				"Jan 22": {
					Negative: 2,
					Neutral: 0,
					Positive: 0,
				},
				"Feb 22": {
					Negative: 3,
					Neutral: 1,
					Positive: 0,
				},
				"Mar 22": {
					Negative: 2,
					Neutral: 0,
					Positive: 0,
				},
				"Apr 22": {
					Negative: 2,
					Neutral: 0,
					Positive: 1,
				},
			},
			{
				"Jul 21": {
					Negative: 99,
					Neutral: 16,
					Positive: 21,
				},
				"Aug 21": {
					Negative: 62,
					Neutral: 13,
					Positive: 10,
				},
				"Sep 21": {
					Negative: 133,
					Neutral: 11,
					Positive: 15,
				},
				"Oct 21": {
					Negative: 109,
					Neutral: 16,
					Positive: 12,
				},
				"Nov 21": {
					Negative: 86,
					Neutral: 10,
					Positive: 10,
				},
				"Dec 21": {
					Negative: 74,
					Neutral: 11,
					Positive: 18,
				},
				"Jan 22": {
					Negative: 107,
					Neutral: 11,
					Positive: 9,
				},
				"Feb 22": {
					Negative: 84,
					Neutral: 18,
					Positive: 6,
				},
				"Mar 22": {
					Negative: 77,
					Neutral: 9,
					Positive: 15,
				},
				"Apr 22": {
					Negative: 49,
					Neutral: 10,
					Positive: 8,
				},
				"May 22": {
					Negative: 64,
					Neutral: 8,
					Positive: 5,
				},
			},
			{
				"Jul 21": {
					Negative: 0,
					Neutral: 1,
					Positive: 1,
				},
				"Aug 21": {
					Negative: 3,
					Neutral: 0,
					Positive: 0,
				},
				"Sep 21": {
					Negative: 4,
					Neutral: 0,
					Positive: 1,
				},
				"Oct 21": {
					Negative: 3,
					Neutral: 1,
					Positive: 0,
				},
				"Nov 21": {
					Negative: 6,
					Neutral: 0,
					Positive: 1,
				},
				"Jan 22": {
					Negative: 3,
					Neutral: 0,
					Positive: 0,
				},
				"Feb 22": {
					Negative: 3,
					Neutral: 0,
					Positive: 0,
				},
				"Mar 22": {
					Negative: 0,
					Neutral: 0,
					Positive: 1,
				},
				"Apr 22": {
					Negative: 2,
					Neutral: 1,
					Positive: 0,
				},
				"May 22": {
					Negative: 1,
					Neutral: 0,
					Positive: 0,
				},
			},
			{
				"Jul 21": {
					Negative: 259,
					Neutral: 48,
					Positive: 51,
				},
				"Aug 21": {
					Negative: 155,
					Neutral: 25,
					Positive: 31,
				},
				"Sep 21": {
					Negative: 267,
					Neutral: 47,
					Positive: 68,
				},
				"Oct 21": {
					Negative: 237,
					Neutral: 39,
					Positive: 73,
				},
				"Nov 21": {
					Negative: 247,
					Neutral: 33,
					Positive: 59,
				},
				"Dec 21": {
					Negative: 219,
					Neutral: 38,
					Positive: 52,
				},
				"Jan 22": {
					Negative: 208,
					Neutral: 33,
					Positive: 53,
				},
				"Feb 22": {
					Negative: 244,
					Neutral: 36,
					Positive: 57,
				},
				"Mar 22": {
					Negative: 222,
					Neutral: 33,
					Positive: 53,
				},
				"Apr 22": {
					Negative: 181,
					Neutral: 34,
					Positive: 46,
				},
				"May 22": {
					Negative: 179,
					Neutral: 30,
					Positive: 22,
				},
			},
			{
				"Jul 21": {
					Negative: 8,
					Neutral: 1,
					Positive: 6,
				},
				"Aug 21": {
					Negative: 10,
					Neutral: 1,
					Positive: 2,
				},
				"Sep 21": {
					Negative: 4,
					Neutral: 1,
					Positive: 1,
				},
				"Oct 21": {
					Negative: 1,
					Neutral: 0,
					Positive: 1,
				},
				"Nov 21": {
					Negative: 2,
					Neutral: 0,
					Positive: 0,
				},
				"Dec 21": {
					Negative: 2,
					Neutral: 1,
					Positive: 0,
				},
				"Jan 22": {
					Negative: 4,
					Neutral: 0,
					Positive: 1,
				},
				"Feb 22": {
					Negative: 1,
					Neutral: 0,
					Positive: 0,
				},
				"Mar 22": {
					Negative: 0,
					Neutral: 1,
					Positive: 0,
				},
				"May 22": {
					Negative: 2,
					Neutral: 0,
					Positive: 0,
				},
			},
			{
				"Jul 21": {
					Negative: 62,
					Neutral: 16,
					Positive: 21,
				},
				"Aug 21": {
					Negative: 59,
					Neutral: 12,
					Positive: 9,
				},
				"Sep 21": {
					Negative: 70,
					Neutral: 24,
					Positive: 26,
				},
				"Oct 21": {
					Negative: 72,
					Neutral: 28,
					Positive: 23,
				},
				"Nov 21": {
					Negative: 84,
					Neutral: 22,
					Positive: 28,
				},
				"Dec 21": {
					Negative: 95,
					Neutral: 25,
					Positive: 42,
				},
				"Jan 22": {
					Negative: 107,
					Neutral: 32,
					Positive: 36,
				},
				"Feb 22": {
					Negative: 106,
					Neutral: 25,
					Positive: 54,
				},
				"Mar 22": {
					Negative: 80,
					Neutral: 36,
					Positive: 45,
				},
				"Apr 22": {
					Negative: 81,
					Neutral: 26,
					Positive: 58,
				},
				"May 22": {
					Negative: 113,
					Neutral: 23,
					Positive: 60,
				},
			},
			{
				"Jul 21": {
					Negative: 2,
					Neutral: 1,
					Positive: 2,
				},
				"Aug 21": {
					Negative: 3,
					Neutral: 3,
					Positive: 1,
				},
				"Sep 21": {
					Negative: 8,
					Neutral: 1,
					Positive: 1,
				},
				"Oct 21": {
					Negative: 9,
					Neutral: 2,
					Positive: 1,
				},
				"Nov 21": {
					Negative: 3,
					Neutral: 2,
					Positive: 4,
				},
				"Dec 21": {
					Negative: 1,
					Neutral: 2,
					Positive: 4,
				},
				"Jan 22": {
					Negative: 4,
					Neutral: 2,
					Positive: 8,
				},
				"Feb 22": {
					Negative: 6,
					Neutral: 1,
					Positive: 4,
				},
				"Mar 22": {
					Negative: 5,
					Neutral: 0,
					Positive: 0,
				},
				"Apr 22": {
					Negative: 5,
					Neutral: 2,
					Positive: 0,
				},
				"May 22": {
					Negative: 4,
					Neutral: 3,
					Positive: 2,
				},
			},
			{
				"Jul 21": {
					Negative: 650,
					Neutral: 231,
					Positive: 384,
				},
				"Aug 21": {
					Negative: 375,
					Neutral: 140,
					Positive: 215,
				},
				"Sep 21": {
					Negative: 667,
					Neutral: 256,
					Positive: 409,
				},
				"Oct 21": {
					Negative: 658,
					Neutral: 267,
					Positive: 373,
				},
				"Nov 21": {
					Negative: 673,
					Neutral: 229,
					Positive: 382,
				},
				"Dec 21": {
					Negative: 592,
					Neutral: 207,
					Positive: 372,
				},
				"Jan 22": {
					Negative: 675,
					Neutral: 244,
					Positive: 406,
				},
				"Feb 22": {
					Negative: 617,
					Neutral: 243,
					Positive: 370,
				},
				"Mar 22": {
					Negative: 734,
					Neutral: 235,
					Positive: 373,
				},
				"Apr 22": {
					Negative: 555,
					Neutral: 195,
					Positive: 284,
				},
				"May 22": {
					Negative: 564,
					Neutral: 192,
					Positive: 284,
				},
			},
			{
				"Jul 21": {
					Negative: 55,
					Neutral: 39,
					Positive: 61,
				},
				"Aug 21": {
					Negative: 45,
					Neutral: 8,
					Positive: 37,
				},
				"Sep 21": {
					Negative: 66,
					Neutral: 41,
					Positive: 58,
				},
				"Oct 21": {
					Negative: 53,
					Neutral: 26,
					Positive: 60,
				},
				"Nov 21": {
					Negative: 48,
					Neutral: 33,
					Positive: 45,
				},
				"Dec 21": {
					Negative: 58,
					Neutral: 29,
					Positive: 42,
				},
				"Jan 22": {
					Negative: 52,
					Neutral: 39,
					Positive: 61,
				},
				"Feb 22": {
					Negative: 66,
					Neutral: 29,
					Positive: 59,
				},
				"Mar 22": {
					Negative: 59,
					Neutral: 27,
					Positive: 41,
				},
				"Apr 22": {
					Negative: 62,
					Neutral: 29,
					Positive: 44,
				},
				"May 22": {
					Negative: 41,
					Neutral: 20,
					Positive: 28,
				},
			},
		],
		chartColor: ["#76BCEE", "#FFC736", "#EE6767"],
	},
];

export const overAllDisconRate = [
	{
		cardName: "Over All Churn Rate",
		yAxisLabel: [],
		xAxisLabel: [],
		topChart: {},
		chartData: [
			{
				"Jul 21": {
					Positive: 90,
				},
				"Aug 21": {
					Positive: 60,
				},
				"Sep 21": {
					Positive: 70,
				},
				"Oct 21": {
					Positive: 20,
				},
				"Nov 21": {
					Positive: 14,
				},
				"Dec 21": {
					Positive: 26,
				},
				"Jan 21": {
					Positive: 36,
				},
				"Feb 21": {
					Positive: 45,
				},
				"Mar 21": {
					Positive: 28,
				},
				"Apr 21": {
					Positive: 41,
				},
				"May 21": {
					Positive: 80,
				},
			},
		],
		chartColor: ["#4472C4"],
	},
];

export const disconRateRishBucket = [
	{
		options: ["July 21", "Aug 21", "Sep 21", "Oct 21", "Nov 21", "Dec 21", "Jan 21", "Feb 21", "Mar 21", "Apr 21", "May 21", "June 21"],
		cardName: "Churn Rate - By risk Bucket",
		yAxisLabel: [],
		xAxisLabel: [],
		topChart: {},
		chartData: [
			{
				"Jul 21": {
					High: 40,
					Medium: 30,
					Low: 10,
				},
				"Aug 21": {
					High: 80,
					Medium: 70,
					Low: 50,
				},
			},
			{
				"Aug 21": {
					High: 10,
					Medium: 20,
					Low: 30,
				},
				"Sep 21": {
					High: 100,
					Medium: 70,
					Low: 50,
				},
			},
			{
				"Sep 21": {
					High: 70,
					Medium: 80,
					Low: 90,
				},
				"Oct 21": {
					High: 10,
					Medium: 40,
					Low: 60,
				},
			},
			{
				"Oct 21": {
					High: 40,
					Medium: 30,
					Low: 10,
				},
				"Nov 21": {
					High: 55,
					Medium: 100,
					Low: 90,
				},
			},
			{
				"Nov 21": {
					High: 100,
					Medium: 80,
					Low: 70,
				},
				"Dec 21": {
					High: 80,
					Medium: 70,
					Low: 50,
				},
			},
			{
				"Dec 21": {
					High: 20,
					Medium: 30,
					Low: 40,
				},
				"Jan 21": {
					High: 80,
					Medium: 70,
					Low: 50,
				},
			},
			{
				"Jan 21": {
					High: 50,
					Medium: 60,
					Low: 70,
				},
				"Feb 21": {
					High: 80,
					Medium: 70,
					Low: 50,
				},
			},
			{
				"Feb 21": {
					High: 20,
					Medium: 10,
					Low: 70,
				},
				"Mar 21": {
					High: 80,
					Medium: 70,
					Low: 50,
				},
			},
			{
				"Mar 21": {
					High: 10,
					Medium: 10,
					Low: 10,
				},
				"Apr 21": {
					High: 80,
					Medium: 70,
					Low: 50,
				},
			},
			{
				"Apr 21": {
					High: 70,
					Medium: 90,
					Low: 60,
				},
				"May 21": {
					High: 80,
					Medium: 70,
					Low: 50,
				},
			},
			{
				"May 21": {
					High: 30,
					Medium: 40,
					Low: 50,
				},
				"June 21": {
					High: 60,
					Medium: 20,
					Low: 10,
				},
			},
			{
				"June 21": {
					High: 20,
					Medium: 80,
					Low: 90,
				},
				"July 21": {
					High: 80,
					Medium: 70,
					Low: 50,
				},
			},
		],
		chartColor: ["#76BCEE", "#FFC736", "#EE6767"],
	},
];

export const disconRateSplitByTactic = [
	{
		options: ["July 21", "Aug 21", "Sep 21", "Oct 21", "Nov 21", "Dec 21", "Jan 21", "Feb 21", "Mar 21", "Apr 21", "May 21", "June 21"],
		cardName: "Churn Rate - Split By Tactic Activation",
		yAxisLabel: [],
		xAxisLabel: [],
		topChart: {},
		chartData: [
			{
				"Jul 21": {
					"More than One Tactic Scent": 40,
					"One Tactic Scent": 30,
					"No Tactic Scent": 10,
				},
				"Aug 21": {
					"More than One Tactic Scent": 80,
					"One Tactic Scent": 70,
					"No Tactic Scent": 50,
				},
			},
			{
				"Aug 21": {
					"More than One Tactic Scent": 10,
					"One Tactic Scent": 20,
					"No Tactic Scent": 30,
				},
				"Sep 21": {
					"More than One Tactic Scent": 100,
					"One Tactic Scent": 70,
					"No Tactic Scent": 50,
				},
			},
			{
				"Sep 21": {
					"More than One Tactic Scent": 70,
					"One Tactic Scent": 80,
					"No Tactic Scent": 90,
				},
				"Oct 21": {
					"More than One Tactic Scent": 10,
					"One Tactic Scent": 40,
					"No Tactic Scent": 60,
				},
			},
			{
				"Oct 21": {
					"More than One Tactic Scent": 40,
					"One Tactic Scent": 30,
					"No Tactic Scent": 10,
				},
				"Nov 21": {
					"More than One Tactic Scent": 55,
					"One Tactic Scent": 100,
					"No Tactic Scent": 90,
				},
			},
			{
				"Nov 21": {
					"More than One Tactic Scent": 100,
					"One Tactic Scent": 80,
					"No Tactic Scent": 70,
				},
				"Dec 21": {
					"More than One Tactic Scent": 80,
					"One Tactic Scent": 70,
					"No Tactic Scent": 50,
				},
			},
			{
				"Dec 21": {
					"More than One Tactic Scent": 20,
					"One Tactic Scent": 30,
					"No Tactic Scent": 40,
				},
				"Jan 21": {
					"More than One Tactic Scent": 80,
					"One Tactic Scent": 70,
					"No Tactic Scent": 50,
				},
			},
			{
				"Jan 21": {
					"More than One Tactic Scent": 50,
					"One Tactic Scent": 60,
					"No Tactic Scent": 70,
				},
				"Feb 21": {
					"More than One Tactic Scent": 80,
					"One Tactic Scent": 70,
					"No Tactic Scent": 50,
				},
			},
			{
				"Feb 21": {
					"More than One Tactic Scent": 20,
					"One Tactic Scent": 10,
					"No Tactic Scent": 70,
				},
				"Mar 21": {
					"More than One Tactic Scent": 80,
					"One Tactic Scent": 70,
					"No Tactic Scent": 50,
				},
			},
			{
				"Mar 21": {
					"More than One Tactic Scent": 10,
					"One Tactic Scent": 10,
					"No Tactic Scent": 10,
				},
				"Apr 21": {
					"More than One Tactic Scent": 80,
					"One Tactic Scent": 70,
					"No Tactic Scent": 50,
				},
			},
			{
				"Apr 21": {
					"More than One Tactic Scent": 70,
					"One Tactic Scent": 90,
					"No Tactic Scent": 60,
				},
				"May 21": {
					"More than One Tactic Scent": 80,
					"One Tactic Scent": 70,
					"No Tactic Scent": 50,
				},
			},
			{
				"May 21": {
					"More than One Tactic Scent": 30,
					"One Tactic Scent": 40,
					"No Tactic Scent": 50,
				},
				"June 21": {
					"More than One Tactic Scent": 60,
					"One Tactic Scent": 20,
					"No Tactic Scent": 10,
				},
			},
			{
				"June 21": {
					"More than One Tactic Scent": 20,
					"One Tactic Scent": 80,
					"No Tactic Scent": 90,
				},
				"July 21": {
					"More than One Tactic Scent": 80,
					"One Tactic Scent": 70,
					"No Tactic Scent": 50,
				},
			},
		],
		chartColor: ["#C3C3C3", "#BACAE5", "#5980C7"],
	},
];

export const dummyDataTable = [
	{
		Patient_ID: "ZS1000",
		Current_Therapy: "ZSabcd",
		Discon_Risk: "Low",
		Top_Unmet_Needs: "Fear of side effects",
		Therapy_Start_Date: "01 Dec 2022",
		Time_On_Therapy: "223",
		Out_Of_Stock_Date: "01 Dec 2022",
	},
	{
		Patient_ID: "ZS1001",
		Current_Therapy: "ZSabcd",
		Discon_Risk: "High",
		Top_Unmet_Needs: "Confusion on Lab Monitoring",
		Therapy_Start_Date: "01 Dec 2022",
		Time_On_Therapy: "223",
		Out_Of_Stock_Date: "01 Dec 2022",
	},
	{
		Patient_ID: "ZS1002",
		Current_Therapy: "ZSabcd",
		Discon_Risk: "Low",
		Top_Unmet_Needs: "Frustration around delayed shipments",
		Therapy_Start_Date: "01 Dec 2022",
		Time_On_Therapy: "223",
		Out_Of_Stock_Date: "01 Dec 2022",
	},
	{
		Patient_ID: "ZS1003",
		Current_Therapy: "ZSabcd",
		Discon_Risk: "High",
		Top_Unmet_Needs: "Confusion on Dosing",
		Therapy_Start_Date: "01 Dec 2022",
		Time_On_Therapy: "223",
		Out_Of_Stock_Date: "01 Dec 2022",
	},
	{
		Patient_ID: "ZS1004",
		Current_Therapy: "ZSabcd",
		Discon_Risk: "Low",
		Top_Unmet_Needs: "Confusion on beverage consumption",
		Therapy_Start_Date: "01 Dec 2022",
		Time_On_Therapy: "223",
		Out_Of_Stock_Date: "01 Dec 2022",
	},
	{
		Patient_ID: "ZS1005",
		Current_Therapy: "ZSabcd",
		Discon_Risk: "High",
		Top_Unmet_Needs: "Lab Monitoring Guidance (average patient mentions)",
		Therapy_Start_Date: "01 Dec 2022",
		Time_On_Therapy: "223",
		Out_Of_Stock_Date: "01 Dec 2022",
	},
	{
		Patient_ID: "ZS1006",
		Current_Therapy: "ZSabcd",
		Discon_Risk: "High",
		Top_Unmet_Needs: "Common Side Effects (average negativity)",
		Therapy_Start_Date: "01 Dec 2022",
		Time_On_Therapy: "223",
		Out_Of_Stock_Date: "01 Dec 2022",
	},
	{
		Patient_ID: "ZS1007",
		Current_Therapy: "ZSabcd",
		Discon_Risk: "High",
		Top_Unmet_Needs: "Shipment (average patient mentions)",
		Therapy_Start_Date: "01 Dec 2022",
		Time_On_Therapy: "223",
		Out_Of_Stock_Date: "01 Dec 2022",
	},
	{
		Patient_ID: "ZS1008",
		Current_Therapy: "ZSabcd",
		Discon_Risk: "High",
		Top_Unmet_Needs: "Dosing Information (average negativity) ",
		Therapy_Start_Date: "01 Dec 2022",
		Time_On_Therapy: "223",
		Out_Of_Stock_Date: "01 Dec 2022",
	},
	{
		Patient_ID: "ZS1009",
		Current_Therapy: "ZSabcd",
		Discon_Risk: "High",
		Top_Unmet_Needs: "Insurance (average negativity)",
		Therapy_Start_Date: "01 Dec 2022",
		Time_On_Therapy: "223",
		Out_Of_Stock_Date: "01 Dec 2022",
	},
	{
		Patient_ID: "ZS1010",
		Current_Therapy: "ZSabcd",
		Discon_Risk: "High",
		Top_Unmet_Needs: "Diet (average curiousity)",
		Therapy_Start_Date: "01 Dec 2022",
		Time_On_Therapy: "223",
		Out_Of_Stock_Date: "01 Dec 2022",
	},
	{
		Patient_ID: "ZS1011",
		Current_Therapy: "ZSabcd",
		Discon_Risk: "High",
		Top_Unmet_Needs: "Skin Rashes, Itching & Flushing (average negativity)",
		Therapy_Start_Date: "01 Dec 2022",
		Time_On_Therapy: "223",
		Out_Of_Stock_Date: "01 Dec 2022",
	},
];

// export const emptyDataTableColumns = [
// 	{
// 		title: "Patient ID",
// 		accessor: "Patient_ID",
// 		width: 180,
// 		Cell: (props: any) => {
// 			return <div>{props.cell.value}</div>;
// 		},
// 	},
// 	{
// 		title: "Current Therapy",
// 		accessor: "Current_Therapy",
// 		sort: true,
// 		Cell: (props: any) => {
// 			// if (props.cell.value === "Updating")
// 			//   return (
// 			//     <div className="text-text-gray-80 text-base">
// 			//       {icons.find((l:any) => l.id === "HourGlass")?.icon}
// 			//       Updating
// 			//     </div>
// 			//   );
// 			return <div>{props.cell.value}</div>;
// 		},
// 	},
// 	{
// 		title: "Discon Risk",
// 		accessor: "Discon_Risk",
// 		sort: true,
// 		Cell: (props: any) => {
// 			return <div style={{ textAlign: "center" }}>{props.cell.value}</div>;
// 		},
// 	},
// 	{
// 		title: "Top Unmet Needs",
// 		accessor: "Top_Unmet_Needs",
// 		width: 300,
// 		sort: true,
// 		Cell: (props: any) => {
// 			return <div style={{ textAlign: "center" }}>{props.cell.value}</div>;
// 		},
// 	},
// 	{
// 		title: "Therapy Start Date",
// 		accessor: "Therapy_Start_Date",
// 		// sort: true,
// 	},

// 	{
// 		title: "Time On Therapy",
// 		accessor: "Time_On_Therapy",
// 		sort: true,
// 		Cell: (props: any) => {
// 			return (
// 				<div
// 					style={{
// 						textAlign: "left",
// 					}}>
// 					<div className="flex">
// 						{icons.find((l: any) => l.id === props.cell.value)?.icon}
// 						&nbsp;
// 						{props.cell.value}
// 					</div>
// 				</div>
// 			);
// 		},
// 	},
// 	{
// 		title: "Out of Stock Date",
// 		accessor: "Out_Of_Stock_Date",
// 		// sort: true,
// 	},
// ];

export const emptyDataTableColumns = [
	{
		title: "Member Id",
		accessor: "Member_Id",
		sortType: "alphanumeric",
		width: 180,
		Cell: (props: any) => {
			return <div>{props.cell.value}</div>;
		},
	},
	{
		title: "Line of Business",
		accessor: "Line_of_Business",
		sortType: "alphanumeric",
		width: 180,
		Cell: (props: any) => {
			return <div>{props.cell.value}</div>;
		},
	},
	{
		title: "Current Plan",
		accessor: "Current_Plan",
		sort: true,
		sortType: "basic",

		Cell: (props: any) => {
			return <div>{props.cell.value}</div>;
		},
	},
	{
		title: "Probability of Churn",
		accessor: "Probability_of_Churn",
		sort: true,
		Cell: (props: any) => {
			return (
				<div style={{ display: "flex" }}>
					<div style={{ textAlign: "center", background: "white" }}>
						{icons.find((l) => l.id === (props.cell.value === "High" ? "Minus Circle" : "Check Circle Fill"))?.icon}
					</div>
					<div style={{ textAlign: "center", paddingLeft: "15px" }}>{props.cell.value}</div>
				</div>
			);
		},
	},
	{
		title: "Top Drivers of Churn",
		accessor: "Top_Drivers_of_Churn",
		width: 300,
		sort: true,
		Cell: (props: any) => {
			return <div style={{ textAlign: "left" }}>{props.cell.value}</div>;
		},
	},
	{
		title: "Number of Support Interactions in last 6 months",
		accessor: "Number_of_Support_Interactions_in_last_6_months",
		width: 300,
		sort: true,
		Cell: (props: any) => {
			return <div style={{ textAlign: "left" }}>{props.cell.value}</div>;
		},
	},
	{
		title: "Member Satisfaction Score(10)",
		accessor: "Member_Satisfaction_Score(10)",
		width: 300,
		sort: true,
		Cell: (props: any) => {
			return <div style={{ textAlign: "left" }}>{props.cell.value}</div>;
		},
	},
];

export const dataTableEmptyData = [
	{
		Patient_ID: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Current_Therapy: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Discon_Risk: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Top_Unmet_Needs: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Therapy_Start_Date: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Time_On_Therapy: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Out_Of_Stock_Date: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
	},
	{
		Patient_ID: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Current_Therapy: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Discon_Risk: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Top_Unmet_Needs: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Therapy_Start_Date: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Time_On_Therapy: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Out_Of_Stock_Date: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
	},
	{
		Patient_ID: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Current_Therapy: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Discon_Risk: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Top_Unmet_Needs: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Therapy_Start_Date: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Time_On_Therapy: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Out_Of_Stock_Date: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
	},
];

export const scatterPlotChartData = [
	{
		Time: "36:50",
		Place: 1,
		Seconds: 2210,
		Name: "Marco Pantani",
		Year: 1995,
		Nationality: "ITA",
		Doping: "Alleged drug use during 1995 due to high hematocrit levels",
		URL: "https://en.wikipedia.org/wiki/Marco_Pantani#Alleged_drug_use",
	},
	{
		Time: "36:55",
		Place: 2,
		Seconds: 2215,
		Name: "Marco Pantani",
		Year: 1997,
		Nationality: "ITA",
		Doping: "Alleged drug use during 1997 due to high hermatocrit levels",
		URL: "https://en.wikipedia.org/wiki/Marco_Pantani#Alleged_drug_use",
	},
	{
		Time: "37:15",
		Place: 3,
		Seconds: 2235,
		Name: "Marco Pantani",
		Year: 1994,
		Nationality: "ITA",
		Doping: "Alleged drug use during 1994 due to high hermatocrit levels",
		URL: "https://en.wikipedia.org/wiki/Marco_Pantani#Alleged_drug_use",
	},
	{
		Time: "37:36",
		Place: 4,
		Seconds: 2256,
		Name: "Lance Armstrong",
		Year: 2004,
		Nationality: "USA",
		Doping: "2004 Tour de France title stripped by UCI in 2012",
		URL: "https://en.wikipedia.org/wiki/History_of_Lance_Armstrong_doping_allegations",
	},
	{
		Time: "37:42",
		Place: 5,
		Seconds: 2262,
		Name: "Jan Ullrich",
		Year: 1997,
		Nationality: "GER",
		Doping: "Confessed later in his career to doping",
		URL: "https://en.wikipedia.org/wiki/Jan_Ullrich#Operaci.C3.B3n_Puerto_doping_case",
	},
	{
		Time: "38:05",
		Place: 6,
		Seconds: 2285,
		Name: "Lance Armstrong",
		Year: 2001,
		Nationality: "USA",
		Doping: "2001 Tour de France title stripped by UCI in 2012",
		URL: "https://en.wikipedia.org/wiki/History_of_Lance_Armstrong_doping_allegations",
	},
	{
		Time: "38:14",
		Place: 7,
		Seconds: 2294,
		Name: "Miguel Indurain",
		Year: 1995,
		Nationality: "ESP",
		Doping: "1994 Failed test for salbutemol, not a banned drug at that time",
		URL: "http://www.independent.co.uk/sport/drugs-in-sport-indurain-allowed-to-use-banned-drug-1379584.html",
	},
];

export const patientNeedsDrivers = [
	{
		chartName: ["Member Needs Drivers"],
		yAxisLabel: [],
		xAxisLabel: [""],
		chartData: [
			{
				"Dosing Discussion": {
					value: 90,
				},
				"Sadness for Side Effect": {
					value: 75,
				},
				"Anger on Insurance": {
					value: 70,
				},
				"MBTI: Introversion": {
					value: 60,
				},
				"Curisoity in Dosing": {
					value: 30,
				},
			},
		],
		chartColor: ["#ADD8E6", "#FFFFE0", "#0B654ACC", "#EAF6FF", "#FFF1F1"],
	},
	{
		chartName: ["Member Needs Drivers"],
		yAxisLabel: [],
		xAxisLabel: [""],
		chartData: [
			{
				"Dosing Discussion": {
					value: 90,
				},
				"Sadness for Side Effect": {
					value: 75,
				},
				"Anger on Insurance": {
					value: 70,
				},
				"MBTI: Introversion": {
					value: 60,
				},
				"Curisoity in Dosing": {
					value: 30,
				},
			},
		],
		chartColor: ["#ADD8E6", "#FFFFE0", "#0B654ACC", "#FFFEE8", "#FFF1F1"],
	},
	{
		cardName: "",
		chartName: ["Member Needs Drivers"],
		yAxisLabel: [],
		xAxisLabel: [""],
		topChart: {},
		chartData: [
			{
				"Dosing Discussion": {
					value: 90,
				},
				"Sadness for Side Effect": {
					value: 75,
				},
				"Anger on Insurance": {
					value: 70,
				},
				"MBTI: Introversion": {
					value: 60,
				},
				"Curisoity in Dosing": {
					value: 30,
				},
			},
		],
		chartColor: ["#ADD8E6", "#FFFFE0", "#0B654ACC", "#FFF1F1", "#FFF1F1"],
	},
];

export const driverSummaryTranscript = [
	{
		sideEffectId: 1,
		sideEffectName: "Side Effect",
		numberOfSearchResults: 100,
		searchResults: [
			{
				searchId: 1,
				name: "Transcript from 09 Feb 2022 [12:35:26]",
				// tags: ["Good", "Outbound", "On Therapy", "27", "Negative", "Others"],
				dialogueNo: 11,
				callId: "C83221FF96DD4888BF4D03051BAFA411",
				callDate: "2022-02-09T00:00:00",
				callTime: "12:35:26",
				dialogueAppearedAt: 69.48,
				dialogueLength: 64.83,
				transcriptLength: 837.46,
				patientId: 15078,
				speaker: "Patient",
				context:
					'[{"dialogueNo": 9, "text": "uh-hum well um i\'ve had a little bit of trouble, i haven\'t been taking the two pills in the morning and two in the evening every day because i have been having trouble with headaches and i don\'t know what it\'s caused from um my vision is getting worse i think.", "speaker": "Patient"}, {"dialogueNo": 10, "text": "oh.", "speaker": "Agent"}, {"dialogueNo": 11, "text": "um but my my headaches i\'ve been waking up happened about a week ago, i woke up one morning and i had a headache and i rarely ever have them but i had this headache and the top of my head was so sore, i couldn\'t touch it without it hurting and they it\'s you know i i just didn\'t know what to do with it and anyway um this went on and it\'s been i\'ve been having a trip, the headaches every day since the my head is no longer sore but you know it\'s well if i push on it it\'s kind of sore but i you know i don\'t know i just it really and i was going to call the doctor here a local doctor and then i thought well it\'ll get better but and then i was gone for a day uh overnight and i well i\'ll wait till i get back and it\'s still the headache is still there.", "speaker": "Patient"}, {"dialogueNo": 12, "text": "mhm. mhm. i see. okay so the headaches did start after you started taking the vumerity is that correct ?", "speaker": "Agent"}, {"dialogueNo": 13, "text": "yes yes it is.", "speaker": "Patient"}]',
				elasticSearchId: "C83221FF96DD4888BF4D03051BAFA411_11",
				transcripts: [
					{
						speaker: "Patient",
						text: "uh-hum well um i've had a little bit of trouble, i haven't been taking the two pills in the morning and two in the evening every day because i have been having trouble with headaches and i don't know what it's caused from um my vision is getting worse i think.",
						highLights: ["think"],
						dialogueNo: 9,
					},
					{
						speaker: "Agent",
						text: "oh.",
						dialogueNo: 10,
						highLights: [],
					},
					{
						speaker: "Patient",
						text: "um but my my headaches i've been waking up happened about a week ago, i woke up one morning and i had a headache and i rarely ever have them but i had this headache and the top of my head was so sore, i couldn't touch it without it hurting and they it's you know i i just didn't know what to do with it and anyway um this went on and it's been i've been having a trip, the headaches every day since the my head is no longer sore but you know it's well if i push on it it's kind of sore but i you know i don't know i just it really and i was going to call the doctor here a local doctor and then i thought well it'll get better but and then i was gone for a day uh overnight and i well i'll wait till i get back and it's still the headache is still there.",
						dialogueNo: 11,
						highLights: [],
					},
					{
						speaker: "Agent",
						text: "mhm. mhm. i see. okay so the headaches did start after you started taking the vumerity is that correct ?",
						dialogueNo: 12,
						highLights: ["vumerity"],
					},
				],
				callTypeId: "2",
				storyDialogues: [],
				therapyStatusId: "1",
				notAddedStories: [],
			},
			{
				searchId: 2,
				name: "Transcript from 10 Jan 2023 [12:35:26]",
				// tags: ["Good", "Outbound", "On Therapy", "27", "Negative", "Others"],
				dialogueNo: 11,
				callId: "C83221FF96DD4888BF4D03051BAFA411",
				callDate: "2022-02-09T00:00:00",
				callTime: "12:35:26",
				dialogueAppearedAt: 69.48,
				dialogueLength: 64.83,
				transcriptLength: 837.46,
				patientId: 15078,
				speaker: "Patient",
				context:
					'[{"dialogueNo": 9, "text": "uh-hum well um i\'ve had a little bit of trouble, i haven\'t been taking the two pills in the morning and two in the evening every day because i have been having trouble with headaches and i don\'t know what it\'s caused from um my vision is getting worse i think.", "speaker": "Patient"}, {"dialogueNo": 10, "text": "oh.", "speaker": "Agent"}, {"dialogueNo": 11, "text": "um but my my headaches i\'ve been waking up happened about a week ago, i woke up one morning and i had a headache and i rarely ever have them but i had this headache and the top of my head was so sore, i couldn\'t touch it without it hurting and they it\'s you know i i just didn\'t know what to do with it and anyway um this went on and it\'s been i\'ve been having a trip, the headaches every day since the my head is no longer sore but you know it\'s well if i push on it it\'s kind of sore but i you know i don\'t know i just it really and i was going to call the doctor here a local doctor and then i thought well it\'ll get better but and then i was gone for a day uh overnight and i well i\'ll wait till i get back and it\'s still the headache is still there.", "speaker": "Patient"}, {"dialogueNo": 12, "text": "mhm. mhm. i see. okay so the headaches did start after you started taking the vumerity is that correct ?", "speaker": "Agent"}, {"dialogueNo": 13, "text": "yes yes it is.", "speaker": "Patient"}]',
				elasticSearchId: "C83221FF96DD4888BF4D03051BAFA411_11",
				transcripts: [
					{
						speaker: "Patient",
						text: "uh-hum well um i've had a little bit of trouble, i haven't been taking the two pills in the morning and two in the evening every day because i have been having trouble with headaches and i don't know what it's caused from um my vision is getting worse i think.",
						highLights: ["think"],
						dialogueNo: 9,
					},
					{
						speaker: "Agent",
						text: "oh.",
						dialogueNo: 10,
						highLights: [],
					},
					{
						speaker: "Patient",
						text: "um but my my headaches i've been waking up happened about a week ago, i woke up one morning and i had a headache and i rarely ever have them but i had this headache and the top of my head was so sore, i couldn't touch it without it hurting and they it's you know i i just didn't know what to do with it and anyway um this went on and it's been i've been having a trip, the headaches every day since the my head is no longer sore but you know it's well if i push on it it's kind of sore but i you know i don't know i just it really and i was going to call the doctor here a local doctor and then i thought well it'll get better but and then i was gone for a day uh overnight and i well i'll wait till i get back and it's still the headache is still there.",
						dialogueNo: 11,
						highLights: [],
					},
					{
						speaker: "Agent",
						text: "mhm. mhm. i see. okay so the headaches did start after you started taking the vumerity is that correct ?",
						dialogueNo: 12,
						highLights: ["vumerity"],
					},
				],
				callTypeId: "2",
				storyDialogues: [],
				therapyStatusId: "1",
				notAddedStories: [],
			},
		],
		headers: {
			"x-time":
				'{"query_expansion_time": 0.2521553039550781, "retriever_time": 1.4508168697357178, "ranker_time": 1.2852530479431152, "df_prepare_time": 0.008672475814819336, "process_time": 3.0736827850341797}',
			"x-search-config":
				"{\"query_type\": \"keyword\", \"expanded_query\": \"[('bad headache', 0.9180014), ('slight headache', 0.9074279), ('big headache', 0.90502834), ('little headache', 0.89852965), ('mild headache', 0.8773646), ('real bad headache', 0.87445515), ('very bad headache', 0.86825484), ('really bad headache', 0.86764216), ('weird headache', 0.863446), ('dull headache', 0.85253), ('headache', 1)]\"}",
			"x-auth-time": "0.23431873321533203",
			"x-process-time": "3.311600923538208",
		},
	},
];

export const dummyDataDriversSummary = {
	numberOfPatients: 84,
	numberOfSearchResults: 20,
	searchResults: [
		{
			name: "Transcript from 01 Nov 2021 [15:47:45]",
			tags: ["Inbound", "Discontinued", "42", "Neutral", "Others"],
			storiesAdded: [],
			source: 1,
			dialogueNo: 40,
			callId: "97F9699980E0415EB33D5D7634F648B4",
			callDate: "2021-11-01T00:00:00",
			callTime: "15:47:45",
			dialogueAppearedAt: 466.45,
			dialogueLength: 36.57,
			transcriptLength: 728.5,
			s3path: "kural/processed_files/97F9699980E0415EB33D5D7634F648B4.json",
			addedToStory: false,
			score: 0.310449600219727,
			patientId: 8665,
			speaker: "Agent",
			context:
				'[{"dialogueNo": 38, "text": "you\'ve had migraines your whole life.", "speaker": "Agent"}, {"dialogueNo": 39, "text": "yes.", "speaker": "Patient"}, {"dialogueNo": 40, "text": "so you had these um even before starting on on vumerity okay, since you\'re still having issues with the headaches, i would suggest you give doctor nico\'s office a call um and let him know that even though you are, you know, taking your vumerity you\'re on your um m. s medication, you\'re having headaches still that are not resolved, he might be able to, you know, advise you or give you, you know something to help control the headaches.", "speaker": "Agent"}, {"dialogueNo": 41, "text": "okay.", "speaker": "Patient"}, {"dialogueNo": 42, "text": "now do you feel that you\'re tolerating the vumerity since you\'ve been on vumerity uh huh.", "speaker": "Agent"}]',
			elasticSearchId: "97F9699980E0415EB33D5D7634F648B4_40",
			transcripts: [
				{
					speaker: "Agent",
					text: "you've had migraines your whole life.",
					dialogueNo: 38,
				},
				{
					speaker: "Patient",
					text: "yes.",
					dialogueNo: 39,
				},
				{
					speaker: "Agent",
					text: "so you had these um even before starting on on vumerity okay, since you're still having issues with the headaches, i would suggest you give doctor nico's office a call um and let him know that even though you are, you know, taking your vumerity you're on your um m. s medication, you're having headaches still that are not resolved, he might be able to, you know, advise you or give you, you know something to help control the headaches.",
					dialogueNo: 40,
				},
				{
					speaker: "Patient",
					text: "okay.",
					dialogueNo: 41,
				},
				{
					speaker: "Agent",
					text: "now do you feel that you're tolerating the vumerity since you've been on vumerity uh huh.",
					dialogueNo: 42,
				},
			],
			callTypeId: "1",
			storyDialogues: [],
			therapyStatusId: "2",
			notAddedStories: [],
		},
		{
			name: "Transcript from 05 Nov 2021 [12:21:44]",
			tags: ["Outbound", "Discontinued", "30", "Negative", "Others"],
			storiesAdded: [],
			source: 1,
			dialogueNo: 60,
			callId: "ACEC79FADC0946318DDDADC13129B0EF",
			callDate: "2021-11-05T00:00:00",
			callTime: "12:21:44",
			dialogueAppearedAt: 631.74,
			dialogueLength: 21.1,
			transcriptLength: 899.8,
			s3path: "kural/processed_files/ACEC79FADC0946318DDDADC13129B0EF.json",
			addedToStory: false,
			score: 0.299056351184845,
			patientId: 15127,
			speaker: "Patient",
			context:
				'[{"dialogueNo": 58, "text": "like i said a week ago monday and um i was better in about three days it took you know, they said it would take you know at least 12 hours to start getting it out of my system.", "speaker": "Patient"}, {"dialogueNo": 59, "text": "right i hear you okay alrighty wow mhm oh wow right yeah no i hear you, i hear you that makes sense to me.", "speaker": "Agent"}, {"dialogueNo": 60, "text": "but by the middle of last week i was the shaking was gone, the headache was gone, i had headaches with um a vumerity that i did not have with the tecfidera tecfidera for some reason, but i had headaches every day and they stopped and the shaking stop in both counts. so i don\'t know what else it would be.", "speaker": "Patient"}, {"dialogueNo": 61, "text": "um but so what actually no you did actually you did increase to the maintenance dose correct ?", "speaker": "Agent"}, {"dialogueNo": 62, "text": "it had to be no, i i increased it.", "speaker": "Patient"}]',
			elasticSearchId: "ACEC79FADC0946318DDDADC13129B0EF_60",
			transcripts: [
				{
					speaker: "Patient",
					text: "like i said a week ago monday and um i was better in about three days it took you know, they said it would take you know at least 12 hours to start getting it out of my system.",
					dialogueNo: 58,
				},
				{
					speaker: "Agent",
					text: "right i hear you okay alrighty wow mhm oh wow right yeah no i hear you, i hear you that makes sense to me.",
					dialogueNo: 59,
				},
				{
					speaker: "Patient",
					text: "but by the middle of last week i was the shaking was gone, the headache was gone, i had headaches with um a vumerity that i did not have with the tecfidera tecfidera for some reason, but i had headaches every day and they stopped and the shaking stop in both counts. so i don't know what else it would be.",
					dialogueNo: 60,
				},
				{
					speaker: "Agent",
					text: "um but so what actually no you did actually you did increase to the maintenance dose correct ?",
					dialogueNo: 61,
				},
				{
					speaker: "Patient",
					text: "it had to be no, i i increased it.",
					dialogueNo: 62,
				},
			],
			callTypeId: "2",
			storyDialogues: [],
			therapyStatusId: "2",
			notAddedStories: [],
		},
		{
			searchId: 1,
			name: "Transcript from 25 May 2022 [20:15:09]",
			tags: ["Positive", "Outbound", "UNKNOWN"],
			dialogueNo: 4,
			callId: "O1388T9ZZ5BCLGTD",
			callDate: "2022-05-25",
			callTime: "20:15:09",
			dialogueAppearedAt: 16.1,
			dialogueLength: 15.18,
			transcriptLength: 75.44,
			patientId: -1,
			speaker: "Patient",
			storiesAdded: [],
			addedToStory: "false",
			context:
				'[{"dialogueNo": 2, "text": "i\'m just so frustrated. my insurance company denied my claim for lectrazine.", "speaker": "Patient"}, {"dialogueNo": 3, "text": "i\'m sorry to hear that. can you tell me what happened ?", "speaker": "Agent"}, {"dialogueNo": 4, "text": "i\'ve been taking lectrazine for my migraines for years, and my insurance has always covered it. but now they\'re saying it\'s not medically necessary and they won\'t pay for it.", "speaker": "Patient"}, {"dialogueNo": 5, "text": "that doesn\'t sound right. let me see if i can help you figure this out.", "speaker": "Agent"}, {"dialogueNo": 6, "text": "i just don\'t understand why they would deny it. it\'s not like it\'s a luxury medication or anything.", "speaker": "Patient"}]',
			s3path: "kural/processed_files/O1388T9ZZ5BCLGTD.json",
			elasticSearchId: "O1388T9ZZ5BCLGTD_4",
			transcripts: [
				{
					speaker: "Patient",
					text: "i'm just so frustrated. my insurance company denied my claim for lectrazine.",
					highLights: ["lectrazine"],
					dialogueNo: 2,
				},
				{
					speaker: "Agent",
					text: "i'm sorry to hear that. can you tell me what happened ?",
					highLights: [],
					dialogueNo: 3,
				},
				{
					speaker: "Patient",
					text: "i've been taking lectrazine for my migraines for years, and my insurance has always covered it. but now they're saying it's not medically necessary and they won't pay for it.",
					highLights: ["lectrazine"],
					dialogueNo: 4,
				},
				{
					speaker: "Agent",
					text: "that doesn't sound right. let me see if i can help you figure this out.",
					highLights: [],
					dialogueNo: 5,
				},
				{
					speaker: "Patient",
					text: "i just don't understand why they would deny it. it's not like it's a luxury medication or anything.",
					highLights: [],
					dialogueNo: 6,
				},
			],
			callTypeId: 2,
			therapyStatusId: -1,
		},
		{
			searchId: 2,
			name: "Transcript from 09 May 2022 [21:20:36]",
			tags: ["Negative", "Common Side Effects", "Outbound", "UNKNOWN"],
			dialogueNo: 3,
			callId: "WW8PUBYMYJOW4S8N",
			callDate: "2022-05-09",
			callTime: "21:20:36",
			dialogueAppearedAt: 39.56,
			dialogueLength: 11.5,
			transcriptLength: 118.68,
			patientId: -1,
			speaker: "Patient",
			storiesAdded: [],
			addedToStory: "false",
			context:
				'[{"dialogueNo": 1, "text": "i\'m really worried about the side effects of lectrazine. i\'ve been hearing a lot of bad things about it.", "speaker": "Patient"}, {"dialogueNo": 2, "text": "lectrazine is a very effective medication for treating allergies, but like all medications, it can cause some side effects. the most common side effects include headache, dizziness, and nausea. these side effects are usually mild and go away on their own. however, if you experience any of these side effects, please let your doctor know.", "speaker": "Agent"}, {"dialogueNo": 3, "text": "i\'ve been having a lot of headaches and dizziness since i started taking lectrazine. it\'s really starting to affect my quality of life.", "speaker": "Patient"}, {"dialogueNo": 4, "text": "i\'m sorry to hear that. headaches and dizziness are common side effects of lectrazine, but they usually go away on their own. if you\'re still experiencing these side effects after a few days, please let your doctor know. they may be able to adjust your dose or switch you to a different medication.", "speaker": "Agent"}, {"dialogueNo": 5, "text": "i\'m really getting worried about these side effects. is there anything i can do to reduce them ?", "speaker": "Patient"}]',
			s3path: "kural/processed_files/WW8PUBYMYJOW4S8N.json",
			elasticSearchId: "WW8PUBYMYJOW4S8N_3",
			transcripts: [
				{
					speaker: "Patient",
					text: "i'm really worried about the side effects of lectrazine. i've been hearing a lot of bad things about it.",
					highLights: ["lectrazine"],
					dialogueNo: 1,
				},
				{
					speaker: "Agent",
					text: "lectrazine is a very effective medication for treating allergies, but like all medications, it can cause some side effects. the most common side effects include headache, dizziness, and nausea. these side effects are usually mild and go away on their own. however, if you experience any of these side effects, please let your doctor know.",
					highLights: ["lectrazine"],
					dialogueNo: 2,
				},
				{
					speaker: "Patient",
					text: "i've been having a lot of headaches and dizziness since i started taking lectrazine. it's really starting to affect my quality of life.",
					highLights: ["lectrazine"],
					dialogueNo: 3,
				},
				{
					speaker: "Agent",
					text: "i'm sorry to hear that. headaches and dizziness are common side effects of lectrazine, but they usually go away on their own. if you're still experiencing these side effects after a few days, please let your doctor know. they may be able to adjust your dose or switch you to a different medication.",
					highLights: ["lectrazine"],
					dialogueNo: 4,
				},
				{
					speaker: "Patient",
					text: "i'm really getting worried about these side effects. is there anything i can do to reduce them ?",
					highLights: [],
					dialogueNo: 5,
				},
			],
			callTypeId: 2,
			therapyStatusId: -1,
		},
		{
			searchId: 3,
			name: "Transcript from 25 May 2022 [15:30:20]",
			tags: ["Negative", "Outbound", "UNKNOWN"],
			dialogueNo: 2,
			callId: "F1T9KO8WBYD609GF",
			callDate: "2022-05-25",
			callTime: "15:30:20",
			dialogueAppearedAt: 3.68,
			dialogueLength: 6.44,
			transcriptLength: 119.14,
			patientId: -1,
			speaker: "Patient",
			storiesAdded: [],
			addedToStory: "false",
			context:
				'[{"dialogueNo": 1, "text": "hi, how can i help you ?", "speaker": "Agent"}, {"dialogueNo": 2, "text": "i\'m just so frustrated. my insurance company denied my claim for lectrazine.", "speaker": "Patient"}, {"dialogueNo": 3, "text": "i\'m sorry to hear that. can you tell me what happened ?", "speaker": "Agent"}, {"dialogueNo": 4, "text": "they said that it\'s not a covered medication. but i\'ve been taking it for years and it\'s the only thing that helps me.", "speaker": "Patient"}]',
			s3path: "kural/processed_files/F1T9KO8WBYD609GF.json",
			elasticSearchId: "F1T9KO8WBYD609GF_2",
			transcripts: [
				{
					speaker: "Agent",
					text: "hi, how can i help you ?",
					highLights: [],
					dialogueNo: 1,
				},
				{
					speaker: "Patient",
					text: "i'm just so frustrated. my insurance company denied my claim for lectrazine.",
					highLights: ["lectrazine"],
					dialogueNo: 2,
				},
				{
					speaker: "Agent",
					text: "i'm sorry to hear that. can you tell me what happened ?",
					highLights: [],
					dialogueNo: 3,
				},
				{
					speaker: "Patient",
					text: "they said that it's not a covered medication. but i've been taking it for years and it's the only thing that helps me.",
					highLights: [],
					dialogueNo: 4,
				},
			],
			callTypeId: 2,
			therapyStatusId: -1,
		},
	],
	statesResults: [
		{
			id: "TX",
			value: 12,
		},
		{
			id: "MI",
			value: 9,
		},
		{
			id: "VA",
			value: 8,
		},
		{
			id: "OH",
			value: 6,
		},
		{
			id: "NY",
			value: 6,
		},
		{
			id: "CA",
			value: 6,
		},
		{
			id: "NJ",
			value: 4,
		},
		{
			id: "MN",
			value: 4,
		},
		{
			id: "AZ",
			value: 3,
		},
		{
			id: "PA",
			value: 3,
		},
		{
			id: "MO",
			value: 3,
		},
		{
			id: "NC",
			value: 3,
		},
		{
			id: "ID",
			value: 3,
		},
		{
			id: "FL",
			value: 3,
		},
		{
			id: "IL",
			value: 2,
		},
		{
			id: "ME",
			value: 2,
		},
		{
			id: "GA",
			value: 2,
		},
		{
			id: "MA",
			value: 2,
		},
		{
			id: "WI",
			value: 2,
		},
		{
			id: "OK",
			value: 2,
		},
		{
			id: "NM",
			value: 1,
		},
		{
			id: "UT",
			value: 1,
		},
		{
			id: "WV",
			value: 1,
		},
		{
			id: "ND",
			value: 1,
		},
		{
			id: "SC",
			value: 1,
		},
		{
			id: "AR",
			value: 1,
		},
		{
			id: "NE",
			value: 1,
		},
		{
			id: "LA",
			value: 1,
		},
		{
			id: "KY",
			value: 1,
		},
		{
			id: "IN",
			value: 1,
		},
		{
			id: "WA",
			value: 1,
		},
		{
			id: "CO",
			value: 1,
		},
		{
			id: "MS",
			value: 1,
		},
		{
			id: "OR",
			value: 1,
		},
		{
			id: "AL",
			value: 1,
		},
	],
	emotionsCounts: [
		{
			text: "information",
			value: 19,
		},
		{
			text: "questions",
			value: 16,
		},
	],
	headers: {
		"x-time":
			'{"query_expansion_time": 0.24471282958984375, "retriever_time": 0.612053394317627, "ranker_time": 0.7366836071014404, "df_prepare_time": 0.008089542388916016, "process_time": 1.6488804817199707}',
		"x-search-config":
			"{\"query_type\": \"keyword\", \"expanded_query\": \"[('bad headache', 0.9180014), ('slight headache', 0.9074279), ('big headache', 0.90502834), ('little headache', 0.89852965), ('mild headache', 0.8773646), ('real bad headache', 0.87445515), ('very bad headache', 0.86825484), ('really bad headache', 0.86764216), ('weird headache', 0.863446), ('dull headache', 0.85253), ('headache', 1)]\"}",
		"x-auth-time": "0.19579458236694336",
		"x-process-time": "1.8481688499450684",
	},
};

export const hppDriversSummaryData = [
	{
		dummyDataTable: [
			{
				Patient_ID: "ZS1000",
				Current_Therapy: "ZSabcd",
				Discon_Risk: "Low",
				Top_Unmet_Needs: "Fear of side effects",
				Therapy_Start_Date: "01 Dec 2022",
				Time_On_Therapy: "223",
				Out_Of_Stock_Date: "01 Dec 2022",
			},
			{
				Patient_ID: "ZS1001",
				Current_Therapy: "ZSabcd",
				Discon_Risk: "High",
				Top_Unmet_Needs: "Confusion on Lab Monitoring",
				Therapy_Start_Date: "01 Dec 2022",
				Time_On_Therapy: "223",
				Out_Of_Stock_Date: "01 Dec 2022",
			},
			{
				Patient_ID: "ZS1002",
				Current_Therapy: "ZSabcd",
				Discon_Risk: "Low",
				Top_Unmet_Needs: "Frustration around delayed shipments",
				Therapy_Start_Date: "01 Dec 2022",
				Time_On_Therapy: "223",
				Out_Of_Stock_Date: "01 Dec 2022",
			},
			{
				Patient_ID: "ZS1003",
				Current_Therapy: "ZSabcd",
				Discon_Risk: "High",
				Top_Unmet_Needs: "Confusion on Dosing",
				Therapy_Start_Date: "01 Dec 2022",
				Time_On_Therapy: "223",
				Out_Of_Stock_Date: "01 Dec 2022",
			},
			{
				Patient_ID: "ZS1004",
				Current_Therapy: "ZSabcd",
				Discon_Risk: "Low",
				Top_Unmet_Needs: "Confusion on beverage consumption",
				Therapy_Start_Date: "01 Dec 2022",
				Time_On_Therapy: "223",
				Out_Of_Stock_Date: "01 Dec 2022",
			},
			{
				Patient_ID: "ZS1005",
				Current_Therapy: "ZSabcd",
				Discon_Risk: "High",
				Top_Unmet_Needs: "Lab Monitoring Guidance (average patient mentions)",
				Therapy_Start_Date: "01 Dec 2022",
				Time_On_Therapy: "223",
				Out_Of_Stock_Date: "01 Dec 2022",
			},
			{
				Patient_ID: "ZS1006",
				Current_Therapy: "ZSabcd",
				Discon_Risk: "High",
				Top_Unmet_Needs: "Common Side Effects (average negativity)",
				Therapy_Start_Date: "01 Dec 2022",
				Time_On_Therapy: "223",
				Out_Of_Stock_Date: "01 Dec 2022",
			},
			{
				Patient_ID: "ZS1007",
				Current_Therapy: "ZSabcd",
				Discon_Risk: "High",
				Top_Unmet_Needs: "Shipment (average patient mentions)",
				Therapy_Start_Date: "01 Dec 2022",
				Time_On_Therapy: "223",
				Out_Of_Stock_Date: "01 Dec 2022",
			},
			{
				Patient_ID: "ZS1008",
				Current_Therapy: "ZSabcd",
				Discon_Risk: "High",
				Top_Unmet_Needs: "Dosing Information (average negativity) ",
				Therapy_Start_Date: "01 Dec 2022",
				Time_On_Therapy: "223",
				Out_Of_Stock_Date: "01 Dec 2022",
			},
			{
				Patient_ID: "ZS1009",
				Current_Therapy: "ZSabcd",
				Discon_Risk: "High",
				Top_Unmet_Needs: "Insurance (average negativity)",
				Therapy_Start_Date: "01 Dec 2022",
				Time_On_Therapy: "223",
				Out_Of_Stock_Date: "01 Dec 2022",
			},
			{
				Patient_ID: "ZS1010",
				Current_Therapy: "ZSabcd",
				Discon_Risk: "High",
				Top_Unmet_Needs: "Diet (average curiousity)",
				Therapy_Start_Date: "01 Dec 2022",
				Time_On_Therapy: "223",
				Out_Of_Stock_Date: "01 Dec 2022",
			},
			{
				Patient_ID: "ZS1011",
				Current_Therapy: "ZSabcd",
				Discon_Risk: "High",
				Top_Unmet_Needs: "Skin Rashes, Itching & Flushing (average negativity)",
				Therapy_Start_Date: "01 Dec 2022",
				Time_On_Therapy: "223",
				Out_Of_Stock_Date: "01 Dec 2022",
			},
		],
	},
	{
		tempR: {
			chartName: "Member Needs Drivers",
			chartData: [
				{
					key: "High Premium Cost",
					value: 364.57811405326004,
					type: "High",
				},
				{
					key: "Attractive Options elsewhere",
					value: 204.19817019407003,
					type: "High",
				},
				{
					key: "Total time on Plan",
					value: 171.7650517077,
					type: "High",
				},
				{
					key: "Limited in-network Access to Providers",
					value: 158.43518328996,
					type: "High",
				},
				{
					key: "Change in Member Health Risk Score",
					value: 137.19540902399598,
					type: "High",
				},
				{
					key: "Slow Claims Processing",
					value: 122.44458255294099,
					type: "High",
				},
				{
					key: "Negative Experience with Care",
					value: 87.01033270702,
					type: "High",
				},
				{
					key: "Billing Errors",
					value: 73.338391733086,
					type: "High",
				},
				{
					key: "Number of claim requests in last 3 months",
					value: 69.39371380165,
					type: "High",
				},
				{
					key: "Frustration with Customer Service",
					value: 55.57508222680299,
					type: "Medium",
				},
				{
					key: "Avg. Cost of claims",
					value: 43.271894750528,
					type: "Medium",
				},
				{
					key: "Confusion on Plan Changes",
					value: 31.558762773822004,
					type: "Medium",
				},
				{
					key: "Confusion on Coverage Benefits",
					value: 28.814867530031098,
					type: "Medium",
				},
				{
					key: "Dis-satisfaction with Provider Quality",
					value: 26.4121278072107,
					type: "Medium",
				},
				{
					key: "SDoH Factors",
					value: 22.273641158220002,
					type: "Medium",
				},
				{
					key: "Difficulty Navigating Online Resources",
					value: 20.624595550313998,
					type: "Medium",
				},
				{
					key: "Age",
					value: 16.058697480221998,
					type: "Medium",
				},
				{
					key: "External Influences",
					value: 12.73123811077,
					type: "Medium",
				},
				{
					key: "Out of Pocket Cost Concerns",
					value: 11.2153146658856,
					type: "Low",
				},
				{
					key: "Difficulty understanding Policy Exclusions",
					value: 10.04242881727,
					type: "Low",
				},
				{
					key: "Confusion around Policy Language",
					value: 9.984101462856,
					type: "Low",
				},
				{
					key: "Dissatisfaction with Coverage",
					value: 9.6193952284,
					type: "Low",
				},
				{
					key: "Limited access to care",
					value: 8.24405389246,
					type: "Low",
				},
				{
					key: "Life Changes affecting Coverage",
					value: 8.215643382306999,
					type: "Low",
				},
			],
		},
	},
	{
		scatterPlotData: [
			{
				data: {
					title: "Dissatisfaction with Coverage",
					chartData: [
						{
							conversationId: 13,
							disconImpact: -0.027775403,
							featureValue: 0,
						},
						{
							conversationId: 30,
							disconImpact: -0.02425361,
							featureValue: 0,
						},
						{
							conversationId: 39,
							disconImpact: -0.02856739,
							featureValue: 0,
						},
						{
							conversationId: 67,
							disconImpact: -0.030477125,
							featureValue: 0,
						},
						{
							conversationId: 68,
							disconImpact: -0.033889122,
							featureValue: 0,
						},
						{
							conversationId: 82,
							disconImpact: 0.9360037,
							featureValue: 0.0831934184016119,
						},
						{
							conversationId: 93,
							disconImpact: -0.021882704,
							featureValue: 0,
						},
						{
							conversationId: 98,
							disconImpact: -0.038438007,
							featureValue: 0,
						},
						{
							conversationId: 100,
							disconImpact: -0.023524744,
							featureValue: 0,
						},
						{
							conversationId: 104,
							disconImpact: -0.022629881,
							featureValue: 0,
						},
						{
							conversationId: 122,
							disconImpact: -0.02083515,
							featureValue: 0,
						},
						{
							conversationId: 126,
							disconImpact: -0.028815776,
							featureValue: 0,
						},
						{
							conversationId: 129,
							disconImpact: -0.02712391,
							featureValue: 0,
						},
						{
							conversationId: 134,
							disconImpact: -0.02504692,
							featureValue: 0,
						},
						{
							conversationId: 139,
							disconImpact: 1.1809416,
							featureValue: 0.0442748516062665,
						},
						{
							conversationId: 175,
							disconImpact: -0.026367076,
							featureValue: 0,
						},
						{
							conversationId: 183,
							disconImpact: -0.040375452,
							featureValue: 0,
						},
						{
							conversationId: 195,
							disconImpact: -0.019831987,
							featureValue: 0,
						},
						{
							conversationId: 196,
							disconImpact: 0.6311537,
							featureValue: 0.0366982567411509,
						},
						{
							conversationId: 209,
							disconImpact: -0.013682633,
							featureValue: 0,
						},
						{
							conversationId: 213,
							disconImpact: -0.034066692,
							featureValue: 0,
						},
						{
							conversationId: 220,
							disconImpact: -0.02853166,
							featureValue: 0,
						},
						{
							conversationId: 231,
							disconImpact: -0.022045787,
							featureValue: 0,
						},
						{
							conversationId: 234,
							disconImpact: -0.023572704,
							featureValue: 0,
						},
						{
							conversationId: 239,
							disconImpact: -0.026505966,
							featureValue: 0,
						},
						{
							conversationId: 244,
							disconImpact: -0.021586493,
							featureValue: 0,
						},
						{
							conversationId: 245,
							disconImpact: -0.033923265,
							featureValue: 0,
						},
						{
							conversationId: 246,
							disconImpact: -0.022132417,
							featureValue: 0,
						},
						{
							conversationId: 264,
							disconImpact: -0.030356491,
							featureValue: 0,
						},
						{
							conversationId: 267,
							disconImpact: -0.040052388,
							featureValue: 0,
						},
						{
							conversationId: 269,
							disconImpact: -0.0317629,
							featureValue: 0,
						},
						{
							conversationId: 274,
							disconImpact: -0.02383481,
							featureValue: 0,
						},
						{
							conversationId: 279,
							disconImpact: -0.022723839,
							featureValue: 0,
						},
						{
							conversationId: 282,
							disconImpact: -0.029982232,
							featureValue: 0,
						},
						{
							conversationId: 293,
							disconImpact: -0.02146837,
							featureValue: 0,
						},
						{
							conversationId: 297,
							disconImpact: -0.029724542,
							featureValue: 0,
						},
						{
							conversationId: 308,
							disconImpact: -0.0352016,
							featureValue: 0,
						},
						{
							conversationId: 311,
							disconImpact: -0.033396307,
							featureValue: 0,
						},
						{
							conversationId: 342,
							disconImpact: -0.028173916,
							featureValue: 0,
						},
						{
							conversationId: 345,
							disconImpact: -0.020714741,
							featureValue: 0,
						},
						{
							conversationId: 353,
							disconImpact: -0.014468236,
							featureValue: 0,
						},
						{
							conversationId: 356,
							disconImpact: -0.033603434,
							featureValue: 0,
						},
						{
							conversationId: 357,
							disconImpact: -0.026415449,
							featureValue: 0,
						},
						{
							conversationId: 368,
							disconImpact: -0.02247689,
							featureValue: 0,
						},
						{
							conversationId: 373,
							disconImpact: -0.027347645,
							featureValue: 0,
						},
						{
							conversationId: 377,
							disconImpact: -0.024870923,
							featureValue: 0,
						},
						{
							conversationId: 385,
							disconImpact: -0.030235713,
							featureValue: 0,
						},
						{
							conversationId: 386,
							disconImpact: -0.020243984,
							featureValue: 0,
						},
						{
							conversationId: 401,
							disconImpact: -0.033061523,
							featureValue: 0,
						},
						{
							conversationId: 411,
							disconImpact: -0.028886048,
							featureValue: 0,
						},
						{
							conversationId: 418,
							disconImpact: -0.020768289,
							featureValue: 0,
						},
						{
							conversationId: 424,
							disconImpact: -0.028959677,
							featureValue: 0,
						},
						{
							conversationId: 426,
							disconImpact: -0.0326879,
							featureValue: 0,
						},
						{
							conversationId: 428,
							disconImpact: -0.031175878,
							featureValue: 0,
						},
						{
							conversationId: 430,
							disconImpact: -0.031833064,
							featureValue: 0,
						},
						{
							conversationId: 434,
							disconImpact: -0.025744608,
							featureValue: 0,
						},
						{
							conversationId: 435,
							disconImpact: -0.0198435,
							featureValue: 0,
						},
						{
							conversationId: 467,
							disconImpact: -0.030102018,
							featureValue: 0,
						},
						{
							conversationId: 472,
							disconImpact: -0.02604799,
							featureValue: 0,
						},
						{
							conversationId: 476,
							disconImpact: -0.020225553,
							featureValue: 0,
						},
						{
							conversationId: 504,
							disconImpact: -0.033038978,
							featureValue: 0,
						},
						{
							conversationId: 525,
							disconImpact: -0.026111938,
							featureValue: 0,
						},
						{
							conversationId: 547,
							disconImpact: -0.024809036,
							featureValue: 0,
						},
						{
							conversationId: 549,
							disconImpact: -0.019202702,
							featureValue: 0,
						},
						{
							conversationId: 566,
							disconImpact: -0.027640209,
							featureValue: 0,
						},
						{
							conversationId: 574,
							disconImpact: -0.03357735,
							featureValue: 0,
						},
						{
							conversationId: 577,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 582,
							disconImpact: -0.02822827,
							featureValue: 0,
						},
						{
							conversationId: 604,
							disconImpact: -0.023651503,
							featureValue: 0,
						},
						{
							conversationId: 605,
							disconImpact: -0.020575147,
							featureValue: 0,
						},
						{
							conversationId: 611,
							disconImpact: -0.03160505,
							featureValue: 0,
						},
						{
							conversationId: 616,
							disconImpact: -0.03629868,
							featureValue: 0,
						},
						{
							conversationId: 622,
							disconImpact: -0.03675177,
							featureValue: 0,
						},
						{
							conversationId: 623,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 624,
							disconImpact: -0.039378017,
							featureValue: 0,
						},
						{
							conversationId: 626,
							disconImpact: -0.028959677,
							featureValue: 0,
						},
						{
							conversationId: 630,
							disconImpact: -0.024347205,
							featureValue: 0,
						},
						{
							conversationId: 661,
							disconImpact: -0.028185237,
							featureValue: 0,
						},
						{
							conversationId: 684,
							disconImpact: -0.019462701,
							featureValue: 0,
						},
						{
							conversationId: 685,
							disconImpact: -0.027058318,
							featureValue: 0,
						},
						{
							conversationId: 692,
							disconImpact: -0.029258562,
							featureValue: 0,
						},
						{
							conversationId: 712,
							disconImpact: -0.023145406,
							featureValue: 0,
						},
						{
							conversationId: 719,
							disconImpact: -0.03452905,
							featureValue: 0,
						},
						{
							conversationId: 722,
							disconImpact: -0.03357735,
							featureValue: 0,
						},
						{
							conversationId: 723,
							disconImpact: -0.021332022,
							featureValue: 0,
						},
						{
							conversationId: 746,
							disconImpact: -0.02457051,
							featureValue: 0,
						},
						{
							conversationId: 758,
							disconImpact: -0.03229168,
							featureValue: 0,
						},
						{
							conversationId: 759,
							disconImpact: -0.02992243,
							featureValue: 0,
						},
						{
							conversationId: 763,
							disconImpact: -0.032937802,
							featureValue: 0,
						},
						{
							conversationId: 790,
							disconImpact: -0.019333996,
							featureValue: 0,
						},
						{
							conversationId: 799,
							disconImpact: -0.017976677,
							featureValue: 0,
						},
						{
							conversationId: 811,
							disconImpact: -0.024514,
							featureValue: 0,
						},
						{
							conversationId: 814,
							disconImpact: -0.028959677,
							featureValue: 0,
						},
						{
							conversationId: 815,
							disconImpact: -0.034310315,
							featureValue: 0,
						},
						{
							conversationId: 819,
							disconImpact: -0.028959677,
							featureValue: 0,
						},
						{
							conversationId: 838,
							disconImpact: -0.022536505,
							featureValue: 0,
						},
						{
							conversationId: 843,
							disconImpact: -0.03294293,
							featureValue: 0,
						},
						{
							conversationId: 876,
							disconImpact: -0.020184157,
							featureValue: 0,
						},
						{
							conversationId: 878,
							disconImpact: -0.032069888,
							featureValue: 0,
						},
						{
							conversationId: 900,
							disconImpact: -0.032028772,
							featureValue: 0,
						},
						{
							conversationId: 901,
							disconImpact: 0.9290142,
							featureValue: 0.0532126297195849,
						},
						{
							conversationId: 907,
							disconImpact: -0.029762106,
							featureValue: 0,
						},
						{
							conversationId: 919,
							disconImpact: -0.010330228,
							featureValue: 0,
						},
						{
							conversationId: 927,
							disconImpact: -0.028959677,
							featureValue: 0,
						},
						{
							conversationId: 928,
							disconImpact: -0.02289304,
							featureValue: 0,
						},
						{
							conversationId: 933,
							disconImpact: -0.020572193,
							featureValue: 0,
						},
						{
							conversationId: 934,
							disconImpact: -0.043256927,
							featureValue: 0,
						},
						{
							conversationId: 948,
							disconImpact: -0.03342135,
							featureValue: 0,
						},
						{
							conversationId: 957,
							disconImpact: -0.030045597,
							featureValue: 0,
						},
						{
							conversationId: 962,
							disconImpact: -0.030208763,
							featureValue: 0,
						},
						{
							conversationId: 968,
							disconImpact: -0.033502903,
							featureValue: 0,
						},
						{
							conversationId: 973,
							disconImpact: -0.026068825,
							featureValue: 0,
						},
						{
							conversationId: 995,
							disconImpact: -0.024475437,
							featureValue: 0,
						},
						{
							conversationId: 1012,
							disconImpact: -0.01783601,
							featureValue: 0,
						},
						{
							conversationId: 1023,
							disconImpact: 1.3449113,
							featureValue: 0.0563667314008966,
						},
						{
							conversationId: 1030,
							disconImpact: -0.02041585,
							featureValue: 0,
						},
						{
							conversationId: 1034,
							disconImpact: -0.03149064,
							featureValue: 0,
						},
						{
							conversationId: 1046,
							disconImpact: -0.027240772,
							featureValue: 0,
						},
						{
							conversationId: 1060,
							disconImpact: -0.031734087,
							featureValue: 0,
						},
						{
							conversationId: 1075,
							disconImpact: -0.040537283,
							featureValue: 0,
						},
						{
							conversationId: 1077,
							disconImpact: -0.035476275,
							featureValue: 0,
						},
						{
							conversationId: 1082,
							disconImpact: -0.03127672,
							featureValue: 0,
						},
						{
							conversationId: 1129,
							disconImpact: -0.024392027,
							featureValue: 0,
						},
						{
							conversationId: 1132,
							disconImpact: -0.027785081,
							featureValue: 0,
						},
						{
							conversationId: 1134,
							disconImpact: -0.030194454,
							featureValue: 0,
						},
						{
							conversationId: 1136,
							disconImpact: -0.025911428,
							featureValue: 0,
						},
						{
							conversationId: 1138,
							disconImpact: -0.037463527,
							featureValue: 0,
						},
						{
							conversationId: 1144,
							disconImpact: -0.032703876,
							featureValue: 0,
						},
						{
							conversationId: 1145,
							disconImpact: 0.8944154,
							featureValue: 0.0430883414081022,
						},
						{
							conversationId: 1146,
							disconImpact: -0.019148706,
							featureValue: 0,
						},
						{
							conversationId: 1155,
							disconImpact: -0.029663073,
							featureValue: 0,
						},
						{
							conversationId: 1159,
							disconImpact: -0.02147585,
							featureValue: 0,
						},
						{
							conversationId: 1186,
							disconImpact: -0.023238478,
							featureValue: 0,
						},
						{
							conversationId: 1196,
							disconImpact: -0.029550467,
							featureValue: 0,
						},
						{
							conversationId: 1198,
							disconImpact: -0.030001462,
							featureValue: 0,
						},
						{
							conversationId: 1201,
							disconImpact: -0.033403914,
							featureValue: 0,
						},
						{
							conversationId: 1211,
							disconImpact: -0.03012185,
							featureValue: 0,
						},
						{
							conversationId: 1215,
							disconImpact: -0.022257121,
							featureValue: 0,
						},
						{
							conversationId: 1245,
							disconImpact: -0.031658605,
							featureValue: 0,
						},
						{
							conversationId: 1247,
							disconImpact: -0.02662639,
							featureValue: 0,
						},
						{
							conversationId: 1253,
							disconImpact: -0.030008495,
							featureValue: 0,
						},
						{
							conversationId: 1256,
							disconImpact: -0.01949233,
							featureValue: 0,
						},
						{
							conversationId: 1274,
							disconImpact: -0.024161018,
							featureValue: 0,
						},
						{
							conversationId: 1300,
							disconImpact: -0.02071198,
							featureValue: 0,
						},
						{
							conversationId: 1303,
							disconImpact: -0.025773488,
							featureValue: 0,
						},
						{
							conversationId: 1319,
							disconImpact: 0.38338995,
							featureValue: 0.0344906611664947,
						},
						{
							conversationId: 1329,
							disconImpact: -0.017189106,
							featureValue: 0,
						},
						{
							conversationId: 1339,
							disconImpact: -0.021264056,
							featureValue: 0,
						},
						{
							conversationId: 1346,
							disconImpact: -0.03209117,
							featureValue: 0,
						},
						{
							conversationId: 1361,
							disconImpact: -0.026682612,
							featureValue: 0,
						},
						{
							conversationId: 1370,
							disconImpact: -0.029713973,
							featureValue: 0,
						},
						{
							conversationId: 1402,
							disconImpact: 1.0880003,
							featureValue: 0.0535744275877511,
						},
						{
							conversationId: 1411,
							disconImpact: 0.50596845,
							featureValue: 0.0440101787811562,
						},
						{
							conversationId: 1414,
							disconImpact: 1.2361737,
							featureValue: 0.1156196528966023,
						},
						{
							conversationId: 1418,
							disconImpact: -0.03148736,
							featureValue: 0,
						},
						{
							conversationId: 1421,
							disconImpact: 1.1125311,
							featureValue: 0.0915338226356879,
						},
						{
							conversationId: 1425,
							disconImpact: -0.03224463,
							featureValue: 0,
						},
						{
							conversationId: 1426,
							disconImpact: -0.03291525,
							featureValue: 0,
						},
						{
							conversationId: 1428,
							disconImpact: -0.029178865,
							featureValue: 0,
						},
						{
							conversationId: 1436,
							disconImpact: -0.0313395,
							featureValue: 0,
						},
						{
							conversationId: 1444,
							disconImpact: -0.026879624,
							featureValue: 0,
						},
						{
							conversationId: 1504,
							disconImpact: -0.026710615,
							featureValue: 0,
						},
						{
							conversationId: 1510,
							disconImpact: -0.028959677,
							featureValue: 0,
						},
						{
							conversationId: 1544,
							disconImpact: -0.02238812,
							featureValue: 0,
						},
						{
							conversationId: 1550,
							disconImpact: -0.026726898,
							featureValue: 0,
						},
						{
							conversationId: 1568,
							disconImpact: -0.025489453,
							featureValue: 0.0059150498164655,
						},
						{
							conversationId: 1573,
							disconImpact: -0.016021118,
							featureValue: 0,
						},
						{
							conversationId: 1585,
							disconImpact: -0.022228917,
							featureValue: 0,
						},
						{
							conversationId: 1592,
							disconImpact: -0.02516102,
							featureValue: 0,
						},
						{
							conversationId: 1594,
							disconImpact: -0.041884486,
							featureValue: 0,
						},
						{
							conversationId: 1599,
							disconImpact: -0.02356704,
							featureValue: 0,
						},
						{
							conversationId: 1602,
							disconImpact: -0.0159518,
							featureValue: 0,
						},
						{
							conversationId: 1607,
							disconImpact: -0.027266461,
							featureValue: 0,
						},
						{
							conversationId: 1616,
							disconImpact: -0.03279859,
							featureValue: 0,
						},
						{
							conversationId: 1620,
							disconImpact: -0.027461994,
							featureValue: 0,
						},
						{
							conversationId: 1626,
							disconImpact: -0.02565036,
							featureValue: 0,
						},
						{
							conversationId: 1628,
							disconImpact: 0.16661116,
							featureValue: 0.0164940214069674,
						},
						{
							conversationId: 1635,
							disconImpact: -0.023073336,
							featureValue: 0,
						},
						{
							conversationId: 1641,
							disconImpact: -0.024865407,
							featureValue: 0,
						},
						{
							conversationId: 1658,
							disconImpact: -0.024428148,
							featureValue: 0,
						},
						{
							conversationId: 1664,
							disconImpact: -0.032750405,
							featureValue: 0,
						},
						{
							conversationId: 1684,
							disconImpact: -0.03301794,
							featureValue: 0,
						},
						{
							conversationId: 1685,
							disconImpact: -0.021408048,
							featureValue: 0,
						},
						{
							conversationId: 1695,
							disconImpact: -0.023649793,
							featureValue: 0,
						},
						{
							conversationId: 1697,
							disconImpact: -0.0306729,
							featureValue: 0,
						},
						{
							conversationId: 1709,
							disconImpact: -0.036065713,
							featureValue: 0,
						},
						{
							conversationId: 1712,
							disconImpact: -0.028361222,
							featureValue: 0,
						},
						{
							conversationId: 1727,
							disconImpact: -0.03107842,
							featureValue: 0,
						},
						{
							conversationId: 1734,
							disconImpact: -0.021814011,
							featureValue: 0,
						},
						{
							conversationId: 1741,
							disconImpact: -0.038806256,
							featureValue: 0,
						},
						{
							conversationId: 1742,
							disconImpact: -0.02690571,
							featureValue: 0,
						},
						{
							conversationId: 1743,
							disconImpact: -0.02299491,
							featureValue: 0,
						},
						{
							conversationId: 1755,
							disconImpact: -0.022181626,
							featureValue: 0,
						},
						{
							conversationId: 1762,
							disconImpact: -0.03828229,
							featureValue: 0,
						},
						{
							conversationId: 1771,
							disconImpact: 1.3123362,
							featureValue: 0.2017377409367821,
						},
						{
							conversationId: 1773,
							disconImpact: -0.036141623,
							featureValue: 0,
						},
						{
							conversationId: 1792,
							disconImpact: -0.030535374,
							featureValue: 0,
						},
						{
							conversationId: 1808,
							disconImpact: -0.03567403,
							featureValue: 0,
						},
						{
							conversationId: 1810,
							disconImpact: -0.03137711,
							featureValue: 0,
						},
						{
							conversationId: 1832,
							disconImpact: -0.0214425,
							featureValue: 0,
						},
						{
							conversationId: 1838,
							disconImpact: -0.023106396,
							featureValue: 0,
						},
						{
							conversationId: 1852,
							disconImpact: -0.027290892,
							featureValue: 0,
						},
						{
							conversationId: 1862,
							disconImpact: -0.018215012,
							featureValue: 0,
						},
						{
							conversationId: 1873,
							disconImpact: -0.022380665,
							featureValue: 0,
						},
						{
							conversationId: 1877,
							disconImpact: -0.03066952,
							featureValue: 0,
						},
						{
							conversationId: 1884,
							disconImpact: -0.036364768,
							featureValue: 0,
						},
						{
							conversationId: 1887,
							disconImpact: -0.033523746,
							featureValue: 0,
						},
						{
							conversationId: 1890,
							disconImpact: -0.03070955,
							featureValue: 0,
						},
						{
							conversationId: 1892,
							disconImpact: -0.02516095,
							featureValue: 0,
						},
						{
							conversationId: 1893,
							disconImpact: -0.017261019,
							featureValue: 0,
						},
						{
							conversationId: 1903,
							disconImpact: -0.03024432,
							featureValue: 0,
						},
						{
							conversationId: 1909,
							disconImpact: -0.020923205,
							featureValue: 0,
						},
						{
							conversationId: 1910,
							disconImpact: -0.029667925,
							featureValue: 0,
						},
						{
							conversationId: 1931,
							disconImpact: -0.030091481,
							featureValue: 0,
						},
						{
							conversationId: 1939,
							disconImpact: -0.03260286,
							featureValue: 0,
						},
						{
							conversationId: 1959,
							disconImpact: -0.028044071,
							featureValue: 0,
						},
						{
							conversationId: 1964,
							disconImpact: -0.025449261,
							featureValue: 0,
						},
						{
							conversationId: 1966,
							disconImpact: -0.027996719,
							featureValue: 0,
						},
						{
							conversationId: 1991,
							disconImpact: -0.026093524,
							featureValue: 0,
						},
						{
							conversationId: 1994,
							disconImpact: -0.040691715,
							featureValue: 0,
						},
						{
							conversationId: 1995,
							disconImpact: -0.02121088,
							featureValue: 0,
						},
						{
							conversationId: 1996,
							disconImpact: -0.030956715,
							featureValue: 0,
						},
						{
							conversationId: 1999,
							disconImpact: 0.61177,
							featureValue: 0.0305284662529389,
						},
						{
							conversationId: 2004,
							disconImpact: -0.01904804,
							featureValue: 0,
						},
						{
							conversationId: 2014,
							disconImpact: -0.019788273,
							featureValue: 0,
						},
						{
							conversationId: 2015,
							disconImpact: -0.02165245,
							featureValue: 0,
						},
						{
							conversationId: 2017,
							disconImpact: -0.025891472,
							featureValue: 0,
						},
						{
							conversationId: 2025,
							disconImpact: -0.03296791,
							featureValue: 0,
						},
						{
							conversationId: 2047,
							disconImpact: -0.021789964,
							featureValue: 0,
						},
						{
							conversationId: 2053,
							disconImpact: -0.033683944,
							featureValue: 0,
						},
						{
							conversationId: 2055,
							disconImpact: -0.022716906,
							featureValue: 0,
						},
						{
							conversationId: 2070,
							disconImpact: -0.025984507,
							featureValue: 0,
						},
						{
							conversationId: 2077,
							disconImpact: -0.025749478,
							featureValue: 0,
						},
						{
							conversationId: 2081,
							disconImpact: -0.024464512,
							featureValue: 0,
						},
						{
							conversationId: 2093,
							disconImpact: -0.033316996,
							featureValue: 0,
						},
						{
							conversationId: 2095,
							disconImpact: -0.028305177,
							featureValue: 0,
						},
						{
							conversationId: 2112,
							disconImpact: -0.024945728,
							featureValue: 0,
						},
						{
							conversationId: 2119,
							disconImpact: -0.022131246,
							featureValue: 0,
						},
						{
							conversationId: 2121,
							disconImpact: -0.022464896,
							featureValue: 0,
						},
						{
							conversationId: 2135,
							disconImpact: -0.01631865,
							featureValue: 0,
						},
						{
							conversationId: 2139,
							disconImpact: -0.01973132,
							featureValue: 0,
						},
						{
							conversationId: 2144,
							disconImpact: -0.03180096,
							featureValue: 0,
						},
						{
							conversationId: 2147,
							disconImpact: -0.025184985,
							featureValue: 0,
						},
						{
							conversationId: 2155,
							disconImpact: -0.031932775,
							featureValue: 0,
						},
						{
							conversationId: 2179,
							disconImpact: -0.019607333,
							featureValue: 0,
						},
						{
							conversationId: 2189,
							disconImpact: -0.019855233,
							featureValue: 0,
						},
						{
							conversationId: 2198,
							disconImpact: -0.024042474,
							featureValue: 0,
						},
						{
							conversationId: 2225,
							disconImpact: -0.022570502,
							featureValue: 0,
						},
						{
							conversationId: 2237,
							disconImpact: -0.0144628985,
							featureValue: 0,
						},
						{
							conversationId: 2256,
							disconImpact: 1.0825045,
							featureValue: 0.0883094571753165,
						},
						{
							conversationId: 2264,
							disconImpact: -0.022228498,
							featureValue: 0,
						},
						{
							conversationId: 2277,
							disconImpact: -0.032848738,
							featureValue: 0,
						},
						{
							conversationId: 2284,
							disconImpact: -0.022167142,
							featureValue: 0,
						},
						{
							conversationId: 2293,
							disconImpact: -0.01886085,
							featureValue: 0,
						},
						{
							conversationId: 2298,
							disconImpact: -0.028448466,
							featureValue: 0,
						},
						{
							conversationId: 2299,
							disconImpact: -0.033451006,
							featureValue: 0,
						},
						{
							conversationId: 2302,
							disconImpact: -0.022084076,
							featureValue: 0,
						},
						{
							conversationId: 2311,
							disconImpact: -0.037182007,
							featureValue: 0,
						},
						{
							conversationId: 2344,
							disconImpact: -0.027521167,
							featureValue: 0,
						},
						{
							conversationId: 2356,
							disconImpact: -0.031326756,
							featureValue: 0,
						},
						{
							conversationId: 2358,
							disconImpact: -0.029857604,
							featureValue: 0,
						},
						{
							conversationId: 2373,
							disconImpact: 0.47342372,
							featureValue: 0.0270635994587279,
						},
						{
							conversationId: 2436,
							disconImpact: -0.023661692,
							featureValue: 0,
						},
						{
							conversationId: 2464,
							disconImpact: -0.033456545,
							featureValue: 0,
						},
						{
							conversationId: 2495,
							disconImpact: -0.018121332,
							featureValue: 0,
						},
						{
							conversationId: 2508,
							disconImpact: -0.03018288,
							featureValue: 0,
						},
						{
							conversationId: 2509,
							disconImpact: -0.020877901,
							featureValue: 0,
						},
						{
							conversationId: 2528,
							disconImpact: -0.021434356,
							featureValue: 0,
						},
						{
							conversationId: 2532,
							disconImpact: -0.032042824,
							featureValue: 0,
						},
						{
							conversationId: 2551,
							disconImpact: -0.029311916,
							featureValue: 0,
						},
						{
							conversationId: 2582,
							disconImpact: -0.023522692,
							featureValue: 0,
						},
						{
							conversationId: 2583,
							disconImpact: -0.0142166875,
							featureValue: 0,
						},
						{
							conversationId: 2585,
							disconImpact: -0.02728005,
							featureValue: 0,
						},
						{
							conversationId: 2598,
							disconImpact: -0.030942779,
							featureValue: 0,
						},
						{
							conversationId: 2606,
							disconImpact: -0.024515063,
							featureValue: 0,
						},
						{
							conversationId: 2608,
							disconImpact: -0.032750405,
							featureValue: 0,
						},
						{
							conversationId: 2615,
							disconImpact: 0.9179759,
							featureValue: 0.0891330891330892,
						},
						{
							conversationId: 2630,
							disconImpact: -0.031158473,
							featureValue: 0,
						},
						{
							conversationId: 2641,
							disconImpact: -0.032756075,
							featureValue: 0,
						},
						{
							conversationId: 2658,
							disconImpact: -0.03200512,
							featureValue: 0,
						},
						{
							conversationId: 2674,
							disconImpact: -0.025600877,
							featureValue: 0,
						},
						{
							conversationId: 2699,
							disconImpact: -0.020636052,
							featureValue: 0,
						},
						{
							conversationId: 2757,
							disconImpact: -0.037182007,
							featureValue: 0,
						},
						{
							conversationId: 2760,
							disconImpact: -0.037182007,
							featureValue: 0,
						},
						{
							conversationId: 2773,
							disconImpact: -0.021905024,
							featureValue: 0,
						},
						{
							conversationId: 2776,
							disconImpact: -0.018640758,
							featureValue: 0,
						},
						{
							conversationId: 2804,
							disconImpact: -0.031243835,
							featureValue: 0,
						},
						{
							conversationId: 2818,
							disconImpact: -0.027195288,
							featureValue: 0,
						},
						{
							conversationId: 2824,
							disconImpact: 0.9193971,
							featureValue: 0.1105845995364408,
						},
						{
							conversationId: 2849,
							disconImpact: -0.029773088,
							featureValue: 0,
						},
						{
							conversationId: 2850,
							disconImpact: -0.019953467,
							featureValue: 0,
						},
						{
							conversationId: 2857,
							disconImpact: -0.034146857,
							featureValue: 0,
						},
						{
							conversationId: 2859,
							disconImpact: -0.030561324,
							featureValue: 0,
						},
						{
							conversationId: 2869,
							disconImpact: -0.026628623,
							featureValue: 0,
						},
						{
							conversationId: 2896,
							disconImpact: -0.0051749684,
							featureValue: 0.0184438365436137,
						},
						{
							conversationId: 2912,
							disconImpact: -0.03564494,
							featureValue: 0,
						},
						{
							conversationId: 2918,
							disconImpact: -0.030570861,
							featureValue: 0,
						},
						{
							conversationId: 2930,
							disconImpact: -0.024200883,
							featureValue: 0,
						},
						{
							conversationId: 2932,
							disconImpact: -0.019811481,
							featureValue: 0,
						},
						{
							conversationId: 2935,
							disconImpact: -0.02201121,
							featureValue: 0,
						},
						{
							conversationId: 2940,
							disconImpact: -0.024143938,
							featureValue: 0,
						},
						{
							conversationId: 2946,
							disconImpact: -0.022563564,
							featureValue: 0,
						},
						{
							conversationId: 2956,
							disconImpact: -0.028959677,
							featureValue: 0,
						},
						{
							conversationId: 2972,
							disconImpact: -0.037977263,
							featureValue: 0,
						},
						{
							conversationId: 2974,
							disconImpact: -0.0250081,
							featureValue: 0,
						},
						{
							conversationId: 3013,
							disconImpact: -0.0235641,
							featureValue: 0,
						},
						{
							conversationId: 3065,
							disconImpact: -0.018302321,
							featureValue: 0,
						},
						{
							conversationId: 3077,
							disconImpact: -0.017530255,
							featureValue: 0,
						},
						{
							conversationId: 3079,
							disconImpact: -0.03284635,
							featureValue: 0,
						},
						{
							conversationId: 3101,
							disconImpact: -0.01747987,
							featureValue: 0,
						},
						{
							conversationId: 3104,
							disconImpact: -0.038686458,
							featureValue: 0,
						},
						{
							conversationId: 3120,
							disconImpact: -0.03164996,
							featureValue: 0,
						},
						{
							conversationId: 3122,
							disconImpact: 1.5620531,
							featureValue: 0.0569823088972881,
						},
						{
							conversationId: 3126,
							disconImpact: -0.03224463,
							featureValue: 0,
						},
						{
							conversationId: 3128,
							disconImpact: -0.026973642,
							featureValue: 0,
						},
						{
							conversationId: 3133,
							disconImpact: -0.035282668,
							featureValue: 0,
						},
						{
							conversationId: 3150,
							disconImpact: -0.029169638,
							featureValue: 0,
						},
						{
							conversationId: 3151,
							disconImpact: 1.279348,
							featureValue: 0.0653575615474795,
						},
						{
							conversationId: 3159,
							disconImpact: -0.024250211,
							featureValue: 0,
						},
						{
							conversationId: 3161,
							disconImpact: -0.02829707,
							featureValue: 0,
						},
						{
							conversationId: 3162,
							disconImpact: -0.02817944,
							featureValue: 0,
						},
						{
							conversationId: 3201,
							disconImpact: -0.029200617,
							featureValue: 0,
						},
						{
							conversationId: 3209,
							disconImpact: -0.017889852,
							featureValue: 0,
						},
						{
							conversationId: 3210,
							disconImpact: -0.020174908,
							featureValue: 0,
						},
						{
							conversationId: 3219,
							disconImpact: -0.033316996,
							featureValue: 0,
						},
						{
							conversationId: 3234,
							disconImpact: 1.48048,
							featureValue: 0.0495855247494751,
						},
						{
							conversationId: 3249,
							disconImpact: -0.026360177,
							featureValue: 0,
						},
						{
							conversationId: 3251,
							disconImpact: -0.02458967,
							featureValue: 0,
						},
						{
							conversationId: 3263,
							disconImpact: -0.013391957,
							featureValue: 0,
						},
						{
							conversationId: 3267,
							disconImpact: -0.01841529,
							featureValue: 0,
						},
						{
							conversationId: 3280,
							disconImpact: -0.027622111,
							featureValue: 0,
						},
						{
							conversationId: 3288,
							disconImpact: -0.028002199,
							featureValue: 0,
						},
						{
							conversationId: 3289,
							disconImpact: 1.0413439,
							featureValue: 0.0772685848196849,
						},
						{
							conversationId: 3294,
							disconImpact: -0.014233682,
							featureValue: 0,
						},
						{
							conversationId: 3296,
							disconImpact: -0.0197228,
							featureValue: 0,
						},
						{
							conversationId: 3305,
							disconImpact: -0.025343578,
							featureValue: 0,
						},
						{
							conversationId: 3310,
							disconImpact: -0.03416567,
							featureValue: 0,
						},
						{
							conversationId: 3317,
							disconImpact: -0.033889122,
							featureValue: 0,
						},
						{
							conversationId: 3321,
							disconImpact: -0.018240431,
							featureValue: 0,
						},
						{
							conversationId: 3322,
							disconImpact: -0.018838644,
							featureValue: 0,
						},
						{
							conversationId: 3332,
							disconImpact: -0.031522065,
							featureValue: 0,
						},
						{
							conversationId: 3342,
							disconImpact: 0.6599442,
							featureValue: 0.0282314089526992,
						},
						{
							conversationId: 3372,
							disconImpact: -0.02893588,
							featureValue: 0,
						},
						{
							conversationId: 3375,
							disconImpact: -0.027300434,
							featureValue: 0,
						},
						{
							conversationId: 3381,
							disconImpact: -0.03791614,
							featureValue: 0,
						},
						{
							conversationId: 3391,
							disconImpact: -0.029884972,
							featureValue: 0,
						},
						{
							conversationId: 3399,
							disconImpact: -0.03204804,
							featureValue: 0,
						},
						{
							conversationId: 3400,
							disconImpact: -0.037182007,
							featureValue: 0,
						},
						{
							conversationId: 3405,
							disconImpact: -0.034472466,
							featureValue: 0,
						},
						{
							conversationId: 3414,
							disconImpact: -0.022452354,
							featureValue: 0,
						},
						{
							conversationId: 3426,
							disconImpact: -0.02882227,
							featureValue: 0,
						},
						{
							conversationId: 3458,
							disconImpact: -0.03204804,
							featureValue: 0,
						},
						{
							conversationId: 3461,
							disconImpact: -0.033316996,
							featureValue: 0,
						},
						{
							conversationId: 3485,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 3490,
							disconImpact: -0.028897893,
							featureValue: 0,
						},
						{
							conversationId: 3500,
							disconImpact: -0.018627014,
							featureValue: 0,
						},
						{
							conversationId: 3503,
							disconImpact: -0.028660348,
							featureValue: 0,
						},
						{
							conversationId: 3511,
							disconImpact: -0.016308036,
							featureValue: 0,
						},
						{
							conversationId: 3512,
							disconImpact: -0.038370706,
							featureValue: 0,
						},
						{
							conversationId: 3516,
							disconImpact: -0.031214327,
							featureValue: 0,
						},
						{
							conversationId: 3518,
							disconImpact: -0.02246353,
							featureValue: 0,
						},
						{
							conversationId: 3525,
							disconImpact: -0.022499532,
							featureValue: 0,
						},
						{
							conversationId: 3535,
							disconImpact: -0.016526097,
							featureValue: 0,
						},
						{
							conversationId: 3537,
							disconImpact: 0.90105927,
							featureValue: 0.1150414276609303,
						},
						{
							conversationId: 3538,
							disconImpact: -0.028761279,
							featureValue: 0,
						},
						{
							conversationId: 3551,
							disconImpact: -0.032441985,
							featureValue: 0,
						},
						{
							conversationId: 3555,
							disconImpact: -0.02651704,
							featureValue: 0,
						},
						{
							conversationId: 3567,
							disconImpact: -0.025926048,
							featureValue: 0.0254296037981597,
						},
						{
							conversationId: 3568,
							disconImpact: -0.033889122,
							featureValue: 0,
						},
						{
							conversationId: 3573,
							disconImpact: -0.018607907,
							featureValue: 0,
						},
						{
							conversationId: 3576,
							disconImpact: -0.030196842,
							featureValue: 0,
						},
						{
							conversationId: 3577,
							disconImpact: -0.03491102,
							featureValue: 0,
						},
						{
							conversationId: 3589,
							disconImpact: -0.025721531,
							featureValue: 0,
						},
						{
							conversationId: 3591,
							disconImpact: -0.025770538,
							featureValue: 0,
						},
						{
							conversationId: 3592,
							disconImpact: -0.029361356,
							featureValue: 0,
						},
						{
							conversationId: 3593,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 3600,
							disconImpact: 0.7526215,
							featureValue: 0.0626032819645672,
						},
						{
							conversationId: 3602,
							disconImpact: -0.017755328,
							featureValue: 0,
						},
						{
							conversationId: 3603,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 3618,
							disconImpact: -0.022874165,
							featureValue: 0,
						},
						{
							conversationId: 3628,
							disconImpact: -0.026235374,
							featureValue: 0,
						},
						{
							conversationId: 3629,
							disconImpact: -0.020118888,
							featureValue: 0,
						},
						{
							conversationId: 3630,
							disconImpact: -0.021118456,
							featureValue: 0,
						},
						{
							conversationId: 3634,
							disconImpact: -0.033396307,
							featureValue: 0,
						},
						{
							conversationId: 3635,
							disconImpact: -0.028489493,
							featureValue: 0,
						},
						{
							conversationId: 3639,
							disconImpact: -0.033915132,
							featureValue: 0,
						},
						{
							conversationId: 3641,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 3644,
							disconImpact: -0.018867373,
							featureValue: 0,
						},
						{
							conversationId: 3653,
							disconImpact: 0.55125254,
							featureValue: 0.0283234082896335,
						},
						{
							conversationId: 3655,
							disconImpact: -0.034807462,
							featureValue: 0,
						},
						{
							conversationId: 3662,
							disconImpact: -0.035586447,
							featureValue: 0,
						},
						{
							conversationId: 3669,
							disconImpact: -0.028165275,
							featureValue: 0,
						},
						{
							conversationId: 3671,
							disconImpact: -0.033675518,
							featureValue: 0,
						},
						{
							conversationId: 3688,
							disconImpact: -0.022415452,
							featureValue: 0,
						},
						{
							conversationId: 3689,
							disconImpact: -0.010127015,
							featureValue: 0,
						},
						{
							conversationId: 3701,
							disconImpact: -0.021844447,
							featureValue: 0,
						},
						{
							conversationId: 3703,
							disconImpact: -0.031584077,
							featureValue: 0,
						},
						{
							conversationId: 3704,
							disconImpact: -0.014185235,
							featureValue: 0,
						},
						{
							conversationId: 3706,
							disconImpact: -0.032154635,
							featureValue: 0,
						},
						{
							conversationId: 3713,
							disconImpact: -0.030724086,
							featureValue: 0,
						},
						{
							conversationId: 3714,
							disconImpact: -0.026087085,
							featureValue: 0,
						},
						{
							conversationId: 3716,
							disconImpact: -0.035798147,
							featureValue: 0,
						},
						{
							conversationId: 3723,
							disconImpact: -0.025303956,
							featureValue: 0,
						},
						{
							conversationId: 3727,
							disconImpact: -0.030267347,
							featureValue: 0,
						},
						{
							conversationId: 3730,
							disconImpact: 0.023225471,
							featureValue: 0.0151366952617654,
						},
						{
							conversationId: 3735,
							disconImpact: -0.024896253,
							featureValue: 0,
						},
						{
							conversationId: 3736,
							disconImpact: -0.02195768,
							featureValue: 0,
						},
						{
							conversationId: 3737,
							disconImpact: -0.18513891,
							featureValue: 0.0131847366273701,
						},
						{
							conversationId: 3747,
							disconImpact: -0.031909313,
							featureValue: 0,
						},
						{
							conversationId: 3748,
							disconImpact: -0.024393797,
							featureValue: 0,
						},
						{
							conversationId: 3753,
							disconImpact: 1.0917383,
							featureValue: 0.0467186654101416,
						},
						{
							conversationId: 3756,
							disconImpact: -0.033652637,
							featureValue: 0,
						},
						{
							conversationId: 3761,
							disconImpact: -0.029839285,
							featureValue: 0,
						},
						{
							conversationId: 3767,
							disconImpact: -0.024593264,
							featureValue: 0,
						},
						{
							conversationId: 3769,
							disconImpact: -0.035765987,
							featureValue: 0,
						},
						{
							conversationId: 3770,
							disconImpact: -0.035144296,
							featureValue: 0,
						},
						{
							conversationId: 3774,
							disconImpact: -0.033889122,
							featureValue: 0,
						},
						{
							conversationId: 3776,
							disconImpact: -0.035665806,
							featureValue: 0,
						},
						{
							conversationId: 3779,
							disconImpact: -0.03231497,
							featureValue: 0,
						},
						{
							conversationId: 3783,
							disconImpact: -0.04570286,
							featureValue: 0,
						},
						{
							conversationId: 3787,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 3788,
							disconImpact: -0.028416377,
							featureValue: 0,
						},
						{
							conversationId: 3799,
							disconImpact: -0.034181423,
							featureValue: 0,
						},
						{
							conversationId: 3801,
							disconImpact: -0.024720214,
							featureValue: 0,
						},
						{
							conversationId: 3804,
							disconImpact: -0.026313782,
							featureValue: 0,
						},
						{
							conversationId: 3805,
							disconImpact: -0.022347253,
							featureValue: 0,
						},
						{
							conversationId: 3806,
							disconImpact: -0.02468629,
							featureValue: 0,
						},
						{
							conversationId: 3811,
							disconImpact: -0.030215628,
							featureValue: 0,
						},
						{
							conversationId: 3812,
							disconImpact: -0.03194605,
							featureValue: 0,
						},
						{
							conversationId: 3819,
							disconImpact: -0.022311464,
							featureValue: 0,
						},
						{
							conversationId: 3820,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 3821,
							disconImpact: -0.028738588,
							featureValue: 0,
						},
						{
							conversationId: 3827,
							disconImpact: -0.020185659,
							featureValue: 0,
						},
						{
							conversationId: 3833,
							disconImpact: -0.009981028,
							featureValue: 0,
						},
						{
							conversationId: 3834,
							disconImpact: -0.03224463,
							featureValue: 0,
						},
						{
							conversationId: 3836,
							disconImpact: -0.028048558,
							featureValue: 0,
						},
						{
							conversationId: 3839,
							disconImpact: -0.030521816,
							featureValue: 0,
						},
						{
							conversationId: 3848,
							disconImpact: -0.032346718,
							featureValue: 0,
						},
						{
							conversationId: 3849,
							disconImpact: -0.024096195,
							featureValue: 0,
						},
						{
							conversationId: 3851,
							disconImpact: -0.032601565,
							featureValue: 0,
						},
						{
							conversationId: 3854,
							disconImpact: -0.028590562,
							featureValue: 0,
						},
						{
							conversationId: 3858,
							disconImpact: -0.020990867,
							featureValue: 0,
						},
						{
							conversationId: 3861,
							disconImpact: -0.013276267,
							featureValue: 0,
						},
						{
							conversationId: 3865,
							disconImpact: -0.029634494,
							featureValue: 0,
						},
						{
							conversationId: 3866,
							disconImpact: -0.032770015,
							featureValue: 0,
						},
						{
							conversationId: 3867,
							disconImpact: -0.029952325,
							featureValue: 0,
						},
						{
							conversationId: 3869,
							disconImpact: -0.028917078,
							featureValue: 0,
						},
						{
							conversationId: 3871,
							disconImpact: -0.022045542,
							featureValue: 0,
						},
						{
							conversationId: 3873,
							disconImpact: -0.0127607975,
							featureValue: 0,
						},
						{
							conversationId: 3876,
							disconImpact: -0.02892094,
							featureValue: 0,
						},
						{
							conversationId: 3879,
							disconImpact: -0.026785687,
							featureValue: 0,
						},
						{
							conversationId: 3880,
							disconImpact: -0.019706804,
							featureValue: 0,
						},
						{
							conversationId: 3882,
							disconImpact: -0.03121277,
							featureValue: 0,
						},
						{
							conversationId: 3884,
							disconImpact: -0.021999968,
							featureValue: 0,
						},
						{
							conversationId: 3890,
							disconImpact: -0.03204804,
							featureValue: 0,
						},
						{
							conversationId: 3892,
							disconImpact: -0.027446492,
							featureValue: 0,
						},
						{
							conversationId: 3893,
							disconImpact: -0.030845467,
							featureValue: 0,
						},
						{
							conversationId: 3894,
							disconImpact: -0.030293133,
							featureValue: 0,
						},
						{
							conversationId: 3899,
							disconImpact: -0.030644607,
							featureValue: 0,
						},
						{
							conversationId: 3900,
							disconImpact: -0.026217034,
							featureValue: 0,
						},
						{
							conversationId: 3901,
							disconImpact: -0.032249458,
							featureValue: 0,
						},
						{
							conversationId: 3904,
							disconImpact: -0.016970478,
							featureValue: 0,
						},
						{
							conversationId: 3905,
							disconImpact: -0.024636436,
							featureValue: 0,
						},
						{
							conversationId: 3908,
							disconImpact: -0.034863867,
							featureValue: 0,
						},
						{
							conversationId: 3921,
							disconImpact: -0.0243086,
							featureValue: 0,
						},
						{
							conversationId: 3922,
							disconImpact: -0.015232468,
							featureValue: 0,
						},
						{
							conversationId: 3923,
							disconImpact: -0.024549268,
							featureValue: 0,
						},
						{
							conversationId: 3926,
							disconImpact: -0.022750884,
							featureValue: 0,
						},
						{
							conversationId: 3932,
							disconImpact: -0.021565408,
							featureValue: 0,
						},
						{
							conversationId: 3933,
							disconImpact: -0.023724623,
							featureValue: 0,
						},
						{
							conversationId: 3935,
							disconImpact: -0.038370706,
							featureValue: 0,
						},
						{
							conversationId: 3947,
							disconImpact: -0.024927948,
							featureValue: 0,
						},
						{
							conversationId: 3949,
							disconImpact: -0.050323743,
							featureValue: 0,
						},
						{
							conversationId: 3956,
							disconImpact: -0.028085165,
							featureValue: 0,
						},
						{
							conversationId: 3959,
							disconImpact: -0.026039213,
							featureValue: 0,
						},
						{
							conversationId: 3961,
							disconImpact: -0.023529146,
							featureValue: 0,
						},
						{
							conversationId: 3967,
							disconImpact: -0.022152688,
							featureValue: 0,
						},
						{
							conversationId: 3970,
							disconImpact: -0.03316265,
							featureValue: 0,
						},
						{
							conversationId: 3972,
							disconImpact: -0.031175308,
							featureValue: 0,
						},
						{
							conversationId: 3973,
							disconImpact: -0.027529342,
							featureValue: 0,
						},
						{
							conversationId: 3975,
							disconImpact: -0.0375658,
							featureValue: 0,
						},
						{
							conversationId: 3976,
							disconImpact: -0.04041897,
							featureValue: 0,
						},
						{
							conversationId: 3983,
							disconImpact: -0.024091732,
							featureValue: 0,
						},
						{
							conversationId: 3985,
							disconImpact: -0.031378467,
							featureValue: 0,
						},
						{
							conversationId: 3999,
							disconImpact: -0.03678594,
							featureValue: 0,
						},
						{
							conversationId: 4006,
							disconImpact: -0.030277407,
							featureValue: 0,
						},
						{
							conversationId: 4031,
							disconImpact: -0.021973303,
							featureValue: 0,
						},
						{
							conversationId: 4051,
							disconImpact: -0.023265498,
							featureValue: 0,
						},
						{
							conversationId: 4078,
							disconImpact: -0.023620691,
							featureValue: 0,
						},
						{
							conversationId: 4079,
							disconImpact: 0.9713759,
							featureValue: 0.044658214945531,
						},
						{
							conversationId: 4089,
							disconImpact: -0.028959677,
							featureValue: 0,
						},
						{
							conversationId: 4091,
							disconImpact: -0.033889122,
							featureValue: 0,
						},
						{
							conversationId: 4099,
							disconImpact: -0.032752078,
							featureValue: 0,
						},
						{
							conversationId: 4107,
							disconImpact: -0.020284148,
							featureValue: 0,
						},
						{
							conversationId: 4112,
							disconImpact: -0.03388187,
							featureValue: 0,
						},
						{
							conversationId: 4129,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 4142,
							disconImpact: -0.028775573,
							featureValue: 0,
						},
						{
							conversationId: 4153,
							disconImpact: -0.020729117,
							featureValue: 0,
						},
						{
							conversationId: 4158,
							disconImpact: -0.039976135,
							featureValue: 0,
						},
						{
							conversationId: 4164,
							disconImpact: -0.03724477,
							featureValue: 0,
						},
						{
							conversationId: 4171,
							disconImpact: -0.02147585,
							featureValue: 0,
						},
						{
							conversationId: 4186,
							disconImpact: -0.03672267,
							featureValue: 0,
						},
						{
							conversationId: 4197,
							disconImpact: -0.033153977,
							featureValue: 0,
						},
						{
							conversationId: 4213,
							disconImpact: -0.026157228,
							featureValue: 0,
						},
						{
							conversationId: 4218,
							disconImpact: -0.037920542,
							featureValue: 0,
						},
						{
							conversationId: 4228,
							disconImpact: -0.024345651,
							featureValue: 0,
						},
						{
							conversationId: 4230,
							disconImpact: -0.024460724,
							featureValue: 0,
						},
						{
							conversationId: 4231,
							disconImpact: -0.020295482,
							featureValue: 0,
						},
						{
							conversationId: 4235,
							disconImpact: -0.021379977,
							featureValue: 0,
						},
						{
							conversationId: 4240,
							disconImpact: -0.028028281,
							featureValue: 0,
						},
						{
							conversationId: 4254,
							disconImpact: -0.022167569,
							featureValue: 0,
						},
						{
							conversationId: 4267,
							disconImpact: 0.79562336,
							featureValue: 0.0534166861837244,
						},
						{
							conversationId: 4287,
							disconImpact: -0.03750783,
							featureValue: 0,
						},
						{
							conversationId: 4289,
							disconImpact: -0.019417096,
							featureValue: 0,
						},
						{
							conversationId: 4293,
							disconImpact: -0.028959677,
							featureValue: 0,
						},
						{
							conversationId: 4298,
							disconImpact: -0.030498054,
							featureValue: 0,
						},
						{
							conversationId: 4341,
							disconImpact: -0.028948672,
							featureValue: 0,
						},
						{
							conversationId: 4347,
							disconImpact: -0.030601654,
							featureValue: 0,
						},
						{
							conversationId: 4350,
							disconImpact: -0.021930952,
							featureValue: 0,
						},
						{
							conversationId: 4369,
							disconImpact: -0.023731053,
							featureValue: 0,
						},
						{
							conversationId: 4409,
							disconImpact: 1.0892203,
							featureValue: 0.141726618705036,
						},
						{
							conversationId: 4416,
							disconImpact: -0.02586005,
							featureValue: 0,
						},
						{
							conversationId: 4428,
							disconImpact: -0.024184415,
							featureValue: 0,
						},
						{
							conversationId: 4442,
							disconImpact: -0.027373508,
							featureValue: 0,
						},
						{
							conversationId: 4456,
							disconImpact: 1.0045257,
							featureValue: 0.1439916193565828,
						},
						{
							conversationId: 4484,
							disconImpact: -0.0294283,
							featureValue: 0,
						},
						{
							conversationId: 4490,
							disconImpact: -0.023215245,
							featureValue: 0,
						},
						{
							conversationId: 4493,
							disconImpact: -0.033889122,
							featureValue: 0,
						},
						{
							conversationId: 4501,
							disconImpact: -0.031724602,
							featureValue: 0,
						},
						{
							conversationId: 4516,
							disconImpact: -0.023803115,
							featureValue: 0,
						},
						{
							conversationId: 4521,
							disconImpact: -0.033652637,
							featureValue: 0,
						},
						{
							conversationId: 4531,
							disconImpact: -0.031644613,
							featureValue: 0,
						},
						{
							conversationId: 4532,
							disconImpact: -0.030535374,
							featureValue: 0,
						},
						{
							conversationId: 4539,
							disconImpact: -0.033889122,
							featureValue: 0,
						},
						{
							conversationId: 4541,
							disconImpact: -0.02598439,
							featureValue: 0,
						},
						{
							conversationId: 4543,
							disconImpact: -0.034179673,
							featureValue: 0,
						},
						{
							conversationId: 4545,
							disconImpact: -0.027411181,
							featureValue: 0,
						},
						{
							conversationId: 4547,
							disconImpact: -0.025369473,
							featureValue: 0,
						},
						{
							conversationId: 4554,
							disconImpact: -0.016939564,
							featureValue: 0,
						},
						{
							conversationId: 4555,
							disconImpact: -0.0338303,
							featureValue: 0,
						},
						{
							conversationId: 4572,
							disconImpact: -0.032471508,
							featureValue: 0,
						},
						{
							conversationId: 4575,
							disconImpact: -0.023643974,
							featureValue: 0,
						},
						{
							conversationId: 4576,
							disconImpact: -0.019946929,
							featureValue: 0,
						},
						{
							conversationId: 4579,
							disconImpact: -0.029311916,
							featureValue: 0,
						},
						{
							conversationId: 4585,
							disconImpact: -0.02256902,
							featureValue: 0,
						},
						{
							conversationId: 4587,
							disconImpact: -0.018175025,
							featureValue: 0,
						},
						{
							conversationId: 4594,
							disconImpact: -0.020158757,
							featureValue: 0,
						},
						{
							conversationId: 4607,
							disconImpact: -0.0155129675,
							featureValue: 0,
						},
						{
							conversationId: 4618,
							disconImpact: -0.024155134,
							featureValue: 0,
						},
						{
							conversationId: 4629,
							disconImpact: -0.022384226,
							featureValue: 0,
						},
						{
							conversationId: 4651,
							disconImpact: -0.019018415,
							featureValue: 0,
						},
						{
							conversationId: 4655,
							disconImpact: -0.022938777,
							featureValue: 0,
						},
						{
							conversationId: 4657,
							disconImpact: -0.013055585,
							featureValue: 0,
						},
						{
							conversationId: 4659,
							disconImpact: -0.026535798,
							featureValue: 0,
						},
						{
							conversationId: 4672,
							disconImpact: -0.018497868,
							featureValue: 0,
						},
						{
							conversationId: 4701,
							disconImpact: -0.024766117,
							featureValue: 0,
						},
						{
							conversationId: 4709,
							disconImpact: -0.024021715,
							featureValue: 0,
						},
						{
							conversationId: 4711,
							disconImpact: -0.032551415,
							featureValue: 0,
						},
						{
							conversationId: 4712,
							disconImpact: -0.020645749,
							featureValue: 0,
						},
						{
							conversationId: 4716,
							disconImpact: -0.023163624,
							featureValue: 0,
						},
						{
							conversationId: 4729,
							disconImpact: -0.030842511,
							featureValue: 0,
						},
						{
							conversationId: 4733,
							disconImpact: -0.2598188,
							featureValue: 0.009331461631464,
						},
						{
							conversationId: 4735,
							disconImpact: -0.023448097,
							featureValue: 0,
						},
						{
							conversationId: 4739,
							disconImpact: -0.031171376,
							featureValue: 0,
						},
						{
							conversationId: 4740,
							disconImpact: -0.015251317,
							featureValue: 0,
						},
						{
							conversationId: 4747,
							disconImpact: -0.03187486,
							featureValue: 0,
						},
						{
							conversationId: 4757,
							disconImpact: -0.013436144,
							featureValue: 0,
						},
						{
							conversationId: 4762,
							disconImpact: -0.025833573,
							featureValue: 0,
						},
						{
							conversationId: 4765,
							disconImpact: -0.022705834,
							featureValue: 0,
						},
						{
							conversationId: 4766,
							disconImpact: -0.024367262,
							featureValue: 0,
						},
						{
							conversationId: 4771,
							disconImpact: -0.01966325,
							featureValue: 0,
						},
						{
							conversationId: 4784,
							disconImpact: -0.025129674,
							featureValue: 0,
						},
						{
							conversationId: 4796,
							disconImpact: -0.024097629,
							featureValue: 0,
						},
						{
							conversationId: 4799,
							disconImpact: -0.033652637,
							featureValue: 0,
						},
						{
							conversationId: 4806,
							disconImpact: -0.04469803,
							featureValue: 0,
						},
						{
							conversationId: 4808,
							disconImpact: -0.033040278,
							featureValue: 0,
						},
						{
							conversationId: 4814,
							disconImpact: -0.0154799195,
							featureValue: 0,
						},
						{
							conversationId: 4820,
							disconImpact: -0.022914896,
							featureValue: 0,
						},
						{
							conversationId: 4828,
							disconImpact: -0.027486708,
							featureValue: 0,
						},
						{
							conversationId: 4830,
							disconImpact: -0.028099535,
							featureValue: 0,
						},
						{
							conversationId: 4831,
							disconImpact: -0.031958587,
							featureValue: 0,
						},
						{
							conversationId: 4838,
							disconImpact: -0.02107542,
							featureValue: 0,
						},
						{
							conversationId: 4842,
							disconImpact: -0.025852274,
							featureValue: 0,
						},
						{
							conversationId: 4847,
							disconImpact: -0.03204804,
							featureValue: 0,
						},
						{
							conversationId: 4848,
							disconImpact: -0.024690337,
							featureValue: 0,
						},
						{
							conversationId: 4849,
							disconImpact: -0.03261516,
							featureValue: 0,
						},
						{
							conversationId: 4852,
							disconImpact: -0.03270756,
							featureValue: 0,
						},
						{
							conversationId: 4854,
							disconImpact: -0.034684546,
							featureValue: 0,
						},
						{
							conversationId: 4859,
							disconImpact: 1.2868042,
							featureValue: 0.1560136157337366,
						},
						{
							conversationId: 4871,
							disconImpact: -0.035336737,
							featureValue: 0,
						},
						{
							conversationId: 4873,
							disconImpact: -0.032767326,
							featureValue: 0,
						},
						{
							conversationId: 4890,
							disconImpact: -0.03495471,
							featureValue: 0,
						},
						{
							conversationId: 4918,
							disconImpact: -0.031233575,
							featureValue: 0,
						},
						{
							conversationId: 4921,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 4925,
							disconImpact: -0.021615947,
							featureValue: 0,
						},
						{
							conversationId: 4934,
							disconImpact: -0.027288392,
							featureValue: 0,
						},
						{
							conversationId: 4939,
							disconImpact: -0.03061875,
							featureValue: 0,
						},
						{
							conversationId: 4940,
							disconImpact: -0.020076074,
							featureValue: 0,
						},
						{
							conversationId: 4942,
							disconImpact: -0.026326854,
							featureValue: 0,
						},
						{
							conversationId: 4945,
							disconImpact: -0.030058898,
							featureValue: 0,
						},
						{
							conversationId: 4948,
							disconImpact: -0.025868857,
							featureValue: 0,
						},
						{
							conversationId: 4953,
							disconImpact: -0.020113133,
							featureValue: 0,
						},
						{
							conversationId: 4988,
							disconImpact: -0.02404822,
							featureValue: 0,
						},
						{
							conversationId: 4991,
							disconImpact: -0.026354648,
							featureValue: 0,
						},
						{
							conversationId: 4995,
							disconImpact: 0.016628956,
							featureValue: 0.0070367097758856,
						},
						{
							conversationId: 5000,
							disconImpact: -0.028959677,
							featureValue: 0,
						},
						{
							conversationId: 5002,
							disconImpact: -0.018989906,
							featureValue: 0,
						},
						{
							conversationId: 5016,
							disconImpact: -0.016567793,
							featureValue: 0,
						},
						{
							conversationId: 5021,
							disconImpact: -0.033396307,
							featureValue: 0,
						},
						{
							conversationId: 5026,
							disconImpact: -0.034477443,
							featureValue: 0,
						},
						{
							conversationId: 5028,
							disconImpact: -0.01799141,
							featureValue: 0,
						},
						{
							conversationId: 5036,
							disconImpact: -0.02103458,
							featureValue: 0,
						},
						{
							conversationId: 5041,
							disconImpact: -0.022267058,
							featureValue: 0,
						},
						{
							conversationId: 5042,
							disconImpact: -0.02793618,
							featureValue: 0,
						},
						{
							conversationId: 5045,
							disconImpact: -0.02842246,
							featureValue: 0,
						},
						{
							conversationId: 5048,
							disconImpact: -0.020856291,
							featureValue: 0,
						},
						{
							conversationId: 5050,
							disconImpact: -0.024472035,
							featureValue: 0,
						},
						{
							conversationId: 5054,
							disconImpact: -0.030303754,
							featureValue: 0,
						},
						{
							conversationId: 5107,
							disconImpact: -0.03753936,
							featureValue: 0,
						},
						{
							conversationId: 5159,
							disconImpact: -0.022254637,
							featureValue: 0,
						},
						{
							conversationId: 5214,
							disconImpact: -0.01984179,
							featureValue: 0,
						},
						{
							conversationId: 5359,
							disconImpact: -0.026520329,
							featureValue: 0,
						},
						{
							conversationId: 5387,
							disconImpact: -0.01744827,
							featureValue: 0,
						},
						{
							conversationId: 5440,
							disconImpact: -0.020647544,
							featureValue: 0,
						},
						{
							conversationId: 5516,
							disconImpact: -0.030198783,
							featureValue: 0,
						},
						{
							conversationId: 5614,
							disconImpact: -0.023338892,
							featureValue: 0,
						},
						{
							conversationId: 5619,
							disconImpact: -0.015262222,
							featureValue: 0,
						},
						{
							conversationId: 5663,
							disconImpact: -0.026067294,
							featureValue: 0,
						},
						{
							conversationId: 5830,
							disconImpact: -0.026858822,
							featureValue: 0,
						},
						{
							conversationId: 5831,
							disconImpact: -0.026881289,
							featureValue: 0,
						},
						{
							conversationId: 5874,
							disconImpact: -0.02906627,
							featureValue: 0,
						},
						{
							conversationId: 5892,
							disconImpact: -0.031220747,
							featureValue: 0,
						},
						{
							conversationId: 5912,
							disconImpact: -0.025013816,
							featureValue: 0,
						},
						{
							conversationId: 5966,
							disconImpact: 1.0043557,
							featureValue: 0.1156027071520028,
						},
						{
							conversationId: 5981,
							disconImpact: -0.027850902,
							featureValue: 0,
						},
						{
							conversationId: 6005,
							disconImpact: -0.022152891,
							featureValue: 0,
						},
						{
							conversationId: 6015,
							disconImpact: 1.3005784,
							featureValue: 0.047754433307633,
						},
						{
							conversationId: 6221,
							disconImpact: -0.020067304,
							featureValue: 0,
						},
						{
							conversationId: 6224,
							disconImpact: -0.02043709,
							featureValue: 0,
						},
						{
							conversationId: 6252,
							disconImpact: -0.034197938,
							featureValue: 0,
						},
						{
							conversationId: 6270,
							disconImpact: -0.03157271,
							featureValue: 0,
						},
						{
							conversationId: 6278,
							disconImpact: -0.02348364,
							featureValue: 0,
						},
						{
							conversationId: 6291,
							disconImpact: -0.031695366,
							featureValue: 0,
						},
						{
							conversationId: 6326,
							disconImpact: -0.028959677,
							featureValue: 0,
						},
						{
							conversationId: 6340,
							disconImpact: -0.028959677,
							featureValue: 0,
						},
						{
							conversationId: 6370,
							disconImpact: -0.02857783,
							featureValue: 0,
						},
						{
							conversationId: 6393,
							disconImpact: -0.024386354,
							featureValue: 0,
						},
						{
							conversationId: 6394,
							disconImpact: -0.029307287,
							featureValue: 0,
						},
						{
							conversationId: 6434,
							disconImpact: -0.033988602,
							featureValue: 0,
						},
						{
							conversationId: 6485,
							disconImpact: -0.024314798,
							featureValue: 0,
						},
						{
							conversationId: 6518,
							disconImpact: -0.02161777,
							featureValue: 0,
						},
						{
							conversationId: 6527,
							disconImpact: -0.028287034,
							featureValue: 0,
						},
						{
							conversationId: 6538,
							disconImpact: -0.022367872,
							featureValue: 0,
						},
						{
							conversationId: 6709,
							disconImpact: -0.028303128,
							featureValue: 0,
						},
						{
							conversationId: 6715,
							disconImpact: -0.028959677,
							featureValue: 0,
						},
						{
							conversationId: 6738,
							disconImpact: -0.023162326,
							featureValue: 0,
						},
						{
							conversationId: 6781,
							disconImpact: -0.028959677,
							featureValue: 0,
						},
						{
							conversationId: 6800,
							disconImpact: -0.033760484,
							featureValue: 0,
						},
						{
							conversationId: 6829,
							disconImpact: -0.035281803,
							featureValue: 0,
						},
						{
							conversationId: 6840,
							disconImpact: -0.028444873,
							featureValue: 0,
						},
						{
							conversationId: 6903,
							disconImpact: -0.019994399,
							featureValue: 0,
						},
						{
							conversationId: 6943,
							disconImpact: -0.028959677,
							featureValue: 0,
						},
						{
							conversationId: 6944,
							disconImpact: -0.022492735,
							featureValue: 0,
						},
						{
							conversationId: 7030,
							disconImpact: -0.030908678,
							featureValue: 0,
						},
						{
							conversationId: 7053,
							disconImpact: -0.023368683,
							featureValue: 0,
						},
						{
							conversationId: 7081,
							disconImpact: -0.022153458,
							featureValue: 0,
						},
						{
							conversationId: 7170,
							disconImpact: -0.017259916,
							featureValue: 0,
						},
						{
							conversationId: 7222,
							disconImpact: -0.031615842,
							featureValue: 0,
						},
						{
							conversationId: 7338,
							disconImpact: -0.036986716,
							featureValue: 0,
						},
						{
							conversationId: 7612,
							disconImpact: -0.026840374,
							featureValue: 0,
						},
						{
							conversationId: 7762,
							disconImpact: -0.02348364,
							featureValue: 0,
						},
						{
							conversationId: 7866,
							disconImpact: 1.0250589,
							featureValue: 0.1432296270107831,
						},
						{
							conversationId: 8060,
							disconImpact: -0.029307287,
							featureValue: 0,
						},
						{
							conversationId: 8515,
							disconImpact: 0.8653479,
							featureValue: 0.0887377239222204,
						},
						{
							conversationId: 8577,
							disconImpact: -0.013917527,
							featureValue: 0,
						},
						{
							conversationId: 8639,
							disconImpact: -0.033200823,
							featureValue: 0,
						},
						{
							conversationId: 8663,
							disconImpact: -0.030878223,
							featureValue: 0,
						},
						{
							conversationId: 8665,
							disconImpact: -0.03227402,
							featureValue: 0,
						},
						{
							conversationId: 8667,
							disconImpact: -0.02333463,
							featureValue: 0,
						},
						{
							conversationId: 8708,
							disconImpact: -0.026879624,
							featureValue: 0,
						},
						{
							conversationId: 8734,
							disconImpact: -0.030477125,
							featureValue: 0,
						},
						{
							conversationId: 8932,
							disconImpact: -0.031040158,
							featureValue: 0,
						},
						{
							conversationId: 8991,
							disconImpact: -0.033942617,
							featureValue: 0,
						},
						{
							conversationId: 9056,
							disconImpact: -0.026879624,
							featureValue: 0,
						},
						{
							conversationId: 9231,
							disconImpact: -0.026497059,
							featureValue: 0,
						},
						{
							conversationId: 9298,
							disconImpact: -0.030147586,
							featureValue: 0,
						},
						{
							conversationId: 9311,
							disconImpact: -0.01736199,
							featureValue: 0,
						},
						{
							conversationId: 9414,
							disconImpact: -0.028959677,
							featureValue: 0,
						},
						{
							conversationId: 9542,
							disconImpact: -0.029286467,
							featureValue: 0,
						},
						{
							conversationId: 9558,
							disconImpact: -0.028959677,
							featureValue: 0,
						},
						{
							conversationId: 9578,
							disconImpact: -0.029307287,
							featureValue: 0,
						},
						{
							conversationId: 9654,
							disconImpact: -0.03432938,
							featureValue: 0,
						},
						{
							conversationId: 9659,
							disconImpact: -0.029272746,
							featureValue: 0,
						},
						{
							conversationId: 9663,
							disconImpact: -0.034431607,
							featureValue: 0,
						},
						{
							conversationId: 9665,
							disconImpact: -0.027396135,
							featureValue: 0,
						},
						{
							conversationId: 9670,
							disconImpact: 1.4771202,
							featureValue: 0.0607675906183369,
						},
						{
							conversationId: 9671,
							disconImpact: -0.031879317,
							featureValue: 0,
						},
						{
							conversationId: 9677,
							disconImpact: -0.01754682,
							featureValue: 0,
						},
						{
							conversationId: 9680,
							disconImpact: -0.022935295,
							featureValue: 0,
						},
						{
							conversationId: 9681,
							disconImpact: -0.033652637,
							featureValue: 0,
						},
						{
							conversationId: 9691,
							disconImpact: -0.034652077,
							featureValue: 0,
						},
						{
							conversationId: 9692,
							disconImpact: -0.031484094,
							featureValue: 0,
						},
						{
							conversationId: 9694,
							disconImpact: -0.032842774,
							featureValue: 0,
						},
						{
							conversationId: 9696,
							disconImpact: -0.029941313,
							featureValue: 0,
						},
						{
							conversationId: 9698,
							disconImpact: -0.032337632,
							featureValue: 0,
						},
						{
							conversationId: 9699,
							disconImpact: -0.03767268,
							featureValue: 0,
						},
						{
							conversationId: 9701,
							disconImpact: -0.037025716,
							featureValue: 0,
						},
						{
							conversationId: 9704,
							disconImpact: 0.05805508,
							featureValue: 0.0184400352886228,
						},
						{
							conversationId: 9705,
							disconImpact: -0.03000537,
							featureValue: 0,
						},
						{
							conversationId: 9707,
							disconImpact: -0.030582355,
							featureValue: 0,
						},
						{
							conversationId: 9712,
							disconImpact: -0.022365093,
							featureValue: 0,
						},
						{
							conversationId: 9717,
							disconImpact: -0.048043452,
							featureValue: 0,
						},
						{
							conversationId: 9718,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 9732,
							disconImpact: -0.014244552,
							featureValue: 0,
						},
						{
							conversationId: 9733,
							disconImpact: -0.0291005,
							featureValue: 0,
						},
						{
							conversationId: 9735,
							disconImpact: -0.028355932,
							featureValue: 0,
						},
						{
							conversationId: 9739,
							disconImpact: -0.037487965,
							featureValue: 0,
						},
						{
							conversationId: 9742,
							disconImpact: -0.21665722,
							featureValue: 0.010268010318842,
						},
						{
							conversationId: 9744,
							disconImpact: -0.026989555,
							featureValue: 0,
						},
						{
							conversationId: 9748,
							disconImpact: -0.02997202,
							featureValue: 0,
						},
						{
							conversationId: 9752,
							disconImpact: -0.03357735,
							featureValue: 0,
						},
						{
							conversationId: 9756,
							disconImpact: -0.030097846,
							featureValue: 0,
						},
						{
							conversationId: 9762,
							disconImpact: -0.028223086,
							featureValue: 0,
						},
						{
							conversationId: 9764,
							disconImpact: -0.022218496,
							featureValue: 0,
						},
						{
							conversationId: 9768,
							disconImpact: -0.033889122,
							featureValue: 0,
						},
						{
							conversationId: 9771,
							disconImpact: -0.03435047,
							featureValue: 0,
						},
						{
							conversationId: 9775,
							disconImpact: -0.029763188,
							featureValue: 0,
						},
						{
							conversationId: 9777,
							disconImpact: -0.026251784,
							featureValue: 0,
						},
						{
							conversationId: 9784,
							disconImpact: -0.02856845,
							featureValue: 0,
						},
						{
							conversationId: 9785,
							disconImpact: -0.026667725,
							featureValue: 0,
						},
						{
							conversationId: 9787,
							disconImpact: -0.03872566,
							featureValue: 0,
						},
						{
							conversationId: 9788,
							disconImpact: -0.02825487,
							featureValue: 0,
						},
						{
							conversationId: 9792,
							disconImpact: -0.028442264,
							featureValue: 0,
						},
						{
							conversationId: 9796,
							disconImpact: -0.03133639,
							featureValue: 0,
						},
						{
							conversationId: 9798,
							disconImpact: -0.035085898,
							featureValue: 0,
						},
						{
							conversationId: 9799,
							disconImpact: -0.03268522,
							featureValue: 0,
						},
						{
							conversationId: 9802,
							disconImpact: 0.66599387,
							featureValue: 0.0322119224981888,
						},
						{
							conversationId: 9807,
							disconImpact: -0.033923153,
							featureValue: 0,
						},
						{
							conversationId: 9809,
							disconImpact: -0.040600948,
							featureValue: 0,
						},
						{
							conversationId: 9821,
							disconImpact: -0.032416556,
							featureValue: 0,
						},
						{
							conversationId: 9826,
							disconImpact: -0.04014083,
							featureValue: 0,
						},
						{
							conversationId: 9828,
							disconImpact: -0.032835778,
							featureValue: 0,
						},
						{
							conversationId: 9830,
							disconImpact: -0.03693217,
							featureValue: 0,
						},
						{
							conversationId: 9838,
							disconImpact: -0.031711478,
							featureValue: 0,
						},
						{
							conversationId: 9839,
							disconImpact: -0.01850307,
							featureValue: 0,
						},
						{
							conversationId: 9840,
							disconImpact: -0.03027301,
							featureValue: 0,
						},
						{
							conversationId: 9848,
							disconImpact: -0.036217418,
							featureValue: 0,
						},
						{
							conversationId: 9857,
							disconImpact: -0.026453376,
							featureValue: 0,
						},
						{
							conversationId: 9859,
							disconImpact: -0.13357565,
							featureValue: 0.0145321934357507,
						},
						{
							conversationId: 9860,
							disconImpact: -0.031246442,
							featureValue: 0,
						},
						{
							conversationId: 9864,
							disconImpact: -0.028679691,
							featureValue: 0,
						},
						{
							conversationId: 9865,
							disconImpact: -0.040450186,
							featureValue: 0,
						},
						{
							conversationId: 9876,
							disconImpact: -0.024220262,
							featureValue: 0,
						},
						{
							conversationId: 9879,
							disconImpact: -0.027675806,
							featureValue: 0,
						},
						{
							conversationId: 9882,
							disconImpact: -0.022957232,
							featureValue: 0,
						},
						{
							conversationId: 9883,
							disconImpact: -0.02461506,
							featureValue: 0,
						},
						{
							conversationId: 9896,
							disconImpact: -0.028411936,
							featureValue: 0,
						},
						{
							conversationId: 9899,
							disconImpact: -0.03132729,
							featureValue: 0,
						},
						{
							conversationId: 9901,
							disconImpact: -0.028643552,
							featureValue: 0,
						},
						{
							conversationId: 9906,
							disconImpact: -0.029687941,
							featureValue: 0,
						},
						{
							conversationId: 9912,
							disconImpact: -0.023991724,
							featureValue: 0,
						},
						{
							conversationId: 9914,
							disconImpact: -0.13995828,
							featureValue: 0.0121472942927755,
						},
						{
							conversationId: 9918,
							disconImpact: -0.038244415,
							featureValue: 0,
						},
						{
							conversationId: 9919,
							disconImpact: -0.031828213,
							featureValue: 0,
						},
						{
							conversationId: 9922,
							disconImpact: -0.037690002,
							featureValue: 0,
						},
						{
							conversationId: 9923,
							disconImpact: -0.028019924,
							featureValue: 0.0061544585377802,
						},
						{
							conversationId: 9924,
							disconImpact: -0.02548617,
							featureValue: 0,
						},
						{
							conversationId: 9927,
							disconImpact: -0.03096578,
							featureValue: 0,
						},
						{
							conversationId: 9930,
							disconImpact: -0.23126353,
							featureValue: 0.0104307692307691,
						},
						{
							conversationId: 9932,
							disconImpact: -0.033101,
							featureValue: 0,
						},
						{
							conversationId: 9933,
							disconImpact: -0.034243334,
							featureValue: 0,
						},
						{
							conversationId: 9938,
							disconImpact: -0.020100338,
							featureValue: 0,
						},
						{
							conversationId: 9940,
							disconImpact: -0.022873495,
							featureValue: 0,
						},
						{
							conversationId: 9942,
							disconImpact: -0.033316996,
							featureValue: 0,
						},
						{
							conversationId: 9945,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 9947,
							disconImpact: -0.033598784,
							featureValue: 0,
						},
						{
							conversationId: 9950,
							disconImpact: -0.035499737,
							featureValue: 0,
						},
						{
							conversationId: 9952,
							disconImpact: -0.028307617,
							featureValue: 0,
						},
						{
							conversationId: 9963,
							disconImpact: -0.024384405,
							featureValue: 0,
						},
						{
							conversationId: 9966,
							disconImpact: -0.026129298,
							featureValue: 0,
						},
						{
							conversationId: 9978,
							disconImpact: -0.032511443,
							featureValue: 0,
						},
						{
							conversationId: 9981,
							disconImpact: -0.043330323,
							featureValue: 0,
						},
						{
							conversationId: 9990,
							disconImpact: -0.034074694,
							featureValue: 0,
						},
						{
							conversationId: 9996,
							disconImpact: -0.027571052,
							featureValue: 0,
						},
						{
							conversationId: 9999,
							disconImpact: -0.022754293,
							featureValue: 0,
						},
						{
							conversationId: 10015,
							disconImpact: -0.030535374,
							featureValue: 0,
						},
						{
							conversationId: 10016,
							disconImpact: -0.02158343,
							featureValue: 0,
						},
						{
							conversationId: 10018,
							disconImpact: -0.03303589,
							featureValue: 0,
						},
						{
							conversationId: 10021,
							disconImpact: -0.029002395,
							featureValue: 0,
						},
						{
							conversationId: 10024,
							disconImpact: -0.02819308,
							featureValue: 0,
						},
						{
							conversationId: 10031,
							disconImpact: -0.0275154,
							featureValue: 0,
						},
						{
							conversationId: 10038,
							disconImpact: -0.03414034,
							featureValue: 0,
						},
						{
							conversationId: 10039,
							disconImpact: -0.030048184,
							featureValue: 0,
						},
						{
							conversationId: 10041,
							disconImpact: -0.029862246,
							featureValue: 0,
						},
						{
							conversationId: 10042,
							disconImpact: -0.024572715,
							featureValue: 0,
						},
						{
							conversationId: 10043,
							disconImpact: 1.3182815,
							featureValue: 0.0943308085021189,
						},
						{
							conversationId: 10044,
							disconImpact: -0.03257173,
							featureValue: 0,
						},
						{
							conversationId: 10046,
							disconImpact: -0.03449445,
							featureValue: 0,
						},
						{
							conversationId: 10050,
							disconImpact: -0.035183985,
							featureValue: 0,
						},
						{
							conversationId: 10058,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 10059,
							disconImpact: -0.032888476,
							featureValue: 0,
						},
						{
							conversationId: 10067,
							disconImpact: -0.021742994,
							featureValue: 0,
						},
						{
							conversationId: 10068,
							disconImpact: -0.03357735,
							featureValue: 0,
						},
						{
							conversationId: 10069,
							disconImpact: -0.02835079,
							featureValue: 0,
						},
						{
							conversationId: 10071,
							disconImpact: -0.023553435,
							featureValue: 0,
						},
						{
							conversationId: 10078,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 10079,
							disconImpact: -0.024647254,
							featureValue: 0,
						},
						{
							conversationId: 10080,
							disconImpact: -0.04008955,
							featureValue: 0,
						},
						{
							conversationId: 10083,
							disconImpact: -0.027066689,
							featureValue: 0,
						},
						{
							conversationId: 10085,
							disconImpact: -0.028154284,
							featureValue: 0,
						},
						{
							conversationId: 10089,
							disconImpact: -0.04418846,
							featureValue: 0.0058804118826625,
						},
						{
							conversationId: 10094,
							disconImpact: -0.034906294,
							featureValue: 0,
						},
						{
							conversationId: 10095,
							disconImpact: -0.035402052,
							featureValue: 0,
						},
						{
							conversationId: 10096,
							disconImpact: -0.03155767,
							featureValue: 0,
						},
						{
							conversationId: 10106,
							disconImpact: -0.04208066,
							featureValue: 0,
						},
						{
							conversationId: 10109,
							disconImpact: -0.032230347,
							featureValue: 0,
						},
						{
							conversationId: 10113,
							disconImpact: -0.031626668,
							featureValue: 0,
						},
						{
							conversationId: 10117,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 10120,
							disconImpact: -0.035969462,
							featureValue: 0,
						},
						{
							conversationId: 10121,
							disconImpact: -0.0284935,
							featureValue: 0,
						},
						{
							conversationId: 10124,
							disconImpact: -0.028014552,
							featureValue: 0,
						},
						{
							conversationId: 10130,
							disconImpact: -0.024483513,
							featureValue: 0,
						},
						{
							conversationId: 10131,
							disconImpact: -0.028190723,
							featureValue: 0,
						},
						{
							conversationId: 10132,
							disconImpact: -0.033045102,
							featureValue: 0,
						},
						{
							conversationId: 10133,
							disconImpact: -0.020849975,
							featureValue: 0,
						},
						{
							conversationId: 10134,
							disconImpact: -0.027884671,
							featureValue: 0,
						},
						{
							conversationId: 10138,
							disconImpact: -0.028144125,
							featureValue: 0,
						},
						{
							conversationId: 10140,
							disconImpact: -0.026200417,
							featureValue: 0,
						},
						{
							conversationId: 10144,
							disconImpact: -0.03331937,
							featureValue: 0,
						},
						{
							conversationId: 10147,
							disconImpact: -0.033396307,
							featureValue: 0,
						},
						{
							conversationId: 10150,
							disconImpact: -0.037430983,
							featureValue: 0,
						},
						{
							conversationId: 10167,
							disconImpact: -0.027922982,
							featureValue: 0,
						},
						{
							conversationId: 10169,
							disconImpact: -0.032501984,
							featureValue: 0,
						},
						{
							conversationId: 10172,
							disconImpact: -0.03143997,
							featureValue: 0,
						},
						{
							conversationId: 10173,
							disconImpact: -0.03404727,
							featureValue: 0,
						},
						{
							conversationId: 10180,
							disconImpact: -0.023195231,
							featureValue: 0,
						},
						{
							conversationId: 10181,
							disconImpact: -0.029100666,
							featureValue: 0,
						},
						{
							conversationId: 10182,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 10184,
							disconImpact: -0.03281214,
							featureValue: 0,
						},
						{
							conversationId: 10186,
							disconImpact: -0.039923612,
							featureValue: 0,
						},
						{
							conversationId: 10187,
							disconImpact: -0.030977933,
							featureValue: 0,
						},
						{
							conversationId: 10189,
							disconImpact: -0.028959677,
							featureValue: 0,
						},
						{
							conversationId: 10190,
							disconImpact: -0.036141384,
							featureValue: 0,
						},
						{
							conversationId: 10192,
							disconImpact: -0.033988252,
							featureValue: 0,
						},
						{
							conversationId: 10196,
							disconImpact: -0.026986664,
							featureValue: 0,
						},
						{
							conversationId: 10199,
							disconImpact: -0.012623511,
							featureValue: 0,
						},
						{
							conversationId: 10200,
							disconImpact: -0.02733564,
							featureValue: 0,
						},
						{
							conversationId: 10202,
							disconImpact: -0.02060927,
							featureValue: 0,
						},
						{
							conversationId: 10205,
							disconImpact: -0.03529253,
							featureValue: 0,
						},
						{
							conversationId: 10206,
							disconImpact: -0.034944735,
							featureValue: 0,
						},
						{
							conversationId: 10207,
							disconImpact: -0.023117267,
							featureValue: 0,
						},
						{
							conversationId: 10214,
							disconImpact: -0.033267464,
							featureValue: 0,
						},
						{
							conversationId: 10224,
							disconImpact: -0.033472046,
							featureValue: 0,
						},
						{
							conversationId: 10226,
							disconImpact: -0.039843254,
							featureValue: 0,
						},
						{
							conversationId: 10228,
							disconImpact: -0.025136387,
							featureValue: 0,
						},
						{
							conversationId: 10235,
							disconImpact: -0.02671189,
							featureValue: 0,
						},
						{
							conversationId: 10236,
							disconImpact: -0.02779032,
							featureValue: 0,
						},
						{
							conversationId: 10238,
							disconImpact: -0.03002271,
							featureValue: 0,
						},
						{
							conversationId: 10240,
							disconImpact: -0.031531826,
							featureValue: 0,
						},
						{
							conversationId: 10242,
							disconImpact: -0.030535646,
							featureValue: 0,
						},
						{
							conversationId: 10249,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 10250,
							disconImpact: -0.026487546,
							featureValue: 0,
						},
						{
							conversationId: 10254,
							disconImpact: -0.031978708,
							featureValue: 0,
						},
						{
							conversationId: 10255,
							disconImpact: -0.025627892,
							featureValue: 0,
						},
						{
							conversationId: 10259,
							disconImpact: -0.03624497,
							featureValue: 0,
						},
						{
							conversationId: 10262,
							disconImpact: -0.022971146,
							featureValue: 0,
						},
						{
							conversationId: 10265,
							disconImpact: -0.02697935,
							featureValue: 0,
						},
						{
							conversationId: 10268,
							disconImpact: -0.031065255,
							featureValue: 0,
						},
						{
							conversationId: 10269,
							disconImpact: -0.027257282,
							featureValue: 0,
						},
						{
							conversationId: 10276,
							disconImpact: -0.032974128,
							featureValue: 0,
						},
						{
							conversationId: 10286,
							disconImpact: -0.03354094,
							featureValue: 0,
						},
						{
							conversationId: 10289,
							disconImpact: -0.03631658,
							featureValue: 0,
						},
						{
							conversationId: 10293,
							disconImpact: -0.032154635,
							featureValue: 0,
						},
						{
							conversationId: 10297,
							disconImpact: -0.03798345,
							featureValue: 0,
						},
						{
							conversationId: 10299,
							disconImpact: -0.031327322,
							featureValue: 0,
						},
						{
							conversationId: 10302,
							disconImpact: 0.21075326,
							featureValue: 0.0295383361768226,
						},
						{
							conversationId: 10308,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 10309,
							disconImpact: -0.03281861,
							featureValue: 0,
						},
						{
							conversationId: 10312,
							disconImpact: -0.0422413,
							featureValue: 0,
						},
						{
							conversationId: 10314,
							disconImpact: -0.03284665,
							featureValue: 0,
						},
						{
							conversationId: 10315,
							disconImpact: 0.045265332,
							featureValue: 0.0126229251136847,
						},
						{
							conversationId: 10318,
							disconImpact: -0.0375658,
							featureValue: 0,
						},
						{
							conversationId: 10319,
							disconImpact: -0.030090818,
							featureValue: 0,
						},
						{
							conversationId: 10320,
							disconImpact: -0.026642352,
							featureValue: 0,
						},
						{
							conversationId: 10325,
							disconImpact: -0.027061682,
							featureValue: 0,
						},
						{
							conversationId: 10330,
							disconImpact: -0.03224383,
							featureValue: 0,
						},
						{
							conversationId: 10333,
							disconImpact: -0.024729311,
							featureValue: 0,
						},
						{
							conversationId: 10336,
							disconImpact: -0.029111762,
							featureValue: 0,
						},
						{
							conversationId: 10338,
							disconImpact: -0.02931704,
							featureValue: 0,
						},
						{
							conversationId: 10339,
							disconImpact: -0.0354092,
							featureValue: 0,
						},
						{
							conversationId: 10344,
							disconImpact: -0.033889122,
							featureValue: 0,
						},
						{
							conversationId: 10347,
							disconImpact: -0.031073146,
							featureValue: 0,
						},
						{
							conversationId: 10358,
							disconImpact: -0.013806224,
							featureValue: 0,
						},
						{
							conversationId: 10360,
							disconImpact: -0.02649602,
							featureValue: 0,
						},
						{
							conversationId: 10361,
							disconImpact: -0.023081396,
							featureValue: 0,
						},
						{
							conversationId: 10372,
							disconImpact: -0.02824558,
							featureValue: 0,
						},
						{
							conversationId: 10373,
							disconImpact: -0.027380936,
							featureValue: 0,
						},
						{
							conversationId: 10379,
							disconImpact: -0.027227532,
							featureValue: 0,
						},
						{
							conversationId: 10380,
							disconImpact: -0.035495125,
							featureValue: 0,
						},
						{
							conversationId: 10384,
							disconImpact: -0.044552322,
							featureValue: 0,
						},
						{
							conversationId: 10386,
							disconImpact: -0.038082242,
							featureValue: 0,
						},
						{
							conversationId: 10388,
							disconImpact: -0.021987263,
							featureValue: 0,
						},
						{
							conversationId: 10394,
							disconImpact: -0.026432287,
							featureValue: 0,
						},
						{
							conversationId: 10395,
							disconImpact: -0.03572395,
							featureValue: 0,
						},
						{
							conversationId: 10398,
							disconImpact: -0.03237975,
							featureValue: 0,
						},
						{
							conversationId: 10407,
							disconImpact: -0.034141496,
							featureValue: 0,
						},
						{
							conversationId: 10420,
							disconImpact: -0.023477446,
							featureValue: 0,
						},
						{
							conversationId: 10425,
							disconImpact: -0.04688809,
							featureValue: 0,
						},
						{
							conversationId: 10426,
							disconImpact: -0.021894034,
							featureValue: 0,
						},
						{
							conversationId: 10427,
							disconImpact: -0.024992026,
							featureValue: 0,
						},
						{
							conversationId: 10428,
							disconImpact: -0.023686448,
							featureValue: 0,
						},
						{
							conversationId: 10429,
							disconImpact: -0.020876827,
							featureValue: 0,
						},
						{
							conversationId: 10432,
							disconImpact: -0.027396314,
							featureValue: 0,
						},
						{
							conversationId: 10433,
							disconImpact: 0.5121136,
							featureValue: 0.0399737757462073,
						},
						{
							conversationId: 10438,
							disconImpact: -0.03151932,
							featureValue: 0,
						},
						{
							conversationId: 10439,
							disconImpact: -0.02546486,
							featureValue: 0,
						},
						{
							conversationId: 10445,
							disconImpact: -0.033247635,
							featureValue: 0,
						},
						{
							conversationId: 10447,
							disconImpact: -0.026578262,
							featureValue: 0,
						},
						{
							conversationId: 10451,
							disconImpact: -0.034248088,
							featureValue: 0,
						},
						{
							conversationId: 10452,
							disconImpact: -0.033316996,
							featureValue: 0,
						},
						{
							conversationId: 10455,
							disconImpact: -0.0375658,
							featureValue: 0,
						},
						{
							conversationId: 10460,
							disconImpact: -0.03511314,
							featureValue: 0.0057619485657206,
						},
						{
							conversationId: 10465,
							disconImpact: -0.030911244,
							featureValue: 0,
						},
						{
							conversationId: 10466,
							disconImpact: -0.023304308,
							featureValue: 0,
						},
						{
							conversationId: 10467,
							disconImpact: -0.03527386,
							featureValue: 0,
						},
						{
							conversationId: 10470,
							disconImpact: -0.037684575,
							featureValue: 0,
						},
						{
							conversationId: 10471,
							disconImpact: -0.028740644,
							featureValue: 0,
						},
						{
							conversationId: 10472,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 10474,
							disconImpact: 0.5014762,
							featureValue: 0.0354210064906339,
						},
						{
							conversationId: 10478,
							disconImpact: -0.032059725,
							featureValue: 0,
						},
						{
							conversationId: 10480,
							disconImpact: -0.03360542,
							featureValue: 0,
						},
						{
							conversationId: 10481,
							disconImpact: -0.030826803,
							featureValue: 0,
						},
						{
							conversationId: 10486,
							disconImpact: -0.032924462,
							featureValue: 0,
						},
						{
							conversationId: 10487,
							disconImpact: -0.03111023,
							featureValue: 0,
						},
						{
							conversationId: 10495,
							disconImpact: -0.03383087,
							featureValue: 0,
						},
						{
							conversationId: 10503,
							disconImpact: -0.033051286,
							featureValue: 0,
						},
						{
							conversationId: 10507,
							disconImpact: -0.03746987,
							featureValue: 0,
						},
						{
							conversationId: 10511,
							disconImpact: -0.01936367,
							featureValue: 0,
						},
						{
							conversationId: 10523,
							disconImpact: -0.029518146,
							featureValue: 0,
						},
						{
							conversationId: 10528,
							disconImpact: -0.033396307,
							featureValue: 0,
						},
						{
							conversationId: 10530,
							disconImpact: -0.033992484,
							featureValue: 0,
						},
						{
							conversationId: 10534,
							disconImpact: -0.028085066,
							featureValue: 0,
						},
						{
							conversationId: 10535,
							disconImpact: -0.035672244,
							featureValue: 0,
						},
						{
							conversationId: 10537,
							disconImpact: -0.025547212,
							featureValue: 0,
						},
						{
							conversationId: 10538,
							disconImpact: -0.03965044,
							featureValue: 0,
						},
						{
							conversationId: 10550,
							disconImpact: -0.027794622,
							featureValue: 0,
						},
						{
							conversationId: 10551,
							disconImpact: -0.03538479,
							featureValue: 0,
						},
						{
							conversationId: 10552,
							disconImpact: -0.03774364,
							featureValue: 0,
						},
						{
							conversationId: 10558,
							disconImpact: -0.03793876,
							featureValue: 0,
						},
						{
							conversationId: 10559,
							disconImpact: -0.038563665,
							featureValue: 0,
						},
						{
							conversationId: 10561,
							disconImpact: -0.032786284,
							featureValue: 0,
						},
						{
							conversationId: 10563,
							disconImpact: -0.03359439,
							featureValue: 0,
						},
						{
							conversationId: 10564,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 10568,
							disconImpact: -0.028593112,
							featureValue: 0,
						},
						{
							conversationId: 10588,
							disconImpact: -0.026137946,
							featureValue: 0,
						},
						{
							conversationId: 10592,
							disconImpact: -0.026516594,
							featureValue: 0,
						},
						{
							conversationId: 10595,
							disconImpact: -0.025061604,
							featureValue: 0,
						},
						{
							conversationId: 10597,
							disconImpact: 0.1568294,
							featureValue: 0.0209739799070875,
						},
						{
							conversationId: 10598,
							disconImpact: -0.033050995,
							featureValue: 0,
						},
						{
							conversationId: 10606,
							disconImpact: -0.028827345,
							featureValue: 0,
						},
						{
							conversationId: 10609,
							disconImpact: -0.03046591,
							featureValue: 0,
						},
						{
							conversationId: 10611,
							disconImpact: -0.016524088,
							featureValue: 0,
						},
						{
							conversationId: 10614,
							disconImpact: -0.032495733,
							featureValue: 0,
						},
						{
							conversationId: 10618,
							disconImpact: -0.035203684,
							featureValue: 0,
						},
						{
							conversationId: 10621,
							disconImpact: -0.017876064,
							featureValue: 0,
						},
						{
							conversationId: 10622,
							disconImpact: -0.033636935,
							featureValue: 0,
						},
						{
							conversationId: 10624,
							disconImpact: -0.036208607,
							featureValue: 0,
						},
						{
							conversationId: 10627,
							disconImpact: -0.028759848,
							featureValue: 0,
						},
						{
							conversationId: 10632,
							disconImpact: -0.029696185,
							featureValue: 0,
						},
						{
							conversationId: 10635,
							disconImpact: -0.0374994,
							featureValue: 0,
						},
						{
							conversationId: 10641,
							disconImpact: -0.030748997,
							featureValue: 0,
						},
						{
							conversationId: 10646,
							disconImpact: -0.027209323,
							featureValue: 0,
						},
						{
							conversationId: 10647,
							disconImpact: -0.031430017,
							featureValue: 0,
						},
						{
							conversationId: 10648,
							disconImpact: -0.02276212,
							featureValue: 0,
						},
						{
							conversationId: 10650,
							disconImpact: -0.035069678,
							featureValue: 0,
						},
						{
							conversationId: 10651,
							disconImpact: -0.022369362,
							featureValue: 0,
						},
						{
							conversationId: 10667,
							disconImpact: -0.031805817,
							featureValue: 0,
						},
						{
							conversationId: 10670,
							disconImpact: -0.019701444,
							featureValue: 0,
						},
						{
							conversationId: 10675,
							disconImpact: -0.030932996,
							featureValue: 0,
						},
						{
							conversationId: 10676,
							disconImpact: 1.2854376,
							featureValue: 0.0645226588396042,
						},
						{
							conversationId: 10677,
							disconImpact: -0.03336688,
							featureValue: 0,
						},
						{
							conversationId: 10679,
							disconImpact: -0.03377615,
							featureValue: 0,
						},
						{
							conversationId: 10683,
							disconImpact: -0.033728715,
							featureValue: 0,
						},
						{
							conversationId: 10686,
							disconImpact: -0.016234674,
							featureValue: 0,
						},
						{
							conversationId: 10687,
							disconImpact: -0.030124528,
							featureValue: 0,
						},
						{
							conversationId: 10691,
							disconImpact: -0.04152515,
							featureValue: 0,
						},
						{
							conversationId: 10692,
							disconImpact: -0.021453785,
							featureValue: 0,
						},
						{
							conversationId: 10695,
							disconImpact: -0.035800703,
							featureValue: 0,
						},
						{
							conversationId: 10700,
							disconImpact: -0.021883093,
							featureValue: 0,
						},
						{
							conversationId: 10705,
							disconImpact: -0.023612928,
							featureValue: 0,
						},
						{
							conversationId: 10707,
							disconImpact: -0.04118619,
							featureValue: 0,
						},
						{
							conversationId: 10709,
							disconImpact: -0.02302251,
							featureValue: 0,
						},
						{
							conversationId: 10713,
							disconImpact: -0.035609104,
							featureValue: 0,
						},
						{
							conversationId: 10714,
							disconImpact: -0.02199734,
							featureValue: 0,
						},
						{
							conversationId: 10717,
							disconImpact: -0.032810252,
							featureValue: 0,
						},
						{
							conversationId: 10721,
							disconImpact: -0.0241494,
							featureValue: 0,
						},
						{
							conversationId: 10723,
							disconImpact: -0.030529413,
							featureValue: 0,
						},
						{
							conversationId: 10725,
							disconImpact: -0.030298581,
							featureValue: 0,
						},
						{
							conversationId: 10730,
							disconImpact: -0.026655577,
							featureValue: 0,
						},
						{
							conversationId: 10732,
							disconImpact: 1.1044298,
							featureValue: 0.0693836978131211,
						},
						{
							conversationId: 10733,
							disconImpact: -0.029025534,
							featureValue: 0,
						},
						{
							conversationId: 10739,
							disconImpact: -0.029893465,
							featureValue: 0,
						},
						{
							conversationId: 10746,
							disconImpact: -0.034550853,
							featureValue: 0,
						},
						{
							conversationId: 10747,
							disconImpact: -0.035707418,
							featureValue: 0,
						},
						{
							conversationId: 10749,
							disconImpact: -0.020370055,
							featureValue: 0,
						},
						{
							conversationId: 10752,
							disconImpact: -0.029396169,
							featureValue: 0,
						},
						{
							conversationId: 10754,
							disconImpact: -0.03064197,
							featureValue: 0,
						},
						{
							conversationId: 10757,
							disconImpact: -0.03252297,
							featureValue: 0,
						},
						{
							conversationId: 10758,
							disconImpact: 0.31840977,
							featureValue: 0.0296651337521862,
						},
						{
							conversationId: 10760,
							disconImpact: -0.03226772,
							featureValue: 0,
						},
						{
							conversationId: 10770,
							disconImpact: -0.033396307,
							featureValue: 0,
						},
						{
							conversationId: 10771,
							disconImpact: -0.030656924,
							featureValue: 0,
						},
						{
							conversationId: 10784,
							disconImpact: -0.033415467,
							featureValue: 0,
						},
						{
							conversationId: 10787,
							disconImpact: -0.028237017,
							featureValue: 0,
						},
						{
							conversationId: 10789,
							disconImpact: -0.028952982,
							featureValue: 0,
						},
						{
							conversationId: 10790,
							disconImpact: -0.030097406,
							featureValue: 0,
						},
						{
							conversationId: 10792,
							disconImpact: -0.02752816,
							featureValue: 0,
						},
						{
							conversationId: 10794,
							disconImpact: -0.035146676,
							featureValue: 0,
						},
						{
							conversationId: 10795,
							disconImpact: -0.030437296,
							featureValue: 0,
						},
						{
							conversationId: 10799,
							disconImpact: -0.02466648,
							featureValue: 0,
						},
						{
							conversationId: 10800,
							disconImpact: -0.031652022,
							featureValue: 0,
						},
						{
							conversationId: 10802,
							disconImpact: -0.0402866,
							featureValue: 0,
						},
						{
							conversationId: 10803,
							disconImpact: -0.024515081,
							featureValue: 0,
						},
						{
							conversationId: 10811,
							disconImpact: -0.03378337,
							featureValue: 0,
						},
						{
							conversationId: 10815,
							disconImpact: -0.031065255,
							featureValue: 0,
						},
						{
							conversationId: 10818,
							disconImpact: -0.025591528,
							featureValue: 0,
						},
						{
							conversationId: 10820,
							disconImpact: -0.023452917,
							featureValue: 0,
						},
						{
							conversationId: 10824,
							disconImpact: -0.026598375,
							featureValue: 0,
						},
						{
							conversationId: 10826,
							disconImpact: -0.035069678,
							featureValue: 0,
						},
						{
							conversationId: 10828,
							disconImpact: -0.026908327,
							featureValue: 0,
						},
						{
							conversationId: 10834,
							disconImpact: -0.03419759,
							featureValue: 0,
						},
						{
							conversationId: 10836,
							disconImpact: -0.016273415,
							featureValue: 0,
						},
						{
							conversationId: 10837,
							disconImpact: -0.030090861,
							featureValue: 0,
						},
						{
							conversationId: 10846,
							disconImpact: -0.02928396,
							featureValue: 0,
						},
						{
							conversationId: 10848,
							disconImpact: -0.027940335,
							featureValue: 0,
						},
						{
							conversationId: 10854,
							disconImpact: -0.028204322,
							featureValue: 0,
						},
						{
							conversationId: 10856,
							disconImpact: -0.027662694,
							featureValue: 0,
						},
						{
							conversationId: 10857,
							disconImpact: -0.02997157,
							featureValue: 0,
						},
						{
							conversationId: 10859,
							disconImpact: -0.030036025,
							featureValue: 0,
						},
						{
							conversationId: 10863,
							disconImpact: -0.027352493,
							featureValue: 0,
						},
						{
							conversationId: 10868,
							disconImpact: -0.031067187,
							featureValue: 0,
						},
						{
							conversationId: 10872,
							disconImpact: -0.030111074,
							featureValue: 0,
						},
						{
							conversationId: 10874,
							disconImpact: -0.035033423,
							featureValue: 0,
						},
						{
							conversationId: 10877,
							disconImpact: -0.015640892,
							featureValue: 0,
						},
						{
							conversationId: 10878,
							disconImpact: -0.03222585,
							featureValue: 0,
						},
						{
							conversationId: 10879,
							disconImpact: -0.030440986,
							featureValue: 0,
						},
						{
							conversationId: 10881,
							disconImpact: -0.03046905,
							featureValue: 0,
						},
						{
							conversationId: 10882,
							disconImpact: -0.026907802,
							featureValue: 0,
						},
						{
							conversationId: 10886,
							disconImpact: -0.038477127,
							featureValue: 0,
						},
						{
							conversationId: 10887,
							disconImpact: -0.028504591,
							featureValue: 0,
						},
						{
							conversationId: 10889,
							disconImpact: -0.02397402,
							featureValue: 0,
						},
						{
							conversationId: 10895,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 10898,
							disconImpact: 0.012675058,
							featureValue: 0.0186591978719051,
						},
						{
							conversationId: 10900,
							disconImpact: -0.034438245,
							featureValue: 0,
						},
						{
							conversationId: 10913,
							disconImpact: 0.925291,
							featureValue: 0.0841421672058892,
						},
						{
							conversationId: 10918,
							disconImpact: -0.03116471,
							featureValue: 0,
						},
						{
							conversationId: 10922,
							disconImpact: -0.033403166,
							featureValue: 0,
						},
						{
							conversationId: 10925,
							disconImpact: -0.033205133,
							featureValue: 0,
						},
						{
							conversationId: 10928,
							disconImpact: -0.035994824,
							featureValue: 0,
						},
						{
							conversationId: 10929,
							disconImpact: -0.028537396,
							featureValue: 0,
						},
						{
							conversationId: 10932,
							disconImpact: -0.037799377,
							featureValue: 0,
						},
						{
							conversationId: 10934,
							disconImpact: -0.024628509,
							featureValue: 0,
						},
						{
							conversationId: 10950,
							disconImpact: -0.03701558,
							featureValue: 0,
						},
						{
							conversationId: 10951,
							disconImpact: -0.026983136,
							featureValue: 0,
						},
						{
							conversationId: 10954,
							disconImpact: -0.032396108,
							featureValue: 0,
						},
						{
							conversationId: 10955,
							disconImpact: -0.025389303,
							featureValue: 0,
						},
						{
							conversationId: 10957,
							disconImpact: -0.022404503,
							featureValue: 0,
						},
						{
							conversationId: 10963,
							disconImpact: -0.037715483,
							featureValue: 0,
						},
						{
							conversationId: 10966,
							disconImpact: -0.023015466,
							featureValue: 0,
						},
						{
							conversationId: 10968,
							disconImpact: -0.034141496,
							featureValue: 0,
						},
						{
							conversationId: 10970,
							disconImpact: -0.021394627,
							featureValue: 0,
						},
						{
							conversationId: 10971,
							disconImpact: -0.038080264,
							featureValue: 0,
						},
						{
							conversationId: 10973,
							disconImpact: -0.03606393,
							featureValue: 0,
						},
						{
							conversationId: 10983,
							disconImpact: -0.028212994,
							featureValue: 0,
						},
						{
							conversationId: 10984,
							disconImpact: -0.09034377,
							featureValue: 0.0118291375839802,
						},
						{
							conversationId: 10992,
							disconImpact: -0.03463967,
							featureValue: 0,
						},
						{
							conversationId: 11001,
							disconImpact: -0.029905679,
							featureValue: 0,
						},
						{
							conversationId: 11002,
							disconImpact: -0.034649227,
							featureValue: 0,
						},
						{
							conversationId: 11003,
							disconImpact: -0.027171671,
							featureValue: 0,
						},
						{
							conversationId: 11013,
							disconImpact: -0.02745871,
							featureValue: 0,
						},
						{
							conversationId: 11025,
							disconImpact: -0.030643238,
							featureValue: 0,
						},
						{
							conversationId: 11026,
							disconImpact: -0.033146616,
							featureValue: 0,
						},
						{
							conversationId: 11027,
							disconImpact: -0.031304073,
							featureValue: 0,
						},
						{
							conversationId: 11029,
							disconImpact: -0.04094521,
							featureValue: 0,
						},
						{
							conversationId: 11038,
							disconImpact: -0.026765035,
							featureValue: 0,
						},
						{
							conversationId: 11042,
							disconImpact: -0.0342934,
							featureValue: 0,
						},
						{
							conversationId: 11043,
							disconImpact: -0.039719757,
							featureValue: 0,
						},
						{
							conversationId: 11045,
							disconImpact: -0.028293116,
							featureValue: 0,
						},
						{
							conversationId: 11046,
							disconImpact: -0.029749062,
							featureValue: 0,
						},
						{
							conversationId: 11047,
							disconImpact: -0.021833729,
							featureValue: 0,
						},
						{
							conversationId: 11049,
							disconImpact: -0.026735859,
							featureValue: 0,
						},
						{
							conversationId: 11053,
							disconImpact: -0.030328482,
							featureValue: 0,
						},
						{
							conversationId: 11055,
							disconImpact: -0.027771425,
							featureValue: 0,
						},
						{
							conversationId: 11057,
							disconImpact: -0.026943428,
							featureValue: 0,
						},
						{
							conversationId: 11061,
							disconImpact: -0.022812016,
							featureValue: 0,
						},
						{
							conversationId: 11066,
							disconImpact: -0.033652637,
							featureValue: 0,
						},
						{
							conversationId: 11071,
							disconImpact: -0.038731463,
							featureValue: 0,
						},
						{
							conversationId: 11073,
							disconImpact: -0.032728434,
							featureValue: 0,
						},
						{
							conversationId: 11084,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 11085,
							disconImpact: -0.026405431,
							featureValue: 0,
						},
						{
							conversationId: 11087,
							disconImpact: -0.031016994,
							featureValue: 0,
						},
						{
							conversationId: 11088,
							disconImpact: -0.036983263,
							featureValue: 0,
						},
						{
							conversationId: 11090,
							disconImpact: -0.033902023,
							featureValue: 0,
						},
						{
							conversationId: 11094,
							disconImpact: -0.024033522,
							featureValue: 0,
						},
						{
							conversationId: 11098,
							disconImpact: -0.026133467,
							featureValue: 0,
						},
						{
							conversationId: 11099,
							disconImpact: -0.030220537,
							featureValue: 0,
						},
						{
							conversationId: 11104,
							disconImpact: -0.026505634,
							featureValue: 0,
						},
						{
							conversationId: 11105,
							disconImpact: -0.030076925,
							featureValue: 0,
						},
						{
							conversationId: 11106,
							disconImpact: -0.038457464,
							featureValue: 0,
						},
						{
							conversationId: 11111,
							disconImpact: -0.025663534,
							featureValue: 0,
						},
						{
							conversationId: 11125,
							disconImpact: -0.030535374,
							featureValue: 0,
						},
						{
							conversationId: 11130,
							disconImpact: -0.03763072,
							featureValue: 0,
						},
						{
							conversationId: 11137,
							disconImpact: -0.037000876,
							featureValue: 0,
						},
						{
							conversationId: 11139,
							disconImpact: -0.025646167,
							featureValue: 0,
						},
						{
							conversationId: 11140,
							disconImpact: -0.029866222,
							featureValue: 0,
						},
						{
							conversationId: 11142,
							disconImpact: -0.036351565,
							featureValue: 0,
						},
						{
							conversationId: 11143,
							disconImpact: -0.024455756,
							featureValue: 0,
						},
						{
							conversationId: 11144,
							disconImpact: -0.022780374,
							featureValue: 0,
						},
						{
							conversationId: 11152,
							disconImpact: -0.04517963,
							featureValue: 0,
						},
						{
							conversationId: 11159,
							disconImpact: -0.03180606,
							featureValue: 0,
						},
						{
							conversationId: 11163,
							disconImpact: -0.034934968,
							featureValue: 0,
						},
						{
							conversationId: 11173,
							disconImpact: -0.03388962,
							featureValue: 0,
						},
						{
							conversationId: 11176,
							disconImpact: -0.029477332,
							featureValue: 0,
						},
						{
							conversationId: 11177,
							disconImpact: -0.020090481,
							featureValue: 0,
						},
						{
							conversationId: 11179,
							disconImpact: -0.03773683,
							featureValue: 0,
						},
						{
							conversationId: 11185,
							disconImpact: -0.028720552,
							featureValue: 0,
						},
						{
							conversationId: 11187,
							disconImpact: -0.028713383,
							featureValue: 0,
						},
						{
							conversationId: 11196,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 11197,
							disconImpact: -0.024262145,
							featureValue: 0,
						},
						{
							conversationId: 11198,
							disconImpact: -0.0328609,
							featureValue: 0,
						},
						{
							conversationId: 11206,
							disconImpact: -0.030826803,
							featureValue: 0,
						},
						{
							conversationId: 11207,
							disconImpact: -0.03441622,
							featureValue: 0,
						},
						{
							conversationId: 11208,
							disconImpact: -0.027953729,
							featureValue: 0,
						},
						{
							conversationId: 11213,
							disconImpact: -0.029971223,
							featureValue: 0,
						},
						{
							conversationId: 11214,
							disconImpact: -0.03157626,
							featureValue: 0,
						},
						{
							conversationId: 11218,
							disconImpact: -0.028018087,
							featureValue: 0,
						},
						{
							conversationId: 11219,
							disconImpact: -0.033316996,
							featureValue: 0,
						},
						{
							conversationId: 11223,
							disconImpact: -0.019772504,
							featureValue: 0,
						},
						{
							conversationId: 11225,
							disconImpact: -0.03410068,
							featureValue: 0,
						},
						{
							conversationId: 11238,
							disconImpact: -0.037928697,
							featureValue: 0,
						},
						{
							conversationId: 11239,
							disconImpact: -0.024771169,
							featureValue: 0,
						},
						{
							conversationId: 11246,
							disconImpact: -0.041073352,
							featureValue: 0,
						},
						{
							conversationId: 11253,
							disconImpact: -0.028800964,
							featureValue: 0,
						},
						{
							conversationId: 11256,
							disconImpact: -0.026618673,
							featureValue: 0,
						},
						{
							conversationId: 11258,
							disconImpact: -0.035003733,
							featureValue: 0,
						},
						{
							conversationId: 11259,
							disconImpact: -0.03745039,
							featureValue: 0,
						},
						{
							conversationId: 11264,
							disconImpact: -0.034002576,
							featureValue: 0,
						},
						{
							conversationId: 11265,
							disconImpact: -0.03160191,
							featureValue: 0,
						},
						{
							conversationId: 11268,
							disconImpact: -0.032015707,
							featureValue: 0,
						},
						{
							conversationId: 11269,
							disconImpact: 0.08603838,
							featureValue: 0.0245681584033328,
						},
						{
							conversationId: 11270,
							disconImpact: -0.027905274,
							featureValue: 0,
						},
						{
							conversationId: 11283,
							disconImpact: -0.03914478,
							featureValue: 0,
						},
						{
							conversationId: 11286,
							disconImpact: -0.035127275,
							featureValue: 0,
						},
						{
							conversationId: 11287,
							disconImpact: -0.032789495,
							featureValue: 0,
						},
						{
							conversationId: 11299,
							disconImpact: -0.041741274,
							featureValue: 0,
						},
						{
							conversationId: 11303,
							disconImpact: -0.03531109,
							featureValue: 0,
						},
						{
							conversationId: 11304,
							disconImpact: -0.030663697,
							featureValue: 0,
						},
						{
							conversationId: 11306,
							disconImpact: -0.023492219,
							featureValue: 0,
						},
						{
							conversationId: 11309,
							disconImpact: -0.022300586,
							featureValue: 0,
						},
						{
							conversationId: 11316,
							disconImpact: -0.036917474,
							featureValue: 0,
						},
						{
							conversationId: 11322,
							disconImpact: -0.03575645,
							featureValue: 0,
						},
						{
							conversationId: 11326,
							disconImpact: -0.02502733,
							featureValue: 0,
						},
						{
							conversationId: 11328,
							disconImpact: -0.025954233,
							featureValue: 0,
						},
						{
							conversationId: 11332,
							disconImpact: -0.028703492,
							featureValue: 0,
						},
						{
							conversationId: 11338,
							disconImpact: -0.031464215,
							featureValue: 0,
						},
						{
							conversationId: 11347,
							disconImpact: -0.037908588,
							featureValue: 0,
						},
						{
							conversationId: 11348,
							disconImpact: -0.030725572,
							featureValue: 0,
						},
						{
							conversationId: 11349,
							disconImpact: -0.024055364,
							featureValue: 0,
						},
						{
							conversationId: 11352,
							disconImpact: -0.03385514,
							featureValue: 0,
						},
						{
							conversationId: 11357,
							disconImpact: -0.033396307,
							featureValue: 0,
						},
						{
							conversationId: 11361,
							disconImpact: -0.0300054,
							featureValue: 0,
						},
						{
							conversationId: 11366,
							disconImpact: -0.029736016,
							featureValue: 0,
						},
						{
							conversationId: 11370,
							disconImpact: -0.038883068,
							featureValue: 0,
						},
						{
							conversationId: 11371,
							disconImpact: -0.036406733,
							featureValue: 0,
						},
						{
							conversationId: 11373,
							disconImpact: -0.032534894,
							featureValue: 0,
						},
						{
							conversationId: 11377,
							disconImpact: -0.03097254,
							featureValue: 0,
						},
						{
							conversationId: 11378,
							disconImpact: -0.033889122,
							featureValue: 0,
						},
						{
							conversationId: 11383,
							disconImpact: -0.021447904,
							featureValue: 0,
						},
						{
							conversationId: 11384,
							disconImpact: -0.029567927,
							featureValue: 0,
						},
						{
							conversationId: 11386,
							disconImpact: -0.033396307,
							featureValue: 0,
						},
						{
							conversationId: 11389,
							disconImpact: -0.022745455,
							featureValue: 0,
						},
						{
							conversationId: 11393,
							disconImpact: -0.027664136,
							featureValue: 0,
						},
						{
							conversationId: 11396,
							disconImpact: -0.027321348,
							featureValue: 0,
						},
						{
							conversationId: 11398,
							disconImpact: -0.029943857,
							featureValue: 0,
						},
						{
							conversationId: 11399,
							disconImpact: -0.025684012,
							featureValue: 0,
						},
						{
							conversationId: 11403,
							disconImpact: -0.030257083,
							featureValue: 0,
						},
						{
							conversationId: 11404,
							disconImpact: -0.037192903,
							featureValue: 0.0047700091463767,
						},
						{
							conversationId: 11408,
							disconImpact: -0.030997641,
							featureValue: 0,
						},
						{
							conversationId: 11413,
							disconImpact: -0.021937098,
							featureValue: 0,
						},
						{
							conversationId: 11416,
							disconImpact: -0.028283795,
							featureValue: 0,
						},
						{
							conversationId: 11419,
							disconImpact: -0.03678192,
							featureValue: 0,
						},
						{
							conversationId: 11422,
							disconImpact: -0.022984343,
							featureValue: 0,
						},
						{
							conversationId: 11423,
							disconImpact: -0.04125666,
							featureValue: 0,
						},
						{
							conversationId: 11427,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 11428,
							disconImpact: -0.017976075,
							featureValue: 0,
						},
						{
							conversationId: 11429,
							disconImpact: -0.027980141,
							featureValue: 0,
						},
						{
							conversationId: 11431,
							disconImpact: -0.030160176,
							featureValue: 0,
						},
						{
							conversationId: 11434,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 11437,
							disconImpact: -0.040745165,
							featureValue: 0,
						},
						{
							conversationId: 11439,
							disconImpact: -0.018706389,
							featureValue: 0,
						},
						{
							conversationId: 11442,
							disconImpact: -0.035761602,
							featureValue: 0,
						},
						{
							conversationId: 11443,
							disconImpact: -0.03713531,
							featureValue: 0,
						},
						{
							conversationId: 11450,
							disconImpact: -0.031443413,
							featureValue: 0,
						},
						{
							conversationId: 11451,
							disconImpact: -0.025946597,
							featureValue: 0,
						},
						{
							conversationId: 11453,
							disconImpact: -0.02957755,
							featureValue: 0,
						},
						{
							conversationId: 11454,
							disconImpact: -0.03003786,
							featureValue: 0,
						},
						{
							conversationId: 11457,
							disconImpact: -0.043630626,
							featureValue: 0,
						},
						{
							conversationId: 11458,
							disconImpact: -0.030773444,
							featureValue: 0,
						},
						{
							conversationId: 11465,
							disconImpact: -0.022227477,
							featureValue: 0,
						},
						{
							conversationId: 11467,
							disconImpact: -0.03324995,
							featureValue: 0,
						},
						{
							conversationId: 11470,
							disconImpact: -0.03254258,
							featureValue: 0,
						},
						{
							conversationId: 11476,
							disconImpact: -0.027500952,
							featureValue: 0,
						},
						{
							conversationId: 11479,
							disconImpact: -0.024473283,
							featureValue: 0,
						},
						{
							conversationId: 11486,
							disconImpact: -0.029815488,
							featureValue: 0,
						},
						{
							conversationId: 11488,
							disconImpact: -0.037769713,
							featureValue: 0,
						},
						{
							conversationId: 11489,
							disconImpact: -0.037631247,
							featureValue: 0,
						},
						{
							conversationId: 11494,
							disconImpact: -0.030130664,
							featureValue: 0,
						},
						{
							conversationId: 11495,
							disconImpact: -0.027197966,
							featureValue: 0,
						},
						{
							conversationId: 11496,
							disconImpact: -0.030243434,
							featureValue: 0,
						},
						{
							conversationId: 11500,
							disconImpact: -0.027561203,
							featureValue: 0,
						},
						{
							conversationId: 11501,
							disconImpact: -0.030650485,
							featureValue: 0,
						},
						{
							conversationId: 11502,
							disconImpact: -0.0374994,
							featureValue: 0,
						},
						{
							conversationId: 11504,
							disconImpact: -0.03451135,
							featureValue: 0,
						},
						{
							conversationId: 11508,
							disconImpact: -0.022104112,
							featureValue: 0,
						},
						{
							conversationId: 11510,
							disconImpact: -0.031220885,
							featureValue: 0,
						},
						{
							conversationId: 11511,
							disconImpact: -0.017957453,
							featureValue: 0,
						},
						{
							conversationId: 11516,
							disconImpact: -0.022031056,
							featureValue: 0,
						},
						{
							conversationId: 11520,
							disconImpact: -0.03204804,
							featureValue: 0,
						},
						{
							conversationId: 11526,
							disconImpact: -0.022170518,
							featureValue: 0,
						},
						{
							conversationId: 11531,
							disconImpact: -0.0288773,
							featureValue: 0,
						},
						{
							conversationId: 11532,
							disconImpact: -0.02084547,
							featureValue: 0,
						},
						{
							conversationId: 11536,
							disconImpact: -0.031717848,
							featureValue: 0,
						},
						{
							conversationId: 11538,
							disconImpact: -0.024169026,
							featureValue: 0,
						},
						{
							conversationId: 11540,
							disconImpact: -0.027256027,
							featureValue: 0,
						},
						{
							conversationId: 11541,
							disconImpact: -0.028985266,
							featureValue: 0,
						},
						{
							conversationId: 11549,
							disconImpact: -0.028449748,
							featureValue: 0,
						},
						{
							conversationId: 11551,
							disconImpact: -0.028225247,
							featureValue: 0,
						},
						{
							conversationId: 11558,
							disconImpact: -0.021986306,
							featureValue: 0,
						},
						{
							conversationId: 11572,
							disconImpact: -0.037684478,
							featureValue: 0,
						},
						{
							conversationId: 11581,
							disconImpact: -0.02909056,
							featureValue: 0,
						},
						{
							conversationId: 11584,
							disconImpact: -0.026734171,
							featureValue: 0,
						},
						{
							conversationId: 11588,
							disconImpact: -0.017345678,
							featureValue: 0.0056484182991505,
						},
						{
							conversationId: 11589,
							disconImpact: -0.038932968,
							featureValue: 0,
						},
						{
							conversationId: 11590,
							disconImpact: -0.030094473,
							featureValue: 0,
						},
						{
							conversationId: 11591,
							disconImpact: -0.031139301,
							featureValue: 0,
						},
						{
							conversationId: 11597,
							disconImpact: -0.03196366,
							featureValue: 0,
						},
						{
							conversationId: 11599,
							disconImpact: -0.032436285,
							featureValue: 0,
						},
						{
							conversationId: 11601,
							disconImpact: -0.030217007,
							featureValue: 0,
						},
						{
							conversationId: 11604,
							disconImpact: -0.040771186,
							featureValue: 0,
						},
						{
							conversationId: 11609,
							disconImpact: -0.023861311,
							featureValue: 0,
						},
						{
							conversationId: 11613,
							disconImpact: -0.029268261,
							featureValue: 0,
						},
						{
							conversationId: 11614,
							disconImpact: -0.029547002,
							featureValue: 0,
						},
						{
							conversationId: 11616,
							disconImpact: -0.044470616,
							featureValue: 0,
						},
						{
							conversationId: 11618,
							disconImpact: -0.037507553,
							featureValue: 0,
						},
						{
							conversationId: 11630,
							disconImpact: -0.021167703,
							featureValue: 0,
						},
						{
							conversationId: 11631,
							disconImpact: -0.03356414,
							featureValue: 0,
						},
						{
							conversationId: 11632,
							disconImpact: -0.033269994,
							featureValue: 0,
						},
						{
							conversationId: 11636,
							disconImpact: -0.032204945,
							featureValue: 0,
						},
						{
							conversationId: 11637,
							disconImpact: -0.026509985,
							featureValue: 0,
						},
						{
							conversationId: 11639,
							disconImpact: -0.034403186,
							featureValue: 0,
						},
						{
							conversationId: 11642,
							disconImpact: -0.034066692,
							featureValue: 0,
						},
						{
							conversationId: 11643,
							disconImpact: -0.027631886,
							featureValue: 0,
						},
						{
							conversationId: 11645,
							disconImpact: 0.002782017,
							featureValue: 0.0233993465047371,
						},
						{
							conversationId: 11648,
							disconImpact: -0.030535374,
							featureValue: 0,
						},
						{
							conversationId: 11651,
							disconImpact: -0.033889122,
							featureValue: 0,
						},
						{
							conversationId: 11656,
							disconImpact: -0.021479242,
							featureValue: 0,
						},
						{
							conversationId: 11657,
							disconImpact: -0.023734283,
							featureValue: 0,
						},
						{
							conversationId: 11660,
							disconImpact: -0.030798309,
							featureValue: 0,
						},
						{
							conversationId: 11661,
							disconImpact: -0.029700566,
							featureValue: 0,
						},
						{
							conversationId: 11663,
							disconImpact: -0.028864048,
							featureValue: 0,
						},
						{
							conversationId: 11666,
							disconImpact: -0.033379786,
							featureValue: 0,
						},
						{
							conversationId: 11674,
							disconImpact: -0.03383087,
							featureValue: 0,
						},
						{
							conversationId: 11675,
							disconImpact: -0.026643027,
							featureValue: 0,
						},
						{
							conversationId: 11677,
							disconImpact: -0.027714714,
							featureValue: 0,
						},
						{
							conversationId: 11679,
							disconImpact: -0.028922465,
							featureValue: 0,
						},
						{
							conversationId: 11681,
							disconImpact: -0.033112478,
							featureValue: 0,
						},
						{
							conversationId: 11682,
							disconImpact: -0.028436435,
							featureValue: 0,
						},
						{
							conversationId: 11686,
							disconImpact: -0.035069678,
							featureValue: 0,
						},
						{
							conversationId: 11688,
							disconImpact: -0.03662624,
							featureValue: 0,
						},
						{
							conversationId: 11695,
							disconImpact: -0.029055003,
							featureValue: 0,
						},
						{
							conversationId: 11697,
							disconImpact: -0.024425311,
							featureValue: 0,
						},
						{
							conversationId: 11699,
							disconImpact: -0.023408953,
							featureValue: 0,
						},
						{
							conversationId: 11702,
							disconImpact: 0.4393656,
							featureValue: 0.0440262371647377,
						},
						{
							conversationId: 11707,
							disconImpact: -0.02385977,
							featureValue: 0,
						},
						{
							conversationId: 11715,
							disconImpact: -0.096570395,
							featureValue: 0.0109751359751359,
						},
						{
							conversationId: 11719,
							disconImpact: -0.017625798,
							featureValue: 0,
						},
						{
							conversationId: 11722,
							disconImpact: -0.030630814,
							featureValue: 0,
						},
						{
							conversationId: 11723,
							disconImpact: -0.028207686,
							featureValue: 0,
						},
						{
							conversationId: 11726,
							disconImpact: -0.032361936,
							featureValue: 0,
						},
						{
							conversationId: 11730,
							disconImpact: -0.027077671,
							featureValue: 0,
						},
						{
							conversationId: 11732,
							disconImpact: -0.032811478,
							featureValue: 0,
						},
						{
							conversationId: 11734,
							disconImpact: -0.04086491,
							featureValue: 0,
						},
						{
							conversationId: 11736,
							disconImpact: -0.030326162,
							featureValue: 0,
						},
						{
							conversationId: 11740,
							disconImpact: -0.014710252,
							featureValue: 0,
						},
						{
							conversationId: 11741,
							disconImpact: -0.030407052,
							featureValue: 0,
						},
						{
							conversationId: 11748,
							disconImpact: -0.024874985,
							featureValue: 0,
						},
						{
							conversationId: 11754,
							disconImpact: -0.038449977,
							featureValue: 0,
						},
						{
							conversationId: 11755,
							disconImpact: -0.02781418,
							featureValue: 0,
						},
						{
							conversationId: 11757,
							disconImpact: -0.033889122,
							featureValue: 0,
						},
						{
							conversationId: 11761,
							disconImpact: -0.037672393,
							featureValue: 0,
						},
						{
							conversationId: 11762,
							disconImpact: -0.024370406,
							featureValue: 0,
						},
						{
							conversationId: 11765,
							disconImpact: -0.025093678,
							featureValue: 0,
						},
						{
							conversationId: 11768,
							disconImpact: -0.035069678,
							featureValue: 0,
						},
						{
							conversationId: 11769,
							disconImpact: -0.02555317,
							featureValue: 0,
						},
						{
							conversationId: 11772,
							disconImpact: -0.03125769,
							featureValue: 0,
						},
						{
							conversationId: 11773,
							disconImpact: -0.031382818,
							featureValue: 0,
						},
						{
							conversationId: 11776,
							disconImpact: -0.037761535,
							featureValue: 0,
						},
						{
							conversationId: 11777,
							disconImpact: -0.03291525,
							featureValue: 0,
						},
						{
							conversationId: 11779,
							disconImpact: -0.028926268,
							featureValue: 0,
						},
						{
							conversationId: 11780,
							disconImpact: -0.030221462,
							featureValue: 0,
						},
						{
							conversationId: 11781,
							disconImpact: -0.03228645,
							featureValue: 0,
						},
						{
							conversationId: 11783,
							disconImpact: -0.03140551,
							featureValue: 0,
						},
						{
							conversationId: 11785,
							disconImpact: -0.03483917,
							featureValue: 0,
						},
						{
							conversationId: 11787,
							disconImpact: -0.02645198,
							featureValue: 0,
						},
						{
							conversationId: 11789,
							disconImpact: -0.033010352,
							featureValue: 0,
						},
						{
							conversationId: 11790,
							disconImpact: -0.026060417,
							featureValue: 0,
						},
						{
							conversationId: 11796,
							disconImpact: -0.033889122,
							featureValue: 0,
						},
						{
							conversationId: 11799,
							disconImpact: -0.034536485,
							featureValue: 0,
						},
						{
							conversationId: 11805,
							disconImpact: -0.03067237,
							featureValue: 0,
						},
						{
							conversationId: 11806,
							disconImpact: -0.02726059,
							featureValue: 0,
						},
						{
							conversationId: 11809,
							disconImpact: -0.03197043,
							featureValue: 0,
						},
						{
							conversationId: 11820,
							disconImpact: -0.03392166,
							featureValue: 0,
						},
						{
							conversationId: 11823,
							disconImpact: -0.14019479,
							featureValue: 0.0098317578024963,
						},
						{
							conversationId: 11825,
							disconImpact: -0.029340018,
							featureValue: 0,
						},
						{
							conversationId: 11826,
							disconImpact: -0.033889122,
							featureValue: 0,
						},
						{
							conversationId: 11830,
							disconImpact: -0.028904025,
							featureValue: 0,
						},
						{
							conversationId: 11831,
							disconImpact: -0.028499745,
							featureValue: 0,
						},
						{
							conversationId: 11834,
							disconImpact: -0.040313378,
							featureValue: 0,
						},
						{
							conversationId: 11835,
							disconImpact: -0.041006953,
							featureValue: 0,
						},
						{
							conversationId: 11838,
							disconImpact: -0.03366363,
							featureValue: 0,
						},
						{
							conversationId: 11842,
							disconImpact: -0.030504256,
							featureValue: 0,
						},
						{
							conversationId: 11851,
							disconImpact: -0.03702089,
							featureValue: 0,
						},
						{
							conversationId: 11852,
							disconImpact: -0.025120098,
							featureValue: 0,
						},
						{
							conversationId: 11857,
							disconImpact: -0.035449795,
							featureValue: 0,
						},
						{
							conversationId: 11858,
							disconImpact: -0.027020995,
							featureValue: 0,
						},
						{
							conversationId: 11865,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 11868,
							disconImpact: -0.031062366,
							featureValue: 0,
						},
						{
							conversationId: 11871,
							disconImpact: -0.03125827,
							featureValue: 0,
						},
						{
							conversationId: 11874,
							disconImpact: -0.033265714,
							featureValue: 0,
						},
						{
							conversationId: 11879,
							disconImpact: -0.022361629,
							featureValue: 0,
						},
						{
							conversationId: 11881,
							disconImpact: 0.22022152,
							featureValue: 0.017149299219444,
						},
						{
							conversationId: 11883,
							disconImpact: -0.024896309,
							featureValue: 0,
						},
						{
							conversationId: 11884,
							disconImpact: -0.036902614,
							featureValue: 0,
						},
						{
							conversationId: 11885,
							disconImpact: -0.035941586,
							featureValue: 0,
						},
						{
							conversationId: 11889,
							disconImpact: -0.035701856,
							featureValue: 0,
						},
						{
							conversationId: 11890,
							disconImpact: -0.028543513,
							featureValue: 0,
						},
						{
							conversationId: 11892,
							disconImpact: -0.036325455,
							featureValue: 0,
						},
						{
							conversationId: 11897,
							disconImpact: -0.034818012,
							featureValue: 0,
						},
						{
							conversationId: 11898,
							disconImpact: -0.032009978,
							featureValue: 0,
						},
						{
							conversationId: 11903,
							disconImpact: -0.03866671,
							featureValue: 0,
						},
						{
							conversationId: 11906,
							disconImpact: -0.029263303,
							featureValue: 0,
						},
						{
							conversationId: 11909,
							disconImpact: -0.033578396,
							featureValue: 0,
						},
						{
							conversationId: 11910,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 11912,
							disconImpact: -0.026567016,
							featureValue: 0,
						},
						{
							conversationId: 11915,
							disconImpact: -0.033291664,
							featureValue: 0,
						},
						{
							conversationId: 11921,
							disconImpact: -0.029726703,
							featureValue: 0,
						},
						{
							conversationId: 11923,
							disconImpact: -0.033396307,
							featureValue: 0,
						},
						{
							conversationId: 11928,
							disconImpact: -0.027921991,
							featureValue: 0,
						},
						{
							conversationId: 11929,
							disconImpact: -0.03724116,
							featureValue: 0,
						},
						{
							conversationId: 11933,
							disconImpact: -0.022806551,
							featureValue: 0,
						},
						{
							conversationId: 11936,
							disconImpact: 0.9816002,
							featureValue: 0.1488084275663928,
						},
						{
							conversationId: 11940,
							disconImpact: -0.03204804,
							featureValue: 0,
						},
						{
							conversationId: 11941,
							disconImpact: -0.02794202,
							featureValue: 0,
						},
						{
							conversationId: 11944,
							disconImpact: -0.03316227,
							featureValue: 0,
						},
						{
							conversationId: 11946,
							disconImpact: -0.037672393,
							featureValue: 0,
						},
						{
							conversationId: 11949,
							disconImpact: -0.018264428,
							featureValue: 0,
						},
						{
							conversationId: 11953,
							disconImpact: -0.024287373,
							featureValue: 0,
						},
						{
							conversationId: 11955,
							disconImpact: -0.032954697,
							featureValue: 0,
						},
						{
							conversationId: 11957,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 11958,
							disconImpact: -0.027763085,
							featureValue: 0,
						},
						{
							conversationId: 11963,
							disconImpact: -0.031064369,
							featureValue: 0,
						},
						{
							conversationId: 11971,
							disconImpact: -0.02611629,
							featureValue: 0,
						},
						{
							conversationId: 11973,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 11977,
							disconImpact: -0.030404722,
							featureValue: 0,
						},
						{
							conversationId: 11978,
							disconImpact: -0.03204804,
							featureValue: 0,
						},
						{
							conversationId: 11979,
							disconImpact: -0.035534672,
							featureValue: 0,
						},
						{
							conversationId: 11981,
							disconImpact: -0.02927956,
							featureValue: 0,
						},
						{
							conversationId: 11985,
							disconImpact: -0.027967513,
							featureValue: 0,
						},
						{
							conversationId: 11987,
							disconImpact: -0.034604315,
							featureValue: 0,
						},
						{
							conversationId: 11994,
							disconImpact: -0.0749126,
							featureValue: 0.0143867767336018,
						},
						{
							conversationId: 11996,
							disconImpact: -0.03655998,
							featureValue: 0,
						},
						{
							conversationId: 11999,
							disconImpact: -0.03933033,
							featureValue: 0,
						},
						{
							conversationId: 12000,
							disconImpact: -0.030535374,
							featureValue: 0,
						},
						{
							conversationId: 12006,
							disconImpact: -0.029681921,
							featureValue: 0,
						},
						{
							conversationId: 12007,
							disconImpact: -0.029434733,
							featureValue: 0,
						},
						{
							conversationId: 12008,
							disconImpact: -0.031606264,
							featureValue: 0,
						},
						{
							conversationId: 12013,
							disconImpact: -0.03394527,
							featureValue: 0,
						},
						{
							conversationId: 12020,
							disconImpact: -0.020796215,
							featureValue: 0,
						},
						{
							conversationId: 12022,
							disconImpact: -0.029781086,
							featureValue: 0,
						},
						{
							conversationId: 12024,
							disconImpact: -0.028517589,
							featureValue: 0,
						},
						{
							conversationId: 12028,
							disconImpact: -0.03204804,
							featureValue: 0,
						},
						{
							conversationId: 12030,
							disconImpact: -0.03333806,
							featureValue: 0,
						},
						{
							conversationId: 12032,
							disconImpact: -0.030065168,
							featureValue: 0,
						},
						{
							conversationId: 12038,
							disconImpact: -0.027124397,
							featureValue: 0,
						},
						{
							conversationId: 12040,
							disconImpact: -0.038640305,
							featureValue: 0,
						},
						{
							conversationId: 12044,
							disconImpact: -0.030249085,
							featureValue: 0,
						},
						{
							conversationId: 12047,
							disconImpact: -0.02526579,
							featureValue: 0,
						},
						{
							conversationId: 12049,
							disconImpact: -0.03259885,
							featureValue: 0,
						},
						{
							conversationId: 12050,
							disconImpact: -0.036039975,
							featureValue: 0,
						},
						{
							conversationId: 12051,
							disconImpact: -0.02353578,
							featureValue: 0,
						},
						{
							conversationId: 12054,
							disconImpact: -0.030043218,
							featureValue: 0,
						},
						{
							conversationId: 12058,
							disconImpact: -0.026602501,
							featureValue: 0,
						},
						{
							conversationId: 12060,
							disconImpact: -0.035838537,
							featureValue: 0,
						},
						{
							conversationId: 12061,
							disconImpact: -0.024857836,
							featureValue: 0,
						},
						{
							conversationId: 12064,
							disconImpact: -0.03135631,
							featureValue: 0,
						},
						{
							conversationId: 12066,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 12077,
							disconImpact: -0.036404494,
							featureValue: 0,
						},
						{
							conversationId: 12085,
							disconImpact: -0.031615842,
							featureValue: 0,
						},
						{
							conversationId: 12086,
							disconImpact: -0.0329497,
							featureValue: 0,
						},
						{
							conversationId: 12088,
							disconImpact: -0.043085605,
							featureValue: 0,
						},
						{
							conversationId: 12092,
							disconImpact: -0.026437033,
							featureValue: 0,
						},
						{
							conversationId: 12094,
							disconImpact: -0.021234307,
							featureValue: 0,
						},
						{
							conversationId: 12095,
							disconImpact: -0.022766024,
							featureValue: 0,
						},
						{
							conversationId: 12098,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 12100,
							disconImpact: -0.030661333,
							featureValue: 0,
						},
						{
							conversationId: 12101,
							disconImpact: -0.030694295,
							featureValue: 0,
						},
						{
							conversationId: 12102,
							disconImpact: -0.021384625,
							featureValue: 0,
						},
						{
							conversationId: 12103,
							disconImpact: -0.027894918,
							featureValue: 0,
						},
						{
							conversationId: 12116,
							disconImpact: -0.034796767,
							featureValue: 0,
						},
						{
							conversationId: 12120,
							disconImpact: -0.03577963,
							featureValue: 0,
						},
						{
							conversationId: 12122,
							disconImpact: -0.03313477,
							featureValue: 0,
						},
						{
							conversationId: 12132,
							disconImpact: -0.034147426,
							featureValue: 0,
						},
						{
							conversationId: 12138,
							disconImpact: -0.03631705,
							featureValue: 0,
						},
						{
							conversationId: 12139,
							disconImpact: 1.2428639,
							featureValue: 0.0552580693142941,
						},
						{
							conversationId: 12142,
							disconImpact: -0.023475599,
							featureValue: 0,
						},
						{
							conversationId: 12147,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 12148,
							disconImpact: -0.032765165,
							featureValue: 0,
						},
						{
							conversationId: 12149,
							disconImpact: -0.0392834,
							featureValue: 0,
						},
						{
							conversationId: 12153,
							disconImpact: -0.02860971,
							featureValue: 0,
						},
						{
							conversationId: 12160,
							disconImpact: -0.024621222,
							featureValue: 0,
						},
						{
							conversationId: 12167,
							disconImpact: -0.035400372,
							featureValue: 0,
						},
						{
							conversationId: 12170,
							disconImpact: -0.03262399,
							featureValue: 0,
						},
						{
							conversationId: 12171,
							disconImpact: -0.014302325,
							featureValue: 0,
						},
						{
							conversationId: 12172,
							disconImpact: -0.04449646,
							featureValue: 0,
						},
						{
							conversationId: 12174,
							disconImpact: -0.027254459,
							featureValue: 0,
						},
						{
							conversationId: 12189,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 12190,
							disconImpact: -0.022875026,
							featureValue: 0,
						},
						{
							conversationId: 12191,
							disconImpact: -0.033882536,
							featureValue: 0,
						},
						{
							conversationId: 12193,
							disconImpact: -0.035436224,
							featureValue: 0,
						},
						{
							conversationId: 12195,
							disconImpact: -0.02147585,
							featureValue: 0,
						},
						{
							conversationId: 12196,
							disconImpact: -0.027894981,
							featureValue: 0,
						},
						{
							conversationId: 12198,
							disconImpact: -0.034130625,
							featureValue: 0,
						},
						{
							conversationId: 12203,
							disconImpact: -0.034955207,
							featureValue: 0,
						},
						{
							conversationId: 12205,
							disconImpact: -0.037076097,
							featureValue: 0,
						},
						{
							conversationId: 12207,
							disconImpact: -0.014497471,
							featureValue: 0,
						},
						{
							conversationId: 12214,
							disconImpact: -0.03139204,
							featureValue: 0,
						},
						{
							conversationId: 12215,
							disconImpact: -0.03226795,
							featureValue: 0,
						},
						{
							conversationId: 12217,
							disconImpact: -0.033669237,
							featureValue: 0,
						},
						{
							conversationId: 12226,
							disconImpact: -0.03302411,
							featureValue: 0,
						},
						{
							conversationId: 12231,
							disconImpact: -0.027428944,
							featureValue: 0,
						},
						{
							conversationId: 12232,
							disconImpact: -0.02870828,
							featureValue: 0,
						},
						{
							conversationId: 12242,
							disconImpact: -0.033213187,
							featureValue: 0,
						},
						{
							conversationId: 12243,
							disconImpact: -0.033232056,
							featureValue: 0,
						},
						{
							conversationId: 12249,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 12251,
							disconImpact: -0.019682948,
							featureValue: 0,
						},
						{
							conversationId: 12254,
							disconImpact: -0.026701441,
							featureValue: 0,
						},
						{
							conversationId: 12256,
							disconImpact: -0.02714008,
							featureValue: 0,
						},
						{
							conversationId: 12258,
							disconImpact: -0.033353716,
							featureValue: 0,
						},
						{
							conversationId: 12260,
							disconImpact: -0.027748728,
							featureValue: 0,
						},
						{
							conversationId: 12280,
							disconImpact: -0.033652637,
							featureValue: 0,
						},
						{
							conversationId: 12284,
							disconImpact: -0.036013275,
							featureValue: 0,
						},
						{
							conversationId: 12295,
							disconImpact: -0.029968984,
							featureValue: 0,
						},
						{
							conversationId: 12310,
							disconImpact: -0.03539683,
							featureValue: 0,
						},
						{
							conversationId: 12316,
							disconImpact: -0.026878966,
							featureValue: 0,
						},
						{
							conversationId: 12321,
							disconImpact: -0.019182038,
							featureValue: 0,
						},
						{
							conversationId: 12324,
							disconImpact: -0.028692413,
							featureValue: 0,
						},
						{
							conversationId: 12329,
							disconImpact: -0.034403186,
							featureValue: 0,
						},
						{
							conversationId: 12331,
							disconImpact: -0.031088773,
							featureValue: 0,
						},
						{
							conversationId: 12334,
							disconImpact: -0.017521475,
							featureValue: 0,
						},
						{
							conversationId: 12336,
							disconImpact: -0.03606393,
							featureValue: 0,
						},
						{
							conversationId: 12345,
							disconImpact: -0.03039467,
							featureValue: 0,
						},
						{
							conversationId: 12350,
							disconImpact: -0.033266712,
							featureValue: 0,
						},
						{
							conversationId: 12351,
							disconImpact: -0.035528198,
							featureValue: 0,
						},
						{
							conversationId: 12355,
							disconImpact: -0.028165273,
							featureValue: 0,
						},
						{
							conversationId: 12360,
							disconImpact: -0.033524256,
							featureValue: 0,
						},
						{
							conversationId: 12366,
							disconImpact: -0.033202995,
							featureValue: 0,
						},
						{
							conversationId: 12371,
							disconImpact: -0.037569925,
							featureValue: 0,
						},
						{
							conversationId: 12372,
							disconImpact: -0.031116657,
							featureValue: 0,
						},
						{
							conversationId: 12383,
							disconImpact: -0.036190856,
							featureValue: 0,
						},
						{
							conversationId: 12386,
							disconImpact: -0.0252935,
							featureValue: 0,
						},
						{
							conversationId: 12392,
							disconImpact: -0.028231286,
							featureValue: 0,
						},
						{
							conversationId: 12407,
							disconImpact: -0.028740514,
							featureValue: 0,
						},
						{
							conversationId: 12423,
							disconImpact: -0.027455892,
							featureValue: 0,
						},
						{
							conversationId: 12426,
							disconImpact: -0.036723785,
							featureValue: 0,
						},
						{
							conversationId: 12435,
							disconImpact: -0.03384757,
							featureValue: 0,
						},
						{
							conversationId: 12442,
							disconImpact: -0.02226225,
							featureValue: 0,
						},
						{
							conversationId: 12445,
							disconImpact: -0.035007197,
							featureValue: 0,
						},
						{
							conversationId: 12447,
							disconImpact: -0.027816989,
							featureValue: 0,
						},
						{
							conversationId: 12450,
							disconImpact: -0.03454735,
							featureValue: 0,
						},
						{
							conversationId: 12453,
							disconImpact: 0.54744214,
							featureValue: 0.0411006273773648,
						},
						{
							conversationId: 12455,
							disconImpact: -0.031631567,
							featureValue: 0,
						},
						{
							conversationId: 12464,
							disconImpact: -0.026737146,
							featureValue: 0,
						},
						{
							conversationId: 12468,
							disconImpact: -0.029390767,
							featureValue: 0,
						},
						{
							conversationId: 12470,
							disconImpact: -0.026856482,
							featureValue: 0,
						},
						{
							conversationId: 12472,
							disconImpact: -0.03194442,
							featureValue: 0,
						},
						{
							conversationId: 12487,
							disconImpact: -0.026604433,
							featureValue: 0,
						},
						{
							conversationId: 12492,
							disconImpact: -0.01726648,
							featureValue: 0,
						},
						{
							conversationId: 12504,
							disconImpact: -0.029056208,
							featureValue: 0,
						},
						{
							conversationId: 12506,
							disconImpact: -0.036848128,
							featureValue: 0,
						},
						{
							conversationId: 12515,
							disconImpact: -0.029720588,
							featureValue: 0,
						},
						{
							conversationId: 12516,
							disconImpact: -0.039555166,
							featureValue: 0,
						},
						{
							conversationId: 12518,
							disconImpact: 0.112488404,
							featureValue: 0.0300039246467818,
						},
						{
							conversationId: 12520,
							disconImpact: -0.032447938,
							featureValue: 0,
						},
						{
							conversationId: 12521,
							disconImpact: -0.020406071,
							featureValue: 0,
						},
						{
							conversationId: 12526,
							disconImpact: -0.030410683,
							featureValue: 0,
						},
						{
							conversationId: 12531,
							disconImpact: -0.045230933,
							featureValue: 0,
						},
						{
							conversationId: 12535,
							disconImpact: -0.02899317,
							featureValue: 0,
						},
						{
							conversationId: 12538,
							disconImpact: -0.028252374,
							featureValue: 0,
						},
						{
							conversationId: 12540,
							disconImpact: -0.030706318,
							featureValue: 0,
						},
						{
							conversationId: 12544,
							disconImpact: -0.032623608,
							featureValue: 0,
						},
						{
							conversationId: 12545,
							disconImpact: -0.030522604,
							featureValue: 0,
						},
						{
							conversationId: 12551,
							disconImpact: -0.027362954,
							featureValue: 0,
						},
						{
							conversationId: 12557,
							disconImpact: -0.024078604,
							featureValue: 0,
						},
						{
							conversationId: 12558,
							disconImpact: -0.033316996,
							featureValue: 0,
						},
						{
							conversationId: 12561,
							disconImpact: -0.034980692,
							featureValue: 0,
						},
						{
							conversationId: 12565,
							disconImpact: -0.032280006,
							featureValue: 0,
						},
						{
							conversationId: 12582,
							disconImpact: -0.027601093,
							featureValue: 0,
						},
						{
							conversationId: 12583,
							disconImpact: -0.031803656,
							featureValue: 0,
						},
						{
							conversationId: 12588,
							disconImpact: -0.020441983,
							featureValue: 0,
						},
						{
							conversationId: 12589,
							disconImpact: -0.026397675,
							featureValue: 0,
						},
						{
							conversationId: 12598,
							disconImpact: -0.027768295,
							featureValue: 0,
						},
						{
							conversationId: 12608,
							disconImpact: -0.018048504,
							featureValue: 0,
						},
						{
							conversationId: 12613,
							disconImpact: -0.02727886,
							featureValue: 0,
						},
						{
							conversationId: 12616,
							disconImpact: -0.01686055,
							featureValue: 0,
						},
						{
							conversationId: 12636,
							disconImpact: -0.02507946,
							featureValue: 0,
						},
						{
							conversationId: 12663,
							disconImpact: -0.026931679,
							featureValue: 0,
						},
						{
							conversationId: 12672,
							disconImpact: -0.042400025,
							featureValue: 0,
						},
						{
							conversationId: 12682,
							disconImpact: -0.026797932,
							featureValue: 0,
						},
						{
							conversationId: 12683,
							disconImpact: -0.02910248,
							featureValue: 0,
						},
						{
							conversationId: 12690,
							disconImpact: -0.03076353,
							featureValue: 0,
						},
						{
							conversationId: 12694,
							disconImpact: -0.02463669,
							featureValue: 0,
						},
						{
							conversationId: 12695,
							disconImpact: 0.7641322,
							featureValue: 0.0436426624981516,
						},
						{
							conversationId: 12698,
							disconImpact: -0.029240623,
							featureValue: 0,
						},
						{
							conversationId: 12713,
							disconImpact: -0.026036948,
							featureValue: 0,
						},
						{
							conversationId: 12720,
							disconImpact: -0.031304073,
							featureValue: 0,
						},
						{
							conversationId: 12725,
							disconImpact: -0.035154685,
							featureValue: 0,
						},
						{
							conversationId: 12729,
							disconImpact: -0.024513653,
							featureValue: 0,
						},
						{
							conversationId: 12740,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 12741,
							disconImpact: -0.02048764,
							featureValue: 0,
						},
						{
							conversationId: 12742,
							disconImpact: -0.031236283,
							featureValue: 0,
						},
						{
							conversationId: 12755,
							disconImpact: 1.0956477,
							featureValue: 0.1527087905741019,
						},
						{
							conversationId: 12768,
							disconImpact: -0.02385023,
							featureValue: 0,
						},
						{
							conversationId: 12769,
							disconImpact: -0.0338531,
							featureValue: 0,
						},
						{
							conversationId: 12771,
							disconImpact: -0.027977921,
							featureValue: 0,
						},
						{
							conversationId: 12778,
							disconImpact: -0.033652637,
							featureValue: 0,
						},
						{
							conversationId: 12793,
							disconImpact: -0.025414746,
							featureValue: 0,
						},
						{
							conversationId: 12801,
							disconImpact: -0.023421207,
							featureValue: 0,
						},
						{
							conversationId: 12811,
							disconImpact: -0.020960215,
							featureValue: 0,
						},
						{
							conversationId: 12835,
							disconImpact: -0.035586447,
							featureValue: 0,
						},
						{
							conversationId: 12840,
							disconImpact: -0.03781355,
							featureValue: 0,
						},
						{
							conversationId: 12854,
							disconImpact: -0.033513345,
							featureValue: 0,
						},
						{
							conversationId: 12858,
							disconImpact: -0.030865204,
							featureValue: 0,
						},
						{
							conversationId: 12860,
							disconImpact: -0.027769089,
							featureValue: 0,
						},
						{
							conversationId: 12866,
							disconImpact: -0.025572041,
							featureValue: 0,
						},
						{
							conversationId: 12868,
							disconImpact: -0.02712391,
							featureValue: 0,
						},
						{
							conversationId: 12869,
							disconImpact: -0.030825602,
							featureValue: 0,
						},
						{
							conversationId: 12873,
							disconImpact: -0.03414998,
							featureValue: 0,
						},
						{
							conversationId: 12875,
							disconImpact: -0.17482209,
							featureValue: 0.0095585047617716,
						},
						{
							conversationId: 12877,
							disconImpact: -0.031722434,
							featureValue: 0,
						},
						{
							conversationId: 12879,
							disconImpact: -0.03215138,
							featureValue: 0,
						},
						{
							conversationId: 12882,
							disconImpact: -0.032844614,
							featureValue: 0,
						},
						{
							conversationId: 12888,
							disconImpact: -0.022375546,
							featureValue: 0,
						},
						{
							conversationId: 12899,
							disconImpact: -0.020556215,
							featureValue: 0,
						},
						{
							conversationId: 12900,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 12904,
							disconImpact: -0.03035902,
							featureValue: 0,
						},
						{
							conversationId: 12912,
							disconImpact: -0.022865273,
							featureValue: 0,
						},
						{
							conversationId: 12916,
							disconImpact: -0.028686225,
							featureValue: 0,
						},
						{
							conversationId: 12920,
							disconImpact: -0.031192806,
							featureValue: 0,
						},
						{
							conversationId: 12921,
							disconImpact: -0.03675177,
							featureValue: 0,
						},
						{
							conversationId: 12923,
							disconImpact: -0.02394763,
							featureValue: 0,
						},
						{
							conversationId: 12925,
							disconImpact: -0.029221702,
							featureValue: 0,
						},
						{
							conversationId: 12926,
							disconImpact: -0.026958894,
							featureValue: 0,
						},
						{
							conversationId: 12929,
							disconImpact: -0.02953106,
							featureValue: 0,
						},
						{
							conversationId: 12932,
							disconImpact: -0.03444085,
							featureValue: 0,
						},
						{
							conversationId: 12936,
							disconImpact: -0.036964756,
							featureValue: 0,
						},
						{
							conversationId: 12938,
							disconImpact: -0.030190475,
							featureValue: 0,
						},
						{
							conversationId: 12940,
							disconImpact: -0.026410414,
							featureValue: 0,
						},
						{
							conversationId: 12941,
							disconImpact: -0.03228864,
							featureValue: 0,
						},
						{
							conversationId: 12945,
							disconImpact: -0.036556743,
							featureValue: 0,
						},
						{
							conversationId: 12948,
							disconImpact: -0.030630557,
							featureValue: 0,
						},
						{
							conversationId: 12950,
							disconImpact: -0.03325539,
							featureValue: 0,
						},
						{
							conversationId: 12954,
							disconImpact: -0.021760877,
							featureValue: 0,
						},
						{
							conversationId: 12958,
							disconImpact: -0.028682208,
							featureValue: 0,
						},
						{
							conversationId: 12959,
							disconImpact: -0.037537575,
							featureValue: 0,
						},
						{
							conversationId: 12962,
							disconImpact: -0.027085189,
							featureValue: 0,
						},
						{
							conversationId: 12968,
							disconImpact: -0.025643256,
							featureValue: 0,
						},
						{
							conversationId: 12973,
							disconImpact: -0.030129218,
							featureValue: 0,
						},
						{
							conversationId: 12977,
							disconImpact: -0.038915467,
							featureValue: 0,
						},
						{
							conversationId: 12979,
							disconImpact: -0.03291525,
							featureValue: 0,
						},
						{
							conversationId: 12980,
							disconImpact: -0.029322578,
							featureValue: 0,
						},
						{
							conversationId: 12982,
							disconImpact: -0.021461511,
							featureValue: 0,
						},
						{
							conversationId: 12985,
							disconImpact: -0.025242817,
							featureValue: 0,
						},
						{
							conversationId: 12986,
							disconImpact: -0.02771874,
							featureValue: 0,
						},
						{
							conversationId: 12989,
							disconImpact: -0.034179673,
							featureValue: 0,
						},
						{
							conversationId: 12991,
							disconImpact: -0.03218638,
							featureValue: 0,
						},
						{
							conversationId: 12992,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 12997,
							disconImpact: -0.03402107,
							featureValue: 0,
						},
						{
							conversationId: 12998,
							disconImpact: -0.03305303,
							featureValue: 0,
						},
						{
							conversationId: 13002,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 13003,
							disconImpact: -0.034678973,
							featureValue: 0,
						},
						{
							conversationId: 13004,
							disconImpact: -0.032541048,
							featureValue: 0,
						},
						{
							conversationId: 13005,
							disconImpact: -0.024787422,
							featureValue: 0,
						},
						{
							conversationId: 13011,
							disconImpact: -0.03224463,
							featureValue: 0,
						},
						{
							conversationId: 13015,
							disconImpact: -0.038918994,
							featureValue: 0,
						},
						{
							conversationId: 13016,
							disconImpact: -0.031647906,
							featureValue: 0,
						},
						{
							conversationId: 13017,
							disconImpact: -0.0280295,
							featureValue: 0,
						},
						{
							conversationId: 13020,
							disconImpact: 0.8950446,
							featureValue: 0.0477315552978693,
						},
						{
							conversationId: 13022,
							disconImpact: -0.032348283,
							featureValue: 0,
						},
						{
							conversationId: 13024,
							disconImpact: -0.034523334,
							featureValue: 0,
						},
						{
							conversationId: 13025,
							disconImpact: -0.020508882,
							featureValue: 0,
						},
						{
							conversationId: 13030,
							disconImpact: -0.029077709,
							featureValue: 0,
						},
						{
							conversationId: 13038,
							disconImpact: -0.027092021,
							featureValue: 0,
						},
						{
							conversationId: 13039,
							disconImpact: -0.026346218,
							featureValue: 0,
						},
						{
							conversationId: 13042,
							disconImpact: -0.025187293,
							featureValue: 0,
						},
						{
							conversationId: 13046,
							disconImpact: -0.030709688,
							featureValue: 0,
						},
						{
							conversationId: 13047,
							disconImpact: -0.04131396,
							featureValue: 0,
						},
						{
							conversationId: 13048,
							disconImpact: -0.033652637,
							featureValue: 0,
						},
						{
							conversationId: 13049,
							disconImpact: -0.008990386,
							featureValue: 0,
						},
						{
							conversationId: 13051,
							disconImpact: -0.023412354,
							featureValue: 0,
						},
						{
							conversationId: 13053,
							disconImpact: -0.033316996,
							featureValue: 0,
						},
						{
							conversationId: 13057,
							disconImpact: -0.029841274,
							featureValue: 0,
						},
						{
							conversationId: 13063,
							disconImpact: -0.04301798,
							featureValue: 0,
						},
						{
							conversationId: 13066,
							disconImpact: -0.031812217,
							featureValue: 0,
						},
						{
							conversationId: 13067,
							disconImpact: -0.025681641,
							featureValue: 0,
						},
						{
							conversationId: 13071,
							disconImpact: -0.03077719,
							featureValue: 0,
						},
						{
							conversationId: 13074,
							disconImpact: -0.02833844,
							featureValue: 0,
						},
						{
							conversationId: 13075,
							disconImpact: -0.026490822,
							featureValue: 0,
						},
						{
							conversationId: 13077,
							disconImpact: -0.036738787,
							featureValue: 0,
						},
						{
							conversationId: 13080,
							disconImpact: -0.031831615,
							featureValue: 0,
						},
						{
							conversationId: 13085,
							disconImpact: 0.28765443,
							featureValue: 0.0155943493416503,
						},
						{
							conversationId: 13087,
							disconImpact: -0.029427998,
							featureValue: 0,
						},
						{
							conversationId: 13090,
							disconImpact: -0.017933795,
							featureValue: 0,
						},
						{
							conversationId: 13098,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 13101,
							disconImpact: -0.030143578,
							featureValue: 0,
						},
						{
							conversationId: 13102,
							disconImpact: -0.024270706,
							featureValue: 0,
						},
						{
							conversationId: 13103,
							disconImpact: -0.022329893,
							featureValue: 0,
						},
						{
							conversationId: 13106,
							disconImpact: 0.07923925,
							featureValue: 0.0080725001903891,
						},
						{
							conversationId: 13109,
							disconImpact: -0.024629058,
							featureValue: 0,
						},
						{
							conversationId: 13112,
							disconImpact: -0.014401387,
							featureValue: 0,
						},
						{
							conversationId: 13115,
							disconImpact: -0.03204804,
							featureValue: 0,
						},
						{
							conversationId: 13117,
							disconImpact: -0.031312224,
							featureValue: 0,
						},
						{
							conversationId: 13118,
							disconImpact: -0.03736405,
							featureValue: 0,
						},
						{
							conversationId: 13119,
							disconImpact: -0.025627762,
							featureValue: 0,
						},
						{
							conversationId: 13120,
							disconImpact: -0.027310088,
							featureValue: 0,
						},
						{
							conversationId: 13121,
							disconImpact: -0.031594206,
							featureValue: 0,
						},
						{
							conversationId: 13124,
							disconImpact: -0.030750554,
							featureValue: 0,
						},
						{
							conversationId: 13127,
							disconImpact: -0.029683556,
							featureValue: 0,
						},
						{
							conversationId: 13129,
							disconImpact: -0.03087674,
							featureValue: 0,
						},
						{
							conversationId: 13130,
							disconImpact: -0.03585314,
							featureValue: 0,
						},
						{
							conversationId: 13134,
							disconImpact: -0.03386177,
							featureValue: 0,
						},
						{
							conversationId: 13135,
							disconImpact: -0.022218112,
							featureValue: 0,
						},
						{
							conversationId: 13136,
							disconImpact: -0.024158522,
							featureValue: 0,
						},
						{
							conversationId: 13137,
							disconImpact: -0.025328072,
							featureValue: 0,
						},
						{
							conversationId: 13139,
							disconImpact: -0.03876201,
							featureValue: 0,
						},
						{
							conversationId: 13141,
							disconImpact: -0.03584848,
							featureValue: 0,
						},
						{
							conversationId: 13143,
							disconImpact: -0.0383345,
							featureValue: 0,
						},
						{
							conversationId: 13144,
							disconImpact: -0.030530505,
							featureValue: 0,
						},
						{
							conversationId: 13146,
							disconImpact: -0.024869489,
							featureValue: 0,
						},
						{
							conversationId: 13147,
							disconImpact: -0.023372397,
							featureValue: 0,
						},
						{
							conversationId: 13151,
							disconImpact: -0.03364978,
							featureValue: 0,
						},
						{
							conversationId: 13153,
							disconImpact: -0.024288539,
							featureValue: 0,
						},
						{
							conversationId: 13157,
							disconImpact: -0.024784466,
							featureValue: 0,
						},
						{
							conversationId: 13161,
							disconImpact: -0.03357735,
							featureValue: 0,
						},
						{
							conversationId: 13164,
							disconImpact: -0.023974707,
							featureValue: 0,
						},
						{
							conversationId: 13167,
							disconImpact: -0.031727377,
							featureValue: 0,
						},
						{
							conversationId: 13170,
							disconImpact: -0.0277447,
							featureValue: 0,
						},
						{
							conversationId: 13171,
							disconImpact: -0.0370809,
							featureValue: 0,
						},
						{
							conversationId: 13176,
							disconImpact: -0.033366952,
							featureValue: 0,
						},
						{
							conversationId: 13184,
							disconImpact: -0.032655526,
							featureValue: 0,
						},
						{
							conversationId: 13188,
							disconImpact: -0.022247441,
							featureValue: 0,
						},
						{
							conversationId: 13189,
							disconImpact: -0.03598987,
							featureValue: 0,
						},
						{
							conversationId: 13194,
							disconImpact: -0.032068726,
							featureValue: 0,
						},
						{
							conversationId: 13195,
							disconImpact: -0.026945118,
							featureValue: 0,
						},
						{
							conversationId: 13200,
							disconImpact: -0.029964292,
							featureValue: 0,
						},
						{
							conversationId: 13201,
							disconImpact: -0.034666307,
							featureValue: 0,
						},
						{
							conversationId: 13202,
							disconImpact: -0.024572574,
							featureValue: 0,
						},
						{
							conversationId: 13203,
							disconImpact: -0.025028355,
							featureValue: 0,
						},
						{
							conversationId: 13204,
							disconImpact: -0.030495903,
							featureValue: 0,
						},
						{
							conversationId: 13207,
							disconImpact: -0.028761566,
							featureValue: 0,
						},
						{
							conversationId: 13210,
							disconImpact: -0.03337874,
							featureValue: 0,
						},
						{
							conversationId: 13212,
							disconImpact: -0.01578766,
							featureValue: 0,
						},
						{
							conversationId: 13213,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 13216,
							disconImpact: -0.028082758,
							featureValue: 0,
						},
						{
							conversationId: 13217,
							disconImpact: -0.02583317,
							featureValue: 0,
						},
						{
							conversationId: 13222,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 13223,
							disconImpact: -0.014168254,
							featureValue: 0,
						},
						{
							conversationId: 13224,
							disconImpact: -0.03731023,
							featureValue: 0,
						},
						{
							conversationId: 13225,
							disconImpact: -0.028563648,
							featureValue: 0,
						},
						{
							conversationId: 13227,
							disconImpact: -0.037534483,
							featureValue: 0,
						},
						{
							conversationId: 13229,
							disconImpact: -0.026137263,
							featureValue: 0.0037154428596583,
						},
						{
							conversationId: 13230,
							disconImpact: -0.030728605,
							featureValue: 0,
						},
						{
							conversationId: 13231,
							disconImpact: -0.032305367,
							featureValue: 0,
						},
						{
							conversationId: 13234,
							disconImpact: -0.030409172,
							featureValue: 0,
						},
						{
							conversationId: 13235,
							disconImpact: -0.02783417,
							featureValue: 0,
						},
						{
							conversationId: 13236,
							disconImpact: -0.031198408,
							featureValue: 0,
						},
						{
							conversationId: 13239,
							disconImpact: -0.031177726,
							featureValue: 0,
						},
						{
							conversationId: 13240,
							disconImpact: -0.021464448,
							featureValue: 0,
						},
						{
							conversationId: 13241,
							disconImpact: -0.039822876,
							featureValue: 0,
						},
						{
							conversationId: 13242,
							disconImpact: -0.033956155,
							featureValue: 0,
						},
						{
							conversationId: 13243,
							disconImpact: -0.026523687,
							featureValue: 0,
						},
						{
							conversationId: 13245,
							disconImpact: -0.03357735,
							featureValue: 0,
						},
						{
							conversationId: 13246,
							disconImpact: -0.02970155,
							featureValue: 0,
						},
						{
							conversationId: 13247,
							disconImpact: -0.029665716,
							featureValue: 0,
						},
						{
							conversationId: 13249,
							disconImpact: -0.017388463,
							featureValue: 0,
						},
						{
							conversationId: 13252,
							disconImpact: -0.03282008,
							featureValue: 0,
						},
						{
							conversationId: 13254,
							disconImpact: -0.026785366,
							featureValue: 0,
						},
						{
							conversationId: 13255,
							disconImpact: -0.03227535,
							featureValue: 0,
						},
						{
							conversationId: 13256,
							disconImpact: -0.037605993,
							featureValue: 0,
						},
						{
							conversationId: 13257,
							disconImpact: -0.03574398,
							featureValue: 0,
						},
						{
							conversationId: 13258,
							disconImpact: -0.025077363,
							featureValue: 0,
						},
						{
							conversationId: 13259,
							disconImpact: -0.039577823,
							featureValue: 0,
						},
						{
							conversationId: 13260,
							disconImpact: -0.05818579,
							featureValue: 0.0087311883757135,
						},
						{
							conversationId: 13263,
							disconImpact: -0.033396307,
							featureValue: 0,
						},
						{
							conversationId: 13264,
							disconImpact: -0.02926439,
							featureValue: 0,
						},
						{
							conversationId: 13266,
							disconImpact: -0.03495468,
							featureValue: 0,
						},
						{
							conversationId: 13267,
							disconImpact: -0.025350325,
							featureValue: 0,
						},
						{
							conversationId: 13269,
							disconImpact: -0.022187848,
							featureValue: 0,
						},
						{
							conversationId: 13271,
							disconImpact: -0.033396307,
							featureValue: 0,
						},
						{
							conversationId: 13272,
							disconImpact: -0.017150234,
							featureValue: 0,
						},
						{
							conversationId: 13273,
							disconImpact: -0.013808444,
							featureValue: 0,
						},
						{
							conversationId: 13274,
							disconImpact: -0.030777097,
							featureValue: 0,
						},
						{
							conversationId: 13275,
							disconImpact: -0.026090499,
							featureValue: 0,
						},
						{
							conversationId: 13277,
							disconImpact: -0.026929237,
							featureValue: 0,
						},
						{
							conversationId: 13278,
							disconImpact: -0.033456508,
							featureValue: 0,
						},
						{
							conversationId: 13282,
							disconImpact: -0.033645533,
							featureValue: 0,
						},
						{
							conversationId: 13284,
							disconImpact: -0.031513263,
							featureValue: 0,
						},
						{
							conversationId: 13285,
							disconImpact: -0.012781086,
							featureValue: 0,
						},
						{
							conversationId: 13288,
							disconImpact: -0.036927514,
							featureValue: 0,
						},
						{
							conversationId: 13290,
							disconImpact: -0.029339224,
							featureValue: 0,
						},
						{
							conversationId: 13291,
							disconImpact: 0.055220783,
							featureValue: 0.008097061923245,
						},
						{
							conversationId: 13292,
							disconImpact: -0.025827017,
							featureValue: 0,
						},
						{
							conversationId: 13293,
							disconImpact: -0.015341962,
							featureValue: 0,
						},
						{
							conversationId: 13294,
							disconImpact: -0.031297013,
							featureValue: 0,
						},
						{
							conversationId: 13296,
							disconImpact: -0.039912976,
							featureValue: 0,
						},
						{
							conversationId: 13298,
							disconImpact: -0.02650971,
							featureValue: 0,
						},
						{
							conversationId: 13299,
							disconImpact: -0.02938699,
							featureValue: 0,
						},
						{
							conversationId: 13300,
							disconImpact: -0.034665756,
							featureValue: 0,
						},
						{
							conversationId: 13301,
							disconImpact: 1.2192591,
							featureValue: 0.1238511179193012,
						},
						{
							conversationId: 13303,
							disconImpact: -0.03091497,
							featureValue: 0,
						},
						{
							conversationId: 13304,
							disconImpact: -0.032081235,
							featureValue: 0,
						},
						{
							conversationId: 13305,
							disconImpact: -0.02926851,
							featureValue: 0,
						},
						{
							conversationId: 13306,
							disconImpact: -0.029430922,
							featureValue: 0,
						},
						{
							conversationId: 13307,
							disconImpact: -0.023875175,
							featureValue: 0,
						},
						{
							conversationId: 13308,
							disconImpact: -0.032759175,
							featureValue: 0,
						},
						{
							conversationId: 13309,
							disconImpact: -0.022668188,
							featureValue: 0,
						},
						{
							conversationId: 13311,
							disconImpact: -0.034256574,
							featureValue: 0,
						},
						{
							conversationId: 13312,
							disconImpact: -0.031040542,
							featureValue: 0,
						},
						{
							conversationId: 13314,
							disconImpact: -0.025538594,
							featureValue: 0,
						},
						{
							conversationId: 13315,
							disconImpact: -0.024744561,
							featureValue: 0.0039643940628729,
						},
						{
							conversationId: 13316,
							disconImpact: -0.029085584,
							featureValue: 0,
						},
						{
							conversationId: 13317,
							disconImpact: -0.026770566,
							featureValue: 0,
						},
						{
							conversationId: 13318,
							disconImpact: -0.030175846,
							featureValue: 0,
						},
						{
							conversationId: 13319,
							disconImpact: -0.02946874,
							featureValue: 0,
						},
						{
							conversationId: 13320,
							disconImpact: -0.021725599,
							featureValue: 0,
						},
						{
							conversationId: 13322,
							disconImpact: -0.03357735,
							featureValue: 0,
						},
						{
							conversationId: 13323,
							disconImpact: -0.030113775,
							featureValue: 0,
						},
						{
							conversationId: 13324,
							disconImpact: -0.0373913,
							featureValue: 0,
						},
						{
							conversationId: 13325,
							disconImpact: -0.032792553,
							featureValue: 0,
						},
						{
							conversationId: 13326,
							disconImpact: -0.029081617,
							featureValue: 0,
						},
						{
							conversationId: 13329,
							disconImpact: 0.16354634,
							featureValue: 0.0166381552738265,
						},
						{
							conversationId: 13330,
							disconImpact: -0.022480253,
							featureValue: 0,
						},
						{
							conversationId: 13331,
							disconImpact: -0.03997726,
							featureValue: 0,
						},
						{
							conversationId: 13334,
							disconImpact: -0.021327805,
							featureValue: 0,
						},
						{
							conversationId: 13335,
							disconImpact: -0.013898782,
							featureValue: 0,
						},
						{
							conversationId: 13336,
							disconImpact: -0.03357735,
							featureValue: 0,
						},
						{
							conversationId: 13337,
							disconImpact: -0.04245647,
							featureValue: 0,
						},
						{
							conversationId: 13338,
							disconImpact: -0.03432629,
							featureValue: 0,
						},
						{
							conversationId: 13340,
							disconImpact: -0.031339407,
							featureValue: 0,
						},
						{
							conversationId: 13341,
							disconImpact: -0.025614958,
							featureValue: 0,
						},
						{
							conversationId: 13343,
							disconImpact: -0.031625386,
							featureValue: 0,
						},
						{
							conversationId: 13344,
							disconImpact: -0.023731202,
							featureValue: 0,
						},
						{
							conversationId: 13345,
							disconImpact: -0.016623193,
							featureValue: 0,
						},
						{
							conversationId: 13346,
							disconImpact: -0.023412138,
							featureValue: 0,
						},
						{
							conversationId: 13348,
							disconImpact: -0.033889122,
							featureValue: 0,
						},
						{
							conversationId: 13349,
							disconImpact: -0.030014176,
							featureValue: 0,
						},
						{
							conversationId: 13350,
							disconImpact: -0.031393446,
							featureValue: 0,
						},
						{
							conversationId: 13351,
							disconImpact: -0.025023952,
							featureValue: 0,
						},
						{
							conversationId: 13352,
							disconImpact: -0.043413665,
							featureValue: 0,
						},
						{
							conversationId: 13353,
							disconImpact: -0.028441403,
							featureValue: 0,
						},
						{
							conversationId: 13356,
							disconImpact: -0.026500326,
							featureValue: 0,
						},
						{
							conversationId: 13358,
							disconImpact: -0.024865638,
							featureValue: 0,
						},
						{
							conversationId: 13360,
							disconImpact: -0.03347783,
							featureValue: 0,
						},
						{
							conversationId: 13361,
							disconImpact: -0.031012844,
							featureValue: 0,
						},
						{
							conversationId: 13364,
							disconImpact: -0.038686458,
							featureValue: 0,
						},
						{
							conversationId: 13365,
							disconImpact: -0.03193358,
							featureValue: 0,
						},
						{
							conversationId: 13366,
							disconImpact: -0.021973677,
							featureValue: 0,
						},
						{
							conversationId: 13368,
							disconImpact: -0.026370775,
							featureValue: 0,
						},
						{
							conversationId: 13369,
							disconImpact: -0.032861356,
							featureValue: 0,
						},
						{
							conversationId: 13370,
							disconImpact: -0.0317182,
							featureValue: 0,
						},
						{
							conversationId: 13371,
							disconImpact: -0.02690266,
							featureValue: 0,
						},
						{
							conversationId: 13374,
							disconImpact: -0.03384825,
							featureValue: 0,
						},
						{
							conversationId: 13376,
							disconImpact: -0.015423376,
							featureValue: 0,
						},
						{
							conversationId: 13379,
							disconImpact: -0.022474576,
							featureValue: 0,
						},
						{
							conversationId: 13380,
							disconImpact: -0.022767503,
							featureValue: 0,
						},
						{
							conversationId: 13381,
							disconImpact: -0.032123454,
							featureValue: 0,
						},
						{
							conversationId: 13382,
							disconImpact: -0.031379357,
							featureValue: 0,
						},
						{
							conversationId: 13384,
							disconImpact: -0.026898993,
							featureValue: 0,
						},
						{
							conversationId: 13386,
							disconImpact: -0.026538946,
							featureValue: 0,
						},
						{
							conversationId: 13387,
							disconImpact: -0.028649937,
							featureValue: 0,
						},
						{
							conversationId: 13389,
							disconImpact: -0.036770504,
							featureValue: 0,
						},
						{
							conversationId: 13390,
							disconImpact: -0.030718988,
							featureValue: 0,
						},
						{
							conversationId: 13391,
							disconImpact: -0.031939756,
							featureValue: 0,
						},
						{
							conversationId: 13393,
							disconImpact: -0.032345954,
							featureValue: 0,
						},
						{
							conversationId: 13396,
							disconImpact: -0.026466906,
							featureValue: 0,
						},
						{
							conversationId: 13397,
							disconImpact: -0.024985112,
							featureValue: 0,
						},
						{
							conversationId: 13399,
							disconImpact: -0.033889122,
							featureValue: 0,
						},
						{
							conversationId: 13400,
							disconImpact: -0.023863012,
							featureValue: 0,
						},
						{
							conversationId: 13401,
							disconImpact: -0.033915646,
							featureValue: 0,
						},
						{
							conversationId: 13402,
							disconImpact: -0.03558287,
							featureValue: 0,
						},
						{
							conversationId: 13403,
							disconImpact: -0.031823482,
							featureValue: 0,
						},
						{
							conversationId: 13404,
							disconImpact: -0.020349804,
							featureValue: 0,
						},
						{
							conversationId: 13405,
							disconImpact: -0.030036602,
							featureValue: 0,
						},
						{
							conversationId: 13406,
							disconImpact: -0.033995714,
							featureValue: 0,
						},
						{
							conversationId: 13407,
							disconImpact: -0.0305394,
							featureValue: 0,
						},
						{
							conversationId: 13409,
							disconImpact: -0.02562312,
							featureValue: 0,
						},
						{
							conversationId: 13411,
							disconImpact: -0.025115656,
							featureValue: 0,
						},
						{
							conversationId: 13412,
							disconImpact: -0.020746,
							featureValue: 0,
						},
						{
							conversationId: 13413,
							disconImpact: -0.026629884,
							featureValue: 0,
						},
						{
							conversationId: 13414,
							disconImpact: -0.026950149,
							featureValue: 0,
						},
						{
							conversationId: 13416,
							disconImpact: -0.03534527,
							featureValue: 0,
						},
						{
							conversationId: 13419,
							disconImpact: -0.0236536,
							featureValue: 0,
						},
						{
							conversationId: 13420,
							disconImpact: -0.029859975,
							featureValue: 0,
						},
						{
							conversationId: 13421,
							disconImpact: -0.034103747,
							featureValue: 0,
						},
						{
							conversationId: 13422,
							disconImpact: -0.029384183,
							featureValue: 0,
						},
						{
							conversationId: 13423,
							disconImpact: -0.033704072,
							featureValue: 0,
						},
						{
							conversationId: 13426,
							disconImpact: -0.021719899,
							featureValue: 0,
						},
						{
							conversationId: 13427,
							disconImpact: -0.03033328,
							featureValue: 0,
						},
						{
							conversationId: 13428,
							disconImpact: -0.025241463,
							featureValue: 0,
						},
						{
							conversationId: 13429,
							disconImpact: -0.033133563,
							featureValue: 0,
						},
						{
							conversationId: 13431,
							disconImpact: -0.018207977,
							featureValue: 0,
						},
						{
							conversationId: 13432,
							disconImpact: -0.0279641,
							featureValue: 0,
						},
						{
							conversationId: 13434,
							disconImpact: 0.76765746,
							featureValue: 0.0312143942271173,
						},
						{
							conversationId: 13437,
							disconImpact: -0.025768809,
							featureValue: 0,
						},
						{
							conversationId: 13438,
							disconImpact: -0.022914954,
							featureValue: 0,
						},
						{
							conversationId: 13440,
							disconImpact: -0.03794967,
							featureValue: 0,
						},
						{
							conversationId: 13441,
							disconImpact: -0.029305818,
							featureValue: 0,
						},
						{
							conversationId: 13442,
							disconImpact: -0.032744173,
							featureValue: 0,
						},
						{
							conversationId: 13443,
							disconImpact: -0.031519238,
							featureValue: 0,
						},
						{
							conversationId: 13444,
							disconImpact: -0.030792318,
							featureValue: 0,
						},
						{
							conversationId: 13446,
							disconImpact: -0.028224673,
							featureValue: 0,
						},
						{
							conversationId: 13447,
							disconImpact: -0.02704895,
							featureValue: 0,
						},
						{
							conversationId: 13448,
							disconImpact: -0.03276296,
							featureValue: 0,
						},
						{
							conversationId: 13449,
							disconImpact: -0.037762985,
							featureValue: 0,
						},
						{
							conversationId: 13450,
							disconImpact: -0.034628082,
							featureValue: 0,
						},
						{
							conversationId: 13452,
							disconImpact: -0.02982691,
							featureValue: 0,
						},
						{
							conversationId: 13453,
							disconImpact: -0.035772156,
							featureValue: 0,
						},
						{
							conversationId: 13454,
							disconImpact: -0.020064568,
							featureValue: 0,
						},
						{
							conversationId: 13455,
							disconImpact: -0.032821205,
							featureValue: 0,
						},
						{
							conversationId: 13459,
							disconImpact: -0.02564349,
							featureValue: 0,
						},
						{
							conversationId: 13460,
							disconImpact: -0.03645588,
							featureValue: 0,
						},
						{
							conversationId: 13461,
							disconImpact: -0.035069678,
							featureValue: 0,
						},
						{
							conversationId: 13462,
							disconImpact: -0.020934705,
							featureValue: 0,
						},
						{
							conversationId: 13464,
							disconImpact: -0.028151114,
							featureValue: 0,
						},
						{
							conversationId: 13465,
							disconImpact: -0.029019855,
							featureValue: 0,
						},
						{
							conversationId: 13466,
							disconImpact: -0.03864627,
							featureValue: 0,
						},
						{
							conversationId: 13467,
							disconImpact: -0.04239621,
							featureValue: 0,
						},
						{
							conversationId: 13471,
							disconImpact: 0.28824466,
							featureValue: 0.0282539193405492,
						},
						{
							conversationId: 13472,
							disconImpact: -0.024844347,
							featureValue: 0,
						},
						{
							conversationId: 13474,
							disconImpact: -0.029110793,
							featureValue: 0,
						},
						{
							conversationId: 13476,
							disconImpact: -0.029245973,
							featureValue: 0,
						},
						{
							conversationId: 13477,
							disconImpact: -0.01417096,
							featureValue: 0,
						},
						{
							conversationId: 13478,
							disconImpact: -0.033889122,
							featureValue: 0,
						},
						{
							conversationId: 13480,
							disconImpact: -0.022125078,
							featureValue: 0,
						},
						{
							conversationId: 13482,
							disconImpact: -0.033309113,
							featureValue: 0,
						},
						{
							conversationId: 13484,
							disconImpact: -0.03442655,
							featureValue: 0,
						},
						{
							conversationId: 13485,
							disconImpact: -0.030563852,
							featureValue: 0,
						},
						{
							conversationId: 13486,
							disconImpact: -0.032114364,
							featureValue: 0,
						},
						{
							conversationId: 13487,
							disconImpact: -0.034209277,
							featureValue: 0,
						},
						{
							conversationId: 13488,
							disconImpact: -0.03289354,
							featureValue: 0,
						},
						{
							conversationId: 13490,
							disconImpact: -0.028295442,
							featureValue: 0,
						},
						{
							conversationId: 13492,
							disconImpact: -0.033194166,
							featureValue: 0,
						},
						{
							conversationId: 13495,
							disconImpact: -0.033652637,
							featureValue: 0,
						},
						{
							conversationId: 13497,
							disconImpact: -0.02288371,
							featureValue: 0,
						},
						{
							conversationId: 13498,
							disconImpact: -0.02419149,
							featureValue: 0,
						},
						{
							conversationId: 13499,
							disconImpact: -0.03315286,
							featureValue: 0,
						},
						{
							conversationId: 13500,
							disconImpact: -0.020833513,
							featureValue: 0,
						},
						{
							conversationId: 13501,
							disconImpact: -0.026280737,
							featureValue: 0,
						},
						{
							conversationId: 13502,
							disconImpact: -0.034005165,
							featureValue: 0,
						},
						{
							conversationId: 13503,
							disconImpact: -0.03118376,
							featureValue: 0,
						},
						{
							conversationId: 13505,
							disconImpact: -0.032827176,
							featureValue: 0,
						},
						{
							conversationId: 13507,
							disconImpact: -0.031521183,
							featureValue: 0,
						},
						{
							conversationId: 13508,
							disconImpact: -0.0332938,
							featureValue: 0,
						},
						{
							conversationId: 13509,
							disconImpact: -0.03059674,
							featureValue: 0,
						},
						{
							conversationId: 13511,
							disconImpact: -0.023401478,
							featureValue: 0,
						},
						{
							conversationId: 13512,
							disconImpact: -0.019041587,
							featureValue: 0,
						},
						{
							conversationId: 13515,
							disconImpact: -0.03250088,
							featureValue: 0,
						},
						{
							conversationId: 13516,
							disconImpact: -0.033889122,
							featureValue: 0,
						},
						{
							conversationId: 13517,
							disconImpact: 0.29378536,
							featureValue: 0.0234175334323913,
						},
						{
							conversationId: 13521,
							disconImpact: -0.02553808,
							featureValue: 0,
						},
						{
							conversationId: 13522,
							disconImpact: -0.035417844,
							featureValue: 0,
						},
						{
							conversationId: 13524,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 13525,
							disconImpact: -0.023509972,
							featureValue: 0,
						},
						{
							conversationId: 13526,
							disconImpact: -0.021337979,
							featureValue: 0,
						},
						{
							conversationId: 13528,
							disconImpact: -0.030901786,
							featureValue: 0,
						},
						{
							conversationId: 13530,
							disconImpact: -0.027496777,
							featureValue: 0,
						},
						{
							conversationId: 13532,
							disconImpact: -0.033533584,
							featureValue: 0,
						},
						{
							conversationId: 13533,
							disconImpact: -0.010020308,
							featureValue: 0,
						},
						{
							conversationId: 13534,
							disconImpact: 0.49577323,
							featureValue: 0.0373802956846488,
						},
						{
							conversationId: 13535,
							disconImpact: -0.028008979,
							featureValue: 0,
						},
						{
							conversationId: 13536,
							disconImpact: -0.029461142,
							featureValue: 0,
						},
						{
							conversationId: 13539,
							disconImpact: -0.03077133,
							featureValue: 0,
						},
						{
							conversationId: 13540,
							disconImpact: -0.019295625,
							featureValue: 0,
						},
						{
							conversationId: 13541,
							disconImpact: -0.033396307,
							featureValue: 0,
						},
						{
							conversationId: 13544,
							disconImpact: -0.034825824,
							featureValue: 0,
						},
						{
							conversationId: 13545,
							disconImpact: -0.023301635,
							featureValue: 0,
						},
						{
							conversationId: 13546,
							disconImpact: -0.031181779,
							featureValue: 0,
						},
						{
							conversationId: 13547,
							disconImpact: -0.030365117,
							featureValue: 0,
						},
						{
							conversationId: 13548,
							disconImpact: -0.030089546,
							featureValue: 0,
						},
						{
							conversationId: 13549,
							disconImpact: -0.026291696,
							featureValue: 0,
						},
						{
							conversationId: 13550,
							disconImpact: -0.037545487,
							featureValue: 0,
						},
						{
							conversationId: 13551,
							disconImpact: -0.035362963,
							featureValue: 0,
						},
						{
							conversationId: 13552,
							disconImpact: -0.032652844,
							featureValue: 0,
						},
						{
							conversationId: 13553,
							disconImpact: -0.02691925,
							featureValue: 0,
						},
						{
							conversationId: 13554,
							disconImpact: -0.033396307,
							featureValue: 0,
						},
						{
							conversationId: 13556,
							disconImpact: -0.028681876,
							featureValue: 0,
						},
						{
							conversationId: 13558,
							disconImpact: -0.024275435,
							featureValue: 0,
						},
						{
							conversationId: 13559,
							disconImpact: -0.027747817,
							featureValue: 0,
						},
						{
							conversationId: 13560,
							disconImpact: -0.022577628,
							featureValue: 0,
						},
						{
							conversationId: 13561,
							disconImpact: -0.02844216,
							featureValue: 0,
						},
						{
							conversationId: 13562,
							disconImpact: -0.026726097,
							featureValue: 0,
						},
						{
							conversationId: 13564,
							disconImpact: -0.026291978,
							featureValue: 0,
						},
						{
							conversationId: 13565,
							disconImpact: 0.4488868,
							featureValue: 0.040577959019282,
						},
						{
							conversationId: 13566,
							disconImpact: -0.030396089,
							featureValue: 0,
						},
						{
							conversationId: 13569,
							disconImpact: -0.041414715,
							featureValue: 0,
						},
						{
							conversationId: 13571,
							disconImpact: -0.022517562,
							featureValue: 0,
						},
						{
							conversationId: 13572,
							disconImpact: -0.032452285,
							featureValue: 0,
						},
						{
							conversationId: 13573,
							disconImpact: -0.03142008,
							featureValue: 0,
						},
						{
							conversationId: 13574,
							disconImpact: -0.032872096,
							featureValue: 0,
						},
						{
							conversationId: 13576,
							disconImpact: -0.024711741,
							featureValue: 0,
						},
						{
							conversationId: 13577,
							disconImpact: -0.030458327,
							featureValue: 0,
						},
						{
							conversationId: 13578,
							disconImpact: -0.028681025,
							featureValue: 0,
						},
						{
							conversationId: 13579,
							disconImpact: -0.026769787,
							featureValue: 0,
						},
						{
							conversationId: 13580,
							disconImpact: -0.032045025,
							featureValue: 0,
						},
						{
							conversationId: 13581,
							disconImpact: -0.03112303,
							featureValue: 0,
						},
						{
							conversationId: 13582,
							disconImpact: -0.036960404,
							featureValue: 0,
						},
						{
							conversationId: 13583,
							disconImpact: -0.031897567,
							featureValue: 0,
						},
						{
							conversationId: 13584,
							disconImpact: -0.029407024,
							featureValue: 0,
						},
						{
							conversationId: 13586,
							disconImpact: -0.03359439,
							featureValue: 0,
						},
						{
							conversationId: 13588,
							disconImpact: -0.025497615,
							featureValue: 0,
						},
						{
							conversationId: 13589,
							disconImpact: -0.028436884,
							featureValue: 0,
						},
						{
							conversationId: 13592,
							disconImpact: -0.0291364,
							featureValue: 0,
						},
						{
							conversationId: 13593,
							disconImpact: -0.03279494,
							featureValue: 0,
						},
						{
							conversationId: 13594,
							disconImpact: -0.033396307,
							featureValue: 0,
						},
						{
							conversationId: 13595,
							disconImpact: -0.03028027,
							featureValue: 0,
						},
						{
							conversationId: 13597,
							disconImpact: 0.3883949,
							featureValue: 0.0217382804863744,
						},
						{
							conversationId: 13598,
							disconImpact: -0.031059679,
							featureValue: 0,
						},
						{
							conversationId: 13599,
							disconImpact: -0.024725003,
							featureValue: 0,
						},
						{
							conversationId: 13601,
							disconImpact: -0.02580195,
							featureValue: 0,
						},
						{
							conversationId: 13603,
							disconImpact: -0.017067537,
							featureValue: 0,
						},
						{
							conversationId: 13605,
							disconImpact: -0.029653918,
							featureValue: 0,
						},
						{
							conversationId: 13606,
							disconImpact: 0.46820408,
							featureValue: 0.0213712077139628,
						},
						{
							conversationId: 13607,
							disconImpact: -0.030370157,
							featureValue: 0,
						},
						{
							conversationId: 13608,
							disconImpact: -0.032176286,
							featureValue: 0,
						},
						{
							conversationId: 13610,
							disconImpact: -0.031020045,
							featureValue: 0,
						},
						{
							conversationId: 13612,
							disconImpact: -0.026153285,
							featureValue: 0,
						},
						{
							conversationId: 13613,
							disconImpact: -0.034904033,
							featureValue: 0,
						},
						{
							conversationId: 13614,
							disconImpact: -0.025586903,
							featureValue: 0,
						},
						{
							conversationId: 13616,
							disconImpact: -0.029315393,
							featureValue: 0,
						},
						{
							conversationId: 13617,
							disconImpact: -0.027455647,
							featureValue: 0,
						},
						{
							conversationId: 13621,
							disconImpact: 0.9717252,
							featureValue: 0.0823135766561439,
						},
						{
							conversationId: 13623,
							disconImpact: -0.025064534,
							featureValue: 0,
						},
						{
							conversationId: 13624,
							disconImpact: -0.02332784,
							featureValue: 0,
						},
						{
							conversationId: 13625,
							disconImpact: -0.028718494,
							featureValue: 0,
						},
						{
							conversationId: 13626,
							disconImpact: -0.028463384,
							featureValue: 0,
						},
						{
							conversationId: 13628,
							disconImpact: -0.032780394,
							featureValue: 0,
						},
						{
							conversationId: 13629,
							disconImpact: -0.027706899,
							featureValue: 0,
						},
						{
							conversationId: 13630,
							disconImpact: -0.022204429,
							featureValue: 0,
						},
						{
							conversationId: 13631,
							disconImpact: -0.036916144,
							featureValue: 0,
						},
						{
							conversationId: 13633,
							disconImpact: -0.031110711,
							featureValue: 0,
						},
						{
							conversationId: 13635,
							disconImpact: -0.032648243,
							featureValue: 0,
						},
						{
							conversationId: 13636,
							disconImpact: -0.03775674,
							featureValue: 0,
						},
						{
							conversationId: 13637,
							disconImpact: -0.025302459,
							featureValue: 0,
						},
						{
							conversationId: 13638,
							disconImpact: -0.032982003,
							featureValue: 0,
						},
						{
							conversationId: 13639,
							disconImpact: -0.030043218,
							featureValue: 0,
						},
						{
							conversationId: 13641,
							disconImpact: -0.04126805,
							featureValue: 0,
						},
						{
							conversationId: 13642,
							disconImpact: -0.033502903,
							featureValue: 0,
						},
						{
							conversationId: 13644,
							disconImpact: -0.018964639,
							featureValue: 0,
						},
						{
							conversationId: 13647,
							disconImpact: 0.36035222,
							featureValue: 0.0322708767748982,
						},
						{
							conversationId: 13648,
							disconImpact: -0.033502903,
							featureValue: 0,
						},
						{
							conversationId: 13651,
							disconImpact: -0.033507474,
							featureValue: 0,
						},
						{
							conversationId: 13652,
							disconImpact: -0.033609618,
							featureValue: 0,
						},
						{
							conversationId: 13654,
							disconImpact: -0.028979495,
							featureValue: 0,
						},
						{
							conversationId: 13655,
							disconImpact: -0.0383623,
							featureValue: 0,
						},
						{
							conversationId: 13656,
							disconImpact: -0.030017283,
							featureValue: 0,
						},
						{
							conversationId: 13657,
							disconImpact: -0.034156412,
							featureValue: 0,
						},
						{
							conversationId: 13660,
							disconImpact: -0.032248627,
							featureValue: 0,
						},
						{
							conversationId: 13661,
							disconImpact: -0.009408594,
							featureValue: 0,
						},
						{
							conversationId: 13663,
							disconImpact: -0.031904973,
							featureValue: 0,
						},
						{
							conversationId: 13664,
							disconImpact: -0.03510728,
							featureValue: 0,
						},
						{
							conversationId: 13666,
							disconImpact: -0.034960125,
							featureValue: 0,
						},
						{
							conversationId: 13669,
							disconImpact: -0.0321614,
							featureValue: 0,
						},
						{
							conversationId: 13670,
							disconImpact: -0.030233543,
							featureValue: 0,
						},
						{
							conversationId: 13675,
							disconImpact: -0.029746499,
							featureValue: 0,
						},
						{
							conversationId: 13676,
							disconImpact: -0.024966158,
							featureValue: 0,
						},
						{
							conversationId: 13677,
							disconImpact: -0.02392438,
							featureValue: 0,
						},
						{
							conversationId: 13679,
							disconImpact: -0.023697326,
							featureValue: 0,
						},
						{
							conversationId: 13680,
							disconImpact: -0.033652637,
							featureValue: 0,
						},
						{
							conversationId: 13681,
							disconImpact: -0.031237736,
							featureValue: 0,
						},
						{
							conversationId: 13682,
							disconImpact: -0.025600739,
							featureValue: 0,
						},
						{
							conversationId: 13683,
							disconImpact: -0.03834291,
							featureValue: 0,
						},
						{
							conversationId: 13684,
							disconImpact: -0.033742882,
							featureValue: 0,
						},
						{
							conversationId: 13686,
							disconImpact: -0.030647837,
							featureValue: 0,
						},
						{
							conversationId: 13687,
							disconImpact: -0.041705124,
							featureValue: 0,
						},
						{
							conversationId: 13688,
							disconImpact: -0.033366952,
							featureValue: 0,
						},
						{
							conversationId: 13689,
							disconImpact: -0.018276809,
							featureValue: 0,
						},
						{
							conversationId: 13690,
							disconImpact: 0.19333076,
							featureValue: 0.0242190081127711,
						},
						{
							conversationId: 13691,
							disconImpact: -0.021854501,
							featureValue: 0,
						},
						{
							conversationId: 13694,
							disconImpact: -0.04171473,
							featureValue: 0,
						},
						{
							conversationId: 13695,
							disconImpact: -0.036514323,
							featureValue: 0,
						},
						{
							conversationId: 13696,
							disconImpact: -0.03423447,
							featureValue: 0,
						},
						{
							conversationId: 13700,
							disconImpact: -0.026177775,
							featureValue: 0,
						},
						{
							conversationId: 13701,
							disconImpact: -0.03357217,
							featureValue: 0,
						},
						{
							conversationId: 13702,
							disconImpact: -0.034679502,
							featureValue: 0,
						},
						{
							conversationId: 13703,
							disconImpact: -0.03064197,
							featureValue: 0,
						},
						{
							conversationId: 13706,
							disconImpact: -0.0389849,
							featureValue: 0,
						},
						{
							conversationId: 13707,
							disconImpact: -0.029330116,
							featureValue: 0,
						},
						{
							conversationId: 13709,
							disconImpact: -0.026849028,
							featureValue: 0,
						},
						{
							conversationId: 13710,
							disconImpact: -0.030465733,
							featureValue: 0,
						},
						{
							conversationId: 13711,
							disconImpact: -0.0375658,
							featureValue: 0,
						},
						{
							conversationId: 13712,
							disconImpact: -0.0301544,
							featureValue: 0,
						},
						{
							conversationId: 13713,
							disconImpact: 0.015723411,
							featureValue: 0.0210741487455197,
						},
						{
							conversationId: 13715,
							disconImpact: 0.6527958,
							featureValue: 0.0409329571025467,
						},
						{
							conversationId: 13720,
							disconImpact: -0.03423957,
							featureValue: 0,
						},
						{
							conversationId: 13721,
							disconImpact: -0.022739993,
							featureValue: 0,
						},
						{
							conversationId: 13723,
							disconImpact: -0.03166505,
							featureValue: 0,
						},
						{
							conversationId: 13724,
							disconImpact: -0.033307206,
							featureValue: 0,
						},
						{
							conversationId: 13728,
							disconImpact: -0.030535374,
							featureValue: 0,
						},
						{
							conversationId: 13733,
							disconImpact: -0.024184491,
							featureValue: 0,
						},
						{
							conversationId: 13734,
							disconImpact: -0.023246638,
							featureValue: 0,
						},
						{
							conversationId: 13736,
							disconImpact: -0.02362698,
							featureValue: 0,
						},
						{
							conversationId: 13737,
							disconImpact: -0.03922372,
							featureValue: 0,
						},
						{
							conversationId: 13743,
							disconImpact: -0.023537174,
							featureValue: 0,
						},
						{
							conversationId: 13745,
							disconImpact: -0.023211258,
							featureValue: 0,
						},
						{
							conversationId: 13746,
							disconImpact: -0.043689087,
							featureValue: 0,
						},
						{
							conversationId: 13747,
							disconImpact: -0.031183664,
							featureValue: 0,
						},
						{
							conversationId: 13750,
							disconImpact: -0.019158756,
							featureValue: 0,
						},
						{
							conversationId: 13751,
							disconImpact: -0.04164539,
							featureValue: 0,
						},
						{
							conversationId: 13752,
							disconImpact: -0.028881907,
							featureValue: 0,
						},
						{
							conversationId: 13754,
							disconImpact: -0.037605993,
							featureValue: 0,
						},
						{
							conversationId: 13756,
							disconImpact: -0.044061508,
							featureValue: 0,
						},
						{
							conversationId: 13757,
							disconImpact: -0.0338516,
							featureValue: 0,
						},
						{
							conversationId: 13758,
							disconImpact: -0.01702856,
							featureValue: 0,
						},
						{
							conversationId: 13759,
							disconImpact: -0.02900261,
							featureValue: 0,
						},
						{
							conversationId: 13761,
							disconImpact: -0.029542685,
							featureValue: 0,
						},
						{
							conversationId: 13764,
							disconImpact: -0.03792143,
							featureValue: 0,
						},
						{
							conversationId: 13765,
							disconImpact: -0.024514474,
							featureValue: 0,
						},
						{
							conversationId: 13766,
							disconImpact: -0.033137016,
							featureValue: 0,
						},
						{
							conversationId: 13768,
							disconImpact: -0.03257558,
							featureValue: 0,
						},
						{
							conversationId: 13769,
							disconImpact: -0.037719622,
							featureValue: 0,
						},
						{
							conversationId: 13770,
							disconImpact: -0.030092403,
							featureValue: 0,
						},
						{
							conversationId: 13772,
							disconImpact: -0.03602328,
							featureValue: 0,
						},
						{
							conversationId: 13773,
							disconImpact: -0.028360795,
							featureValue: 0,
						},
						{
							conversationId: 13774,
							disconImpact: -0.034784034,
							featureValue: 0,
						},
						{
							conversationId: 13776,
							disconImpact: -0.033645637,
							featureValue: 0,
						},
						{
							conversationId: 13777,
							disconImpact: -0.038516376,
							featureValue: 0,
						},
						{
							conversationId: 13778,
							disconImpact: -0.03434634,
							featureValue: 0,
						},
						{
							conversationId: 13781,
							disconImpact: -0.031437818,
							featureValue: 0,
						},
						{
							conversationId: 13782,
							disconImpact: -0.034898646,
							featureValue: 0,
						},
						{
							conversationId: 13784,
							disconImpact: -0.027025359,
							featureValue: 0,
						},
						{
							conversationId: 13787,
							disconImpact: -0.035054244,
							featureValue: 0,
						},
						{
							conversationId: 13791,
							disconImpact: -0.030471444,
							featureValue: 0,
						},
						{
							conversationId: 13792,
							disconImpact: -0.027979251,
							featureValue: 0,
						},
						{
							conversationId: 13793,
							disconImpact: -0.03436312,
							featureValue: 0,
						},
						{
							conversationId: 13794,
							disconImpact: -0.03085868,
							featureValue: 0,
						},
						{
							conversationId: 13796,
							disconImpact: -0.03433474,
							featureValue: 0,
						},
						{
							conversationId: 13799,
							disconImpact: -0.034943182,
							featureValue: 0,
						},
						{
							conversationId: 13800,
							disconImpact: -0.028146286,
							featureValue: 0,
						},
						{
							conversationId: 13804,
							disconImpact: -0.03648973,
							featureValue: 0,
						},
						{
							conversationId: 13805,
							disconImpact: -0.03644274,
							featureValue: 0,
						},
						{
							conversationId: 13806,
							disconImpact: -0.040277194,
							featureValue: 0,
						},
						{
							conversationId: 13807,
							disconImpact: -0.038014136,
							featureValue: 0,
						},
						{
							conversationId: 13808,
							disconImpact: -0.032735314,
							featureValue: 0,
						},
						{
							conversationId: 13810,
							disconImpact: -0.032901846,
							featureValue: 0,
						},
						{
							conversationId: 13812,
							disconImpact: -0.035069678,
							featureValue: 0,
						},
						{
							conversationId: 13813,
							disconImpact: -0.033434723,
							featureValue: 0,
						},
						{
							conversationId: 13815,
							disconImpact: -0.034258876,
							featureValue: 0,
						},
						{
							conversationId: 13816,
							disconImpact: -0.033968657,
							featureValue: 0,
						},
						{
							conversationId: 13820,
							disconImpact: -0.03652992,
							featureValue: 0,
						},
						{
							conversationId: 13821,
							disconImpact: -0.04951523,
							featureValue: 0,
						},
						{
							conversationId: 13822,
							disconImpact: -0.035031036,
							featureValue: 0,
						},
						{
							conversationId: 13823,
							disconImpact: -0.036097195,
							featureValue: 0,
						},
						{
							conversationId: 13824,
							disconImpact: -0.03488463,
							featureValue: 0,
						},
						{
							conversationId: 13826,
							disconImpact: -0.03583948,
							featureValue: 0,
						},
						{
							conversationId: 13827,
							disconImpact: -0.025967859,
							featureValue: 0,
						},
						{
							conversationId: 13828,
							disconImpact: -0.024644215,
							featureValue: 0,
						},
						{
							conversationId: 13829,
							disconImpact: -0.02273423,
							featureValue: 0,
						},
						{
							conversationId: 13830,
							disconImpact: -0.020693066,
							featureValue: 0,
						},
						{
							conversationId: 13831,
							disconImpact: -0.032622155,
							featureValue: 0,
						},
						{
							conversationId: 13832,
							disconImpact: -0.0221273,
							featureValue: 0,
						},
						{
							conversationId: 13834,
							disconImpact: -0.030158121,
							featureValue: 0,
						},
						{
							conversationId: 13839,
							disconImpact: -0.027158508,
							featureValue: 0,
						},
						{
							conversationId: 13840,
							disconImpact: -0.035975803,
							featureValue: 0,
						},
						{
							conversationId: 13841,
							disconImpact: -0.031099465,
							featureValue: 0,
						},
						{
							conversationId: 13842,
							disconImpact: -0.02652771,
							featureValue: 0,
						},
						{
							conversationId: 13843,
							disconImpact: -0.033943016,
							featureValue: 0,
						},
						{
							conversationId: 13844,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 13845,
							disconImpact: -0.032166447,
							featureValue: 0,
						},
						{
							conversationId: 13848,
							disconImpact: -0.0370753,
							featureValue: 0,
						},
						{
							conversationId: 13849,
							disconImpact: -0.02488406,
							featureValue: 0,
						},
						{
							conversationId: 13851,
							disconImpact: -0.02700178,
							featureValue: 0,
						},
						{
							conversationId: 13852,
							disconImpact: -0.03493565,
							featureValue: 0,
						},
						{
							conversationId: 13855,
							disconImpact: -0.035144523,
							featureValue: 0,
						},
						{
							conversationId: 13856,
							disconImpact: -0.030644987,
							featureValue: 0,
						},
						{
							conversationId: 13859,
							disconImpact: -0.031010259,
							featureValue: 0,
						},
						{
							conversationId: 13860,
							disconImpact: -0.029947773,
							featureValue: 0,
						},
						{
							conversationId: 13861,
							disconImpact: -0.023164049,
							featureValue: 0,
						},
						{
							conversationId: 13862,
							disconImpact: -0.016283117,
							featureValue: 0,
						},
						{
							conversationId: 13866,
							disconImpact: -0.03228894,
							featureValue: 0,
						},
						{
							conversationId: 13867,
							disconImpact: -0.03356876,
							featureValue: 0,
						},
						{
							conversationId: 13869,
							disconImpact: -0.024607452,
							featureValue: 0,
						},
						{
							conversationId: 13870,
							disconImpact: -0.036321204,
							featureValue: 0,
						},
						{
							conversationId: 13872,
							disconImpact: -0.03261884,
							featureValue: 0,
						},
						{
							conversationId: 13874,
							disconImpact: -0.029027235,
							featureValue: 0,
						},
						{
							conversationId: 13875,
							disconImpact: -0.027653856,
							featureValue: 0,
						},
						{
							conversationId: 13876,
							disconImpact: -0.036436066,
							featureValue: 0,
						},
						{
							conversationId: 13878,
							disconImpact: -0.035069678,
							featureValue: 0,
						},
						{
							conversationId: 13880,
							disconImpact: -0.039164174,
							featureValue: 0,
						},
						{
							conversationId: 13883,
							disconImpact: -0.034354642,
							featureValue: 0,
						},
						{
							conversationId: 13884,
							disconImpact: -0.033869084,
							featureValue: 0,
						},
						{
							conversationId: 13885,
							disconImpact: -0.022247681,
							featureValue: 0,
						},
						{
							conversationId: 13886,
							disconImpact: -0.035069678,
							featureValue: 0,
						},
						{
							conversationId: 13887,
							disconImpact: -0.027507588,
							featureValue: 0,
						},
						{
							conversationId: 13889,
							disconImpact: -0.033862147,
							featureValue: 0,
						},
						{
							conversationId: 13890,
							disconImpact: -0.0380428,
							featureValue: 0,
						},
						{
							conversationId: 13891,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 13892,
							disconImpact: -0.035908528,
							featureValue: 0,
						},
						{
							conversationId: 13893,
							disconImpact: -0.03878787,
							featureValue: 0,
						},
						{
							conversationId: 13894,
							disconImpact: -0.023093844,
							featureValue: 0,
						},
						{
							conversationId: 13895,
							disconImpact: -0.021110233,
							featureValue: 0,
						},
						{
							conversationId: 13897,
							disconImpact: -0.02496165,
							featureValue: 0,
						},
						{
							conversationId: 13898,
							disconImpact: -0.03430611,
							featureValue: 0,
						},
						{
							conversationId: 13900,
							disconImpact: -0.028042335,
							featureValue: 0,
						},
						{
							conversationId: 13902,
							disconImpact: -0.03543874,
							featureValue: 0,
						},
						{
							conversationId: 13905,
							disconImpact: -0.034769207,
							featureValue: 0,
						},
						{
							conversationId: 13906,
							disconImpact: -0.019721292,
							featureValue: 0,
						},
						{
							conversationId: 13909,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 13910,
							disconImpact: -0.032267734,
							featureValue: 0,
						},
						{
							conversationId: 13912,
							disconImpact: -0.032423824,
							featureValue: 0,
						},
						{
							conversationId: 13915,
							disconImpact: -0.025966195,
							featureValue: 0,
						},
						{
							conversationId: 13917,
							disconImpact: -0.025418095,
							featureValue: 0,
						},
						{
							conversationId: 13918,
							disconImpact: -0.023276605,
							featureValue: 0,
						},
						{
							conversationId: 13920,
							disconImpact: -0.035476275,
							featureValue: 0,
						},
						{
							conversationId: 13922,
							disconImpact: -0.03107968,
							featureValue: 0,
						},
						{
							conversationId: 13924,
							disconImpact: -0.03291525,
							featureValue: 0,
						},
						{
							conversationId: 13926,
							disconImpact: -0.032307003,
							featureValue: 0,
						},
						{
							conversationId: 13927,
							disconImpact: -0.037725005,
							featureValue: 0,
						},
						{
							conversationId: 13928,
							disconImpact: -0.029275097,
							featureValue: 0,
						},
						{
							conversationId: 13929,
							disconImpact: -0.040338077,
							featureValue: 0,
						},
						{
							conversationId: 13931,
							disconImpact: -0.035476275,
							featureValue: 0,
						},
						{
							conversationId: 13932,
							disconImpact: -0.02615527,
							featureValue: 0,
						},
						{
							conversationId: 13933,
							disconImpact: -0.035231773,
							featureValue: 0,
						},
						{
							conversationId: 13934,
							disconImpact: -0.025197985,
							featureValue: 0,
						},
						{
							conversationId: 13936,
							disconImpact: -0.02796195,
							featureValue: 0,
						},
						{
							conversationId: 13937,
							disconImpact: -0.02250364,
							featureValue: 0,
						},
						{
							conversationId: 13943,
							disconImpact: -0.030535374,
							featureValue: 0,
						},
						{
							conversationId: 13945,
							disconImpact: -0.026879888,
							featureValue: 0,
						},
						{
							conversationId: 13946,
							disconImpact: -0.03912055,
							featureValue: 0,
						},
						{
							conversationId: 13947,
							disconImpact: -0.03803959,
							featureValue: 0,
						},
						{
							conversationId: 13949,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 13951,
							disconImpact: -0.030103806,
							featureValue: 0,
						},
						{
							conversationId: 13953,
							disconImpact: -0.027662644,
							featureValue: 0,
						},
						{
							conversationId: 13956,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 13958,
							disconImpact: -0.030084493,
							featureValue: 0,
						},
						{
							conversationId: 13961,
							disconImpact: -0.030433131,
							featureValue: 0,
						},
						{
							conversationId: 13966,
							disconImpact: -0.018927554,
							featureValue: 0,
						},
						{
							conversationId: 13971,
							disconImpact: -0.043088187,
							featureValue: 0,
						},
						{
							conversationId: 13972,
							disconImpact: -0.030286964,
							featureValue: 0,
						},
						{
							conversationId: 13973,
							disconImpact: -0.025329905,
							featureValue: 0,
						},
						{
							conversationId: 13974,
							disconImpact: -0.029159775,
							featureValue: 0,
						},
						{
							conversationId: 13975,
							disconImpact: -0.025287613,
							featureValue: 0,
						},
						{
							conversationId: 13977,
							disconImpact: -0.027685331,
							featureValue: 0,
						},
						{
							conversationId: 13979,
							disconImpact: -0.03935762,
							featureValue: 0,
						},
						{
							conversationId: 13980,
							disconImpact: -0.02493087,
							featureValue: 0,
						},
						{
							conversationId: 13982,
							disconImpact: -0.031124312,
							featureValue: 0,
						},
						{
							conversationId: 13983,
							disconImpact: -0.030151716,
							featureValue: 0,
						},
						{
							conversationId: 13986,
							disconImpact: -0.025395812,
							featureValue: 0,
						},
						{
							conversationId: 13987,
							disconImpact: -0.036849022,
							featureValue: 0,
						},
						{
							conversationId: 13988,
							disconImpact: -0.019469375,
							featureValue: 0,
						},
						{
							conversationId: 13996,
							disconImpact: -0.031171044,
							featureValue: 0,
						},
						{
							conversationId: 13997,
							disconImpact: -0.03642081,
							featureValue: 0,
						},
						{
							conversationId: 13998,
							disconImpact: -0.02992493,
							featureValue: 0,
						},
						{
							conversationId: 14000,
							disconImpact: -0.032042325,
							featureValue: 0,
						},
						{
							conversationId: 14005,
							disconImpact: -0.022647459,
							featureValue: 0,
						},
						{
							conversationId: 14008,
							disconImpact: -0.028432632,
							featureValue: 0,
						},
						{
							conversationId: 14011,
							disconImpact: -0.03125969,
							featureValue: 0,
						},
						{
							conversationId: 14013,
							disconImpact: -0.029476989,
							featureValue: 0,
						},
						{
							conversationId: 14014,
							disconImpact: -0.038312454,
							featureValue: 0,
						},
						{
							conversationId: 14015,
							disconImpact: -0.038473442,
							featureValue: 0,
						},
						{
							conversationId: 14016,
							disconImpact: -0.03501372,
							featureValue: 0,
						},
						{
							conversationId: 14021,
							disconImpact: -0.027911788,
							featureValue: 0,
						},
						{
							conversationId: 14025,
							disconImpact: -0.033761427,
							featureValue: 0,
						},
						{
							conversationId: 14027,
							disconImpact: -0.018401153,
							featureValue: 0,
						},
						{
							conversationId: 14030,
							disconImpact: -0.032760713,
							featureValue: 0,
						},
						{
							conversationId: 14031,
							disconImpact: -0.03300164,
							featureValue: 0,
						},
						{
							conversationId: 14032,
							disconImpact: -0.03195671,
							featureValue: 0,
						},
						{
							conversationId: 14035,
							disconImpact: -0.03423551,
							featureValue: 0,
						},
						{
							conversationId: 14041,
							disconImpact: -0.026625784,
							featureValue: 0,
						},
						{
							conversationId: 14047,
							disconImpact: -0.032585703,
							featureValue: 0,
						},
						{
							conversationId: 14048,
							disconImpact: -0.033132546,
							featureValue: 0,
						},
						{
							conversationId: 14049,
							disconImpact: -0.02758281,
							featureValue: 0,
						},
						{
							conversationId: 14053,
							disconImpact: -0.0226122,
							featureValue: 0,
						},
						{
							conversationId: 14055,
							disconImpact: -0.030271858,
							featureValue: 0,
						},
						{
							conversationId: 14056,
							disconImpact: -0.030650727,
							featureValue: 0,
						},
						{
							conversationId: 14059,
							disconImpact: -0.015884977,
							featureValue: 0,
						},
						{
							conversationId: 14060,
							disconImpact: -0.027992535,
							featureValue: 0,
						},
						{
							conversationId: 14061,
							disconImpact: -0.042729706,
							featureValue: 0,
						},
						{
							conversationId: 14062,
							disconImpact: -0.028120207,
							featureValue: 0,
						},
						{
							conversationId: 14064,
							disconImpact: -0.02609944,
							featureValue: 0,
						},
						{
							conversationId: 14071,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 14072,
							disconImpact: -0.028116576,
							featureValue: 0,
						},
						{
							conversationId: 14079,
							disconImpact: -0.04049639,
							featureValue: 0,
						},
						{
							conversationId: 14081,
							disconImpact: -0.021659404,
							featureValue: 0,
						},
						{
							conversationId: 14084,
							disconImpact: -0.028025055,
							featureValue: 0,
						},
						{
							conversationId: 14086,
							disconImpact: -0.0314848,
							featureValue: 0,
						},
						{
							conversationId: 14091,
							disconImpact: -0.036528364,
							featureValue: 0,
						},
						{
							conversationId: 14094,
							disconImpact: -0.03064197,
							featureValue: 0,
						},
						{
							conversationId: 14096,
							disconImpact: -0.022476163,
							featureValue: 0,
						},
						{
							conversationId: 14101,
							disconImpact: -0.027053501,
							featureValue: 0,
						},
						{
							conversationId: 14102,
							disconImpact: 0.490444,
							featureValue: 0.0299136406201227,
						},
						{
							conversationId: 14106,
							disconImpact: -0.039967902,
							featureValue: 0,
						},
						{
							conversationId: 14107,
							disconImpact: -0.02813669,
							featureValue: 0,
						},
						{
							conversationId: 14110,
							disconImpact: -0.03268359,
							featureValue: 0,
						},
						{
							conversationId: 14113,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 14119,
							disconImpact: -0.024766173,
							featureValue: 0,
						},
						{
							conversationId: 14123,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 14124,
							disconImpact: -0.03759374,
							featureValue: 0,
						},
						{
							conversationId: 14125,
							disconImpact: -0.027637282,
							featureValue: 0,
						},
						{
							conversationId: 14132,
							disconImpact: -0.029783314,
							featureValue: 0,
						},
						{
							conversationId: 14134,
							disconImpact: -0.026829362,
							featureValue: 0,
						},
						{
							conversationId: 14140,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 14147,
							disconImpact: -0.03373379,
							featureValue: 0,
						},
						{
							conversationId: 14160,
							disconImpact: -0.0335574,
							featureValue: 0,
						},
						{
							conversationId: 14186,
							disconImpact: -0.027104689,
							featureValue: 0,
						},
						{
							conversationId: 14252,
							disconImpact: -0.030378316,
							featureValue: 0,
						},
						{
							conversationId: 14283,
							disconImpact: -0.02726851,
							featureValue: 0,
						},
						{
							conversationId: 14318,
							disconImpact: -0.03333744,
							featureValue: 0,
						},
						{
							conversationId: 14379,
							disconImpact: -0.02641726,
							featureValue: 0,
						},
						{
							conversationId: 14856,
							disconImpact: -0.035069678,
							featureValue: 0,
						},
						{
							conversationId: 14857,
							disconImpact: -0.027939798,
							featureValue: 0,
						},
						{
							conversationId: 14859,
							disconImpact: -0.025127944,
							featureValue: 0,
						},
						{
							conversationId: 14868,
							disconImpact: -0.03000979,
							featureValue: 0,
						},
						{
							conversationId: 14871,
							disconImpact: -0.025582133,
							featureValue: 0,
						},
						{
							conversationId: 14875,
							disconImpact: -0.019736378,
							featureValue: 0,
						},
						{
							conversationId: 14877,
							disconImpact: -0.022167478,
							featureValue: 0,
						},
						{
							conversationId: 14906,
							disconImpact: -0.028896656,
							featureValue: 0,
						},
						{
							conversationId: 14908,
							disconImpact: -0.03035174,
							featureValue: 0,
						},
						{
							conversationId: 14910,
							disconImpact: -0.028494962,
							featureValue: 0,
						},
						{
							conversationId: 14915,
							disconImpact: -0.03447204,
							featureValue: 0,
						},
						{
							conversationId: 14919,
							disconImpact: -0.02303322,
							featureValue: 0,
						},
						{
							conversationId: 14921,
							disconImpact: -0.03771699,
							featureValue: 0,
						},
						{
							conversationId: 14924,
							disconImpact: -0.02869978,
							featureValue: 0,
						},
						{
							conversationId: 14925,
							disconImpact: -0.03333744,
							featureValue: 0,
						},
						{
							conversationId: 14927,
							disconImpact: -0.035069678,
							featureValue: 0,
						},
						{
							conversationId: 14928,
							disconImpact: -0.026757695,
							featureValue: 0,
						},
						{
							conversationId: 14932,
							disconImpact: -0.02646738,
							featureValue: 0,
						},
						{
							conversationId: 14934,
							disconImpact: -0.039179955,
							featureValue: 0,
						},
						{
							conversationId: 14940,
							disconImpact: -0.038551766,
							featureValue: 0,
						},
						{
							conversationId: 14944,
							disconImpact: -0.033110622,
							featureValue: 0,
						},
						{
							conversationId: 14947,
							disconImpact: -0.0137209585,
							featureValue: 0,
						},
						{
							conversationId: 14949,
							disconImpact: -0.033210542,
							featureValue: 0,
						},
						{
							conversationId: 14958,
							disconImpact: -0.031722434,
							featureValue: 0,
						},
						{
							conversationId: 14962,
							disconImpact: -0.028642004,
							featureValue: 0,
						},
						{
							conversationId: 14966,
							disconImpact: -0.03085377,
							featureValue: 0,
						},
						{
							conversationId: 14974,
							disconImpact: -0.031853706,
							featureValue: 0,
						},
						{
							conversationId: 14981,
							disconImpact: -0.03239947,
							featureValue: 0,
						},
						{
							conversationId: 14992,
							disconImpact: -0.03430611,
							featureValue: 0,
						},
						{
							conversationId: 14993,
							disconImpact: -0.036681566,
							featureValue: 0,
						},
						{
							conversationId: 14995,
							disconImpact: -0.019732874,
							featureValue: 0,
						},
						{
							conversationId: 14996,
							disconImpact: -0.018070642,
							featureValue: 0,
						},
						{
							conversationId: 14998,
							disconImpact: -0.034437507,
							featureValue: 0,
						},
						{
							conversationId: 15001,
							disconImpact: -0.03129574,
							featureValue: 0,
						},
						{
							conversationId: 15013,
							disconImpact: -0.031124536,
							featureValue: 0,
						},
						{
							conversationId: 15016,
							disconImpact: -0.033637643,
							featureValue: 0,
						},
						{
							conversationId: 15017,
							disconImpact: -0.025324743,
							featureValue: 0,
						},
						{
							conversationId: 15018,
							disconImpact: -0.023313625,
							featureValue: 0,
						},
						{
							conversationId: 15020,
							disconImpact: -0.026038654,
							featureValue: 0,
						},
						{
							conversationId: 15029,
							disconImpact: -0.030908875,
							featureValue: 0,
						},
						{
							conversationId: 15031,
							disconImpact: -0.026248252,
							featureValue: 0,
						},
						{
							conversationId: 15032,
							disconImpact: -0.026723314,
							featureValue: 0,
						},
						{
							conversationId: 15033,
							disconImpact: -0.024058115,
							featureValue: 0,
						},
						{
							conversationId: 15035,
							disconImpact: 1.205645,
							featureValue: 0.169728896312476,
						},
						{
							conversationId: 15036,
							disconImpact: -0.023841552,
							featureValue: 0,
						},
						{
							conversationId: 15040,
							disconImpact: -0.031632103,
							featureValue: 0.0072787050837051,
						},
						{
							conversationId: 15041,
							disconImpact: -0.032777175,
							featureValue: 0,
						},
						{
							conversationId: 15043,
							disconImpact: -0.19678928,
							featureValue: 0.0150417238829618,
						},
						{
							conversationId: 15044,
							disconImpact: -0.032098807,
							featureValue: 0,
						},
						{
							conversationId: 15045,
							disconImpact: -0.029734276,
							featureValue: 0,
						},
						{
							conversationId: 15046,
							disconImpact: -0.025764959,
							featureValue: 0,
						},
						{
							conversationId: 15049,
							disconImpact: -0.034171835,
							featureValue: 0,
						},
						{
							conversationId: 15050,
							disconImpact: -0.029541137,
							featureValue: 0,
						},
						{
							conversationId: 15051,
							disconImpact: -0.023723291,
							featureValue: 0,
						},
						{
							conversationId: 15053,
							disconImpact: 0.30896255,
							featureValue: 0.0305264001281948,
						},
						{
							conversationId: 15055,
							disconImpact: -0.037534244,
							featureValue: 0,
						},
						{
							conversationId: 15057,
							disconImpact: -0.0300644,
							featureValue: 0,
						},
						{
							conversationId: 15060,
							disconImpact: -0.030809838,
							featureValue: 0,
						},
						{
							conversationId: 15061,
							disconImpact: -0.026570404,
							featureValue: 0,
						},
						{
							conversationId: 15063,
							disconImpact: -0.051609237,
							featureValue: 0.0102154224502824,
						},
						{
							conversationId: 15064,
							disconImpact: -0.037511133,
							featureValue: 0,
						},
						{
							conversationId: 15066,
							disconImpact: -0.030918255,
							featureValue: 0,
						},
						{
							conversationId: 15067,
							disconImpact: -0.03918096,
							featureValue: 0,
						},
						{
							conversationId: 15069,
							disconImpact: -0.031224504,
							featureValue: 0,
						},
						{
							conversationId: 15072,
							disconImpact: -0.032204248,
							featureValue: 0,
						},
						{
							conversationId: 15080,
							disconImpact: -0.030396905,
							featureValue: 0,
						},
						{
							conversationId: 15083,
							disconImpact: -0.022132358,
							featureValue: 0,
						},
						{
							conversationId: 15086,
							disconImpact: -0.03048422,
							featureValue: 0,
						},
						{
							conversationId: 15088,
							disconImpact: -0.027639251,
							featureValue: 0,
						},
						{
							conversationId: 15090,
							disconImpact: -0.034803912,
							featureValue: 0,
						},
						{
							conversationId: 15099,
							disconImpact: 0.51165676,
							featureValue: 0.0406662368929994,
						},
						{
							conversationId: 15110,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 15114,
							disconImpact: -0.026182616,
							featureValue: 0,
						},
						{
							conversationId: 15122,
							disconImpact: -0.035678174,
							featureValue: 0,
						},
						{
							conversationId: 15127,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 15129,
							disconImpact: -0.029257705,
							featureValue: 0,
						},
						{
							conversationId: 15132,
							disconImpact: -0.033048954,
							featureValue: 0,
						},
						{
							conversationId: 15133,
							disconImpact: -0.031710066,
							featureValue: 0,
						},
						{
							conversationId: 15145,
							disconImpact: -0.03254258,
							featureValue: 0,
						},
						{
							conversationId: 15151,
							disconImpact: -0.03105603,
							featureValue: 0,
						},
						{
							conversationId: 15154,
							disconImpact: -0.037916936,
							featureValue: 0,
						},
						{
							conversationId: 15158,
							disconImpact: -0.030811537,
							featureValue: 0,
						},
						{
							conversationId: 15163,
							disconImpact: -0.03345371,
							featureValue: 0,
						},
						{
							conversationId: 15164,
							disconImpact: -0.031304073,
							featureValue: 0,
						},
						{
							conversationId: 15169,
							disconImpact: -0.031005114,
							featureValue: 0,
						},
						{
							conversationId: 15174,
							disconImpact: -0.03679365,
							featureValue: 0,
						},
						{
							conversationId: 15177,
							disconImpact: -0.024538934,
							featureValue: 0,
						},
						{
							conversationId: 15178,
							disconImpact: -0.033396307,
							featureValue: 0,
						},
						{
							conversationId: 15179,
							disconImpact: -0.025920458,
							featureValue: 0,
						},
						{
							conversationId: 15184,
							disconImpact: -0.035476275,
							featureValue: 0,
						},
						{
							conversationId: 15194,
							disconImpact: -0.03109809,
							featureValue: 0,
						},
						{
							conversationId: 15197,
							disconImpact: -0.035979602,
							featureValue: 0,
						},
						{
							conversationId: 15221,
							disconImpact: -0.033889122,
							featureValue: 0,
						},
						{
							conversationId: 15223,
							disconImpact: -0.026734171,
							featureValue: 0,
						},
						{
							conversationId: 15228,
							disconImpact: -0.019154286,
							featureValue: 0,
						},
						{
							conversationId: 15232,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 15240,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 15243,
							disconImpact: -0.037604615,
							featureValue: 0,
						},
						{
							conversationId: 15248,
							disconImpact: -0.034156498,
							featureValue: 0,
						},
						{
							conversationId: 15249,
							disconImpact: -0.029472398,
							featureValue: 0,
						},
						{
							conversationId: 15253,
							disconImpact: -0.0348674,
							featureValue: 0,
						},
						{
							conversationId: 15263,
							disconImpact: -0.015195175,
							featureValue: 0,
						},
						{
							conversationId: 15267,
							disconImpact: -0.026740475,
							featureValue: 0,
						},
						{
							conversationId: 15269,
							disconImpact: -0.019639008,
							featureValue: 0,
						},
						{
							conversationId: 15270,
							disconImpact: -0.036422346,
							featureValue: 0,
						},
						{
							conversationId: 15272,
							disconImpact: -0.024606228,
							featureValue: 0,
						},
						{
							conversationId: 15273,
							disconImpact: -0.03849324,
							featureValue: 0,
						},
						{
							conversationId: 15280,
							disconImpact: -0.028480045,
							featureValue: 0,
						},
						{
							conversationId: 15287,
							disconImpact: -0.020636475,
							featureValue: 0,
						},
						{
							conversationId: 15288,
							disconImpact: -0.026802648,
							featureValue: 0,
						},
						{
							conversationId: 15289,
							disconImpact: -0.02739085,
							featureValue: 0,
						},
						{
							conversationId: 15290,
							disconImpact: -0.025977273,
							featureValue: 0,
						},
						{
							conversationId: 15295,
							disconImpact: -0.018072538,
							featureValue: 0,
						},
						{
							conversationId: 15296,
							disconImpact: -0.026358359,
							featureValue: 0,
						},
						{
							conversationId: 15300,
							disconImpact: -0.026251376,
							featureValue: 0,
						},
						{
							conversationId: 15305,
							disconImpact: -0.03720402,
							featureValue: 0,
						},
						{
							conversationId: 15307,
							disconImpact: -0.026173728,
							featureValue: 0,
						},
						{
							conversationId: 15313,
							disconImpact: -0.027155586,
							featureValue: 0,
						},
						{
							conversationId: 15317,
							disconImpact: -0.027108839,
							featureValue: 0,
						},
						{
							conversationId: 15318,
							disconImpact: -0.031359494,
							featureValue: 0,
						},
						{
							conversationId: 15329,
							disconImpact: -0.026166912,
							featureValue: 0,
						},
						{
							conversationId: 15333,
							disconImpact: -0.03213015,
							featureValue: 0,
						},
						{
							conversationId: 15334,
							disconImpact: -0.029861338,
							featureValue: 0,
						},
						{
							conversationId: 15346,
							disconImpact: -0.029942665,
							featureValue: 0,
						},
						{
							conversationId: 15347,
							disconImpact: -0.028755758,
							featureValue: 0,
						},
						{
							conversationId: 15350,
							disconImpact: -0.026969695,
							featureValue: 0,
						},
						{
							conversationId: 15352,
							disconImpact: -0.031583276,
							featureValue: 0,
						},
						{
							conversationId: 15353,
							disconImpact: -0.022569463,
							featureValue: 0,
						},
						{
							conversationId: 15356,
							disconImpact: -0.03520788,
							featureValue: 0,
						},
						{
							conversationId: 15357,
							disconImpact: -0.011589424,
							featureValue: 0,
						},
						{
							conversationId: 15359,
							disconImpact: -0.032381464,
							featureValue: 0,
						},
						{
							conversationId: 15360,
							disconImpact: -0.026187353,
							featureValue: 0,
						},
						{
							conversationId: 15361,
							disconImpact: -0.026770566,
							featureValue: 0,
						},
						{
							conversationId: 15365,
							disconImpact: -0.032386567,
							featureValue: 0,
						},
						{
							conversationId: 15368,
							disconImpact: -0.020375133,
							featureValue: 0,
						},
						{
							conversationId: 15369,
							disconImpact: -0.044549074,
							featureValue: 0,
						},
						{
							conversationId: 15372,
							disconImpact: -0.039775502,
							featureValue: 0,
						},
						{
							conversationId: 15379,
							disconImpact: -0.030129887,
							featureValue: 0,
						},
						{
							conversationId: 15380,
							disconImpact: -0.036380123,
							featureValue: 0,
						},
						{
							conversationId: 15381,
							disconImpact: -0.028924119,
							featureValue: 0,
						},
						{
							conversationId: 15385,
							disconImpact: -0.034553744,
							featureValue: 0,
						},
						{
							conversationId: 15389,
							disconImpact: -0.035800703,
							featureValue: 0,
						},
						{
							conversationId: 15391,
							disconImpact: -0.032802694,
							featureValue: 0,
						},
						{
							conversationId: 15395,
							disconImpact: -0.034002777,
							featureValue: 0,
						},
						{
							conversationId: 15398,
							disconImpact: -0.023807941,
							featureValue: 0,
						},
						{
							conversationId: 15401,
							disconImpact: -0.026296834,
							featureValue: 0,
						},
						{
							conversationId: 15405,
							disconImpact: -0.02854371,
							featureValue: 0,
						},
						{
							conversationId: 15407,
							disconImpact: -0.034142233,
							featureValue: 0,
						},
						{
							conversationId: 15408,
							disconImpact: -0.028656868,
							featureValue: 0,
						},
						{
							conversationId: 15410,
							disconImpact: -0.035069678,
							featureValue: 0,
						},
						{
							conversationId: 15412,
							disconImpact: -0.032511383,
							featureValue: 0,
						},
						{
							conversationId: 15413,
							disconImpact: -0.024798699,
							featureValue: 0,
						},
						{
							conversationId: 15414,
							disconImpact: -0.033116825,
							featureValue: 0,
						},
						{
							conversationId: 15419,
							disconImpact: -0.030535374,
							featureValue: 0,
						},
						{
							conversationId: 15423,
							disconImpact: -0.03750442,
							featureValue: 0,
						},
						{
							conversationId: 15424,
							disconImpact: -0.03530531,
							featureValue: 0,
						},
						{
							conversationId: 15427,
							disconImpact: -0.027284784,
							featureValue: 0,
						},
						{
							conversationId: 15429,
							disconImpact: -0.025039542,
							featureValue: 0,
						},
						{
							conversationId: 15434,
							disconImpact: -0.038347952,
							featureValue: 0,
						},
						{
							conversationId: 15436,
							disconImpact: -0.028641332,
							featureValue: 0,
						},
						{
							conversationId: 15439,
							disconImpact: -0.0368439,
							featureValue: 0,
						},
						{
							conversationId: 15441,
							disconImpact: -0.033684496,
							featureValue: 0,
						},
						{
							conversationId: 15442,
							disconImpact: -0.025903204,
							featureValue: 0,
						},
						{
							conversationId: 15444,
							disconImpact: -0.03730289,
							featureValue: 0,
						},
						{
							conversationId: 15450,
							disconImpact: -0.027431093,
							featureValue: 0,
						},
						{
							conversationId: 15451,
							disconImpact: -0.03274468,
							featureValue: 0,
						},
						{
							conversationId: 15452,
							disconImpact: -0.032815486,
							featureValue: 0,
						},
						{
							conversationId: 15454,
							disconImpact: -0.037982386,
							featureValue: 0,
						},
						{
							conversationId: 15459,
							disconImpact: -0.018855596,
							featureValue: 0,
						},
						{
							conversationId: 15463,
							disconImpact: -0.027947737,
							featureValue: 0,
						},
						{
							conversationId: 15466,
							disconImpact: -0.02987323,
							featureValue: 0,
						},
						{
							conversationId: 15472,
							disconImpact: -0.03333744,
							featureValue: 0,
						},
						{
							conversationId: 15480,
							disconImpact: -0.037511054,
							featureValue: 0,
						},
						{
							conversationId: 15481,
							disconImpact: -0.01779579,
							featureValue: 0,
						},
						{
							conversationId: 15485,
							disconImpact: -0.027135748,
							featureValue: 0,
						},
						{
							conversationId: 15491,
							disconImpact: -0.033989333,
							featureValue: 0,
						},
						{
							conversationId: 15493,
							disconImpact: -0.022615569,
							featureValue: 0,
						},
						{
							conversationId: 15494,
							disconImpact: -0.024390938,
							featureValue: 0,
						},
						{
							conversationId: 15497,
							disconImpact: -0.034146857,
							featureValue: 0,
						},
						{
							conversationId: 15503,
							disconImpact: -0.030796869,
							featureValue: 0,
						},
						{
							conversationId: 15507,
							disconImpact: -0.03328175,
							featureValue: 0,
						},
						{
							conversationId: 15515,
							disconImpact: -0.03551505,
							featureValue: 0,
						},
						{
							conversationId: 15516,
							disconImpact: 0.12249129,
							featureValue: 0.0180903460593767,
						},
						{
							conversationId: 15517,
							disconImpact: -0.018600922,
							featureValue: 0,
						},
						{
							conversationId: 15520,
							disconImpact: -0.04628154,
							featureValue: 0,
						},
						{
							conversationId: 15525,
							disconImpact: -0.025319248,
							featureValue: 0,
						},
						{
							conversationId: 15529,
							disconImpact: -0.030296147,
							featureValue: 0,
						},
						{
							conversationId: 15537,
							disconImpact: -0.033402205,
							featureValue: 0,
						},
						{
							conversationId: 15538,
							disconImpact: -0.023950856,
							featureValue: 0,
						},
						{
							conversationId: 15541,
							disconImpact: -0.04144389,
							featureValue: 0,
						},
						{
							conversationId: 15543,
							disconImpact: -0.02474047,
							featureValue: 0,
						},
						{
							conversationId: 15547,
							disconImpact: 0.62824875,
							featureValue: 0.0349345485828546,
						},
						{
							conversationId: 15551,
							disconImpact: -0.040159702,
							featureValue: 0,
						},
						{
							conversationId: 15558,
							disconImpact: -0.02736744,
							featureValue: 0,
						},
						{
							conversationId: 15564,
							disconImpact: -0.023151806,
							featureValue: 0,
						},
						{
							conversationId: 15565,
							disconImpact: -0.026402196,
							featureValue: 0,
						},
						{
							conversationId: 15567,
							disconImpact: -0.03279781,
							featureValue: 0,
						},
						{
							conversationId: 15569,
							disconImpact: -0.033516426,
							featureValue: 0,
						},
						{
							conversationId: 15570,
							disconImpact: -0.036218517,
							featureValue: 0,
						},
						{
							conversationId: 15571,
							disconImpact: -0.025419064,
							featureValue: 0,
						},
						{
							conversationId: 15572,
							disconImpact: -0.039167143,
							featureValue: 0,
						},
						{
							conversationId: 15573,
							disconImpact: -0.02288477,
							featureValue: 0,
						},
						{
							conversationId: 15577,
							disconImpact: -0.03810401,
							featureValue: 0,
						},
						{
							conversationId: 15578,
							disconImpact: -0.026782934,
							featureValue: 0,
						},
						{
							conversationId: 15580,
							disconImpact: -0.02883416,
							featureValue: 0,
						},
						{
							conversationId: 15582,
							disconImpact: -0.03357735,
							featureValue: 0,
						},
						{
							conversationId: 15583,
							disconImpact: -0.018803094,
							featureValue: 0,
						},
						{
							conversationId: 15588,
							disconImpact: -0.035476275,
							featureValue: 0,
						},
						{
							conversationId: 15590,
							disconImpact: -0.1491396,
							featureValue: 0.0144197310915012,
						},
						{
							conversationId: 15599,
							disconImpact: -0.022659894,
							featureValue: 0,
						},
						{
							conversationId: 15600,
							disconImpact: -0.02567826,
							featureValue: 0,
						},
						{
							conversationId: 15604,
							disconImpact: -0.033950787,
							featureValue: 0,
						},
						{
							conversationId: 15610,
							disconImpact: -0.03770385,
							featureValue: 0,
						},
						{
							conversationId: 15611,
							disconImpact: -0.030694101,
							featureValue: 0,
						},
						{
							conversationId: 15615,
							disconImpact: -0.034562897,
							featureValue: 0,
						},
						{
							conversationId: 15618,
							disconImpact: -0.03544359,
							featureValue: 0,
						},
						{
							conversationId: 15623,
							disconImpact: -0.03168738,
							featureValue: 0,
						},
						{
							conversationId: 15624,
							disconImpact: 1.4906156,
							featureValue: 0.1407751543308087,
						},
						{
							conversationId: 15628,
							disconImpact: 0.11108311,
							featureValue: 0.0157551843072687,
						},
						{
							conversationId: 15631,
							disconImpact: -0.02578694,
							featureValue: 0,
						},
						{
							conversationId: 15632,
							disconImpact: -0.027779646,
							featureValue: 0,
						},
						{
							conversationId: 15636,
							disconImpact: -0.020639207,
							featureValue: 0,
						},
						{
							conversationId: 15637,
							disconImpact: -0.036345184,
							featureValue: 0,
						},
						{
							conversationId: 15647,
							disconImpact: -0.025207132,
							featureValue: 0,
						},
						{
							conversationId: 15648,
							disconImpact: -0.03357735,
							featureValue: 0,
						},
						{
							conversationId: 15652,
							disconImpact: -0.023758706,
							featureValue: 0,
						},
						{
							conversationId: 15653,
							disconImpact: -0.017711997,
							featureValue: 0,
						},
						{
							conversationId: 15659,
							disconImpact: -0.03615981,
							featureValue: 0,
						},
						{
							conversationId: 15665,
							disconImpact: -0.02906571,
							featureValue: 0,
						},
						{
							conversationId: 15667,
							disconImpact: -0.030497352,
							featureValue: 0,
						},
						{
							conversationId: 15676,
							disconImpact: -0.029645186,
							featureValue: 0,
						},
						{
							conversationId: 15677,
							disconImpact: -0.032069795,
							featureValue: 0,
						},
						{
							conversationId: 15680,
							disconImpact: -0.021054853,
							featureValue: 0,
						},
						{
							conversationId: 15681,
							disconImpact: 0.06376158,
							featureValue: 0.01509411448856,
						},
						{
							conversationId: 15683,
							disconImpact: -0.03598687,
							featureValue: 0,
						},
						{
							conversationId: 15685,
							disconImpact: -0.03452821,
							featureValue: 0,
						},
						{
							conversationId: 15689,
							disconImpact: -0.034951307,
							featureValue: 0,
						},
						{
							conversationId: 15690,
							disconImpact: -0.09042252,
							featureValue: 0.0136002249661273,
						},
						{
							conversationId: 15691,
							disconImpact: -0.039688446,
							featureValue: 0,
						},
						{
							conversationId: 15696,
							disconImpact: -0.033889122,
							featureValue: 0,
						},
						{
							conversationId: 15703,
							disconImpact: -0.03691253,
							featureValue: 0,
						},
						{
							conversationId: 15723,
							disconImpact: -0.030016595,
							featureValue: 0,
						},
						{
							conversationId: 15727,
							disconImpact: -0.025308898,
							featureValue: 0,
						},
						{
							conversationId: 15729,
							disconImpact: -0.02941372,
							featureValue: 0,
						},
						{
							conversationId: 15730,
							disconImpact: -0.038989652,
							featureValue: 0,
						},
						{
							conversationId: 15733,
							disconImpact: -0.038594343,
							featureValue: 0,
						},
						{
							conversationId: 15735,
							disconImpact: -0.031877797,
							featureValue: 0,
						},
						{
							conversationId: 15736,
							disconImpact: -0.038426958,
							featureValue: 0,
						},
						{
							conversationId: 15737,
							disconImpact: -0.03616316,
							featureValue: 0,
						},
						{
							conversationId: 15740,
							disconImpact: -0.023863515,
							featureValue: 0,
						},
						{
							conversationId: 15743,
							disconImpact: -0.025505383,
							featureValue: 0,
						},
						{
							conversationId: 15744,
							disconImpact: -0.024457296,
							featureValue: 0,
						},
						{
							conversationId: 15749,
							disconImpact: -0.040349036,
							featureValue: 0,
						},
						{
							conversationId: 15750,
							disconImpact: -0.032640487,
							featureValue: 0,
						},
						{
							conversationId: 15752,
							disconImpact: -0.03467255,
							featureValue: 0,
						},
						{
							conversationId: 15753,
							disconImpact: -0.027509594,
							featureValue: 0,
						},
						{
							conversationId: 15760,
							disconImpact: -0.028103877,
							featureValue: 0,
						},
						{
							conversationId: 15763,
							disconImpact: -0.026734412,
							featureValue: 0,
						},
						{
							conversationId: 15766,
							disconImpact: 1.3380998,
							featureValue: 0.0487956954483523,
						},
						{
							conversationId: 15767,
							disconImpact: -0.025579412,
							featureValue: 0,
						},
						{
							conversationId: 15776,
							disconImpact: -0.0650377,
							featureValue: 0.0127196449709091,
						},
						{
							conversationId: 15783,
							disconImpact: -0.033889122,
							featureValue: 0,
						},
						{
							conversationId: 15791,
							disconImpact: -0.02856998,
							featureValue: 0,
						},
						{
							conversationId: 15793,
							disconImpact: -0.022668941,
							featureValue: 0,
						},
						{
							conversationId: 15794,
							disconImpact: -0.026358105,
							featureValue: 0,
						},
						{
							conversationId: 15801,
							disconImpact: -0.023890609,
							featureValue: 0,
						},
						{
							conversationId: 15803,
							disconImpact: -0.037596177,
							featureValue: 0,
						},
						{
							conversationId: 15805,
							disconImpact: -0.03342003,
							featureValue: 0,
						},
						{
							conversationId: 15809,
							disconImpact: 0.717695,
							featureValue: 0.0363677096557389,
						},
						{
							conversationId: 15811,
							disconImpact: -0.024063135,
							featureValue: 0,
						},
						{
							conversationId: 15814,
							disconImpact: -0.021675665,
							featureValue: 0,
						},
						{
							conversationId: 15820,
							disconImpact: -0.031596083,
							featureValue: 0,
						},
						{
							conversationId: 15825,
							disconImpact: -0.029429678,
							featureValue: 0,
						},
						{
							conversationId: 15827,
							disconImpact: -0.026168812,
							featureValue: 0,
						},
						{
							conversationId: 15828,
							disconImpact: -0.023304971,
							featureValue: 0,
						},
						{
							conversationId: 15833,
							disconImpact: -0.033097144,
							featureValue: 0,
						},
						{
							conversationId: 15836,
							disconImpact: -0.025271527,
							featureValue: 0,
						},
						{
							conversationId: 15839,
							disconImpact: -0.023110595,
							featureValue: 0,
						},
						{
							conversationId: 15842,
							disconImpact: -0.029641297,
							featureValue: 0,
						},
						{
							conversationId: 15851,
							disconImpact: -0.032705754,
							featureValue: 0,
						},
						{
							conversationId: 15852,
							disconImpact: -0.027310994,
							featureValue: 0,
						},
						{
							conversationId: 15855,
							disconImpact: -0.034445897,
							featureValue: 0,
						},
						{
							conversationId: 15856,
							disconImpact: -0.030122481,
							featureValue: 0,
						},
						{
							conversationId: 15863,
							disconImpact: -0.030814268,
							featureValue: 0,
						},
						{
							conversationId: 15866,
							disconImpact: -0.03317133,
							featureValue: 0,
						},
						{
							conversationId: 15871,
							disconImpact: -0.041338257,
							featureValue: 0,
						},
						{
							conversationId: 15873,
							disconImpact: -0.042934623,
							featureValue: 0,
						},
						{
							conversationId: 15874,
							disconImpact: -0.035877213,
							featureValue: 0,
						},
						{
							conversationId: 15876,
							disconImpact: -0.028654294,
							featureValue: 0,
						},
						{
							conversationId: 15878,
							disconImpact: -0.031722434,
							featureValue: 0,
						},
						{
							conversationId: 15880,
							disconImpact: -0.033396307,
							featureValue: 0,
						},
						{
							conversationId: 15881,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 15884,
							disconImpact: -0.041036144,
							featureValue: 0,
						},
						{
							conversationId: 15885,
							disconImpact: -0.039683677,
							featureValue: 0,
						},
						{
							conversationId: 15892,
							disconImpact: -0.030171443,
							featureValue: 0,
						},
						{
							conversationId: 15895,
							disconImpact: -0.035058003,
							featureValue: 0,
						},
						{
							conversationId: 15898,
							disconImpact: -0.02752427,
							featureValue: 0,
						},
						{
							conversationId: 15904,
							disconImpact: -0.03577854,
							featureValue: 0,
						},
						{
							conversationId: 15907,
							disconImpact: -0.018504463,
							featureValue: 0,
						},
						{
							conversationId: 15911,
							disconImpact: -0.028803047,
							featureValue: 0,
						},
						{
							conversationId: 15914,
							disconImpact: -0.027109258,
							featureValue: 0,
						},
						{
							conversationId: 15917,
							disconImpact: -0.035946455,
							featureValue: 0,
						},
						{
							conversationId: 15918,
							disconImpact: -0.025727842,
							featureValue: 0,
						},
						{
							conversationId: 15921,
							disconImpact: -0.030504819,
							featureValue: 0,
						},
						{
							conversationId: 15927,
							disconImpact: -0.02927005,
							featureValue: 0,
						},
						{
							conversationId: 15930,
							disconImpact: -0.021107193,
							featureValue: 0,
						},
						{
							conversationId: 15938,
							disconImpact: -0.028064977,
							featureValue: 0,
						},
						{
							conversationId: 15942,
							disconImpact: -0.029793378,
							featureValue: 0,
						},
						{
							conversationId: 15945,
							disconImpact: -0.03250806,
							featureValue: 0,
						},
						{
							conversationId: 15952,
							disconImpact: -0.1588713,
							featureValue: 0.0142569607514257,
						},
						{
							conversationId: 15955,
							disconImpact: -0.019830517,
							featureValue: 0,
						},
						{
							conversationId: 15960,
							disconImpact: -0.023612514,
							featureValue: 0,
						},
						{
							conversationId: 15963,
							disconImpact: -0.027603097,
							featureValue: 0,
						},
						{
							conversationId: 15965,
							disconImpact: -0.02926439,
							featureValue: 0,
						},
						{
							conversationId: 15967,
							disconImpact: -0.026588116,
							featureValue: 0,
						},
						{
							conversationId: 15970,
							disconImpact: -0.024637435,
							featureValue: 0,
						},
						{
							conversationId: 15973,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 15979,
							disconImpact: -0.027692888,
							featureValue: 0,
						},
						{
							conversationId: 15984,
							disconImpact: -0.040064424,
							featureValue: 0,
						},
						{
							conversationId: 15999,
							disconImpact: -0.03204804,
							featureValue: 0,
						},
						{
							conversationId: 16003,
							disconImpact: -0.030372148,
							featureValue: 0,
						},
						{
							conversationId: 16008,
							disconImpact: -0.028366636,
							featureValue: 0,
						},
						{
							conversationId: 16011,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 16020,
							disconImpact: -0.032808654,
							featureValue: 0,
						},
						{
							conversationId: 16021,
							disconImpact: -0.033057846,
							featureValue: 0,
						},
						{
							conversationId: 16025,
							disconImpact: 0.10432133,
							featureValue: 0.012838925882404,
						},
						{
							conversationId: 16026,
							disconImpact: -0.031379357,
							featureValue: 0,
						},
						{
							conversationId: 16032,
							disconImpact: -0.03357735,
							featureValue: 0,
						},
						{
							conversationId: 16035,
							disconImpact: 0.33558074,
							featureValue: 0.0177463324599831,
						},
						{
							conversationId: 16043,
							disconImpact: 0.16823404,
							featureValue: 0.0174411834690994,
						},
						{
							conversationId: 16046,
							disconImpact: -0.025260802,
							featureValue: 0,
						},
						{
							conversationId: 16052,
							disconImpact: -0.03675177,
							featureValue: 0,
						},
						{
							conversationId: 16065,
							disconImpact: -0.03115719,
							featureValue: 0,
						},
						{
							conversationId: 16071,
							disconImpact: -0.024477467,
							featureValue: 0,
						},
						{
							conversationId: 16082,
							disconImpact: -0.029906536,
							featureValue: 0,
						},
						{
							conversationId: 16096,
							disconImpact: -0.029866084,
							featureValue: 0,
						},
						{
							conversationId: 16098,
							disconImpact: -0.026817698,
							featureValue: 0,
						},
						{
							conversationId: 16101,
							disconImpact: -0.033959415,
							featureValue: 0,
						},
						{
							conversationId: 16104,
							disconImpact: 1.3082349,
							featureValue: 0.1122509960159361,
						},
						{
							conversationId: 16113,
							disconImpact: -0.033360697,
							featureValue: 0,
						},
					],
				},
			},
			{
				data: {
					title: "Attractive Options elsewhere",
					chartData: [
						{
							conversationId: 13,
							disconImpact: -0.07296576,
							featureValue: 0.1055,
						},
						{
							conversationId: 30,
							disconImpact: -0.012703816,
							featureValue: 0,
						},
						{
							conversationId: 39,
							disconImpact: 0.21131301,
							featureValue: 0.0728333333333333,
						},
						{
							conversationId: 67,
							disconImpact: -0.29085526,
							featureValue: 0,
						},
						{
							conversationId: 68,
							disconImpact: -0.012763087,
							featureValue: 0,
						},
						{
							conversationId: 82,
							disconImpact: 0.22143781,
							featureValue: 0.063,
						},
						{
							conversationId: 93,
							disconImpact: -0.012651217,
							featureValue: 0,
						},
						{
							conversationId: 98,
							disconImpact: 0.013871724,
							featureValue: 0,
						},
						{
							conversationId: 100,
							disconImpact: -0.23032264,
							featureValue: 0.1059333333333333,
						},
						{
							conversationId: 104,
							disconImpact: 0.74724,
							featureValue: 0.101,
						},
						{
							conversationId: 122,
							disconImpact: 0.07074578,
							featureValue: 0,
						},
						{
							conversationId: 126,
							disconImpact: -0.24841675,
							featureValue: 0,
						},
						{
							conversationId: 129,
							disconImpact: 0.051669125,
							featureValue: 0,
						},
						{
							conversationId: 134,
							disconImpact: -1.0650601,
							featureValue: 0.2075,
						},
						{
							conversationId: 139,
							disconImpact: 0.5498772,
							featureValue: 0.0473333333333333,
						},
						{
							conversationId: 175,
							disconImpact: -0.008957059,
							featureValue: 0,
						},
						{
							conversationId: 183,
							disconImpact: -0.4422126,
							featureValue: 0,
						},
						{
							conversationId: 195,
							disconImpact: 0.40959314,
							featureValue: 0.068,
						},
						{
							conversationId: 196,
							disconImpact: -0.038443998,
							featureValue: 0,
						},
						{
							conversationId: 209,
							disconImpact: 0.014802881,
							featureValue: 0.058,
						},
						{
							conversationId: 213,
							disconImpact: 0.016428705,
							featureValue: 0,
						},
						{
							conversationId: 220,
							disconImpact: -0.022552935,
							featureValue: 0.061625,
						},
						{
							conversationId: 231,
							disconImpact: -0.06285044,
							featureValue: 0.031,
						},
						{
							conversationId: 234,
							disconImpact: 0.037819874,
							featureValue: 0,
						},
						{
							conversationId: 239,
							disconImpact: 0.27118,
							featureValue: 0.068,
						},
						{
							conversationId: 244,
							disconImpact: 0.026972689,
							featureValue: 0,
						},
						{
							conversationId: 245,
							disconImpact: -0.046485323,
							featureValue: 0,
						},
						{
							conversationId: 246,
							disconImpact: 0.27589485,
							featureValue: 0.0395,
						},
						{
							conversationId: 264,
							disconImpact: -0.07023196,
							featureValue: 0,
						},
						{
							conversationId: 267,
							disconImpact: -0.28341678,
							featureValue: 0.022,
						},
						{
							conversationId: 269,
							disconImpact: -0.3307751,
							featureValue: 0,
						},
						{
							conversationId: 274,
							disconImpact: -0.019221863,
							featureValue: 0,
						},
						{
							conversationId: 279,
							disconImpact: 0.53268856,
							featureValue: 0.056,
						},
						{
							conversationId: 282,
							disconImpact: -0.027386613,
							featureValue: 0,
						},
						{
							conversationId: 293,
							disconImpact: 0.5390121,
							featureValue: 0.0483333333333333,
						},
						{
							conversationId: 297,
							disconImpact: -0.26714003,
							featureValue: 0.05575,
						},
						{
							conversationId: 308,
							disconImpact: -0.17016786,
							featureValue: 0,
						},
						{
							conversationId: 311,
							disconImpact: -0.07639252,
							featureValue: 0,
						},
						{
							conversationId: 342,
							disconImpact: 0.24486974,
							featureValue: 0.068,
						},
						{
							conversationId: 345,
							disconImpact: 0.5842141,
							featureValue: 0.1035,
						},
						{
							conversationId: 353,
							disconImpact: 0.62424386,
							featureValue: 0.045,
						},
						{
							conversationId: 356,
							disconImpact: -0.09413099,
							featureValue: 0,
						},
						{
							conversationId: 357,
							disconImpact: 0.028366078,
							featureValue: 0,
						},
						{
							conversationId: 368,
							disconImpact: 0.50071466,
							featureValue: 0.044,
						},
						{
							conversationId: 373,
							disconImpact: -0.5757213,
							featureValue: 0.174,
						},
						{
							conversationId: 377,
							disconImpact: 0.072395876,
							featureValue: 0.0559999999999999,
						},
						{
							conversationId: 385,
							disconImpact: -0.24339157,
							featureValue: 0.053,
						},
						{
							conversationId: 386,
							disconImpact: -0.13053402,
							featureValue: 0,
						},
						{
							conversationId: 401,
							disconImpact: 0.101272985,
							featureValue: 0.0895,
						},
						{
							conversationId: 411,
							disconImpact: 0.5014223,
							featureValue: 0.072,
						},
						{
							conversationId: 418,
							disconImpact: -0.22633281,
							featureValue: 0.088,
						},
						{
							conversationId: 424,
							disconImpact: -0.09982287,
							featureValue: 0,
						},
						{
							conversationId: 426,
							disconImpact: -1.4634634,
							featureValue: 0.1498571428571428,
						},
						{
							conversationId: 428,
							disconImpact: -0.23553206,
							featureValue: 0.0606666666666666,
						},
						{
							conversationId: 430,
							disconImpact: -0.22786546,
							featureValue: 0,
						},
						{
							conversationId: 434,
							disconImpact: 0.3707047,
							featureValue: 0.071,
						},
						{
							conversationId: 435,
							disconImpact: 0.18727504,
							featureValue: 0.041,
						},
						{
							conversationId: 467,
							disconImpact: 1.3108859,
							featureValue: 0.048,
						},
						{
							conversationId: 472,
							disconImpact: 0.61751145,
							featureValue: 0.098,
						},
						{
							conversationId: 476,
							disconImpact: -0.13833833,
							featureValue: 0.0775,
						},
						{
							conversationId: 504,
							disconImpact: -0.087570034,
							featureValue: 0,
						},
						{
							conversationId: 525,
							disconImpact: -0.09613018,
							featureValue: 0,
						},
						{
							conversationId: 547,
							disconImpact: 0.18758097,
							featureValue: 0,
						},
						{
							conversationId: 549,
							disconImpact: 0.2536402,
							featureValue: 0.052,
						},
						{
							conversationId: 566,
							disconImpact: -0.04785531,
							featureValue: 0.031,
						},
						{
							conversationId: 574,
							disconImpact: -0.04507029,
							featureValue: 0,
						},
						{
							conversationId: 577,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 582,
							disconImpact: 0.21294527,
							featureValue: 0.066,
						},
						{
							conversationId: 604,
							disconImpact: -0.008790959,
							featureValue: 0,
						},
						{
							conversationId: 605,
							disconImpact: -0.07478819,
							featureValue: 0,
						},
						{
							conversationId: 611,
							disconImpact: 0.39950284,
							featureValue: 0.046,
						},
						{
							conversationId: 616,
							disconImpact: 0.14451824,
							featureValue: 0.0668333333333333,
						},
						{
							conversationId: 622,
							disconImpact: 0.0010896116,
							featureValue: 0,
						},
						{
							conversationId: 623,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 624,
							disconImpact: 1.0690488,
							featureValue: 0.046,
						},
						{
							conversationId: 626,
							disconImpact: -0.11733002,
							featureValue: 0,
						},
						{
							conversationId: 630,
							disconImpact: -0.3272851,
							featureValue: 0.06025,
						},
						{
							conversationId: 661,
							disconImpact: 0.017438363,
							featureValue: 0.0536666666666666,
						},
						{
							conversationId: 684,
							disconImpact: -0.7888489,
							featureValue: 0.2115,
						},
						{
							conversationId: 685,
							disconImpact: 0.0089254975,
							featureValue: 0.057,
						},
						{
							conversationId: 692,
							disconImpact: -0.031200351,
							featureValue: 0,
						},
						{
							conversationId: 712,
							disconImpact: -0.291217,
							featureValue: 0.06,
						},
						{
							conversationId: 719,
							disconImpact: 0.5044574,
							featureValue: 0.043,
						},
						{
							conversationId: 722,
							disconImpact: -0.04507029,
							featureValue: 0,
						},
						{
							conversationId: 723,
							disconImpact: 0.19675536,
							featureValue: 0.0452999999999999,
						},
						{
							conversationId: 746,
							disconImpact: -0.019303981,
							featureValue: 0,
						},
						{
							conversationId: 758,
							disconImpact: -0.46604297,
							featureValue: 0.051,
						},
						{
							conversationId: 759,
							disconImpact: 0.41023093,
							featureValue: 0.0665,
						},
						{
							conversationId: 763,
							disconImpact: -0.16466138,
							featureValue: 0,
						},
						{
							conversationId: 790,
							disconImpact: 0.1754077,
							featureValue: 0.0504166666666666,
						},
						{
							conversationId: 799,
							disconImpact: -0.01615119,
							featureValue: 0.029,
						},
						{
							conversationId: 811,
							disconImpact: 0.18318361,
							featureValue: 0.073,
						},
						{
							conversationId: 814,
							disconImpact: -0.09982287,
							featureValue: 0,
						},
						{
							conversationId: 815,
							disconImpact: -0.2967585,
							featureValue: 0.0854999999999999,
						},
						{
							conversationId: 819,
							disconImpact: -0.09982287,
							featureValue: 0,
						},
						{
							conversationId: 838,
							disconImpact: -0.044434875,
							featureValue: 0.0915,
						},
						{
							conversationId: 843,
							disconImpact: 0.5920608,
							featureValue: 0.08975,
						},
						{
							conversationId: 876,
							disconImpact: -0.027782885,
							featureValue: 0.0635,
						},
						{
							conversationId: 878,
							disconImpact: 0.20127182,
							featureValue: 0.073,
						},
						{
							conversationId: 900,
							disconImpact: -0.046515305,
							featureValue: 0,
						},
						{
							conversationId: 901,
							disconImpact: -0.22086957,
							featureValue: 0,
						},
						{
							conversationId: 907,
							disconImpact: -0.47939342,
							featureValue: 0.0793333333333333,
						},
						{
							conversationId: 919,
							disconImpact: 0.10737813,
							featureValue: 0.0539999999999999,
						},
						{
							conversationId: 927,
							disconImpact: -0.09982287,
							featureValue: 0,
						},
						{
							conversationId: 928,
							disconImpact: 0.07500265,
							featureValue: 0.0765,
						},
						{
							conversationId: 933,
							disconImpact: -0.14123064,
							featureValue: 0.0683,
						},
						{
							conversationId: 934,
							disconImpact: -0.32891273,
							featureValue: 0,
						},
						{
							conversationId: 948,
							disconImpact: 0.27404338,
							featureValue: 0.05,
						},
						{
							conversationId: 957,
							disconImpact: -0.17193592,
							featureValue: 0,
						},
						{
							conversationId: 962,
							disconImpact: 0.31536657,
							featureValue: 0.033,
						},
						{
							conversationId: 968,
							disconImpact: -0.17829373,
							featureValue: 0,
						},
						{
							conversationId: 973,
							disconImpact: -0.3697915,
							featureValue: 0.051,
						},
						{
							conversationId: 995,
							disconImpact: 0.00212227,
							featureValue: 0.0577499999999999,
						},
						{
							conversationId: 1012,
							disconImpact: -0.3733703,
							featureValue: 0.057,
						},
						{
							conversationId: 1023,
							disconImpact: 0.39897412,
							featureValue: 0.0467999999999999,
						},
						{
							conversationId: 1030,
							disconImpact: -0.3345325,
							featureValue: 0.1172222222222222,
						},
						{
							conversationId: 1034,
							disconImpact: -0.01494994,
							featureValue: 0.0445555555555555,
						},
						{
							conversationId: 1046,
							disconImpact: 0.071238875,
							featureValue: 0.045,
						},
						{
							conversationId: 1060,
							disconImpact: -0.24662584,
							featureValue: 0,
						},
						{
							conversationId: 1075,
							disconImpact: -0.23350215,
							featureValue: 0,
						},
						{
							conversationId: 1077,
							disconImpact: -0.10446005,
							featureValue: 0,
						},
						{
							conversationId: 1082,
							disconImpact: 0.43843153,
							featureValue: 0.065,
						},
						{
							conversationId: 1129,
							disconImpact: 0.36478937,
							featureValue: 0.067,
						},
						{
							conversationId: 1132,
							disconImpact: 0.38250107,
							featureValue: 0.074,
						},
						{
							conversationId: 1134,
							disconImpact: -0.1735024,
							featureValue: 0,
						},
						{
							conversationId: 1136,
							disconImpact: 0.3866747,
							featureValue: 0.0679999999999999,
						},
						{
							conversationId: 1138,
							disconImpact: -0.051202215,
							featureValue: 0,
						},
						{
							conversationId: 1144,
							disconImpact: 0.18100199,
							featureValue: 0.1024416666666666,
						},
						{
							conversationId: 1145,
							disconImpact: 0.6426693,
							featureValue: 0.071,
						},
						{
							conversationId: 1146,
							disconImpact: 0.18102233,
							featureValue: 0.064,
						},
						{
							conversationId: 1155,
							disconImpact: 0.05733164,
							featureValue: 0,
						},
						{
							conversationId: 1159,
							disconImpact: 0.053080965,
							featureValue: 0,
						},
						{
							conversationId: 1186,
							disconImpact: 0.111791015,
							featureValue: 0.023,
						},
						{
							conversationId: 1196,
							disconImpact: -0.21111932,
							featureValue: 0,
						},
						{
							conversationId: 1198,
							disconImpact: 0.26423806,
							featureValue: 0.0713333333333333,
						},
						{
							conversationId: 1201,
							disconImpact: -0.1987084,
							featureValue: 0.052,
						},
						{
							conversationId: 1211,
							disconImpact: -1.1361101,
							featureValue: 0.1188333333333333,
						},
						{
							conversationId: 1215,
							disconImpact: 0.14567968,
							featureValue: 0.07,
						},
						{
							conversationId: 1245,
							disconImpact: 0.20307088,
							featureValue: 0.0665,
						},
						{
							conversationId: 1247,
							disconImpact: 0.49797788,
							featureValue: 0.046,
						},
						{
							conversationId: 1253,
							disconImpact: 0.16880524,
							featureValue: 0.085,
						},
						{
							conversationId: 1256,
							disconImpact: -0.6834755,
							featureValue: 0.128,
						},
						{
							conversationId: 1274,
							disconImpact: 0.15297477,
							featureValue: 0.0791666666666666,
						},
						{
							conversationId: 1300,
							disconImpact: -0.71254385,
							featureValue: 0.1217999999999999,
						},
						{
							conversationId: 1303,
							disconImpact: -0.084300436,
							featureValue: 0,
						},
						{
							conversationId: 1319,
							disconImpact: 0.14409731,
							featureValue: 0.034,
						},
						{
							conversationId: 1329,
							disconImpact: 0.074437976,
							featureValue: 0.029,
						},
						{
							conversationId: 1339,
							disconImpact: 0.29437143,
							featureValue: 0.07,
						},
						{
							conversationId: 1346,
							disconImpact: -0.05178247,
							featureValue: 0,
						},
						{
							conversationId: 1361,
							disconImpact: 0.48193246,
							featureValue: 0.072,
						},
						{
							conversationId: 1370,
							disconImpact: 0.34026766,
							featureValue: 0.06725,
						},
						{
							conversationId: 1402,
							disconImpact: -0.036829077,
							featureValue: 0.0595,
						},
						{
							conversationId: 1411,
							disconImpact: 0.42337012,
							featureValue: 0.074,
						},
						{
							conversationId: 1414,
							disconImpact: 0.3555108,
							featureValue: 0.0356666666666666,
						},
						{
							conversationId: 1418,
							disconImpact: 0.43554467,
							featureValue: 0.045,
						},
						{
							conversationId: 1421,
							disconImpact: -0.1358574,
							featureValue: 0,
						},
						{
							conversationId: 1425,
							disconImpact: -0.11005368,
							featureValue: 0,
						},
						{
							conversationId: 1426,
							disconImpact: -0.07020643,
							featureValue: 0,
						},
						{
							conversationId: 1428,
							disconImpact: 0.92734617,
							featureValue: 0.0485,
						},
						{
							conversationId: 1436,
							disconImpact: -0.42941716,
							featureValue: 0.0832499999999999,
						},
						{
							conversationId: 1444,
							disconImpact: 0.026113484,
							featureValue: 0,
						},
						{
							conversationId: 1504,
							disconImpact: -0.12738746,
							featureValue: 0.056,
						},
						{
							conversationId: 1510,
							disconImpact: -0.09982287,
							featureValue: 0,
						},
						{
							conversationId: 1544,
							disconImpact: 0.11556194,
							featureValue: 0,
						},
						{
							conversationId: 1550,
							disconImpact: -0.15202543,
							featureValue: 0.04,
						},
						{
							conversationId: 1568,
							disconImpact: 0.20655443,
							featureValue: 0.0691666666666666,
						},
						{
							conversationId: 1573,
							disconImpact: -0.08711552,
							featureValue: 0.0785,
						},
						{
							conversationId: 1585,
							disconImpact: 0.12525938,
							featureValue: 0.0745,
						},
						{
							conversationId: 1592,
							disconImpact: 0.19937027,
							featureValue: 0,
						},
						{
							conversationId: 1594,
							disconImpact: -0.17662887,
							featureValue: 0,
						},
						{
							conversationId: 1599,
							disconImpact: 0.16107462,
							featureValue: 0,
						},
						{
							conversationId: 1602,
							disconImpact: -0.048425056,
							featureValue: 0.051,
						},
						{
							conversationId: 1607,
							disconImpact: 0.95545447,
							featureValue: 0.048,
						},
						{
							conversationId: 1616,
							disconImpact: 0.21388283,
							featureValue: 0,
						},
						{
							conversationId: 1620,
							disconImpact: 0.25672162,
							featureValue: 0.074,
						},
						{
							conversationId: 1626,
							disconImpact: -0.1758479,
							featureValue: 0,
						},
						{
							conversationId: 1628,
							disconImpact: 0.25733793,
							featureValue: 0,
						},
						{
							conversationId: 1635,
							disconImpact: 0.25134388,
							featureValue: 0.0375,
						},
						{
							conversationId: 1641,
							disconImpact: -0.19339775,
							featureValue: 0,
						},
						{
							conversationId: 1658,
							disconImpact: 0.34288695,
							featureValue: 0.049,
						},
						{
							conversationId: 1664,
							disconImpact: -0.22702527,
							featureValue: 0,
						},
						{
							conversationId: 1684,
							disconImpact: -0.15874869,
							featureValue: 0.054,
						},
						{
							conversationId: 1685,
							disconImpact: -0.24923016,
							featureValue: 0.054,
						},
						{
							conversationId: 1695,
							disconImpact: 0.6799885,
							featureValue: 0.0465,
						},
						{
							conversationId: 1697,
							disconImpact: 0.14327618,
							featureValue: 0.032,
						},
						{
							conversationId: 1709,
							disconImpact: 0.2796625,
							featureValue: 0.043,
						},
						{
							conversationId: 1712,
							disconImpact: -0.10614882,
							featureValue: 0.0755,
						},
						{
							conversationId: 1727,
							disconImpact: -0.21723476,
							featureValue: 0,
						},
						{
							conversationId: 1734,
							disconImpact: -0.38861647,
							featureValue: 0.234,
						},
						{
							conversationId: 1741,
							disconImpact: -0.27683416,
							featureValue: 0,
						},
						{
							conversationId: 1742,
							disconImpact: -0.07715473,
							featureValue: 0.05,
						},
						{
							conversationId: 1743,
							disconImpact: 0.4220608,
							featureValue: 0.0553333333333333,
						},
						{
							conversationId: 1755,
							disconImpact: -0.047097728,
							featureValue: 0,
						},
						{
							conversationId: 1762,
							disconImpact: 0.29136527,
							featureValue: 0.0463636363636363,
						},
						{
							conversationId: 1771,
							disconImpact: 0.11939863,
							featureValue: 0.04025,
						},
						{
							conversationId: 1773,
							disconImpact: -0.2319755,
							featureValue: 0,
						},
						{
							conversationId: 1792,
							disconImpact: -0.1620582,
							featureValue: 0,
						},
						{
							conversationId: 1808,
							disconImpact: 0.019536782,
							featureValue: 0.031,
						},
						{
							conversationId: 1810,
							disconImpact: 0.12992224,
							featureValue: 0.077,
						},
						{
							conversationId: 1832,
							disconImpact: 0.3909465,
							featureValue: 0.1013333333333333,
						},
						{
							conversationId: 1838,
							disconImpact: 0.1023627,
							featureValue: 0.062,
						},
						{
							conversationId: 1852,
							disconImpact: 0.59362185,
							featureValue: 0.06875,
						},
						{
							conversationId: 1862,
							disconImpact: 0.43884286,
							featureValue: 0.106,
						},
						{
							conversationId: 1873,
							disconImpact: 0.2967203,
							featureValue: 0.0976666666666666,
						},
						{
							conversationId: 1877,
							disconImpact: -0.16491467,
							featureValue: 0,
						},
						{
							conversationId: 1884,
							disconImpact: 0.7135329,
							featureValue: 0.049,
						},
						{
							conversationId: 1887,
							disconImpact: -0.5315887,
							featureValue: 0.053,
						},
						{
							conversationId: 1890,
							disconImpact: -1.2204691,
							featureValue: 0.1746666666666666,
						},
						{
							conversationId: 1892,
							disconImpact: 0.31601748,
							featureValue: 0.047,
						},
						{
							conversationId: 1893,
							disconImpact: 0.2914519,
							featureValue: 0.07,
						},
						{
							conversationId: 1903,
							disconImpact: 0.28988922,
							featureValue: 0.039,
						},
						{
							conversationId: 1909,
							disconImpact: 0.091970325,
							featureValue: 0.0903333333333333,
						},
						{
							conversationId: 1910,
							disconImpact: 0.075912274,
							featureValue: 0,
						},
						{
							conversationId: 1931,
							disconImpact: -0.32701933,
							featureValue: 0.0622,
						},
						{
							conversationId: 1939,
							disconImpact: 0.5969588,
							featureValue: 0.045,
						},
						{
							conversationId: 1959,
							disconImpact: 0.58592427,
							featureValue: 0.045,
						},
						{
							conversationId: 1964,
							disconImpact: -0.49722043,
							featureValue: 0.263,
						},
						{
							conversationId: 1966,
							disconImpact: 0.38048515,
							featureValue: 0.066,
						},
						{
							conversationId: 1991,
							disconImpact: 0.098088115,
							featureValue: 0,
						},
						{
							conversationId: 1994,
							disconImpact: -0.42420828,
							featureValue: 0.0611666666666666,
						},
						{
							conversationId: 1995,
							disconImpact: 0.3806531,
							featureValue: 0.077,
						},
						{
							conversationId: 1996,
							disconImpact: -0.105851464,
							featureValue: 0,
						},
						{
							conversationId: 1999,
							disconImpact: -0.04048767,
							featureValue: 0.0736666666666666,
						},
						{
							conversationId: 2004,
							disconImpact: -0.07993081,
							featureValue: 0,
						},
						{
							conversationId: 2014,
							disconImpact: 0.13803084,
							featureValue: 0.0735,
						},
						{
							conversationId: 2015,
							disconImpact: 0.05841884,
							featureValue: 0.07,
						},
						{
							conversationId: 2017,
							disconImpact: -0.882413,
							featureValue: 0.1513333333333333,
						},
						{
							conversationId: 2025,
							disconImpact: -0.31334507,
							featureValue: 0.0515,
						},
						{
							conversationId: 2047,
							disconImpact: 0.41455027,
							featureValue: 0.0503333333333333,
						},
						{
							conversationId: 2053,
							disconImpact: -0.047044616,
							featureValue: 0,
						},
						{
							conversationId: 2055,
							disconImpact: 0.08002712,
							featureValue: 0,
						},
						{
							conversationId: 2070,
							disconImpact: 0.6495254,
							featureValue: 0.104,
						},
						{
							conversationId: 2077,
							disconImpact: -0.09315602,
							featureValue: 0,
						},
						{
							conversationId: 2081,
							disconImpact: -0.08588282,
							featureValue: 0,
						},
						{
							conversationId: 2093,
							disconImpact: -0.08855321,
							featureValue: 0,
						},
						{
							conversationId: 2095,
							disconImpact: -0.12610999,
							featureValue: 0,
						},
						{
							conversationId: 2112,
							disconImpact: -0.22049466,
							featureValue: 0.024,
						},
						{
							conversationId: 2119,
							disconImpact: -0.21665694,
							featureValue: 0,
						},
						{
							conversationId: 2121,
							disconImpact: 0.34477276,
							featureValue: 0.073,
						},
						{
							conversationId: 2135,
							disconImpact: -0.11161381,
							featureValue: 0.025,
						},
						{
							conversationId: 2139,
							disconImpact: -0.1252743,
							featureValue: 0,
						},
						{
							conversationId: 2144,
							disconImpact: -0.04798395,
							featureValue: 0,
						},
						{
							conversationId: 2147,
							disconImpact: 0.3487248,
							featureValue: 0.091,
						},
						{
							conversationId: 2155,
							disconImpact: 0.47200963,
							featureValue: 0.047,
						},
						{
							conversationId: 2179,
							disconImpact: -0.020898852,
							featureValue: 0.0521666666666666,
						},
						{
							conversationId: 2189,
							disconImpact: -0.08398022,
							featureValue: 0,
						},
						{
							conversationId: 2198,
							disconImpact: 0.12688285,
							featureValue: 0,
						},
						{
							conversationId: 2225,
							disconImpact: 0.56713,
							featureValue: 0.067,
						},
						{
							conversationId: 2237,
							disconImpact: 0.09891057,
							featureValue: 0.042,
						},
						{
							conversationId: 2256,
							disconImpact: 0.28516898,
							featureValue: 0.0695,
						},
						{
							conversationId: 2264,
							disconImpact: 0.106558695,
							featureValue: 0,
						},
						{
							conversationId: 2277,
							disconImpact: -0.03862509,
							featureValue: 0,
						},
						{
							conversationId: 2284,
							disconImpact: -0.45642385,
							featureValue: 0.055,
						},
						{
							conversationId: 2293,
							disconImpact: 0.20437005,
							featureValue: 0.063,
						},
						{
							conversationId: 2298,
							disconImpact: -0.030687924,
							featureValue: 0,
						},
						{
							conversationId: 2299,
							disconImpact: 0.064827,
							featureValue: 0,
						},
						{
							conversationId: 2302,
							disconImpact: -0.27771816,
							featureValue: 0.1399999999999999,
						},
						{
							conversationId: 2311,
							disconImpact: -0.20117399,
							featureValue: 0,
						},
						{
							conversationId: 2344,
							disconImpact: 0.01887105,
							featureValue: 0,
						},
						{
							conversationId: 2356,
							disconImpact: -0.28431568,
							featureValue: 0.041,
						},
						{
							conversationId: 2358,
							disconImpact: -1.188478,
							featureValue: 0.136,
						},
						{
							conversationId: 2373,
							disconImpact: -0.059714958,
							featureValue: 0.06,
						},
						{
							conversationId: 2436,
							disconImpact: -0.33345214,
							featureValue: 0.0576666666666666,
						},
						{
							conversationId: 2464,
							disconImpact: -0.6617758,
							featureValue: 0.086,
						},
						{
							conversationId: 2495,
							disconImpact: -0.24778473,
							featureValue: 0,
						},
						{
							conversationId: 2508,
							disconImpact: 0.25441736,
							featureValue: 0.0625,
						},
						{
							conversationId: 2509,
							disconImpact: -0.14969434,
							featureValue: 0,
						},
						{
							conversationId: 2528,
							disconImpact: -0.17536262,
							featureValue: 0,
						},
						{
							conversationId: 2532,
							disconImpact: -0.026580784,
							featureValue: 0.05075,
						},
						{
							conversationId: 2551,
							disconImpact: -0.09628179,
							featureValue: 0,
						},
						{
							conversationId: 2582,
							disconImpact: -0.48185295,
							featureValue: 0.0605,
						},
						{
							conversationId: 2583,
							disconImpact: -0.13591507,
							featureValue: 0,
						},
						{
							conversationId: 2585,
							disconImpact: 0.38232526,
							featureValue: 0.071,
						},
						{
							conversationId: 2598,
							disconImpact: 0.872104,
							featureValue: 0.049,
						},
						{
							conversationId: 2606,
							disconImpact: -0.47397676,
							featureValue: 0.058,
						},
						{
							conversationId: 2608,
							disconImpact: -0.22702527,
							featureValue: 0,
						},
						{
							conversationId: 2615,
							disconImpact: -0.6936208,
							featureValue: 0.1215,
						},
						{
							conversationId: 2630,
							disconImpact: -0.07647895,
							featureValue: 0,
						},
						{
							conversationId: 2641,
							disconImpact: 0.0011017849,
							featureValue: 0.1096666666666666,
						},
						{
							conversationId: 2658,
							disconImpact: -0.68744093,
							featureValue: 0.0793333333333333,
						},
						{
							conversationId: 2674,
							disconImpact: 0.04398521,
							featureValue: 0,
						},
						{
							conversationId: 2699,
							disconImpact: -0.06036674,
							featureValue: 0,
						},
						{
							conversationId: 2757,
							disconImpact: -0.26451313,
							featureValue: 0,
						},
						{
							conversationId: 2760,
							disconImpact: -0.21005827,
							featureValue: 0,
						},
						{
							conversationId: 2773,
							disconImpact: -0.2475902,
							featureValue: 0.02,
						},
						{
							conversationId: 2776,
							disconImpact: 0.28003672,
							featureValue: 0.059,
						},
						{
							conversationId: 2804,
							disconImpact: -0.43744075,
							featureValue: 0.04,
						},
						{
							conversationId: 2818,
							disconImpact: -0.77867055,
							featureValue: 0.315,
						},
						{
							conversationId: 2824,
							disconImpact: 0.14362223,
							featureValue: 0.036,
						},
						{
							conversationId: 2849,
							disconImpact: 0.29341903,
							featureValue: 0.036,
						},
						{
							conversationId: 2850,
							disconImpact: -0.09822147,
							featureValue: 0.0596666666666666,
						},
						{
							conversationId: 2857,
							disconImpact: 0.21796614,
							featureValue: 0,
						},
						{
							conversationId: 2859,
							disconImpact: 0.73634857,
							featureValue: 0.048,
						},
						{
							conversationId: 2869,
							disconImpact: -0.12050464,
							featureValue: 0,
						},
						{
							conversationId: 2896,
							disconImpact: -0.016534295,
							featureValue: 0,
						},
						{
							conversationId: 2912,
							disconImpact: 0.23122232,
							featureValue: 0.05,
						},
						{
							conversationId: 2918,
							disconImpact: -0.111574434,
							featureValue: 0.063,
						},
						{
							conversationId: 2930,
							disconImpact: -0.20032081,
							featureValue: 0,
						},
						{
							conversationId: 2932,
							disconImpact: 0.07067093,
							featureValue: 0.035,
						},
						{
							conversationId: 2935,
							disconImpact: -0.14174767,
							featureValue: 0.065,
						},
						{
							conversationId: 2940,
							disconImpact: 0.25743303,
							featureValue: 0.0568333333333333,
						},
						{
							conversationId: 2946,
							disconImpact: -0.49920288,
							featureValue: 0.084,
						},
						{
							conversationId: 2956,
							disconImpact: -0.09982287,
							featureValue: 0,
						},
						{
							conversationId: 2972,
							disconImpact: 0.12907526,
							featureValue: 0.044,
						},
						{
							conversationId: 2974,
							disconImpact: -0.20897754,
							featureValue: 0.0605,
						},
						{
							conversationId: 3013,
							disconImpact: 0.4286304,
							featureValue: 0.066,
						},
						{
							conversationId: 3065,
							disconImpact: -0.34568226,
							featureValue: 0.053,
						},
						{
							conversationId: 3077,
							disconImpact: -0.15019488,
							featureValue: 0,
						},
						{
							conversationId: 3079,
							disconImpact: -0.0005940739,
							featureValue: 0,
						},
						{
							conversationId: 3101,
							disconImpact: 0.12281258,
							featureValue: 0.04,
						},
						{
							conversationId: 3104,
							disconImpact: 0.025183083,
							featureValue: 0,
						},
						{
							conversationId: 3120,
							disconImpact: 1.1912494,
							featureValue: 0.0473333333333333,
						},
						{
							conversationId: 3122,
							disconImpact: 0.20379715,
							featureValue: 0.066,
						},
						{
							conversationId: 3126,
							disconImpact: -0.11005368,
							featureValue: 0,
						},
						{
							conversationId: 3128,
							disconImpact: 0.00080842525,
							featureValue: 0.1151666666666666,
						},
						{
							conversationId: 3133,
							disconImpact: 0.3261279,
							featureValue: 0.062,
						},
						{
							conversationId: 3150,
							disconImpact: -0.40761325,
							featureValue: 0.108,
						},
						{
							conversationId: 3151,
							disconImpact: 0.52922666,
							featureValue: 0.067,
						},
						{
							conversationId: 3159,
							disconImpact: -0.45782596,
							featureValue: 0.0827499999999999,
						},
						{
							conversationId: 3161,
							disconImpact: -0.35831195,
							featureValue: 0,
						},
						{
							conversationId: 3162,
							disconImpact: -0.098764606,
							featureValue: 0,
						},
						{
							conversationId: 3201,
							disconImpact: 0.03239659,
							featureValue: 0.044,
						},
						{
							conversationId: 3209,
							disconImpact: 0.14472573,
							featureValue: 0.0424999999999999,
						},
						{
							conversationId: 3210,
							disconImpact: -0.2967041,
							featureValue: 0,
						},
						{
							conversationId: 3219,
							disconImpact: -0.08855321,
							featureValue: 0,
						},
						{
							conversationId: 3234,
							disconImpact: 0.28158998,
							featureValue: 0.0626666666666666,
						},
						{
							conversationId: 3249,
							disconImpact: 0.3599402,
							featureValue: 0.093,
						},
						{
							conversationId: 3251,
							disconImpact: 0.22203004,
							featureValue: 0.041,
						},
						{
							conversationId: 3263,
							disconImpact: 0.24006483,
							featureValue: 0.044,
						},
						{
							conversationId: 3267,
							disconImpact: 0.06773312,
							featureValue: 0,
						},
						{
							conversationId: 3280,
							disconImpact: 0.07220302,
							featureValue: 0.0596666666666666,
						},
						{
							conversationId: 3288,
							disconImpact: -0.22396448,
							featureValue: 0.245,
						},
						{
							conversationId: 3289,
							disconImpact: -0.16915804,
							featureValue: 0,
						},
						{
							conversationId: 3294,
							disconImpact: 0.120286606,
							featureValue: 0.073,
						},
						{
							conversationId: 3296,
							disconImpact: 0.22571129,
							featureValue: 0.04,
						},
						{
							conversationId: 3305,
							disconImpact: 0.26737365,
							featureValue: 0.0635,
						},
						{
							conversationId: 3310,
							disconImpact: -0.27649897,
							featureValue: 0,
						},
						{
							conversationId: 3317,
							disconImpact: -0.012763087,
							featureValue: 0,
						},
						{
							conversationId: 3321,
							disconImpact: 0.033658892,
							featureValue: 0,
						},
						{
							conversationId: 3322,
							disconImpact: -0.1173968,
							featureValue: 0,
						},
						{
							conversationId: 3332,
							disconImpact: -0.02285552,
							featureValue: 0.0915,
						},
						{
							conversationId: 3342,
							disconImpact: 0.3630824,
							featureValue: 0.031,
						},
						{
							conversationId: 3372,
							disconImpact: -0.004525043,
							featureValue: 0.083,
						},
						{
							conversationId: 3375,
							disconImpact: -0.75517225,
							featureValue: 0.0794285714285714,
						},
						{
							conversationId: 3381,
							disconImpact: 0.5467016,
							featureValue: 0.047,
						},
						{
							conversationId: 3391,
							disconImpact: -0.40499246,
							featureValue: 0,
						},
						{
							conversationId: 3399,
							disconImpact: -0.048338987,
							featureValue: 0,
						},
						{
							conversationId: 3400,
							disconImpact: -0.21005827,
							featureValue: 0,
						},
						{
							conversationId: 3405,
							disconImpact: -0.22992145,
							featureValue: 0.0515,
						},
						{
							conversationId: 3414,
							disconImpact: 0.56160754,
							featureValue: 0.036,
						},
						{
							conversationId: 3426,
							disconImpact: 0.5637601,
							featureValue: 0.102,
						},
						{
							conversationId: 3458,
							disconImpact: -0.053149536,
							featureValue: 0,
						},
						{
							conversationId: 3461,
							disconImpact: -0.08855321,
							featureValue: 0,
						},
						{
							conversationId: 3485,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 3490,
							disconImpact: -0.1295324,
							featureValue: 0,
						},
						{
							conversationId: 3500,
							disconImpact: 0.06464876,
							featureValue: 0,
						},
						{
							conversationId: 3503,
							disconImpact: 0.032535005,
							featureValue: 0.054,
						},
						{
							conversationId: 3511,
							disconImpact: -0.01223756,
							featureValue: 0,
						},
						{
							conversationId: 3512,
							disconImpact: -0.2237201,
							featureValue: 0,
						},
						{
							conversationId: 3516,
							disconImpact: 0.025749817,
							featureValue: 0.063,
						},
						{
							conversationId: 3518,
							disconImpact: -0.23403347,
							featureValue: 0,
						},
						{
							conversationId: 3525,
							disconImpact: 0.085384466,
							featureValue: 0.056,
						},
						{
							conversationId: 3535,
							disconImpact: 0.0024435576,
							featureValue: 0.14475,
						},
						{
							conversationId: 3537,
							disconImpact: 0.31362396,
							featureValue: 0.0655,
						},
						{
							conversationId: 3538,
							disconImpact: -0.19024426,
							featureValue: 0,
						},
						{
							conversationId: 3551,
							disconImpact: 0.3746432,
							featureValue: 0.069,
						},
						{
							conversationId: 3555,
							disconImpact: 0.45217723,
							featureValue: 0.0656666666666666,
						},
						{
							conversationId: 3567,
							disconImpact: -1.2821661,
							featureValue: 0.1358333333333333,
						},
						{
							conversationId: 3568,
							disconImpact: -0.012763087,
							featureValue: 0,
						},
						{
							conversationId: 3573,
							disconImpact: 0.25323382,
							featureValue: 0.053,
						},
						{
							conversationId: 3576,
							disconImpact: -0.51121795,
							featureValue: 0.084,
						},
						{
							conversationId: 3577,
							disconImpact: 0.8706912,
							featureValue: 0.049,
						},
						{
							conversationId: 3589,
							disconImpact: -0.07618525,
							featureValue: 0,
						},
						{
							conversationId: 3591,
							disconImpact: -0.14619711,
							featureValue: 0,
						},
						{
							conversationId: 3592,
							disconImpact: -0.12945548,
							featureValue: 0.0555,
						},
						{
							conversationId: 3593,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 3600,
							disconImpact: 0.15192755,
							featureValue: 0.092,
						},
						{
							conversationId: 3602,
							disconImpact: 0.20635697,
							featureValue: 0.031,
						},
						{
							conversationId: 3603,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 3618,
							disconImpact: 0.23020294,
							featureValue: 0.07025,
						},
						{
							conversationId: 3628,
							disconImpact: 0.6850093,
							featureValue: 0.0445,
						},
						{
							conversationId: 3629,
							disconImpact: -0.21518564,
							featureValue: 0.062,
						},
						{
							conversationId: 3630,
							disconImpact: -0.8707922,
							featureValue: 0.1404,
						},
						{
							conversationId: 3634,
							disconImpact: -0.06022827,
							featureValue: 0,
						},
						{
							conversationId: 3635,
							disconImpact: -0.5837445,
							featureValue: 0.06,
						},
						{
							conversationId: 3639,
							disconImpact: -0.25796112,
							featureValue: 0,
						},
						{
							conversationId: 3641,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 3644,
							disconImpact: 0.62133,
							featureValue: 0.0485,
						},
						{
							conversationId: 3653,
							disconImpact: 0.14244857,
							featureValue: 0.062,
						},
						{
							conversationId: 3655,
							disconImpact: 0.2860919,
							featureValue: 0.09425,
						},
						{
							conversationId: 3662,
							disconImpact: -0.04692764,
							featureValue: 0,
						},
						{
							conversationId: 3669,
							disconImpact: 0.31090784,
							featureValue: 0.06725,
						},
						{
							conversationId: 3671,
							disconImpact: 0.20785373,
							featureValue: 0.045,
						},
						{
							conversationId: 3688,
							disconImpact: -0.0071582906,
							featureValue: 0.0566,
						},
						{
							conversationId: 3689,
							disconImpact: -0.05584393,
							featureValue: 0.053,
						},
						{
							conversationId: 3701,
							disconImpact: -0.51504254,
							featureValue: 0.203,
						},
						{
							conversationId: 3703,
							disconImpact: -0.26161397,
							featureValue: 0,
						},
						{
							conversationId: 3704,
							disconImpact: -0.013636367,
							featureValue: 0,
						},
						{
							conversationId: 3706,
							disconImpact: -0.16393423,
							featureValue: 0,
						},
						{
							conversationId: 3713,
							disconImpact: 0.31748965,
							featureValue: 0.045,
						},
						{
							conversationId: 3714,
							disconImpact: 0.08471874,
							featureValue: 0.0636666666666666,
						},
						{
							conversationId: 3716,
							disconImpact: 0.28617385,
							featureValue: 0.0586666666666666,
						},
						{
							conversationId: 3723,
							disconImpact: 0.04006879,
							featureValue: 0.0518333333333333,
						},
						{
							conversationId: 3727,
							disconImpact: 0.07103496,
							featureValue: 0,
						},
						{
							conversationId: 3730,
							disconImpact: 0.6250929,
							featureValue: 0.1013888888888888,
						},
						{
							conversationId: 3735,
							disconImpact: 0.24332629,
							featureValue: 0.0555,
						},
						{
							conversationId: 3736,
							disconImpact: 0.02603497,
							featureValue: 0.0543333333333333,
						},
						{
							conversationId: 3737,
							disconImpact: -0.12397223,
							featureValue: 0.074625,
						},
						{
							conversationId: 3747,
							disconImpact: -0.4387681,
							featureValue: 0.061125,
						},
						{
							conversationId: 3748,
							disconImpact: -0.06701141,
							featureValue: 0,
						},
						{
							conversationId: 3753,
							disconImpact: -0.1186024,
							featureValue: 0.0633333333333333,
						},
						{
							conversationId: 3756,
							disconImpact: -0.078005075,
							featureValue: 0,
						},
						{
							conversationId: 3761,
							disconImpact: 0.33221182,
							featureValue: 0.056,
						},
						{
							conversationId: 3767,
							disconImpact: 0.49536446,
							featureValue: 0.0445,
						},
						{
							conversationId: 3769,
							disconImpact: 0.3586576,
							featureValue: 0.0505,
						},
						{
							conversationId: 3770,
							disconImpact: -0.7163722,
							featureValue: 0.132,
						},
						{
							conversationId: 3774,
							disconImpact: -0.012763087,
							featureValue: 0,
						},
						{
							conversationId: 3776,
							disconImpact: 0.38352486,
							featureValue: 0.0730694444444444,
						},
						{
							conversationId: 3779,
							disconImpact: -0.1806258,
							featureValue: 0,
						},
						{
							conversationId: 3783,
							disconImpact: -0.18968669,
							featureValue: 0,
						},
						{
							conversationId: 3787,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 3788,
							disconImpact: 0.15317589,
							featureValue: 0.062,
						},
						{
							conversationId: 3799,
							disconImpact: -0.06796364,
							featureValue: 0,
						},
						{
							conversationId: 3801,
							disconImpact: -1.4837711,
							featureValue: 0.1496666666666666,
						},
						{
							conversationId: 3804,
							disconImpact: 0.40634495,
							featureValue: 0.0728333333333333,
						},
						{
							conversationId: 3805,
							disconImpact: 0.19413728,
							featureValue: 0.0435,
						},
						{
							conversationId: 3806,
							disconImpact: 0.089721955,
							featureValue: 0.123,
						},
						{
							conversationId: 3811,
							disconImpact: -0.06588992,
							featureValue: 0.0581249999999999,
						},
						{
							conversationId: 3812,
							disconImpact: 0.016232906,
							featureValue: 0,
						},
						{
							conversationId: 3819,
							disconImpact: 0.27750057,
							featureValue: 0.0715,
						},
						{
							conversationId: 3820,
							disconImpact: 0.001454901,
							featureValue: 0,
						},
						{
							conversationId: 3821,
							disconImpact: 0.013546357,
							featureValue: 0.04,
						},
						{
							conversationId: 3827,
							disconImpact: 0.15475652,
							featureValue: 0.059,
						},
						{
							conversationId: 3833,
							disconImpact: -0.3719536,
							featureValue: 0.0806666666666666,
						},
						{
							conversationId: 3834,
							disconImpact: -0.14171034,
							featureValue: 0,
						},
						{
							conversationId: 3836,
							disconImpact: -0.15366748,
							featureValue: 0.0554999999999999,
						},
						{
							conversationId: 3839,
							disconImpact: -0.8587726,
							featureValue: 0.13,
						},
						{
							conversationId: 3848,
							disconImpact: 0.5447898,
							featureValue: 0.068,
						},
						{
							conversationId: 3849,
							disconImpact: 0.27691147,
							featureValue: 0.0503333333333333,
						},
						{
							conversationId: 3851,
							disconImpact: 0.09856037,
							featureValue: 0,
						},
						{
							conversationId: 3854,
							disconImpact: 0.3698745,
							featureValue: 0.075,
						},
						{
							conversationId: 3858,
							disconImpact: 0.3362938,
							featureValue: 0.0731111111111111,
						},
						{
							conversationId: 3861,
							disconImpact: 0.41871107,
							featureValue: 0.093,
						},
						{
							conversationId: 3865,
							disconImpact: -0.2287195,
							featureValue: 0.0771,
						},
						{
							conversationId: 3866,
							disconImpact: -0.16172758,
							featureValue: 0,
						},
						{
							conversationId: 3867,
							disconImpact: 0.025569914,
							featureValue: 0,
						},
						{
							conversationId: 3869,
							disconImpact: 0.4603557,
							featureValue: 0.0463333333333333,
						},
						{
							conversationId: 3871,
							disconImpact: 0.11567836,
							featureValue: 0,
						},
						{
							conversationId: 3873,
							disconImpact: -0.08233662,
							featureValue: 0.052875,
						},
						{
							conversationId: 3876,
							disconImpact: 0.15391053,
							featureValue: 0,
						},
						{
							conversationId: 3879,
							disconImpact: 0.04204427,
							featureValue: 0.0965666666666666,
						},
						{
							conversationId: 3880,
							disconImpact: 0.15700656,
							featureValue: 0.062,
						},
						{
							conversationId: 3882,
							disconImpact: 0.6348744,
							featureValue: 0.049,
						},
						{
							conversationId: 3884,
							disconImpact: -0.42247733,
							featureValue: 0.087,
						},
						{
							conversationId: 3890,
							disconImpact: -0.053149536,
							featureValue: 0,
						},
						{
							conversationId: 3892,
							disconImpact: -0.20964938,
							featureValue: 0.086,
						},
						{
							conversationId: 3893,
							disconImpact: -0.09473306,
							featureValue: 0.077,
						},
						{
							conversationId: 3894,
							disconImpact: 0.33314124,
							featureValue: 0.067,
						},
						{
							conversationId: 3899,
							disconImpact: 0.14016414,
							featureValue: 0.0569999999999999,
						},
						{
							conversationId: 3900,
							disconImpact: -0.056802522,
							featureValue: 0.0665,
						},
						{
							conversationId: 3901,
							disconImpact: 0.09350146,
							featureValue: 0.0435,
						},
						{
							conversationId: 3904,
							disconImpact: 0.50790864,
							featureValue: 0.0485,
						},
						{
							conversationId: 3905,
							disconImpact: -0.011749672,
							featureValue: 0.0588333333333333,
						},
						{
							conversationId: 3908,
							disconImpact: -0.2724029,
							featureValue: 0,
						},
						{
							conversationId: 3921,
							disconImpact: 0.15548347,
							featureValue: 0.0658888888888888,
						},
						{
							conversationId: 3922,
							disconImpact: 0.7350198,
							featureValue: 0.1001666666666666,
						},
						{
							conversationId: 3923,
							disconImpact: -0.11127438,
							featureValue: 0,
						},
						{
							conversationId: 3926,
							disconImpact: 0.596093,
							featureValue: 0.067,
						},
						{
							conversationId: 3932,
							disconImpact: 0.88617706,
							featureValue: 0.0932222222222222,
						},
						{
							conversationId: 3933,
							disconImpact: 0.9502107,
							featureValue: 0.102,
						},
						{
							conversationId: 3935,
							disconImpact: -0.2237201,
							featureValue: 0,
						},
						{
							conversationId: 3947,
							disconImpact: 0.3467869,
							featureValue: 0.0383333333333333,
						},
						{
							conversationId: 3949,
							disconImpact: 0.23954386,
							featureValue: 0.048,
						},
						{
							conversationId: 3956,
							disconImpact: -0.31652397,
							featureValue: 0.0956875,
						},
						{
							conversationId: 3959,
							disconImpact: -0.04789409,
							featureValue: 0,
						},
						{
							conversationId: 3961,
							disconImpact: -0.47449228,
							featureValue: 0.1165,
						},
						{
							conversationId: 3967,
							disconImpact: -0.009004228,
							featureValue: 0.234,
						},
						{
							conversationId: 3970,
							disconImpact: -0.14171067,
							featureValue: 0,
						},
						{
							conversationId: 3972,
							disconImpact: -0.08382792,
							featureValue: 0,
						},
						{
							conversationId: 3973,
							disconImpact: 0.50156415,
							featureValue: 0.1065,
						},
						{
							conversationId: 3975,
							disconImpact: -0.030914204,
							featureValue: 0,
						},
						{
							conversationId: 3976,
							disconImpact: -0.32991824,
							featureValue: 0,
						},
						{
							conversationId: 3983,
							disconImpact: -0.11579407,
							featureValue: 0.0575333333333333,
						},
						{
							conversationId: 3985,
							disconImpact: 0.37948167,
							featureValue: 0.068,
						},
						{
							conversationId: 3999,
							disconImpact: 0.30669892,
							featureValue: 0.037,
						},
						{
							conversationId: 4006,
							disconImpact: 0.4982943,
							featureValue: 0.049,
						},
						{
							conversationId: 4031,
							disconImpact: -0.24284963,
							featureValue: 0.06,
						},
						{
							conversationId: 4051,
							disconImpact: -0.09019449,
							featureValue: 0.051,
						},
						{
							conversationId: 4078,
							disconImpact: -0.34349415,
							featureValue: 0.0543333333333333,
						},
						{
							conversationId: 4079,
							disconImpact: -0.09653,
							featureValue: 0.0579999999999999,
						},
						{
							conversationId: 4089,
							disconImpact: -0.09146411,
							featureValue: 0,
						},
						{
							conversationId: 4091,
							disconImpact: -0.014028642,
							featureValue: 0,
						},
						{
							conversationId: 4099,
							disconImpact: -0.08637409,
							featureValue: 0.0765,
						},
						{
							conversationId: 4107,
							disconImpact: -0.15592086,
							featureValue: 0.0600833333333333,
						},
						{
							conversationId: 4112,
							disconImpact: 0.16473971,
							featureValue: 0.07,
						},
						{
							conversationId: 4129,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 4142,
							disconImpact: 0.36770463,
							featureValue: 0.043,
						},
						{
							conversationId: 4153,
							disconImpact: -0.00399034,
							featureValue: 0.095,
						},
						{
							conversationId: 4158,
							disconImpact: -0.21916607,
							featureValue: 0.0673333333333333,
						},
						{
							conversationId: 4164,
							disconImpact: -0.19768032,
							featureValue: 0,
						},
						{
							conversationId: 4171,
							disconImpact: 0.053080965,
							featureValue: 0,
						},
						{
							conversationId: 4186,
							disconImpact: 0.047001656,
							featureValue: 0,
						},
						{
							conversationId: 4197,
							disconImpact: -0.4670586,
							featureValue: 0,
						},
						{
							conversationId: 4213,
							disconImpact: 0.33446082,
							featureValue: 0.0695,
						},
						{
							conversationId: 4218,
							disconImpact: 0.24415165,
							featureValue: 0.041,
						},
						{
							conversationId: 4228,
							disconImpact: -0.1655675,
							featureValue: 0,
						},
						{
							conversationId: 4230,
							disconImpact: -0.56760454,
							featureValue: 0.16325,
						},
						{
							conversationId: 4231,
							disconImpact: 0.34298155,
							featureValue: 0.0404999999999999,
						},
						{
							conversationId: 4235,
							disconImpact: -0.0020251514,
							featureValue: 0.0699999999999999,
						},
						{
							conversationId: 4240,
							disconImpact: -0.103777446,
							featureValue: 0,
						},
						{
							conversationId: 4254,
							disconImpact: -0.102897465,
							featureValue: 0,
						},
						{
							conversationId: 4267,
							disconImpact: -0.008645289,
							featureValue: 0.055,
						},
						{
							conversationId: 4287,
							disconImpact: 0.418899,
							featureValue: 0.07,
						},
						{
							conversationId: 4289,
							disconImpact: -0.19476423,
							featureValue: 0.0605,
						},
						{
							conversationId: 4293,
							disconImpact: -0.09146411,
							featureValue: 0,
						},
						{
							conversationId: 4298,
							disconImpact: 0.6942051,
							featureValue: 0.047,
						},
						{
							conversationId: 4341,
							disconImpact: 0.55797946,
							featureValue: 0.0375,
						},
						{
							conversationId: 4347,
							disconImpact: -0.3516955,
							featureValue: 0,
						},
						{
							conversationId: 4350,
							disconImpact: 0.27410665,
							featureValue: 0.0684999999999999,
						},
						{
							conversationId: 4369,
							disconImpact: 0.009787681,
							featureValue: 0,
						},
						{
							conversationId: 4409,
							disconImpact: 0.7010156,
							featureValue: 0.049,
						},
						{
							conversationId: 4416,
							disconImpact: 0.05813449,
							featureValue: 0.0993125,
						},
						{
							conversationId: 4428,
							disconImpact: 0.49362043,
							featureValue: 0.074,
						},
						{
							conversationId: 4442,
							disconImpact: 0.46984243,
							featureValue: 0.049,
						},
						{
							conversationId: 4456,
							disconImpact: -0.34533006,
							featureValue: 0.0854999999999999,
						},
						{
							conversationId: 4484,
							disconImpact: 0.1272785,
							featureValue: 0.1063333333333333,
						},
						{
							conversationId: 4490,
							disconImpact: 0.3608211,
							featureValue: 0.102,
						},
						{
							conversationId: 4493,
							disconImpact: -0.012763087,
							featureValue: 0,
						},
						{
							conversationId: 4501,
							disconImpact: -0.62671953,
							featureValue: 0,
						},
						{
							conversationId: 4516,
							disconImpact: 0.07656355,
							featureValue: 0.05125,
						},
						{
							conversationId: 4521,
							disconImpact: -0.018297799,
							featureValue: 0,
						},
						{
							conversationId: 4531,
							disconImpact: -0.09248734,
							featureValue: 0,
						},
						{
							conversationId: 4532,
							disconImpact: -0.1620582,
							featureValue: 0,
						},
						{
							conversationId: 4539,
							disconImpact: -0.012763087,
							featureValue: 0,
						},
						{
							conversationId: 4541,
							disconImpact: 0.82414204,
							featureValue: 0.046,
						},
						{
							conversationId: 4543,
							disconImpact: 0.2871749,
							featureValue: 0.0308333333333333,
						},
						{
							conversationId: 4545,
							disconImpact: -0.13451552,
							featureValue: 0.0602222222222222,
						},
						{
							conversationId: 4547,
							disconImpact: 0.0064663086,
							featureValue: 0.0592083333333333,
						},
						{
							conversationId: 4554,
							disconImpact: -0.77492994,
							featureValue: 0.1285,
						},
						{
							conversationId: 4555,
							disconImpact: 0.28978786,
							featureValue: 0.0385,
						},
						{
							conversationId: 4572,
							disconImpact: -0.040965527,
							featureValue: 0.059125,
						},
						{
							conversationId: 4575,
							disconImpact: -0.091196194,
							featureValue: 0.025,
						},
						{
							conversationId: 4576,
							disconImpact: -0.0077474765,
							featureValue: 0,
						},
						{
							conversationId: 4579,
							disconImpact: -0.12214769,
							featureValue: 0,
						},
						{
							conversationId: 4585,
							disconImpact: 0.63932246,
							featureValue: 0.046,
						},
						{
							conversationId: 4587,
							disconImpact: 0.63386065,
							featureValue: 0.046,
						},
						{
							conversationId: 4594,
							disconImpact: 0.0046326267,
							featureValue: 0,
						},
						{
							conversationId: 4607,
							disconImpact: -0.04981479,
							featureValue: 0.058,
						},
						{
							conversationId: 4618,
							disconImpact: 0.36461422,
							featureValue: 0.0683333333333333,
						},
						{
							conversationId: 4629,
							disconImpact: 0.0013846881,
							featureValue: 0,
						},
						{
							conversationId: 4651,
							disconImpact: -0.026108872,
							featureValue: 0,
						},
						{
							conversationId: 4655,
							disconImpact: 0.4460152,
							featureValue: 0.051,
						},
						{
							conversationId: 4657,
							disconImpact: -0.01552367,
							featureValue: 0.056,
						},
						{
							conversationId: 4659,
							disconImpact: -0.12034702,
							featureValue: 0.077,
						},
						{
							conversationId: 4672,
							disconImpact: 0.3844089,
							featureValue: 0.0625,
						},
						{
							conversationId: 4701,
							disconImpact: -0.53756666,
							featureValue: 0.1669999999999999,
						},
						{
							conversationId: 4709,
							disconImpact: -0.050324246,
							featureValue: 0.056,
						},
						{
							conversationId: 4711,
							disconImpact: -0.06931554,
							featureValue: 0.062,
						},
						{
							conversationId: 4712,
							disconImpact: 0.7022589,
							featureValue: 0.104,
						},
						{
							conversationId: 4716,
							disconImpact: -0.17581579,
							featureValue: 0,
						},
						{
							conversationId: 4729,
							disconImpact: 0.22247425,
							featureValue: 0.073,
						},
						{
							conversationId: 4733,
							disconImpact: 0.03435909,
							featureValue: 0.0742857142857142,
						},
						{
							conversationId: 4735,
							disconImpact: -0.6088502,
							featureValue: 0.06,
						},
						{
							conversationId: 4739,
							disconImpact: 0.2954331,
							featureValue: 0.051,
						},
						{
							conversationId: 4740,
							disconImpact: -0.1478133,
							featureValue: 0.06,
						},
						{
							conversationId: 4747,
							disconImpact: -0.20688641,
							featureValue: 0,
						},
						{
							conversationId: 4757,
							disconImpact: -0.2228663,
							featureValue: 0,
						},
						{
							conversationId: 4762,
							disconImpact: 0.4621511,
							featureValue: 0.042,
						},
						{
							conversationId: 4765,
							disconImpact: -0.11714783,
							featureValue: 0.051,
						},
						{
							conversationId: 4766,
							disconImpact: 0.15893762,
							featureValue: 0.052,
						},
						{
							conversationId: 4771,
							disconImpact: 0.6404936,
							featureValue: 0.1001111111111111,
						},
						{
							conversationId: 4784,
							disconImpact: 0.23949674,
							featureValue: 0.0665,
						},
						{
							conversationId: 4796,
							disconImpact: 0.17122576,
							featureValue: 0.0409999999999999,
						},
						{
							conversationId: 4799,
							disconImpact: -0.078005075,
							featureValue: 0,
						},
						{
							conversationId: 4806,
							disconImpact: 0.5268559,
							featureValue: 0.0348333333333333,
						},
						{
							conversationId: 4808,
							disconImpact: -0.038939387,
							featureValue: 0.0553333333333333,
						},
						{
							conversationId: 4814,
							disconImpact: 0.19234055,
							featureValue: 0.03,
						},
						{
							conversationId: 4820,
							disconImpact: 0.2757871,
							featureValue: 0.0824166666666666,
						},
						{
							conversationId: 4828,
							disconImpact: -1.1539258,
							featureValue: 0.266,
						},
						{
							conversationId: 4830,
							disconImpact: 0.1772711,
							featureValue: 0.073,
						},
						{
							conversationId: 4831,
							disconImpact: 0.23460352,
							featureValue: 0.045,
						},
						{
							conversationId: 4838,
							disconImpact: -0.007298529,
							featureValue: 0.04,
						},
						{
							conversationId: 4842,
							disconImpact: 0.3562911,
							featureValue: 0.0871,
						},
						{
							conversationId: 4847,
							disconImpact: -0.062281784,
							featureValue: 0,
						},
						{
							conversationId: 4848,
							disconImpact: -0.06245944,
							featureValue: 0,
						},
						{
							conversationId: 4849,
							disconImpact: 0.045335483,
							featureValue: 0.044,
						},
						{
							conversationId: 4852,
							disconImpact: -0.06990222,
							featureValue: 0.071,
						},
						{
							conversationId: 4854,
							disconImpact: 0.31614,
							featureValue: 0.0355,
						},
						{
							conversationId: 4859,
							disconImpact: -0.054329548,
							featureValue: 0,
						},
						{
							conversationId: 4871,
							disconImpact: -0.10436025,
							featureValue: 0.0533333333333333,
						},
						{
							conversationId: 4873,
							disconImpact: -0.04742956,
							featureValue: 0.062,
						},
						{
							conversationId: 4890,
							disconImpact: -0.2950032,
							featureValue: 0.0636666666666666,
						},
						{
							conversationId: 4918,
							disconImpact: 0.11315202,
							featureValue: 0.059,
						},
						{
							conversationId: 4921,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 4925,
							disconImpact: 0.16129708,
							featureValue: 0.043,
						},
						{
							conversationId: 4934,
							disconImpact: -0.15297586,
							featureValue: 0,
						},
						{
							conversationId: 4939,
							disconImpact: -0.60415506,
							featureValue: 0.053,
						},
						{
							conversationId: 4940,
							disconImpact: -0.06471168,
							featureValue: 0.064,
						},
						{
							conversationId: 4942,
							disconImpact: 0.82214695,
							featureValue: 0.074,
						},
						{
							conversationId: 4945,
							disconImpact: 0.13858244,
							featureValue: 0.076,
						},
						{
							conversationId: 4948,
							disconImpact: 0.3798813,
							featureValue: 0.0445,
						},
						{
							conversationId: 4953,
							disconImpact: -0.083283715,
							featureValue: 0,
						},
						{
							conversationId: 4988,
							disconImpact: -0.10676359,
							featureValue: 0.063,
						},
						{
							conversationId: 4991,
							disconImpact: 0.36418676,
							featureValue: 0.043,
						},
						{
							conversationId: 4995,
							disconImpact: 0.43343338,
							featureValue: 0.0737142857142857,
						},
						{
							conversationId: 5000,
							disconImpact: -0.09982287,
							featureValue: 0,
						},
						{
							conversationId: 5002,
							disconImpact: -0.15518665,
							featureValue: 0.0605,
						},
						{
							conversationId: 5016,
							disconImpact: -0.35764793,
							featureValue: 0.0834,
						},
						{
							conversationId: 5021,
							disconImpact: -0.07798204,
							featureValue: 0,
						},
						{
							conversationId: 5026,
							disconImpact: -0.2283107,
							featureValue: 0,
						},
						{
							conversationId: 5028,
							disconImpact: 0.6753513,
							featureValue: 0.105,
						},
						{
							conversationId: 5036,
							disconImpact: 0.17820534,
							featureValue: 0.0905,
						},
						{
							conversationId: 5041,
							disconImpact: -0.17449832,
							featureValue: 0,
						},
						{
							conversationId: 5042,
							disconImpact: -0.31242165,
							featureValue: 0.0579999999999999,
						},
						{
							conversationId: 5045,
							disconImpact: 0.28464898,
							featureValue: 0.043,
						},
						{
							conversationId: 5048,
							disconImpact: 0.11914426,
							featureValue: 0.052,
						},
						{
							conversationId: 5050,
							disconImpact: 0.14449465,
							featureValue: 0,
						},
						{
							conversationId: 5054,
							disconImpact: -0.084118865,
							featureValue: 0,
						},
						{
							conversationId: 5107,
							disconImpact: -0.06080737,
							featureValue: 0,
						},
						{
							conversationId: 5159,
							disconImpact: -0.51735735,
							featureValue: 0.083,
						},
						{
							conversationId: 5214,
							disconImpact: -0.23006622,
							featureValue: 0.061,
						},
						{
							conversationId: 5359,
							disconImpact: -0.49969038,
							featureValue: 0.324,
						},
						{
							conversationId: 5387,
							disconImpact: -0.060770582,
							featureValue: 0,
						},
						{
							conversationId: 5440,
							disconImpact: 0.07082646,
							featureValue: 0.077,
						},
						{
							conversationId: 5516,
							disconImpact: -0.18733314,
							featureValue: 0.054,
						},
						{
							conversationId: 5614,
							disconImpact: 0.43169686,
							featureValue: 0.047,
						},
						{
							conversationId: 5619,
							disconImpact: 0.36300677,
							featureValue: 0.05,
						},
						{
							conversationId: 5663,
							disconImpact: -0.30341014,
							featureValue: 0.0675,
						},
						{
							conversationId: 5830,
							disconImpact: 0.16329122,
							featureValue: 0,
						},
						{
							conversationId: 5831,
							disconImpact: -0.05217761,
							featureValue: 0.05,
						},
						{
							conversationId: 5874,
							disconImpact: -0.10413308,
							featureValue: 0,
						},
						{
							conversationId: 5892,
							disconImpact: -0.18130782,
							featureValue: 0,
						},
						{
							conversationId: 5912,
							disconImpact: 0.6145328,
							featureValue: 0.049,
						},
						{
							conversationId: 5966,
							disconImpact: -0.030820876,
							featureValue: 0,
						},
						{
							conversationId: 5981,
							disconImpact: -0.01581945,
							featureValue: 0,
						},
						{
							conversationId: 6005,
							disconImpact: -0.009688418,
							featureValue: 0,
						},
						{
							conversationId: 6015,
							disconImpact: 0.1083997,
							featureValue: 0,
						},
						{
							conversationId: 6221,
							disconImpact: -0.15289909,
							featureValue: 0,
						},
						{
							conversationId: 6224,
							disconImpact: -0.12226237,
							featureValue: 0,
						},
						{
							conversationId: 6252,
							disconImpact: -0.5165365,
							featureValue: 0.0782,
						},
						{
							conversationId: 6270,
							disconImpact: -0.27615955,
							featureValue: 0,
						},
						{
							conversationId: 6278,
							disconImpact: -0.0248424,
							featureValue: 0,
						},
						{
							conversationId: 6291,
							disconImpact: -0.4150994,
							featureValue: 0.0605,
						},
						{
							conversationId: 6326,
							disconImpact: -0.09982287,
							featureValue: 0,
						},
						{
							conversationId: 6340,
							disconImpact: -0.09982287,
							featureValue: 0,
						},
						{
							conversationId: 6370,
							disconImpact: -0.13333312,
							featureValue: 0,
						},
						{
							conversationId: 6393,
							disconImpact: -0.07051827,
							featureValue: 0,
						},
						{
							conversationId: 6394,
							disconImpact: -0.16411719,
							featureValue: 0,
						},
						{
							conversationId: 6434,
							disconImpact: 0.07881855,
							featureValue: 0,
						},
						{
							conversationId: 6485,
							disconImpact: -0.17702654,
							featureValue: 0.0556666666666666,
						},
						{
							conversationId: 6518,
							disconImpact: 0.018662088,
							featureValue: 0.035,
						},
						{
							conversationId: 6527,
							disconImpact: -0.07532088,
							featureValue: 0.068,
						},
						{
							conversationId: 6538,
							disconImpact: -0.059755266,
							featureValue: 0,
						},
						{
							conversationId: 6709,
							disconImpact: 0.2259048,
							featureValue: 0.067,
						},
						{
							conversationId: 6715,
							disconImpact: -0.09982287,
							featureValue: 0,
						},
						{
							conversationId: 6738,
							disconImpact: -0.053228054,
							featureValue: 0.051,
						},
						{
							conversationId: 6781,
							disconImpact: -0.09982287,
							featureValue: 0,
						},
						{
							conversationId: 6800,
							disconImpact: 0.40736282,
							featureValue: 0.029,
						},
						{
							conversationId: 6829,
							disconImpact: -0.17043534,
							featureValue: 0.0635,
						},
						{
							conversationId: 6840,
							disconImpact: -0.7836031,
							featureValue: 0.06,
						},
						{
							conversationId: 6903,
							disconImpact: 0.19775039,
							featureValue: 0.0682,
						},
						{
							conversationId: 6943,
							disconImpact: -0.09982287,
							featureValue: 0,
						},
						{
							conversationId: 6944,
							disconImpact: -0.07475616,
							featureValue: 0,
						},
						{
							conversationId: 7030,
							disconImpact: 0.5791073,
							featureValue: 0.0465999999999999,
						},
						{
							conversationId: 7053,
							disconImpact: -0.26467168,
							featureValue: 0,
						},
						{
							conversationId: 7081,
							disconImpact: 0.30411687,
							featureValue: 0.0655,
						},
						{
							conversationId: 7170,
							disconImpact: -0.16934201,
							featureValue: 0.058,
						},
						{
							conversationId: 7222,
							disconImpact: -0.053070083,
							featureValue: 0,
						},
						{
							conversationId: 7338,
							disconImpact: -0.2647345,
							featureValue: 0,
						},
						{
							conversationId: 7612,
							disconImpact: -0.16453949,
							featureValue: 0.061,
						},
						{
							conversationId: 7762,
							disconImpact: -0.0248424,
							featureValue: 0,
						},
						{
							conversationId: 7866,
							disconImpact: 0.4251189,
							featureValue: 0.045,
						},
						{
							conversationId: 8060,
							disconImpact: -0.11110086,
							featureValue: 0,
						},
						{
							conversationId: 8515,
							disconImpact: 0.06772103,
							featureValue: 0.071,
						},
						{
							conversationId: 8577,
							disconImpact: -0.2262584,
							featureValue: 0,
						},
						{
							conversationId: 8639,
							disconImpact: -0.27348146,
							featureValue: 0,
						},
						{
							conversationId: 8663,
							disconImpact: 0.34079322,
							featureValue: 0.044,
						},
						{
							conversationId: 8665,
							disconImpact: 0.5836512,
							featureValue: 0.05,
						},
						{
							conversationId: 8667,
							disconImpact: -0.2785281,
							featureValue: 0.0575,
						},
						{
							conversationId: 8708,
							disconImpact: 0.026113484,
							featureValue: 0,
						},
						{
							conversationId: 8734,
							disconImpact: -0.34055838,
							featureValue: 0,
						},
						{
							conversationId: 8932,
							disconImpact: -0.14365086,
							featureValue: 0.053,
						},
						{
							conversationId: 8991,
							disconImpact: -0.08543635,
							featureValue: 0,
						},
						{
							conversationId: 9056,
							disconImpact: 0.030503798,
							featureValue: 0,
						},
						{
							conversationId: 9231,
							disconImpact: 0.02317866,
							featureValue: 0.051,
						},
						{
							conversationId: 9298,
							disconImpact: -0.16983998,
							featureValue: 0.0515,
						},
						{
							conversationId: 9311,
							disconImpact: 0.4007443,
							featureValue: 0.067,
						},
						{
							conversationId: 9414,
							disconImpact: -0.09982287,
							featureValue: 0,
						},
						{
							conversationId: 9542,
							disconImpact: -0.26873598,
							featureValue: 0.061,
						},
						{
							conversationId: 9558,
							disconImpact: -0.09982287,
							featureValue: 0,
						},
						{
							conversationId: 9578,
							disconImpact: -0.16411719,
							featureValue: 0,
						},
						{
							conversationId: 9654,
							disconImpact: 0.062103543,
							featureValue: 0.0559999999999999,
						},
						{
							conversationId: 9659,
							disconImpact: 0.06890954,
							featureValue: 0.034,
						},
						{
							conversationId: 9663,
							disconImpact: -0.13513404,
							featureValue: 0,
						},
						{
							conversationId: 9665,
							disconImpact: 0.015196862,
							featureValue: 0.066,
						},
						{
							conversationId: 9670,
							disconImpact: -0.13495685,
							featureValue: 0.033,
						},
						{
							conversationId: 9671,
							disconImpact: -0.24236646,
							featureValue: 0,
						},
						{
							conversationId: 9677,
							disconImpact: -0.14866874,
							featureValue: 0,
						},
						{
							conversationId: 9680,
							disconImpact: -1.1227286,
							featureValue: 0.1175,
						},
						{
							conversationId: 9681,
							disconImpact: -0.078005075,
							featureValue: 0,
						},
						{
							conversationId: 9691,
							disconImpact: -0.28099555,
							featureValue: 0.079,
						},
						{
							conversationId: 9692,
							disconImpact: -0.14110349,
							featureValue: 0.062,
						},
						{
							conversationId: 9694,
							disconImpact: -0.08972507,
							featureValue: 0,
						},
						{
							conversationId: 9696,
							disconImpact: -1.4125051,
							featureValue: 0.1555555555555555,
						},
						{
							conversationId: 9698,
							disconImpact: -0.010477681,
							featureValue: 0.067,
						},
						{
							conversationId: 9699,
							disconImpact: -0.07918753,
							featureValue: 0,
						},
						{
							conversationId: 9701,
							disconImpact: -0.049242154,
							featureValue: 0,
						},
						{
							conversationId: 9704,
							disconImpact: -0.1400932,
							featureValue: 0.05325,
						},
						{
							conversationId: 9705,
							disconImpact: -0.34712145,
							featureValue: 0.0808181818181818,
						},
						{
							conversationId: 9707,
							disconImpact: -0.1122832,
							featureValue: 0.072375,
						},
						{
							conversationId: 9712,
							disconImpact: -0.8223533,
							featureValue: 0.159,
						},
						{
							conversationId: 9717,
							disconImpact: -0.3321702,
							featureValue: 0,
						},
						{
							conversationId: 9718,
							disconImpact: -0.043281153,
							featureValue: 0,
						},
						{
							conversationId: 9732,
							disconImpact: 0.0218291,
							featureValue: 0.0683333333333333,
						},
						{
							conversationId: 9733,
							disconImpact: -0.3062603,
							featureValue: 0.052,
						},
						{
							conversationId: 9735,
							disconImpact: 0.3374299,
							featureValue: 0.066,
						},
						{
							conversationId: 9739,
							disconImpact: 0.11778001,
							featureValue: 0.0715,
						},
						{
							conversationId: 9742,
							disconImpact: 0.0150349215,
							featureValue: 0.0591666666666666,
						},
						{
							conversationId: 9744,
							disconImpact: 0.56774807,
							featureValue: 0.047,
						},
						{
							conversationId: 9748,
							disconImpact: -0.36659995,
							featureValue: 0.061,
						},
						{
							conversationId: 9752,
							disconImpact: -0.06370722,
							featureValue: 0,
						},
						{
							conversationId: 9756,
							disconImpact: -0.5953701,
							featureValue: 0.302,
						},
						{
							conversationId: 9762,
							disconImpact: 0.14366663,
							featureValue: 0.0863333333333333,
						},
						{
							conversationId: 9764,
							disconImpact: -0.029909933,
							featureValue: 0.091,
						},
						{
							conversationId: 9768,
							disconImpact: -0.012763087,
							featureValue: 0,
						},
						{
							conversationId: 9771,
							disconImpact: 0.6037966,
							featureValue: 0.06775,
						},
						{
							conversationId: 9775,
							disconImpact: 0.11089628,
							featureValue: 0.028,
						},
						{
							conversationId: 9777,
							disconImpact: -0.026169956,
							featureValue: 0.060375,
						},
						{
							conversationId: 9784,
							disconImpact: -0.14071435,
							featureValue: 0.0755,
						},
						{
							conversationId: 9785,
							disconImpact: -0.22711878,
							featureValue: 0.0604166666666666,
						},
						{
							conversationId: 9787,
							disconImpact: 0.05323837,
							featureValue: 0,
						},
						{
							conversationId: 9788,
							disconImpact: -0.030617284,
							featureValue: 0,
						},
						{
							conversationId: 9792,
							disconImpact: 0.31735408,
							featureValue: 0.037,
						},
						{
							conversationId: 9796,
							disconImpact: -0.0733397,
							featureValue: 0.0755,
						},
						{
							conversationId: 9798,
							disconImpact: 0.36964607,
							featureValue: 0.035,
						},
						{
							conversationId: 9799,
							disconImpact: -0.09088213,
							featureValue: 0.054,
						},
						{
							conversationId: 9802,
							disconImpact: 0.33794194,
							featureValue: 0.0473333333333333,
						},
						{
							conversationId: 9807,
							disconImpact: -0.10986339,
							featureValue: 0,
						},
						{
							conversationId: 9809,
							disconImpact: 0.54670113,
							featureValue: 0.049,
						},
						{
							conversationId: 9821,
							disconImpact: -0.43185675,
							featureValue: 0.079,
						},
						{
							conversationId: 9826,
							disconImpact: -0.9261323,
							featureValue: 0.19375,
						},
						{
							conversationId: 9828,
							disconImpact: -0.22837965,
							featureValue: 0,
						},
						{
							conversationId: 9830,
							disconImpact: -1.08196,
							featureValue: 0.13275,
						},
						{
							conversationId: 9838,
							disconImpact: -1.6142305,
							featureValue: 0.215,
						},
						{
							conversationId: 9839,
							disconImpact: 0.08461339,
							featureValue: 0,
						},
						{
							conversationId: 9840,
							disconImpact: 0.13331468,
							featureValue: 0,
						},
						{
							conversationId: 9848,
							disconImpact: -0.07242019,
							featureValue: 0,
						},
						{
							conversationId: 9857,
							disconImpact: -0.29412183,
							featureValue: 0.0555,
						},
						{
							conversationId: 9859,
							disconImpact: 0.3795027,
							featureValue: 0.1085,
						},
						{
							conversationId: 9860,
							disconImpact: -0.22759165,
							featureValue: 0.059,
						},
						{
							conversationId: 9864,
							disconImpact: 0.1374654,
							featureValue: 0.068,
						},
						{
							conversationId: 9865,
							disconImpact: -0.053960357,
							featureValue: 0,
						},
						{
							conversationId: 9876,
							disconImpact: -1.0544835,
							featureValue: 0.18825,
						},
						{
							conversationId: 9879,
							disconImpact: 0.05605237,
							featureValue: 0.0445,
						},
						{
							conversationId: 9882,
							disconImpact: 0.045317747,
							featureValue: 0.067,
						},
						{
							conversationId: 9883,
							disconImpact: -0.1074177,
							featureValue: 0.065,
						},
						{
							conversationId: 9896,
							disconImpact: -0.3409738,
							featureValue: 0.256,
						},
						{
							conversationId: 9899,
							disconImpact: -1.3477565,
							featureValue: 0.1654,
						},
						{
							conversationId: 9901,
							disconImpact: -0.8421846,
							featureValue: 0.157,
						},
						{
							conversationId: 9906,
							disconImpact: -0.010247521,
							featureValue: 0.065,
						},
						{
							conversationId: 9912,
							disconImpact: -0.014124012,
							featureValue: 0.0625,
						},
						{
							conversationId: 9914,
							disconImpact: -0.3218332,
							featureValue: 0.05345,
						},
						{
							conversationId: 9918,
							disconImpact: -1.1541748,
							featureValue: 0.181,
						},
						{
							conversationId: 9919,
							disconImpact: -0.17589524,
							featureValue: 0,
						},
						{
							conversationId: 9922,
							disconImpact: 0.28171325,
							featureValue: 0.050375,
						},
						{
							conversationId: 9923,
							disconImpact: -0.3961825,
							featureValue: 0.0635833333333333,
						},
						{
							conversationId: 9924,
							disconImpact: 0.048673075,
							featureValue: 0.0579999999999999,
						},
						{
							conversationId: 9927,
							disconImpact: -0.11127154,
							featureValue: 0,
						},
						{
							conversationId: 9930,
							disconImpact: 0.17810032,
							featureValue: 0.0396666666666666,
						},
						{
							conversationId: 9932,
							disconImpact: 0.45645627,
							featureValue: 0.0655,
						},
						{
							conversationId: 9933,
							disconImpact: -0.19652776,
							featureValue: 0,
						},
						{
							conversationId: 9938,
							disconImpact: 0.10229466,
							featureValue: 0.081,
						},
						{
							conversationId: 9940,
							disconImpact: -0.18329,
							featureValue: 0.04,
						},
						{
							conversationId: 9942,
							disconImpact: -0.08855321,
							featureValue: 0,
						},
						{
							conversationId: 9945,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 9947,
							disconImpact: -0.039097674,
							featureValue: 0.057,
						},
						{
							conversationId: 9950,
							disconImpact: -0.24109396,
							featureValue: 0,
						},
						{
							conversationId: 9952,
							disconImpact: 0.092623256,
							featureValue: 0.0651111111111111,
						},
						{
							conversationId: 9963,
							disconImpact: -1.0075727,
							featureValue: 0.1281666666666666,
						},
						{
							conversationId: 9966,
							disconImpact: 0.24347192,
							featureValue: 0.0333333333333333,
						},
						{
							conversationId: 9978,
							disconImpact: -0.053407747,
							featureValue: 0.0705,
						},
						{
							conversationId: 9981,
							disconImpact: -0.050084643,
							featureValue: 0,
						},
						{
							conversationId: 9990,
							disconImpact: 0.060001098,
							featureValue: 0.031,
						},
						{
							conversationId: 9996,
							disconImpact: 0.25629273,
							featureValue: 0.068,
						},
						{
							conversationId: 9999,
							disconImpact: 0.020654783,
							featureValue: 0.0645,
						},
						{
							conversationId: 10015,
							disconImpact: -0.1620582,
							featureValue: 0,
						},
						{
							conversationId: 10016,
							disconImpact: 0.21653923,
							featureValue: 0.056,
						},
						{
							conversationId: 10018,
							disconImpact: 0.92624885,
							featureValue: 0.048,
						},
						{
							conversationId: 10021,
							disconImpact: -0.36810088,
							featureValue: 0,
						},
						{
							conversationId: 10024,
							disconImpact: -0.19201586,
							featureValue: 0.0547499999999999,
						},
						{
							conversationId: 10031,
							disconImpact: -0.12759337,
							featureValue: 0.0579999999999999,
						},
						{
							conversationId: 10038,
							disconImpact: -0.09801812,
							featureValue: 0,
						},
						{
							conversationId: 10039,
							disconImpact: -0.17272876,
							featureValue: 0.0646111111111111,
						},
						{
							conversationId: 10041,
							disconImpact: -0.29841122,
							featureValue: 0.0755,
						},
						{
							conversationId: 10042,
							disconImpact: -1.3667458,
							featureValue: 0.1905,
						},
						{
							conversationId: 10043,
							disconImpact: -1.2581068,
							featureValue: 0.1325,
						},
						{
							conversationId: 10044,
							disconImpact: -0.09180998,
							featureValue: 0.06325,
						},
						{
							conversationId: 10046,
							disconImpact: -0.34994254,
							featureValue: 0,
						},
						{
							conversationId: 10050,
							disconImpact: -0.38828564,
							featureValue: 0.054,
						},
						{
							conversationId: 10058,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 10059,
							disconImpact: -0.23292229,
							featureValue: 0,
						},
						{
							conversationId: 10067,
							disconImpact: -0.093045175,
							featureValue: 0.1151666666666666,
						},
						{
							conversationId: 10068,
							disconImpact: -0.04507029,
							featureValue: 0,
						},
						{
							conversationId: 10069,
							disconImpact: -0.065145954,
							featureValue: 0.0694999999999999,
						},
						{
							conversationId: 10071,
							disconImpact: -0.9231513,
							featureValue: 0.148,
						},
						{
							conversationId: 10078,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 10079,
							disconImpact: 0.1315245,
							featureValue: 0.0715,
						},
						{
							conversationId: 10080,
							disconImpact: -0.31580848,
							featureValue: 0.014,
						},
						{
							conversationId: 10083,
							disconImpact: 0.20866004,
							featureValue: 0.073,
						},
						{
							conversationId: 10085,
							disconImpact: -0.525062,
							featureValue: 0.083,
						},
						{
							conversationId: 10089,
							disconImpact: -0.05279723,
							featureValue: 0,
						},
						{
							conversationId: 10094,
							disconImpact: 0.08771549,
							featureValue: 0.05,
						},
						{
							conversationId: 10095,
							disconImpact: 0.062995985,
							featureValue: 0.076,
						},
						{
							conversationId: 10096,
							disconImpact: -0.32290292,
							featureValue: 0.06,
						},
						{
							conversationId: 10106,
							disconImpact: -0.32971007,
							featureValue: 0,
						},
						{
							conversationId: 10109,
							disconImpact: -0.61915654,
							featureValue: 0.089,
						},
						{
							conversationId: 10113,
							disconImpact: 0.49619913,
							featureValue: 0.048,
						},
						{
							conversationId: 10117,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 10120,
							disconImpact: -0.43999028,
							featureValue: 0.0599166666666666,
						},
						{
							conversationId: 10121,
							disconImpact: 0.33695948,
							featureValue: 0.068,
						},
						{
							conversationId: 10124,
							disconImpact: 0.1079615,
							featureValue: 0.073,
						},
						{
							conversationId: 10130,
							disconImpact: -0.042199027,
							featureValue: 0.0566111111111111,
						},
						{
							conversationId: 10131,
							disconImpact: 0.028677614,
							featureValue: 0.052,
						},
						{
							conversationId: 10132,
							disconImpact: 0.588664,
							featureValue: 0.047,
						},
						{
							conversationId: 10133,
							disconImpact: -0.09693184,
							featureValue: 0.1087499999999999,
						},
						{
							conversationId: 10134,
							disconImpact: -1.0335368,
							featureValue: 0.224,
						},
						{
							conversationId: 10138,
							disconImpact: 0.7442203,
							featureValue: 0.1021666666666666,
						},
						{
							conversationId: 10140,
							disconImpact: -0.19238693,
							featureValue: 0,
						},
						{
							conversationId: 10144,
							disconImpact: -0.19730493,
							featureValue: 0.0611666666666666,
						},
						{
							conversationId: 10147,
							disconImpact: -0.08782727,
							featureValue: 0,
						},
						{
							conversationId: 10150,
							disconImpact: -0.026158977,
							featureValue: 0.033,
						},
						{
							conversationId: 10167,
							disconImpact: -0.75096375,
							featureValue: 0.1649999999999999,
						},
						{
							conversationId: 10169,
							disconImpact: 0.09149201,
							featureValue: 0.091,
						},
						{
							conversationId: 10172,
							disconImpact: 0.24386694,
							featureValue: 0.033,
						},
						{
							conversationId: 10173,
							disconImpact: -0.14967027,
							featureValue: 0,
						},
						{
							conversationId: 10180,
							disconImpact: -0.077801876,
							featureValue: 0,
						},
						{
							conversationId: 10181,
							disconImpact: -0.41395536,
							featureValue: 0.054,
						},
						{
							conversationId: 10182,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 10184,
							disconImpact: -0.13480741,
							featureValue: 0.053,
						},
						{
							conversationId: 10186,
							disconImpact: -0.30001545,
							featureValue: 0.079625,
						},
						{
							conversationId: 10187,
							disconImpact: -0.16958316,
							featureValue: 0,
						},
						{
							conversationId: 10189,
							disconImpact: -0.09982287,
							featureValue: 0,
						},
						{
							conversationId: 10190,
							disconImpact: -0.41412923,
							featureValue: 0.084,
						},
						{
							conversationId: 10192,
							disconImpact: -1.2415662,
							featureValue: 0.138,
						},
						{
							conversationId: 10196,
							disconImpact: -0.8612114,
							featureValue: 0.152,
						},
						{
							conversationId: 10199,
							disconImpact: 0.22503921,
							featureValue: 0.038,
						},
						{
							conversationId: 10200,
							disconImpact: -0.17477123,
							featureValue: 0.0698055555555555,
						},
						{
							conversationId: 10202,
							disconImpact: -0.58810216,
							featureValue: 0.182,
						},
						{
							conversationId: 10205,
							disconImpact: -0.16044714,
							featureValue: 0,
						},
						{
							conversationId: 10206,
							disconImpact: -0.05080729,
							featureValue: 0,
						},
						{
							conversationId: 10207,
							disconImpact: -0.62289095,
							featureValue: 0.082375,
						},
						{
							conversationId: 10214,
							disconImpact: -0.2093601,
							featureValue: 0,
						},
						{
							conversationId: 10224,
							disconImpact: 0.0010534786,
							featureValue: 0,
						},
						{
							conversationId: 10226,
							disconImpact: -0.19322684,
							featureValue: 0,
						},
						{
							conversationId: 10228,
							disconImpact: -0.32179546,
							featureValue: 0.052,
						},
						{
							conversationId: 10235,
							disconImpact: -0.16767941,
							featureValue: 0.0595,
						},
						{
							conversationId: 10236,
							disconImpact: -0.7024846,
							featureValue: 0.1404999999999999,
						},
						{
							conversationId: 10238,
							disconImpact: -0.045496587,
							featureValue: 0,
						},
						{
							conversationId: 10240,
							disconImpact: -0.8119177,
							featureValue: 0.2083333333333333,
						},
						{
							conversationId: 10242,
							disconImpact: 0.35385823,
							featureValue: 0.04625,
						},
						{
							conversationId: 10249,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 10250,
							disconImpact: -0.2515324,
							featureValue: 0.053,
						},
						{
							conversationId: 10254,
							disconImpact: -0.24321775,
							featureValue: 0.052,
						},
						{
							conversationId: 10255,
							disconImpact: 0.28324828,
							featureValue: 0.044,
						},
						{
							conversationId: 10259,
							disconImpact: -0.08370074,
							featureValue: 0,
						},
						{
							conversationId: 10262,
							disconImpact: -0.08597376,
							featureValue: 0.0660833333333333,
						},
						{
							conversationId: 10265,
							disconImpact: 0.031840503,
							featureValue: 0.0738333333333333,
						},
						{
							conversationId: 10268,
							disconImpact: -0.09725738,
							featureValue: 0,
						},
						{
							conversationId: 10269,
							disconImpact: 0.34181455,
							featureValue: 0.0665,
						},
						{
							conversationId: 10276,
							disconImpact: -0.14353105,
							featureValue: 0.0876666666666666,
						},
						{
							conversationId: 10286,
							disconImpact: -0.649322,
							featureValue: 0.3399999999999999,
						},
						{
							conversationId: 10289,
							disconImpact: -0.116720214,
							featureValue: 0,
						},
						{
							conversationId: 10293,
							disconImpact: -0.09585843,
							featureValue: 0,
						},
						{
							conversationId: 10297,
							disconImpact: 0.45531514,
							featureValue: 0.0419999999999999,
						},
						{
							conversationId: 10299,
							disconImpact: -0.05391812,
							featureValue: 0,
						},
						{
							conversationId: 10302,
							disconImpact: -0.5142186,
							featureValue: 0.0886388888888888,
						},
						{
							conversationId: 10308,
							disconImpact: -0.043281153,
							featureValue: 0,
						},
						{
							conversationId: 10309,
							disconImpact: -0.5240103,
							featureValue: 0.178,
						},
						{
							conversationId: 10312,
							disconImpact: 0.5487747,
							featureValue: 0.046,
						},
						{
							conversationId: 10314,
							disconImpact: 0.058604818,
							featureValue: 0.0633333333333333,
						},
						{
							conversationId: 10315,
							disconImpact: -0.067633756,
							featureValue: 0,
						},
						{
							conversationId: 10318,
							disconImpact: -0.021355877,
							featureValue: 0,
						},
						{
							conversationId: 10319,
							disconImpact: -0.42926916,
							featureValue: 0.0508333333333333,
						},
						{
							conversationId: 10320,
							disconImpact: -0.010336806,
							featureValue: 0,
						},
						{
							conversationId: 10325,
							disconImpact: -0.2180022,
							featureValue: 0,
						},
						{
							conversationId: 10330,
							disconImpact: -0.19655573,
							featureValue: 0.0515,
						},
						{
							conversationId: 10333,
							disconImpact: -0.41033906,
							featureValue: 0.0548888888888888,
						},
						{
							conversationId: 10336,
							disconImpact: -0.062591925,
							featureValue: 0.064,
						},
						{
							conversationId: 10338,
							disconImpact: 0.27750742,
							featureValue: 0.1129999999999999,
						},
						{
							conversationId: 10339,
							disconImpact: 0.28773484,
							featureValue: 0.0423333333333333,
						},
						{
							conversationId: 10344,
							disconImpact: -0.012763087,
							featureValue: 0,
						},
						{
							conversationId: 10347,
							disconImpact: 0.368356,
							featureValue: 0.0445,
						},
						{
							conversationId: 10358,
							disconImpact: 0.10794959,
							featureValue: 0.076,
						},
						{
							conversationId: 10360,
							disconImpact: -0.061603777,
							featureValue: 0.0526666666666666,
						},
						{
							conversationId: 10361,
							disconImpact: 0.75036913,
							featureValue: 0.047,
						},
						{
							conversationId: 10372,
							disconImpact: -0.112339474,
							featureValue: 0.051,
						},
						{
							conversationId: 10373,
							disconImpact: 0.05010768,
							featureValue: 0.0705,
						},
						{
							conversationId: 10379,
							disconImpact: -0.15620446,
							featureValue: 0.0564999999999999,
						},
						{
							conversationId: 10380,
							disconImpact: -0.07753244,
							featureValue: 0,
						},
						{
							conversationId: 10384,
							disconImpact: 0.0039066914,
							featureValue: 0.0605,
						},
						{
							conversationId: 10386,
							disconImpact: -0.26614583,
							featureValue: 0.076,
						},
						{
							conversationId: 10388,
							disconImpact: -0.21864653,
							featureValue: 0.0575,
						},
						{
							conversationId: 10394,
							disconImpact: -0.3878103,
							featureValue: 0.086,
						},
						{
							conversationId: 10395,
							disconImpact: 0.088019826,
							featureValue: 0.05,
						},
						{
							conversationId: 10398,
							disconImpact: -0.4162821,
							featureValue: 0.108,
						},
						{
							conversationId: 10407,
							disconImpact: 0.21484943,
							featureValue: 0,
						},
						{
							conversationId: 10420,
							disconImpact: -0.18357703,
							featureValue: 0,
						},
						{
							conversationId: 10425,
							disconImpact: -0.13601573,
							featureValue: 0,
						},
						{
							conversationId: 10426,
							disconImpact: -0.008164511,
							featureValue: 0.07025,
						},
						{
							conversationId: 10427,
							disconImpact: -0.14985043,
							featureValue: 0,
						},
						{
							conversationId: 10428,
							disconImpact: 0.1603351,
							featureValue: 0.036,
						},
						{
							conversationId: 10429,
							disconImpact: -0.27553707,
							featureValue: 0.06,
						},
						{
							conversationId: 10432,
							disconImpact: 0.49390605,
							featureValue: 0.068,
						},
						{
							conversationId: 10433,
							disconImpact: 0.4300937,
							featureValue: 0.04675,
						},
						{
							conversationId: 10438,
							disconImpact: -0.89501524,
							featureValue: 0.3705,
						},
						{
							conversationId: 10439,
							disconImpact: -0.3641118,
							featureValue: 0.06,
						},
						{
							conversationId: 10445,
							disconImpact: 0.20501378,
							featureValue: 0.092,
						},
						{
							conversationId: 10447,
							disconImpact: -0.12085449,
							featureValue: 0,
						},
						{
							conversationId: 10451,
							disconImpact: -0.17091778,
							featureValue: 0,
						},
						{
							conversationId: 10452,
							disconImpact: -0.08855321,
							featureValue: 0,
						},
						{
							conversationId: 10455,
							disconImpact: -0.026354687,
							featureValue: 0,
						},
						{
							conversationId: 10460,
							disconImpact: 0.34221563,
							featureValue: 0.04875,
						},
						{
							conversationId: 10465,
							disconImpact: -0.5307228,
							featureValue: 0.083,
						},
						{
							conversationId: 10466,
							disconImpact: -0.16907205,
							featureValue: 0.076,
						},
						{
							conversationId: 10467,
							disconImpact: 0.25951952,
							featureValue: 0.035,
						},
						{
							conversationId: 10470,
							disconImpact: -0.07395564,
							featureValue: 0,
						},
						{
							conversationId: 10471,
							disconImpact: -0.78519154,
							featureValue: 0.08,
						},
						{
							conversationId: 10472,
							disconImpact: -0.043281153,
							featureValue: 0,
						},
						{
							conversationId: 10474,
							disconImpact: 0.04438666,
							featureValue: 0.1085,
						},
						{
							conversationId: 10478,
							disconImpact: -0.24693449,
							featureValue: 0,
						},
						{
							conversationId: 10480,
							disconImpact: 0.26076263,
							featureValue: 0.044,
						},
						{
							conversationId: 10481,
							disconImpact: -0.07840947,
							featureValue: 0.073,
						},
						{
							conversationId: 10486,
							disconImpact: -0.06533068,
							featureValue: 0.0578333333333333,
						},
						{
							conversationId: 10487,
							disconImpact: -0.23257929,
							featureValue: 0,
						},
						{
							conversationId: 10495,
							disconImpact: -0.20280793,
							featureValue: 0,
						},
						{
							conversationId: 10503,
							disconImpact: 0.17625006,
							featureValue: 0.051,
						},
						{
							conversationId: 10507,
							disconImpact: -0.2173923,
							featureValue: 0,
						},
						{
							conversationId: 10511,
							disconImpact: -0.58460426,
							featureValue: 0.059,
						},
						{
							conversationId: 10523,
							disconImpact: -0.0644016,
							featureValue: 0.07675,
						},
						{
							conversationId: 10528,
							disconImpact: -0.07566905,
							featureValue: 0,
						},
						{
							conversationId: 10530,
							disconImpact: -0.053676996,
							featureValue: 0.0416666666666666,
						},
						{
							conversationId: 10534,
							disconImpact: -0.0034851264,
							featureValue: 0.067,
						},
						{
							conversationId: 10535,
							disconImpact: -0.27614167,
							featureValue: 0.062,
						},
						{
							conversationId: 10537,
							disconImpact: -0.75081325,
							featureValue: 0.08,
						},
						{
							conversationId: 10538,
							disconImpact: -0.18475884,
							featureValue: 0,
						},
						{
							conversationId: 10550,
							disconImpact: -0.1396212,
							featureValue: 0,
						},
						{
							conversationId: 10551,
							disconImpact: -0.11429227,
							featureValue: 0,
						},
						{
							conversationId: 10552,
							disconImpact: -0.2111291,
							featureValue: 0.0515,
						},
						{
							conversationId: 10558,
							disconImpact: -0.08045747,
							featureValue: 0.0915,
						},
						{
							conversationId: 10559,
							disconImpact: -1.5574372,
							featureValue: 0.1634166666666666,
						},
						{
							conversationId: 10561,
							disconImpact: -0.39767602,
							featureValue: 0.02,
						},
						{
							conversationId: 10563,
							disconImpact: -0.2679254,
							featureValue: 0,
						},
						{
							conversationId: 10564,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 10568,
							disconImpact: -0.063474566,
							featureValue: 0.0626666666666666,
						},
						{
							conversationId: 10588,
							disconImpact: -0.9741756,
							featureValue: 0.2175,
						},
						{
							conversationId: 10592,
							disconImpact: -0.03800552,
							featureValue: 0.065,
						},
						{
							conversationId: 10595,
							disconImpact: -0.45729497,
							featureValue: 0.061,
						},
						{
							conversationId: 10597,
							disconImpact: -0.19010776,
							featureValue: 0.0570833333333333,
						},
						{
							conversationId: 10598,
							disconImpact: -0.4392022,
							featureValue: 0.0849999999999999,
						},
						{
							conversationId: 10606,
							disconImpact: -0.014855577,
							featureValue: 0.072,
						},
						{
							conversationId: 10609,
							disconImpact: -0.4912516,
							featureValue: 0.0842,
						},
						{
							conversationId: 10611,
							disconImpact: -0.06956951,
							featureValue: 0,
						},
						{
							conversationId: 10614,
							disconImpact: -0.3975693,
							featureValue: 0,
						},
						{
							conversationId: 10618,
							disconImpact: -0.040957883,
							featureValue: 0,
						},
						{
							conversationId: 10621,
							disconImpact: 0.18421288,
							featureValue: 0.064,
						},
						{
							conversationId: 10622,
							disconImpact: 0.11786532,
							featureValue: 0.1055,
						},
						{
							conversationId: 10624,
							disconImpact: -0.05288639,
							featureValue: 0,
						},
						{
							conversationId: 10627,
							disconImpact: -0.048507817,
							featureValue: 0.0716666666666666,
						},
						{
							conversationId: 10632,
							disconImpact: -0.67114544,
							featureValue: 0.0854999999999999,
						},
						{
							conversationId: 10635,
							disconImpact: -0.064689174,
							featureValue: 0,
						},
						{
							conversationId: 10641,
							disconImpact: -0.28652993,
							featureValue: 0,
						},
						{
							conversationId: 10646,
							disconImpact: 0.07937701,
							featureValue: 0.058,
						},
						{
							conversationId: 10647,
							disconImpact: -0.9688743,
							featureValue: 0.174,
						},
						{
							conversationId: 10648,
							disconImpact: 0.36736593,
							featureValue: 0.0655,
						},
						{
							conversationId: 10650,
							disconImpact: -0.12536368,
							featureValue: 0,
						},
						{
							conversationId: 10651,
							disconImpact: -0.040304713,
							featureValue: 0.0553333333333333,
						},
						{
							conversationId: 10667,
							disconImpact: -0.2042933,
							featureValue: 0,
						},
						{
							conversationId: 10670,
							disconImpact: -0.096660696,
							featureValue: 0,
						},
						{
							conversationId: 10675,
							disconImpact: -0.14154907,
							featureValue: 0.0535,
						},
						{
							conversationId: 10676,
							disconImpact: 0.13367106,
							featureValue: 0.065,
						},
						{
							conversationId: 10677,
							disconImpact: 0.057113104,
							featureValue: 0.0725,
						},
						{
							conversationId: 10679,
							disconImpact: -0.4268586,
							featureValue: 0,
						},
						{
							conversationId: 10683,
							disconImpact: -0.22812086,
							featureValue: 0.079,
						},
						{
							conversationId: 10686,
							disconImpact: -0.16214842,
							featureValue: 0.0575,
						},
						{
							conversationId: 10687,
							disconImpact: -0.28556618,
							featureValue: 0.0562,
						},
						{
							conversationId: 10691,
							disconImpact: -0.31775677,
							featureValue: 0,
						},
						{
							conversationId: 10692,
							disconImpact: 0.16123834,
							featureValue: 0.044,
						},
						{
							conversationId: 10695,
							disconImpact: -0.02571165,
							featureValue: 0,
						},
						{
							conversationId: 10700,
							disconImpact: -0.9523391,
							featureValue: 0.1392499999999999,
						},
						{
							conversationId: 10705,
							disconImpact: -0.17988357,
							featureValue: 0,
						},
						{
							conversationId: 10707,
							disconImpact: -0.20406614,
							featureValue: 0,
						},
						{
							conversationId: 10709,
							disconImpact: -0.33201835,
							featureValue: 0,
						},
						{
							conversationId: 10713,
							disconImpact: -0.16881919,
							featureValue: 0,
						},
						{
							conversationId: 10714,
							disconImpact: 0.50902027,
							featureValue: 0.0895,
						},
						{
							conversationId: 10717,
							disconImpact: -0.2356898,
							featureValue: 0,
						},
						{
							conversationId: 10721,
							disconImpact: -0.010601677,
							featureValue: 0.098,
						},
						{
							conversationId: 10723,
							disconImpact: -0.33148956,
							featureValue: 0,
						},
						{
							conversationId: 10725,
							disconImpact: -0.071379565,
							featureValue: 0.091,
						},
						{
							conversationId: 10730,
							disconImpact: -0.44129845,
							featureValue: 0.0847499999999999,
						},
						{
							conversationId: 10732,
							disconImpact: -0.08747991,
							featureValue: 0.0776666666666666,
						},
						{
							conversationId: 10733,
							disconImpact: 0.34873864,
							featureValue: 0.0443333333333333,
						},
						{
							conversationId: 10739,
							disconImpact: -0.24919331,
							featureValue: 0.087,
						},
						{
							conversationId: 10746,
							disconImpact: -0.041540615,
							featureValue: 0.0595,
						},
						{
							conversationId: 10747,
							disconImpact: 0.3307177,
							featureValue: 0.0424999999999999,
						},
						{
							conversationId: 10749,
							disconImpact: -0.19300045,
							featureValue: 0.0768333333333333,
						},
						{
							conversationId: 10752,
							disconImpact: 0.07462893,
							featureValue: 0,
						},
						{
							conversationId: 10754,
							disconImpact: -0.32864922,
							featureValue: 0,
						},
						{
							conversationId: 10757,
							disconImpact: -0.13739078,
							featureValue: 0,
						},
						{
							conversationId: 10758,
							disconImpact: -0.17825648,
							featureValue: 0.063,
						},
						{
							conversationId: 10760,
							disconImpact: -0.21601331,
							featureValue: 0,
						},
						{
							conversationId: 10770,
							disconImpact: -0.08782727,
							featureValue: 0,
						},
						{
							conversationId: 10771,
							disconImpact: 0.526885,
							featureValue: 0.067,
						},
						{
							conversationId: 10784,
							disconImpact: 0.08940946,
							featureValue: 0.051,
						},
						{
							conversationId: 10787,
							disconImpact: 0.26181877,
							featureValue: 0.04625,
						},
						{
							conversationId: 10789,
							disconImpact: -0.26938242,
							featureValue: 0.052,
						},
						{
							conversationId: 10790,
							disconImpact: -0.1356427,
							featureValue: 0.06875,
						},
						{
							conversationId: 10792,
							disconImpact: -0.102055214,
							featureValue: 0.066,
						},
						{
							conversationId: 10794,
							disconImpact: -0.14141557,
							featureValue: 0.0546666666666666,
						},
						{
							conversationId: 10795,
							disconImpact: -0.06871921,
							featureValue: 0.069,
						},
						{
							conversationId: 10799,
							disconImpact: 0.42586634,
							featureValue: 0.108,
						},
						{
							conversationId: 10800,
							disconImpact: -0.2937543,
							featureValue: 0,
						},
						{
							conversationId: 10802,
							disconImpact: -0.3055391,
							featureValue: 0,
						},
						{
							conversationId: 10803,
							disconImpact: -0.25263762,
							featureValue: 0,
						},
						{
							conversationId: 10811,
							disconImpact: -0.35927755,
							featureValue: 0.0853333333333333,
						},
						{
							conversationId: 10815,
							disconImpact: -0.09725738,
							featureValue: 0,
						},
						{
							conversationId: 10818,
							disconImpact: 0.118878566,
							featureValue: 0.073,
						},
						{
							conversationId: 10820,
							disconImpact: 0.16214651,
							featureValue: 0.0596,
						},
						{
							conversationId: 10824,
							disconImpact: -0.45902115,
							featureValue: 0.265,
						},
						{
							conversationId: 10826,
							disconImpact: 0.021509465,
							featureValue: 0,
						},
						{
							conversationId: 10828,
							disconImpact: 0.16728047,
							featureValue: 0.067,
						},
						{
							conversationId: 10834,
							disconImpact: -0.105514236,
							featureValue: 0,
						},
						{
							conversationId: 10836,
							disconImpact: 0.5549252,
							featureValue: 0.0495,
						},
						{
							conversationId: 10837,
							disconImpact: -0.16993645,
							featureValue: 0,
						},
						{
							conversationId: 10846,
							disconImpact: -0.17377871,
							featureValue: 0.051,
						},
						{
							conversationId: 10848,
							disconImpact: -0.3925746,
							featureValue: 0.0905,
						},
						{
							conversationId: 10854,
							disconImpact: -0.13238189,
							featureValue: 0,
						},
						{
							conversationId: 10856,
							disconImpact: -0.31586242,
							featureValue: 0.09165,
						},
						{
							conversationId: 10857,
							disconImpact: -0.33455428,
							featureValue: 0.0546666666666666,
						},
						{
							conversationId: 10859,
							disconImpact: -0.27277657,
							featureValue: 0.109,
						},
						{
							conversationId: 10863,
							disconImpact: -0.047647174,
							featureValue: 0.054,
						},
						{
							conversationId: 10868,
							disconImpact: -0.27364448,
							featureValue: 0.059,
						},
						{
							conversationId: 10872,
							disconImpact: -0.3487383,
							featureValue: 0.079,
						},
						{
							conversationId: 10874,
							disconImpact: -0.28151533,
							featureValue: 0.0643333333333333,
						},
						{
							conversationId: 10877,
							disconImpact: 0.08915506,
							featureValue: 0.1122499999999999,
						},
						{
							conversationId: 10878,
							disconImpact: 0.056394055,
							featureValue: 0.0593333333333333,
						},
						{
							conversationId: 10879,
							disconImpact: -0.587554,
							featureValue: 0.2045,
						},
						{
							conversationId: 10881,
							disconImpact: -0.043673646,
							featureValue: 0.078,
						},
						{
							conversationId: 10882,
							disconImpact: -1.3647311,
							featureValue: 0.148,
						},
						{
							conversationId: 10886,
							disconImpact: 0.1852772,
							featureValue: 0.0555,
						},
						{
							conversationId: 10887,
							disconImpact: -0.018742131,
							featureValue: 0.055,
						},
						{
							conversationId: 10889,
							disconImpact: 0.016296443,
							featureValue: 0.0555,
						},
						{
							conversationId: 10895,
							disconImpact: -0.043281153,
							featureValue: 0,
						},
						{
							conversationId: 10898,
							disconImpact: -0.9361049,
							featureValue: 0.1422999999999999,
						},
						{
							conversationId: 10900,
							disconImpact: 0.5412717,
							featureValue: 0.047,
						},
						{
							conversationId: 10913,
							disconImpact: -0.056697965,
							featureValue: 0,
						},
						{
							conversationId: 10918,
							disconImpact: 0.47923583,
							featureValue: 0.043,
						},
						{
							conversationId: 10922,
							disconImpact: 0.15242906,
							featureValue: 0.07175,
						},
						{
							conversationId: 10925,
							disconImpact: -0.22979736,
							featureValue: 0,
						},
						{
							conversationId: 10928,
							disconImpact: -0.17986423,
							featureValue: 0,
						},
						{
							conversationId: 10929,
							disconImpact: 0.21695188,
							featureValue: 0.0353333333333333,
						},
						{
							conversationId: 10932,
							disconImpact: -0.41363907,
							featureValue: 0.061,
						},
						{
							conversationId: 10934,
							disconImpact: 0.08807936,
							featureValue: 0.076,
						},
						{
							conversationId: 10950,
							disconImpact: -0.59327894,
							featureValue: 0.0603333333333333,
						},
						{
							conversationId: 10951,
							disconImpact: 0.109647214,
							featureValue: 0.0574999999999999,
						},
						{
							conversationId: 10954,
							disconImpact: -0.10574406,
							featureValue: 0,
						},
						{
							conversationId: 10955,
							disconImpact: -0.020174596,
							featureValue: 0,
						},
						{
							conversationId: 10957,
							disconImpact: 0.09359576,
							featureValue: 0.0348611111111111,
						},
						{
							conversationId: 10963,
							disconImpact: 0.055480763,
							featureValue: 0.068,
						},
						{
							conversationId: 10966,
							disconImpact: 0.24220422,
							featureValue: 0.073,
						},
						{
							conversationId: 10968,
							disconImpact: 0.20322138,
							featureValue: 0,
						},
						{
							conversationId: 10970,
							disconImpact: -0.018045248,
							featureValue: 0,
						},
						{
							conversationId: 10971,
							disconImpact: -0.064382106,
							featureValue: 0.0524642857142857,
						},
						{
							conversationId: 10973,
							disconImpact: -0.12363034,
							featureValue: 0,
						},
						{
							conversationId: 10983,
							disconImpact: -0.3137875,
							featureValue: 0.05175,
						},
						{
							conversationId: 10984,
							disconImpact: 0.020398099,
							featureValue: 0.06925,
						},
						{
							conversationId: 10992,
							disconImpact: 0.22732973,
							featureValue: 0,
						},
						{
							conversationId: 11001,
							disconImpact: -0.07075543,
							featureValue: 0.063,
						},
						{
							conversationId: 11002,
							disconImpact: -0.19009817,
							featureValue: 0.054,
						},
						{
							conversationId: 11003,
							disconImpact: -0.8715827,
							featureValue: 0.285,
						},
						{
							conversationId: 11013,
							disconImpact: 0.04865183,
							featureValue: 0.066,
						},
						{
							conversationId: 11025,
							disconImpact: -0.008074623,
							featureValue: 0.0541785714285714,
						},
						{
							conversationId: 11026,
							disconImpact: -0.11764725,
							featureValue: 0,
						},
						{
							conversationId: 11027,
							disconImpact: -0.16181773,
							featureValue: 0,
						},
						{
							conversationId: 11029,
							disconImpact: -0.13229643,
							featureValue: 0.03325,
						},
						{
							conversationId: 11038,
							disconImpact: -0.05323781,
							featureValue: 0.06,
						},
						{
							conversationId: 11042,
							disconImpact: -0.07082447,
							featureValue: 0,
						},
						{
							conversationId: 11043,
							disconImpact: -0.23354296,
							featureValue: 0.07,
						},
						{
							conversationId: 11045,
							disconImpact: -0.8255198,
							featureValue: 0.248,
						},
						{
							conversationId: 11046,
							disconImpact: -0.48687407,
							featureValue: 0.05125,
						},
						{
							conversationId: 11047,
							disconImpact: -0.13114583,
							featureValue: 0.057,
						},
						{
							conversationId: 11049,
							disconImpact: -0.18013959,
							featureValue: 0.1134999999999999,
						},
						{
							conversationId: 11053,
							disconImpact: 0.40024945,
							featureValue: 0.058,
						},
						{
							conversationId: 11055,
							disconImpact: 0.06436958,
							featureValue: 0,
						},
						{
							conversationId: 11057,
							disconImpact: 0.033573166,
							featureValue: 0,
						},
						{
							conversationId: 11061,
							disconImpact: -0.2792698,
							featureValue: 0.0585,
						},
						{
							conversationId: 11066,
							disconImpact: -0.09223787,
							featureValue: 0,
						},
						{
							conversationId: 11071,
							disconImpact: 0.12810875,
							featureValue: 0.06985,
						},
						{
							conversationId: 11073,
							disconImpact: 0.13043743,
							featureValue: 0.0563333333333333,
						},
						{
							conversationId: 11084,
							disconImpact: -0.043281153,
							featureValue: 0,
						},
						{
							conversationId: 11085,
							disconImpact: 0.79434204,
							featureValue: 0.1014999999999999,
						},
						{
							conversationId: 11087,
							disconImpact: -0.1097374,
							featureValue: 0,
						},
						{
							conversationId: 11088,
							disconImpact: -0.05621046,
							featureValue: 0,
						},
						{
							conversationId: 11090,
							disconImpact: -1.4299612,
							featureValue: 0.1185,
						},
						{
							conversationId: 11094,
							disconImpact: -1.1811846,
							featureValue: 0.119,
						},
						{
							conversationId: 11098,
							disconImpact: -0.3847788,
							featureValue: 0.056,
						},
						{
							conversationId: 11099,
							disconImpact: -0.16300008,
							featureValue: 0.0565,
						},
						{
							conversationId: 11104,
							disconImpact: 0.030550838,
							featureValue: 0.053,
						},
						{
							conversationId: 11105,
							disconImpact: 0.047764,
							featureValue: 0,
						},
						{
							conversationId: 11106,
							disconImpact: -0.15310918,
							featureValue: 0,
						},
						{
							conversationId: 11111,
							disconImpact: -0.7516497,
							featureValue: 0.121875,
						},
						{
							conversationId: 11125,
							disconImpact: -0.10169142,
							featureValue: 0,
						},
						{
							conversationId: 11130,
							disconImpact: 0.06508049,
							featureValue: 0.068,
						},
						{
							conversationId: 11137,
							disconImpact: 0.16785736,
							featureValue: 0.05,
						},
						{
							conversationId: 11139,
							disconImpact: 0.08760919,
							featureValue: 0.106625,
						},
						{
							conversationId: 11140,
							disconImpact: -1.6210743,
							featureValue: 0.163,
						},
						{
							conversationId: 11142,
							disconImpact: -0.09179055,
							featureValue: 0,
						},
						{
							conversationId: 11143,
							disconImpact: -0.7532602,
							featureValue: 0.329,
						},
						{
							conversationId: 11144,
							disconImpact: -0.15374951,
							featureValue: 0,
						},
						{
							conversationId: 11152,
							disconImpact: -0.20905043,
							featureValue: 0.0696666666666666,
						},
						{
							conversationId: 11159,
							disconImpact: -0.25991908,
							featureValue: 0,
						},
						{
							conversationId: 11163,
							disconImpact: -0.08676142,
							featureValue: 0,
						},
						{
							conversationId: 11173,
							disconImpact: -0.12143061,
							featureValue: 0,
						},
						{
							conversationId: 11176,
							disconImpact: -0.054527156,
							featureValue: 0,
						},
						{
							conversationId: 11177,
							disconImpact: -0.13089386,
							featureValue: 0,
						},
						{
							conversationId: 11179,
							disconImpact: 0.75131184,
							featureValue: 0.049,
						},
						{
							conversationId: 11185,
							disconImpact: -0.29355854,
							featureValue: 0.0583333333333333,
						},
						{
							conversationId: 11187,
							disconImpact: -0.10360483,
							featureValue: 0,
						},
						{
							conversationId: 11196,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 11197,
							disconImpact: -0.04917833,
							featureValue: 0,
						},
						{
							conversationId: 11198,
							disconImpact: -0.12500612,
							featureValue: 0.0645,
						},
						{
							conversationId: 11206,
							disconImpact: -0.1522851,
							featureValue: 0,
						},
						{
							conversationId: 11207,
							disconImpact: -0.07297217,
							featureValue: 0.0686666666666666,
						},
						{
							conversationId: 11208,
							disconImpact: 0.296922,
							featureValue: 0.101,
						},
						{
							conversationId: 11213,
							disconImpact: -0.15963143,
							featureValue: 0.0540833333333333,
						},
						{
							conversationId: 11214,
							disconImpact: 0.344055,
							featureValue: 0.045,
						},
						{
							conversationId: 11218,
							disconImpact: -1.1396216,
							featureValue: 0.3766666666666666,
						},
						{
							conversationId: 11219,
							disconImpact: -0.08855321,
							featureValue: 0,
						},
						{
							conversationId: 11223,
							disconImpact: -0.14204931,
							featureValue: 0,
						},
						{
							conversationId: 11225,
							disconImpact: 0.09553786,
							featureValue: 0.0634444444444444,
						},
						{
							conversationId: 11238,
							disconImpact: -0.35578918,
							featureValue: 0.061,
						},
						{
							conversationId: 11239,
							disconImpact: 0.062217794,
							featureValue: 0.0405,
						},
						{
							conversationId: 11246,
							disconImpact: -0.20881723,
							featureValue: 0,
						},
						{
							conversationId: 11253,
							disconImpact: -0.45055982,
							featureValue: 0.097,
						},
						{
							conversationId: 11256,
							disconImpact: -0.09929187,
							featureValue: 0.04475,
						},
						{
							conversationId: 11258,
							disconImpact: -0.055628084,
							featureValue: 0,
						},
						{
							conversationId: 11259,
							disconImpact: 0.086045824,
							featureValue: 0,
						},
						{
							conversationId: 11264,
							disconImpact: -0.1836718,
							featureValue: 0.0745,
						},
						{
							conversationId: 11265,
							disconImpact: -0.043402597,
							featureValue: 0,
						},
						{
							conversationId: 11268,
							disconImpact: -0.616924,
							featureValue: 0.371,
						},
						{
							conversationId: 11269,
							disconImpact: -0.014790168,
							featureValue: 0.0582499999999999,
						},
						{
							conversationId: 11270,
							disconImpact: 0.11895208,
							featureValue: 0.091,
						},
						{
							conversationId: 11283,
							disconImpact: -0.06718816,
							featureValue: 0,
						},
						{
							conversationId: 11286,
							disconImpact: 0.012432411,
							featureValue: 0.0965,
						},
						{
							conversationId: 11287,
							disconImpact: -0.17880183,
							featureValue: 0.0546666666666666,
						},
						{
							conversationId: 11299,
							disconImpact: -0.23622613,
							featureValue: 0,
						},
						{
							conversationId: 11303,
							disconImpact: -0.34476888,
							featureValue: 0.02,
						},
						{
							conversationId: 11304,
							disconImpact: -0.6938562,
							featureValue: 0.08025,
						},
						{
							conversationId: 11306,
							disconImpact: 0.07786177,
							featureValue: 0.065,
						},
						{
							conversationId: 11309,
							disconImpact: -0.22561982,
							featureValue: 0.057,
						},
						{
							conversationId: 11316,
							disconImpact: 0.41691807,
							featureValue: 0.047,
						},
						{
							conversationId: 11322,
							disconImpact: 0.0009412607,
							featureValue: 0,
						},
						{
							conversationId: 11326,
							disconImpact: -0.29198653,
							featureValue: 0.0584999999999999,
						},
						{
							conversationId: 11328,
							disconImpact: -0.02693019,
							featureValue: 0.0586,
						},
						{
							conversationId: 11332,
							disconImpact: 0.51036984,
							featureValue: 0.04625,
						},
						{
							conversationId: 11338,
							disconImpact: 0.13136587,
							featureValue: 0.045,
						},
						{
							conversationId: 11347,
							disconImpact: 0.52078575,
							featureValue: 0.0465,
						},
						{
							conversationId: 11348,
							disconImpact: -0.48700348,
							featureValue: 0.059,
						},
						{
							conversationId: 11349,
							disconImpact: 0.29618478,
							featureValue: 0.067,
						},
						{
							conversationId: 11352,
							disconImpact: -0.25905567,
							featureValue: 0,
						},
						{
							conversationId: 11357,
							disconImpact: -0.07639252,
							featureValue: 0,
						},
						{
							conversationId: 11361,
							disconImpact: -0.17835838,
							featureValue: 0,
						},
						{
							conversationId: 11366,
							disconImpact: 0.19991028,
							featureValue: 0.0635,
						},
						{
							conversationId: 11370,
							disconImpact: -0.12574641,
							featureValue: 0,
						},
						{
							conversationId: 11371,
							disconImpact: -0.03389784,
							featureValue: 0.068,
						},
						{
							conversationId: 11373,
							disconImpact: -0.24682054,
							featureValue: 0.06075,
						},
						{
							conversationId: 11377,
							disconImpact: -1.249628,
							featureValue: 0.123,
						},
						{
							conversationId: 11378,
							disconImpact: -0.016412485,
							featureValue: 0,
						},
						{
							conversationId: 11383,
							disconImpact: -0.046116065,
							featureValue: 0,
						},
						{
							conversationId: 11384,
							disconImpact: -0.35095578,
							featureValue: 0.0535,
						},
						{
							conversationId: 11386,
							disconImpact: -0.07566905,
							featureValue: 0,
						},
						{
							conversationId: 11389,
							disconImpact: -0.22788496,
							featureValue: 0.0907142857142857,
						},
						{
							conversationId: 11393,
							disconImpact: -0.9921401,
							featureValue: 0.121,
						},
						{
							conversationId: 11396,
							disconImpact: 0.18744548,
							featureValue: 0.036,
						},
						{
							conversationId: 11398,
							disconImpact: -0.270646,
							featureValue: 0.08825,
						},
						{
							conversationId: 11399,
							disconImpact: -0.007895365,
							featureValue: 0.0565,
						},
						{
							conversationId: 11403,
							disconImpact: -0.53861386,
							featureValue: 0.12325,
						},
						{
							conversationId: 11404,
							disconImpact: 0.16428055,
							featureValue: 0.0483333333333333,
						},
						{
							conversationId: 11408,
							disconImpact: -0.36652344,
							featureValue: 0.088,
						},
						{
							conversationId: 11413,
							disconImpact: -0.04678305,
							featureValue: 0.1075,
						},
						{
							conversationId: 11416,
							disconImpact: 0.64509505,
							featureValue: 0.046,
						},
						{
							conversationId: 11419,
							disconImpact: -0.03035128,
							featureValue: 0,
						},
						{
							conversationId: 11422,
							disconImpact: 0.2132436,
							featureValue: 0.054,
						},
						{
							conversationId: 11423,
							disconImpact: -0.103306845,
							featureValue: 0.062,
						},
						{
							conversationId: 11427,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 11428,
							disconImpact: 0.110009134,
							featureValue: 0.078,
						},
						{
							conversationId: 11429,
							disconImpact: -0.15245283,
							featureValue: 0.0635,
						},
						{
							conversationId: 11431,
							disconImpact: -0.040336527,
							featureValue: 0.0766666666666666,
						},
						{
							conversationId: 11434,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 11437,
							disconImpact: -0.30922416,
							featureValue: 0,
						},
						{
							conversationId: 11439,
							disconImpact: -0.28663415,
							featureValue: 0,
						},
						{
							conversationId: 11442,
							disconImpact: -0.38714677,
							featureValue: 0.055625,
						},
						{
							conversationId: 11443,
							disconImpact: -0.066578135,
							featureValue: 0.06375,
						},
						{
							conversationId: 11450,
							disconImpact: -0.2627009,
							featureValue: 0.0556666666666666,
						},
						{
							conversationId: 11451,
							disconImpact: 0.07277365,
							featureValue: 0.0554999999999999,
						},
						{
							conversationId: 11453,
							disconImpact: -0.030284738,
							featureValue: 0.075,
						},
						{
							conversationId: 11454,
							disconImpact: -0.5199334,
							featureValue: 0.0579999999999999,
						},
						{
							conversationId: 11457,
							disconImpact: -0.11670031,
							featureValue: 0,
						},
						{
							conversationId: 11458,
							disconImpact: 0.31995404,
							featureValue: 0.0485,
						},
						{
							conversationId: 11465,
							disconImpact: 0.1745407,
							featureValue: 0.0414999999999999,
						},
						{
							conversationId: 11467,
							disconImpact: 0.21548961,
							featureValue: 0.041,
						},
						{
							conversationId: 11470,
							disconImpact: -0.03539889,
							featureValue: 0,
						},
						{
							conversationId: 11476,
							disconImpact: 0.11791746,
							featureValue: 0.064,
						},
						{
							conversationId: 11479,
							disconImpact: -0.004026346,
							featureValue: 0.112,
						},
						{
							conversationId: 11486,
							disconImpact: -0.10063039,
							featureValue: 0.0671666666666666,
						},
						{
							conversationId: 11488,
							disconImpact: 0.48075634,
							featureValue: 0.048,
						},
						{
							conversationId: 11489,
							disconImpact: -0.1318219,
							featureValue: 0.0777222222222222,
						},
						{
							conversationId: 11494,
							disconImpact: 0.10566848,
							featureValue: 0.0699999999999999,
						},
						{
							conversationId: 11495,
							disconImpact: 0.7543999,
							featureValue: 0.048,
						},
						{
							conversationId: 11496,
							disconImpact: -0.094801694,
							featureValue: 0.0579999999999999,
						},
						{
							conversationId: 11500,
							disconImpact: -0.2414782,
							featureValue: 0,
						},
						{
							conversationId: 11501,
							disconImpact: -0.07035851,
							featureValue: 0.051,
						},
						{
							conversationId: 11502,
							disconImpact: -0.051851887,
							featureValue: 0,
						},
						{
							conversationId: 11504,
							disconImpact: -0.9401625,
							featureValue: 0.1889999999999999,
						},
						{
							conversationId: 11508,
							disconImpact: 0.12454286,
							featureValue: 0.0562499999999999,
						},
						{
							conversationId: 11510,
							disconImpact: -0.113634855,
							featureValue: 0.051,
						},
						{
							conversationId: 11511,
							disconImpact: 0.07728975,
							featureValue: 0.07125,
						},
						{
							conversationId: 11516,
							disconImpact: -0.012272611,
							featureValue: 0.07475,
						},
						{
							conversationId: 11520,
							disconImpact: -0.053149536,
							featureValue: 0,
						},
						{
							conversationId: 11526,
							disconImpact: -0.3385765,
							featureValue: 0.544,
						},
						{
							conversationId: 11531,
							disconImpact: -0.14835054,
							featureValue: 0.0510416666666666,
						},
						{
							conversationId: 11532,
							disconImpact: 0.13555643,
							featureValue: 0,
						},
						{
							conversationId: 11536,
							disconImpact: 0.1830406,
							featureValue: 0.0667222222222222,
						},
						{
							conversationId: 11538,
							disconImpact: -0.03289925,
							featureValue: 0.073,
						},
						{
							conversationId: 11540,
							disconImpact: -0.031511515,
							featureValue: 0.0635,
						},
						{
							conversationId: 11541,
							disconImpact: 0.63763916,
							featureValue: 0.047,
						},
						{
							conversationId: 11549,
							disconImpact: -0.7941281,
							featureValue: 0.121,
						},
						{
							conversationId: 11551,
							disconImpact: -0.11278882,
							featureValue: 0.057,
						},
						{
							conversationId: 11558,
							disconImpact: 0.52906996,
							featureValue: 0.049,
						},
						{
							conversationId: 11572,
							disconImpact: -0.16264519,
							featureValue: 0,
						},
						{
							conversationId: 11581,
							disconImpact: -0.51854354,
							featureValue: 0.06425,
						},
						{
							conversationId: 11584,
							disconImpact: -0.8300472,
							featureValue: 0.183,
						},
						{
							conversationId: 11588,
							disconImpact: 0.29191172,
							featureValue: 0.0636666666666666,
						},
						{
							conversationId: 11589,
							disconImpact: 0.5712434,
							featureValue: 0.0488888888888888,
						},
						{
							conversationId: 11590,
							disconImpact: -0.51647586,
							featureValue: 0.125,
						},
						{
							conversationId: 11591,
							disconImpact: -0.3219712,
							featureValue: 0.061,
						},
						{
							conversationId: 11597,
							disconImpact: -0.4929638,
							featureValue: 0.061,
						},
						{
							conversationId: 11599,
							disconImpact: 0.23893805,
							featureValue: 0.06475,
						},
						{
							conversationId: 11601,
							disconImpact: 0.09938903,
							featureValue: 0.053,
						},
						{
							conversationId: 11604,
							disconImpact: -0.11449688,
							featureValue: 0.032,
						},
						{
							conversationId: 11609,
							disconImpact: 0.43931168,
							featureValue: 0.0495,
						},
						{
							conversationId: 11613,
							disconImpact: -0.12785776,
							featureValue: 0,
						},
						{
							conversationId: 11614,
							disconImpact: 0.38800523,
							featureValue: 0.067,
						},
						{
							conversationId: 11616,
							disconImpact: -0.29846624,
							featureValue: 0,
						},
						{
							conversationId: 11618,
							disconImpact: -0.2918544,
							featureValue: 0,
						},
						{
							conversationId: 11630,
							disconImpact: 0.034775697,
							featureValue: 0.0584999999999999,
						},
						{
							conversationId: 11631,
							disconImpact: -0.3041854,
							featureValue: 0.0783333333333333,
						},
						{
							conversationId: 11632,
							disconImpact: -0.24147591,
							featureValue: 0,
						},
						{
							conversationId: 11636,
							disconImpact: 0.08508856,
							featureValue: 0.105,
						},
						{
							conversationId: 11637,
							disconImpact: 0.21123679,
							featureValue: 0.0725,
						},
						{
							conversationId: 11639,
							disconImpact: 0.14274994,
							featureValue: 0,
						},
						{
							conversationId: 11642,
							disconImpact: -0.10944266,
							featureValue: 0,
						},
						{
							conversationId: 11643,
							disconImpact: 0.23477086,
							featureValue: 0.074,
						},
						{
							conversationId: 11645,
							disconImpact: -0.33778906,
							featureValue: 0.0956666666666666,
						},
						{
							conversationId: 11648,
							disconImpact: -0.1620582,
							featureValue: 0,
						},
						{
							conversationId: 11651,
							disconImpact: -0.012763087,
							featureValue: 0,
						},
						{
							conversationId: 11656,
							disconImpact: 0.18638998,
							featureValue: 0.044,
						},
						{
							conversationId: 11657,
							disconImpact: 0.033609662,
							featureValue: 0.0393333333333333,
						},
						{
							conversationId: 11660,
							disconImpact: -0.25907052,
							featureValue: 0.1125555555555555,
						},
						{
							conversationId: 11661,
							disconImpact: -0.16576895,
							featureValue: 0,
						},
						{
							conversationId: 11663,
							disconImpact: -0.042994387,
							featureValue: 0.078,
						},
						{
							conversationId: 11666,
							disconImpact: -0.045096412,
							featureValue: 0.072,
						},
						{
							conversationId: 11674,
							disconImpact: -0.14029914,
							featureValue: 0,
						},
						{
							conversationId: 11675,
							disconImpact: -0.17406902,
							featureValue: 0.0740357142857142,
						},
						{
							conversationId: 11677,
							disconImpact: -0.40882906,
							featureValue: 0.329,
						},
						{
							conversationId: 11679,
							disconImpact: -0.11663767,
							featureValue: 0,
						},
						{
							conversationId: 11681,
							disconImpact: -0.29607102,
							featureValue: 0.076,
						},
						{
							conversationId: 11682,
							disconImpact: 0.19747645,
							featureValue: 0.07,
						},
						{
							conversationId: 11686,
							disconImpact: -0.12732226,
							featureValue: 0,
						},
						{
							conversationId: 11688,
							disconImpact: 0.006135151,
							featureValue: 0,
						},
						{
							conversationId: 11695,
							disconImpact: -0.1758874,
							featureValue: 0.052,
						},
						{
							conversationId: 11697,
							disconImpact: -0.0020574033,
							featureValue: 0,
						},
						{
							conversationId: 11699,
							disconImpact: -0.69754493,
							featureValue: 0.1245,
						},
						{
							conversationId: 11702,
							disconImpact: -0.07301579,
							featureValue: 0.074,
						},
						{
							conversationId: 11707,
							disconImpact: -0.13397864,
							featureValue: 0.0528,
						},
						{
							conversationId: 11715,
							disconImpact: -0.15076622,
							featureValue: 0.0697777777777777,
						},
						{
							conversationId: 11719,
							disconImpact: -0.0024199504,
							featureValue: 0.041,
						},
						{
							conversationId: 11722,
							disconImpact: 0.1927078,
							featureValue: 0.073,
						},
						{
							conversationId: 11723,
							disconImpact: -0.19085376,
							featureValue: 0.067,
						},
						{
							conversationId: 11726,
							disconImpact: -0.046837367,
							featureValue: 0,
						},
						{
							conversationId: 11730,
							disconImpact: -0.20578434,
							featureValue: 0.021,
						},
						{
							conversationId: 11732,
							disconImpact: -0.26723722,
							featureValue: 0,
						},
						{
							conversationId: 11734,
							disconImpact: 0.3315455,
							featureValue: 0.04875,
						},
						{
							conversationId: 11736,
							disconImpact: 0.0645918,
							featureValue: 0.0632222222222222,
						},
						{
							conversationId: 11740,
							disconImpact: -0.06928135,
							featureValue: 0.08925,
						},
						{
							conversationId: 11741,
							disconImpact: 0.08537247,
							featureValue: 0.06,
						},
						{
							conversationId: 11748,
							disconImpact: 0.13624562,
							featureValue: 0.07,
						},
						{
							conversationId: 11754,
							disconImpact: -0.23899147,
							featureValue: 0,
						},
						{
							conversationId: 11755,
							disconImpact: -0.090288766,
							featureValue: 0,
						},
						{
							conversationId: 11757,
							disconImpact: -0.016412485,
							featureValue: 0,
						},
						{
							conversationId: 11761,
							disconImpact: -0.13894367,
							featureValue: 0,
						},
						{
							conversationId: 11762,
							disconImpact: 0.04288956,
							featureValue: 0.05,
						},
						{
							conversationId: 11765,
							disconImpact: 0.07494241,
							featureValue: 0.068,
						},
						{
							conversationId: 11768,
							disconImpact: 0.001454901,
							featureValue: 0,
						},
						{
							conversationId: 11769,
							disconImpact: 0.7763139,
							featureValue: 0.0463333333333333,
						},
						{
							conversationId: 11772,
							disconImpact: 0.0026585096,
							featureValue: 0.0565,
						},
						{
							conversationId: 11773,
							disconImpact: -0.18631005,
							featureValue: 0,
						},
						{
							conversationId: 11776,
							disconImpact: -0.23748508,
							featureValue: 0.082,
						},
						{
							conversationId: 11777,
							disconImpact: -0.10860511,
							featureValue: 0,
						},
						{
							conversationId: 11779,
							disconImpact: 0.0011666529,
							featureValue: 0.07325,
						},
						{
							conversationId: 11780,
							disconImpact: -0.52763784,
							featureValue: 0.08,
						},
						{
							conversationId: 11781,
							disconImpact: -0.19189051,
							featureValue: 0,
						},
						{
							conversationId: 11783,
							disconImpact: -0.44508052,
							featureValue: 0.0833333333333333,
						},
						{
							conversationId: 11785,
							disconImpact: -0.19257595,
							featureValue: 0.041,
						},
						{
							conversationId: 11787,
							disconImpact: 0.0057067797,
							featureValue: 0.068,
						},
						{
							conversationId: 11789,
							disconImpact: -0.58186406,
							featureValue: 0.0834166666666666,
						},
						{
							conversationId: 11790,
							disconImpact: 0.12318025,
							featureValue: 0.074,
						},
						{
							conversationId: 11796,
							disconImpact: -0.012763087,
							featureValue: 0,
						},
						{
							conversationId: 11799,
							disconImpact: 0.9310414,
							featureValue: 0.0949999999999999,
						},
						{
							conversationId: 11805,
							disconImpact: -0.89867485,
							featureValue: 0.0805,
						},
						{
							conversationId: 11806,
							disconImpact: 0.12788574,
							featureValue: 0.0443333333333333,
						},
						{
							conversationId: 11809,
							disconImpact: -0.044237524,
							featureValue: 0.0696666666666666,
						},
						{
							conversationId: 11820,
							disconImpact: -0.38834688,
							featureValue: 0.083,
						},
						{
							conversationId: 11823,
							disconImpact: -0.90841097,
							featureValue: 0.0846597222222222,
						},
						{
							conversationId: 11825,
							disconImpact: -0.095792904,
							featureValue: 0.064,
						},
						{
							conversationId: 11826,
							disconImpact: -0.012763087,
							featureValue: 0,
						},
						{
							conversationId: 11830,
							disconImpact: 0.43681943,
							featureValue: 0.073,
						},
						{
							conversationId: 11831,
							disconImpact: -0.16153361,
							featureValue: 0.062,
						},
						{
							conversationId: 11834,
							disconImpact: 0.45721027,
							featureValue: 0.049,
						},
						{
							conversationId: 11835,
							disconImpact: -0.11222367,
							featureValue: 0.036,
						},
						{
							conversationId: 11838,
							disconImpact: -0.10671482,
							featureValue: 0,
						},
						{
							conversationId: 11842,
							disconImpact: -0.70329654,
							featureValue: 0.0804999999999999,
						},
						{
							conversationId: 11851,
							disconImpact: 0.11200279,
							featureValue: 0.0623333333333333,
						},
						{
							conversationId: 11852,
							disconImpact: -0.13652343,
							featureValue: 0,
						},
						{
							conversationId: 11857,
							disconImpact: 0.068420164,
							featureValue: 0.102,
						},
						{
							conversationId: 11858,
							disconImpact: 0.28917253,
							featureValue: 0.049,
						},
						{
							conversationId: 11865,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 11868,
							disconImpact: -0.3377253,
							featureValue: 0.06,
						},
						{
							conversationId: 11871,
							disconImpact: -0.20461088,
							featureValue: 0,
						},
						{
							conversationId: 11874,
							disconImpact: -0.8285125,
							featureValue: 0.1385,
						},
						{
							conversationId: 11879,
							disconImpact: -1.3446991,
							featureValue: 0.1563333333333333,
						},
						{
							conversationId: 11881,
							disconImpact: 0.046976928,
							featureValue: 0.0701666666666666,
						},
						{
							conversationId: 11883,
							disconImpact: -0.29246575,
							featureValue: 0.0596666666666666,
						},
						{
							conversationId: 11884,
							disconImpact: -0.0144706555,
							featureValue: 0.068,
						},
						{
							conversationId: 11885,
							disconImpact: -0.039561793,
							featureValue: 0,
						},
						{
							conversationId: 11889,
							disconImpact: 0.18607976,
							featureValue: 0.0647166666666666,
						},
						{
							conversationId: 11890,
							disconImpact: -0.6286054,
							featureValue: 0.055,
						},
						{
							conversationId: 11892,
							disconImpact: -0.2513908,
							featureValue: 0.076,
						},
						{
							conversationId: 11897,
							disconImpact: 0.13872832,
							featureValue: 0.042,
						},
						{
							conversationId: 11898,
							disconImpact: -0.9265866,
							featureValue: 0.08,
						},
						{
							conversationId: 11903,
							disconImpact: -0.26007187,
							featureValue: 0,
						},
						{
							conversationId: 11906,
							disconImpact: -1.2640411,
							featureValue: 0.16975,
						},
						{
							conversationId: 11909,
							disconImpact: -0.20649604,
							featureValue: 0,
						},
						{
							conversationId: 11910,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 11912,
							disconImpact: -0.1069043,
							featureValue: 0.0526666666666666,
						},
						{
							conversationId: 11915,
							disconImpact: -0.096625686,
							featureValue: 0,
						},
						{
							conversationId: 11921,
							disconImpact: -0.54164517,
							featureValue: 0.0868333333333333,
						},
						{
							conversationId: 11923,
							disconImpact: -0.090211965,
							featureValue: 0,
						},
						{
							conversationId: 11928,
							disconImpact: -0.37742093,
							featureValue: 0.0908571428571428,
						},
						{
							conversationId: 11929,
							disconImpact: -0.31463718,
							featureValue: 0,
						},
						{
							conversationId: 11933,
							disconImpact: -0.097551584,
							featureValue: 0,
						},
						{
							conversationId: 11936,
							disconImpact: -0.25765195,
							featureValue: 0.05,
						},
						{
							conversationId: 11940,
							disconImpact: -0.060105022,
							featureValue: 0,
						},
						{
							conversationId: 11941,
							disconImpact: -0.27599898,
							featureValue: 0,
						},
						{
							conversationId: 11944,
							disconImpact: -0.054292887,
							featureValue: 0,
						},
						{
							conversationId: 11946,
							disconImpact: -0.1614291,
							featureValue: 0,
						},
						{
							conversationId: 11949,
							disconImpact: 0.096941255,
							featureValue: 0.0625,
						},
						{
							conversationId: 11953,
							disconImpact: -0.2691347,
							featureValue: 0,
						},
						{
							conversationId: 11955,
							disconImpact: -0.63532656,
							featureValue: 0.061,
						},
						{
							conversationId: 11957,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 11958,
							disconImpact: 0.17014056,
							featureValue: 0.06225,
						},
						{
							conversationId: 11963,
							disconImpact: -0.11019809,
							featureValue: 0,
						},
						{
							conversationId: 11971,
							disconImpact: 0.59098876,
							featureValue: 0.06775,
						},
						{
							conversationId: 11973,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 11977,
							disconImpact: -0.10099877,
							featureValue: 0.075,
						},
						{
							conversationId: 11978,
							disconImpact: -0.060105022,
							featureValue: 0,
						},
						{
							conversationId: 11979,
							disconImpact: -0.21762912,
							featureValue: 0,
						},
						{
							conversationId: 11981,
							disconImpact: -0.057588987,
							featureValue: 0.07125,
						},
						{
							conversationId: 11985,
							disconImpact: -0.2776997,
							featureValue: 0,
						},
						{
							conversationId: 11987,
							disconImpact: 0.07398989,
							featureValue: 0,
						},
						{
							conversationId: 11994,
							disconImpact: -0.3060775,
							featureValue: 0.052,
						},
						{
							conversationId: 11996,
							disconImpact: 0.11972509,
							featureValue: 0,
						},
						{
							conversationId: 11999,
							disconImpact: -0.14037946,
							featureValue: 0,
						},
						{
							conversationId: 12000,
							disconImpact: -0.16631484,
							featureValue: 0,
						},
						{
							conversationId: 12006,
							disconImpact: 0.096272,
							featureValue: 0.063,
						},
						{
							conversationId: 12007,
							disconImpact: 0.26190147,
							featureValue: 0.103375,
						},
						{
							conversationId: 12008,
							disconImpact: -0.08624222,
							featureValue: 0.063,
						},
						{
							conversationId: 12013,
							disconImpact: 0.36661407,
							featureValue: 0.036,
						},
						{
							conversationId: 12020,
							disconImpact: 0.18658768,
							featureValue: 0.0963333333333333,
						},
						{
							conversationId: 12022,
							disconImpact: 0.19201636,
							featureValue: 0.032,
						},
						{
							conversationId: 12024,
							disconImpact: -0.071148045,
							featureValue: 0.0766666666666666,
						},
						{
							conversationId: 12028,
							disconImpact: -0.053149536,
							featureValue: 0,
						},
						{
							conversationId: 12030,
							disconImpact: -0.30502895,
							featureValue: 0,
						},
						{
							conversationId: 12032,
							disconImpact: -0.50245076,
							featureValue: 0.283,
						},
						{
							conversationId: 12038,
							disconImpact: 0.14499858,
							featureValue: 0.115,
						},
						{
							conversationId: 12040,
							disconImpact: -0.23113444,
							featureValue: 0,
						},
						{
							conversationId: 12044,
							disconImpact: 0.03322763,
							featureValue: 0.07325,
						},
						{
							conversationId: 12047,
							disconImpact: -0.25154737,
							featureValue: 0.06675,
						},
						{
							conversationId: 12049,
							disconImpact: -0.8669469,
							featureValue: 0.13925,
						},
						{
							conversationId: 12050,
							disconImpact: 0.9265997,
							featureValue: 0.05,
						},
						{
							conversationId: 12051,
							disconImpact: -0.13656013,
							featureValue: 0.054,
						},
						{
							conversationId: 12054,
							disconImpact: -0.29236367,
							featureValue: 0.057,
						},
						{
							conversationId: 12058,
							disconImpact: 0.11857157,
							featureValue: 0.072,
						},
						{
							conversationId: 12060,
							disconImpact: 0.2765408,
							featureValue: 0.03825,
						},
						{
							conversationId: 12061,
							disconImpact: 0.073926955,
							featureValue: 0.078,
						},
						{
							conversationId: 12064,
							disconImpact: 0.39690024,
							featureValue: 0.103,
						},
						{
							conversationId: 12066,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 12077,
							disconImpact: 0.58596665,
							featureValue: 0.04825,
						},
						{
							conversationId: 12085,
							disconImpact: -0.10381741,
							featureValue: 0,
						},
						{
							conversationId: 12086,
							disconImpact: -0.18821904,
							featureValue: 0.072,
						},
						{
							conversationId: 12088,
							disconImpact: -0.14395279,
							featureValue: 0,
						},
						{
							conversationId: 12092,
							disconImpact: 0.011714743,
							featureValue: 0,
						},
						{
							conversationId: 12094,
							disconImpact: 0.1547092,
							featureValue: 0.054,
						},
						{
							conversationId: 12095,
							disconImpact: -0.39682382,
							featureValue: 0.1183333333333333,
						},
						{
							conversationId: 12098,
							disconImpact: -0.043281153,
							featureValue: 0,
						},
						{
							conversationId: 12100,
							disconImpact: -0.01767712,
							featureValue: 0.06495,
						},
						{
							conversationId: 12101,
							disconImpact: -0.2470915,
							featureValue: 0,
						},
						{
							conversationId: 12102,
							disconImpact: 0.27779976,
							featureValue: 0.042,
						},
						{
							conversationId: 12103,
							disconImpact: -1.2596073,
							featureValue: 0.232,
						},
						{
							conversationId: 12116,
							disconImpact: -0.22606461,
							featureValue: 0,
						},
						{
							conversationId: 12120,
							disconImpact: -0.2340535,
							featureValue: 0.063,
						},
						{
							conversationId: 12122,
							disconImpact: -0.50095695,
							featureValue: 0.149,
						},
						{
							conversationId: 12132,
							disconImpact: 0.40211108,
							featureValue: 0.045,
						},
						{
							conversationId: 12138,
							disconImpact: -0.91497594,
							featureValue: 0.1493333333333333,
						},
						{
							conversationId: 12139,
							disconImpact: -1.0329416,
							featureValue: 0.1225,
						},
						{
							conversationId: 12142,
							disconImpact: 0.64545214,
							featureValue: 0.048,
						},
						{
							conversationId: 12147,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 12148,
							disconImpact: -1.1297156,
							featureValue: 0.18125,
						},
						{
							conversationId: 12149,
							disconImpact: -0.13500983,
							featureValue: 0,
						},
						{
							conversationId: 12153,
							disconImpact: -0.13166422,
							featureValue: 0.076,
						},
						{
							conversationId: 12160,
							disconImpact: -0.10871668,
							featureValue: 0,
						},
						{
							conversationId: 12167,
							disconImpact: 0.08151963,
							featureValue: 0.032,
						},
						{
							conversationId: 12170,
							disconImpact: -1.7821975,
							featureValue: 0.153,
						},
						{
							conversationId: 12171,
							disconImpact: -0.1031655,
							featureValue: 0.06175,
						},
						{
							conversationId: 12172,
							disconImpact: -0.42795822,
							featureValue: 0,
						},
						{
							conversationId: 12174,
							disconImpact: -0.22314817,
							featureValue: 0.0545,
						},
						{
							conversationId: 12189,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 12190,
							disconImpact: 0.17780474,
							featureValue: 0.072,
						},
						{
							conversationId: 12191,
							disconImpact: -0.14325531,
							featureValue: 0.059,
						},
						{
							conversationId: 12193,
							disconImpact: -0.043665472,
							featureValue: 0.074,
						},
						{
							conversationId: 12195,
							disconImpact: 0.06320975,
							featureValue: 0,
						},
						{
							conversationId: 12196,
							disconImpact: -1.4570256,
							featureValue: 0.1843333333333333,
						},
						{
							conversationId: 12198,
							disconImpact: -0.09364714,
							featureValue: 0,
						},
						{
							conversationId: 12203,
							disconImpact: -0.5875376,
							featureValue: 0.0826666666666666,
						},
						{
							conversationId: 12205,
							disconImpact: -0.106712006,
							featureValue: 0,
						},
						{
							conversationId: 12207,
							disconImpact: 0.23883308,
							featureValue: 0.067,
						},
						{
							conversationId: 12214,
							disconImpact: -0.1287471,
							featureValue: 0,
						},
						{
							conversationId: 12215,
							disconImpact: 0.27166972,
							featureValue: 0.103,
						},
						{
							conversationId: 12217,
							disconImpact: -0.10765327,
							featureValue: 0,
						},
						{
							conversationId: 12226,
							disconImpact: -1.3458358,
							featureValue: 0.193,
						},
						{
							conversationId: 12231,
							disconImpact: -0.011500422,
							featureValue: 0,
						},
						{
							conversationId: 12232,
							disconImpact: -0.10858493,
							featureValue: 0.078,
						},
						{
							conversationId: 12242,
							disconImpact: 0.35077313,
							featureValue: 0.037,
						},
						{
							conversationId: 12243,
							disconImpact: 0.4637999,
							featureValue: 0.0495,
						},
						{
							conversationId: 12249,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 12251,
							disconImpact: 0.14153388,
							featureValue: 0.05975,
						},
						{
							conversationId: 12254,
							disconImpact: 0.0070104226,
							featureValue: 0.0643333333333333,
						},
						{
							conversationId: 12256,
							disconImpact: -0.9077788,
							featureValue: 0.1646666666666666,
						},
						{
							conversationId: 12258,
							disconImpact: 0.04857378,
							featureValue: 0.0424999999999999,
						},
						{
							conversationId: 12260,
							disconImpact: -0.0637826,
							featureValue: 0.091,
						},
						{
							conversationId: 12280,
							disconImpact: -0.09223787,
							featureValue: 0,
						},
						{
							conversationId: 12284,
							disconImpact: -0.24088056,
							featureValue: 0,
						},
						{
							conversationId: 12295,
							disconImpact: 0.3173612,
							featureValue: 0.1134999999999999,
						},
						{
							conversationId: 12310,
							disconImpact: 0.11744827,
							featureValue: 0,
						},
						{
							conversationId: 12316,
							disconImpact: 0.26619512,
							featureValue: 0.0438333333333333,
						},
						{
							conversationId: 12321,
							disconImpact: 0.8090424,
							featureValue: 0.0965,
						},
						{
							conversationId: 12324,
							disconImpact: 0.23546214,
							featureValue: 0.074,
						},
						{
							conversationId: 12329,
							disconImpact: 0.2744885,
							featureValue: 0,
						},
						{
							conversationId: 12331,
							disconImpact: 0.09088116,
							featureValue: 0,
						},
						{
							conversationId: 12334,
							disconImpact: 0.563092,
							featureValue: 0.0971666666666666,
						},
						{
							conversationId: 12336,
							disconImpact: -0.11280141,
							featureValue: 0,
						},
						{
							conversationId: 12345,
							disconImpact: 0.21087761,
							featureValue: 0.095,
						},
						{
							conversationId: 12350,
							disconImpact: -0.39961636,
							featureValue: 0.058375,
						},
						{
							conversationId: 12351,
							disconImpact: -0.14499053,
							featureValue: 0,
						},
						{
							conversationId: 12355,
							disconImpact: 0.25144038,
							featureValue: 0.1077999999999999,
						},
						{
							conversationId: 12360,
							disconImpact: -1.1647156,
							featureValue: 0.1506666666666666,
						},
						{
							conversationId: 12366,
							disconImpact: -0.18589091,
							featureValue: 0,
						},
						{
							conversationId: 12371,
							disconImpact: -0.27293858,
							featureValue: 0,
						},
						{
							conversationId: 12372,
							disconImpact: -0.15846631,
							featureValue: 0,
						},
						{
							conversationId: 12383,
							disconImpact: -0.50775176,
							featureValue: 0.06,
						},
						{
							conversationId: 12386,
							disconImpact: 0.07751001,
							featureValue: 0.068,
						},
						{
							conversationId: 12392,
							disconImpact: -0.61478823,
							featureValue: 0.059,
						},
						{
							conversationId: 12407,
							disconImpact: -1.1438212,
							featureValue: 0.501,
						},
						{
							conversationId: 12423,
							disconImpact: 0.14308164,
							featureValue: 0.0573809523809523,
						},
						{
							conversationId: 12426,
							disconImpact: 0.10954578,
							featureValue: 0,
						},
						{
							conversationId: 12435,
							disconImpact: -0.27785024,
							featureValue: 0.322,
						},
						{
							conversationId: 12442,
							disconImpact: -0.968872,
							featureValue: 0.273,
						},
						{
							conversationId: 12445,
							disconImpact: 0.34828255,
							featureValue: 0.046,
						},
						{
							conversationId: 12447,
							disconImpact: -0.1899628,
							featureValue: 0,
						},
						{
							conversationId: 12450,
							disconImpact: -0.5879431,
							featureValue: 0.314,
						},
						{
							conversationId: 12453,
							disconImpact: -0.01966623,
							featureValue: 0.0414999999999999,
						},
						{
							conversationId: 12455,
							disconImpact: 0.37233433,
							featureValue: 0.101,
						},
						{
							conversationId: 12464,
							disconImpact: 0.24065351,
							featureValue: 0.038,
						},
						{
							conversationId: 12468,
							disconImpact: -0.3442884,
							featureValue: 0,
						},
						{
							conversationId: 12470,
							disconImpact: 0.58585125,
							featureValue: 0.0458333333333333,
						},
						{
							conversationId: 12472,
							disconImpact: -1.031899,
							featureValue: 0.1225833333333333,
						},
						{
							conversationId: 12487,
							disconImpact: 0.504392,
							featureValue: 0.0959999999999999,
						},
						{
							conversationId: 12492,
							disconImpact: -0.07901081,
							featureValue: 0.088,
						},
						{
							conversationId: 12504,
							disconImpact: -0.6040498,
							featureValue: 0.0855,
						},
						{
							conversationId: 12506,
							disconImpact: -0.069775335,
							featureValue: 0,
						},
						{
							conversationId: 12515,
							disconImpact: -0.07710767,
							featureValue: 0,
						},
						{
							conversationId: 12516,
							disconImpact: 0.35670224,
							featureValue: 0.05,
						},
						{
							conversationId: 12518,
							disconImpact: 0.17995587,
							featureValue: 0.0395,
						},
						{
							conversationId: 12520,
							disconImpact: -0.36912587,
							featureValue: 0.061,
						},
						{
							conversationId: 12521,
							disconImpact: -0.40100703,
							featureValue: 0.0549999999999999,
						},
						{
							conversationId: 12526,
							disconImpact: 0.09735502,
							featureValue: 0.074,
						},
						{
							conversationId: 12531,
							disconImpact: 0.2682518,
							featureValue: 0.039,
						},
						{
							conversationId: 12535,
							disconImpact: -0.17828266,
							featureValue: 0,
						},
						{
							conversationId: 12538,
							disconImpact: -0.81937784,
							featureValue: 0.1332,
						},
						{
							conversationId: 12540,
							disconImpact: 0.20226285,
							featureValue: 0.04325,
						},
						{
							conversationId: 12544,
							disconImpact: -0.22557953,
							featureValue: 0,
						},
						{
							conversationId: 12545,
							disconImpact: 0.7207591,
							featureValue: 0.046,
						},
						{
							conversationId: 12551,
							disconImpact: 0.07820465,
							featureValue: 0.0643333333333333,
						},
						{
							conversationId: 12557,
							disconImpact: -0.00068513304,
							featureValue: 0.0555,
						},
						{
							conversationId: 12558,
							disconImpact: -0.08855321,
							featureValue: 0,
						},
						{
							conversationId: 12561,
							disconImpact: 0.15060194,
							featureValue: 0.06225,
						},
						{
							conversationId: 12565,
							disconImpact: -0.16055663,
							featureValue: 0.0911666666666666,
						},
						{
							conversationId: 12582,
							disconImpact: -0.36180472,
							featureValue: 0,
						},
						{
							conversationId: 12583,
							disconImpact: -0.10019638,
							featureValue: 0.051,
						},
						{
							conversationId: 12588,
							disconImpact: 0.15669191,
							featureValue: 0.0515,
						},
						{
							conversationId: 12589,
							disconImpact: -0.8805868,
							featureValue: 0.1343333333333333,
						},
						{
							conversationId: 12598,
							disconImpact: -0.43587065,
							featureValue: 0,
						},
						{
							conversationId: 12608,
							disconImpact: -0.060703307,
							featureValue: 0,
						},
						{
							conversationId: 12613,
							disconImpact: -0.12279813,
							featureValue: 0.0435,
						},
						{
							conversationId: 12616,
							disconImpact: 0.08317499,
							featureValue: 0.079,
						},
						{
							conversationId: 12636,
							disconImpact: -0.17406812,
							featureValue: 0,
						},
						{
							conversationId: 12663,
							disconImpact: -0.12608191,
							featureValue: 0.053,
						},
						{
							conversationId: 12672,
							disconImpact: -0.29761675,
							featureValue: 0,
						},
						{
							conversationId: 12682,
							disconImpact: -0.14373775,
							featureValue: 0.0615,
						},
						{
							conversationId: 12683,
							disconImpact: -0.01048377,
							featureValue: 0,
						},
						{
							conversationId: 12690,
							disconImpact: 0.32217172,
							featureValue: 0.099,
						},
						{
							conversationId: 12694,
							disconImpact: 0.44893268,
							featureValue: 0.048,
						},
						{
							conversationId: 12695,
							disconImpact: 0.047198255,
							featureValue: 0.0635,
						},
						{
							conversationId: 12698,
							disconImpact: -0.02933272,
							featureValue: 0.038,
						},
						{
							conversationId: 12713,
							disconImpact: -0.82060695,
							featureValue: 0.1161666666666666,
						},
						{
							conversationId: 12720,
							disconImpact: -0.16815597,
							featureValue: 0,
						},
						{
							conversationId: 12725,
							disconImpact: -0.13986905,
							featureValue: 0,
						},
						{
							conversationId: 12729,
							disconImpact: 0.60850644,
							featureValue: 0.046,
						},
						{
							conversationId: 12740,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 12741,
							disconImpact: -0.19753122,
							featureValue: 0,
						},
						{
							conversationId: 12742,
							disconImpact: -0.23384303,
							featureValue: 0,
						},
						{
							conversationId: 12755,
							disconImpact: -0.041171458,
							featureValue: 0,
						},
						{
							conversationId: 12768,
							disconImpact: -0.21727514,
							featureValue: 0.077,
						},
						{
							conversationId: 12769,
							disconImpact: 0.23867442,
							featureValue: 0.073,
						},
						{
							conversationId: 12771,
							disconImpact: -0.024962053,
							featureValue: 0,
						},
						{
							conversationId: 12778,
							disconImpact: -0.018297799,
							featureValue: 0,
						},
						{
							conversationId: 12793,
							disconImpact: -0.16016784,
							featureValue: 0.0505,
						},
						{
							conversationId: 12801,
							disconImpact: -0.06169513,
							featureValue: 0.044,
						},
						{
							conversationId: 12811,
							disconImpact: -0.5504268,
							featureValue: 0.125,
						},
						{
							conversationId: 12835,
							disconImpact: -0.04692764,
							featureValue: 0,
						},
						{
							conversationId: 12840,
							disconImpact: 0.29607624,
							featureValue: 0.0655,
						},
						{
							conversationId: 12854,
							disconImpact: 0.05709637,
							featureValue: 0.064,
						},
						{
							conversationId: 12858,
							disconImpact: -0.5330031,
							featureValue: 0.084,
						},
						{
							conversationId: 12860,
							disconImpact: -0.08451494,
							featureValue: 0.053,
						},
						{
							conversationId: 12866,
							disconImpact: -0.04788717,
							featureValue: 0.056,
						},
						{
							conversationId: 12868,
							disconImpact: -0.088480726,
							featureValue: 0,
						},
						{
							conversationId: 12869,
							disconImpact: -0.21068776,
							featureValue: 0.1152499999999999,
						},
						{
							conversationId: 12873,
							disconImpact: -0.08219675,
							featureValue: 0,
						},
						{
							conversationId: 12875,
							disconImpact: 0.119345024,
							featureValue: 0.06575,
						},
						{
							conversationId: 12877,
							disconImpact: -0.17718977,
							featureValue: 0,
						},
						{
							conversationId: 12879,
							disconImpact: 0.60136753,
							featureValue: 0.0986666666666666,
						},
						{
							conversationId: 12882,
							disconImpact: 0.3318805,
							featureValue: 0.0738333333333333,
						},
						{
							conversationId: 12888,
							disconImpact: -0.41195422,
							featureValue: 0.085,
						},
						{
							conversationId: 12899,
							disconImpact: -0.40537438,
							featureValue: 0.0545,
						},
						{
							conversationId: 12900,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 12904,
							disconImpact: 0.45181587,
							featureValue: 0.099,
						},
						{
							conversationId: 12912,
							disconImpact: -0.21854396,
							featureValue: 0,
						},
						{
							conversationId: 12916,
							disconImpact: 0.1757808,
							featureValue: 0.036,
						},
						{
							conversationId: 12920,
							disconImpact: -0.07408423,
							featureValue: 0.06,
						},
						{
							conversationId: 12921,
							disconImpact: 0.0010896116,
							featureValue: 0,
						},
						{
							conversationId: 12923,
							disconImpact: 0.2589483,
							featureValue: 0.0593333333333333,
						},
						{
							conversationId: 12925,
							disconImpact: 0.09773255,
							featureValue: 0,
						},
						{
							conversationId: 12926,
							disconImpact: 0.43693465,
							featureValue: 0.0473333333333333,
						},
						{
							conversationId: 12929,
							disconImpact: 0.6101697,
							featureValue: 0.046,
						},
						{
							conversationId: 12932,
							disconImpact: 0.28952128,
							featureValue: 0.0659444444444444,
						},
						{
							conversationId: 12936,
							disconImpact: -0.07969347,
							featureValue: 0,
						},
						{
							conversationId: 12938,
							disconImpact: -0.6118298,
							featureValue: 0.129,
						},
						{
							conversationId: 12940,
							disconImpact: -0.97895646,
							featureValue: 0.141,
						},
						{
							conversationId: 12941,
							disconImpact: 0.028197438,
							featureValue: 0,
						},
						{
							conversationId: 12945,
							disconImpact: -0.05139354,
							featureValue: 0,
						},
						{
							conversationId: 12948,
							disconImpact: 0.022969896,
							featureValue: 0.068,
						},
						{
							conversationId: 12950,
							disconImpact: -0.032359764,
							featureValue: 0,
						},
						{
							conversationId: 12954,
							disconImpact: -0.23427016,
							featureValue: 0.09,
						},
						{
							conversationId: 12958,
							disconImpact: -0.0805532,
							featureValue: 0,
						},
						{
							conversationId: 12959,
							disconImpact: 0.15359993,
							featureValue: 0.034,
						},
						{
							conversationId: 12962,
							disconImpact: -0.15120378,
							featureValue: 0.0556666666666666,
						},
						{
							conversationId: 12968,
							disconImpact: -0.0044865133,
							featureValue: 0.0715,
						},
						{
							conversationId: 12973,
							disconImpact: 0.4863527,
							featureValue: 0.048,
						},
						{
							conversationId: 12977,
							disconImpact: -0.12084469,
							featureValue: 0,
						},
						{
							conversationId: 12979,
							disconImpact: -0.18096086,
							featureValue: 0,
						},
						{
							conversationId: 12980,
							disconImpact: -0.19271767,
							featureValue: 0,
						},
						{
							conversationId: 12982,
							disconImpact: 0.36208153,
							featureValue: 0.0737857142857142,
						},
						{
							conversationId: 12985,
							disconImpact: -0.11517571,
							featureValue: 0,
						},
						{
							conversationId: 12986,
							disconImpact: 0.0008027381,
							featureValue: 0,
						},
						{
							conversationId: 12989,
							disconImpact: 0.28216678,
							featureValue: 0.034,
						},
						{
							conversationId: 12991,
							disconImpact: -0.2751258,
							featureValue: 0,
						},
						{
							conversationId: 12992,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 12997,
							disconImpact: 0.0037810747,
							featureValue: 0.067,
						},
						{
							conversationId: 12998,
							disconImpact: -0.32653433,
							featureValue: 0.0545,
						},
						{
							conversationId: 13002,
							disconImpact: -0.043281153,
							featureValue: 0,
						},
						{
							conversationId: 13003,
							disconImpact: -0.1820965,
							featureValue: 0.078,
						},
						{
							conversationId: 13004,
							disconImpact: -0.26082307,
							featureValue: 0.054,
						},
						{
							conversationId: 13005,
							disconImpact: 0.003916871,
							featureValue: 0,
						},
						{
							conversationId: 13011,
							disconImpact: -0.103579246,
							featureValue: 0,
						},
						{
							conversationId: 13015,
							disconImpact: -0.14034098,
							featureValue: 0,
						},
						{
							conversationId: 13016,
							disconImpact: -0.28287226,
							featureValue: 0.06,
						},
						{
							conversationId: 13017,
							disconImpact: -0.6057551,
							featureValue: 0.088,
						},
						{
							conversationId: 13020,
							disconImpact: 0.18758851,
							featureValue: 0.061625,
						},
						{
							conversationId: 13022,
							disconImpact: 0.21972139,
							featureValue: 0.0596190476190476,
						},
						{
							conversationId: 13024,
							disconImpact: 0.6708306,
							featureValue: 0.0485,
						},
						{
							conversationId: 13025,
							disconImpact: -0.33500126,
							featureValue: 0.0835,
						},
						{
							conversationId: 13030,
							disconImpact: 0.45837194,
							featureValue: 0.06675,
						},
						{
							conversationId: 13038,
							disconImpact: 0.563485,
							featureValue: 0.049,
						},
						{
							conversationId: 13039,
							disconImpact: 0.42688414,
							featureValue: 0.038,
						},
						{
							conversationId: 13042,
							disconImpact: 0.11136168,
							featureValue: 0.072,
						},
						{
							conversationId: 13046,
							disconImpact: -0.22533132,
							featureValue: 0,
						},
						{
							conversationId: 13047,
							disconImpact: -0.13625273,
							featureValue: 0.0721111111111111,
						},
						{
							conversationId: 13048,
							disconImpact: -0.03641019,
							featureValue: 0,
						},
						{
							conversationId: 13049,
							disconImpact: 0.32719398,
							featureValue: 0.0485,
						},
						{
							conversationId: 13051,
							disconImpact: 0.056742497,
							featureValue: 0.054,
						},
						{
							conversationId: 13053,
							disconImpact: -0.08855321,
							featureValue: 0,
						},
						{
							conversationId: 13057,
							disconImpact: -0.18810847,
							featureValue: 0,
						},
						{
							conversationId: 13063,
							disconImpact: 0.4763363,
							featureValue: 0.0656,
						},
						{
							conversationId: 13066,
							disconImpact: 0.26934373,
							featureValue: 0.102,
						},
						{
							conversationId: 13067,
							disconImpact: -0.2292907,
							featureValue: 0.1086111111111111,
						},
						{
							conversationId: 13071,
							disconImpact: -0.028532617,
							featureValue: 0.06275,
						},
						{
							conversationId: 13074,
							disconImpact: 0.1410681,
							featureValue: 0.068,
						},
						{
							conversationId: 13075,
							disconImpact: 0.32789567,
							featureValue: 0.065,
						},
						{
							conversationId: 13077,
							disconImpact: -0.0467397,
							featureValue: 0,
						},
						{
							conversationId: 13080,
							disconImpact: -0.047420688,
							featureValue: 0.064,
						},
						{
							conversationId: 13085,
							disconImpact: 0.037689943,
							featureValue: 0.10675,
						},
						{
							conversationId: 13087,
							disconImpact: -0.06617863,
							featureValue: 0,
						},
						{
							conversationId: 13090,
							disconImpact: 0.26266155,
							featureValue: 0.0518333333333333,
						},
						{
							conversationId: 13098,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 13101,
							disconImpact: -0.66658175,
							featureValue: 0.122,
						},
						{
							conversationId: 13102,
							disconImpact: 0.12584414,
							featureValue: 0,
						},
						{
							conversationId: 13103,
							disconImpact: 0.30620492,
							featureValue: 0.043,
						},
						{
							conversationId: 13106,
							disconImpact: 0.19778697,
							featureValue: 0.039,
						},
						{
							conversationId: 13109,
							disconImpact: -0.15525356,
							featureValue: 0,
						},
						{
							conversationId: 13112,
							disconImpact: -0.2055347,
							featureValue: 0,
						},
						{
							conversationId: 13115,
							disconImpact: -0.048338987,
							featureValue: 0,
						},
						{
							conversationId: 13117,
							disconImpact: 0.441789,
							featureValue: 0.09625,
						},
						{
							conversationId: 13118,
							disconImpact: -0.24171707,
							featureValue: 0.0683333333333333,
						},
						{
							conversationId: 13119,
							disconImpact: -0.5976704,
							featureValue: 0.307,
						},
						{
							conversationId: 13120,
							disconImpact: 0.090809405,
							featureValue: 0.029,
						},
						{
							conversationId: 13121,
							disconImpact: 0.05944112,
							featureValue: 0.0895,
						},
						{
							conversationId: 13124,
							disconImpact: -0.26278943,
							featureValue: 0,
						},
						{
							conversationId: 13127,
							disconImpact: -0.13961314,
							featureValue: 0.0601666666666666,
						},
						{
							conversationId: 13129,
							disconImpact: -0.26532486,
							featureValue: 0,
						},
						{
							conversationId: 13130,
							disconImpact: 0.37605986,
							featureValue: 0.0496666666666666,
						},
						{
							conversationId: 13134,
							disconImpact: -0.10426537,
							featureValue: 0,
						},
						{
							conversationId: 13135,
							disconImpact: 0.10765622,
							featureValue: 0.0386666666666666,
						},
						{
							conversationId: 13136,
							disconImpact: 0.74358094,
							featureValue: 0.048,
						},
						{
							conversationId: 13137,
							disconImpact: -0.124819204,
							featureValue: 0.0529583333333333,
						},
						{
							conversationId: 13139,
							disconImpact: -0.0035987343,
							featureValue: 0,
						},
						{
							conversationId: 13141,
							disconImpact: -0.03933973,
							featureValue: 0,
						},
						{
							conversationId: 13143,
							disconImpact: -0.0033248716,
							featureValue: 0,
						},
						{
							conversationId: 13144,
							disconImpact: 0.045794956,
							featureValue: 0.0595,
						},
						{
							conversationId: 13146,
							disconImpact: -0.50655,
							featureValue: 0.07995,
						},
						{
							conversationId: 13147,
							disconImpact: -0.09273468,
							featureValue: 0.081,
						},
						{
							conversationId: 13151,
							disconImpact: -0.12860629,
							featureValue: 0,
						},
						{
							conversationId: 13153,
							disconImpact: 0.23956603,
							featureValue: 0.255,
						},
						{
							conversationId: 13157,
							disconImpact: -0.37993264,
							featureValue: 0.0799444444444444,
						},
						{
							conversationId: 13161,
							disconImpact: -0.04507029,
							featureValue: 0,
						},
						{
							conversationId: 13164,
							disconImpact: 0.4949541,
							featureValue: 0.099875,
						},
						{
							conversationId: 13167,
							disconImpact: 0.07160869,
							featureValue: 0.055,
						},
						{
							conversationId: 13170,
							disconImpact: 0.07438478,
							featureValue: 0.031,
						},
						{
							conversationId: 13171,
							disconImpact: -0.08211892,
							featureValue: 0,
						},
						{
							conversationId: 13176,
							disconImpact: -0.11397262,
							featureValue: 0,
						},
						{
							conversationId: 13184,
							disconImpact: 0.21417214,
							featureValue: 0.065,
						},
						{
							conversationId: 13188,
							disconImpact: -0.17132299,
							featureValue: 0.0562499999999999,
						},
						{
							conversationId: 13189,
							disconImpact: -0.70242494,
							featureValue: 0.0806666666666666,
						},
						{
							conversationId: 13194,
							disconImpact: -0.3856633,
							featureValue: 0,
						},
						{
							conversationId: 13195,
							disconImpact: 0.43067887,
							featureValue: 0.0365,
						},
						{
							conversationId: 13200,
							disconImpact: 0.13675529,
							featureValue: 0.0781111111111111,
						},
						{
							conversationId: 13201,
							disconImpact: -0.06310393,
							featureValue: 0.015,
						},
						{
							conversationId: 13202,
							disconImpact: -0.014943909,
							featureValue: 0,
						},
						{
							conversationId: 13203,
							disconImpact: -1.2410802,
							featureValue: 0.1386666666666667,
						},
						{
							conversationId: 13204,
							disconImpact: 0.14685829,
							featureValue: 0.049,
						},
						{
							conversationId: 13207,
							disconImpact: -0.36861065,
							featureValue: 0.09225,
						},
						{
							conversationId: 13210,
							disconImpact: 0.33531168,
							featureValue: 0.0974999999999999,
						},
						{
							conversationId: 13212,
							disconImpact: 0.30769762,
							featureValue: 0.044,
						},
						{
							conversationId: 13213,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 13216,
							disconImpact: -0.06164944,
							featureValue: 0.073,
						},
						{
							conversationId: 13217,
							disconImpact: 0.066922985,
							featureValue: 0,
						},
						{
							conversationId: 13222,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 13223,
							disconImpact: -0.42678723,
							featureValue: 0.083,
						},
						{
							conversationId: 13224,
							disconImpact: -0.34514818,
							featureValue: 0.079,
						},
						{
							conversationId: 13225,
							disconImpact: -0.00532962,
							featureValue: 0.063,
						},
						{
							conversationId: 13227,
							disconImpact: -0.22625604,
							featureValue: 0,
						},
						{
							conversationId: 13229,
							disconImpact: 0.2675708,
							featureValue: 0.0741666666666666,
						},
						{
							conversationId: 13230,
							disconImpact: 0.0036330596,
							featureValue: 0.075,
						},
						{
							conversationId: 13231,
							disconImpact: -0.3051698,
							featureValue: 0,
						},
						{
							conversationId: 13234,
							disconImpact: -0.11810127,
							featureValue: 0,
						},
						{
							conversationId: 13235,
							disconImpact: -0.5968318,
							featureValue: 0.06,
						},
						{
							conversationId: 13236,
							disconImpact: -0.5732805,
							featureValue: 0.087,
						},
						{
							conversationId: 13239,
							disconImpact: 0.3190358,
							featureValue: 0.0655,
						},
						{
							conversationId: 13240,
							disconImpact: 0.053899378,
							featureValue: 0.065,
						},
						{
							conversationId: 13241,
							disconImpact: 0.19175965,
							featureValue: 0.05,
						},
						{
							conversationId: 13242,
							disconImpact: -0.2526014,
							featureValue: 0,
						},
						{
							conversationId: 13243,
							disconImpact: 0.26346192,
							featureValue: 0.066,
						},
						{
							conversationId: 13245,
							disconImpact: -0.062099617,
							featureValue: 0,
						},
						{
							conversationId: 13246,
							disconImpact: -0.24325576,
							featureValue: 0,
						},
						{
							conversationId: 13247,
							disconImpact: -0.75135905,
							featureValue: 0.0799166666666666,
						},
						{
							conversationId: 13249,
							disconImpact: -0.23665333,
							featureValue: 0.062,
						},
						{
							conversationId: 13252,
							disconImpact: -1.2381818,
							featureValue: 0.195,
						},
						{
							conversationId: 13254,
							disconImpact: -0.1262933,
							featureValue: 0,
						},
						{
							conversationId: 13255,
							disconImpact: 0.10270792,
							featureValue: 0.069,
						},
						{
							conversationId: 13256,
							disconImpact: -0.10860963,
							featureValue: 0,
						},
						{
							conversationId: 13257,
							disconImpact: -0.22223005,
							featureValue: 0.027,
						},
						{
							conversationId: 13258,
							disconImpact: -0.7001179,
							featureValue: 0.082,
						},
						{
							conversationId: 13259,
							disconImpact: -0.103619814,
							featureValue: 0,
						},
						{
							conversationId: 13260,
							disconImpact: 0.046254374,
							featureValue: 0.0684166666666666,
						},
						{
							conversationId: 13263,
							disconImpact: -0.06022827,
							featureValue: 0,
						},
						{
							conversationId: 13264,
							disconImpact: 0.022112243,
							featureValue: 0,
						},
						{
							conversationId: 13266,
							disconImpact: -0.10480136,
							featureValue: 0.076,
						},
						{
							conversationId: 13267,
							disconImpact: 0.27258748,
							featureValue: 0.071,
						},
						{
							conversationId: 13269,
							disconImpact: 0.39532512,
							featureValue: 0.066,
						},
						{
							conversationId: 13271,
							disconImpact: -0.07566905,
							featureValue: 0,
						},
						{
							conversationId: 13272,
							disconImpact: -0.1892477,
							featureValue: 0.05875,
						},
						{
							conversationId: 13273,
							disconImpact: 0.07621353,
							featureValue: 0.069,
						},
						{
							conversationId: 13274,
							disconImpact: -0.68162966,
							featureValue: 0.082,
						},
						{
							conversationId: 13275,
							disconImpact: -0.31171265,
							featureValue: 0.058,
						},
						{
							conversationId: 13277,
							disconImpact: -0.25266233,
							featureValue: 0.0516666666666666,
						},
						{
							conversationId: 13278,
							disconImpact: -0.071781576,
							featureValue: 0,
						},
						{
							conversationId: 13282,
							disconImpact: -0.22018026,
							featureValue: 0.077,
						},
						{
							conversationId: 13284,
							disconImpact: -0.13243806,
							featureValue: 0,
						},
						{
							conversationId: 13285,
							disconImpact: -0.20281383,
							featureValue: 0,
						},
						{
							conversationId: 13288,
							disconImpact: 0.9066855,
							featureValue: 0.046,
						},
						{
							conversationId: 13290,
							disconImpact: -0.11191042,
							featureValue: 0,
						},
						{
							conversationId: 13291,
							disconImpact: 0.30214083,
							featureValue: 0.0656666666666666,
						},
						{
							conversationId: 13292,
							disconImpact: -0.2553659,
							featureValue: 0,
						},
						{
							conversationId: 13293,
							disconImpact: 0.1004663,
							featureValue: 0.0635,
						},
						{
							conversationId: 13294,
							disconImpact: -0.057417654,
							featureValue: 0.0584999999999999,
						},
						{
							conversationId: 13296,
							disconImpact: -1.3202239,
							featureValue: 0.121,
						},
						{
							conversationId: 13298,
							disconImpact: -0.361677,
							featureValue: 0.0705,
						},
						{
							conversationId: 13299,
							disconImpact: 0.19934407,
							featureValue: 0.0353333333333333,
						},
						{
							conversationId: 13300,
							disconImpact: 0.092074946,
							featureValue: 0.0511666666666666,
						},
						{
							conversationId: 13301,
							disconImpact: -0.41863352,
							featureValue: 0.052,
						},
						{
							conversationId: 13303,
							disconImpact: 0.14225091,
							featureValue: 0.051,
						},
						{
							conversationId: 13304,
							disconImpact: -0.20708165,
							featureValue: 0,
						},
						{
							conversationId: 13305,
							disconImpact: -1.5255188,
							featureValue: 0.1332,
						},
						{
							conversationId: 13306,
							disconImpact: -1.3132784,
							featureValue: 0.151,
						},
						{
							conversationId: 13307,
							disconImpact: 0.08892105,
							featureValue: 0.063,
						},
						{
							conversationId: 13308,
							disconImpact: -0.2543681,
							featureValue: 0,
						},
						{
							conversationId: 13309,
							disconImpact: 0.15715426,
							featureValue: 0.0675,
						},
						{
							conversationId: 13311,
							disconImpact: -0.08683977,
							featureValue: 0,
						},
						{
							conversationId: 13312,
							disconImpact: -0.037382707,
							featureValue: 0.051,
						},
						{
							conversationId: 13314,
							disconImpact: -0.30864817,
							featureValue: 0.086,
						},
						{
							conversationId: 13315,
							disconImpact: 0.4954212,
							featureValue: 0.044,
						},
						{
							conversationId: 13316,
							disconImpact: -0.30600873,
							featureValue: 0.053,
						},
						{
							conversationId: 13317,
							disconImpact: 0.054582227,
							featureValue: 0,
						},
						{
							conversationId: 13318,
							disconImpact: -0.057740256,
							featureValue: 0.0555,
						},
						{
							conversationId: 13319,
							disconImpact: -0.3107556,
							featureValue: 0.058,
						},
						{
							conversationId: 13320,
							disconImpact: 1.0563786,
							featureValue: 0.0915,
						},
						{
							conversationId: 13322,
							disconImpact: -0.04507029,
							featureValue: 0,
						},
						{
							conversationId: 13323,
							disconImpact: 0.09615187,
							featureValue: 0.0914444444444444,
						},
						{
							conversationId: 13324,
							disconImpact: -0.2826763,
							featureValue: 0,
						},
						{
							conversationId: 13325,
							disconImpact: -0.20254056,
							featureValue: 0,
						},
						{
							conversationId: 13326,
							disconImpact: 0.54740554,
							featureValue: 0.0943333333333333,
						},
						{
							conversationId: 13329,
							disconImpact: -1.0615268,
							featureValue: 0.164,
						},
						{
							conversationId: 13330,
							disconImpact: 0.51894754,
							featureValue: 0.0655,
						},
						{
							conversationId: 13331,
							disconImpact: 0.009884696,
							featureValue: 0.03625,
						},
						{
							conversationId: 13334,
							disconImpact: -0.14013247,
							featureValue: 0,
						},
						{
							conversationId: 13335,
							disconImpact: 0.025700757,
							featureValue: 0.0881666666666666,
						},
						{
							conversationId: 13336,
							disconImpact: -0.04507029,
							featureValue: 0,
						},
						{
							conversationId: 13337,
							disconImpact: -0.2085008,
							featureValue: 0,
						},
						{
							conversationId: 13338,
							disconImpact: 0.25034836,
							featureValue: 0.074,
						},
						{
							conversationId: 13340,
							disconImpact: 0.31094998,
							featureValue: 0.038,
						},
						{
							conversationId: 13341,
							disconImpact: 0.22448325,
							featureValue: 0.07125,
						},
						{
							conversationId: 13343,
							disconImpact: 0.25504696,
							featureValue: 0,
						},
						{
							conversationId: 13344,
							disconImpact: -0.19857123,
							featureValue: 0.056,
						},
						{
							conversationId: 13345,
							disconImpact: -0.063008085,
							featureValue: 0,
						},
						{
							conversationId: 13346,
							disconImpact: -0.27990177,
							featureValue: 0,
						},
						{
							conversationId: 13348,
							disconImpact: -0.012763087,
							featureValue: 0,
						},
						{
							conversationId: 13349,
							disconImpact: -0.55524355,
							featureValue: 0.087,
						},
						{
							conversationId: 13350,
							disconImpact: -0.23684198,
							featureValue: 0.064,
						},
						{
							conversationId: 13351,
							disconImpact: -0.024459125,
							featureValue: 0.0903333333333333,
						},
						{
							conversationId: 13352,
							disconImpact: -0.13898106,
							featureValue: 0.056,
						},
						{
							conversationId: 13353,
							disconImpact: -0.36129993,
							featureValue: 0.273,
						},
						{
							conversationId: 13356,
							disconImpact: -0.25222483,
							featureValue: 0.063,
						},
						{
							conversationId: 13358,
							disconImpact: 0.03752776,
							featureValue: 0.072,
						},
						{
							conversationId: 13360,
							disconImpact: 0.31754616,
							featureValue: 0.044,
						},
						{
							conversationId: 13361,
							disconImpact: -0.17263106,
							featureValue: 0.055,
						},
						{
							conversationId: 13364,
							disconImpact: -0.17601974,
							featureValue: 0,
						},
						{
							conversationId: 13365,
							disconImpact: -0.058741145,
							featureValue: 0.081,
						},
						{
							conversationId: 13366,
							disconImpact: -0.19153136,
							featureValue: 0.055,
						},
						{
							conversationId: 13368,
							disconImpact: 0.116148785,
							featureValue: 0.053,
						},
						{
							conversationId: 13369,
							disconImpact: -0.4569072,
							featureValue: 0.0575416666666666,
						},
						{
							conversationId: 13370,
							disconImpact: -0.14975682,
							featureValue: 0,
						},
						{
							conversationId: 13371,
							disconImpact: -0.6363965,
							featureValue: 0.12,
						},
						{
							conversationId: 13374,
							disconImpact: -0.31175688,
							featureValue: 0.05475,
						},
						{
							conversationId: 13376,
							disconImpact: -0.07059901,
							featureValue: 0.0685,
						},
						{
							conversationId: 13379,
							disconImpact: 0.3182747,
							featureValue: 0.0565,
						},
						{
							conversationId: 13380,
							disconImpact: 0.038994774,
							featureValue: 0.0886666666666666,
						},
						{
							conversationId: 13381,
							disconImpact: 0.26510927,
							featureValue: 0.092,
						},
						{
							conversationId: 13382,
							disconImpact: -0.08470709,
							featureValue: 0,
						},
						{
							conversationId: 13384,
							disconImpact: 0.008342095,
							featureValue: 0.053,
						},
						{
							conversationId: 13386,
							disconImpact: 0.36185825,
							featureValue: 0.0595,
						},
						{
							conversationId: 13387,
							disconImpact: -0.02521944,
							featureValue: 0.0565,
						},
						{
							conversationId: 13389,
							disconImpact: -0.04340344,
							featureValue: 0.0705,
						},
						{
							conversationId: 13390,
							disconImpact: -0.23357472,
							featureValue: 0,
						},
						{
							conversationId: 13391,
							disconImpact: -0.030737394,
							featureValue: 0.098,
						},
						{
							conversationId: 13393,
							disconImpact: -0.2502916,
							featureValue: 0.05175,
						},
						{
							conversationId: 13396,
							disconImpact: -0.10830973,
							featureValue: 0,
						},
						{
							conversationId: 13397,
							disconImpact: 0.08199725,
							featureValue: 0.066,
						},
						{
							conversationId: 13399,
							disconImpact: -0.012763087,
							featureValue: 0,
						},
						{
							conversationId: 13400,
							disconImpact: -8.277688e-5,
							featureValue: 0.0753333333333333,
						},
						{
							conversationId: 13401,
							disconImpact: -0.10571835,
							featureValue: 0.1,
						},
						{
							conversationId: 13402,
							disconImpact: -0.22267494,
							featureValue: 0,
						},
						{
							conversationId: 13403,
							disconImpact: -0.22779107,
							featureValue: 0.0794999999999999,
						},
						{
							conversationId: 13404,
							disconImpact: 0.0007724222,
							featureValue: 0,
						},
						{
							conversationId: 13405,
							disconImpact: 0.26733753,
							featureValue: 0.092,
						},
						{
							conversationId: 13406,
							disconImpact: -0.020722702,
							featureValue: 0,
						},
						{
							conversationId: 13407,
							disconImpact: 0.26322985,
							featureValue: 0.096,
						},
						{
							conversationId: 13409,
							disconImpact: 0.088825665,
							featureValue: 0,
						},
						{
							conversationId: 13411,
							disconImpact: -0.11249059,
							featureValue: 0.058,
						},
						{
							conversationId: 13412,
							disconImpact: -0.3450913,
							featureValue: 0,
						},
						{
							conversationId: 13413,
							disconImpact: 0.20057763,
							featureValue: 0.0503333333333333,
						},
						{
							conversationId: 13414,
							disconImpact: -0.19241296,
							featureValue: 0.084,
						},
						{
							conversationId: 13416,
							disconImpact: 0.23353341,
							featureValue: 0.0845,
						},
						{
							conversationId: 13419,
							disconImpact: 0.4983937,
							featureValue: 0.0435,
						},
						{
							conversationId: 13420,
							disconImpact: 0.30035257,
							featureValue: 0.073,
						},
						{
							conversationId: 13421,
							disconImpact: -0.47770697,
							featureValue: 0.0793333333333333,
						},
						{
							conversationId: 13422,
							disconImpact: -0.44843653,
							featureValue: 0.07925,
						},
						{
							conversationId: 13423,
							disconImpact: -0.23775968,
							featureValue: 0,
						},
						{
							conversationId: 13426,
							disconImpact: -0.24044892,
							featureValue: 0,
						},
						{
							conversationId: 13427,
							disconImpact: -1.3126334,
							featureValue: 0.187,
						},
						{
							conversationId: 13428,
							disconImpact: 0.01746355,
							featureValue: 0,
						},
						{
							conversationId: 13429,
							disconImpact: -0.17327838,
							featureValue: 0.07655,
						},
						{
							conversationId: 13431,
							disconImpact: -0.40500814,
							featureValue: 0.053,
						},
						{
							conversationId: 13432,
							disconImpact: -0.6812834,
							featureValue: 0.08675,
						},
						{
							conversationId: 13434,
							disconImpact: 0.6713099,
							featureValue: 0.0940555555555555,
						},
						{
							conversationId: 13437,
							disconImpact: -0.084852256,
							featureValue: 0.099,
						},
						{
							conversationId: 13438,
							disconImpact: 0.2797441,
							featureValue: 0.056,
						},
						{
							conversationId: 13440,
							disconImpact: 0.38744393,
							featureValue: 0.049,
						},
						{
							conversationId: 13441,
							disconImpact: -0.062143482,
							featureValue: 0.0513333333333333,
						},
						{
							conversationId: 13442,
							disconImpact: -1.1031576,
							featureValue: 0.33,
						},
						{
							conversationId: 13443,
							disconImpact: -0.17110023,
							featureValue: 0.061,
						},
						{
							conversationId: 13444,
							disconImpact: 0.53839105,
							featureValue: 0.0478333333333333,
						},
						{
							conversationId: 13446,
							disconImpact: -0.3150393,
							featureValue: 0.061,
						},
						{
							conversationId: 13447,
							disconImpact: -0.25362,
							featureValue: 0,
						},
						{
							conversationId: 13448,
							disconImpact: -0.25115156,
							featureValue: 0,
						},
						{
							conversationId: 13449,
							disconImpact: 0.09614426,
							featureValue: 0.05,
						},
						{
							conversationId: 13450,
							disconImpact: -0.20527445,
							featureValue: 0.077625,
						},
						{
							conversationId: 13452,
							disconImpact: 0.12728405,
							featureValue: 0,
						},
						{
							conversationId: 13453,
							disconImpact: -0.28049567,
							featureValue: 0.0611666666666666,
						},
						{
							conversationId: 13454,
							disconImpact: -0.32616168,
							featureValue: 0,
						},
						{
							conversationId: 13455,
							disconImpact: 0.3926771,
							featureValue: 0.047,
						},
						{
							conversationId: 13459,
							disconImpact: 0.40559098,
							featureValue: 0.067,
						},
						{
							conversationId: 13460,
							disconImpact: 0.14930789,
							featureValue: 0.0715,
						},
						{
							conversationId: 13461,
							disconImpact: -0.12732226,
							featureValue: 0,
						},
						{
							conversationId: 13462,
							disconImpact: -0.36825904,
							featureValue: 0.318,
						},
						{
							conversationId: 13464,
							disconImpact: -0.86511326,
							featureValue: 0.1295,
						},
						{
							conversationId: 13465,
							disconImpact: 0.1473356,
							featureValue: 0.0966666666666666,
						},
						{
							conversationId: 13466,
							disconImpact: 0.036434103,
							featureValue: 0,
						},
						{
							conversationId: 13467,
							disconImpact: 0.2042216,
							featureValue: 0.0481666666666666,
						},
						{
							conversationId: 13471,
							disconImpact: -0.20909974,
							featureValue: 0.05275,
						},
						{
							conversationId: 13472,
							disconImpact: 0.15623316,
							featureValue: 0.0746666666666666,
						},
						{
							conversationId: 13474,
							disconImpact: 0.26904857,
							featureValue: 0,
						},
						{
							conversationId: 13476,
							disconImpact: -0.19517502,
							featureValue: 0.0574999999999999,
						},
						{
							conversationId: 13477,
							disconImpact: 0.19633494,
							featureValue: 0.078,
						},
						{
							conversationId: 13478,
							disconImpact: -0.012763087,
							featureValue: 0,
						},
						{
							conversationId: 13480,
							disconImpact: 0.041450728,
							featureValue: 0.052,
						},
						{
							conversationId: 13482,
							disconImpact: -1.0657617,
							featureValue: 0.301,
						},
						{
							conversationId: 13484,
							disconImpact: 0.16908805,
							featureValue: 0.07225,
						},
						{
							conversationId: 13485,
							disconImpact: 0.03585229,
							featureValue: 0.095,
						},
						{
							conversationId: 13486,
							disconImpact: -0.22047621,
							featureValue: 0.028,
						},
						{
							conversationId: 13487,
							disconImpact: 0.02849736,
							featureValue: 0.062,
						},
						{
							conversationId: 13488,
							disconImpact: -0.12789258,
							featureValue: 0,
						},
						{
							conversationId: 13490,
							disconImpact: -0.24816485,
							featureValue: 0,
						},
						{
							conversationId: 13492,
							disconImpact: -0.07417498,
							featureValue: 0.076,
						},
						{
							conversationId: 13495,
							disconImpact: -0.078005075,
							featureValue: 0,
						},
						{
							conversationId: 13497,
							disconImpact: -0.12595761,
							featureValue: 0,
						},
						{
							conversationId: 13498,
							disconImpact: -0.23691404,
							featureValue: 0.119,
						},
						{
							conversationId: 13499,
							disconImpact: 0.11731121,
							featureValue: 0.071,
						},
						{
							conversationId: 13500,
							disconImpact: -0.78364736,
							featureValue: 0.478,
						},
						{
							conversationId: 13501,
							disconImpact: -1.1894935,
							featureValue: 0.173,
						},
						{
							conversationId: 13502,
							disconImpact: -0.26236507,
							featureValue: 0.0535,
						},
						{
							conversationId: 13503,
							disconImpact: -0.10929606,
							featureValue: 0.0535,
						},
						{
							conversationId: 13505,
							disconImpact: -0.061595753,
							featureValue: 0,
						},
						{
							conversationId: 13507,
							disconImpact: 0.096612826,
							featureValue: 0.036,
						},
						{
							conversationId: 13508,
							disconImpact: -0.722336,
							featureValue: 0.0574999999999999,
						},
						{
							conversationId: 13509,
							disconImpact: -0.07322042,
							featureValue: 0.065,
						},
						{
							conversationId: 13511,
							disconImpact: -0.26122248,
							featureValue: 0.0545,
						},
						{
							conversationId: 13512,
							disconImpact: -0.12236736,
							featureValue: 0.061,
						},
						{
							conversationId: 13515,
							disconImpact: -1.0809189,
							featureValue: 0.124,
						},
						{
							conversationId: 13516,
							disconImpact: -0.014028642,
							featureValue: 0,
						},
						{
							conversationId: 13517,
							disconImpact: -0.13518667,
							featureValue: 0,
						},
						{
							conversationId: 13521,
							disconImpact: -0.13822505,
							featureValue: 0,
						},
						{
							conversationId: 13522,
							disconImpact: -0.08110756,
							featureValue: 0.1106666666666666,
						},
						{
							conversationId: 13524,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 13525,
							disconImpact: 0.39695352,
							featureValue: 0.067,
						},
						{
							conversationId: 13526,
							disconImpact: -0.51493114,
							featureValue: 0.3355,
						},
						{
							conversationId: 13528,
							disconImpact: -1.5136257,
							featureValue: 0.1236666666666666,
						},
						{
							conversationId: 13530,
							disconImpact: 0.6503265,
							featureValue: 0.046,
						},
						{
							conversationId: 13532,
							disconImpact: 0.27882028,
							featureValue: 0.036,
						},
						{
							conversationId: 13533,
							disconImpact: 0.054991204,
							featureValue: 0.077,
						},
						{
							conversationId: 13534,
							disconImpact: -0.37435734,
							featureValue: 0.055,
						},
						{
							conversationId: 13535,
							disconImpact: 0.10761538,
							featureValue: 0.076,
						},
						{
							conversationId: 13536,
							disconImpact: -0.27624336,
							featureValue: 0.1115,
						},
						{
							conversationId: 13539,
							disconImpact: 0.06252741,
							featureValue: 0.0699999999999999,
						},
						{
							conversationId: 13540,
							disconImpact: 0.13247648,
							featureValue: 0.071,
						},
						{
							conversationId: 13541,
							disconImpact: -0.07566905,
							featureValue: 0,
						},
						{
							conversationId: 13544,
							disconImpact: 0.57447153,
							featureValue: 0.0465,
						},
						{
							conversationId: 13545,
							disconImpact: -0.2789415,
							featureValue: 0,
						},
						{
							conversationId: 13546,
							disconImpact: 0.26788875,
							featureValue: 0.067,
						},
						{
							conversationId: 13547,
							disconImpact: 0.322112,
							featureValue: 0.073,
						},
						{
							conversationId: 13548,
							disconImpact: 0.18038905,
							featureValue: 0.043,
						},
						{
							conversationId: 13549,
							disconImpact: 0.22901192,
							featureValue: 0.067,
						},
						{
							conversationId: 13550,
							disconImpact: -0.09778319,
							featureValue: 0,
						},
						{
							conversationId: 13551,
							disconImpact: 0.020155996,
							featureValue: 0.07475,
						},
						{
							conversationId: 13552,
							disconImpact: -0.31028917,
							featureValue: 0.0895,
						},
						{
							conversationId: 13553,
							disconImpact: -0.009708325,
							featureValue: 0,
						},
						{
							conversationId: 13554,
							disconImpact: -0.07566905,
							featureValue: 0,
						},
						{
							conversationId: 13556,
							disconImpact: 0.31434113,
							featureValue: 0.071,
						},
						{
							conversationId: 13558,
							disconImpact: -0.3773734,
							featureValue: 0.298,
						},
						{
							conversationId: 13559,
							disconImpact: 0.0015125237,
							featureValue: 0,
						},
						{
							conversationId: 13560,
							disconImpact: 0.25756833,
							featureValue: 0.108,
						},
						{
							conversationId: 13561,
							disconImpact: -0.33732918,
							featureValue: 0.0581249999999999,
						},
						{
							conversationId: 13562,
							disconImpact: -0.20920005,
							featureValue: 0,
						},
						{
							conversationId: 13564,
							disconImpact: -0.13891903,
							featureValue: 0,
						},
						{
							conversationId: 13565,
							disconImpact: -0.36682814,
							featureValue: 0.1456666666666667,
						},
						{
							conversationId: 13566,
							disconImpact: -0.5615749,
							featureValue: 0.082,
						},
						{
							conversationId: 13569,
							disconImpact: -0.10799732,
							featureValue: 0,
						},
						{
							conversationId: 13571,
							disconImpact: -0.034091473,
							featureValue: 0,
						},
						{
							conversationId: 13572,
							disconImpact: -1.0413473,
							featureValue: 0.182,
						},
						{
							conversationId: 13573,
							disconImpact: 0.008839766,
							featureValue: 0,
						},
						{
							conversationId: 13574,
							disconImpact: 0.14600244,
							featureValue: 0.032,
						},
						{
							conversationId: 13576,
							disconImpact: -0.92671597,
							featureValue: 0.2086666666666666,
						},
						{
							conversationId: 13577,
							disconImpact: -0.7059852,
							featureValue: 0.1152499999999999,
						},
						{
							conversationId: 13578,
							disconImpact: -0.07476931,
							featureValue: 0.078,
						},
						{
							conversationId: 13579,
							disconImpact: -0.29182914,
							featureValue: 0,
						},
						{
							conversationId: 13580,
							disconImpact: 0.20698744,
							featureValue: 0.094,
						},
						{
							conversationId: 13581,
							disconImpact: -0.16672337,
							featureValue: 0,
						},
						{
							conversationId: 13582,
							disconImpact: 0.05627814,
							featureValue: 0.079,
						},
						{
							conversationId: 13583,
							disconImpact: -0.7959323,
							featureValue: 0.11775,
						},
						{
							conversationId: 13584,
							disconImpact: 0.7381438,
							featureValue: 0.102,
						},
						{
							conversationId: 13586,
							disconImpact: -0.30987236,
							featureValue: 0,
						},
						{
							conversationId: 13588,
							disconImpact: -0.02316576,
							featureValue: 0.0853333333333333,
						},
						{
							conversationId: 13589,
							disconImpact: 0.08672306,
							featureValue: 0.0415,
						},
						{
							conversationId: 13592,
							disconImpact: 0.03609562,
							featureValue: 0.06875,
						},
						{
							conversationId: 13593,
							disconImpact: -0.24291775,
							featureValue: 0,
						},
						{
							conversationId: 13594,
							disconImpact: -0.07566905,
							featureValue: 0,
						},
						{
							conversationId: 13595,
							disconImpact: -0.13572611,
							featureValue: 0,
						},
						{
							conversationId: 13597,
							disconImpact: -0.8803975,
							featureValue: 0.123,
						},
						{
							conversationId: 13598,
							disconImpact: -0.35335013,
							featureValue: 0.076,
						},
						{
							conversationId: 13599,
							disconImpact: -0.29804084,
							featureValue: 0.058,
						},
						{
							conversationId: 13601,
							disconImpact: 0.0027019978,
							featureValue: 0.0899841269841269,
						},
						{
							conversationId: 13603,
							disconImpact: 0.7122725,
							featureValue: 0.113,
						},
						{
							conversationId: 13605,
							disconImpact: 0.5309208,
							featureValue: 0.0465,
						},
						{
							conversationId: 13606,
							disconImpact: 0.8084231,
							featureValue: 0.0456666666666666,
						},
						{
							conversationId: 13607,
							disconImpact: 0.093843184,
							featureValue: 0.0645,
						},
						{
							conversationId: 13608,
							disconImpact: -0.04390424,
							featureValue: 0.0503333333333333,
						},
						{
							conversationId: 13610,
							disconImpact: -0.8587376,
							featureValue: 0.0802499999999999,
						},
						{
							conversationId: 13612,
							disconImpact: -0.14238413,
							featureValue: 0.0548,
						},
						{
							conversationId: 13613,
							disconImpact: -0.102759235,
							featureValue: 0.064,
						},
						{
							conversationId: 13614,
							disconImpact: 0.17742012,
							featureValue: 0.0875,
						},
						{
							conversationId: 13616,
							disconImpact: -0.08437325,
							featureValue: 0.0911666666666666,
						},
						{
							conversationId: 13617,
							disconImpact: -0.16165978,
							featureValue: 0,
						},
						{
							conversationId: 13621,
							disconImpact: 0.2834479,
							featureValue: 0.0967,
						},
						{
							conversationId: 13623,
							disconImpact: 0.22027797,
							featureValue: 0.066,
						},
						{
							conversationId: 13624,
							disconImpact: -0.122598335,
							featureValue: 0.09,
						},
						{
							conversationId: 13625,
							disconImpact: -0.24046387,
							featureValue: 0,
						},
						{
							conversationId: 13626,
							disconImpact: 0.35632834,
							featureValue: 0.099,
						},
						{
							conversationId: 13628,
							disconImpact: -0.059059933,
							featureValue: 0,
						},
						{
							conversationId: 13629,
							disconImpact: -0.12642848,
							featureValue: 0.022,
						},
						{
							conversationId: 13630,
							disconImpact: -0.3178075,
							featureValue: 0.112,
						},
						{
							conversationId: 13631,
							disconImpact: -0.60368615,
							featureValue: 0.083,
						},
						{
							conversationId: 13633,
							disconImpact: -0.6357528,
							featureValue: 0.055,
						},
						{
							conversationId: 13635,
							disconImpact: 0.15671279,
							featureValue: 0.04,
						},
						{
							conversationId: 13636,
							disconImpact: -0.16435553,
							featureValue: 0,
						},
						{
							conversationId: 13637,
							disconImpact: 0.25657552,
							featureValue: 0.0715,
						},
						{
							conversationId: 13638,
							disconImpact: -0.111109436,
							featureValue: 0.027,
						},
						{
							conversationId: 13639,
							disconImpact: -0.47157753,
							featureValue: 0.055,
						},
						{
							conversationId: 13641,
							disconImpact: -0.119935885,
							featureValue: 0,
						},
						{
							conversationId: 13642,
							disconImpact: -0.17032614,
							featureValue: 0,
						},
						{
							conversationId: 13644,
							disconImpact: 0.2688829,
							featureValue: 0.09425,
						},
						{
							conversationId: 13647,
							disconImpact: -0.087848306,
							featureValue: 0.07,
						},
						{
							conversationId: 13648,
							disconImpact: -0.14244229,
							featureValue: 0,
						},
						{
							conversationId: 13651,
							disconImpact: 0.21127746,
							featureValue: 0.0436666666666666,
						},
						{
							conversationId: 13652,
							disconImpact: 0.062388975,
							featureValue: 0.072,
						},
						{
							conversationId: 13654,
							disconImpact: 0.3682127,
							featureValue: 0.094,
						},
						{
							conversationId: 13655,
							disconImpact: -0.07513032,
							featureValue: 0.023,
						},
						{
							conversationId: 13656,
							disconImpact: -0.0137400795,
							featureValue: 0.062,
						},
						{
							conversationId: 13657,
							disconImpact: -0.23543048,
							featureValue: 0.0595,
						},
						{
							conversationId: 13660,
							disconImpact: 0.16732028,
							featureValue: 0.0625,
						},
						{
							conversationId: 13661,
							disconImpact: 0.17192747,
							featureValue: 0.064,
						},
						{
							conversationId: 13663,
							disconImpact: -0.16476974,
							featureValue: 0,
						},
						{
							conversationId: 13664,
							disconImpact: -0.3353841,
							featureValue: 0,
						},
						{
							conversationId: 13666,
							disconImpact: -0.16952977,
							featureValue: 0.027,
						},
						{
							conversationId: 13669,
							disconImpact: -0.0558973,
							featureValue: 0.04,
						},
						{
							conversationId: 13670,
							disconImpact: -0.25685284,
							featureValue: 0.056,
						},
						{
							conversationId: 13675,
							disconImpact: 0.019188,
							featureValue: 0.07,
						},
						{
							conversationId: 13676,
							disconImpact: 0.105834484,
							featureValue: 0.0743333333333333,
						},
						{
							conversationId: 13677,
							disconImpact: -0.7756321,
							featureValue: 0.285,
						},
						{
							conversationId: 13679,
							disconImpact: 0.16095908,
							featureValue: 0.0584761904761904,
						},
						{
							conversationId: 13680,
							disconImpact: -0.09223787,
							featureValue: 0,
						},
						{
							conversationId: 13681,
							disconImpact: 0.058671184,
							featureValue: 0,
						},
						{
							conversationId: 13682,
							disconImpact: -0.8334451,
							featureValue: 0.1776,
						},
						{
							conversationId: 13683,
							disconImpact: -0.013698088,
							featureValue: 0.05025,
						},
						{
							conversationId: 13684,
							disconImpact: -0.4779812,
							featureValue: 0.059,
						},
						{
							conversationId: 13686,
							disconImpact: -0.10755508,
							featureValue: 0.053,
						},
						{
							conversationId: 13687,
							disconImpact: -0.15141176,
							featureValue: 0,
						},
						{
							conversationId: 13688,
							disconImpact: -0.19328068,
							featureValue: 0,
						},
						{
							conversationId: 13689,
							disconImpact: -0.26015788,
							featureValue: 0,
						},
						{
							conversationId: 13690,
							disconImpact: -0.17522433,
							featureValue: 0.055,
						},
						{
							conversationId: 13691,
							disconImpact: 0.6654176,
							featureValue: 0.095,
						},
						{
							conversationId: 13694,
							disconImpact: 0.421292,
							featureValue: 0.0463333333333333,
						},
						{
							conversationId: 13695,
							disconImpact: 0.34137365,
							featureValue: 0.04875,
						},
						{
							conversationId: 13696,
							disconImpact: -0.07364414,
							featureValue: 0.051,
						},
						{
							conversationId: 13700,
							disconImpact: -0.022398353,
							featureValue: 0.058,
						},
						{
							conversationId: 13701,
							disconImpact: -0.12256787,
							featureValue: 0.061,
						},
						{
							conversationId: 13702,
							disconImpact: -0.0065976772,
							featureValue: 0.0675,
						},
						{
							conversationId: 13703,
							disconImpact: -0.16777465,
							featureValue: 0,
						},
						{
							conversationId: 13706,
							disconImpact: 0.06663583,
							featureValue: 0.0349999999999999,
						},
						{
							conversationId: 13707,
							disconImpact: -0.72989666,
							featureValue: 0.302,
						},
						{
							conversationId: 13709,
							disconImpact: -0.86835325,
							featureValue: 0.1443333333333333,
						},
						{
							conversationId: 13710,
							disconImpact: -1.2498312,
							featureValue: 0.128,
						},
						{
							conversationId: 13711,
							disconImpact: -0.009061089,
							featureValue: 0,
						},
						{
							conversationId: 13712,
							disconImpact: -0.09603593,
							featureValue: 0.0543333333333333,
						},
						{
							conversationId: 13713,
							disconImpact: -0.65165365,
							featureValue: 0.1176666666666666,
						},
						{
							conversationId: 13715,
							disconImpact: 0.23204857,
							featureValue: 0.069,
						},
						{
							conversationId: 13720,
							disconImpact: 0.10292743,
							featureValue: 0.0703333333333333,
						},
						{
							conversationId: 13721,
							disconImpact: 0.07370176,
							featureValue: 0.068,
						},
						{
							conversationId: 13723,
							disconImpact: -0.96742195,
							featureValue: 0.2615,
						},
						{
							conversationId: 13724,
							disconImpact: -0.27414605,
							featureValue: 0,
						},
						{
							conversationId: 13728,
							disconImpact: -0.1620582,
							featureValue: 0,
						},
						{
							conversationId: 13733,
							disconImpact: -0.0009830501,
							featureValue: 0.056,
						},
						{
							conversationId: 13734,
							disconImpact: -0.19071485,
							featureValue: 0.021,
						},
						{
							conversationId: 13736,
							disconImpact: 0.019664858,
							featureValue: 0,
						},
						{
							conversationId: 13737,
							disconImpact: -0.33126092,
							featureValue: 0,
						},
						{
							conversationId: 13743,
							disconImpact: -0.8375118,
							featureValue: 0.3241666666666666,
						},
						{
							conversationId: 13745,
							disconImpact: -0.24040608,
							featureValue: 0.058,
						},
						{
							conversationId: 13746,
							disconImpact: -0.26016837,
							featureValue: 0,
						},
						{
							conversationId: 13747,
							disconImpact: -0.15375337,
							featureValue: 0,
						},
						{
							conversationId: 13750,
							disconImpact: 0.3344555,
							featureValue: 0.067,
						},
						{
							conversationId: 13751,
							disconImpact: -0.43613127,
							featureValue: 0,
						},
						{
							conversationId: 13752,
							disconImpact: -0.5935721,
							featureValue: 0.1935,
						},
						{
							conversationId: 13754,
							disconImpact: -0.06621015,
							featureValue: 0,
						},
						{
							conversationId: 13756,
							disconImpact: -0.064193316,
							featureValue: 0.043,
						},
						{
							conversationId: 13757,
							disconImpact: 0.28222466,
							featureValue: 0.067,
						},
						{
							conversationId: 13758,
							disconImpact: -0.27297497,
							featureValue: 0,
						},
						{
							conversationId: 13759,
							disconImpact: 0.022879312,
							featureValue: 0.0543333333333333,
						},
						{
							conversationId: 13761,
							disconImpact: -0.92158854,
							featureValue: 0.155,
						},
						{
							conversationId: 13764,
							disconImpact: -0.15850136,
							featureValue: 0.0413333333333333,
						},
						{
							conversationId: 13765,
							disconImpact: -0.3382058,
							featureValue: 0.06175,
						},
						{
							conversationId: 13766,
							disconImpact: 0.025272183,
							featureValue: 0.058,
						},
						{
							conversationId: 13768,
							disconImpact: 0.30946356,
							featureValue: 0.0665,
						},
						{
							conversationId: 13769,
							disconImpact: -0.121584535,
							featureValue: 0.065,
						},
						{
							conversationId: 13770,
							disconImpact: -0.26881936,
							featureValue: 0.053,
						},
						{
							conversationId: 13772,
							disconImpact: -1.0846286,
							featureValue: 0.22175,
						},
						{
							conversationId: 13773,
							disconImpact: -0.041271232,
							featureValue: 0.065,
						},
						{
							conversationId: 13774,
							disconImpact: 0.20694193,
							featureValue: 0.037,
						},
						{
							conversationId: 13776,
							disconImpact: -0.20055996,
							featureValue: 0,
						},
						{
							conversationId: 13777,
							disconImpact: -0.28494,
							featureValue: 0,
						},
						{
							conversationId: 13778,
							disconImpact: 0.13456696,
							featureValue: 0.056,
						},
						{
							conversationId: 13781,
							disconImpact: -0.07650077,
							featureValue: 0.0565,
						},
						{
							conversationId: 13782,
							disconImpact: -0.17359099,
							featureValue: 0,
						},
						{
							conversationId: 13784,
							disconImpact: -0.20180964,
							featureValue: 0.0771666666666666,
						},
						{
							conversationId: 13787,
							disconImpact: -0.03432252,
							featureValue: 0,
						},
						{
							conversationId: 13791,
							disconImpact: -0.052534662,
							featureValue: 0.0699999999999999,
						},
						{
							conversationId: 13792,
							disconImpact: -0.8459856,
							featureValue: 0.1241666666666666,
						},
						{
							conversationId: 13793,
							disconImpact: -0.17807019,
							featureValue: 0,
						},
						{
							conversationId: 13794,
							disconImpact: -0.53482234,
							featureValue: 0.087,
						},
						{
							conversationId: 13796,
							disconImpact: -0.15285893,
							featureValue: 0.10975,
						},
						{
							conversationId: 13799,
							disconImpact: 0.25035003,
							featureValue: 0.03,
						},
						{
							conversationId: 13800,
							disconImpact: -0.1296029,
							featureValue: 0.0611666666666666,
						},
						{
							conversationId: 13804,
							disconImpact: -0.18281755,
							featureValue: 0,
						},
						{
							conversationId: 13805,
							disconImpact: -0.3095702,
							featureValue: 0,
						},
						{
							conversationId: 13806,
							disconImpact: -0.047721364,
							featureValue: 0,
						},
						{
							conversationId: 13807,
							disconImpact: 0.049597602,
							featureValue: 0.054,
						},
						{
							conversationId: 13808,
							disconImpact: -0.2766771,
							featureValue: 0.0815,
						},
						{
							conversationId: 13810,
							disconImpact: -0.3034527,
							featureValue: 0.0584999999999999,
						},
						{
							conversationId: 13812,
							disconImpact: -0.12732226,
							featureValue: 0,
						},
						{
							conversationId: 13813,
							disconImpact: -1.3803784,
							featureValue: 0.1321666666666666,
						},
						{
							conversationId: 13815,
							disconImpact: -0.05537183,
							featureValue: 0,
						},
						{
							conversationId: 13816,
							disconImpact: 0.1880905,
							featureValue: 0.073,
						},
						{
							conversationId: 13820,
							disconImpact: -0.48697498,
							featureValue: 0,
						},
						{
							conversationId: 13821,
							disconImpact: -0.33972025,
							featureValue: 0.06075,
						},
						{
							conversationId: 13822,
							disconImpact: -0.19554517,
							featureValue: 0,
						},
						{
							conversationId: 13823,
							disconImpact: -0.17854658,
							featureValue: 0,
						},
						{
							conversationId: 13824,
							disconImpact: -0.21203998,
							featureValue: 0,
						},
						{
							conversationId: 13826,
							disconImpact: 0.014571387,
							featureValue: 0.092,
						},
						{
							conversationId: 13827,
							disconImpact: 0.11576329,
							featureValue: 0.032,
						},
						{
							conversationId: 13828,
							disconImpact: 0.3154561,
							featureValue: 0.048,
						},
						{
							conversationId: 13829,
							disconImpact: -0.6957209,
							featureValue: 0.086,
						},
						{
							conversationId: 13830,
							disconImpact: -0.16947982,
							featureValue: 0.069,
						},
						{
							conversationId: 13831,
							disconImpact: -0.161547,
							featureValue: 0,
						},
						{
							conversationId: 13832,
							disconImpact: 0.068223625,
							featureValue: 0.063,
						},
						{
							conversationId: 13834,
							disconImpact: -0.20687485,
							featureValue: 0.078,
						},
						{
							conversationId: 13839,
							disconImpact: -0.15022749,
							featureValue: 0.0636666666666666,
						},
						{
							conversationId: 13840,
							disconImpact: -0.30584848,
							featureValue: 0.075,
						},
						{
							conversationId: 13841,
							disconImpact: 0.08283346,
							featureValue: 0,
						},
						{
							conversationId: 13842,
							disconImpact: -0.9876044,
							featureValue: 0.1725,
						},
						{
							conversationId: 13843,
							disconImpact: -0.079611234,
							featureValue: 0.0535555555555555,
						},
						{
							conversationId: 13844,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 13845,
							disconImpact: 0.09143843,
							featureValue: 0.07,
						},
						{
							conversationId: 13848,
							disconImpact: -0.3610448,
							featureValue: 0.087,
						},
						{
							conversationId: 13849,
							disconImpact: -0.26063457,
							featureValue: 0,
						},
						{
							conversationId: 13851,
							disconImpact: -0.11538739,
							featureValue: 0.057,
						},
						{
							conversationId: 13852,
							disconImpact: -0.1500683,
							featureValue: 0,
						},
						{
							conversationId: 13855,
							disconImpact: 0.38542506,
							featureValue: 0.0695,
						},
						{
							conversationId: 13856,
							disconImpact: 0.17239293,
							featureValue: 0.043,
						},
						{
							conversationId: 13859,
							disconImpact: -0.20316608,
							featureValue: 0,
						},
						{
							conversationId: 13860,
							disconImpact: -0.9965112,
							featureValue: 0.087,
						},
						{
							conversationId: 13861,
							disconImpact: -0.120269574,
							featureValue: 0,
						},
						{
							conversationId: 13862,
							disconImpact: 0.2159105,
							featureValue: 0.04,
						},
						{
							conversationId: 13866,
							disconImpact: -0.71792656,
							featureValue: 0.143,
						},
						{
							conversationId: 13867,
							disconImpact: -0.39486036,
							featureValue: 0.05475,
						},
						{
							conversationId: 13869,
							disconImpact: -1.4757086,
							featureValue: 0.162,
						},
						{
							conversationId: 13870,
							disconImpact: -0.510015,
							featureValue: 0.274,
						},
						{
							conversationId: 13872,
							disconImpact: -0.10688743,
							featureValue: 0.044,
						},
						{
							conversationId: 13874,
							disconImpact: 0.14884554,
							featureValue: 0.07025,
						},
						{
							conversationId: 13875,
							disconImpact: -0.83987415,
							featureValue: 0.1621666666666666,
						},
						{
							conversationId: 13876,
							disconImpact: -0.23452109,
							featureValue: 0.0887714285714285,
						},
						{
							conversationId: 13878,
							disconImpact: 0.001454901,
							featureValue: 0,
						},
						{
							conversationId: 13880,
							disconImpact: 0.7075407,
							featureValue: 0.0485,
						},
						{
							conversationId: 13883,
							disconImpact: -0.4140085,
							featureValue: 0.061,
						},
						{
							conversationId: 13884,
							disconImpact: 0.58099306,
							featureValue: 0.101,
						},
						{
							conversationId: 13885,
							disconImpact: -0.1792182,
							featureValue: 0.056,
						},
						{
							conversationId: 13886,
							disconImpact: 0.021509465,
							featureValue: 0,
						},
						{
							conversationId: 13887,
							disconImpact: 0.53624123,
							featureValue: 0.0483333333333333,
						},
						{
							conversationId: 13889,
							disconImpact: -0.06281734,
							featureValue: 0.057,
						},
						{
							conversationId: 13890,
							disconImpact: -0.08569656,
							featureValue: 0.0355,
						},
						{
							conversationId: 13891,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 13892,
							disconImpact: 0.06125172,
							featureValue: 0,
						},
						{
							conversationId: 13893,
							disconImpact: -0.35384873,
							featureValue: 0,
						},
						{
							conversationId: 13894,
							disconImpact: -0.03397432,
							featureValue: 0.06775,
						},
						{
							conversationId: 13895,
							disconImpact: -0.2142748,
							featureValue: 0,
						},
						{
							conversationId: 13897,
							disconImpact: -0.7148003,
							featureValue: 0.138,
						},
						{
							conversationId: 13898,
							disconImpact: -0.07443267,
							featureValue: 0,
						},
						{
							conversationId: 13900,
							disconImpact: -0.26772943,
							featureValue: 0,
						},
						{
							conversationId: 13902,
							disconImpact: -0.20280212,
							featureValue: 0,
						},
						{
							conversationId: 13905,
							disconImpact: 0.062479142,
							featureValue: 0,
						},
						{
							conversationId: 13906,
							disconImpact: -0.15167531,
							featureValue: 0,
						},
						{
							conversationId: 13909,
							disconImpact: -0.043281153,
							featureValue: 0,
						},
						{
							conversationId: 13910,
							disconImpact: 0.1849275,
							featureValue: 0.05,
						},
						{
							conversationId: 13912,
							disconImpact: -0.8440886,
							featureValue: 0.08,
						},
						{
							conversationId: 13915,
							disconImpact: -0.5800609,
							featureValue: 0.059,
						},
						{
							conversationId: 13917,
							disconImpact: 0.33088103,
							featureValue: 0.04625,
						},
						{
							conversationId: 13918,
							disconImpact: -0.1613676,
							featureValue: 0,
						},
						{
							conversationId: 13920,
							disconImpact: -0.10446005,
							featureValue: 0,
						},
						{
							conversationId: 13922,
							disconImpact: -0.4412253,
							featureValue: 0.0803928571428571,
						},
						{
							conversationId: 13924,
							disconImpact: -0.08668903,
							featureValue: 0,
						},
						{
							conversationId: 13926,
							disconImpact: -0.15880695,
							featureValue: 0.365,
						},
						{
							conversationId: 13927,
							disconImpact: -0.10263342,
							featureValue: 0,
						},
						{
							conversationId: 13928,
							disconImpact: -0.02725205,
							featureValue: 0.0405,
						},
						{
							conversationId: 13929,
							disconImpact: 0.26649347,
							featureValue: 0.0395,
						},
						{
							conversationId: 13931,
							disconImpact: -0.042089447,
							featureValue: 0,
						},
						{
							conversationId: 13932,
							disconImpact: 0.2977247,
							featureValue: 0.097,
						},
						{
							conversationId: 13933,
							disconImpact: -0.25601485,
							featureValue: 0.0824999999999999,
						},
						{
							conversationId: 13934,
							disconImpact: 0.2163229,
							featureValue: 0.0660833333333333,
						},
						{
							conversationId: 13936,
							disconImpact: 0.04245872,
							featureValue: 0,
						},
						{
							conversationId: 13937,
							disconImpact: 0.011599396,
							featureValue: 0,
						},
						{
							conversationId: 13943,
							disconImpact: -0.10169142,
							featureValue: 0,
						},
						{
							conversationId: 13945,
							disconImpact: -0.4810593,
							featureValue: 0.121,
						},
						{
							conversationId: 13946,
							disconImpact: -0.23750722,
							featureValue: 0.025,
						},
						{
							conversationId: 13947,
							disconImpact: 0.09288616,
							featureValue: 0,
						},
						{
							conversationId: 13949,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 13951,
							disconImpact: -0.6485136,
							featureValue: 0.236,
						},
						{
							conversationId: 13953,
							disconImpact: -1.0168623,
							featureValue: 0.1805,
						},
						{
							conversationId: 13956,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 13958,
							disconImpact: -0.23026417,
							featureValue: 0.058,
						},
						{
							conversationId: 13961,
							disconImpact: -0.10734478,
							featureValue: 0.058,
						},
						{
							conversationId: 13966,
							disconImpact: 0.1702254,
							featureValue: 0,
						},
						{
							conversationId: 13971,
							disconImpact: -0.43425882,
							featureValue: 0,
						},
						{
							conversationId: 13972,
							disconImpact: -0.55642337,
							featureValue: 0.08,
						},
						{
							conversationId: 13973,
							disconImpact: 0.20042078,
							featureValue: 0.089,
						},
						{
							conversationId: 13974,
							disconImpact: 0.15948243,
							featureValue: 0.067,
						},
						{
							conversationId: 13975,
							disconImpact: -0.39652762,
							featureValue: 0.082,
						},
						{
							conversationId: 13977,
							disconImpact: -0.8727561,
							featureValue: 0.1555,
						},
						{
							conversationId: 13979,
							disconImpact: -0.19349526,
							featureValue: 0,
						},
						{
							conversationId: 13980,
							disconImpact: 0.42443603,
							featureValue: 0.0665,
						},
						{
							conversationId: 13982,
							disconImpact: -0.430279,
							featureValue: 0.06025,
						},
						{
							conversationId: 13983,
							disconImpact: 0.09442663,
							featureValue: 0.087,
						},
						{
							conversationId: 13986,
							disconImpact: 0.036519293,
							featureValue: 0.064,
						},
						{
							conversationId: 13987,
							disconImpact: 0.24445802,
							featureValue: 0.065,
						},
						{
							conversationId: 13988,
							disconImpact: 0.253613,
							featureValue: 0.071,
						},
						{
							conversationId: 13996,
							disconImpact: -0.85727644,
							featureValue: 0.1325,
						},
						{
							conversationId: 13997,
							disconImpact: 0.2497718,
							featureValue: 0.044,
						},
						{
							conversationId: 13998,
							disconImpact: -0.17277937,
							featureValue: 0,
						},
						{
							conversationId: 14000,
							disconImpact: 0.55353147,
							featureValue: 0.1013333333333333,
						},
						{
							conversationId: 14005,
							disconImpact: -0.45949915,
							featureValue: 0.117,
						},
						{
							conversationId: 14008,
							disconImpact: -0.0010917801,
							featureValue: 0.085,
						},
						{
							conversationId: 14011,
							disconImpact: -0.7846336,
							featureValue: 0.1815,
						},
						{
							conversationId: 14013,
							disconImpact: -0.03524261,
							featureValue: 0,
						},
						{
							conversationId: 14014,
							disconImpact: -0.38434204,
							featureValue: 0,
						},
						{
							conversationId: 14015,
							disconImpact: 0.09013133,
							featureValue: 0,
						},
						{
							conversationId: 14016,
							disconImpact: 0.070274815,
							featureValue: 0.0734444444444444,
						},
						{
							conversationId: 14021,
							disconImpact: -0.9712953,
							featureValue: 0.1355,
						},
						{
							conversationId: 14025,
							disconImpact: 0.52546066,
							featureValue: 0.049,
						},
						{
							conversationId: 14027,
							disconImpact: 0.12749021,
							featureValue: 0.074,
						},
						{
							conversationId: 14030,
							disconImpact: -0.24727523,
							featureValue: 0,
						},
						{
							conversationId: 14031,
							disconImpact: 0.12183161,
							featureValue: 0.056,
						},
						{
							conversationId: 14032,
							disconImpact: -0.09622007,
							featureValue: 0,
						},
						{
							conversationId: 14035,
							disconImpact: 0.11475817,
							featureValue: 0.036,
						},
						{
							conversationId: 14041,
							disconImpact: 0.27611253,
							featureValue: 0.0561666666666666,
						},
						{
							conversationId: 14047,
							disconImpact: 0.15600379,
							featureValue: 0.0424999999999999,
						},
						{
							conversationId: 14048,
							disconImpact: 0.2223688,
							featureValue: 0.05,
						},
						{
							conversationId: 14049,
							disconImpact: 0.12640853,
							featureValue: 0.041,
						},
						{
							conversationId: 14053,
							disconImpact: 0.6020046,
							featureValue: 0.049,
						},
						{
							conversationId: 14055,
							disconImpact: 0.13843904,
							featureValue: 0.0606666666666666,
						},
						{
							conversationId: 14056,
							disconImpact: -0.41300517,
							featureValue: 0.028,
						},
						{
							conversationId: 14059,
							disconImpact: 0.5011477,
							featureValue: 0.095,
						},
						{
							conversationId: 14060,
							disconImpact: -1.0745167,
							featureValue: 0.1265,
						},
						{
							conversationId: 14061,
							disconImpact: -0.35393003,
							featureValue: 0,
						},
						{
							conversationId: 14062,
							disconImpact: -0.07328413,
							featureValue: 0.086,
						},
						{
							conversationId: 14064,
							disconImpact: 0.06311421,
							featureValue: 0.057,
						},
						{
							conversationId: 14071,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 14072,
							disconImpact: -0.89002305,
							featureValue: 0.161,
						},
						{
							conversationId: 14079,
							disconImpact: -0.35606048,
							featureValue: 0,
						},
						{
							conversationId: 14081,
							disconImpact: -0.118950926,
							featureValue: 0,
						},
						{
							conversationId: 14084,
							disconImpact: 0.12064422,
							featureValue: 0.0745,
						},
						{
							conversationId: 14086,
							disconImpact: -0.12244273,
							featureValue: 0.04,
						},
						{
							conversationId: 14091,
							disconImpact: -0.028048925,
							featureValue: 0.0579999999999999,
						},
						{
							conversationId: 14094,
							disconImpact: -0.23185724,
							featureValue: 0,
						},
						{
							conversationId: 14096,
							disconImpact: 0.29198638,
							featureValue: 0.0623333333333333,
						},
						{
							conversationId: 14101,
							disconImpact: -0.4504407,
							featureValue: 0.147,
						},
						{
							conversationId: 14102,
							disconImpact: 0.63763475,
							featureValue: 0.048,
						},
						{
							conversationId: 14106,
							disconImpact: -0.1118901,
							featureValue: 0,
						},
						{
							conversationId: 14107,
							disconImpact: -0.70750433,
							featureValue: 0.213,
						},
						{
							conversationId: 14110,
							disconImpact: -0.25620782,
							featureValue: 0.0605,
						},
						{
							conversationId: 14113,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 14119,
							disconImpact: -0.11192173,
							featureValue: 0.0583333333333333,
						},
						{
							conversationId: 14123,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 14124,
							disconImpact: -0.12218723,
							featureValue: 0,
						},
						{
							conversationId: 14125,
							disconImpact: 0.10583721,
							featureValue: 0.0676,
						},
						{
							conversationId: 14132,
							disconImpact: -0.22345543,
							featureValue: 0.057,
						},
						{
							conversationId: 14134,
							disconImpact: 0.12442715,
							featureValue: 0.056,
						},
						{
							conversationId: 14140,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 14147,
							disconImpact: -0.13329415,
							featureValue: 0,
						},
						{
							conversationId: 14160,
							disconImpact: -0.1804178,
							featureValue: 0,
						},
						{
							conversationId: 14186,
							disconImpact: 0.31072634,
							featureValue: 0.067,
						},
						{
							conversationId: 14252,
							disconImpact: 0.21286972,
							featureValue: 0.073,
						},
						{
							conversationId: 14283,
							disconImpact: -0.26278013,
							featureValue: 0.087,
						},
						{
							conversationId: 14318,
							disconImpact: -0.049710557,
							featureValue: 0,
						},
						{
							conversationId: 14379,
							disconImpact: 0.22410187,
							featureValue: 0.037,
						},
						{
							conversationId: 14856,
							disconImpact: 0.021509465,
							featureValue: 0,
						},
						{
							conversationId: 14857,
							disconImpact: 0.10140593,
							featureValue: 0.0409999999999999,
						},
						{
							conversationId: 14859,
							disconImpact: -0.006650522,
							featureValue: 0.06,
						},
						{
							conversationId: 14868,
							disconImpact: 0.044558108,
							featureValue: 0.0333333333333333,
						},
						{
							conversationId: 14871,
							disconImpact: -1.1543288,
							featureValue: 0.17175,
						},
						{
							conversationId: 14875,
							disconImpact: 0.21537244,
							featureValue: 0.1085,
						},
						{
							conversationId: 14877,
							disconImpact: 0.5152268,
							featureValue: 0.1014444444444444,
						},
						{
							conversationId: 14906,
							disconImpact: -0.4294532,
							featureValue: 0.072,
						},
						{
							conversationId: 14908,
							disconImpact: -0.8628989,
							featureValue: 0.1676666666666666,
						},
						{
							conversationId: 14910,
							disconImpact: -0.5475155,
							featureValue: 0.052,
						},
						{
							conversationId: 14915,
							disconImpact: 0.42035627,
							featureValue: 0.0993749999999999,
						},
						{
							conversationId: 14919,
							disconImpact: -0.06749224,
							featureValue: 0,
						},
						{
							conversationId: 14921,
							disconImpact: -0.061525494,
							featureValue: 0.07425,
						},
						{
							conversationId: 14924,
							disconImpact: -0.0067495974,
							featureValue: 0,
						},
						{
							conversationId: 14925,
							disconImpact: -0.049710557,
							featureValue: 0,
						},
						{
							conversationId: 14927,
							disconImpact: -0.12536368,
							featureValue: 0,
						},
						{
							conversationId: 14928,
							disconImpact: -0.082885906,
							featureValue: 0,
						},
						{
							conversationId: 14932,
							disconImpact: -0.061956603,
							featureValue: 0.0579999999999999,
						},
						{
							conversationId: 14934,
							disconImpact: -0.34856772,
							featureValue: 0.0886,
						},
						{
							conversationId: 14940,
							disconImpact: -0.20662685,
							featureValue: 0,
						},
						{
							conversationId: 14944,
							disconImpact: -0.12998034,
							featureValue: 0,
						},
						{
							conversationId: 14947,
							disconImpact: -0.027238123,
							featureValue: 0.069,
						},
						{
							conversationId: 14949,
							disconImpact: -0.30285463,
							featureValue: 0.058,
						},
						{
							conversationId: 14958,
							disconImpact: -0.17166758,
							featureValue: 0,
						},
						{
							conversationId: 14962,
							disconImpact: 0.11971661,
							featureValue: 0.058,
						},
						{
							conversationId: 14966,
							disconImpact: -1.4705312,
							featureValue: 0.163,
						},
						{
							conversationId: 14974,
							disconImpact: -0.10146353,
							featureValue: 0.089,
						},
						{
							conversationId: 14981,
							disconImpact: -0.24074,
							featureValue: 0,
						},
						{
							conversationId: 14992,
							disconImpact: -0.1337812,
							featureValue: 0,
						},
						{
							conversationId: 14993,
							disconImpact: 0.12302464,
							featureValue: 0.101,
						},
						{
							conversationId: 14995,
							disconImpact: -0.08322479,
							featureValue: 0.052,
						},
						{
							conversationId: 14996,
							disconImpact: -0.0006255163,
							featureValue: 0.0646666666666666,
						},
						{
							conversationId: 14998,
							disconImpact: -0.31413645,
							featureValue: 0.075,
						},
						{
							conversationId: 15001,
							disconImpact: 0.3590714,
							featureValue: 0.074,
						},
						{
							conversationId: 15013,
							disconImpact: -0.021014873,
							featureValue: 0.077,
						},
						{
							conversationId: 15016,
							disconImpact: -0.07245867,
							featureValue: 0,
						},
						{
							conversationId: 15017,
							disconImpact: 0.19413719,
							featureValue: 0.051,
						},
						{
							conversationId: 15018,
							disconImpact: 0.095156185,
							featureValue: 0.0745555555555555,
						},
						{
							conversationId: 15020,
							disconImpact: -0.0022636969,
							featureValue: 0.063,
						},
						{
							conversationId: 15029,
							disconImpact: 0.0064558033,
							featureValue: 0,
						},
						{
							conversationId: 15031,
							disconImpact: 0.46712613,
							featureValue: 0.071,
						},
						{
							conversationId: 15032,
							disconImpact: 0.49874336,
							featureValue: 0.043,
						},
						{
							conversationId: 15033,
							disconImpact: -1.2900755,
							featureValue: 0.12675,
						},
						{
							conversationId: 15035,
							disconImpact: -0.26085213,
							featureValue: 0.057,
						},
						{
							conversationId: 15036,
							disconImpact: -0.5307284,
							featureValue: 0.0538333333333333,
						},
						{
							conversationId: 15040,
							disconImpact: -0.07588432,
							featureValue: 0.0534090909090909,
						},
						{
							conversationId: 15041,
							disconImpact: -0.17013383,
							featureValue: 0,
						},
						{
							conversationId: 15043,
							disconImpact: -0.4892174,
							featureValue: 0.055,
						},
						{
							conversationId: 15044,
							disconImpact: -0.25387606,
							featureValue: 0.054,
						},
						{
							conversationId: 15045,
							disconImpact: -0.26254517,
							featureValue: 0.0715,
						},
						{
							conversationId: 15046,
							disconImpact: -0.17498375,
							featureValue: 0.0625,
						},
						{
							conversationId: 15049,
							disconImpact: -0.106601804,
							featureValue: 0.0376666666666666,
						},
						{
							conversationId: 15050,
							disconImpact: -0.020365011,
							featureValue: 0,
						},
						{
							conversationId: 15051,
							disconImpact: -0.42847547,
							featureValue: 0.055,
						},
						{
							conversationId: 15053,
							disconImpact: -0.11068517,
							featureValue: 0,
						},
						{
							conversationId: 15055,
							disconImpact: -0.21192914,
							featureValue: 0,
						},
						{
							conversationId: 15057,
							disconImpact: 0.24114083,
							featureValue: 0.0715,
						},
						{
							conversationId: 15060,
							disconImpact: -0.021263568,
							featureValue: 0.0603333333333333,
						},
						{
							conversationId: 15061,
							disconImpact: 0.9273379,
							featureValue: 0.0975,
						},
						{
							conversationId: 15063,
							disconImpact: -0.356724,
							featureValue: 0.053,
						},
						{
							conversationId: 15064,
							disconImpact: -0.019186178,
							featureValue: 0,
						},
						{
							conversationId: 15066,
							disconImpact: 0.38140234,
							featureValue: 0.0559999999999999,
						},
						{
							conversationId: 15067,
							disconImpact: -0.21297593,
							featureValue: 0,
						},
						{
							conversationId: 15069,
							disconImpact: -0.9990957,
							featureValue: 0.125,
						},
						{
							conversationId: 15072,
							disconImpact: -0.09657302,
							featureValue: 0.056,
						},
						{
							conversationId: 15080,
							disconImpact: 0.23470712,
							featureValue: 0.056,
						},
						{
							conversationId: 15083,
							disconImpact: -0.086496666,
							featureValue: 0.071,
						},
						{
							conversationId: 15086,
							disconImpact: 0.7264603,
							featureValue: 0.048,
						},
						{
							conversationId: 15088,
							disconImpact: -0.26586005,
							featureValue: 0,
						},
						{
							conversationId: 15090,
							disconImpact: 0.00860589,
							featureValue: 0,
						},
						{
							conversationId: 15099,
							disconImpact: 0.08428724,
							featureValue: 0.0936666666666666,
						},
						{
							conversationId: 15110,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 15114,
							disconImpact: 0.31304172,
							featureValue: 0.0494761904761904,
						},
						{
							conversationId: 15122,
							disconImpact: 0.21259612,
							featureValue: 0.0715,
						},
						{
							conversationId: 15127,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 15129,
							disconImpact: -0.27014574,
							featureValue: 0.053,
						},
						{
							conversationId: 15132,
							disconImpact: 0.16887547,
							featureValue: 0.059,
						},
						{
							conversationId: 15133,
							disconImpact: -0.037657082,
							featureValue: 0.0578333333333333,
						},
						{
							conversationId: 15145,
							disconImpact: -0.065865666,
							featureValue: 0,
						},
						{
							conversationId: 15151,
							disconImpact: -0.96940666,
							featureValue: 0.1691666666666666,
						},
						{
							conversationId: 15154,
							disconImpact: 0.24346712,
							featureValue: 0.045,
						},
						{
							conversationId: 15158,
							disconImpact: 0.5940468,
							featureValue: 0.048,
						},
						{
							conversationId: 15163,
							disconImpact: 0.73519635,
							featureValue: 0.0946666666666666,
						},
						{
							conversationId: 15164,
							disconImpact: -0.16181773,
							featureValue: 0,
						},
						{
							conversationId: 15169,
							disconImpact: -0.21356419,
							featureValue: 0.061,
						},
						{
							conversationId: 15174,
							disconImpact: -0.0010815039,
							featureValue: 0,
						},
						{
							conversationId: 15177,
							disconImpact: -0.5395851,
							featureValue: 0.0818333333333333,
						},
						{
							conversationId: 15178,
							disconImpact: -0.07566905,
							featureValue: 0,
						},
						{
							conversationId: 15179,
							disconImpact: -1.5448519,
							featureValue: 0.122,
						},
						{
							conversationId: 15184,
							disconImpact: -0.10446005,
							featureValue: 0,
						},
						{
							conversationId: 15194,
							disconImpact: 0.1323646,
							featureValue: 0.077,
						},
						{
							conversationId: 15197,
							disconImpact: -0.15117456,
							featureValue: 0.0854999999999999,
						},
						{
							conversationId: 15221,
							disconImpact: -0.012763087,
							featureValue: 0,
						},
						{
							conversationId: 15223,
							disconImpact: -0.5760989,
							featureValue: 0.25525,
						},
						{
							conversationId: 15228,
							disconImpact: 0.13976495,
							featureValue: 0.044,
						},
						{
							conversationId: 15232,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 15240,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 15243,
							disconImpact: -0.70797366,
							featureValue: 0.06,
						},
						{
							conversationId: 15248,
							disconImpact: -0.31447425,
							featureValue: 0.0618333333333333,
						},
						{
							conversationId: 15249,
							disconImpact: 0.24398276,
							featureValue: 0.062,
						},
						{
							conversationId: 15253,
							disconImpact: -0.45899332,
							featureValue: 0.086,
						},
						{
							conversationId: 15263,
							disconImpact: 0.12842059,
							featureValue: 0.0735,
						},
						{
							conversationId: 15267,
							disconImpact: -1.2540954,
							featureValue: 0.1379999999999999,
						},
						{
							conversationId: 15269,
							disconImpact: 0.06913271,
							featureValue: 0.0555,
						},
						{
							conversationId: 15270,
							disconImpact: -1.1509161,
							featureValue: 0.1378888888888889,
						},
						{
							conversationId: 15272,
							disconImpact: 0.29394484,
							featureValue: 0.1025,
						},
						{
							conversationId: 15273,
							disconImpact: -0.05146375,
							featureValue: 0,
						},
						{
							conversationId: 15280,
							disconImpact: -0.7128733,
							featureValue: 0.2,
						},
						{
							conversationId: 15287,
							disconImpact: 0.13285518,
							featureValue: 0.041,
						},
						{
							conversationId: 15288,
							disconImpact: -0.34265873,
							featureValue: 0.0635,
						},
						{
							conversationId: 15289,
							disconImpact: -0.6825895,
							featureValue: 0.123,
						},
						{
							conversationId: 15290,
							disconImpact: 0.6455392,
							featureValue: 0.046,
						},
						{
							conversationId: 15295,
							disconImpact: -0.24756086,
							featureValue: 0,
						},
						{
							conversationId: 15296,
							disconImpact: -0.23971486,
							featureValue: 0.091,
						},
						{
							conversationId: 15300,
							disconImpact: -0.28112617,
							featureValue: 0.08825,
						},
						{
							conversationId: 15305,
							disconImpact: -0.08638296,
							featureValue: 0,
						},
						{
							conversationId: 15307,
							disconImpact: 0.1528965,
							featureValue: 0.069,
						},
						{
							conversationId: 15313,
							disconImpact: -0.10631984,
							featureValue: 0,
						},
						{
							conversationId: 15317,
							disconImpact: -0.27370727,
							featureValue: 0.116,
						},
						{
							conversationId: 15318,
							disconImpact: 0.07827773,
							featureValue: 0.057,
						},
						{
							conversationId: 15329,
							disconImpact: -0.100294225,
							featureValue: 0,
						},
						{
							conversationId: 15333,
							disconImpact: -0.31200942,
							featureValue: 0.0596666666666666,
						},
						{
							conversationId: 15334,
							disconImpact: -0.25573123,
							featureValue: 0,
						},
						{
							conversationId: 15346,
							disconImpact: -0.022855885,
							featureValue: 0,
						},
						{
							conversationId: 15347,
							disconImpact: -0.010277649,
							featureValue: 0.0560833333333333,
						},
						{
							conversationId: 15350,
							disconImpact: -1.1687027,
							featureValue: 0.1319999999999999,
						},
						{
							conversationId: 15352,
							disconImpact: -0.15668035,
							featureValue: 0.026,
						},
						{
							conversationId: 15353,
							disconImpact: -0.6259591,
							featureValue: 0.0545,
						},
						{
							conversationId: 15356,
							disconImpact: -0.69538933,
							featureValue: 0.0814999999999999,
						},
						{
							conversationId: 15357,
							disconImpact: 0.45933083,
							featureValue: 0.046,
						},
						{
							conversationId: 15359,
							disconImpact: 0.05429834,
							featureValue: 0.043,
						},
						{
							conversationId: 15360,
							disconImpact: -0.63185066,
							featureValue: 0.0535,
						},
						{
							conversationId: 15361,
							disconImpact: 0.03904439,
							featureValue: 0,
						},
						{
							conversationId: 15365,
							disconImpact: 0.20897914,
							featureValue: 0.116,
						},
						{
							conversationId: 15368,
							disconImpact: -0.26107988,
							featureValue: 0.081,
						},
						{
							conversationId: 15369,
							disconImpact: -0.14258854,
							featureValue: 0,
						},
						{
							conversationId: 15372,
							disconImpact: -0.21989375,
							featureValue: 0,
						},
						{
							conversationId: 15379,
							disconImpact: -0.52793336,
							featureValue: 0.058,
						},
						{
							conversationId: 15380,
							disconImpact: -0.26282266,
							featureValue: 0.075,
						},
						{
							conversationId: 15381,
							disconImpact: -0.18929535,
							featureValue: 0,
						},
						{
							conversationId: 15385,
							disconImpact: 0.022176977,
							featureValue: 0.0904,
						},
						{
							conversationId: 15389,
							disconImpact: -0.02571165,
							featureValue: 0,
						},
						{
							conversationId: 15391,
							disconImpact: -0.19551665,
							featureValue: 0,
						},
						{
							conversationId: 15395,
							disconImpact: 0.12390382,
							featureValue: 0.066,
						},
						{
							conversationId: 15398,
							disconImpact: 0.043845072,
							featureValue: 0,
						},
						{
							conversationId: 15401,
							disconImpact: -0.39491642,
							featureValue: 0.0916666666666666,
						},
						{
							conversationId: 15405,
							disconImpact: -0.20725213,
							featureValue: 0,
						},
						{
							conversationId: 15407,
							disconImpact: -0.28829634,
							featureValue: 0,
						},
						{
							conversationId: 15408,
							disconImpact: -0.3133476,
							featureValue: 0.076,
						},
						{
							conversationId: 15410,
							disconImpact: -0.12732226,
							featureValue: 0,
						},
						{
							conversationId: 15412,
							disconImpact: -0.20845732,
							featureValue: 0,
						},
						{
							conversationId: 15413,
							disconImpact: -0.50335515,
							featureValue: 0.09,
						},
						{
							conversationId: 15414,
							disconImpact: -0.07478857,
							featureValue: 0,
						},
						{
							conversationId: 15419,
							disconImpact: -0.1620582,
							featureValue: 0,
						},
						{
							conversationId: 15423,
							disconImpact: -0.39751014,
							featureValue: 0.06,
						},
						{
							conversationId: 15424,
							disconImpact: -0.15162177,
							featureValue: 0,
						},
						{
							conversationId: 15427,
							disconImpact: -0.51322603,
							featureValue: 0.086,
						},
						{
							conversationId: 15429,
							disconImpact: -0.25986898,
							featureValue: 0.062,
						},
						{
							conversationId: 15434,
							disconImpact: 0.2326341,
							featureValue: 0.0665,
						},
						{
							conversationId: 15436,
							disconImpact: -0.1184631,
							featureValue: 0.0556666666666666,
						},
						{
							conversationId: 15439,
							disconImpact: 0.33017918,
							featureValue: 0.0728333333333333,
						},
						{
							conversationId: 15441,
							disconImpact: -0.18436131,
							featureValue: 0,
						},
						{
							conversationId: 15442,
							disconImpact: 0.08853183,
							featureValue: 0.0703999999999999,
						},
						{
							conversationId: 15444,
							disconImpact: 0.24739584,
							featureValue: 0.0455,
						},
						{
							conversationId: 15450,
							disconImpact: -0.05873197,
							featureValue: 0,
						},
						{
							conversationId: 15451,
							disconImpact: 0.0044364566,
							featureValue: 0.051,
						},
						{
							conversationId: 15452,
							disconImpact: 0.38518173,
							featureValue: 0.0375,
						},
						{
							conversationId: 15454,
							disconImpact: -0.09950581,
							featureValue: 0,
						},
						{
							conversationId: 15459,
							disconImpact: 0.667827,
							featureValue: 0.046,
						},
						{
							conversationId: 15463,
							disconImpact: 0.37696442,
							featureValue: 0.066,
						},
						{
							conversationId: 15466,
							disconImpact: -0.4904262,
							featureValue: 0.0606666666666666,
						},
						{
							conversationId: 15472,
							disconImpact: -0.102726914,
							featureValue: 0,
						},
						{
							conversationId: 15480,
							disconImpact: -0.022546265,
							featureValue: 0,
						},
						{
							conversationId: 15481,
							disconImpact: 0.31332427,
							featureValue: 0.08875,
						},
						{
							conversationId: 15485,
							disconImpact: 0.045617916,
							featureValue: 0.06675,
						},
						{
							conversationId: 15491,
							disconImpact: 0.056466915,
							featureValue: 0.06475,
						},
						{
							conversationId: 15493,
							disconImpact: -0.10846934,
							featureValue: 0,
						},
						{
							conversationId: 15494,
							disconImpact: 0.06378142,
							featureValue: 0,
						},
						{
							conversationId: 15497,
							disconImpact: 0.20495899,
							featureValue: 0,
						},
						{
							conversationId: 15503,
							disconImpact: -0.13596185,
							featureValue: 0,
						},
						{
							conversationId: 15507,
							disconImpact: 0.18188423,
							featureValue: 0.072,
						},
						{
							conversationId: 15515,
							disconImpact: -0.11570849,
							featureValue: 0,
						},
						{
							conversationId: 15516,
							disconImpact: 0.19998634,
							featureValue: 0.0661333333333333,
						},
						{
							conversationId: 15517,
							disconImpact: -0.05795517,
							featureValue: 0.0564999999999999,
						},
						{
							conversationId: 15520,
							disconImpact: -0.14723188,
							featureValue: 0.0888333333333333,
						},
						{
							conversationId: 15525,
							disconImpact: -0.2765075,
							featureValue: 0,
						},
						{
							conversationId: 15529,
							disconImpact: 0.27290967,
							featureValue: 0.0289999999999999,
						},
						{
							conversationId: 15537,
							disconImpact: -0.49489567,
							featureValue: 0.06,
						},
						{
							conversationId: 15538,
							disconImpact: 0.29670286,
							featureValue: 0.065,
						},
						{
							conversationId: 15541,
							disconImpact: -0.07319802,
							featureValue: 0.04,
						},
						{
							conversationId: 15543,
							disconImpact: -0.609804,
							featureValue: 0.146,
						},
						{
							conversationId: 15547,
							disconImpact: -0.13774519,
							featureValue: 0.0777777777777777,
						},
						{
							conversationId: 15551,
							disconImpact: -0.043018676,
							featureValue: 0.069,
						},
						{
							conversationId: 15558,
							disconImpact: 0.3617085,
							featureValue: 0.095,
						},
						{
							conversationId: 15564,
							disconImpact: -0.19707394,
							featureValue: 0.08675,
						},
						{
							conversationId: 15565,
							disconImpact: -0.33710468,
							featureValue: 0.064,
						},
						{
							conversationId: 15567,
							disconImpact: -0.39569208,
							featureValue: 0.061,
						},
						{
							conversationId: 15569,
							disconImpact: -0.1050033,
							featureValue: 0.067,
						},
						{
							conversationId: 15570,
							disconImpact: -0.24130265,
							featureValue: 0.0565,
						},
						{
							conversationId: 15571,
							disconImpact: 0.07579286,
							featureValue: 0,
						},
						{
							conversationId: 15572,
							disconImpact: -0.21927312,
							featureValue: 0,
						},
						{
							conversationId: 15573,
							disconImpact: -0.26329768,
							featureValue: 0.054,
						},
						{
							conversationId: 15577,
							disconImpact: -0.4579628,
							featureValue: 0.0857999999999999,
						},
						{
							conversationId: 15578,
							disconImpact: -0.2289896,
							featureValue: 0.0595,
						},
						{
							conversationId: 15580,
							disconImpact: -0.120467715,
							featureValue: 0,
						},
						{
							conversationId: 15582,
							disconImpact: -0.04507029,
							featureValue: 0,
						},
						{
							conversationId: 15583,
							disconImpact: -0.0027094185,
							featureValue: 0.024,
						},
						{
							conversationId: 15588,
							disconImpact: -0.10446005,
							featureValue: 0,
						},
						{
							conversationId: 15590,
							disconImpact: -0.1261623,
							featureValue: 0.062,
						},
						{
							conversationId: 15599,
							disconImpact: -0.45519313,
							featureValue: 0.363,
						},
						{
							conversationId: 15600,
							disconImpact: 0.12770316,
							featureValue: 0.0765,
						},
						{
							conversationId: 15604,
							disconImpact: 0.038543127,
							featureValue: 0.0632777777777777,
						},
						{
							conversationId: 15610,
							disconImpact: -0.26429164,
							featureValue: 0,
						},
						{
							conversationId: 15611,
							disconImpact: -0.21131602,
							featureValue: 0.055,
						},
						{
							conversationId: 15615,
							disconImpact: 0.0307631,
							featureValue: 0.0559999999999999,
						},
						{
							conversationId: 15618,
							disconImpact: -0.15883723,
							featureValue: 0,
						},
						{
							conversationId: 15623,
							disconImpact: -0.03152114,
							featureValue: 0.059,
						},
						{
							conversationId: 15624,
							disconImpact: 0.56741434,
							featureValue: 0.044,
						},
						{
							conversationId: 15628,
							disconImpact: -0.34070536,
							featureValue: 0.0613333333333333,
						},
						{
							conversationId: 15631,
							disconImpact: 0.2766301,
							featureValue: 0.04975,
						},
						{
							conversationId: 15632,
							disconImpact: -0.19363195,
							featureValue: 0.09,
						},
						{
							conversationId: 15636,
							disconImpact: -0.23585843,
							featureValue: 0.075,
						},
						{
							conversationId: 15637,
							disconImpact: -0.2266812,
							featureValue: 0,
						},
						{
							conversationId: 15647,
							disconImpact: 0.4345254,
							featureValue: 0.102,
						},
						{
							conversationId: 15648,
							disconImpact: -0.062099617,
							featureValue: 0,
						},
						{
							conversationId: 15652,
							disconImpact: -0.015017755,
							featureValue: 0.057,
						},
						{
							conversationId: 15653,
							disconImpact: -0.6821744,
							featureValue: 0.152,
						},
						{
							conversationId: 15659,
							disconImpact: 0.01684518,
							featureValue: 0.06475,
						},
						{
							conversationId: 15665,
							disconImpact: 0.056978557,
							featureValue: 0.0626111111111111,
						},
						{
							conversationId: 15667,
							disconImpact: -0.45877254,
							featureValue: 0.0833333333333333,
						},
						{
							conversationId: 15676,
							disconImpact: -0.10276952,
							featureValue: 0,
						},
						{
							conversationId: 15677,
							disconImpact: 0.74870163,
							featureValue: 0.047,
						},
						{
							conversationId: 15680,
							disconImpact: -0.29105207,
							featureValue: 0.0576666666666666,
						},
						{
							conversationId: 15681,
							disconImpact: 0.24654591,
							featureValue: 0.0643333333333333,
						},
						{
							conversationId: 15683,
							disconImpact: 0.14096962,
							featureValue: 0.066,
						},
						{
							conversationId: 15685,
							disconImpact: -0.22172233,
							featureValue: 0,
						},
						{
							conversationId: 15689,
							disconImpact: -0.27168593,
							featureValue: 0,
						},
						{
							conversationId: 15690,
							disconImpact: -1.151349,
							featureValue: 0.1452916666666666,
						},
						{
							conversationId: 15691,
							disconImpact: -0.25424534,
							featureValue: 0.018,
						},
						{
							conversationId: 15696,
							disconImpact: -0.012763087,
							featureValue: 0,
						},
						{
							conversationId: 15703,
							disconImpact: 0.17939758,
							featureValue: 0.039,
						},
						{
							conversationId: 15723,
							disconImpact: -0.010454571,
							featureValue: 0,
						},
						{
							conversationId: 15727,
							disconImpact: -0.104630224,
							featureValue: 0.0695833333333333,
						},
						{
							conversationId: 15729,
							disconImpact: -0.7304093,
							featureValue: 0.136125,
						},
						{
							conversationId: 15730,
							disconImpact: -0.23014463,
							featureValue: 0.057,
						},
						{
							conversationId: 15733,
							disconImpact: -1.2115426,
							featureValue: 0.19275,
						},
						{
							conversationId: 15735,
							disconImpact: -0.32657465,
							featureValue: 0.0626666666666666,
						},
						{
							conversationId: 15736,
							disconImpact: -0.6158383,
							featureValue: 0.08225,
						},
						{
							conversationId: 15737,
							disconImpact: -0.2807675,
							featureValue: 0.0786,
						},
						{
							conversationId: 15740,
							disconImpact: -0.053868406,
							featureValue: 0.0695,
						},
						{
							conversationId: 15743,
							disconImpact: 0.14706479,
							featureValue: 0.0527222222222222,
						},
						{
							conversationId: 15744,
							disconImpact: -0.1287836,
							featureValue: 0.077,
						},
						{
							conversationId: 15749,
							disconImpact: -0.11540693,
							featureValue: 0.0574999999999999,
						},
						{
							conversationId: 15750,
							disconImpact: -0.91105837,
							featureValue: 0.139,
						},
						{
							conversationId: 15752,
							disconImpact: 0.014955663,
							featureValue: 0,
						},
						{
							conversationId: 15753,
							disconImpact: -0.45254532,
							featureValue: 0.06,
						},
						{
							conversationId: 15760,
							disconImpact: -0.046974465,
							featureValue: 0.071,
						},
						{
							conversationId: 15763,
							disconImpact: -0.8604895,
							featureValue: 0.12,
						},
						{
							conversationId: 15766,
							disconImpact: -0.023284595,
							featureValue: 0.065,
						},
						{
							conversationId: 15767,
							disconImpact: -0.54090756,
							featureValue: 0.0858,
						},
						{
							conversationId: 15776,
							disconImpact: -0.30259088,
							featureValue: 0.0797,
						},
						{
							conversationId: 15783,
							disconImpact: -0.012763087,
							featureValue: 0,
						},
						{
							conversationId: 15791,
							disconImpact: 0.26814386,
							featureValue: 0.097,
						},
						{
							conversationId: 15793,
							disconImpact: 0.18285728,
							featureValue: 0.108,
						},
						{
							conversationId: 15794,
							disconImpact: -0.397049,
							featureValue: 0.0625833333333333,
						},
						{
							conversationId: 15801,
							disconImpact: 0.34986234,
							featureValue: 0.093,
						},
						{
							conversationId: 15803,
							disconImpact: -0.15510243,
							featureValue: 0,
						},
						{
							conversationId: 15805,
							disconImpact: -0.047733065,
							featureValue: 0.068,
						},
						{
							conversationId: 15809,
							disconImpact: 0.62041265,
							featureValue: 0.09775,
						},
						{
							conversationId: 15811,
							disconImpact: 0.55910593,
							featureValue: 0.045,
						},
						{
							conversationId: 15814,
							disconImpact: 0.108271465,
							featureValue: 0.091,
						},
						{
							conversationId: 15820,
							disconImpact: -0.055142514,
							featureValue: 0,
						},
						{
							conversationId: 15825,
							disconImpact: -0.1942992,
							featureValue: 0.061,
						},
						{
							conversationId: 15827,
							disconImpact: 0.07585623,
							featureValue: 0,
						},
						{
							conversationId: 15828,
							disconImpact: 0.4171739,
							featureValue: 0.0705,
						},
						{
							conversationId: 15833,
							disconImpact: -0.08744438,
							featureValue: 0,
						},
						{
							conversationId: 15836,
							disconImpact: -0.49619517,
							featureValue: 0.054,
						},
						{
							conversationId: 15839,
							disconImpact: -0.3793024,
							featureValue: 0.0821249999999999,
						},
						{
							conversationId: 15842,
							disconImpact: -0.7167937,
							featureValue: 0.08,
						},
						{
							conversationId: 15851,
							disconImpact: -0.39403194,
							featureValue: 0.06,
						},
						{
							conversationId: 15852,
							disconImpact: 0.21549681,
							featureValue: 0.0965,
						},
						{
							conversationId: 15855,
							disconImpact: -0.01968019,
							featureValue: 0.085825,
						},
						{
							conversationId: 15856,
							disconImpact: 0.054251198,
							featureValue: 0.04,
						},
						{
							conversationId: 15863,
							disconImpact: 0.20006767,
							featureValue: 0.067,
						},
						{
							conversationId: 15866,
							disconImpact: 0.23091047,
							featureValue: 0.068,
						},
						{
							conversationId: 15871,
							disconImpact: -0.22023539,
							featureValue: 0.06075,
						},
						{
							conversationId: 15873,
							disconImpact: -0.41144922,
							featureValue: 0,
						},
						{
							conversationId: 15874,
							disconImpact: -0.21881261,
							featureValue: 0,
						},
						{
							conversationId: 15876,
							disconImpact: 0.30796525,
							featureValue: 0.0525,
						},
						{
							conversationId: 15878,
							disconImpact: -0.17822203,
							featureValue: 0,
						},
						{
							conversationId: 15880,
							disconImpact: -0.07639252,
							featureValue: 0,
						},
						{
							conversationId: 15881,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 15884,
							disconImpact: 0.23840506,
							featureValue: 0.1013333333333333,
						},
						{
							conversationId: 15885,
							disconImpact: 0.47322738,
							featureValue: 0.049,
						},
						{
							conversationId: 15892,
							disconImpact: 0.08814559,
							featureValue: 0.0655999999999999,
						},
						{
							conversationId: 15895,
							disconImpact: -0.04754242,
							featureValue: 0,
						},
						{
							conversationId: 15898,
							disconImpact: 0.30441755,
							featureValue: 0.0991666666666666,
						},
						{
							conversationId: 15904,
							disconImpact: -0.6787253,
							featureValue: 0.0825,
						},
						{
							conversationId: 15907,
							disconImpact: -0.733613,
							featureValue: 0.155,
						},
						{
							conversationId: 15911,
							disconImpact: 0.5901913,
							featureValue: 0.103,
						},
						{
							conversationId: 15914,
							disconImpact: -0.93179005,
							featureValue: 0.1379999999999999,
						},
						{
							conversationId: 15917,
							disconImpact: 0.0019890405,
							featureValue: 0,
						},
						{
							conversationId: 15918,
							disconImpact: 0.45154095,
							featureValue: 0.0955,
						},
						{
							conversationId: 15921,
							disconImpact: -0.5845764,
							featureValue: 0.0605,
						},
						{
							conversationId: 15927,
							disconImpact: 0.49316862,
							featureValue: 0.048,
						},
						{
							conversationId: 15930,
							disconImpact: 0.5098395,
							featureValue: 0.093,
						},
						{
							conversationId: 15938,
							disconImpact: -0.7977169,
							featureValue: 0.209,
						},
						{
							conversationId: 15942,
							disconImpact: -0.08672525,
							featureValue: 0,
						},
						{
							conversationId: 15945,
							disconImpact: 0.07827318,
							featureValue: 0,
						},
						{
							conversationId: 15952,
							disconImpact: -0.14170077,
							featureValue: 0.0715,
						},
						{
							conversationId: 15955,
							disconImpact: 0.37668785,
							featureValue: 0.041,
						},
						{
							conversationId: 15960,
							disconImpact: 0.03334282,
							featureValue: 0.0502499999999999,
						},
						{
							conversationId: 15963,
							disconImpact: -0.19541539,
							featureValue: 0,
						},
						{
							conversationId: 15965,
							disconImpact: 0.022112243,
							featureValue: 0,
						},
						{
							conversationId: 15967,
							disconImpact: 0.13636282,
							featureValue: 0.0693333333333333,
						},
						{
							conversationId: 15970,
							disconImpact: -0.054933857,
							featureValue: 0,
						},
						{
							conversationId: 15973,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 15979,
							disconImpact: 0.03667881,
							featureValue: 0.0916666666666666,
						},
						{
							conversationId: 15984,
							disconImpact: -0.2067814,
							featureValue: 0.0661666666666666,
						},
						{
							conversationId: 15999,
							disconImpact: -0.048338987,
							featureValue: 0,
						},
						{
							conversationId: 16003,
							disconImpact: -0.027744954,
							featureValue: 0.062,
						},
						{
							conversationId: 16008,
							disconImpact: 0.2074905,
							featureValue: 0.095,
						},
						{
							conversationId: 16011,
							disconImpact: -0.043281153,
							featureValue: 0,
						},
						{
							conversationId: 16020,
							disconImpact: -0.06589621,
							featureValue: 0,
						},
						{
							conversationId: 16021,
							disconImpact: -0.105224445,
							featureValue: 0,
						},
						{
							conversationId: 16025,
							disconImpact: 0.16854133,
							featureValue: 0.067,
						},
						{
							conversationId: 16026,
							disconImpact: -0.12382495,
							featureValue: 0,
						},
						{
							conversationId: 16032,
							disconImpact: -0.04507029,
							featureValue: 0,
						},
						{
							conversationId: 16035,
							disconImpact: -0.26353967,
							featureValue: 0,
						},
						{
							conversationId: 16043,
							disconImpact: -0.09476147,
							featureValue: 0.0775833333333333,
						},
						{
							conversationId: 16046,
							disconImpact: -0.00465264,
							featureValue: 0.0643333333333333,
						},
						{
							conversationId: 16052,
							disconImpact: 0.0010896116,
							featureValue: 0,
						},
						{
							conversationId: 16065,
							disconImpact: -0.50404537,
							featureValue: 0.0825,
						},
						{
							conversationId: 16071,
							disconImpact: -1.1706715,
							featureValue: 0.1858333333333333,
						},
						{
							conversationId: 16082,
							disconImpact: -0.09150174,
							featureValue: 0.063,
						},
						{
							conversationId: 16096,
							disconImpact: -0.18339318,
							featureValue: 0,
						},
						{
							conversationId: 16098,
							disconImpact: 0.41237593,
							featureValue: 0.101,
						},
						{
							conversationId: 16101,
							disconImpact: 0.16579698,
							featureValue: 0.089,
						},
						{
							conversationId: 16104,
							disconImpact: -0.16916947,
							featureValue: 0.027,
						},
						{
							conversationId: 16113,
							disconImpact: -0.15611371,
							featureValue: 0,
						},
					],
				},
			},
		],
	},
	{
		dummyDataDriversSummary: [
			{
				numberOfPatients: 84,
				numberOfSearchResults: 20,
				searchResults: [
					{
						name: "Transcript from 01 Nov 2021 [15:47:45]",
						tags: ["Inbound", "Discontinued", "42", "Neutral", "Others"],
						storiesAdded: [],
						source: 1,
						dialogueNo: 40,
						callId: "97F9699980E0415EB33D5D7634F648B4",
						callDate: "2021-11-01T00:00:00",
						callTime: "15:47:45",
						dialogueAppearedAt: 466.45,
						dialogueLength: 36.57,
						transcriptLength: 728.5,
						s3path: "kural/processed_files/97F9699980E0415EB33D5D7634F648B4.json",
						addedToStory: "false",
						score: 0.310449600219727,
						patientId: 8665,
						speaker: "Agent",
						context: [
							{
								dialogueNo: 38,
								text: "you've had migraines your whole life.",
								speaker: "Agent",
							},
							{
								dialogueNo: 39,
								text: "yes.",
								speaker: "Patient",
							},
							{
								dialogueNo: 40,
								text: "so you had these um even before starting on on vumerity okay, since you're still having issues with the headaches, i would suggest you give doctor nico's office a call um and let him know that even though you are, you know, taking your vumerity you're on your um m. s medication, you're having headaches still that are not resolved, he might be able to, you know, advise you or give you, you know something to help control the headaches.",
								speaker: "Agent",
							},
							{
								dialogueNo: 41,
								text: "okay.",
								speaker: "Patient",
							},
							{
								dialogueNo: 42,
								text: "now do you feel that you're tolerating the vumerity since you've been on vumerity uh huh.",
								speaker: "Agent",
							},
						],
						elasticSearchId: "97F9699980E0415EB33D5D7634F648B4_40",
						transcripts: [
							{
								speaker: "Agent",
								text: "you've had migraines your whole life.",
								dialogueNo: 38,
							},
							{
								speaker: "Patient",
								text: "yes.",
								dialogueNo: 39,
							},
							{
								speaker: "Agent",
								text: "so you had these um even before starting on on vumerity okay, since you're still having issues with the headaches, i would suggest you give doctor nico's office a call um and let him know that even though you are, you know, taking your vumerity you're on your um m. s medication, you're having headaches still that are not resolved, he might be able to, you know, advise you or give you, you know something to help control the headaches.",
								dialogueNo: 40,
							},
							{
								speaker: "Patient",
								text: "okay.",
								dialogueNo: 41,
							},
							{
								speaker: "Agent",
								text: "now do you feel that you're tolerating the vumerity since you've been on vumerity uh huh.",
								dialogueNo: 42,
							},
						],
						callTypeId: "1",
						storyDialogues: [],
						therapyStatusId: "2",
						notAddedStories: [],
					},
					{
						name: "Transcript from 05 Nov 2021 [12:21:44]",
						tags: ["Outbound", "Discontinued", "30", "Negative", "Others"],
						storiesAdded: [],
						source: 1,
						dialogueNo: 60,
						callId: "ACEC79FADC0946318DDDADC13129B0EF",
						callDate: "2021-11-05T00:00:00",
						callTime: "12:21:44",
						dialogueAppearedAt: 631.74,
						dialogueLength: 21.1,
						transcriptLength: 899.8,
						s3path: "kural/processed_files/ACEC79FADC0946318DDDADC13129B0EF.json",
						addedToStory: "false",
						score: 0.299056351184845,
						patientId: 15127,
						speaker: "Patient",
						context: [
							{
								dialogueNo: 58,
								text: "like i said a week ago monday and um i was better in about three days it took you know, they said it would take you know at least 12 hours to start getting it out of my system.",
								speaker: "Patient",
							},
							{
								dialogueNo: 59,
								text: "right i hear you okay alrighty wow mhm oh wow right yeah no i hear you, i hear you that makes sense to me.",
								speaker: "Agent",
							},
							{
								dialogueNo: 60,
								text: "but by the middle of last week i was the shaking was gone, the headache was gone, i had headaches with um a vumerity that i did not have with the tecfidera tecfidera for some reason, but i had headaches every day and they stopped and the shaking stop in both counts. so i don't know what else it would be.",
								speaker: "Patient",
							},
							{
								dialogueNo: 61,
								text: "um but so what actually no you did actually you did increase to the maintenance dose correct ?",
								speaker: "Agent",
							},
							{
								dialogueNo: 62,
								text: "it had to be no, i i increased it.",
								speaker: "Patient",
							},
						],
						elasticSearchId: "ACEC79FADC0946318DDDADC13129B0EF_60",
						transcripts: [
							{
								speaker: "Patient",
								text: "like i said a week ago monday and um i was better in about three days it took you know, they said it would take you know at least 12 hours to start getting it out of my system.",
								dialogueNo: 58,
							},
							{
								speaker: "Agent",
								text: "right i hear you okay alrighty wow mhm oh wow right yeah no i hear you, i hear you that makes sense to me.",
								dialogueNo: 59,
							},
							{
								speaker: "Patient",
								text: "but by the middle of last week i was the shaking was gone, the headache was gone, i had headaches with um a vumerity that i did not have with the tecfidera tecfidera for some reason, but i had headaches every day and they stopped and the shaking stop in both counts. so i don't know what else it would be.",
								dialogueNo: 60,
							},
							{
								speaker: "Agent",
								text: "um but so what actually no you did actually you did increase to the maintenance dose correct ?",
								dialogueNo: 61,
							},
							{
								speaker: "Patient",
								text: "it had to be no, i i increased it.",
								dialogueNo: 62,
							},
						],
						callTypeId: "2",
						storyDialogues: [],
						therapyStatusId: "2",
						notAddedStories: [],
					},
					{
						searchId: 1,
						name: "Transcript from 25 May 2022 [20:15:09]",
						tags: ["Positive", "Outbound", "UNKNOWN"],
						dialogueNo: 4,
						callId: "O1388T9ZZ5BCLGTD",
						callDate: "2022-05-25",
						callTime: "20:15:09",
						dialogueAppearedAt: 16.1,
						dialogueLength: 15.18,
						transcriptLength: 75.44,
						patientId: -1,
						speaker: "Patient",
						storiesAdded: [],
						addedToStory: "false",
						context: [
							{
								dialogueNo: 2,
								text: "i'm just so frustrated. my insurance company denied my claim for lectrazine.",
								speaker: "Patient",
							},
							{
								dialogueNo: 3,
								text: "i'm sorry to hear that. can you tell me what happened ?",
								speaker: "Agent",
							},
							{
								dialogueNo: 4,
								text: "i've been taking lectrazine for my migraines for years, and my insurance has always covered it. but now they're saying it's not medically necessary and they won't pay for it.",
								speaker: "Patient",
							},
							{
								dialogueNo: 5,
								text: "that doesn't sound right. let me see if i can help you figure this out.",
								speaker: "Agent",
							},
							{
								dialogueNo: 6,
								text: "i just don't understand why they would deny it. it's not like it's a luxury medication or anything.",
								speaker: "Patient",
							},
						],
						s3path: "kural/processed_files/O1388T9ZZ5BCLGTD.json",
						elasticSearchId: "O1388T9ZZ5BCLGTD_4",
						transcripts: [
							{
								speaker: "Patient",
								text: "i'm just so frustrated. my insurance company denied my claim for lectrazine.",
								highLights: ["lectrazine"],
								dialogueNo: 2,
							},
							{
								speaker: "Agent",
								text: "i'm sorry to hear that. can you tell me what happened ?",
								highLights: [],
								dialogueNo: 3,
							},
							{
								speaker: "Patient",
								text: "i've been taking lectrazine for my migraines for years, and my insurance has always covered it. but now they're saying it's not medically necessary and they won't pay for it.",
								highLights: ["lectrazine"],
								dialogueNo: 4,
							},
							{
								speaker: "Agent",
								text: "that doesn't sound right. let me see if i can help you figure this out.",
								highLights: [],
								dialogueNo: 5,
							},
							{
								speaker: "Patient",
								text: "i just don't understand why they would deny it. it's not like it's a luxury medication or anything.",
								highLights: [],
								dialogueNo: 6,
							},
						],
						callTypeId: 2,
						therapyStatusId: -1,
					},
					{
						searchId: 2,
						name: "Transcript from 09 May 2022 [21:20:36]",
						tags: ["Negative", "Common Side Effects", "Outbound", "UNKNOWN"],
						dialogueNo: 3,
						callId: "WW8PUBYMYJOW4S8N",
						callDate: "2022-05-09",
						callTime: "21:20:36",
						dialogueAppearedAt: 39.56,
						dialogueLength: 11.5,
						transcriptLength: 118.68,
						patientId: -1,
						speaker: "Patient",
						storiesAdded: [],
						addedToStory: "false",
						context: [
							{
								dialogueNo: 1,
								text: "i'm really worried about the side effects of lectrazine. i've been hearing a lot of bad things about it.",
								speaker: "Patient",
							},
							{
								dialogueNo: 2,
								text: "lectrazine is a very effective medication for treating allergies, but like all medications, it can cause some side effects. the most common side effects include headache, dizziness, and nausea. these side effects are usually mild and go away on their own. however, if you experience any of these side effects, please let your doctor know.",
								speaker: "Agent",
							},
							{
								dialogueNo: 3,
								text: "i've been having a lot of headaches and dizziness since i started taking lectrazine. it's really starting to affect my quality of life.",
								speaker: "Patient",
							},
							{
								dialogueNo: 4,
								text: "i'm sorry to hear that. headaches and dizziness are common side effects of lectrazine, but they usually go away on their own. if you're still experiencing these side effects after a few days, please let your doctor know. they may be able to adjust your dose or switch you to a different medication.",
								speaker: "Agent",
							},
							{
								dialogueNo: 5,
								text: "i'm really getting worried about these side effects. is there anything i can do to reduce them ?",
								speaker: "Patient",
							},
						],
						s3path: "kural/processed_files/WW8PUBYMYJOW4S8N.json",
						elasticSearchId: "WW8PUBYMYJOW4S8N_3",
						transcripts: [
							{
								speaker: "Patient",
								text: "i'm really worried about the side effects of lectrazine. i've been hearing a lot of bad things about it.",
								highLights: ["lectrazine"],
								dialogueNo: 1,
							},
							{
								speaker: "Agent",
								text: "lectrazine is a very effective medication for treating allergies, but like all medications, it can cause some side effects. the most common side effects include headache, dizziness, and nausea. these side effects are usually mild and go away on their own. however, if you experience any of these side effects, please let your doctor know.",
								highLights: ["lectrazine"],
								dialogueNo: 2,
							},
							{
								speaker: "Patient",
								text: "i've been having a lot of headaches and dizziness since i started taking lectrazine. it's really starting to affect my quality of life.",
								highLights: ["lectrazine"],
								dialogueNo: 3,
							},
							{
								speaker: "Agent",
								text: "i'm sorry to hear that. headaches and dizziness are common side effects of lectrazine, but they usually go away on their own. if you're still experiencing these side effects after a few days, please let your doctor know. they may be able to adjust your dose or switch you to a different medication.",
								highLights: ["lectrazine"],
								dialogueNo: 4,
							},
							{
								speaker: "Patient",
								text: "i'm really getting worried about these side effects. is there anything i can do to reduce them ?",
								highLights: [],
								dialogueNo: 5,
							},
						],
						callTypeId: 2,
						therapyStatusId: -1,
					},
					{
						searchId: 3,
						name: "Transcript from 25 May 2022 [15:30:20]",
						tags: ["Negative", "Outbound", "UNKNOWN"],
						dialogueNo: 2,
						callId: "F1T9KO8WBYD609GF",
						callDate: "2022-05-25",
						callTime: "15:30:20",
						dialogueAppearedAt: 3.68,
						dialogueLength: 6.44,
						transcriptLength: 119.14,
						patientId: -1,
						speaker: "Patient",
						storiesAdded: [],
						addedToStory: "false",
						context: [
							{
								dialogueNo: 1,
								text: "hi, how can i help you ?",
								speaker: "Agent",
							},
							{
								dialogueNo: 2,
								text: "i'm just so frustrated. my insurance company denied my claim for lectrazine.",
								speaker: "Patient",
							},
							{
								dialogueNo: 3,
								text: "i'm sorry to hear that. can you tell me what happened ?",
								speaker: "Agent",
							},
							{
								dialogueNo: 4,
								text: "they said that it's not a covered medication. but i've been taking it for years and it's the only thing that helps me.",
								speaker: "Patient",
							},
						],
						s3path: "kural/processed_files/F1T9KO8WBYD609GF.json",
						elasticSearchId: "F1T9KO8WBYD609GF_2",
						transcripts: [
							{
								speaker: "Agent",
								text: "hi, how can i help you ?",
								highLights: [],
								dialogueNo: 1,
							},
							{
								speaker: "Patient",
								text: "i'm just so frustrated. my insurance company denied my claim for lectrazine.",
								highLights: ["lectrazine"],
								dialogueNo: 2,
							},
							{
								speaker: "Agent",
								text: "i'm sorry to hear that. can you tell me what happened ?",
								highLights: [],
								dialogueNo: 3,
							},
							{
								speaker: "Patient",
								text: "they said that it's not a covered medication. but i've been taking it for years and it's the only thing that helps me.",
								highLights: [],
								dialogueNo: 4,
							},
						],
						callTypeId: 2,
						therapyStatusId: -1,
					},
				],
				statesResults: [
					{
						id: "TX",
						value: 12,
					},
					{
						id: "MI",
						value: 9,
					},
					{
						id: "VA",
						value: 8,
					},
					{
						id: "OH",
						value: 6,
					},
					{
						id: "NY",
						value: 6,
					},
					{
						id: "CA",
						value: 6,
					},
					{
						id: "NJ",
						value: 4,
					},
					{
						id: "MN",
						value: 4,
					},
					{
						id: "AZ",
						value: 3,
					},
					{
						id: "PA",
						value: 3,
					},
					{
						id: "MO",
						value: 3,
					},
					{
						id: "NC",
						value: 3,
					},
					{
						id: "ID",
						value: 3,
					},
					{
						id: "FL",
						value: 3,
					},
					{
						id: "IL",
						value: 2,
					},
					{
						id: "ME",
						value: 2,
					},
					{
						id: "GA",
						value: 2,
					},
					{
						id: "MA",
						value: 2,
					},
					{
						id: "WI",
						value: 2,
					},
					{
						id: "OK",
						value: 2,
					},
					{
						id: "NM",
						value: 1,
					},
					{
						id: "UT",
						value: 1,
					},
					{
						id: "WV",
						value: 1,
					},
					{
						id: "ND",
						value: 1,
					},
					{
						id: "SC",
						value: 1,
					},
					{
						id: "AR",
						value: 1,
					},
					{
						id: "NE",
						value: 1,
					},
					{
						id: "LA",
						value: 1,
					},
					{
						id: "KY",
						value: 1,
					},
					{
						id: "IN",
						value: 1,
					},
					{
						id: "WA",
						value: 1,
					},
					{
						id: "CO",
						value: 1,
					},
					{
						id: "MS",
						value: 1,
					},
					{
						id: "OR",
						value: 1,
					},
					{
						id: "AL",
						value: 1,
					},
				],
				emotionsCounts: [
					{
						text: "information",
						value: 19,
					},
					{
						text: "questions",
						value: 16,
					},
				],
				headers: {
					"x-time": {
						query_expansion_time: 0.24471282958984375,
						retriever_time: 0.612053394317627,
						ranker_time: 0.7366836071014404,
						df_prepare_time: 0.008089542388916016,
						process_time: 1.6488804817199707,
					},
					"x-search-config": {
						query_type: "keyword",
						expanded_query:
							"[('bad headache', 0.9180014), ('slight headache', 0.9074279), ('big headache', 0.90502834), ('little headache', 0.89852965), ('mild headache', 0.8773646), ('real bad headache', 0.87445515), ('very bad headache', 0.86825484), ('really bad headache', 0.86764216), ('weird headache', 0.863446), ('dull headache', 0.85253), ('headache', 1)]",
					},
					"x-auth-time": "0.19579458236694336",
					"x-process-time": "1.8481688499450684",
				},
			},
		],
	},
];

export const hppChurnPredictionsData = [
	{
		patientRiskBucket: [
			{
				cardName: "Low",
				value: "245",
				vsPrevMonth: -10,
			},
			{
				cardName: "Medium",
				value: "230",
				vsPrevMonth: -7,
			},
			{
				cardName: "High",
				value: "270",
				vsPrevMonth: 10,
			},
		],
	},
	{
		patientScore: {
			cardName: "#Members scorecard over time",
			yAxisLabel: [],
			chartData: [
				{
					"Feb 22": {
						Negative: 1136,
						Neutral: 353,
						Positive: 548,
					},
					"Mar 22": {
						Negative: 714,
						Neutral: 202,
						Positive: 305,
					},
					"Apr 22": {
						Negative: 1220,
						Neutral: 382,
						Positive: 579,
					},
					"May 22": {
						Negative: 1142,
						Neutral: 379,
						Positive: 543,
					},
					"Jun 22": {
						Negative: 1150,
						Neutral: 329,
						Positive: 529,
					},
					"Jul 22": {
						Negative: 1044,
						Neutral: 313,
						Positive: 530,
					},
					"Aug 22": {
						Negative: 1162,
						Neutral: 361,
						Positive: 574,
					},
					"Sep 22": {
						Negative: 1130,
						Neutral: 353,
						Positive: 550,
					},
					"Oct 22": {
						Negative: 1179,
						Neutral: 341,
						Positive: 528,
					},
					"Nov 22": {
						Negative: 937,
						Neutral: 297,
						Positive: 441,
					},
					"Dec 22": {
						Negative: 968,
						Neutral: 276,
						Positive: 401,
					},
				},
				{
					"Jul 21": {
						Negative: 1,
						Neutral: 0,
						Positive: 1,
					},
					"Aug 21": {
						Negative: 2,
						Neutral: 0,
						Positive: 0,
					},
					"Sep 21": {
						Negative: 1,
						Neutral: 1,
						Positive: 0,
					},
					"Nov 21": {
						Negative: 1,
						Neutral: 0,
						Positive: 0,
					},
					"Dec 21": {
						Negative: 3,
						Neutral: 0,
						Positive: 0,
					},
					"Jan 22": {
						Negative: 2,
						Neutral: 0,
						Positive: 0,
					},
					"Feb 22": {
						Negative: 3,
						Neutral: 1,
						Positive: 0,
					},
					"Mar 22": {
						Negative: 2,
						Neutral: 0,
						Positive: 0,
					},
					"Apr 22": {
						Negative: 2,
						Neutral: 0,
						Positive: 1,
					},
				},
				{
					"Jul 21": {
						Negative: 99,
						Neutral: 16,
						Positive: 21,
					},
					"Aug 21": {
						Negative: 62,
						Neutral: 13,
						Positive: 10,
					},
					"Sep 21": {
						Negative: 133,
						Neutral: 11,
						Positive: 15,
					},
					"Oct 21": {
						Negative: 109,
						Neutral: 16,
						Positive: 12,
					},
					"Nov 21": {
						Negative: 86,
						Neutral: 10,
						Positive: 10,
					},
					"Dec 21": {
						Negative: 74,
						Neutral: 11,
						Positive: 18,
					},
					"Jan 22": {
						Negative: 107,
						Neutral: 11,
						Positive: 9,
					},
					"Feb 22": {
						Negative: 84,
						Neutral: 18,
						Positive: 6,
					},
					"Mar 22": {
						Negative: 77,
						Neutral: 9,
						Positive: 15,
					},
					"Apr 22": {
						Negative: 49,
						Neutral: 10,
						Positive: 8,
					},
					"May 22": {
						Negative: 64,
						Neutral: 8,
						Positive: 5,
					},
				},
				{
					"Jul 21": {
						Negative: 0,
						Neutral: 1,
						Positive: 1,
					},
					"Aug 21": {
						Negative: 3,
						Neutral: 0,
						Positive: 0,
					},
					"Sep 21": {
						Negative: 4,
						Neutral: 0,
						Positive: 1,
					},
					"Oct 21": {
						Negative: 3,
						Neutral: 1,
						Positive: 0,
					},
					"Nov 21": {
						Negative: 6,
						Neutral: 0,
						Positive: 1,
					},
					"Jan 22": {
						Negative: 3,
						Neutral: 0,
						Positive: 0,
					},
					"Feb 22": {
						Negative: 3,
						Neutral: 0,
						Positive: 0,
					},
					"Mar 22": {
						Negative: 0,
						Neutral: 0,
						Positive: 1,
					},
					"Apr 22": {
						Negative: 2,
						Neutral: 1,
						Positive: 0,
					},
					"May 22": {
						Negative: 1,
						Neutral: 0,
						Positive: 0,
					},
				},
				{
					"Jul 21": {
						Negative: 259,
						Neutral: 48,
						Positive: 51,
					},
					"Aug 21": {
						Negative: 155,
						Neutral: 25,
						Positive: 31,
					},
					"Sep 21": {
						Negative: 267,
						Neutral: 47,
						Positive: 68,
					},
					"Oct 21": {
						Negative: 237,
						Neutral: 39,
						Positive: 73,
					},
					"Nov 21": {
						Negative: 247,
						Neutral: 33,
						Positive: 59,
					},
					"Dec 21": {
						Negative: 219,
						Neutral: 38,
						Positive: 52,
					},
					"Jan 22": {
						Negative: 208,
						Neutral: 33,
						Positive: 53,
					},
					"Feb 22": {
						Negative: 244,
						Neutral: 36,
						Positive: 57,
					},
					"Mar 22": {
						Negative: 222,
						Neutral: 33,
						Positive: 53,
					},
					"Apr 22": {
						Negative: 181,
						Neutral: 34,
						Positive: 46,
					},
					"May 22": {
						Negative: 179,
						Neutral: 30,
						Positive: 22,
					},
				},
				{
					"Jul 21": {
						Negative: 8,
						Neutral: 1,
						Positive: 6,
					},
					"Aug 21": {
						Negative: 10,
						Neutral: 1,
						Positive: 2,
					},
					"Sep 21": {
						Negative: 4,
						Neutral: 1,
						Positive: 1,
					},
					"Oct 21": {
						Negative: 1,
						Neutral: 0,
						Positive: 1,
					},
					"Nov 21": {
						Negative: 2,
						Neutral: 0,
						Positive: 0,
					},
					"Dec 21": {
						Negative: 2,
						Neutral: 1,
						Positive: 0,
					},
					"Jan 22": {
						Negative: 4,
						Neutral: 0,
						Positive: 1,
					},
					"Feb 22": {
						Negative: 1,
						Neutral: 0,
						Positive: 0,
					},
					"Mar 22": {
						Negative: 0,
						Neutral: 1,
						Positive: 0,
					},
					"May 22": {
						Negative: 2,
						Neutral: 0,
						Positive: 0,
					},
				},
				{
					"Jul 21": {
						Negative: 62,
						Neutral: 16,
						Positive: 21,
					},
					"Aug 21": {
						Negative: 59,
						Neutral: 12,
						Positive: 9,
					},
					"Sep 21": {
						Negative: 70,
						Neutral: 24,
						Positive: 26,
					},
					"Oct 21": {
						Negative: 72,
						Neutral: 28,
						Positive: 23,
					},
					"Nov 21": {
						Negative: 84,
						Neutral: 22,
						Positive: 28,
					},
					"Dec 21": {
						Negative: 95,
						Neutral: 25,
						Positive: 42,
					},
					"Jan 22": {
						Negative: 107,
						Neutral: 32,
						Positive: 36,
					},
					"Feb 22": {
						Negative: 106,
						Neutral: 25,
						Positive: 54,
					},
					"Mar 22": {
						Negative: 80,
						Neutral: 36,
						Positive: 45,
					},
					"Apr 22": {
						Negative: 81,
						Neutral: 26,
						Positive: 58,
					},
					"May 22": {
						Negative: 113,
						Neutral: 23,
						Positive: 60,
					},
				},
				{
					"Jul 21": {
						Negative: 2,
						Neutral: 1,
						Positive: 2,
					},
					"Aug 21": {
						Negative: 3,
						Neutral: 3,
						Positive: 1,
					},
					"Sep 21": {
						Negative: 8,
						Neutral: 1,
						Positive: 1,
					},
					"Oct 21": {
						Negative: 9,
						Neutral: 2,
						Positive: 1,
					},
					"Nov 21": {
						Negative: 3,
						Neutral: 2,
						Positive: 4,
					},
					"Dec 21": {
						Negative: 1,
						Neutral: 2,
						Positive: 4,
					},
					"Jan 22": {
						Negative: 4,
						Neutral: 2,
						Positive: 8,
					},
					"Feb 22": {
						Negative: 6,
						Neutral: 1,
						Positive: 4,
					},
					"Mar 22": {
						Negative: 5,
						Neutral: 0,
						Positive: 0,
					},
					"Apr 22": {
						Negative: 5,
						Neutral: 2,
						Positive: 0,
					},
					"May 22": {
						Negative: 4,
						Neutral: 3,
						Positive: 2,
					},
				},
				{
					"Jul 21": {
						Negative: 650,
						Neutral: 231,
						Positive: 384,
					},
					"Aug 21": {
						Negative: 375,
						Neutral: 140,
						Positive: 215,
					},
					"Sep 21": {
						Negative: 667,
						Neutral: 256,
						Positive: 409,
					},
					"Oct 21": {
						Negative: 658,
						Neutral: 267,
						Positive: 373,
					},
					"Nov 21": {
						Negative: 673,
						Neutral: 229,
						Positive: 382,
					},
					"Dec 21": {
						Negative: 592,
						Neutral: 207,
						Positive: 372,
					},
					"Jan 22": {
						Negative: 675,
						Neutral: 244,
						Positive: 40,
					},
					"Feb 22": {
						Negative: 617,
						Neutral: 243,
						Positive: 370,
					},
					"Mar 22": {
						Negative: 734,
						Neutral: 235,
						Positive: 373,
					},
					"Apr 22": {
						Negative: 555,
						Neutral: 195,
						Positive: 284,
					},
					"May 22": {
						Negative: 564,
						Neutral: 192,
						Positive: 284,
					},
				},
				{
					"Jul 21": {
						Negative: 55,
						Neutral: 39,
						Positive: 61,
					},
					"Aug 21": {
						Negative: 45,
						Neutral: 8,
						Positive: 37,
					},
					"Sep 21": {
						Negative: 66,
						Neutral: 41,
						Positive: 58,
					},
					"Oct 21": {
						Negative: 53,
						Neutral: 26,
						Positive: 60,
					},
					"Nov 21": {
						Negative: 48,
						Neutral: 33,
						Positive: 45,
					},
					"Dec 21": {
						Negative: 58,
						Neutral: 29,
						Positive: 42,
					},
					"Jan 22": {
						Negative: 52,
						Neutral: 39,
						Positive: 61,
					},
					"Feb 22": {
						Negative: 66,
						Neutral: 29,
						Positive: 59,
					},
					"Mar 22": {
						Negative: 59,
						Neutral: 27,
						Positive: 41,
					},
					"Apr 22": {
						Negative: 62,
						Neutral: 29,
						Positive: 44,
					},
					"May 22": {
						Negative: 41,
						Neutral: 20,
						Positive: 28,
					},
				},
			],
			chartColor: ["#76BCEE", "#FFC736", "#EE6767"],
		},
	},
	{
		dummyDataTable: [
			{
				Member_Id: "ZSID001",
				Line_of_Business: "Commercial",
				Current_Plan: "HMO",
				Probability_of_Churn: "High",
				Top_Drivers_of_Churn: "1. High Premium Cost [Calls]\n2. Attractive Options elsewhere [Calls]\n3. Total time on Plan [Plan]",
				Number_of_Support_Interactions_in_last_6_months: 3,
				"Member_Satisfaction_Score(10)": 3.5,
			},
			{
				Member_Id: "ZSID002",
				Line_of_Business: "Commercial",
				Current_Plan: "PPO",
				Probability_of_Churn: "High",
				Top_Drivers_of_Churn: "1. Limited in-network Access to Providers [Calls]\n2. Change in Member Health Risk Score [Plan]\n3. Slow Claims Processing [Calls]",
				Number_of_Support_Interactions_in_last_6_months: 6,
				"Member_Satisfaction_Score(10)": 4.0,
			},
			{
				Member_Id: "ZSID003",
				Line_of_Business: "Medicare",
				Current_Plan: "MSA",
				Probability_of_Churn: "High",
				Top_Drivers_of_Churn: "1. Negative Experience with Care [Calls]\n2. Billing Errors [Claims]\n3. Number of claim requests in last 3 months [Claims]",
				Number_of_Support_Interactions_in_last_6_months: 5,
				"Member_Satisfaction_Score(10)": 4.0,
			},
			{
				Member_Id: "ZSID004",
				Line_of_Business: "Medicaid",
				Current_Plan: "SSI",
				Probability_of_Churn: "Medium",
				Top_Drivers_of_Churn: "1. Frustration with Customer Service [Calls]\n2. Avg. Cost of claims [Claims]\n3. Confusion on Plan Changes [Calls]",
				Number_of_Support_Interactions_in_last_6_months: 2,
				"Member_Satisfaction_Score(10)": 5.0,
			},
			{
				Member_Id: "ZSID005",
				Line_of_Business: "Commercial",
				Current_Plan: "HDHP",
				Probability_of_Churn: "Medium",
				Top_Drivers_of_Churn: "1. Confusion on Coverage Benefits [Calls]\n2. Dis-satisfaction with Provider Quality [Calls]\n3. SDoH Factors [Claims]",
				Number_of_Support_Interactions_in_last_6_months: 1,
				"Member_Satisfaction_Score(10)": 6.0,
			},
			{
				Member_Id: "ZSID006",
				Line_of_Business: "Commercial",
				Current_Plan: "EPO",
				Probability_of_Churn: "Medium",
				Top_Drivers_of_Churn: "1. Difficulty Navigating Online Resources [Calls]\n2. Age [Claims]\n3. External Influences [Calls]",
				Number_of_Support_Interactions_in_last_6_months: 2,
				"Member_Satisfaction_Score(10)": 6.0,
			},
		],
	},
];
