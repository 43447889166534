import { Button, Checkbox, DatePicker, Input, Modal, Select, Switch } from "antd";
import { icons } from "../../GlobalThemes";
import React, { useState, useContext } from "react";
import moment from "moment";
import AppContext from "../../store";
import { searchQuery } from "../../Pages/SearchEngine/Search";
import { getAllTranscripts } from "../../Pages/SearchEngine/ServiceHelpers/search";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";

export interface filterValues {
	startDate: string;
	endData: string;
	speaker: string;
	searchWord: string;
}

/**
 * Renders the search filters at the top of search page, which includes speaker type, time period and search bar.
 *
 * @remarks - This function is the part of search tab and will be rendered at the top of the page
 *
 * @param showDrawer - The props used to show and hide the drawer for advanced filters
 * @param fetchTranscripts - This function is  used to fetch the transcripts based on the added inputs
 * @param searchWordRequired - This props check search word is present or not
 *
 * @returns the filters at search tab
 */

export function SearchFilters(props: {
	showDrawer: () => void;
	fetchTranscripts: (queries: searchQuery) => void;
	searchWordRequired: boolean;
	searchString?: any;
	setSearchString?: any;
}) {
	var filterData = {
		startDate: "",
		endDate: "",
		speaker: "",
		searchWord: "",
		deepSearch: true,
	};
	var dateFormat = "MMM YYYY";
	const [searchFilterData, setSearchFilterData] = useState<searchQuery>(filterData);
	const app = useContext(AppContext);
	const [dateRanges, setDateRanges] = React.useState<Array<any>>([]);
	const [modalVisible, setModalVisible] = React.useState(false);

	const handleFilters = (filterType: string, filterValue: string | boolean) => {
		var filters: any = searchFilterData;
		filters[filterType] = filterValue;
		setSearchFilterData(filters);
	};

	const disableSelectedDates = (current: any) => {
		var c = current;
		//console.log(current);
		return (
			current < moment(app.startDate, dateFormat) ||
			current > moment(app.endDate, dateFormat).endOf("month") ||
			(dateRanges && dateRanges[0] && current.diff(dateRanges[0], "months") > 10) ||
			(dateRanges && dateRanges[1] && dateRanges[1].diff(current, "months") > 10)
		);
	};

	function ModalContent() {
		const [deepSearch, setDeepSearch] = React.useState(searchFilterData["deepSearch"]);
		const [testButton1, setTestButton1] = React.useState(searchFilterData["testButton1"]);
		const [testButton2, setTestButton2] = React.useState(searchFilterData["testButton2"]);
		const [testButton3, setTestButton3] = React.useState(searchFilterData["testButton3"]);
		const [testButton4, setTestButton4] = React.useState(searchFilterData["testButton4"]);
		const [testButton5, setTestButton5] = React.useState(searchFilterData["testButton5"]);

		return (
			<>
				{/* <Checkbox
					onChange={(e) => {
						handleFilters("deepSearch", e.target.checked);
					}}
				>
					Deep Search
				</Checkbox> */}
				<div className="text-2xl text-text-gray-100 font-normal my-4">Search Settings</div>
				<div className="text-base text-text-gray-50 my-6">Please select the search settings.</div>
				<div className="w-full ">
					<div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">Enable LLM Summary - Single</div>

						<Switch
							onChange={(checked) => {
								setDeepSearch(checked);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CloseOutlined />
								</div>
							}
							defaultChecked={deepSearch}
						/>
					</div>

					{/* <div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">Enable LLM Summary - Multiple</div>

						<Switch
							onChange={(checked) => {
								setTestButton1(checked);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CloseOutlined />
								</div>
							}
							defaultChecked={testButton1}
						/>
					</div>

					<div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">Test Button 2</div>

						<Switch
							onChange={(checked) => {
								setTestButton2(checked);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CloseOutlined />
								</div>
							}
							defaultChecked={testButton2}
						/>
					</div>

					<div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">Test Button 3</div>

						<Switch
							onChange={(checked) => {
								setTestButton3(checked);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CloseOutlined />
								</div>
							}
							defaultChecked={testButton3}
						/>
					</div>

					<div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">Test Button 4</div>

						<Switch
							onChange={(checked) => {
								setTestButton4(checked);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CloseOutlined />
								</div>
							}
							defaultChecked={testButton4}
						/>
					</div>
					<div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">Test Button 5</div>

						<Switch
							onChange={(checked) => {
								setTestButton5(checked);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CloseOutlined />
								</div>
							}
							defaultChecked={testButton5}
						/>
					</div> */}
					<div className="flex justify-end pt-4">
						<button
							type="button"
							className="bg-text-teal text-white text-base border-text-teal border-2 px-4 py-2"
							onClick={() => {
								if (deepSearch !== undefined) handleFilters("deepSearch", deepSearch);
								if (testButton1 !== undefined) handleFilters("testButton1", testButton1);
								if (testButton2 !== undefined) handleFilters("testButton2", testButton2);
								if (testButton3 !== undefined) handleFilters("testButton3", testButton3);
								if (testButton4 !== undefined) handleFilters("testButton4", testButton4);
								if (testButton5 !== undefined) handleFilters("testButton5", testButton5);
								setModalVisible(false);
							}}>
							Save Settings
						</button>
					</div>
				</div>
			</>
		);
	}

	const onClickSearch = () => {
		props.fetchTranscripts(searchFilterData);
		console.log("HERE", searchFilterData.searchWord);
		props.setSearchString(searchFilterData.searchWord);
	};
	return (
		<div>
			<Modal
				// title="Search Settings"
				visible={modalVisible}
				onOk={() => setModalVisible(false)}
				onCancel={() => setModalVisible(false)}
				footer={null}>
				<ModalContent />
			</Modal>
			<div className="flex justify-between items-center pb-4">
				<div className="flex align-middle">
					<div className="flex items-center mr-8">
						<span className="mr-2">Speaker Type:</span>
						<Select
							clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
							className="w-32"
							placeholder="Speaker Type"
							allowClear
							onChange={(value: string) => {
								handleFilters("speaker", value);
							}}>
							<Select.Option value="Agent">Agent</Select.Option>
							<Select.Option value="Member">Member</Select.Option>
						</Select>
					</div>
					<div className="flex items-center mr-8">
						<span className="mr-2">Time Period:</span>
						<DatePicker.RangePicker
							clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
							disabledDate={disableSelectedDates}
							defaultPickerValue={[moment(app.startDate, "DD MMM YYYY"), moment(app.endDate, "DD MMM YYYY")]}
							format={dateFormat}
							separator={icons.find((l) => l.id === "Swap")?.icon}
							onChange={(e: any) => {
								handleFilters("startDate", moment(e[0]).format("MMM YYYY"));
								handleFilters("endDate", moment(e[1]).format("MMM YYYY"));
							}}
							onCalendarChange={(value: any) => {
								setDateRanges(value);
							}}
							className=""
							picker="month"
						/>
					</div>
					<div className={props.searchWordRequired ? "" : "flex items-center"}>
						{props.searchWordRequired ? (
							<div style={{ marginTop: "-20px" }}>
								<p className="text-sm text-text-red">Keyword is Required</p>
							</div>
						) : null}
						<Input
							className="flex input-search lg:w-96 md:w-48"
							placeholder="Search for transcript dialogues with keywords and tags"
							onChange={(e) => {
								handleFilters("searchWord", e.target.value);
							}}
							onKeyDown={(e: any) => {
								if (e.key === "Enter") {
									props.fetchTranscripts(searchFilterData);
									props.setSearchString(searchFilterData.searchWord);
								}
							}}
						/>
					</div>
					<div className="flex items-center ml-5">
						<Button type="primary" className="green-button flex items-center" onClick={() => onClickSearch()}>
							Search
						</Button>
					</div>

					<div
						title="Search Settings"
						className="flex align-middle mt-1 ml-5 text-text-gray-60 cursor-pointer"
						onClick={() => {
							setModalVisible(true);
						}}>
						{icons.find((l) => l.id === "Search Settings")?.icon}
					</div>
				</div>
				<div>
					<div
						className="flex items-center cursor-pointer w-44 ml-4"
						onClick={() => {
							props.showDrawer();
						}}>
						<div className="mr-2">{icons.find((l) => l.id === "FilterGreen")?.icon}</div>
						<div style={{ color: "#27A6A4" }} className="flex text-base">
							Advanced Filters
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
