import { searchQuery } from "../Search";
import * as PatientList from "./../../../services/patientJourney";

export const getPatientListData: () => Promise<unknown> = async () => {
  const data = await PatientList.getPatientList();
  // console.log("api", data);
  if (data === null || data === undefined) return null;
  return data;
};

export const getPatientDetailsData: (patientId: any) => Promise<unknown> = async (
  patientId: any
) => {
  const data = await PatientList.getPatientDetails(patientId);
  // console.log("api", data);
  if (data === null || data === undefined) return null;
  return data;
};

export const getCallTranscriptDetailsData: (callId: any) => Promise<unknown> = async (
  callId: any
) => {
  const data = await PatientList.getCallTranscriptDetails(callId);
  // console.log("api", data);
  if (data === null || data === undefined) return null;
  return data;
};
