import { Form, Button, Select, DatePicker, TreeSelect, Slider } from "antd";
import moment from "moment";
import { FilterInterface } from "../..";
import "./style.css";
import AppContext from "../../../../store";
import React, { useState } from "react";
import { icons } from "../../../../GlobalThemes";
import * as ExploreServiceHelper from "../../../SearchEngine/ServiceHelpers/explore";

export default function Drawer(props: {
	filters: FilterInterface;
	clusterId?: any;
	filterVal?: any;
	setFilters: React.Dispatch<React.SetStateAction<FilterInterface>>;
	filterOptions: {
		filter: string;
		type: string;
		optionsObject: {
			id: number;
			name: string;
			subgroup: {
				id: number;
				name: string;
			}[];
		}[];
	}[];
	clearFilters: () => void;
	filterActiveStatus?: any;
}) {
	const [dateRanges, setDateRanges] = useState<Array<any>>([]);
	const [selectedGroup, setSelectedGroup] = useState("");
	const [timeframe, setTimeframe] = useState(props.filters.Timeframe_Display || "Month");

	{
		console.log("PROPS", props);
	}

	const marks: { [id: number]: string } = {
		0: "0",
		16.6: "30",
		33.3: "60",
		50: "90",
		66.6: "180",
		83.3: "360",
		100: "360+",
	};

	async function fetchFilteredData(obj: any) {
		console.log("CHECK DATA HERE", obj);
		if (props.filterVal) {
			let filters: any;

			// props.filterVal.callTypeIds ? props.filterVal.callTypeIds[0]
			console.log(props.filterVal);
			//@ts-ignore
			filters["callTypeId"] = "1";
			// props.filterVal.callTypeIds.length > 0 ? props.filterVal.callTypeIds[0] : undefined;
			filters["totStart"] = "180";
			// props.filterVal.totStart ? props.filterVal.totStart : null;
			filters["totEnd"] = "0";
			// props.filterVal.totEnd ? props.filterVal.totEnd : null;
			filters["therapyStatusId"] = "1";
			// props.filterVal.therapyStatusIds ? props.filterVal.therapyStatusIds[0] : null;
			filters["sentiment_id"] = "3";
			// props.filterVal.sentiment ? props.filterVal.sentiment[0] : null;
			filters["topic"] = "topic_2";
			// props.filterVal.topics ? props.filterVal.topics[0] : null;
			filters["emotion_id"] = "5";
			// props.filterVal.emotions ? [...props.filterVal.emotions] : null;
			filters["clusterId"] = "1";
			// props.clusterId;
			filters["startDate "] = "2022-01-01T12:00:27.87+00:20";
			// props.filterVal.startDate;
			filters["endDate"] = "2022-04-01T12:00:27.87+00:20";
			// props.filterVal.endDate;
			filters["speaker "] = "Agent";
			// props.filterVal.speaker;
			console.log("DATA FROM API AFTER FILTERS", filters);

			const data: any = await ExploreServiceHelper.getExploreCardsData(
				filters.clusterId,
				filters.startDate,
				filters.endDate,
				filters.speaker,
				filters.topic,
				filters.totStart,
				filters.totEnd,
				filters.callTypeId,
				filters.therapyStatusId,
				filters.sentiment_id,
				filters.emotion_id
			);

			// props.setSelectedClusterData(data);
		}

		// console.log("CHECK DATA HERE", data);
	}
	const onFinish = (values: any) => {
		console.log("Set", values);

		const obj = {
			...values,
			End_Date: values.Selection_Period ? moment(values.Selection_Period[1]).format("MMMM YYYY") : "",
			Start_Date: values.Selection_Period ? moment(values.Selection_Period[0]).format("MMMM YYYY") : "",
			totStart: values.TOT ? marks[values.TOT[0]] : undefined,
			totEnd: values.TOT ? marks[values.TOT[1]] : undefined,
			// speaker: "Member",
		};

		delete obj.Selection_Period;
		delete obj.TOT;
		if (!obj["Emotions"]) obj["Emotions"] = new Array<string>();

		props.setFilters(obj);

		// if (props.clusterId) fetchFilteredData(obj);
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log("Failed:", errorInfo);
	};
	const [form] = Form.useForm();
	const onReset = () => {
		props.clearFilters();
		// form.resetFields();
	};
	const dateFormat = "MMMM YYYY";
	const { RangePicker } = DatePicker;
	function disabledDate(current: any) {
		// Can not select days before today and today
		return context.startDate && context.endDate;
	}
	const disableSelectedDates = (current: any) => {
		var c = current;
		//console.log(current);
		return (
			current < moment(context.startDate, dateFormat) ||
			current > moment(context.endDate, dateFormat).endOf("month") ||
			(dateRanges && dateRanges[0] && current.diff(dateRanges[0], "months") > 10) ||
			(dateRanges && dateRanges[1] && dateRanges[1].diff(current, "months") > 10)
		);
	};
	const context = React.useContext(AppContext);
	React.useEffect(() => {
		props.filterOptions.map((filter) => {
			filter.optionsObject?.map((group) => {
				if (
					group.subgroup.find(
						(sg) =>
							//@ts-ignore
							sg.name === props.filters.Emotions[0]
					)
				) {
					setSelectedGroup(group.name);
				}
			});
		});
	}, []);
	return (
		<div className="px-4 py-2">
			<Form
				form={form}
				layout="vertical"
				name="Filter Form"
				labelCol={{ span: 24 }}
				onValuesChange={(changedValues, allValues) => {
					console.log(changedValues, allValues);
				}}
				// wrapperCol={{ span: 16 }}
				initialValues={{
					...props.filters,
					Selection_Period: props.filters.Start_Date ? [moment(props.filters.Start_Date, dateFormat), moment(props.filters.End_Date, dateFormat)] : "",
				}}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off">
				{props.filterOptions.map((filter) => {
					return (
						<>
							{timeframe === "Quarter" ? (
								<>
									{filter.optionsObject.length === 0 && filter.filter === "Selection_Period" && (
										<Form.Item label={filter.filter.split("_").join(" ")} name={filter.filter}>
											<RangePicker
												picker="quarter"
												disabledDate={disableSelectedDates}
												disabled={props.filterActiveStatus[filter.filter] === false}
												defaultPickerValue={[moment(context.startDate, "DD MMM YYYY"), moment(context.endDate, "DD MMM YYYY")]}
												// format={dateFormat}
												onCalendarChange={(value: any) => {
													console.log(value);
													setDateRanges(value);
												}}
												separator={icons.find((l) => l.id === "Swap")?.icon}
												allowClear
												clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
											/>
										</Form.Item>
									)}
								</>
							) : (
								<>
									{filter.optionsObject.length === 0 && filter.filter === "Selection_Period" && (
										<Form.Item label={filter.filter.split("_").join(" ")} name={filter.filter}>
											<RangePicker
												picker="month"
												disabledDate={disableSelectedDates}
												disabled={props.filterActiveStatus[filter.filter] === false}
												defaultPickerValue={[moment(context.startDate, "DD MMM YYYY"), moment(context.endDate, "DD MMM YYYY")]}
												format={dateFormat}
												onCalendarChange={(value: any) => {
													setDateRanges(value);
												}}
												separator={icons.find((l) => l.id === "Swap")?.icon}
												allowClear
												clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
											/>
										</Form.Item>
									)}
								</>
							)}

							{filter.optionsObject && filter.optionsObject.length > 0 && filter.type === "tree" && (
								<Form.Item label={filter.filter.split("_").join(" ")} name={filter.filter}>
									<TreeSelect
										showSearch
										disabled={props.filterActiveStatus[filter.filter] === false}
										placeholder={"Select " + filter.filter.split("_").join(" ")}
										showArrow
										allowClear
										clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
										multiple
										treeDefaultExpandAll
										treeCheckable
										showCheckedStrategy={TreeSelect.SHOW_PARENT}
										// onChange={(e) => console.log(e)}
									>
										{filter.optionsObject?.map((option) => {
											if (option.subgroup.length > 0)
												return (
													<TreeSelect.TreeNode key={option.name} value={option.name} title={option.name}>
														{option.subgroup.map((subGrp) => {
															return <TreeSelect.TreeNode key={subGrp.name} value={subGrp.name} title={subGrp.name} />;
														})}
													</TreeSelect.TreeNode>
												);
										})}
									</TreeSelect>
								</Form.Item>
							)}
							{filter.filter === "Timeframe_Display" && (
								<Form.Item label={filter.filter.split("_").join(" ")} name={filter.filter}>
									<Select
										allowClear
										disabled={props.filterActiveStatus[filter.filter] === false}
										clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
										onChange={(value: string) => {
											console.log(value);
											setDateRanges([]);
											setTimeframe(value);
										}}
										showArrow
										placeholder={"Select " + filter.filter.split("_").join(" ")}>
										{filter.optionsObject?.map((option) => {
											return (
												<Select.Option key={option.id} value={option.name}>
													{option.name}
												</Select.Option>
											);
										})}
									</Select>
								</Form.Item>
							)}
							{filter.optionsObject && filter.optionsObject.length > 0 && filter.type === "select" && filter.filter !== "Timeframe_Display" && (
								<Form.Item label={filter.filter.split("_").join(" ")} name={filter.filter}>
									<Select
										allowClear
										disabled={props.filterActiveStatus[filter.filter] === false}
										clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
										onChange={(value: string[]) => {
											const idx = filter.optionsObject?.map((obj) => {
												if (obj.subgroup.find((ob) => ob.name === value[0])) return obj.name;
											});
											const selected = idx?.find((f) => f !== undefined) || "";
											setSelectedGroup(selected);
										}}
										showArrow
										mode={filter.filter === "Emotions" ? "multiple" : undefined}
										placeholder={"Select " + filter.filter.split("_").join(" ")}>
										{filter.optionsObject?.map((option) => {
											if (option.subgroup.length === 0)
												return (
													<Select.Option key={option.id} value={option.name}>
														{option.name}
													</Select.Option>
												);
											else
												return (
													<Select.OptGroup key={option.id} label={option.name}>
														{option.subgroup.map((subGrp) => (
															<Select.Option
																key={subGrp.id}
																value={subGrp.name}
																disabled={selectedGroup && option.name !== selectedGroup ? true : false}>
																{subGrp.name}
															</Select.Option>
														))}
													</Select.OptGroup>
												);
										})}
									</Select>
								</Form.Item>
							)}
							{filter.type === "slider" && (
								<Form.Item name={filter.filter} label={"Time on Plan"}>
									<Slider
										range
										marks={marks}
										step={null}
										// defaultValue={[0, 0]}
										min={0}
										max={100}
										tooltipVisible={false}
									/>
								</Form.Item>
							)}
						</>
					);
				})}

				<Button type="primary" htmlType="submit">
					Apply
				</Button>
				<Button type="default" htmlType="button" style={{ marginLeft: "8px" }} onClick={onReset}>
					Clear Filters
				</Button>
			</Form>
		</div>
	);
}
