import {
	CardContainer,
	CardHeader,
	CardHeadingText,
	CardSubHeadingText,
	CardHeaderActions,
	CardHeaderAction,
	CardBody,
	CardHeaderText,
	CardFooter,
	CardFooterText,
	HeaderRibbon,
} from "./style";
import GearIcon from "./components/GearIcon";
import FullScreen from "./components/FullScreen";
import Save from "./components/Save";
// import {Actions} from "../../layout/components/body-layout/style";

import React from "react";

/**
 * Interface for Card Component
 */
export interface Interface {
	header?: string | React.ReactNode;
	borderedHeading?: boolean;
	subHeader?: string;
	footer?: string | React.ReactNode;
	bg?: string;
	minHeight?: string;
	height?: string;
	hideActions?: boolean[];
	hideRibbon?: boolean;
	ribbonData?: React.ReactNode;
	hideShadow?: boolean;
	bodyPadding?: string;
	bodyChartContent?: string[];
	children?: React.ReactNode;
	handleEdit?: any;
	handleSave?: any;
	flexContent?: string;
}
/**
 * Renders a Card Component with the chosen customizations
 *
 * @param props - The props for the Card Component
 *
 * @returns Card Component with Header, Body and Footer
 */
export default function Card(props: Interface) {
	return (
		<>
			<CardContainer
				bg={props.bg}
				height={props.height}
				hideShadow={props.hideShadow}
			>
				{props.header && (
					<CardHeader borderedHeading={props.borderedHeading}>
						<CardHeaderText>
							<CardHeadingText>{props.header}</CardHeadingText>
							{props.subHeader && (
								<CardSubHeadingText>
									{props.subHeader}
								</CardSubHeadingText>
							)}
						</CardHeaderText>

						<CardHeaderActions>
							{props.hideActions && !props.hideActions[0] && (
								<CardHeaderAction>
									<Save
										handleClick={() => {
											if (props.handleSave)
												props.handleSave();
										}}
									/>
								</CardHeaderAction>
							)}
							{props.hideActions && !props.hideActions[1] && (
								<CardHeaderAction>
									<GearIcon
										handleClick={() => {
											if (props.handleEdit)
												props.handleEdit();
										}}
									/>
								</CardHeaderAction>
							)}
						</CardHeaderActions>

						{!props.hideRibbon && props.ribbonData}
					</CardHeader>
				)}

				<CardBody
					minHeight={props.minHeight}
					bodyPadding={props.bodyPadding}
					flexContent={props.flexContent}
				>
					{props.children}
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							minWidth: 0,
							minHeight: 0,
						}}
					>
						{props.bodyChartContent?.map((content) => (
							<div
								style={{
									fontSize: "0.875rem",
									color: "#87848D",
								}}
							>
								{content}
							</div>
						))}
					</div>
				</CardBody>
				{props.footer && (
					<CardFooter>
						<CardFooterText>{props.footer}</CardFooterText>
					</CardFooter>
				)}
			</CardContainer>
		</>
	);
}
