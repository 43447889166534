import { Divider, Drawer, Select, Skeleton, Space } from "antd";
import React, { useEffect } from "react";
import Collapse from "../../Components/Collapse";
import { SentimentPatientCard } from "../../Components/ReportCards/CommonCardComponents/SentimentPatientCard";
import { SkeletonDetail, SkeletonSentiment } from "../../Components/SkeletonCard";
import { icons } from "../../GlobalThemes";
import ReportTypes from "../../libraries/report-types";
import * as ReportTypesComponentInterface from "../../libraries/report-types/charts/interfaces/main";
import { transformBarChartData, transformTopChartData } from "../Dashboard/Processing";
import { findMaxGroupValue } from "../Dashboard/ProgramOverview/ProgramOverviewCalls";
// import * as Legends from '../../libraries/report-types/charts/main'
import { ResponsiveLine } from "@nivo/line";

type FetchData = {
	cardName: string;
	kpi: string[];
	month: string[];
	value: number[];
	unit: string[];
	vsPrevMonth: string[];
	vsPrevMonthArray: string[];
	topChart: {
		[id: string]: number;
	};
	chartName: string[];
	xAxisLabel: string[];
	yAxisLabel: string[];
	chartColor: string[];
	chartAccessor: string[];
	footer: string;
	storyData?: {
		id?: string;
		storyName?: string;
	}[];
	chartData: {
		[id: string]: {
			[id: string]: number;
		};
	}[];
};

function RenderDropdown(props: { options: string[]; view: number; setView: React.Dispatch<React.SetStateAction<number>> }) {
	var reversedArray = [...props.options];

	return (
		<div>
			<Select
				className="w-44 h-9"
				value={props.view}
				onChange={(value: number) => {
					props.setView(value);
				}}
			>
				{reversedArray?.map(
					(option, index) =>
						index < reversedArray.length && (
							<Select.Option value={index} key={index}>
								{option}
							</Select.Option>
						)
				)}
			</Select>
		</div>
	);
}

export function DisconRateRishBucket(props: { data: any }) {
	const [fetchedData, setFetchedData] = React.useState<FetchData | any>(null);
	const [loading, setLoading] = React.useState(true);
	const [view, setView] = React.useState(0);
	const [visible, setVisible] = React.useState(false);
	const [drawerHeader, setdrawerHeader] = React.useState("");
	const [drawerBody, setDrawerBody] = React.useState(<></>);
	const onClose = () => {
		setVisible(false);
	};

	React.useEffect(() => {
		setFetchedData(props.data);
		setLoading(false);
	}, []);

	const getTickValues = () => {
		let arr: any = [];
		const data = fetchedData?.chartData[view];
		for (const key in data) {
			arr.push(Object.values(data[key]));
		}
		return arr;
	};

	useEffect(() => {
		getTickValues();
	}, [view]);

	return (
		<>
			{!false ? (
				<div className="bg-white w-full rate-graph">
					<ReportTypes
						header={
							<>
								<div className="flex gap-2 font-semibold text-base">
									<p>{fetchedData?.cardName}</p>
								</div>
							</>
						}
						hideShadow
						hideRibbon={false}
						height="16rem"
						bodyPadding="0px 0px"
						title={{ title: "xx" }}
						chartType={ReportTypesComponentInterface.chartType.line}
						series={
							transformBarChartData(
								fetchedData?.chartData.length > 0 && fetchedData !== undefined ? fetchedData?.chartData[view] : [],
								fetchedData?.chartData.length > 0 && fetchedData !== undefined ? fetchedData?.chartColor : []
							).series
						}
						data={
							transformBarChartData(
								fetchedData?.chartData.length > 0 && fetchedData !== undefined ? fetchedData?.chartData[view] : [],
								fetchedData?.chartData.length > 0 && fetchedData !== undefined ? fetchedData?.chartColor : []
							).data
						}
						tooltip={{
							isInteractive: true,
							format: "z",
						}}
						yScale={{
							type: "linear",
							// min: 100,
							max: "auto",
							stacked: false,
							reverse: false,
						}}
						axis={{
							primaryX: {
								dim: 0,
								tickSize: 0,
								tickRotation: 0,
								legend: "",
								legendPosition: ReportTypesComponentInterface.axisLegendPosition.middle,
								legendOffset: 22,
							},
							primaryY: {
								tickSize: 3,
								tickValues: getTickValues()[0],
								tickRotation: 0,
								legend: fetchedData?.yAxisLabel[0],
								legendPosition: ReportTypesComponentInterface.axisLegendPosition.middle,
								legendOffset: -50,
								minValue: 0,
								maxValue: 20,
							},
						}}
						axisRight={{
							orient: "right",
							tickSize: 3,
							tickValues: getTickValues()[1],
							tickPadding: 5,
							tickRotation: 0,
							legend: "",
							legendOffset: -45,
							minValue: 0,
							maxValue: 100,
						}}
						ribbonData={<RenderDropdown options={fetchedData?.options} view={view} setView={setView} />}
						chartConfig={{
							cartesian: {
								line: {
									lineWidth: 3,
									pointSize: 9,
								},
								// groupMode: groupMode.grouped,
								grid: {
									enableGridX: false,
									enableGridY: true,
									gridYValues: 1,
									gridXValues: 8,
								},
							},
						}}
						style={{
							margin: {
								top: 20,
								right: 30,
								bottom: 50,
								left: 30,
							},
							axisWidth: 1,
							axisColor: "#E7E7E7",
							textColor: "#53565A",
							fontSize: 10,
						}}
						legend={{
							position: ReportTypesComponentInterface.legendPosition.bottom,
							direction: ReportTypesComponentInterface.legendDirection.row,
							symbolSize: 1,
							translateX: -5,
							translateY: 46,
							symbolShape: ReportTypesComponentInterface.legendSymbolShape.circle,
						}}
					/>
				</div>
			) : (
				<></>
			)}
		</>
	);
}
