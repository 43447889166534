import { Modal } from "antd";
import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import AddStoryModalContent from "../../Components/AddStoryModal";
import DeleteAlert from "../../Components/DeleteAlert";
import SubHeaderComponent from "../../Components/SubHeaderComponent";
import TableWrapper from "../../Components/TableWrapper";
import { storyTypes, dummyDataTable } from "./data";
import DataTable from "./DataTable";
import StoryMode from "../SearchEngine/Stories/storyMode";
import "./style.css";
import * as StoriesServiceHelper from "./../SearchEngine/ServiceHelpers/stories";
import * as Messages from "../../Components/Messages";
import * as NodeGraphServiceHelper from "../SearchEngine/ServiceHelpers/nodeGraph";

const dummyData = [
	{
		patientRiskBucket: [
			{
				cardName: "Low",
				value: "245",
				vsPrevMonth: -10,
			},
			{
				cardName: "Medium",
				value: "230",
				vsPrevMonth: -7,
			},
			{
				cardName: "High",
				value: "270",
				vsPrevMonth: 10,
			},
		],
	},
	{
		patientScore: {
			cardName: "#Members scorecard over time",
			yAxisLabel: [],
			chartData: [
				{
					"Jul 21": {
						Negative: 1136,
						Neutral: 353,
						Positive: 548,
					},
					"Aug 21": {
						Negative: 714,
						Neutral: 202,
						Positive: 305,
					},
					"Sep 21": {
						Negative: 1220,
						Neutral: 382,
						Positive: 579,
					},
					"Oct 21": {
						Negative: 1142,
						Neutral: 379,
						Positive: 543,
					},
					"Nov 21": {
						Negative: 1150,
						Neutral: 329,
						Positive: 529,
					},
					"Dec 21": {
						Negative: 1044,
						Neutral: 313,
						Positive: 530,
					},
					"Jan 22": {
						Negative: 1162,
						Neutral: 361,
						Positive: 574,
					},
					"Feb 22": {
						Negative: 1130,
						Neutral: 353,
						Positive: 550,
					},
					"Mar 22": {
						Negative: 1179,
						Neutral: 341,
						Positive: 528,
					},
					"Apr 22": {
						Negative: 937,
						Neutral: 297,
						Positive: 441,
					},
					"May 22": {
						Negative: 968,
						Neutral: 276,
						Positive: 401,
					},
				},
				{
					"Jul 21": {
						Negative: 1,
						Neutral: 0,
						Positive: 1,
					},
					"Aug 21": {
						Negative: 2,
						Neutral: 0,
						Positive: 0,
					},
					"Sep 21": {
						Negative: 1,
						Neutral: 1,
						Positive: 0,
					},
					"Nov 21": {
						Negative: 1,
						Neutral: 0,
						Positive: 0,
					},
					"Dec 21": {
						Negative: 3,
						Neutral: 0,
						Positive: 0,
					},
					"Jan 22": {
						Negative: 2,
						Neutral: 0,
						Positive: 0,
					},
					"Feb 22": {
						Negative: 3,
						Neutral: 1,
						Positive: 0,
					},
					"Mar 22": {
						Negative: 2,
						Neutral: 0,
						Positive: 0,
					},
					"Apr 22": {
						Negative: 2,
						Neutral: 0,
						Positive: 1,
					},
				},
				{
					"Jul 21": {
						Negative: 99,
						Neutral: 16,
						Positive: 21,
					},
					"Aug 21": {
						Negative: 62,
						Neutral: 13,
						Positive: 10,
					},
					"Sep 21": {
						Negative: 133,
						Neutral: 11,
						Positive: 15,
					},
					"Oct 21": {
						Negative: 109,
						Neutral: 16,
						Positive: 12,
					},
					"Nov 21": {
						Negative: 86,
						Neutral: 10,
						Positive: 10,
					},
					"Dec 21": {
						Negative: 74,
						Neutral: 11,
						Positive: 18,
					},
					"Jan 22": {
						Negative: 107,
						Neutral: 11,
						Positive: 9,
					},
					"Feb 22": {
						Negative: 84,
						Neutral: 18,
						Positive: 6,
					},
					"Mar 22": {
						Negative: 77,
						Neutral: 9,
						Positive: 15,
					},
					"Apr 22": {
						Negative: 49,
						Neutral: 10,
						Positive: 8,
					},
					"May 22": {
						Negative: 64,
						Neutral: 8,
						Positive: 5,
					},
				},
				{
					"Jul 21": {
						Negative: 0,
						Neutral: 1,
						Positive: 1,
					},
					"Aug 21": {
						Negative: 3,
						Neutral: 0,
						Positive: 0,
					},
					"Sep 21": {
						Negative: 4,
						Neutral: 0,
						Positive: 1,
					},
					"Oct 21": {
						Negative: 3,
						Neutral: 1,
						Positive: 0,
					},
					"Nov 21": {
						Negative: 6,
						Neutral: 0,
						Positive: 1,
					},
					"Jan 22": {
						Negative: 3,
						Neutral: 0,
						Positive: 0,
					},
					"Feb 22": {
						Negative: 3,
						Neutral: 0,
						Positive: 0,
					},
					"Mar 22": {
						Negative: 0,
						Neutral: 0,
						Positive: 1,
					},
					"Apr 22": {
						Negative: 2,
						Neutral: 1,
						Positive: 0,
					},
					"May 22": {
						Negative: 1,
						Neutral: 0,
						Positive: 0,
					},
				},
				{
					"Jul 21": {
						Negative: 259,
						Neutral: 48,
						Positive: 51,
					},
					"Aug 21": {
						Negative: 155,
						Neutral: 25,
						Positive: 31,
					},
					"Sep 21": {
						Negative: 267,
						Neutral: 47,
						Positive: 68,
					},
					"Oct 21": {
						Negative: 237,
						Neutral: 39,
						Positive: 73,
					},
					"Nov 21": {
						Negative: 247,
						Neutral: 33,
						Positive: 59,
					},
					"Dec 21": {
						Negative: 219,
						Neutral: 38,
						Positive: 52,
					},
					"Jan 22": {
						Negative: 208,
						Neutral: 33,
						Positive: 53,
					},
					"Feb 22": {
						Negative: 244,
						Neutral: 36,
						Positive: 57,
					},
					"Mar 22": {
						Negative: 222,
						Neutral: 33,
						Positive: 53,
					},
					"Apr 22": {
						Negative: 181,
						Neutral: 34,
						Positive: 46,
					},
					"May 22": {
						Negative: 179,
						Neutral: 30,
						Positive: 22,
					},
				},
				{
					"Jul 21": {
						Negative: 8,
						Neutral: 1,
						Positive: 6,
					},
					"Aug 21": {
						Negative: 10,
						Neutral: 1,
						Positive: 2,
					},
					"Sep 21": {
						Negative: 4,
						Neutral: 1,
						Positive: 1,
					},
					"Oct 21": {
						Negative: 1,
						Neutral: 0,
						Positive: 1,
					},
					"Nov 21": {
						Negative: 2,
						Neutral: 0,
						Positive: 0,
					},
					"Dec 21": {
						Negative: 2,
						Neutral: 1,
						Positive: 0,
					},
					"Jan 22": {
						Negative: 4,
						Neutral: 0,
						Positive: 1,
					},
					"Feb 22": {
						Negative: 1,
						Neutral: 0,
						Positive: 0,
					},
					"Mar 22": {
						Negative: 0,
						Neutral: 1,
						Positive: 0,
					},
					"May 22": {
						Negative: 2,
						Neutral: 0,
						Positive: 0,
					},
				},
				{
					"Jul 21": {
						Negative: 62,
						Neutral: 16,
						Positive: 21,
					},
					"Aug 21": {
						Negative: 59,
						Neutral: 12,
						Positive: 9,
					},
					"Sep 21": {
						Negative: 70,
						Neutral: 24,
						Positive: 26,
					},
					"Oct 21": {
						Negative: 72,
						Neutral: 28,
						Positive: 23,
					},
					"Nov 21": {
						Negative: 84,
						Neutral: 22,
						Positive: 28,
					},
					"Dec 21": {
						Negative: 95,
						Neutral: 25,
						Positive: 42,
					},
					"Jan 22": {
						Negative: 107,
						Neutral: 32,
						Positive: 36,
					},
					"Feb 22": {
						Negative: 106,
						Neutral: 25,
						Positive: 54,
					},
					"Mar 22": {
						Negative: 80,
						Neutral: 36,
						Positive: 45,
					},
					"Apr 22": {
						Negative: 81,
						Neutral: 26,
						Positive: 58,
					},
					"May 22": {
						Negative: 113,
						Neutral: 23,
						Positive: 60,
					},
				},
				{
					"Jul 21": {
						Negative: 2,
						Neutral: 1,
						Positive: 2,
					},
					"Aug 21": {
						Negative: 3,
						Neutral: 3,
						Positive: 1,
					},
					"Sep 21": {
						Negative: 8,
						Neutral: 1,
						Positive: 1,
					},
					"Oct 21": {
						Negative: 9,
						Neutral: 2,
						Positive: 1,
					},
					"Nov 21": {
						Negative: 3,
						Neutral: 2,
						Positive: 4,
					},
					"Dec 21": {
						Negative: 1,
						Neutral: 2,
						Positive: 4,
					},
					"Jan 22": {
						Negative: 4,
						Neutral: 2,
						Positive: 8,
					},
					"Feb 22": {
						Negative: 6,
						Neutral: 1,
						Positive: 4,
					},
					"Mar 22": {
						Negative: 5,
						Neutral: 0,
						Positive: 0,
					},
					"Apr 22": {
						Negative: 5,
						Neutral: 2,
						Positive: 0,
					},
					"May 22": {
						Negative: 4,
						Neutral: 3,
						Positive: 2,
					},
				},
				{
					"Jul 21": {
						Negative: 650,
						Neutral: 231,
						Positive: 384,
					},
					"Aug 21": {
						Negative: 375,
						Neutral: 140,
						Positive: 215,
					},
					"Sep 21": {
						Negative: 667,
						Neutral: 256,
						Positive: 409,
					},
					"Oct 21": {
						Negative: 658,
						Neutral: 267,
						Positive: 373,
					},
					"Nov 21": {
						Negative: 673,
						Neutral: 229,
						Positive: 382,
					},
					"Dec 21": {
						Negative: 592,
						Neutral: 207,
						Positive: 372,
					},
					"Jan 22": {
						Negative: 675,
						Neutral: 244,
						Positive: 40,
					},
					"Feb 22": {
						Negative: 617,
						Neutral: 243,
						Positive: 370,
					},
					"Mar 22": {
						Negative: 734,
						Neutral: 235,
						Positive: 373,
					},
					"Apr 22": {
						Negative: 555,
						Neutral: 195,
						Positive: 284,
					},
					"May 22": {
						Negative: 564,
						Neutral: 192,
						Positive: 284,
					},
				},
				{
					"Jul 21": {
						Negative: 55,
						Neutral: 39,
						Positive: 61,
					},
					"Aug 21": {
						Negative: 45,
						Neutral: 8,
						Positive: 37,
					},
					"Sep 21": {
						Negative: 66,
						Neutral: 41,
						Positive: 58,
					},
					"Oct 21": {
						Negative: 53,
						Neutral: 26,
						Positive: 60,
					},
					"Nov 21": {
						Negative: 48,
						Neutral: 33,
						Positive: 45,
					},
					"Dec 21": {
						Negative: 58,
						Neutral: 29,
						Positive: 42,
					},
					"Jan 22": {
						Negative: 52,
						Neutral: 39,
						Positive: 61,
					},
					"Feb 22": {
						Negative: 66,
						Neutral: 29,
						Positive: 59,
					},
					"Mar 22": {
						Negative: 59,
						Neutral: 27,
						Positive: 41,
					},
					"Apr 22": {
						Negative: 62,
						Neutral: 29,
						Positive: 44,
					},
					"May 22": {
						Negative: 41,
						Neutral: 20,
						Positive: 28,
					},
				},
			],
			chartColor: ["#76BCEE", "#FFC736", "#EE6767"],
		},
	},
	{
		dummyDataTable: [
			{
				Member_Id: "ZSID001",
				Line_of_Business: "Commercial",
				Current_Plan: "HMO",
				Probability_of_Churn: "High",
				Top_Drivers_of_Churn: "1. High Premium Cost [C]\n2. Attractive Options elsewhere [C]\n3. Total time on Plan [S]",
				Number_of_Support_Interactions_in_last_6_months: 3,
				"Member_Satisfaction_Score(10)": 3.5,
			},
			{
				Member_Id: "ZSID002",
				Line_of_Business: "Commercial",
				Current_Plan: "PPO",
				Probability_of_Churn: "High",
				Top_Drivers_of_Churn: "1. Limited in-network Access to Providers [C]\n2. Change in Risk Score [S]\n3. Slow Claims Processing [C]",
				Number_of_Support_Interactions_in_last_6_months: 6,
				"Member_Satisfaction_Score(10)": 4,
			},
			{
				Member_Id: "ZSID003",
				Line_of_Business: "Medicare",
				Current_Plan: "MSA",
				Probability_of_Churn: "High",
				Top_Drivers_of_Churn: "1. Negative Experience with Care [C]\n2. Billing Errors [C]\n3. Number of claim requests in last 3 months [S]",
				Number_of_Support_Interactions_in_last_6_months: 5,
				"Member_Satisfaction_Score(10)": 4,
			},
			{
				Member_Id: "ZSID004",
				Line_of_Business: "Medicaid",
				Current_Plan: "SSI",
				Probability_of_Churn: "Medium",
				Top_Drivers_of_Churn: "1. Frustration with Customer Service [C]\n2. Avg. Cost of claims [S]\n3. Confusion on Plan Changes [C]",
				Number_of_Support_Interactions_in_last_6_months: 2,
				"Member_Satisfaction_Score(10)": 5,
			},
			{
				Member_Id: "ZSID005",
				Line_of_Business: "Commercial",
				Current_Plan: "HDHP",
				Probability_of_Churn: "Medium",
				Top_Drivers_of_Churn: "1. Confusion on Coverage Benefits [C]\n2. Dis-satisfaction with Provider Quality [C]\n3. SDoH Factors [S]",
				Number_of_Support_Interactions_in_last_6_months: 1,
				"Member_Satisfaction_Score(10)": 6,
			},
			{
				Member_Id: "ZSID006",
				Line_of_Business: "Commercial",
				Current_Plan: "EPO",
				Probability_of_Churn: "Medium",
				Top_Drivers_of_Churn: "1. Difficulty Navigating Online Resources [C]\n2. Age [S]\n3. External Influences [C]",
				Number_of_Support_Interactions_in_last_6_months: 2,
				"Member_Satisfaction_Score(10)": 6,
			},
		],
	},
];

/**
 * Renders the Stories Table. This function also handles the APIs for this table.
 *
 * @returns Stories Table View.
 */
function StoriesTable(): JSX.Element {
	const [fetching, setFetching] = React.useState(true);
	const [fetchedData, setFetchedData] = React.useState<any>();
	const [isModalVisible, setIsModalVisible] = React.useState(false);
	const [alertVisible, setAlertVisible] = React.useState(false);
	const [modalContent, setModalContent] = React.useState(<></>);
	const [selectDelete, setSelectDelete] = React.useState("");

	// TODO Add service call here to fetch data
	async function fetchData() {
		let data: any = await NodeGraphServiceHelper.getNodeJsons(
			//   "kural/json_data/hnp_discon_preds.txt"
			// "kural_hpp_demo/json_data/hnp_discon_preds.txt"
			"kural_hpp_demo/predictive_dashboard_data/hnp_discon_preds.txt"
		);
		if (data) {
			data = eval("(" + data + ")");
			console.log("Discon Table data: ", data[2]["dummyDataTable"]);
			// setFetchedData(dummyData[2]["dummyDataTable"]);
			setFetchedData(data[2]["dummyDataTable"]);
			setFetching(false);
		}
	}

	React.useEffect(() => {
		// 	// console.log("Discon Table data: ", dummyDataTable);
		// 	// console.log("Discon Table data: ", data[2]["dummyDataTable"]);
		// 	setFetchedData(data[2]["dummyDataTable"]);
		// 	setFetching(false);
		fetchData();
	}, []);

	return (
		<>
			{!fetching ? (
				<div>
					<div className="bg-body py-4">
						<>{console.log("Not Fetching", fetchedData)}</>
						<DataTable data={fetchedData} loading={false} />
					</div>
				</div>
			) : (
				<>
					<div>
						<div className="shadow-lg relative">
							<SubHeaderComponent title="Stories" />
						</div>
						<div className="stories-body bg-body p-4">
							<>{console.log("Fetching", fetchedData)}</>
							<DataTable data={{}} loading={true} />
						</div>
					</div>
				</>
			)}
		</>
	);
}
/**
 * Renders the Stories Tab View. This function sets up the routes within the Stories Tab.
 *
 * @returns Stories Tab View Routes.
 */
export function PatientDisconTable() {
	return (
		<div className="">
			<Routes>
				<Route key={"Stories 0"} path="/" element={<StoriesTable />} />
				<Route key="Stories - Stories Mode" path="story-mode/:storyId/*" element={<StoryMode />} />
			</Routes>
		</div>
	);
}
