import { Input, Popover } from "antd";
import React from "react";
import { icons } from "../../GlobalThemes";
import "./style.css";
export default function Tags(props: {
  data: string[];
  type?: string;
  add?: any;
  remove?: any;
  visible?: boolean;
  handleAdd?: (tagName: string) => void;
  handleCancel?: () => void;
  popupContent?: JSX.Element;
}) {
  const [showInput, setShowInput] = React.useState(false);
  return (
    <>
      {props.type === "Header" && (
        <div className="flex flex-wrap justify-start items-center text-sm text-acordion-tag-text">
          {props.data.map((tag: string) => {
            return (
              <div className="bg-acordion-tag border-2 rounded-sm border-acordion-tag-border mr-4 px-2 mb-4">
                {tag}
                {props.remove && (
                  <span onClick={() => props.remove(tag)} className="cursor-pointer">
                    {icons.find((l) => l.id === "Remove")?.icon}
                  </span>
                )}
              </div>
            );
          })}
          {props.add && (
            <>
              <div className="bg-acordion-tag border-2 rounded-sm border-acordion-tag-border mr-4 px-2 mb-4">
                {/* {!showInput ? (
									<>
										Add New Tag
										<span
											onClick={() => {
												setShowInput(!showInput);
												props.add();
											}}
											className="cursor-pointer"
										>
											{
												icons.find(
													(l) => l.id === "Add"
												)?.icon
											}
										</span>
									</>
								) : (
									<>{props.popupContent}</>
								)} */}
                <>
                  Add New Tag
                  <span
                    onClick={() => {
                      setShowInput(!showInput);
                      props.add();
                    }}
                    className="cursor-pointer"
                  >
                    {icons.find((l) => l.id === "Add")?.icon}
                  </span>
                </>
              </div>
              {props.popupContent && (
                <Popover
                  arrowContent
                  destroyTooltipOnHide={true}
                  content={props.popupContent}
                  title={
                    <div className="flex justify-between">
                      <div>Add Tag</div>
                      <div
                        className="font-normal text-sm text-blue-500 cursor-pointer"
                        onClick={() => {
                          if (props.handleCancel) props.handleCancel();
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                  }
                  trigger="click"
                  visible={props.visible}
                  placement="bottom"
                ></Popover>
              )}
            </>
          )}
        </div>
      )}
      {(!props.type || props.type !== "Header") && (
        <div className="flex flex-wrap justify-start items-center text-sm text-acordion-description-tag-text">
          {props.data.map((tag: string, index: any) => {
            return (
              tag &&
              (tag === "Good" || tag === "Bad" || tag === "Average" ? (
                <></>
              ) : (
                <div className="bg-acordion-description-tag border-2 rounded-sm border-acordion-description-tag-border mr-4 px-2">
                  {tag}
                </div>
              ))
            );
          })}
        </div>
      )}
    </>
  );
}
