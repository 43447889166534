import { Link } from "react-router-dom";
import { icons } from "../../GlobalThemes";

/**
 * This function is used to render the cards on app landing page
 *
 * @remarks - This function is used inside LandingBody function to render the cards in a loop
 *
 * @param name - This props is used to print the name of the card
 * @param comingSoon - This props shows whether card is launched or coming soon
 * @param link - This props is used to redirect to the link when card is clicked
 * @param type - This props is used to render the type of icon
 *
 * @returns the single card with card name and route link
 */

export default function LandingCards(props: { name: string; comingSoon?: boolean; link?: string; type?: number }) {
	const handleClick = () => {
		if (props.link === "nan") {
			window.location.href = "https://kural.test.zsservices.com/dashboard";
		}
	};
	return (
		<div className="container sm:w-1/2 md:w-1/3 xl:w-1/4 max-w-sm">
			<Link to={props.link ? (props.link !== "nan" ? props.link : "") : ""} onClick={handleClick}>
				<div className="mx-2 my-2 bg-white h-48 shadow-lg shadow-gray-900 hover:shadow-xl hover:bg-gray-50 transition scaling-class cursor-pointer">
					<div
						className={`flex justify-center items-center ${
							props.type === 1 || props.type == undefined ? "bg-landingCard-teal text-text-teal" : "bg-landingCard-orange text-text-orange"
						} h-1/2 w-full`}
					>
						{props.name === "Dashboard" ? icons.find((l) => l.id === `${props.name}${props.type}`)?.icon : icons.find((l) => l.id === `${props.name}`)?.icon}
					</div>
					<div className="flex flex-col justify-between h-1/2 w-full">
						<div className="h-10 px-4 py-2 ">
							<p className="font-semibold text-lg text-text-gray-100">{props.name}</p>
							{props.comingSoon && <p className="text-text-50">Coming Soon...</p>}
						</div>
						{props.link && (
							<div className=" flex justify-end mx-4 py-2 text-base font-medium ">
								<Link to={props.link}>
									<div
										className={`flex cursor-pointer ${
											props.type === 1 || props.type === undefined ? "text-text-teal hover:text-text-orange" : "text-text-orange hover:text-text-teal"
										}`}
									>
										<p>Launch</p>
										{icons.find((l) => l.id === "AngleRight")?.icon}
									</div>
								</Link>
							</div>
						)}
					</div>
				</div>
			</Link>
		</div>
	);
}
