import React, { useEffect, useState, useRef, HtmlHTMLAttributes } from "react";
import PatientNeedsDrivers from "./PatientNeedsDrivers";
import { patientScoreChartData, patientRiskBucket } from "./data";
import { Header } from "../../Components/ReportCards/CommonCardComponents/Header";
import { NumberData } from "../../Components/ReportCards/CommonCardComponents/NumberData";
import { ArrowLegendDiscon } from "../../Components/ReportCards/CommonCardComponents/ArrowLegendDiscon";
import ScatterPlotChart from "../../Components/ScatterPlotChart";

import { Button, DatePicker, Input, Pagination, Select, List, message, Skeleton, Tag, Empty, Slider, Switch } from "antd";
import { DriversSummaryTranscript } from "./DriversSummaryTranscript";
import * as ReportTypesComponentInterface from "../../libraries/report-types/charts/interfaces/main";
import { ResponsiveLine } from "@nivo/line";
import { icons } from "../../GlobalThemes";
import { FilterInterface } from "../Dashboard";
import { Drawer as AntDrawer } from "antd";
import Drawer from "../Dashboard/Components/Drawer";
import { FilterCard } from "../../Components/FilterCard";
import { usData } from "../../libraries/report-types/charts/geoMap/dataUS";
import { Choropleth, ResponsiveChoropleth } from "@nivo/geo";
import { usFeatures } from "../../libraries/report-types/charts/geoMap/geoUSFeatures";
import TranscriptsWrapper from "../HighPatientNeeds/TransciptsWrapper";
import ReactWordcloud from "react-wordcloud";
import {
	DUMMY_DATA,
	fetchedFilters,
	searchFilterActiveStatus,
	words,
	driverSummaryTranscript,
	dummyDataDriversSummary,
	hppChurnPredictionsData,
	hppDriversSummaryData,
} from "./data";
import { SearchFilters } from "../../Components/SearchEngineCards/SearchFilters";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import * as StoriesServiceHelper from "./../SearchEngine/ServiceHelpers/stories";
import * as SearchServiceHelper from "./../SearchEngine/ServiceHelpers/search";
import moment from "moment";
import * as Messages from "../../Components/Messages";
import AppContext from "../../store";
import { ResponsiveBar } from "@nivo/bar";
import ReportTypes from "../../libraries/report-types";
// import { scatterPlotData } from "../../Pages/HighPatientNeeds/Discon/data";
import * as NodeGraphServiceHelper from "../SearchEngine/ServiceHelpers/nodeGraph";
export interface searchQuery {
	deepSearch?: boolean;
	testButton1?: boolean;
	testButton2?: boolean;
	testButton3?: boolean;
	testButton4?: boolean;
	testButton5?: boolean;
	searchWord?: string;
	startDate?: string | any;
	endDate?: string | any;
	speaker?: string;
	showResults?: boolean;
	storyId?: string;
	callTypeIds?: number[];
	therapyStatusIds?: number[];
	emotions?: number[];
	emotionsGroup?: number[];
	sentiment?: number[];
	topics?: number[];
	totStart?: string;
	totEnd?: string;
}

export interface transcriptDataInter {
	numberOfPatients: number;
	numberOfSearchResults: number;
	emotionsCounts: any;
	statesResults: any;
	searchResults: {
		id: number;
		s3path: string;
		callDate: string;
		callId: string;
		callTime: string;
		callTypeIds: string;
		therapyStatusIds: string;
		dialogueNo: number;
		name: string;
		tags: string[];
		dialogueAppearedAt: number;
		dialogueLength: number;
		transcriptLength: number;
		addedToStory: boolean;
		storiesAdded: {
			storyId: any;
			storyName: string;
		}[];
		patientId: number;
		speaker: string;
		elasticSearchId: string;
		transcripts: {
			dialogueNo: number;
			speaker: string;
			text: string;
		}[];
		storyDialogues: any;
	}[];
}

/* tslint:disable */
export default function DriversSummary() {
	const [showSearchNodes, setSearchNodes] = useState<any>([]);
	const [showSadnessSideEffect, setSadnessSideEffect] = useState<string | undefined>();
	const [scatterPlot, setScatterPlot] = useState<string[] | undefined>();
	const [isLassoEnabled, setIsLassoEnabled] = useState<boolean>(false);

	const [showResultSummary, setShowResultSummary] = useState<boolean>(false);
	// const [scrollToTop, setScrollToTop] = React.useState(false);
	const [visible, setVisible] = React.useState(false);
	const [transcriptData, setTranscriptData] = useState<any[]>([]);
	const dataCopy = React.useRef<any[]>([]);
	const [pinnedStories, setPinnedStories] = useState<Array<any>>([]);
	const [fetchedStoryData, setFetchedStoryData] = useState<any>([]);
	const [transcriptLoading, setTranscriptLoading] = useState<boolean>(false);
	const [searchTranscriptQuery, setSearchTranscriptQuery] = useState<searchQuery>({});
	const [searchWordRequired, setsearchWordRequired] = useState<boolean>(false);
	const [searchFilterData, setSearchFilterData] = useState<any>();
	const [selectLevel, setSelectLevel] = useState<any>();
	const [therapyStatus, setTherapyStatus] = useState<any>();
	const [emptyTextField, setEmptyTextField] = useState("Enter a search word to see results");
	const [searchString, setSearchString] = React.useState("");
	const [selectedNode, setSelectedNode] = React.useState<any>(null);
	const [keywords, setKeywords] = React.useState<string[] | undefined>();
	const [dummyDataDriversSummary, setDummyDataDriversSummary] = React.useState<any>(null);
	const topRef = useRef<any>(null);
	const [patientRiskBucket, setPatientRiskBucket] = React.useState<any>(null);
	const [filters, setFilters] = React.useState<FilterInterface>({
		Timeframe_Display: undefined,
		Start_Date: "",
		End_Date: "",
		Call_Type: undefined,
		Therapy_Status: undefined,
		Call_Intent: undefined,
		Adherence: undefined,
		Sentiment: undefined,
		Topics: undefined,
		Emotions: new Array<string>(),
		totStart: undefined,
		totEnd: undefined,
	});
	const [fetchedFilters, setFetchedFilters] = React.useState<
		{
			filter: string;
			type: string;
			optionsObject: {
				id: number;
				name: string;
				subgroup: {
					id: number;
					name: string;
				}[];
			}[];
		}[]
	>([]);
	const [tempRData, setTempRData] = React.useState<any>(null);
	const [scatterPlotData, setScatterPlotData] = React.useState<any>([]);

	const temp: any = [];
	const arrForFilteringChart: any = [];
	const colors: Record<string, string> = {
		Inbound: "rgb(224,198,255)",
		Outbound: "rgb(114,50,207)",
		Agent: "rgb(169,218,253)",
		Patient: "rgb(169,218,253)",
		Positive: "rgb(38,195,141)",
		Neutral: "rgb(222,220,222)",
		Negative: "rgb(230,84,40)",
	};

	const handleFilters = (filterValue: any) => setSearchFilterData(filterValue);
	const handleFilters2 = (filterValue: string | boolean) => setSelectLevel(filterValue);
	const handleFilters3 = (filterValue: string | boolean) => setTherapyStatus(filterValue);
	const getColor = (bar: any) => colors[bar.id];
	const showDrawer = () => setVisible(true);
	const onClose = () => setVisible(false);

	// async function fetchFilters() {
	//     const data: any = await SearchServiceHelper.getFilters();
	//     data.push({
	//         filter: "TOT",
	//         optionsObject: [],
	//         type: "slider",
	//     });
	//     if (data) {
	//         setFetchedFilters(data);
	//     }
	// }

	// let scatterPlotData: any = [];
	// let scatterPlotData: any = hppDriversSummaryData[2]["scatterPlotData"];
	async function fetchStoryData() {
		// TODO Make API call here
		// const data: any = await StoriesServiceHelper.getAllStories();

		let data: any = await NodeGraphServiceHelper.getNodeJsons(
			// "kural/json_data/hnp_discon_drivers.txt"
			// "kural_hpp_demo/json_data/hnp_discon_drivers.txt"
			"kural_hpp_demo/predictive_dashboard_data/hnp_discon_drivers.txt"
		);
		// let data: any = hppDriversSummaryData;

		// const data2: any = await NodeGraphServiceHelper.getNodeJsons("kural/json_data/hnp_discon_preds.txt");
		// const data2: any = await NodeGraphServiceHelper.getNodeJsons("kural_hpp_demo/json_data/hnp_discon_preds.txt");
		let data2: any = await NodeGraphServiceHelper.getNodeJsons("kural_hpp_demo/predictive_dashboard_data/hnp_discon_preds.txt");
		// let data2: any = hppChurnPredictionsData;

		if (data) {
			data = eval("(" + data + ")");
			console.log(data);
			setDummyDataDriversSummary(data[3]["dummyDataDriversSummary"][0]);
			setTempRData(data[1]["tempR"]);
			setScatterPlot(data[2]["scatterPlotData"]);
			setScatterPlotData(data[2]["scatterPlotData"]);
			console.log("CHECK");
		}

		if (data2) {
			data2 = eval("(" + data2 + ")");
			setPatientRiskBucket(data2[0]["patientRiskBucket"]);
		}

		if (driverSummaryTranscript) {
			setFetchedStoryData(driverSummaryTranscript);
		}
	}

	// async function addNewStory(addData: {
	//     "Story Name": string;
	//     "Story Type": string;
	// }) {
	//     const key = "updatable";
	//     Messages.loading("Adding Transcript", key);

	//     addData["Story Type"] = addData["Story Type"].split(" ").join("");
	//     // console.log(addData);
	//     const data: any = await StoriesServiceHelper.addNewStory(addData);
	//     if (data) {
	//         var temp: any = [];
	//         var d = data["Date Created"];
	//         data["Date Created"] = moment(d).format("DD MMM YYYY");
	//         temp = [data, ...fetchedStoryData];
	//         setFetchedStoryData(temp);
	//         Messages.success("Successfully Created New Story", key);
	//     } else Messages.error("Failed", key);
	// }

	// async function addStoryToTranscriptData(transcript: {
	//     id: any;
	//     name: any;
	//     tags: any;
	//     storiesAdded: any;
	//     source: number;
	//     dialogueNo: any;
	//     callId: any;
	//     callDate: any;
	//     callTime: any;
	//     s3path: any;
	//     dialogueAppearedAt: any;
	//     dialogueLength: any;
	//     transcriptLength: any;
	//     transcripts: any;
	//     patientId: any;
	//     speaker: any;
	//     elasticSearchId: any;
	//     storyDialogues: any;
	// }) {
	//     const key = "updatable";
	//     Messages.loading("Adding Transcript to Story", key);
	//     const data: any = await SearchServiceHelper.addToStory(transcript);
	//     // console.log("Fetched : ", data);
	//     if (data) {
	//         Messages.success("Successfully Added Transcript to Story", key);

	//         const temp = [...transcriptData];
	//         console.log("transc", transcriptData, temp, transcript);

	//         const idx = temp[0].searchResults.findIndex(
	//             (t) =>
	//                 t.callId === transcript.callId &&
	//                 t.dialogueNo === transcript.dialogueNo
	//         );

	//         temp[0].searchResults[idx].storiesAdded = data;
	//         if (data.length === 0) {
	//             temp[0].searchResults[idx].addedToStory = false;
	//         }
	//         setTranscriptData(temp);

	//         return true;
	//     } else {
	//         Messages.error("Failed", key);
	//         return false;
	//     }
	// }

	const clearFilters = () => {
		const obj = {
			Timeframe_Display: undefined,
			Start_Date: "",
			End_Date: "",
			Call_Type: undefined,
			Therapy_Status: undefined,
			Call_Intent: undefined,
			Adherence: undefined,
			Sentiment: undefined,
			Topics: undefined,
			Emotions: new Array<string>(),
			totStart: undefined,
			totEnd: undefined,
		};
		setFilters(obj);
		setVisible(false);
		setTranscriptFilters(obj);
	};

	const fetchTranscripts = async (query: searchQuery) => {
		setTranscriptLoading(true);
		Messages.loading("Fetching...", "key1");
		console.log("the search query coming in ", query);
		//const data: any = await SearchServiceHelper.getAllTranscripts(query);
		setTranscriptLoading(false);
		const key = "Key 1";
		//console.log("fetch transcript is called after the node click", query, data);
		if (dummyDataDriversSummary && query?.searchWord !== "") {
			let dummyDataDriversSuffle = dummyDataDriversSummary;
			dummyDataDriversSuffle.searchResults = shuffleArrayObject(dummyDataDriversSummary.searchResults);
			setTranscriptData([dummyDataDriversSuffle]);
			dataCopy.current = [dummyDataDriversSuffle];
			console.log("fetch transcript is called after the node click", query, dummyDataDriversSuffle);
			Messages.success("Success", "key1");
			topRef.current?.querySelector("#custom-transcript").scrollIntoView({ behavior: "smooth" });
		} else {
			Messages.error("Fetching failed", "key1");
			setTranscriptData([]);
		}
	};
	const shuffleArrayObject = (array: any) => {
		array.reverse().forEach((item: any, index: any) => {
			const j = Math.floor(Math.random() * (index + 1));
			[array[index], array[j]] = [array[j], array[index]];
		});

		return array;
	};
	// const addToStorySearch = (transcriptData: any, storyId: any) => {
	//     var add: any = [];
	//     storyId.forEach((story: any, index: number) => {
	//         add.push({
	//             storyId: story,
	//             storyName:
	//                 fetchedStoryData[
	//                     fetchedStoryData.findIndex(
	//                         (storyFetch: any) => storyFetch.id === story
	//                     )
	//                 ].storyName,
	//         });
	//     });

	//     var trans = {
	//         id: transcriptData.id,
	//         name: transcriptData.name,
	//         tags: transcriptData.tags,
	//         storiesAdded: add,
	//         source: 1,
	//         dialogueNo: transcriptData.dialogueNo,
	//         callId: transcriptData.callId,
	//         callDate: transcriptData.callDate,
	//         callTime: transcriptData.callTime,
	//         s3path: transcriptData.s3path,
	//         dialogueAppearedAt: transcriptData.dialogueAppearedAt,
	//         dialogueLength: transcriptData.dialogueLength,
	//         transcriptLength: transcriptData.transcriptLength,
	//         transcripts: transcriptData.transcripts,
	//         patientId: transcriptData.patientId,
	//         speaker: transcriptData.speaker,
	//         elasticSearchId: transcriptData.elasticSearchId,
	//         storyDialogues: transcriptData.storyDialogues,
	//     };
	//     const d = addStoryToTranscriptData(trans);
	//     return d;
	// };

	// const changePinnedStories = (stories: any) => {
	//     setPinnedStories(stories);
	// };

	const addSearchFilters = (query: searchQuery) => {
		var searchFilter: searchQuery = searchTranscriptQuery;
		searchFilter.searchWord = query.searchWord;
		searchFilter.speaker = query.speaker;
		if (query.startDate) {
			searchFilter.startDate = moment(query.startDate).startOf("month").add(1, "days");
			searchFilter.endDate = moment(query.endDate).add(1, "months");
		}
		searchFilter.deepSearch = query.deepSearch;
		searchFilter.testButton1 = query.testButton1;
		searchFilter.testButton2 = query.testButton2;
		searchFilter.testButton3 = query.testButton3;
		searchFilter.testButton4 = query.testButton4;
		searchFilter.testButton5 = query.testButton5;
		checksearchWord(searchFilter);
		setSearchTranscriptQuery(searchFilter);
	};

	const findId = (filter: string, value: string) => {
		const grpIdx: number[] = [];
		const idx: number[] = [];
		fetchedFilters.map((f) => {
			f.optionsObject.map((group) => {
				if (filter === f.filter) {
					if (group.subgroup.length === 0 && value === group.name) {
						idx.push(group.id);
						grpIdx.push(group.id);
						return group.id;
					} else if (group.subgroup.length > 0) {
						if (Array.isArray(value) && value.find((f) => f === group.name)) {
							grpIdx.push(group.id);
						} else if (Array.isArray(value)) {
							value.map((v) => {
								const ob = group.subgroup.find((sg) => sg.name === v);
								if (ob) {
									grpIdx.push(group.id);
									idx.push(ob.id);
								}
								return -1;
							});
						}
					}
				}
			});
		});
		return { idx, grpIdx };
	};

	const setTranscriptFilters = (filter: any) => {
		console.log("Filter", filter);
		var transcriptFilters: searchQuery = searchTranscriptQuery;
		const callType = findId("Call_Type", filter.Call_Type);
		transcriptFilters.callTypeIds = callType.grpIdx;

		const therapyStatus = findId("Therapy_Status", filter.Therapy_Status);
		transcriptFilters.therapyStatusIds = therapyStatus.grpIdx;

		const sentiment = findId("Sentiment", filter.Sentiment);
		transcriptFilters.sentiment = sentiment.grpIdx;

		const topics = findId("Topics", filter.Topics);
		transcriptFilters.topics = topics.grpIdx;

		const emotions = findId("Emotions", filter.Emotions);
		transcriptFilters.emotionsGroup = emotions.grpIdx;
		transcriptFilters.emotions = emotions.idx;

		transcriptFilters.totStart = filter.totStart;
		transcriptFilters.totEnd = filter.totEnd;

		console.log("TranscriptFilter", transcriptFilters);
		setFilters(filter);
		checksearchWord(transcriptFilters);
		setSearchTranscriptQuery(transcriptFilters);
	};

	const checksearchWord = (query: searchQuery) => {
		if (query.searchWord === undefined || query.searchWord === "") {
			setsearchWordRequired(true);
		} else {
			setsearchWordRequired(false);
			setEmptyTextField("No Data");
			console.log(query);
			fetchTranscripts(query);
		}
	};

	const findValueForTherapyStatusGraph = (therapyStatus: any, bound: any, filterVal: any) => {
		let count = 0;
		transcriptData[0].searchResults.map((i: any) => {
			if (
				i.tags[2] === therapyStatus &&
				i.tags[1] === bound &&
				(filterVal.length === 1
					? i.tags[0] === filterVal[0]
					: filterVal.length === 2
					? i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1]
					: i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1] || i.tags[0] === filterVal[2])
			)
				++count;
		});
		return count;
	};

	const findValueForSentimentGraph = (patient: any, sentiment: any, filterVal: any, useTopic: boolean) => {
		if (useTopic === true) {
			let count = 0;
			transcriptData[0].searchResults.map((i: any) => {
				if (
					i.tags[4] === sentiment &&
					i.tags[5] === patient &&
					(filterVal.length === 1
						? i.tags[0] === filterVal[0]
						: filterVal.length === 2
						? i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1]
						: i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1] || i.tags[0] === filterVal[2])
				)
					++count;
			});

			return count;
		} else {
			let count = 0;
			transcriptData[0].searchResults.map((i: any) => {
				if (
					i.tags[4] === sentiment &&
					i.speaker === patient &&
					(filterVal.length === 1
						? i.tags[0] === filterVal[0]
						: filterVal.length === 2
						? i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1]
						: i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1] || i.tags[0] === filterVal[2])
				)
					++count;
			});

			return count;
		}
	};

	const findValueForToT = (ll: any, ul: any, filterVal: any, therapyStatus?: any) => {
		if (therapyStatus) {
			let count = 0;

			transcriptData[0].searchResults.map((i: any) => {
				if (
					i.tags[3] > ll &&
					i.tags[3] < ul &&
					i.tags[2] === therapyStatus &&
					(filterVal.length === 1
						? i.tags[0] === filterVal[0]
						: filterVal.length === 2
						? i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1]
						: i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1] || i.tags[0] === filterVal[2])
				)
					++count;
			});
			return count;
		} else {
			let count = 0;

			transcriptData[0].searchResults.map((i: any) => {
				if (
					i.tags[3] > ll &&
					i.tags[3] < ul &&
					(filterVal.length === 1
						? i.tags[0] === filterVal[0]
						: filterVal.length === 2
						? i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1]
						: i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1] || i.tags[0] === filterVal[2])
				)
					++count;
			});
			return count;
		}
	};

	if (transcriptData.length > 0) {
		let objs: any = transcriptData[0].emotionsCounts;
		objs.sort((a: any, b: any) => b.value - a.value);
		objs.map((i: any, index: any) => {
			if (index < 10) {
				temp.push(i);
			}
		});
	}

	if (transcriptData.length > 0) {
		let tempArr: any = [
			"Dosing Information",
			"Closing",
			"Speciality Pharmacy",
			"Common Side Effects",
			"Next Call Confirmation",
			"Introduction",
			"Lab Monitoring Guidance",
			"  Nurse Educator Role",
			"Others",
			"Patient Questionnaire",
			"Safety Recording",
			"Shipment",
		];

		for (let i = 0; i <= tempArr.length; i++) {
			if (
				findValueForSentimentGraph(tempArr[i], "Negative", searchFilterData, true) +
					findValueForSentimentGraph(tempArr[i], "Positive", searchFilterData, true) +
					+findValueForSentimentGraph(tempArr[i], "Neutral", searchFilterData, true) >
				0
			) {
				let obj: any = {};
				obj.Topic = tempArr[i];
				obj.Positive = findValueForSentimentGraph(tempArr[i], "Positive", searchFilterData, true);
				obj["positive Color"] = "hsl(251 70%, 50%)";
				obj.Neutral = findValueForSentimentGraph(tempArr[i], "Neutral", searchFilterData, true);
				obj["neutral Color"] = "hsl(41, 70%, 50%)";
				obj.Negative = findValueForSentimentGraph(tempArr[i], "Negative", searchFilterData, true);
				obj["negative Color"] = "hsl(41, 70%, 50%)";
				arrForFilteringChart.push(obj);
			}
		}
	}
	const getImg = (id: string, icon: string) => `<img id="${id}" src="/icons/${icon}.svg" alt="${icon}"/>`;

	useEffect(() => {
		if (showSadnessSideEffect) {
			setTranscriptData([]);
			let rest: any = scatterPlotData.find((d: any) => {
				return d.data.title == showSadnessSideEffect;
				//console.log(d)
			});
			if (rest?.data?.chartData.length > 0) {
				let dataForChart = rest.data.chartData;
				setIsLassoEnabled(false);
				setScatterPlot(dataForChart);
				//setXmin(d3.min(dataForChart, (d: any) => d.featureValue))
			}
		}
	}, [showSadnessSideEffect]);

	const scrollToTop = () => {
		topRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		// fetchFilters();
		fetchStoryData();
		setSearchFilterData(["Good"]);
	}, []);

	useEffect(() => {
		console.log("Selected Node - ", showSearchNodes);
		if (showSearchNodes.length > 0) {
			// setKeywords(
			//     //selectedNode.keywords.map((keyword: { name: any }) => keyword.name)
			// );
			console.log(showSearchNodes);
			fetchTranscripts({ searchWord: "headache" });
		} else {
			// fetchTranscripts({ searchWord: "" });
		}
	}, [showSearchNodes]);
	return (
		<>
			{tempRData && (
				<>
					<div className="drivers-summary" ref={topRef}>
						<div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4">
							<PatientNeedsDrivers onBarRectangleClick={setSadnessSideEffect} data={tempRData} />
							<div className="bg-white shadow-md shadow-gray-900 py-4  w-full">
								{showSadnessSideEffect && scatterPlot ? (
									<>
										<div className="font-semibold text-base px-4">
											<p>{showSadnessSideEffect}</p>
										</div>
										<div className="w-full">
											{/* <div className="grid lg:grid-row-2 md:grid-row-2 sm:grid-rows-1 gap-2">
												<div className="flex justify-between px-8 pt-5">
													{patientRiskBucket.length > 0
														? patientRiskBucket.map((risk: any) => (
																<div>
																	<h4 className="font-semibold mb-3">
																		<Header header={risk.cardName} />
																	</h4>
																	<div className="flex align-top justify-between">
																		<div className="font-semibold text-4xl">
																			<span>
																				{
																					<div className="flex items-center">
																						<div className="flex items-end flex-start ">
																							<ArrowLegendDiscon dataNumber={risk.vsPrevMonth} />
																						</div>
																						<div className="flex items-end flex-start">
																							<NumberData dataNumber={risk.value} />
																						</div>
																					</div>
																				}
																			</span>{" "}
																		</div>
																	</div>
																	<div className="text-sm text-gray-500 ml-1 mt-1">
																		<span>Vs Previous Month</span>
																	</div>
																</div>
														  ))
														: null}
												</div>
											</div> */}
											<br />
											<div className="font-semibold text-base mt-2">
												<div className="border-b-2 pb-3 mb-2 px-4 flex justify-between">
													<p>Driver dependency on member needs</p>
													<div
														className={"flex lasso-button " + (isLassoEnabled ? "active" : "")}
														style={{ cursor: "pointer" }}
														onClick={() => {
															setIsLassoEnabled(!isLassoEnabled);
														}}
													>
														{icons.find((f) => f.id === "LassoIcon")?.icon}
													</div>
													{/* lassoIcon
                      <img id="lasso-icon" src="/icons/lasso-icon.svg" alt="lasso-icon" /> */}
												</div>
												<ScatterPlotChart
													data={scatterPlot}
													onScatterPlatLoad={{ showSadnessSideEffect }}
													onDrawLassoEnd={setSearchNodes}
													isLassoEnable={isLassoEnabled}
													selectedDriver={showSadnessSideEffect}
												/>
											</div>
										</div>
									</>
								) : (
									<div className="middle-text">Select Member Drivers</div>
								)}
							</div>
						</div>
						<div id="custom-transcript" className="bg-body flex h-full py-4">
							<div
								className="bg-white shadow-md shadow-gray-900 "
								style={{
									overflowY: "auto",
									flexBasis: showResultSummary ? "65%" : "100%",
								}}
							>
								{showSadnessSideEffect && (
									<TranscriptsWrapper
										header={showSadnessSideEffect || ""}
										subHeader={`100 Conversations`}
										hideSummaryTags={true}
										noSummary={true}
										// expanded={expanded}
										toggleScrollToTop={scrollToTop}
										loading={transcriptLoading}
										noSideSummary={transcriptData.length > 0 ? false : true}
									>
										{transcriptLoading ? (
											<div>
												<Skeleton active className="py-4 px-8 border-b" />
												<Skeleton active className="py-4 px-8 border-b" />
												<Skeleton active className="py-4 px-8 border-b" />
											</div>
										) : (
											<div className={transcriptData.length > 0 ? "" : "flex items-center justify-around h-96"}>
												<List
													itemLayout="vertical"
													size="large"
													pagination={{
														hideOnSinglePage: true,
														style: {
															marginLeft: "2rem",
															marginBottom: "1.2rem",
															float: "left",
															paddingBottom: "2rem",
														},
														onChange: (page) => {
															console.log(page);
														},
														pageSize: 20,
													}}
													dataSource={transcriptData.length > 0 ? transcriptData[0].searchResults : []}
													locale={{
														emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>{emptyTextField}</span>} />,
													}}
													renderItem={(result, index) => (
														// @ts-ignore
														<DriversSummaryTranscript
															showTags={false}
															keywords={keywords}
															hideProgressBar={true}
															data={result}
															// expanded={expanded}
															// addToStoryApi={addToStorySearch}
															// pinnedStoriesData={pinnedStories}
															fetchedStoryData={fetchedStoryData}
															fetchStoryData={fetchStoryData}
															// addNewStory={addNewStory}
															// changePinnedStories={changePinnedStories}
															transcriptData={transcriptData.length > 0 ? transcriptData[0].searchResults : []}
															searchString={searchString}
															setSearchString={setSearchString}
														/>
													)}
												/>
											</div>
										)}
									</TranscriptsWrapper>
								)}
							</div>
							{showResultSummary ? (
								<div className="bg-white shadow-md shadow-gray-900 ml-4 p-4" style={{ flexBasis: "40%", overflowY: "auto" }}>
									<p className="font-semibold text-base">
										Search Result Summary
										<Select
											clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
											className="ml-4 float-right w-60"
											placeholder="Select Quality"
											allowClear
											mode="multiple"
											defaultValue={searchFilterData ? searchFilterData : ["High"]}
											onChange={(value: string) => {
												handleFilters(value);
											}}
										>
											<Select.Option value="Good">High</Select.Option>
											<Select.Option value="Average">Medium</Select.Option>
											<Select.Option value="Bad">Low</Select.Option>
										</Select>
									</p>
									<p className="font-semibold mt-5 text-sm mb-3">Therapy Status Distribution</p>
									<div className="flex h-60 border-2">
										<ResponsiveBar
											data={[
												{
													"Therapy Status": "Discontinued",
													Inbound: findValueForTherapyStatusGraph("Discontinued", "Inbound", searchFilterData),
													"inbound Color": "rgb(224,198,255)",
													Outbound: findValueForTherapyStatusGraph("Discontinued", "Outbound", searchFilterData),
													"outbound Color": "hsl(267, 100%, 88.8%)",
												},
												{
													"Therapy Status": "Therapy",
													Inbound: findValueForTherapyStatusGraph("On Therapy", "Inbound", searchFilterData),
													"inbound Color": "rgb(224,198,255)",
													Outbound: findValueForTherapyStatusGraph("On Therapy", "Outbound", searchFilterData),
													"outbound Color": "hsl(41, 70%, 50%)",
												},
												{
													"Therapy Status": "Therapy Repeat ",
													Inbound: findValueForTherapyStatusGraph("ON THERAPY REPEAT", "Inbound", searchFilterData),
													"inbound Color": "rgb(224,198,255)",
													Outbound: findValueForTherapyStatusGraph("ON THERAPY REPEAT", "Outbound", searchFilterData),
													"outbound Color": "hsl(41, 70%, 50%)",
												},
												{
													"Therapy Status": "In-Process",
													Inbound: findValueForTherapyStatusGraph("IN PROCESS", "Inbound", searchFilterData),
													"inbound Color": "rgb(224,198,255)",
													Outbound: findValueForTherapyStatusGraph("IN PROCESS", "Outbound", searchFilterData),
													"outbound Color": "hsl(41, 70%, 50%)",
												},
												{
													"Therapy Status": "Unknown",
													Inbound: findValueForTherapyStatusGraph("UNKNOWN", "Inbound", searchFilterData),
													"inbound Color": "rgb(224,198,255)",
													Outbound: findValueForTherapyStatusGraph("UNKNOWN", "Outbound", searchFilterData),
													"outbound Color": "hsl(41, 70%, 50%)",
												},
												{
													"Therapy Status": "Never Start",
													Inbound: findValueForTherapyStatusGraph("NEVER START", "Inbound", searchFilterData),
													"inbound Color": "rgb(224,198,255)",
													Outbound: findValueForTherapyStatusGraph("NEVER START", "Outbound", searchFilterData),
													"outbound Color": "hsl(41, 70%, 50%)",
												},
											]}
											keys={["Inbound", "Outbound"]}
											indexBy="Therapy Status"
											margin={{ top: 20, right: 20, bottom: 90, left: 35 }}
											padding={0.3}
											valueScale={{ type: "linear" }}
											indexScale={{ type: "band", round: true }}
											// colors={{ scheme: "nivo" }}
											colors={getColor}
											defs={[
												{
													id: "dots",
													type: "patternDots",
													background: "inherit",
													color: "#38bcb2",
													size: 4,
													padding: 1,
													stagger: true,
												},
												{
													id: "lines",
													type: "patternLines",
													background: "inherit",
													color: "#eed312",
													rotation: -45,
													lineWidth: 6,
													spacing: 10,
												},
											]}
											borderColor={{
												from: "color",
												modifiers: [["darker", 1.6]],
											}}
											axisTop={null}
											axisRight={null}
											axisBottom={{
												format: (v) => {
													return v.length > 14 ? (
														<tspan>
															{v.substring(0, 12) + ".."}
															<p>{v}</p>
														</tspan>
													) : (
														v
													);
												},
												tickSize: 5,
												tickPadding: 5,
												tickRotation: 0,
												legend: <tspan>Therapy Status</tspan>,
												legendPosition: "middle",
												legendOffset: 40,
											}}
											axisLeft={{
												format: (e) => Math.floor(e) === e && e,
												tickSize: 5,
												tickPadding: 5,
												tickRotation: 0,
												legend: "food",
												legendPosition: "middle",
												legendOffset: -40,
											}}
											labelSkipWidth={12}
											labelSkipHeight={12}
											labelTextColor={{
												from: "color",
												modifiers: [["darker", 1.6]],
											}}
											legends={[
												{
													dataFrom: "keys",
													anchor: "bottom",
													direction: "row",
													justify: false,
													translateX: 30,
													translateY: 80,
													itemsSpacing: 2,
													itemWidth: 100,
													itemHeight: 20,
													itemDirection: "left-to-right",
													itemOpacity: 0.85,
													symbolSize: 20,
													effects: [
														{
															on: "hover",
															style: {
																itemOpacity: 1,
															},
														},
													],
												},
											]}
											role="application"
											ariaLabel="Therapy Status Distribution"
										/>
									</div>
									<p className="font-semibold mt-5 text-sm mb-3">
										Discussion Sentiment
										<span className="font-normal float-right">
											Select Level
											<Select
												clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
												className="ml-4 -mt-2 float-right w-32"
												placeholder="Select Quality"
												allowClear
												defaultValue={selectLevel ? selectLevel : "Speaker"}
												onChange={(value: string) => {
													handleFilters2(value);
												}}
											>
												<Select.Option value="Topic">Topic</Select.Option>
												<Select.Option value="Speaker">Speaker</Select.Option>
											</Select>
										</span>
									</p>
									<div className="flex h-60 border-2">
										{selectLevel === "Topic" ? (
											<ResponsiveBar
												layout="horizontal"
												colors={getColor}
												data={arrForFilteringChart.map((i: any) => i)}
												keys={["Positive", "Neutral", "Negative"]}
												indexBy="Topic"
												margin={{ top: 10, right: 110, bottom: 30, left: 160 }}
												padding={0.3}
												valueScale={{ type: "linear" }}
												indexScale={{ type: "band", round: true }}
												defs={[
													{
														id: "dots",
														type: "patternDots",
														background: "inherit",
														color: "#38bcb2",
														size: 4,
														padding: 1,
														stagger: true,
													},
													{
														id: "lines",
														type: "patternLines",
														background: "inherit",
														color: "#eed312",
														rotation: -45,
														lineWidth: 6,
														spacing: 10,
													},
												]}
												borderColor={{
													from: "color",
													modifiers: [["darker", 1.6]],
												}}
												axisTop={null}
												axisRight={null}
												axisBottom={{
													tickSize: 0,
													tickPadding: 5,
													tickRotation: 0,
													legend: "",
													legendPosition: "middle",
													legendOffset: 38,
												}}
												axisLeft={{
													tickSize: 5,
													tickPadding: 5,
													tickRotation: 0,
													legendPosition: "middle",
													legendOffset: -40,
												}}
												labelSkipWidth={12}
												labelSkipHeight={12}
												labelTextColor={{
													from: "color",
													modifiers: [["darker", 1.6]],
												}}
												legends={[
													{
														dataFrom: "keys",
														anchor: "bottom-right",
														direction: "column",
														justify: false,
														translateX: 120,
														translateY: 0,
														itemsSpacing: 2,
														itemWidth: 100,
														itemHeight: 20,
														itemDirection: "left-to-right",
														itemOpacity: 0.85,
														symbolSize: 20,
														effects: [
															{
																on: "hover",
																style: {
																	itemOpacity: 1,
																},
															},
														],
													},
												]}
												role="application"
												ariaLabel="Therapy Status Distribution"
											/>
										) : (
											<ResponsiveBar
												layout="horizontal"
												colors={getColor}
												data={[
													{
														Sentiment: "Patient",
														Positive: findValueForSentimentGraph("Patient", "Positive", searchFilterData, false),
														"positive Color": "hsl(251, 70%, 50%)",
														Neutral: findValueForSentimentGraph("Patient", "Neutral", searchFilterData, false),
														"neutral Color": "hsl(41, 70%, 50%)",
														Negative: findValueForSentimentGraph("Patient", "Negative", searchFilterData, false),
														"negative Color": "hsl(41, 70%, 50%)",
													},
													{
														Sentiment: "Agent",
														Positive: findValueForSentimentGraph("Agent", "Positive", searchFilterData, false),
														"positive Color": "hsl(251, 70%, 50%)",
														Neutral: findValueForSentimentGraph("Agent", "Neutral", searchFilterData, false),
														"neutral Color": "hsl(41, 70%, 50%)",
														Negative: findValueForSentimentGraph("Agent", "Negative", searchFilterData, false),
														"negative Color": "hsl(41, 70%, 50%)",
													},
												]}
												keys={["Positive", "Neutral", "Negative"]}
												indexBy="Sentiment"
												margin={{ top: 20, right: 130, bottom: 50, left: 60 }}
												padding={0.3}
												valueScale={{ type: "linear" }}
												indexScale={{ type: "band", round: true }}
												defs={[
													{
														id: "dots",
														type: "patternDots",
														background: "inherit",
														color: "#38bcb2",
														size: 4,
														padding: 1,
														stagger: true,
													},
													{
														id: "lines",
														type: "patternLines",
														background: "inherit",
														color: "#eed312",
														rotation: -45,
														lineWidth: 6,
														spacing: 10,
													},
												]}
												borderColor={{
													from: "color",
													modifiers: [["darker", 1.6]],
												}}
												axisTop={null}
												axisRight={null}
												axisBottom={{
													tickSize: 0,
													tickPadding: 5,
													tickRotation: 0,
													legend: "",
													legendPosition: "middle",
													legendOffset: 38,
												}}
												axisLeft={{
													tickSize: 5,
													tickPadding: 5,
													tickRotation: 0,
													legendPosition: "middle",
													legendOffset: -40,
												}}
												labelSkipWidth={12}
												labelSkipHeight={12}
												labelTextColor={{
													from: "color",
													modifiers: [["darker", 1.6]],
												}}
												legends={[
													{
														dataFrom: "keys",
														anchor: "bottom-right",
														direction: "column",
														justify: false,
														translateX: 120,
														translateY: 0,
														itemsSpacing: 2,
														itemWidth: 100,
														itemHeight: 20,
														itemDirection: "left-to-right",
														itemOpacity: 0.85,
														symbolSize: 20,
														effects: [
															{
																on: "hover",
																style: {
																	itemOpacity: 1,
																},
															},
														],
													},
												]}
												role="application"
												ariaLabel="Therapy Status Distribution"
											/>
										)}
									</div>

									<p className="font-semibold mt-5 text-sm mb-3">
										Time on Therapy Distribution
										<span className="font-normal float-right">
											Show Therapy Status
											<Switch className="ml-2" onChange={handleFilters3} />
										</span>
									</p>
									<div className="flex h-60 border-2">
										{therapyStatus ? (
											<ResponsiveLine
												data={[
													{
														id: "On Therapy",
														color: "hsl(331, 70%, 50%)",
														data: [
															{
																x: "30 days",
																y: findValueForToT(-99999, 30, searchFilterData, "On Therapy"),
															},
															{
																x: "60 days",
																y: findValueForToT(30, 60, searchFilterData, "On Therapy"),
															},
															{
																x: "90 days",
																y: findValueForToT(60, 90, searchFilterData, "On Therapy"),
															},
															{
																x: "180 days",
																y: findValueForToT(90, 180, searchFilterData, "On Therapy"),
															},
															{
																x: "360 days",
																y: findValueForToT(180, 360, searchFilterData, "On Therapy"),
															},
															{
																x: "360+ days",
																y: findValueForToT(360, 99999999, searchFilterData, "On Therapy"),
															},
														],
													},
													{
														id: "Discontinued",
														color: "hsl(281, 70%, 50%)",
														data: [
															{
																x: "30 days",
																y: findValueForToT(-99999, 30, searchFilterData, "Discontinued"),
															},
															{
																x: "60 days",
																y: findValueForToT(30, 60, searchFilterData, "Discontinued"),
															},
															{
																x: "90 days",
																y: findValueForToT(60, 90, searchFilterData, "Discontinued"),
															},
															{
																x: "180 days",
																y: findValueForToT(90, 180, searchFilterData, "Discontinued"),
															},
															{
																x: "360 days",
																y: findValueForToT(180, 360, searchFilterData, "Discontinued"),
															},
															{
																x: "360+ days",
																y: findValueForToT(360, 99999999, searchFilterData, "Discontinued"),
															},
														],
													},
													{
														id: "Therapy Repeat",
														color: "hsl(281, 70%, 50%)",
														data: [
															{
																x: "30 days",
																y: findValueForToT(-99999, 30, searchFilterData, "Therapy Repeat"),
															},
															{
																x: "60 days",
																y: findValueForToT(30, 60, searchFilterData, "Therapy Repeat"),
															},
															{
																x: "90 days",
																y: findValueForToT(60, 90, searchFilterData, "Therapy Repeat"),
															},
															{
																x: "180 days",
																y: findValueForToT(90, 180, searchFilterData, "Therapy Repeat"),
															},
															{
																x: "360 days",
																y: findValueForToT(180, 360, searchFilterData, "Therapy Repeat"),
															},
															{
																x: "360+ days",
																y: findValueForToT(360, 99999999, searchFilterData, "Therapy Repeat"),
															},
														],
													},
													{
														id: "In Process",
														color: "hsl(281, 70%, 50%)",
														data: [
															{
																x: "30 days",
																y: findValueForToT(-99999, 30, searchFilterData, "In Process"),
															},
															{
																x: "60 days",
																y: findValueForToT(-99999, 30, searchFilterData, "In Process"),
															},
															{
																x: "90 days",
																y: findValueForToT(-99999, 30, searchFilterData, "In Process"),
															},
															{
																x: "180 days",
																y: findValueForToT(-99999, 30, searchFilterData, "In Process"),
															},
															{
																x: "360 days",
																y: findValueForToT(-99999, 30, searchFilterData, "In Process"),
															},
															{
																x: "360+ days",
																y: findValueForToT(-99999, 30, searchFilterData, "In Process"),
															},
														],
													},
													{
														id: "Unknown",
														color: "hsl(281, 70%, 50%)",
														data: [
															{
																x: "30 days",
																y: findValueForToT(-99999, 30, searchFilterData, "UNKNOWN"),
															},
															{
																x: "60 days",
																y: findValueForToT(30, 60, searchFilterData, "UNKNOWN"),
															},
															{
																x: "90 days",
																y: findValueForToT(60, 90, searchFilterData, "UNKNOWN"),
															},
															{
																x: "180 days",
																y: findValueForToT(90, 180, searchFilterData, "UNKNOWN"),
															},
															{
																x: "360 days",
																y: findValueForToT(180, 360, searchFilterData, "UNKNOWN"),
															},
															{
																x: "360+ days",
																y: findValueForToT(360, 99999, searchFilterData, "UNKNOWN"),
															},
														],
													},
													{
														id: "Never Start",
														color: "hsl(281, 70%, 50%)",
														data: [
															{
																x: "30 days",
																y: findValueForToT(-99999, 30, searchFilterData, "Never Start"),
															},
															{
																x: "60 days",
																y: findValueForToT(30, 60, searchFilterData, "Never Start"),
															},
															{
																x: "90 days",
																y: findValueForToT(60, 90, searchFilterData, "Never Start"),
															},
															{
																x: "180 days",
																y: findValueForToT(90, 180, searchFilterData, "Never Start"),
															},
															{
																x: "360 days",
																y: findValueForToT(180, 360, searchFilterData, "Never Start"),
															},
															{
																x: "360+ days",
																y: findValueForToT(360, 99999999, searchFilterData, "Never Start"),
															},
														],
													},
												]}
												margin={{ top: 20, right: 130, bottom: 60, left: 60 }}
												xScale={{ type: "point" }}
												yScale={{
													type: "linear",
													min: "auto",
													max: "auto",
													stacked: false,
													reverse: false,
												}}
												yFormat=" >-.2f"
												axisTop={null}
												axisRight={null}
												enableGridX={false}
												pointSize={10}
												pointColor={{ theme: "background" }}
												pointBorderWidth={2}
												pointBorderColor={{ from: "serieColor" }}
												pointLabelYOffset={-12}
												useMesh={true}
												axisLeft={{
													format: (e) => Math.floor(e) === e && e,
												}}
												axisBottom={{
													tickSize: 5,
													tickPadding: 5,
													tickRotation: 0,
													legend: <tspan className="font-semibold">Time on Therapy</tspan>,
													legendPosition: "middle",
													legendOffset: 40,
												}}
												legends={[
													{
														anchor: "bottom-right",
														direction: "column",
														justify: false,
														translateX: 100,
														translateY: 0,
														itemsSpacing: 0,
														itemDirection: "left-to-right",
														itemWidth: 80,
														itemHeight: 20,
														itemOpacity: 0.75,
														symbolSize: 12,
														symbolShape: "circle",
														symbolBorderColor: "rgba(0, 0, 0, .5)",
														effects: [
															{
																on: "hover",
																style: {
																	itemBackground: "rgba(0, 0, 0, .03)",
																	itemOpacity: 1,
																},
															},
														],
													},
												]}
											/>
										) : (
											<ResponsiveLine
												data={[
													{
														id: "Total",
														color: "hsl(331, 70%, 50%)",
														data: [
															{
																x: "30 days",
																y: findValueForToT(-99999, 30, searchFilterData),
															},
															{
																x: "60 days",
																y: findValueForToT(30, 60, searchFilterData),
															},
															{
																x: "90 days",
																y: findValueForToT(60, 90, searchFilterData),
															},
															{
																x: "180 days",
																y: findValueForToT(90, 180, searchFilterData),
															},
															{
																x: "360 days",
																y: findValueForToT(180, 360, searchFilterData),
															},
															{
																x: "360+ days",
																y: findValueForToT(360, 99999999, searchFilterData),
															},
														],
													},
												]}
												margin={{ top: 20, right: 80, bottom: 60, left: 50 }}
												xScale={{ type: "point" }}
												yScale={{
													type: "linear",
													min: "auto",
													max: "auto",
													stacked: false,
													reverse: false,
												}}
												axisBottom={{
													tickSize: 5,
													tickPadding: 5,
													tickRotation: 0,
													legend: <tspan className="font-semibold">Time on Therapy</tspan>,
													legendPosition: "middle",
													legendOffset: 40,
												}}
												colors="#3287C4"
												yFormat=" >-.2f"
												axisTop={null}
												axisLeft={{
													format: (e) => Math.floor(e) === e && e,
												}}
												axisRight={null}
												enableGridX={false}
												pointSize={10}
												pointColor={{ theme: "background" }}
												pointBorderWidth={2}
												pointBorderColor={{ from: "serieColor" }}
												pointLabelYOffset={-12}
												useMesh={true}
												legends={[
													{
														anchor: "bottom-right",
														direction: "column",
														justify: false,
														translateX: 100,
														translateY: 0,
														itemsSpacing: 0,
														itemDirection: "left-to-right",
														itemWidth: 80,
														itemHeight: 20,
														itemOpacity: 0.75,
														symbolSize: 12,
														symbolShape: "circle",
														symbolBorderColor: "rgba(0, 0, 0, .5)",
														effects: [
															{
																on: "hover",
																style: {
																	itemBackground: "rgba(0, 0, 0, .03)",
																	itemOpacity: 1,
																},
															},
														],
													},
												]}
											/>
										)}
									</div>

									<p className="font-semibold mt-5 text-sm mb-3">Location of Calls</p>
									<div className="border-2" style={{ height: "240px", overflow: "hidden" }}>
										<div style={{ height: "476px" }}>
											<ResponsiveChoropleth
												data={transcriptData.length > 0 ? transcriptData[0].statesResults : []}
												features={usFeatures.features}
												margin={{
													top: 0,
													right: 0,
													bottom: 0,
													left: 0,
												}}
												colors={["#D8ECFB", "#99D3FD", "#3B9FE7", "#2A71A4"]}
												domain={[
													0,
													Math.max.apply(
														Math,
														transcriptData[0].statesResults.map(function (o: any) {
															return o.value;
														})
													),
												]}
												unknownColor="#ffffff"
												label="properties.name"
												projectionScale={140}
												projectionTranslation={[0.52, 0.24]}
												projectionRotation={[0, 0, 0]}
												enableGraticule={false}
												graticuleLineColor="#dddddd"
												borderWidth={0.5}
												borderColor="#152538"
											/>
										</div>
									</div>
									<p className="mt-7 font-semibold text-sm">Key Words and Concepts</p>
									<div className="flex h-60 mt-2 border-2">
										<ResponsiveBar
											layout="horizontal"
											colors={"#3B9FE7"}
											data={temp.reverse()}
											keys={["value"]}
											indexBy="text"
											margin={{ top: 15, right: 30, bottom: 15, left: 200 }}
											padding={0.3}
											valueScale={{ type: "linear" }}
											borderColor={{
												from: "color",
												modifiers: [["darker", 1.6]],
											}}
											enableGridY={false}
											axisTop={null}
											axisRight={null}
											axisBottom={null}
											axisLeft={{
												tickSize: 0,
												tickPadding: 5,
												tickRotation: 0,
												legendPosition: "middle",
												legendOffset: -40,
											}}
											labelSkipWidth={12}
											labelSkipHeight={20}
											labelTextColor={{
												from: "color",
												modifiers: [["darker", 1.6]],
											}}
											role="application"
											ariaLabel="Therapy Status Distribution"
										/>
									</div>
								</div>
							) : null}
						</div>
					</div>
				</>
			)}
		</>
	);
}
