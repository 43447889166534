import React, { useState } from "react";
import { FilterCard } from "../../Components/FilterCard";
import TableWrapper from "../../Components/TableWrapper";
import ReportTypes from "../../libraries/report-types";
import { icons, userTypeEnum } from "../../GlobalThemes";
import { SearchFilters } from "../../Components/PatientJourneyCards/SearchFilters";
import * as ReportTypesComponentInterface from "../../libraries/report-types/charts/interfaces/main";
import { Route, Routes, useNavigate } from "react-router-dom";

import { patientJourneyData, patient_journey_empty_grouped_columns } from "./data";
import PatientDetails from "./PatientDetails";
import { getPatientListData } from "./ServiceHelpers/patientJourney";
import useBreadcrumbs from "use-react-router-breadcrumbs";

import moment from "moment";
import { Link } from "react-router-dom";
export default function PatientJourneyList(props: { data?: any }) {
  React.useEffect(() => {
    let linkUrl = window.location.href;
    let x = linkUrl.lastIndexOf("patientID=");
    let y: any = null;
    if (x !== -1) {
      y = linkUrl.substring(x + 10);
    }
    if (y) {
      setShowPatientDetails(4);
    }
  }, []);
  const [patientListPage, setPatientListPage] = React.useState<boolean>(false);
  const [fetching, setFetching] = React.useState(false);
  const [data, setData] = React.useState();
  const [fetchedData, setFetchedData] = React.useState<
    {
      [id: string]: string | number;
    }[]
  >([]);

  const navigate = useNavigate();
  const [patientsList, setPatientsList] = useState<any>();
  const [showPatientDetails, setShowPatientDetails] = useState<any>(false);
  const showPatientHistory = (patientID: any, lh_id: any) => {
    console.log(patientID);

    setPatientListPage(true);
    navigate("/search/Patient_Journey/patientID=" + patientID, { state: { lh_id: patientID } });
    setShowPatientDetails(patientID);
  };

  async function fetchdata() {
    const data: any = await getPatientListData();
    if (data) {
      setData(data);
      let dummyData: any = [];
      let dataForList: any = [];

      data.map((i: any, index: any) => {
        let obj: any = {};
        obj.lh_id = i.lh_id;
        obj.currentTherapy = i.currentTherapy;
        obj.disconRisk = i.disconRisk;
        obj.outOfStockDate = moment(i.outOfStockDate).format("D MMM YYYY");
        obj.patientId = i.patientId;
        obj.therapyStartDate = moment(i.therapyStartDate).format("D MMM YYYY");
        obj.timeOnTherapy = i.timeOnTherapy;
        obj.topUnmetNeeds = i.topUnmetNeeds;
        dataForList.push(obj);
      });

      console.log(dataForList);

      dataForList.map((i: any) => {
        let obj: any = {};
        obj.lh_id = (
          <span
            onClick={() => showPatientHistory(i.patientId, i.lh_id)}
            style={{ color: "#27A6A4", cursor: "pointer" }}
          >
            {/* <Route path="/Patient_Details" element={<PatientDetails />}> */}
            {i.lh_id}
            {/* </Route> */}
          </span>
        );
        obj.currentTherapy = i.currentTherapy;
        obj.disconRisk = (
          <div
            style={{
              color:
                i.disconRisk === "High"
                  ? "#B21111"
                  : i.disconRisk === "Medium"
                  ? "#8A640C"
                  : "#27A6A7",
              font: "semibold",
            }}
          >
            {i.disconRisk === "High" ? (
              <span className="mr-2 -mt-1">
                {icons.find((l: any) => l.id === "ExclamationTriangle")?.icon}
              </span>
            ) : i.disconRisk === "Medium" ? (
              <span className="mr-2 -mt-1">
                {icons.find((l: any) => l.id === "MinusCircle")?.icon}
              </span>
            ) : i.disconRisk === "Low" ? (
              <span className="mr-2 -mt-1">
                {icons.find((l: any) => l.id === "Thumbs Up")?.icon}
              </span>
            ) : (
              <></>
            )}

            <span>{i.disconRisk}</span>
          </div>
        );
        obj.topUnmetNeeds = (
          <ul style={{ listStyleType: "disc" }}>
            {i.topUnmetNeeds_1 && <li className="mt-2">{i.topUnmetNeeds_1}</li>}
            {i.topUnmetNeeds_2 && <li className="mt-2">{i.topUnmetNeeds_2}</li>}
            {i.topUnmetNeeds_3 && <li className="mt-2">{i.topUnmetNeeds_3}</li>}
            {i.topUnmetNeeds_4 && <li className="mt-2">{i.topUnmetNeeds_4}</li>}
            {i.topUnmetNeeds_5 && <li className="mt-2">{i.topUnmetNeeds_5}</li>}
            {i.topUnmetNeeds_6 && <li className="mt-2">{i.topUnmetNeeds_6}</li>}
            {i.topUnmetNeeds_7 && <li className="mt-2">{i.topUnmetNeeds_7}</li>}
            {i.topUnmetNeeds_8 && <li className="mt-2">{i.topUnmetNeeds_8}</li>}
          </ul>
        );
        obj.therapyStartDate = i.therapyStartDate;
        obj.timeOnTherapy = i.timeOnTherapy;
        obj.outOfStockDate = i.outOfStockDate;
        dummyData.push(obj);
      });
      setFetchedData(dummyData);
      setPatientsList(dummyData);

      setFetching(true);
    }
  }

  React.useEffect(() => {
    fetchdata();
    // setFetchedData(DUMMY_DATA);
  }, [showPatientDetails]);

  return (
    <>
      {fetching ? (
        <>
          {/* Patient Details Page */}
          {showPatientDetails ? (
            <PatientDetails
              showPatientDetails={showPatientDetails}
              setShowPatientDetails={setShowPatientDetails}
              patientId={showPatientDetails}
            />
          ) : (
            <div>
              <div className="w-screen px-4 shadow-lg" style={{ zIndex: 99, position: "relative" }}>
                <div className="flex py-4 items-center">
                  <div className="flex">
                    <h2 className="text-xl font-semibold ">Patient Journey</h2>
                    <div className="float-left"></div>
                  </div>
                </div>
                <SearchFilters
                  data={data}
                  showPatientHistory={showPatientHistory}
                  patientsList={patientsList}
                  setPatientsList={setPatientsList}
                  fetchedData={fetchedData}
                  setFetchedData={setFetchedData}
                />
              </div>
              <div className="bg-body p-4 w-screen" style={{ height: "calc(100vh - 14.3rem)" }}>
                <TableWrapper title="Patient List" value={1} loading={false} minHeight="37em">
                  <div style={{ minHeight: "29rem" }}>
                    <ReportTypes
                      hideShadow
                      hideRibbon
                      chartType={ReportTypesComponentInterface.chartType.simpleTable}
                      series={[]}
                      chartConfig={{
                        table: {
                          tableData: {
                            column: patient_journey_empty_grouped_columns,
                            data: fetchedData,
                          },
                          noSearchField: true,
                          noPagination: false,
                          maxHeight: 372,
                        },
                      }}
                    />
                  </div>
                </TableWrapper>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {/* Patient Journey Page */}
          <div className="w-screen px-4 shadow-lg" style={{ zIndex: 99, position: "relative" }}>
            <div className="flex py-4 items-center">
              <div className="flex">
                <h2 className="text-xl font-semibold ">Patient Journey</h2>
                <div className="float-left"></div>
              </div>
            </div>
            <SearchFilters
              data={data}
              showPatientHistory={showPatientHistory}
              patientsList={patientsList}
              setPatientsList={setPatientsList}
              fetchedData={fetchedData}
              setFetchedData={setFetchedData}
            />
          </div>
          <TableWrapper loading={true} minHeight="37em">
            <ReportTypes
              hideShadow
              hideRibbon
              chartType={ReportTypesComponentInterface.chartType.simpleTable}
              series={[]}
              chartConfig={{
                table: {
                  tableData: {
                    column: patient_journey_empty_grouped_columns,
                    data: patientJourneyData,
                  },

                  noSearchField: true,
                  noPagination: true,
                  maxHeight: 394,
                },
              }}
            />
          </TableWrapper>
        </>
      )}
    </>
  );
}

export function PatientJourney() {
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <div className="">
      {breadcrumbs.map((match: any, breadcrumb: any) => (
        <Link key={match.key} to={match.url}>
          {match.breadcrumb} /
        </Link>
      ))}
    </div>
  );
}

export const routes = [
  {
    key: "Patient Journey - Patient Details",
    path: "patient-details/:patientID/*",
    breadcrumb: "Example 1",
  },
  { key: "Stories 0", path: "/", breadcrumb: "Example 2" },
];
