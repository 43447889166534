import { Modal } from "antd";
import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import AddStoryModalContent from "../../Components/AddStoryModal";
import DeleteAlert from "../../Components/DeleteAlert";
import SubHeaderComponent from "../../Components/SubHeaderComponent";
import TableWrapper from "../../Components/TableWrapper";
import { storyTypes } from "./data";
import Stories from "./Stories/index";
import StoryMode from "./Stories/storyMode";
import "./style.css";
import * as StoriesServiceHelper from "./../SearchEngine/ServiceHelpers/stories";
import * as Messages from "../../Components/Messages";
const DUMMY_DATA = [
  {
    id: 11,
    "Story Name": "Query about Alcohol Consumption",
    "Story Type": "General Tracker",
    "Date Created": "12 Aug 2020",
    "# of Annotated Selections": 12,
    Status: "In Draft",
  },
  {
    id: 22,
    "Story Name": "Worried about Side Effects",
    "Story Type": "Issues",
    "Date Created": "15 Sept 2020",
    "# of Annotated Selections": 42,
    Status: "Published",
  },
  {
    id: 33,
    "Story Name": "Unhappy with Meal Restrictions",
    "Story Type": "Issues",
    "Date Created": "19 Aug 2020",
    "# of Annotated Selections": 15,
    Status: "In Draft",
  },
  {
    id: 44,
    "Story Name": "Receiving too many Calls",
    "Story Type": "General Tracker",
    "Date Created": "22 Oct 2020",
    "# of Annotated Selections": 26,
    Status: "In Draft",
  },
  {
    id: 55,
    "Story Name": "Unhappy with Pill Box",
    "Story Type": "Issues",
    "Date Created": "15 Jun 2020",
    "# of Annotated Selections": 6,
    Status: "Published",
  },
  {
    id: 66,
    "Story Name": "Unhappy with Pill Box",
    "Story Type": "General Tracker",
    "Date Created": "15 Jun 2020",
    "# of Annotated Selections": 6,
    Status: "Published",
  },
  {
    id: 77,
    "Story Name": "Unhappy with Pill Box",
    "Story Type": "Issues",
    "Date Created": "15 Jun 2020",
    "# of Annotated Selections": 6,
    Status: "Published",
  },
  {
    id: 88,
    "Story Name": "Unhappy with Pill Box",
    "Story Type": "Issues",
    "Date Created": "15 Jun 2020",
    "# of Annotated Selections": 6,
    Status: "Published",
  },
];

/**
 * Renders the Stories Table. This function also handles the APIs for this table.
 *
 * @returns Stories Table View.
 */
function StoriesTable(): JSX.Element {
  const [fetching, setFetching] = React.useState(true);
  const [fetchedData, setFetchedData] = React.useState<
    {
      [id: string]: string | number;
    }[]
  >([]);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [alertVisible, setAlertVisible] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(<></>);
  const [selectDelete, setSelectDelete] = React.useState("");
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function handleDelete(storyId: string) {
    setAlertVisible(true);
    setSelectDelete(storyId);
  }

  function showModal() {
    setIsModalVisible(true);
    setModalContent(
      <AddStoryModalContent
        handleCancel={handleCancel}
        storyTypes={storyTypes}
        onSave={handleAddApi}
      />
    );
  }
  function handleEdit(storyId: number) {
    setIsModalVisible(true);
    setModalContent(
      <AddStoryModalContent
        handleCancel={handleCancel}
        storyTypes={storyTypes}
        editData={fetchedData.find((story) => story.id === storyId)}
        onSave={handleEditApi}
      />
    );
  }
  function handleDeleteApi() {
    setAlertVisible(false);
    if (selectDelete) {
      deleteStory(selectDelete);
    }
    setSelectDelete("");
  }
  function handleEditApi(editData: any) {
    setIsModalVisible(false);
    const temp = [...fetchedData];
    const idx = fetchedData.findIndex((story) => story.id === editData.id);

    if (idx !== -1) {
      const editDataTemp = {
        id: String(temp[idx].id),
        "Story Name": String(editData["Story Name"]),
        "Story Type": String(editData["Story Type"]).split(" ").join(""),
      };
      editStory(editDataTemp);
      temp[idx]["Story Name"] = "Updating";
      temp[idx]["Story Type"] = "Updating";
      setFetchedData(temp);
    }
  }
  function handleAddApi(addData: any) {
    setIsModalVisible(false);
    if (addData) {
      addNewStory(addData);
    }
  }
  async function editStory(editData: { id: string; "Story Name": string; "Story Type": string }) {
    const key = "updatable";
    Messages.loading("Updating Story", key);
    const data: any = await StoriesServiceHelper.edtStory(editData);
    // console.log("Data...", data);
    if (data) {
      const temp = [...fetchedData];
      const idx = fetchedData.findIndex((story) => story.id === editData.id);
      temp[idx]["Story Name"] = data["Story Name"];
      temp[idx]["Story Type"] = data["Story Type"];
      setFetchedData(temp);
      Messages.success("Story Updated Sucessfully", key);
    } else {
      Messages.error("Failed!", key);
    }
  }
  async function deleteStory(storyId: string) {
    const key = "updatable";
    Messages.loading("Deleting Story", key);
    const data: any = await StoriesServiceHelper.deleteStory(storyId);
    if (data === 200) {
      const temp = [...fetchedData];
      const idx = temp.findIndex((d) => d.id === storyId);
      temp.splice(idx, 1);
      setFetchedData(temp);
      Messages.success("Story Deleted Sucessfully", key);
    } else {
      Messages.error("Failed!", key);
    }
  }
  async function addNewStory(addData: { "Story Name": string; "Story Type": string }) {
    addData["Story Type"] = addData["Story Type"].split(" ").join("");
    console.log(addData);
    const key = "updatable";
    Messages.loading("Adding Story", key);
    const data: any = await StoriesServiceHelper.addNewStory(addData);
    if (data) {
      // data["Story Type"] =
      // 	data["Story Type"] === "GeneralTracker"
      // 		? "General Tracker"
      // 		: data["Story Type"];
      // data["Status"] =
      // 	data["Status"] === "InDraft" ? "In Draft" : data["Status"];

      const temp = [data, ...fetchedData];
      // temp.push(data);
      setFetchedData(temp);
      Messages.success("Story Added Sucessfully", key);
    } else {
      Messages.error("Failed!", key);
    }
  }
  async function fetchdata() {
    const data: any = await StoriesServiceHelper.getAllStories();

    if (data) {
      setFetchedData(data);
      setFetching(false);
    }
  }
  React.useEffect(() => {
    fetchdata();
    // setFetchedData(DUMMY_DATA);
  }, []);
  return (
    <>
      <DeleteAlert visible={alertVisible} setVisible={setAlertVisible} onYes={handleDeleteApi} />
      {!fetching ? (
        <div>
          <div className="shadow-lg relative">
            <SubHeaderComponent title="Stories" />
          </div>
          <div className="stories-body bg-body p-4">
            <Modal
              title={null}
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={null}
              destroyOnClose={true}
            >
              {modalContent}
            </Modal>
            <Stories
              data={{ stories: fetchedData }}
              deleteStory={handleDelete}
              editStory={handleEdit}
              addNewStory={showModal}
            />
          </div>
        </div>
      ) : (
        <>
          <div>
            <div className="shadow-lg relative">
              <SubHeaderComponent title="Stories" />
            </div>
            <div className="stories-body bg-body p-4">
              <Stories data={{}} loading={true} />
            </div>
          </div>
        </>
      )}
    </>
  );
}
/**
 * Renders the Stories Tab View. This function sets up the routes within the Stories Tab.
 *
 * @returns Stories Tab View Routes.
 */
export function StoriesView() {
  return (
    <div className="">
      <Routes>
        <Route key={"Stories 0"} path="/" element={<StoriesTable />} />
        <Route key="Stories - Stories Mode" path="story-mode/:storyId/*" element={<StoryMode />} />
      </Routes>
    </div>
  );
}
