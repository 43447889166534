import { exportDataInterface } from ".";

export function transformExportData(data: exportDataInterface) {
	const csvData: string[][] = [];
	csvData.push([
		"Story Id",
		"Story Name",
		"Story Status",
		"Transcript",
		"Transcript Added On",
		"Dialogue Appeared At",
		"Dialogue Length",
		"Transcript Length",
		"Transcript Text",
		// "Source",
		// "Therapy Status",
		// "ToT",
		"Transcript Tags",
		"Transcript Status",
		"Highlighted Text",
	]);
	console.log("SS", data);
	const storyName = data.storyName;
	const storyId = data.storyId;
	const storyStatus = data.storyStatus;
	data.transcripts.map((transcript) => {
		let transcriptText = "";
		transcript.Description.transcripts.map((t) => {
			transcriptText += t.speaker + " : " + t.text + "\n";
		});
		let tags: string = "";
		transcript.Tags.map((tag) => tags + ", " + tag);
		csvData.push([
			storyId,
			storyName,
			storyStatus,
			transcript["Annotated Selection"],
			transcript["Date Added on"],
			transcript.Description.dialogueAppearedAt,
			transcript.Description.dialogueLength,
			transcript.Description.transcriptLength,
			transcriptText,
			transcript.Source,
			transcript.therapyStatus,
			//transcript.ToT,
			tags,
			transcript.Status,
			transcript.highlightedString,
		]);
	});
	console.log("CSV Data : ", csvData);
	return csvData;
}
