import React from "react";
import { Modal } from "antd";
import { icons } from "../../GlobalThemes";
import { useNavigate } from "react-router-dom";

export default function DeleteAlert(props: {
	visible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	onYes?: any;
}) {
	return (
		<Modal
			destroyOnClose
			title={null}
			visible={props.visible}
			footer={null}
			onCancel={() => {
				props.setVisible(false);
			}}
		>
			<div>
				<div className="py-10 px-4">
					<div className="flex items-center gap-4">
						<div className="text-text-orange">
							{icons.find((l) => l.id === "AlertCircle")?.icon}
						</div>
						<div className="text-xl">
							Are you sure you want to delete this story? All data
							will be lost.
						</div>
					</div>

					<div className="flex justify-end gap-4 pt-4">
						<button
							className="text-text-gray-50 text-base px-4 py-1 border-2 border-border"
							onClick={() => {
								props.setVisible(false);
							}}
						>
							No
						</button>
						<button
							className="bg-text-teal text-text-white text-base px-4 py-1 border-2 border-text-teal"
							onClick={() => {
								props.onYes();
							}}
						>
							Yes
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
}
