import { Input, List, Skeleton, Spin } from "antd";
import React from "react";
import FilterResultCard from "../../../../Components/FilterResultCard";
import { Transcripts } from "../../../../Components/SearchEngineCards/Transcripts";
import TranscriptsWrapper from "../../../../Components/TransciptsWrapper";
import { icons } from "../../../../GlobalThemes";
import * as StoriesFindSimilarConversations from "../../ServiceHelpers/stories_findSimilarConversations";
import * as StoriesServiceHelper from "../../../../Pages/SearchEngine/ServiceHelpers/stories";
import * as SearchServiceHelper from "../../../../Pages/SearchEngine/ServiceHelpers/search";
import * as NodeGraphServiceHelper from "../../../../Pages/SearchEngine/ServiceHelpers/nodeGraph";
import StoriesSummaryCard from "../StoriesSummaryCard";

import * as Messages from "../../../../Components/Messages";
import { filterOptions } from "../../data";
import { searchQuery } from "../../Search";
import moment from "moment";

/**
 * Renders the Find Similar Conversations View, showing the Filters and the Similar Transcripts.
 *
 * @param storyId - Id of the Story that the component is getting rendered in
 * @param addTranscript - Callback function for handling Addition of Transcript to a Story
 *
 * @returns Find Similar Conversations View
 */

// export interface transcriptDataInter {
//   // filterOptions: {
//   // 	filterName: string;
//   // 	type: string;
//   // 	options: string[];
//   // }[];
//   numberOfPatients: number;
//   numberOfSearchResults: number;
//   emotionsCounts: any;
//   statesResults: any;
//   searchResults: {
//     id: number;
//     s3path: string;
//     callDate: string;
//     callId: string;
//     callTime: string;
//     callTypeIds: string;
//     therapyStatusIds: string;
//     dialogueNo: number;
//     name: string;
//     tags: string[];
//     dialogueAppearedAt: number;
//     dialogueLength: number;
//     transcriptLength: number;
//     addedToStory: boolean;
//     storiesAdded: {
//       storyId: any;
//       storyName: string;
//     }[];
//     patientId: number;
//     speaker: string;
//     elasticSearchId: string;
//     transcripts: {
//       dialogueNo: number;
//       speaker: string;
//       text: string;
//     }[];
//   }[];
// }

export default function FindSimilarConversations(props: { storyId: string; addTranscript: (transcriptData: any) => void }) {
	const [dataResult2, setDataResult2] = React.useState<
		{
			filterOptions: {
				filterName: string;
				type: string;
				options: string[];
			}[];
			numberOfPatients: number;
			numberOfSearchResults: number;
			searchResults: {
				id: number;
				s3path: string;
				callDate: string;
				callId: string;
				callTime: string;
				dialogueNo: number;
				name: string;
				tags: string[];
				dialogueAppearedAt: number;
				dialogueLength: number;
				transcriptLength: number;
				addedToStory: boolean;
				patientId: number;
				speaker: string;
				elasticSearchId: string;
				transcripts: {
					dialogueNo: number;
					speaker: string;
					text: string;
				}[];
			}[];
		}[]
	>([]);
	const dataCopy = React.useRef<
		{
			filterOptions: {
				filterName: string;
				type: string;
				options: string[];
			}[];
			numberOfPatients: number;
			numberOfSearchResults: number;
			searchResults: {
				id: number;
				s3path: string;
				callDate: string;
				callId: string;
				callTime: string;
				dialogueNo: number;
				name: string;
				tags: string[];
				dialogueAppearedAt: number;
				dialogueLength: number;
				transcriptLength: number;
				addedToStory: boolean;
				patientId: number;
				speaker: string;
				elasticSearchId: string;
				transcripts: {
					dialogueNo: number;
					speaker: string;
					text: string;
				}[];
			}[];
		}[]
	>([]);
	const [expanded, setExpanded] = React.useState(false);
	const [filters, setFilters] = React.useState<
		{
			filter: string;
			value: string;
		}[]
	>([]);

	function handleAddToStory(transcriptData: any, storyId: number[]) {
		// console.log("DDD", transcriptData);

		const temp = [...dataResult2];
		const idx = temp[0].searchResults.findIndex((res) => res.callId === transcriptData.callId && res.dialogueNo === transcriptData.dialogueNo);
		console.log("Console", idx);
		if (idx !== -1) {
			const res = addToStory({
				...temp[0].searchResults[idx],
				storiesAdded: [{ storyId: props.storyId, storyName: "" }],
				source: 2,
			});
			return res;
		}
		return Promise.reject();
	}

	async function addToStory(transcriptData: any) {
		const key = "updatable";
		Messages.loading("Adding to Story", key);

		const idx = [...dataResult2][0].searchResults.findIndex((res) => res.callId === transcriptData.callId && res.dialogueNo === transcriptData.dialogueNo);

		const notSelected: {
			// index: number;
			callId: string;
			dialogueNo: number;
		}[] = [];
		for (let i = 0; i < idx; i++) {
			if (!dataResult2[0].searchResults[i].addedToStory)
				notSelected.push({
					// index: i,
					callId: dataResult2[0].searchResults[i].callId,
					dialogueNo: dataResult2[0].searchResults[i].dialogueNo,
				});
		}
		// console.log("Transcript Data", notSelected);
		console.log(transcriptData, notSelected);
		const data: any = await StoriesFindSimilarConversations.addToStory(
			{
				...transcriptData,
				notAddedStories: notSelected,
			},
			props.storyId
		);
		if (data) {
			const temp = [...dataResult2];
			const idx = temp[0].searchResults.findIndex((res) => res.callId === transcriptData.callId && res.dialogueNo === transcriptData.dialogueNo);

			temp[0].searchResults[idx].addedToStory = true;
			props.addTranscript(temp[0].searchResults[idx]);
			// dataCopy.current = [...temp];
			setDataResult2(temp);
			// setFetchedData(temp);
			Messages.success("Added Successfully", key);
			return true;
		} else {
			Messages.error("Failed!", key);
			return false;
		}
		return false;
	}
	const [fetching, setFetching] = React.useState(true);

	async function fetchdata() {
		// const data: any = await StoriesFindSimilarConversations.getAllStories(
		// 	props.storyId
		// );

		setFetching(true);
		let showResults = filters.find((r) => r.filter === "Show Results")?.value;

		// const showResultsBool =
		// 	showResults === "All"
		// 		? undefined
		// 		: showResults === "Already added to a story"
		// 		? true
		// 		: false;
		// const startdate = filters.find((r) => r.filter === "Start Date")?.value;
		// const enddate = filters.find((r) => r.filter === "End Date")?.value;
		// const speaker = filters.find((r) => r.filter === "Speaker Type")?.value;

		const obj: searchQuery = {};

		obj.startDate = filters.find((r) => r.filter === "Start Date")?.value;
		obj.endDate = filters.find((r) => r.filter === "End Date")?.value;
		obj.speaker = filters.find((r) => r.filter === "Speaker Type")?.value;
		obj.totStart = filters.find((r) => r.filter === "totStart")?.value;
		obj.totEnd = filters.find((r) => r.filter === "totEnd")?.value;
		obj.showResults = showResults ? (showResults === "All" ? undefined : showResults === "Already added to a story" ? true : false) : undefined;
		obj.storyId = props.storyId;

		const data: any = await StoriesFindSimilarConversations.getAllStories(obj);

		if (data) {
			// data.filterOptions = filterOptions;
			// dataCopy.current = [{ ...data }];
			setDataResult2([data]);
			setFetching(false);
		}
	}
	React.useEffect(() => {
		fetchdata();
	}, [filters]);
	React.useEffect(() => {
		fetchdata();
	}, []);

	const [pinnedStories, setPinnedStories] = React.useState<Array<any>>([]);
	const [fetchedStoryData, setFetchedStoryData] = React.useState<any>([]);
	const [transcriptData, setTranscriptData] = React.useState<any>([]);
	const [transcriptLoading, setTranscriptLoading] = React.useState<boolean>(false);
	const [summaryData, setSummaryData] = React.useState<string>("");
	const [summaryDataFetched, setSummaryDataFetched] = React.useState<boolean>(false);

	async function fetchSummaryData() {
		const s3Data: any = await NodeGraphServiceHelper.getNodeJsons("kural_hpp_demo/gpt_config/" + props.storyId + ".txt");
		let summary = "No summary available at the moment.";

		if (s3Data) {
			summary = s3Data?.summary
				? s3Data.summary
				: "Thank you for your patience. Our system is diligently working on generating the summary for you. Please hold tight while we finalize the details. Your wait won't be much longer.";
		}
		setSummaryData(summary);
		setSummaryDataFetched(true);
	}

	async function fetchStoryStatus() {
		try {
			fetchSummaryData();
			fetchdata();

			// const response: any = await StoriesFindSimilarConversations.getStoryStatus(props.storyId);
			// if (response.status === "COMPLETED") {
			// 	fetchSummaryData();
			// 	fetchdata();
			// } else if (response.status === "IN PROGRESS") {
			// 	const summary = "Story Overview generation in progress. Please wait.";
			// 	setSummaryData(summary);
			// } else if (response.status === "FAILED") {
			// 	const summary = "Story Overview generation failed.";
			// 	setSummaryData(summary);
			// } else if (response.status === "UNAVAILABLE") {
			// 	const summary = "Begin highlighting the annotations to generate story overview and recommendations.";
			// 	setSummaryData(summary);
			// } else if (response.status === "UNCHANGED" && !summaryDataFetched) {
			// 	fetchSummaryData();
			// }
		} catch (error) {
			console.error("Error fetching overview summary:", error);
		}
	}

	const changePinnedStories = (stories: any) => {
		setPinnedStories(stories);
	};

	async function fetchStoryData() {
		const data: any = await StoriesServiceHelper.getAllStories();

		if (data) {
			setFetchedStoryData(data);
		}
	}

	async function addStoryToTranscriptData(
		transcript: {
			id: any;
			name: any;
			tags: any;
			storiesAdded: any;
			source: number;
			dialogueNo: any;
			callId: any;
			callDate: any;
			callTime: any;
			s3path: any;
			dialogueAppearedAt: any;
			dialogueLength: any;
			transcriptLength: any;
			transcripts: any;
			patientId: any;
			speaker: any;
			elasticSearchId: any;
			storyDialogues: any;
		},
		storyId?: any
	) {
		const key = "updatable";
		Messages.loading("Adding Transcript to Story", key);

		const data: any = await SearchServiceHelper.addToStory(transcript);

		// console.log("Fetched : ", data);
		if (data) {
			Messages.success("Successfully Added Transcript to Story", key);

			const temp = [...dataResult2];
			setTranscriptData(temp);

			console.log("DataResult2", data, dataResult2, transcriptData, transcript);

			const idx = temp[0].searchResults.findIndex((res: any) => res.callId === transcript.callId && res.dialogueNo === transcript.dialogueNo);

			// temp[0].searchResults[idx].storiesAdded = data;
			console.log("Console", data, idx);

			// if (idx !== -1) {
			//   const res = addToStory({
			//     ...temp[0].searchResults[idx],
			//     storiesAdded: [{ storyId: storyId, storyName: "" }],
			//     source: 2,
			//   });
			//   console.log(res);
			//   return res;
			// }
			//@ts-ignore
			temp[0].searchResults[idx].storiesAdded = data;
			if (data.length === 0) {
				temp[0].searchResults[idx].addedToStory = false;
			}
			setTranscriptData(temp);

			return true;
		} else {
			Messages.error("Failed", key);
			return false;
		}
	}

	const addToStorySearch = (transcriptData: any, storyId: any) => {
		var request = {
			transcriptData: transcriptData,
			storyId: storyId,
		};
		var add: any = [];
		storyId.forEach((story: any, index: number) => {
			add.push({
				storyId: story,
				storyName: fetchedStoryData[fetchedStoryData.findIndex((storyFetch: any) => storyFetch.id === story)].storyName,
			});
		});

		var trans = {
			id: transcriptData.id,
			name: transcriptData.name,
			tags: transcriptData.tags,
			storiesAdded: add,
			source: 1,
			dialogueNo: transcriptData.dialogueNo,
			callId: transcriptData.callId,
			callDate: transcriptData.callDate,
			callTime: transcriptData.callTime,
			s3path: transcriptData.s3path,
			dialogueAppearedAt: transcriptData.dialogueAppearedAt,
			dialogueLength: transcriptData.dialogueLength,
			transcriptLength: transcriptData.transcriptLength,
			transcripts: transcriptData.transcripts,
			patientId: transcriptData.patientId,
			speaker: transcriptData.speaker,
			elasticSearchId: transcriptData.elasticSearchId,
			storyDialogues: transcriptData.storyDialogues,
		};
		console.log("TRANS", trans, storyId);
		const d = addStoryToTranscriptData(trans, storyId);

		return d;
	};

	async function addNewStory(addData: { "Story Name": string; "Story Type": string }) {
		const key = "updatable";
		Messages.loading("Adding Transcript", key);

		addData["Story Type"] = addData["Story Type"].split(" ").join("");
		// console.log(addData);
		const data: any = await StoriesServiceHelper.addNewStory(addData);
		if (data) {
			var temp: any = [];
			var d = data["Date Created"];
			data["Date Created"] = moment(d).format("DD MMM YYYY");
			temp = [data, ...fetchedStoryData];
			setFetchedStoryData(temp);
			Messages.success("Successfully Created New Story", key);
		} else Messages.error("Failed", key);
	}

	const fetchTranscripts = async (query: searchQuery) => {
		setTranscriptLoading(true);
		Messages.loading("Fetching...", "key1");
		const data: any = await SearchServiceHelper.getAllTranscripts(query);
		setTranscriptLoading(false);
		const key = "Key 1";

		if (data) {
			setTranscriptData([data]);
			dataCopy.current = [data];
			Messages.success("Success", "key1");
		} else {
			Messages.error("Fetching failed", "key1");
			setTranscriptData([]);
		}
	};

	React.useEffect(() => {
		// fetchFilters();
		fetchStoryData();
	}, []);

	React.useEffect(() => {
		if (props.storyId) {
			fetchStoryStatus();
			fetchdata();
		}
	}, [props.storyId, filters]);

	React.useEffect(() => {
		const intervalId = setInterval(fetchStoryStatus, 60000);
		return () => clearInterval(intervalId);
	}, [summaryDataFetched]);

	return (
		<>
			{dataResult2.length > 0 ? (
				<div style={{ height: "calc(100vh - 15rem)", overflowY: "auto" }}>
					{summaryData.length > 0 ? (
						<div className="bg-white shadow-md shadow-gray-900 mb-1">
							<StoriesSummaryCard data={summaryData} searchString="Overview" isloaded={summaryData.length > 0 ? true : false}></StoriesSummaryCard>
						</div>
					) : (
						<></>
					)}
					<div className="p-4 bg-white">
						<div className="grid grid-cols-10">
							<div
								className="col-span-2 border-r-2 p-2 overflow-y-auto"
								// style={{ maxHeight: "30.8em" }}
								style={{ maxHeight: "59vh" }}
							>
								<FilterResultCard filterOptions={filterOptions} filters={filters} setFilters={setFilters} />
							</div>
							{!fetching ? (
								<div
									className="col-span-8 px-2 overflow-y-auto"
									// style={{ maxHeight: "30.8em" }}
									style={{ maxHeight: "59vh" }}
								>
									<TranscriptsWrapper
										header={`${dataResult2[0].numberOfSearchResults} search results across ${dataResult2[0].numberOfPatients} members`}
										expanded={expanded}
										toggleExpand={setExpanded}
										noSummary
									>
										<List
											itemLayout="vertical"
											size="large"
											pagination={{
												hideOnSinglePage: true,
												style: {
													marginLeft: "2rem",
													marginBottom: "1.2rem",
													float: "right",
												},
												onChange: (page) => {
													console.log(page);
												},
												pageSize: 10,
											}}
											dataSource={dataResult2[0].searchResults}
											renderItem={(result: any) => (
												<Transcripts
													storyId={props.storyId}
													data={result}
													expanded={expanded}
													addToStoryApi={addToStorySearch}
													// addToStoryApi={handleAddToStory}
													//added props for stories
													dataForNegativeSampling={dataResult2[0].searchResults}
													pinnedStoriesData={pinnedStories}
													fetchedStoryData={fetchedStoryData}
													fetchStoryData={fetchStoryData}
													addNewStory={addNewStory}
													changePinnedStories={changePinnedStories}
												/>
											)}
										/>
									</TranscriptsWrapper>
								</div>
							) : (
								<>
									<div
										className="col-span-8 px-2 overflow-y-hidden"
										// style={{ maxHeight: "30.8em" }}
										style={{ height: "59vh" }}
									>
										<TranscriptsWrapper header="" loading>
											<>
												{[1, 2].map((result) => (
													<div className="p-4 px-8">
														<Skeleton
															loading={true}
															active
															paragraph={{
																rows: 4,
															}}
														></Skeleton>
													</div>
												))}
											</>
										</TranscriptsWrapper>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			) : (
				<>
					<div className="p-4 bg-white">
						<div className="grid grid-cols-10">
							<div
								className="col-span-2 border-r-2 p-2 overflow-y-auto"
								// style={{ maxHeight: "30.8em" }}
								style={{ height: "59vh" }}
							>
								<FilterResultCard loading={true} filterOptions={[]} filters={[]} setFilters={setFilters} />
							</div>
							<div
								className="col-span-8 px-2 overflow-y-hidden"
								// style={{ maxHeight: "26.5em" }}
								style={{ height: "59vh" }}
							>
								<TranscriptsWrapper header="" loading>
									<>
										{[1, 2].map((result) => (
											<div className="p-4 px-8">
												<Skeleton
													loading={true}
													active
													paragraph={{
														rows: 4,
													}}
												></Skeleton>
											</div>
										))}
									</>
								</TranscriptsWrapper>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}
