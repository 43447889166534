import React from "react";
import Pages from "./Pages";
import AppContext from "./store";
import { userTypeEnum } from "./GlobalThemes";
/**
 * Defines the different routes to the pages
 *
 * @returns Routes
 */
export function Routes() {
	const context = React.useContext(AppContext);
	let routePaths: {
		path: string;
		element: JSX.Element;
	}[] = [];
	if (context.userType === userTypeEnum[0]) {
		{
			console.log("POWERRRRRR");
		}
		routePaths = [
			{
				path: "/",
				element: <Pages.LandingPage />,
			},
			{
				path: "/dashboard/*",
				element: <Pages.Dashboard />,
			},
			{
				path: "/search/*",
				element: <Pages.SearchEngine />,
			},
			{
				path: "/highpatientneeds/*",
				element: <Pages.HighPatientNeeds />
			}
			// {
			// 	path: "highpatientneeds/Discon",
			// 	element: <Pages.Discon />
			// }

		];
	} else if (context.userType === userTypeEnum[1]) {
		routePaths = [
			{
				path: "/",
				element: <Pages.LandingPage />,
			},
			{
				path: "/dashboard/*",
				element: <Pages.Dashboard />,
			},
			{
				path: "/search/*",
				element: <Pages.SearchEngine />,
			},
		];
	} else if (context.userType === userTypeEnum[2]) {
		routePaths = [
			{
				path: "/",
				element: <Pages.LandingPage />,
			},
			{
				path: "/search/*",
				element: <Pages.SearchEngine />,
			},
			{
				path: "/dashboard/*",
				element: <Pages.Dashboard />,
			},
			{
				path: "/highpatientneeds/*",
				element: <Pages.HighPatientNeeds />
			}
		];
	}

	return routePaths;
}
// business user - dash +searc
// power - dash+search+story
// analyst - search+story
